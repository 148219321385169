
// outsource dependencies

// local dependencies

export const LIST = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        GET_DATA: `${prefix}GET_DATA`,
        INITIALIZE: `${prefix}INITIALIZE`,
        DELETE_ITEM: `${prefix}DELETE_ITEM`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
    };
})('@my-tasks/list/');

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        CANCEL: `${prefix}CANCEL`,
        UPDATE: `${prefix}UPDATE`,
        INITIALIZE: `${prefix}INITIALIZE`,
    };
})('@my-tasks/edit/');
