// outsource dependencies
import {store} from '../../../store';
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, FieldArray, getFormValues, reduxForm, reset} from 'redux-form';
// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import MdSwitch from '../../../components/md-switch';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import TasksManagement from '../../../components/tasks-management';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import Radio from "../../../components/md-radio";
import {filters} from "../../../components/filter";
import {ASSESSMENTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import MdDatePicker from "../../../components/md-date-picker";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';

// config
const FORM_NAME = 'editAssessment';
export const changeField = (field, value) => change(FORM_NAME, field, value);
export const REQUIREMENT_TYPE = {
    ALL_REQUIREMENTS: 'ALL_REQUIREMENTS',
    FRAMEWORKS: 'FRAMEWORKS',
    REQUIREMENTS : 'REQUIREMENTS'
};

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ ASSESSMENTS_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:8,offset:2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase offset-bottom-10">
                                <span>
                                     <RichHintTitle
                                         update={EDIT}
                                         name={isNew ? `ASSESSMENTS$CREATE_ASSESSMENT` : `ASSESSMENTS$EDIT_ASSESSMENT`}
                                         expectAnswer={expectAnswer}
                                         data={findHint(hints, `ASSESSMENTS_TITLE`)}/>
                                </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                            <ConnectedError />
                            <ConnectedForm isNew={isNew} />
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({expectAnswer: state.assessments.edit.expectAnswer, hints: state.assessments.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.assessments.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.assessments.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

// noinspection JSAnnotator
const ConnectedForm = withTranslation(connect(
    state => ({
        formValues: getFormValues(FORM_NAME)(state),
        initialValues: state.assessments.edit.data,
        disabled: state.assessments.edit.expectAnswer,
        securityRequirements: state.assessments.edit.securityRequirements,
        hints: state.assessments.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        clearItems: () => dispatch({type: EDIT.CLEAR_ITEMS}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        resetForm: () => {
            dispatch(reset(FORM_NAME));
            // NOTE getting initial values for displaying after reset form
            const { assessmentLevel, securityRequirements } = store.getState().assessments.edit.data;
            // NOTE setting initial data of requirementsMapping after reset form
            dispatch({type: EDIT.META, requirementsMapping: []});
            dispatch({type: EDIT.CHANGE_SECURITY_REQUIREMENTS, newValue: securityRequirements});
            // NOTE setting initial assessment level after reset form
            dispatch({type: EDIT.CHANGE_ASSESSMENT_LEVEL, assessmentLevel});
        },
        changeAssessmentLevel: assessmentLevel => {
            dispatch(changeField('securityRequirements', []));
            dispatch({type: EDIT.META, requirementsMapping: []});
            dispatch({type: EDIT.CHANGE_ASSESSMENT_LEVEL, assessmentLevel})
        },
        changeRequirements: (e, newValue, prevValue) => {
            dispatch({type: EDIT.CHANGE_SECURITY_REQUIREMENTS, newValue, prevValue});
        },
        changeRequirementType: (e, value) => {
            switch ( value ) {
                default:
                    break;
                case REQUIREMENT_TYPE.ALL_REQUIREMENTS:
                    dispatch(changeField('securityRequirements', []));
                    dispatch(changeField('assessmentTypes', []));
                    dispatch({type: EDIT.META, requirementsMapping: []});
                    break;
                case REQUIREMENT_TYPE.FRAMEWORKS:
                    dispatch(changeField('securityRequirements', []));
                    dispatch({type: EDIT.META, requirementsMapping: []});
                    break;
                case REQUIREMENT_TYPE.REQUIREMENTS:
                    dispatch(changeField('assessmentTypes', []));
                    break;
            }
        },
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, isNew, cancel, formValues = {}, clearItems, resetForm, changeAssessmentLevel, changeRequirements, securityRequirements, initialValues, changeRequirementType, hints})=>{
    let hasAdditionalFields = get(formValues, 'assessmentLevel') && get(formValues, 'assessmentLevel.name') !== 'Org';
    return (<form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$DESCRIPTION')}
                    label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} md={6}>
                <SelectEntities
                    disabled={disabled}
                    name="assessmentLevel"
                    type={ENTITY_TYPES.ASSESSMENT_LEVELS}
                    onChange={(e, value) => changeAssessmentLevel(value)}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('ASSESSMENTS$ASSESSMENT_LEVEL')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6}>
                <SelectEntities
                    name="owner"
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={option => get(option, 'fullName')}
                    label={(<strong /* className="required-asterisk" */> {translate('GLOBALS$OWNER')} </strong>)}
                />
            </Col>
        </Row>


        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    disabled={disabled}
                    component={MdDatePicker}
                    name="estimatedStartDate"
                    inputProps={{max: get(formValues, 'estimatedEndDate')}}
                    label={(<strong> {translate('TASKS$ESTIMATED_START_DATE')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    disabled={disabled}
                    name="estimatedEndDate"
                    component={MdDatePicker}
                    inputProps={{min: get(formValues, 'estimatedStartDate')}}
                    label={(<strong> {translate('TASKS$ESTIMATED_END_DATE')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    disabled={disabled}
                    name="actualStartDate"
                    component={MdDatePicker}
                    inputProps={{max: get(formValues, 'actualEndDate')}}
                    label={(<strong> {translate('TASKS$ACTUAL_START_DATE')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    disabled={disabled}
                    name="actualEndDate"
                    component={MdDatePicker}
                    inputProps={{min: get(formValues, 'actualStartDate')}}
                    label={(<strong> {translate('TASKS$ACTUAL_END_DATE')} </strong>)}
                />
            </Col>
        </Row>


        {hasAdditionalFields && (<Row className="offset-bottom-2"> <Col xs={12}>
            <Field
                fullWidth={false}
                disabled={disabled}
                component={MdSwitch}
                name="isAllSelected"
                onChange={(e, value) => value&&clearItems()}
                label={(<strong> {translate('GLOBALS$ALL_ITEMS')} </strong>)}
                    />
        </Col> </Row>)}
        {hasAdditionalFields && !get(formValues, 'isAllSelected') && (<Row className="offset-bottom-4">
            {get(formValues, 'assessmentLevel.name') === 'Technology' && (<Col xs={12}>
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="technologyCategories"
                    type={ENTITY_TYPES.TECH_CATEGORIES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TITLE')} </strong>)}
                        />
            </Col>)}
            {get(formValues, 'assessmentLevel.name') === 'System' && (<Col xs={12}>
                <SelectEntities
                    isMulti
                    name="systems"
                    disabled={disabled}
                    type={ENTITY_TYPES.SYSTEMS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('SYSTEMS$TITLE')} </strong>)}
                        />
            </Col>)}
            {get(formValues, 'assessmentLevel.name') === 'Process' && (<Col xs={12}>
                <SelectEntities
                    isMulti
                    name="processes"
                    disabled={disabled}
                    type={ENTITY_TYPES.PROCESSES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('PROCESSES$TITLE')} </strong>)}
                        />
            </Col>)}
        </Row>)}
        <Row className="offset-bottom-2">
            <Col xs={12}>
                <Field
                    row
                    name="relationToRequirementType"
                    component={Radio}
                    disabled={disabled}
                    onChange={(e, value)=>changeRequirementType(e, value)}
                    // label={translate('label label label')}
                    options={Object.keys(REQUIREMENT_TYPE).map(item=> ({value: item.toString(), label: filters.humanize(item)}))}
                />
            </Col>
        </Row>
        {formValues.relationToRequirementType === REQUIREMENT_TYPE.FRAMEWORKS && (<Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="assessmentTypes"
                    type={ENTITY_TYPES.SECURITY_FRAMEWORKS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('SECURITY_FRAMEWORKS$TITLE')} </strong>)}
                    // onChange={() => changeFrameworks()}
                    getOptionLabel={option => `${get(option, 'name', '')}`}
                />
            </Col>
        </Row>)}
        {formValues.relationToRequirementType === REQUIREMENT_TYPE.REQUIREMENTS && (<Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="securityRequirements"
                    // additionalFilters={{assessmentLevelId: get(formValues, 'assessmentLevel.id')}}
                    // NOTE implemented defaultOptions for dynamically update the list of securityRequirements by assessmentLevel.id
                    defaultOptions={securityRequirements}
                    type={ENTITY_TYPES.SECURITY_REQUIREMENTS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('SECURITY_REQUIREMENTS$TITLE')} </strong>)}
                    onChange={(e, newValue, prevValue)=>changeRequirements(e, newValue, prevValue)}
                    getOptionLabel={option => `${get(option, 'code', '')}.${get(option, 'securityControlName.name', '')}`}
                />
            </Col>
        </Row>)}
        <RequirementMapping />
        <Row className="offset-bottom-8"><Col xs={12}> <FieldArray name="tasks" component={TasksManagement} /> </Col></Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_ASSESSMENTS_CREATE' : 'BUTTON_ASSESSMENTS_SAVE')} />
                <ResetBtn onClick={resetForm} disabled={pristine||disabled} className="offset-right-2"  hint={findHint(hints, `BUTTON_ASSESSMENTS_RESET`)} />
                <CancelBtn onClick={cancel}  hint={findHint(hints, 'BUTTON_ASSESSMENTS_CANCEL')} />
            </Col>
        </Row>
    </form>)
})));

const RequirementMapping = connect(
    state => ({requirementsMapping: state.assessments.edit.requirementsMapping})
)(({requirementsMapping})=>{
    if (requirementsMapping.length) {
        return (<Row className="offset-bottom-6">
            <Col xs={12}>
                <ul>{requirementsMapping.map((item,key) => (
                    <li key={key}>
                        <h4>{get(item, 'securityRequirement.code', '')}.{get(item, 'securityRequirement.securityControlName.name', '')}</h4>
                        <ul>{(item.mapping||[]).map((item, key)=>(
                            <li key={key}>
                                <p className="offset-0">
                                    <strong>{get(item, 'assessmentType.name')}</strong>
                                </p>
                                <ul className='list-unstyled'>{(item.dependencies||[]).map((item, key)=>(
                                    <li key={key}>
                                        <Row>
                                            <Col xs={4}>{get(item, 'controlFunction.name')}</Col>
                                            <Col xs={4}>{get(item, 'controlCategory.name')}</Col>
                                            <Col xs={4}>{get(item, 'controlSubcategory.name')}</Col>
                                        </Row>
                                    </li>
                                ))}</ul>
                            </li>
                         ))}
                        </ul>
                    </li>
                ))}</ul>
            </Col>
        </Row>);
    }
    return null;
});
