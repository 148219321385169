// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import ACTIONS from './actions';
import {PERMISSION} from '../../constants/spec';
import Preloader from '../../components/preloader';
import UploadFile from '../../components/data-import';
import ErrorMessage from '../../components/alert-error';
import {translate} from '../../services/translate.service';
import PermissionAccess from '../../components/permission-access';
import {findHint, RichHintTitle} from '../../components/hints/hints';

class Edit extends Component {
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { disabled, message, clearError, uploadFile, data, hints } = this.props;
        return (<Container fluid>
            <Row className="offset-top-10">
                <Col xs={12} md={{span:10,offset:1}} lg={{span:6,offset:3}}>
                    <Paper className="indent-8">
                        <Row>
                            <Col xs={12} className="offset-bottom-4">
                                <h2 className="text-center text-uppercase">
                                    <RichHintTitle update={ACTIONS} name={translate(`IMPORT_AND_EXPORT$GDPR_EVIDENCE_ARTICLES`)}
                                                   data={findHint(hints, `GDPR_EVIDENCE_ARTICLES_IMPORT_EXPORT_TITLE`)}/>
                                    &nbsp;<Preloader expectAnswer={disabled} type="ICON" />
                                </h2>
                            </Col>
                        </Row>
                        <ErrorMessage active message={message} onChange={clearError} />
                        {/*<Row className="text-center offset-bottom-5"> <Col xs={12}>*/}
                            {/*<DownloadLink disabled={disabled} hint={findHint(hints, `BUTTON_GDPR_EVIDENCE_ARTICLES_IMPORT_EXPORT_DOWNLOAD`)}/>*/}
                        {/*</Col> </Row>*/}
                        <Row> <Col xs={12}  className='dropzone'>
                            <PermissionAccess permission={PERMISSION.IMPORT.GDPR_EVIDENCE_ARTICLES}>
                                <UploadFile data={data} loading={disabled} uploadFile={uploadFile} />
                            </PermissionAccess>
                        </Col> </Row>
                    </Paper>
                </Col>
            </Row>
        </Container>);
    }
}

export default connect(
    state => ({
        data: state.importGdprEvidenceArticles.data,
        message: state.importGdprEvidenceArticles.errorMessage,
        disabled: state.importGdprEvidenceArticles.expectAnswer,
        hints: state.importGdprEvidenceArticles.hintsData,
    }),
    dispatch => ({
        clear: () => dispatch({type: ACTIONS.CLEAR}),
        uploadFile: file => dispatch({type: ACTIONS.UPLOAD_FILE, file}),
        clearError: () => dispatch({ type: ACTIONS.META, errorMessage: null})
    })
)(Edit)

// const DownloadLink = withDownloadLink({downloadType: DOWNLOAD_TYPES.GDPR_ARTICLES.CSV_LIST})(props => (
//     <PrimaryBtn tooltip="Download data" permission={PERMISSION.EXPORT.GDPR_EVIDENCE_ARTICLES} {...props}>
//         <i className="fa fa-download" aria-hidden="true" /> &nbsp; Download data
//     </PrimaryBtn>
// ));

