// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {GDPR_SYSTEM_COMPLIANCE} from '../../../constants/routes';
import {DrilldownBtn, Link, LogBtn, ScoringQuestionsBtn} from '../../../components/md-button';
import {formatCompliance} from '../../../services/data-formatting.service';
import {translate, withTranslation} from '../../../services/translate.service';
import {GDPR_SYSTEM_COMPLIANCE_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, data, clearError, message, hints} = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={GDPR_SYSTEM_COMPLIANCE_MAP}/>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4"> <Col xs={12}>
                        <RichHintTitle update={LIST} name={`GDPR$SYSTEM_COMPLIANCE`} expectAnswer={expectAnswer}
                                       data={findHint(hints, `GDPR_SYSTEM_COMPLIANCE_TITLE`)}/>
                    </Col> </Row>
                    <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}> <SearchFilterConnected/> </Col> </Row>
                    <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col> </Row>
                    {data.length ? (
                        <Paper> <ConnectedTable/> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>

        );
    }
}

export default connect(
    state => ({
        data: state.gdprSystemCompliance.list.data,
        hints: state.gdprSystemCompliance.list.hintsData,
        message: state.gdprSystemCompliance.list.errorMessage,
        expectAnswer: state.gdprSystemCompliance.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null})
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.gdprSystemCompliance.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter: state.gdprSystemCompliance.list.filter,
        disabled: state.gdprSystemCompliance.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('SYSTEMS$SEARCH_BY_SYSTEM')}
    />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.gdprSystemCompliance.list, hints: state.gdprSystemCompliance.list.hintsData,}),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({data, hints, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort}) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'system'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('system')}
                        >
                            {translate('SYSTEMS$SYSTEM')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'articlesNumber'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('articlesNumber')}
                        >
                            {translate('GDPR$QUESTIONS_APPLIED')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'articlesProcessed'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('articlesProcessed')}
                        >
                            {translate('GDPR$QUESTIONS_ANSWERED')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'compliance'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('compliance')}
                        >
                            {translate('GDPR$COMPLIANCE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"/>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{get(item, 'system.name')}</TableCell>
                    <TableCell>{item.articlesNumber}</TableCell>
                    <TableCell>{item.articlesProcessed}</TableCell>
                    <TableCell>{formatCompliance(item.compliance)}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={DrilldownBtn}
                            className="offset-right-2"
                            tooltip={translate('GLOBALS$DRILLDOWN')}
                            hint={findHint(hints, `BUTTON_GDPR_SYSTEM_COMPLIANCE_DRILLDOWN`)}
                            permission={PERMISSION.GDPR_SYSTEM_COMPLIANCE.ARTICLE_READ}
                            to={GDPR_SYSTEM_COMPLIANCE.LINK_DRILLDOWN({
                                systemId: get(item, 'system.id', null),
                                query: {back: history.location.pathname + history.location.search}
                            })}>
                        </Link>
                        <Link
                            Btn={ScoringQuestionsBtn}
                            className="offset-right-2 md-btn md-btn-warning"
                            hint={findHint(hints, `BUTTON_GDPR_SYSTEM_COMPLIANCE_SCORING_QUESTIONS`)}
                            tooltip={translate('SCORING_QUESTIONS$TITLE')}
                            permission={PERMISSION.GDPR_SYSTEM_COMPLIANCE.SCORING_QUESTION}
                            to={GDPR_SYSTEM_COMPLIANCE.LINK_QUESTIONS({
                                id: item.system.id,
                                query: {back: history.location.pathname + history.location.search}
                            })}
                        >
                        </Link>
                        <Link
                            Btn={LogBtn}
                            className="md-btn md-btn-warning"
                            hint={findHint(hints, `BUTTON_GDPR_SYSTEM_COMPLIANCE_STATUS_LOG`)}
                            tooltip={translate('GLOBALS$LOG')}
                            to={GDPR_SYSTEM_COMPLIANCE.LINK_SYSTEM_LOG({
                                systemId: get(item, 'system.id', null),
                                query: {back: history.location.pathname + history.location.search}
                            })}>
                        </Link>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>)));
