// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
// import { Prompt } from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {Field, FieldArray, Form, isPristine, reduxForm, submit} from 'redux-form';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {PERMISSION} from '../../../constants/spec';
import {GeoRecordsBtn, SubmitBtn} from '../../../components/md-button';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import {ProtectedInput} from '../../../components/md-input';
import MdTablePagination from '../../../components/pagination';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import {translate, withTranslation} from '../../../services/translate.service';
import separateService from '../../../services/separate-with-comma.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {DATA_EXFILTRATION_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import GEO_RECORDS_DIALOG from '../../../components/geo-records-processed-dialog/actions';

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, list, update, pristine, hints} = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={DATA_EXFILTRATION_MAP}/>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <RichHintTitle
                                update={LIST}
                                name={'DATA_EXFILTRATION$TITLE'}
                                expectAnswer={expectAnswer}
                                data={findHint(hints, 'DATA_EXFILTRATION_TITLE')}
                            />
                        </Col>
                        <Col xs={3} className="text-right top-indent-4 align-middle">
                            {!pristine && <strong className="text-danger h4">
                                <i className="fa fa-exclamation-triangle"
                                   aria-hidden="true"/> {translate('GLOBALS$UNSAVED_DATA')}
                            </strong>}&nbsp;
                            <SubmitBtn
                                hint={findHint(hints, 'BUTTON_DATA_EXFILTRATION_SAVE')}
                                onClick={update}
                                disabled={pristine || expectAnswer}
                                permission={PERMISSION.DATA_EXFILTRATION.UPDATE}
                            />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected/>
                        </Col>
                    </Row>
                    {/*NOTE block navigating away from a page and show message*/}
                    {/*<Prompt when={!pristine} message={translate('GLOBALS$UNSAVED_DATA_MESSAGE')} />*/}
                    <ConnectedError/>
                    {list.length ? (
                        <Paper> <ConnectedTable/> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        list: state.dataExfiltration.list.data,
        pristine: isPristine('dataExfiltration')(state),
        expectAnswer: state.dataExfiltration.list.expectAnswer,
        hints: state.dataExfiltration.list.hintsData,
    }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        update: () => dispatch(submit('dataExfiltration')),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.dataExfiltration.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter: state.dataExfiltration.list.filter,
        disabled: state.dataExfiltration.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION')}
    />
)));

const ConnectedTable = withTranslation(connect(
    state => ({
        ...state.dataExfiltration.list,
        initialValues: {exfiltrations: state.dataExfiltration.list.data}
    }),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
        update: ({exfiltrations}) => dispatch({type: LIST.SAVE, exfiltrations})
    })
)(reduxForm({form: 'dataExfiltration', enableReinitialize: 'true'})(
    ({
        page,
        size,
        totalPages,
        sortF,
        sortD,
        expectAnswer,
        changePage,
        changeSize,
        changeSort,
        handleSubmit,
        update
    }) => (
        <Form autoComplete="off" name="dataExfiltration" onSubmit={handleSubmit(update)}>
            <div style={{overflowX: 'auto'}}>
                <Table className="md-table" padding="checkbox">
                    <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                        <TableRow style={{height: 48}}>
                            <TableCell className="th">
                                <TableSortLabel
                                    active={sortF === 'name'}
                                    direction={sortD ? 'asc' : 'desc'}
                                    onClick={() => changeSort('name')}
                                >
                                    {translate('SYSTEMS$SYSTEM')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="th">
                                <TableSortLabel
                                    active={sortF === 'businessUnit'}
                                    direction={sortD ? 'asc' : 'desc'}
                                    onClick={() => changeSort('businessUnit')}
                                >
                                    {translate('BUSINESS_UNITS$BUSINESS_UNIT')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="th">
                                <TableSortLabel
                                    direction={sortD ? 'asc' : 'desc'}
                                    active={sortF === 'dataAssetClassification'}
                                    onClick={() => changeSort('dataAssetClassification')}
                                >
                                    {translate('ASSET_CLASSES$ASSET_CLASS')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="th"/>
                            <TableCell className="th" style={{width: '200px'}}>
                                <TableSortLabel
                                    active={sortF === 'numberOfRecProcessed'}
                                    direction={sortD ? 'asc' : 'desc'}
                                    onClick={() => changeSort('numberOfRecProcessed')}
                                >
                                    {translate('SYSTEMS$RECORDS_PROCESSED')}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {/*NOTE list items with input fields*/}
                    <FieldArray name="exfiltrations" component={Items}/>
                </Table>
            </div>
            <MdTablePagination
                page={page}
                size={size}
                disabled={expectAnswer}
                totalPages={totalPages}
                changeSize={changeSize}
                changePage={changePage}
            />
        </Form>
    ))));

const ConnectedError = connect(
    state => ({message: state.dataExfiltration.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: LIST.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const Items = withTranslation(connect(
    state => ({disabled: state.dataExfiltration.list.expectAnswers, hints: state.dataExfiltration.list.hintsData}),
    dispatch => ({
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
        openGeoRecordsProcessedModal: item => dispatch({type: GEO_RECORDS_DIALOG.INITIALIZE, item}),
    })
)(({fields, disabled, openGeoRecordsProcessedModal, hints}) => (
    <TableBody>
        {fields.map((mKey, i) => {
            let item = fields.get(i);
            return (<TableRow style={{height: 48}} key={i}>
                <TableCell>{get(item, 'name')}</TableCell>
                <TableCell>{formatBusinessUnitLabel(get(item, 'businessUnit'))}</TableCell>
                <TableCell>{get(item, 'dataAssetClassification.name')}</TableCell>
                <TableCell className="text-nowrap" align="right">
                    <GeoRecordsBtn
                        className="offset-right-2"
                        onClick={() => openGeoRecordsProcessedModal(item)}
                        hint={findHint(hints, 'BUTTON_DATA_EXFILTRATION_GEO_RECORDS_PROCESSED')}
                    >
                    </GeoRecordsBtn>
                </TableCell>
                <TableCell>
                    <Field
                        type="text"
                        disabled={disabled}
                        component={ProtectedInput}
                        style={{maxWidth: '250px'}}
                        classes={{input: 'text-right'}}
                        name={`${mKey}.numberOfRecProcessed`}
                        // normalize={value => value && Number(value)}
                        normalize={separateService.normalize}
                        permission={PERMISSION.DATA_EXFILTRATION.UPDATE}
                        placeholder={translate('DATA_EXFILTRATION$RECORDS_PROCESSED')}
                    />
                </TableCell>
            </TableRow>);
        })}
    </TableBody>
)));
