
// outsource dependencies
import {change, reset} from 'redux-form';
import { toastr } from 'react-redux-toastr';
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import GEO_RECORDS from './actions';
import { instanceAPI } from '../../services/api.service';
import {LIST} from '../../private-layout/data-exfiltration/actions';
import separateService from "../../services/separate-with-comma.service";
import {translate} from "../../services/translate.service";
import getHintsList, {GEO_RECORDS_PROCESSED_HINTS} from '../../services/hints.service';

// configure
const FORM_NAME = 'geoRecordsForm';

export const changeField = (field, value) => change(FORM_NAME, field, value);

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(GEO_RECORDS.CANCEL, cancelSaga);
    yield takeEvery(GEO_RECORDS.REASSIGN, reassignSaga);
    yield takeEvery(GEO_RECORDS.INITIALIZE, initializeSaga);
    yield takeEvery(GEO_RECORDS.SETUP_COUNTRY.REQUEST, setupCountrySaga);
    yield takeEvery(GEO_RECORDS.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({item}) {
    yield put({type: GEO_RECORDS.CLEAR});
    yield put({type: GEO_RECORDS.META, showModal: true});
    yield put({type: GEO_RECORDS.DATA, data: item });
    // const { geoRecordsProcessed } = item;
    yield put({type: GEO_RECORDS.GET_HINTS_DATA.REQUEST});
    yield put({type: GEO_RECORDS.META, initialized: true});
}

function* reassignSaga ({type, ...options}) {
    yield put({type: GEO_RECORDS.META, expectAnswer: true});
    yield call(separateService.convert, options);
    try {
        yield call(updateSystemsData, options);
        yield put({type: LIST.GET_DATA.REQUEST});
        yield put({type: GEO_RECORDS.CANCEL});
        yield call(toastr.success, translate('DATA_EXFILTRATION$TITLE'), translate('GLOBALS$SUCCESSFUL_DATA_UPDATE'));
    } catch ( {message} ) {
        yield put({type: GEO_RECORDS.META, errorMessage: message, expectAnswer: false});
    }
}

function* cancelSaga () {
    yield put({type: GEO_RECORDS.CLEAR});
    yield put(reset(FORM_NAME));
}

function* setupCountrySaga ({countryId, mKey}) {
    yield put({type: GEO_RECORDS.META, expectAnswer: true, states: []});
    try {
        // NOTE clear old field values
        yield put(changeField(`${mKey}.state`, null));
        yield put(changeField(`${mKey}.numberOfRecProcessed`, null));
        // NOTE get list of states for chosen country
        let states = yield call(getStates, countryId);
        yield put({type: GEO_RECORDS.META, expectAnswer: false, states});
    }
    catch ({message}) {
        yield put({type: GEO_RECORDS.META, expectAnswer: false, errorMessage: message});
    }
    yield put({type: GEO_RECORDS.SETUP_COUNTRY.FINISH});
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, GEO_RECORDS_PROCESSED_HINTS);
        // NOTE setup hints data
        yield put({type: GEO_RECORDS.META, hintsData});
    } catch ( {message} ) {
        yield put({type: GEO_RECORDS.META, errorMessage: message});
    }
    yield put({type: GEO_RECORDS.GET_HINTS_DATA.FINISH});
}

/**
 * get list of states for current country
 * @param {Number} countryId
 * @private
 */
function getStates( countryId ) {
    return new Promise( ( resolve, reject ) => {
        instanceAPI({ method: 'post', url: '/states/filter', data: { filter: { countryId } } })
            .then( ({items}) => resolve(items) ).catch(reject);
    });
}

/**
 * update system
 * @param {Object} data
 * @private
 */
function updateSystemsData ( data ) {
    return instanceAPI({method: 'put', data, url: `/systems/data-exfiltration`, });
}
