
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, TablePagination } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, LastPage, FirstPage } from '@mui/icons-material';

// local dependencies
import { DIRECTION } from '../constants/spec';
import { translate, withTranslation } from '../services/translate.service';

// configure
Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    changeSize: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    totalPages: PropTypes.number,
    disabled: PropTypes.bool,
    sizes: PropTypes.array,
    label: PropTypes.node,
};
export default Pagination;

/**
 * Table pagination
 *
 * @param {Object} props
 * @public
 */
function Pagination ({ page, size, disabled, label, totalPages, changePage, changeSize, sizes = [10, 20, 50, 100] }) {
    let maxItemsCount = totalPages * size;
    // console.log('Size:', size, ', Total Pages:', totalPages)
    return (
        <TablePagination
            component="div"
            className='pagination-custom'
            count={maxItemsCount}
            page={totalPages <= 0 ? 0 : page}
            rowsPerPage={size}
            disabled={disabled}
            rowsPerPageOptions={sizes}
            ActionsComponent={PageActions}
            labelDisplayedRows={PageLabels(size)}
            // unknown reason material pagination duplicate firing function without event
            onPageChange={(e, page) => e && changePage(page)}
            onRowsPerPageChange={e => changeSize(e.target.value)}
            SelectProps={{ disabled, renderValue: value => (<strong>&nbsp;{value}&nbsp;&nbsp;</strong>) }}
            labelRowsPerPage={label||(<strong><span className="text-uppercase"> {translate("PAGINATION$SIZE")} </span></strong>)}
                />
      );
}

/**
 * Pagination actions
 *
 * @param {Object} props
 * @public
 */
const PageLabels = function (rowsPerPage) {
    return (props) => {
        let { count, page } = props;
        let pagesCount = rowsPerPage ? count / rowsPerPage : 0;
        // console.log('PageLabels info:', props, 'Page Size:', rowsPerPage);
        return (<>
            {translate(
                "PAGINATION$PAGES_LABEL",
                <Tooltip title={translate("PAGINATION$CURRENT_PAGE")} placement="bottom" enterDelay={300} >
                    <strong>{page + 1}</strong>
                </Tooltip>,
                <Tooltip title={translate("PAGINATION$TOTAL_PAGES")} placement="bottom" enterDelay={300}>
                    <strong>{pagesCount}</strong>
                </Tooltip>
            )}
        </>);
    };
}

/**
 * Pagination actions
 *
 * @param {Object} props
 * @public
 */
const PageActions = withTranslation( ({ direction, className, count, page, onPageChange , disabled, rowsPerPage }) => {
    let pagesCount = rowsPerPage ? count / rowsPerPage : 0;
    let disableNext = disabled || page >= (pagesCount - 1);
    let disablePrev = disabled || page === 0;
    return (<div className={className}>
        <Tooltip title={translate("PAGINATION$FIRST_PAGE")} placement="bottom" enterDelay={300}><span>
            <IconButton onClick={e => onPageChange (e, 0)} disabled={disablePrev} aria-label={translate("PAGINATION$FIRST_PAGE")}>
                 {direction === DIRECTION.RTL ? <LastPage /> :  <FirstPage />}
            </IconButton>
        </span></Tooltip>
        <Tooltip title={translate("PAGINATION$PREVIOUS_PAGE")} placement="bottom" enterDelay={300}><span>
            <IconButton onClick={e => onPageChange (e, page - 1)} disabled={disablePrev} aria-label={translate("PAGINATION$PREVIOUS_PAGE")}>
                {direction === DIRECTION.RTL ? <KeyboardArrowRight /> :  <KeyboardArrowLeft />}
            </IconButton>
        </span></Tooltip>
        <Tooltip title={translate("PAGINATION$NEXT_PAGE")} placement="bottom" enterDelay={300}><span>
            <IconButton onClick={e => onPageChange (e, page + 1)} disabled={disableNext} aria-label={translate("PAGINATION$NEXT_PAGE")}>
                {direction === DIRECTION.RTL ? <KeyboardArrowLeft /> :  <KeyboardArrowRight />}
            </IconButton>
        </span></Tooltip>
        <Tooltip title={translate("PAGINATION$LAST_PAGE")} placement="bottom" enterDelay={300}><span>
            <IconButton onClick={e => onPageChange (e, pagesCount - 1)} disabled={disableNext} aria-label={translate("PAGINATION$LAST_PAGE")}>
                {direction === DIRECTION.RTL ? <FirstPage /> :  <LastPage />}
            </IconButton>
        </span></Tooltip>
    </div>);
});
