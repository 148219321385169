// outsource dependencies
import React from 'react';
import {Outlet} from 'react-router-dom';

// local dependencies

// nested pages

export default function ( props ) {
    return <Outlet/>
}
