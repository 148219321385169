
// outsource dependencies

// local dependencies

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
    };
})('@system-quants-domain/');
