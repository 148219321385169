
// outsource dependencies
import * as Msal from 'msal';

// local dependencies
import config from './constants';

// config
const msalConfig = {
    auth: {
        clientId: config.MICROSOFT_CLIENT_ID,
        postLogoutRedirectUri: 'http://localhost:3002/public/sign-in'
    }
};


const msalInstance = new Msal.UserAgentApplication(msalConfig);

export default msalInstance;
