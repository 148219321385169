
// outsource dependencies
import { call, put, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import ACTIONS from './actions';
import { APP } from '../../actions/types';
import { instanceAPI } from '../../services/api.service';

// configure

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(ACTIONS.INITIALIZE, initializeSaga);
    yield takeEvery(ACTIONS.DECLINE, declineSaga);
    yield takeEvery(ACTIONS.ACCEPT, acceptSaga);
}

function* initializeSaga ({type, ...options}) {
    yield put({type: ACTIONS.CLEAR});
    let data = yield call(getData, options);
    yield put({type: ACTIONS.DATA, data});
    if ( data.length ) {
        yield put({type: ACTIONS.META, showModal: true});
    }
    yield put({type: ACTIONS.META, initialized: true});
}

function* acceptSaga () {
    yield put({type: ACTIONS.META, expectAnswer: true, errorMessage: null });
    let { data, currentIndex } = yield select(state => state.agreementAlert);
    try {
        if (currentIndex < data.length - 1) {
            data[currentIndex].answer = true;
            yield put({type: ACTIONS.DATA, data: [...data]});
            yield put({type: ACTIONS.META, currentIndex: currentIndex + 1});
        } else {
            data[currentIndex].answer = true;
            yield call(update, data);
            yield put({type: ACTIONS.META, showModal: false});
        }
    }
    catch ({message}) {
        yield put({type: ACTIONS.META, errorMessage: message});
    }
    yield put({type: ACTIONS.META, expectAnswer: false});
}

function* declineSaga () {
    yield put({type: ACTIONS.META, expectAnswer: true, errorMessage: null });
    try {
        let { data, currentIndex } = yield select(state => state.agreementAlert);
        data[currentIndex].answer = false;
        yield call(update, data);
        yield put({type: ACTIONS.META, showModal: false, expectAnswer: false});
        yield put({type: APP.SIGN_OUT.REQUEST});
    }
    catch ({message}) {
        yield put({type: ACTIONS.META, errorMessage: message});
    }
    yield put({type: ACTIONS.META, expectAnswer: false});
}

/**
 * get list of agreements
 *
 * @returns {Promise}
 * @public
 */
function getData () {
    return instanceAPI({method: 'get', url: '/user-agreement/unanswered'}).catch(()=>([]));
}

function update ( data ) {
    return instanceAPI({method: 'post', url: '/user-agreement/unanswered', data});
}

