// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from './actions';
import Preloader from '../../components/preloader';
import Password from '../../components/md-password';
import ErrorMessage from '../../components/alert-error';
import {ResetBtn, SubmitBtn} from '../../components/md-button';
import {translate, withTranslation} from '../../services/translate.service';
import {findHint, RichHintTitle} from '../../components/hints/hints';

class Edit extends Component {
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, hints } = this.props;
        return (
            <Container fluid>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:6,offset:3}}>
                        <Paper className="indent-8">
                            <h2 className="text-uppercase offset-bottom-8 text-center">
                                <RichHintTitle update={EDIT}  name={translate('AUTHORIZATION$CHANGE_PASSWORD')} expectAnswer={expectAnswer} data={findHint(hints, `CHANGE_PASSWORD_TITLE`)}/>
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                            <ConnectedError />
                            <ConnectedForm />
                        </Paper>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.changePassword.expectAnswer, hints: state.changePassword.hintsData}),
    dispatch => ({ clear: () => dispatch({type: EDIT.CLEAR}) })
)(Edit)

const ConnectedError = connect(
    state => ({message: state.changePassword.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({disabled: state.changePassword.expectAnswer, hints: state.changePassword.hintsData}),
    dispatch => ({ changePassword: ({oldPassword, password}) => dispatch({type: EDIT.CHANGE_PASSWORD, oldPassword, password}) })
)(reduxForm({
    form: 'changePassword',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // old password
        if (!values.oldPassword) { errors.oldPassword = 'CHANGE_PASSWORD$OLD_PASSWORD_REQUIRED'; }
        // new password
        if (!values.password || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9])(?=.{8,}$)/.test(values.password)) {
            errors.password = 'CHANGE_PASSWORD$NEW_PASSWORD_INVALID';
        }
        // confirm password
        if ( !values.confirmPassword ) {
            errors.confirmPassword = 'CHANGE_PASSWORD$CONFIRM_PASSWORD_REQUIRED';
        } else if ( values.confirmPassword !== values.password ) {
            errors.confirmPassword = 'CHANGE_PASSWORD$CONFIRM_PASSWORD_INVALID';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, changePassword, reset, hints})=>(
    <form autoComplete="off" name="changePassword" onSubmit={handleSubmit(changePassword)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="oldPassword"
                    disabled={disabled}
                    component={Password}
                    placeholder={translate('CHANGE_PASSWORD$OLD_PASSWORD')}
                    label={(<strong> {translate('CHANGE_PASSWORD$OLD_PASSWORD')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="password"
                    disabled={disabled}
                    component={Password}
                    placeholder={translate('CHANGE_PASSWORD$NEW_PASSWORD')}
                    helpText={translate('CHANGE_PASSWORD$NEW_PASSWORD_HELP_TEXT')}
                    label={(<strong> {translate('CHANGE_PASSWORD$NEW_PASSWORD')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    name="confirmPassword"
                    disabled={disabled}
                    component={Password}
                    placeholder={translate('CHANGE_PASSWORD$CONFIRM_PASSWORD')}
                    label={(<strong> {translate('CHANGE_PASSWORD$CONFIRM_PASSWORD')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn disabled={ pristine || invalid || disabled } className="offset-right-2" hint={findHint(hints, 'BUTTON_CHANGE_PASSWORD_SAVE')} />
                <ResetBtn onClick={reset} disabled={ pristine || disabled } className="offset-right-2" hint={findHint(hints, 'BUTTON_CHANGE_PASSWORD_RESET')} />
            </Col>
        </Row>
    </form>
))));
