
// outsource dependencies

// local dependencies


export default (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        CANCEL: `${prefix}CANCEL`,
        INITIALIZE: `${prefix}INITIALIZE`,
    };
})('@preview-dialog/');
