// outsource dependencies
import get from 'lodash/get';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { CloudDownload } from '@mui/icons-material';
import { IconButton, InputLabel, Paper, Tooltip } from '@mui/material';
import { change, Field, getFormValues, reduxForm } from 'redux-form';

// local dependencies
import { EDIT } from '../actions';
import { ENTITY_TYPES, NEW_ID } from '../../../constants/spec';
import MdInput from '../../../components/md-input';
import { DOWNLOAD } from '../../../constants/routes';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import withDownloadLink from '../../../components/download-link';
import { formatFileSize } from '../../../services/data-formatting.service';
import { translate, withTranslation } from '../../../services/translate.service';
import { CancelBtn, DeleteIconBtn, ResetBtn, SubmitBtn } from '../../../components/md-button';
import { VENDOR_CONTRACT_MAP } from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import { findHint, RichHintTitle } from '../../../components/hints/hints';
import SelectEntities from '../../../components/select-entities';
import MdDatePicker from '../../../components/md-date-picker';

// config
export const FORM_NAME = 'editVendorContract';
export const changeField = (field, value) => change(FORM_NAME, field, value);
const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    let {disabled, message, clearError, data} = props;
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, []);
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={VENDOR_CONTRACT_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} lg={{span: 8, offset: 2}}>
                        <Paper className="indent-5"> <Container fluid>
                            <Row className="offset-bottom-8">
                                <Col xs={12} md={data.templateLink ? 8 : 12}>
                                    <h2 className="text-uppercase">
                                        <span>
                                            <RichHintTitle
                                                update={EDIT}
                                                name={isNew ? `VENDOR_CONTRACT$CREATE_CONTRACT` : `VENDOR_CONTRACT$EDIT_CONTRACT`}
                                                data={findHint(hints, isNew ? 'VENDOR_CONTRACT_CREATE_TITLE' : `VENDOR_CONTRACT_EDIT_TITLE`)}
                                            />
                                        </span>
                                        <Preloader expectAnswer={disabled} type="ICON"/>
                                    </h2>
                                </Col>
                            </Row>
                            <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col>
                            </Row>
                            <Row> <Col xs={12}> <ConnectedForm isNew={isNew}/> </Col> </Row>
                        </Container> </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};

export default connect(
    state => ({
        data: state.vendorContracts.edit.data,
        message: state.vendorContracts.edit.errorMessage,
        disabled: state.vendorContracts.edit.expectAnswer,
        expectAnswer: state.vendorContracts.edit.expectAnswer,
        hints: state.vendorContracts.edit.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.vendorContracts.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const DownloadLink = withTranslation(withDownloadLink()(props => (
    <Tooltip title={translate('GLOBALS$DOWNLOAD')} className="offset-bottom-1"><span>
        <IconButton {...props} color="primary" style={{padding: '5px'}} aria-label={translate('GLOBALS$DOWNLOAD')} >
            <CloudDownload fontSize="small"/>
        </IconButton>
    </span></Tooltip>
)));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.vendorContracts.edit.data,
        disabled: state.vendorContracts.edit.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
        hints: state.vendorContracts.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        removeDocument: () => dispatch(changeField('document', null)),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        if (!values.number) {
            errors.number = 'Contract number required';
        }

        return errors;
    }
})(
    ({
        handleSubmit,
        invalid,
        pristine,
        disabled,
        update,
        reset,
        isNew,
        cancel,
        formValues = {},
        removeDocument,
        hints
    }) => (
        <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-4"> <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                />
            </Col> </Row>
            <Row className="offset-bottom-4"> <Col xs={12}>
                <Field
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$DESCRIPTION')}
                    label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                />
            </Col> </Row>
            <Row className="offset-bottom-4"> <Col xs={12}>
                <Field
                    name="number"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('VENDOR_CONTRACT$CONTRACT_NUMBER')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('VENDOR_CONTRACT$CONTRACT_NUMBER')} </strong>)}
                />
            </Col>

                <Row className="offset-top-8">
                    <Col xs={6}>
                        <Field
                            disabled={disabled}
                            name="startDate"
                            component={MdDatePicker}
                            label={(<strong> Date </strong>)}
                        />
                    </Col>
                    <Col xs={6} className="offset-bottom-4">
                        <Field
                            disabled={disabled}
                            name="expiryDate"
                            component={MdDatePicker}
                            label={(<strong> Expiry date </strong>)}
                        />
                    </Col>
                </Row>


                <Col xs={12} md={6} className="offset-bottom-4">
                    <SelectEntities
                        name="vendor"
                        disabled={disabled}
                        isClearable={true}
                        type={ENTITY_TYPES.VENDORS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(
                            <strong> {translate('VENDORS$VENDOR')} </strong>)}
                    />
                </Col> </Row>

            {/*<Row className="offset-bottom-4"> <Col xs={12}>*/}
            {/*    <h4><strong>{translate('GLOBALS$DOCUMENT_COLON')} </strong></h4>*/}
            {/*    {!Boolean((get(formValues, 'document'))) ? (*/}
            {/*        <h4> {translate('GDPR$NO_DOCUMENTS')}  </h4>*/}
            {/*    ) : (*/}
            {/*        <h4>*/}
            {/*            {*/}
            {/*                get(formValues, 'document') && (*/}
            {/*                    <span>*/}
            {/*                {get(formValues, 'document.fileName')} ({formatFileSize(get(formValues, 'document.fileSize'))})&nbsp;*/}
            {/*                        {get(formValues, 'document.downloadUrl') && (*/}
            {/*                            <DownloadLink*/}
            {/*                                link={() => DOWNLOAD.EVIDENCE_DOCUMENT_LINK({downloadUrl: get(formValues, 'document.downloadUrl')})}/>*/}
            {/*                        )}*/}
            {/*                        &nbsp;<DeleteIconBtn className="offset-bottom-1" style={{padding: '5px'}}*/}
            {/*                                             onClick={() => removeDocument()}/>*/}
            {/*            </span>)*/}
            {/*            }*/}
            {/*        </h4>*/}
            {/*    )}*/}
            {/*</Col> </Row>*/}

            <Row className="offset-bottom-4">
                <Col xs={12}>
                <h4><strong>{translate('GLOBALS$DOCUMENTS_COLON')} </strong></h4>
                {!Boolean((get(formValues, 'documents.length', 0))) ? (
                    <h4>{translate('GDPR$NO_DOCUMENTS')}</h4>
                ) : (
                    <ul className="offset-0">
                        {(get(formValues, 'documents', [])).map((item, index) => (
                            <li key={index}>
                                <strong>{get(item, 'fileName')}</strong> ({formatFileSize(get(item, 'fileSize'))})&nbsp;
                                {get(item, 'downloadUrl') && (
                                    <DownloadLink link={() => DOWNLOAD.EVIDENCE_DOCUMENT_LINK({downloadUrl: get(item, 'downloadUrl')})} />
                                )}
                                &nbsp;
                                <DeleteIconBtn className="offset-bottom-1" style={{padding: '5px'}} onClick={() => removeDocument(index)} />
                            </li>
                        ))}
                    </ul>
                )}
                </Col>
            </Row>

            <Row className="offset-bottom-8"> <Col xs={12} className="dropzone"> <UploadFile/> </Col> </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                               hint={findHint(hints, `BUTTON_VENDOR_CONTRACT_SAVE`)}/>
                    <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"
                              hint={findHint(hints, `BUTTON_VENDOR_CONTRACT_RESET`)}/>
                    <CancelBtn onClick={cancel} hint={findHint(hints, `BUTTON_VENDOR_CONTRACT_CANCEL`)}/>
                </Col>
            </Row>
        </form>
    ))));

const UploadFile = withTranslation(connect(
    state => ({
        uploaded: state.vendorContracts.edit.uploaded,
        disabled: state.vendorContracts.edit.expectAnswer
    }),
    dispatch => ({uploadFile: file => dispatch({type: EDIT.UPLOAD_FILE, file})})
)(({uploadFile, uploaded, disabled, ...attr}) => (<div>
    <InputLabel htmlFor="fileUpload"> <strong> {translate('GLOBALS$DOCUMENT_UPLOAD')} </strong> </InputLabel>
    <Dropzone
        {...attr}
        id="fileUpload"
        className="dropzone"
        useFsAccessApi={false}
        disabled={!uploaded || disabled}
        accept={{
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xlsx', '.xls'],
            'application/vnd.ms-word': ['.docx', '.doc'],
            'application/pdf': ['.pdf'],
        }}
        activeClassName="dropzone-active"
        disabledClassName="dropzone-disabled"
        onDrop={(acceptedFiles, rejectedFiles) => {
            !rejectedFiles.length && uploadFile(acceptedFiles[0]);
        }}
    >
        {({getRootProps, getInputProps}) => {
            if (!uploaded) {
                return (<h3 className="text-center text-muted">{translate('GLOBALS$LOADING')}</h3>);
            }
            return (<div {...getRootProps()} className="text-center text-muted">
                <input {...getInputProps()} />
                <p style={{fontSize: '50px'}}><i className="fa fa-upload"/></p>
                <h3 className="offset-top-0">{translate('DROPZONE$DEFAULT_MESSAGE')}</h3>
            </div>);
        }}
    </Dropzone>
</div>)));
