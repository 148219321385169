// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {CLOUD_SCORING} from '../../../constants/routes';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import REASSIGN_DIALOG from '../../../components/reassign-dialog/actions';
import {getVendorTypes} from '../../../services/data-formatting.service';
import {FirstLvlQuestionsBtn, Link, ReassignBtn, SecondLvlQuestionsBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {CLOUD_SCORING_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list, hints } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ CLOUD_SCORING_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <RichHintTitle update={LIST}  name={`CLOUD_SCORING$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `CLOUD_SCORING_TITLE`)}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected />
                        </Col>
                    </Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.cloudScoring.list.expectAnswer, hints: state.cloudScoring.list.hintsData, list: state.cloudScoring.list.data}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.cloudScoring.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.cloudScoring.list.filter,
        disabled: state.cloudScoring.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
    />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.cloudScoring.list, hints: state.cloudScoring.list.hintsData}),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
        openReassignModal: item => dispatch({type: REASSIGN_DIALOG.INITIALIZE, item}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort, openReassignModal }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('GLOBALS$SYSTEM_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('VENDORS$VENDOR_TYPE')} </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('description')}
                                >
                            {translate('GLOBALS$DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'owner'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('owner')}
                                >
                            {translate('GLOBALS$OWNER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{getVendorTypes(item)}</TableCell>
                    <AbbrevTableCell content={item.description} title={item.name} length={50} minWidth={250}/>
                    <TableCell>{get(item, 'owner.fullName')}</TableCell>
                    <TableCell className="text-nowrap" align="right">
                        {!item.allowedReassign ? '' :(
                            <ReassignBtn
                                className="offset-right-2"
                                onClick={()=>openReassignModal(item)}
                                tooltip={translate('REASSIGN$REASSIGN_QUESTIONS')}
                                hint = {findHint(hints, 'BUTTON_CLOUD_SCORING_REASSIGN')}
                            >
                            </ReassignBtn>
                        )}
                        <Link
                            Btn={FirstLvlQuestionsBtn}
                            className="offset-right-2 md-btn md-btn-warning"
                            tooltip={translate('VENDORS$SCORING_QUESTIONS')}
                            permission={PERMISSION.CLOUD_SCORING.QUESTION}
                            to={CLOUD_SCORING.LINK_QUESTIONS({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            hint = {findHint(hints, 'BUTTON_CLOUD_SCORING_LEVEL_1_QUESTIONS')}
                        >
                            {translate('VENDORS$SCORING_QUESTIONS')}
                        </Link>
                        <Link
                            Btn={SecondLvlQuestionsBtn}
                            className="offset-right-2 md-btn md-btn-warning"
                            tooltip={translate('VENDORS$INTERNAL_QUESTIONS')}
                            permission={PERMISSION.CLOUD_SCORING.INTERNAL_QUESTION}
                            to={CLOUD_SCORING.LINK_QUESTIONS({id: item.id, group: 'internal', query: {back: history.location.pathname + history.location.search} })}
                            hint = {findHint(hints, 'BUTTON_CLOUD_SCORING_LEVEL_2_QUESTIONS')}
                        >
                            {translate('VENDORS$INTERNAL_QUESTIONS')}
                        </Link>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));

const ConnectedError = connect(
    state => ({message: state.vendors.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
