
// outsource dependencies
import { combineReducers } from 'redux';

// local dependencies
import list from './list/reducer';
import questions from './questions/reducer';

export default combineReducers({
    list,
    questions,
});
