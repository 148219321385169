// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {SETTINGS} from '../actions';
import MdInput from '../../../../components/md-input';
import Preloader from '../../../../components/preloader';
import {instanceAPI} from '../../../../services/api.service';
import {MdAsyncSelect} from '../../../../components/md-select';
import {ASSESSMENT_FRAMEWORK_LEVEL, ENTITY_TYPES, NEW_ID} from '../../../../constants/spec';
import SelectEntities from '../../../../components/select-entities';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../../components/md-button';
import {translate, withTranslation} from '../../../../services/translate.service';
import {useParams} from "react-router-dom";
import { Paper } from '@mui/material';
import {findHint} from '../../../../components/hints/hints';

// config
export const FORM_NAME = 'editSecurityRequirements';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    return (<ConnectedInitializer> <ConnectedForm isNew={isNew} /> </ConnectedInitializer>);
}

export default connect(
    state => ({
        message: state.securityRequirements.edit.settings.errorMessage,
        expectAnswer: state.securityRequirements.edit.settings.expectAnswer,
        hints: state.securityRequirements.edit.settings.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: SETTINGS.CLEAR}),
        initialize: id => dispatch({type: SETTINGS.INITIALIZE, id}),
        clearError: () => dispatch({ type: SETTINGS.META, errorMessage: null})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.securityRequirements.edit.settings.initialized, hints: state.securityRequirements.edit.settings.hintsData}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.securityRequirements.edit.settings.data,
        disabled: state.securityRequirements.edit.settings.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
        securityControlNames: state.securityRequirements.edit.settings.securityControlNames,
        hints: state.securityRequirements.edit.settings.hintsData,
    }),
    dispatch => ({
        cancel: () => dispatch({type: SETTINGS.CANCEL}),
        update: formData => dispatch({type: SETTINGS.UPDATE, ...formData}),
        changeControlFamily: family => dispatch({type: SETTINGS.CHANGE_CONTROL_FAMILY, family}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // code
        if (!values.code) {
            errors.code = 'GLOBALS$CODE_REQUIRED';
        }
        if (!values.programArea) {
            errors.programArea = 'SECURITY_REQUIREMENTS$PROGRAM_AREA_REQUIRED';
        }
        if (!values.securityControlFamily) {
            errors.securityControlFamily = 'SECURITY_REQUIREMENTS$SECURITY_CONTROL_FAMILY_REQUIRED';
        }
        if (!values.securityControlName) {
            errors.securityControlName = 'SECURITY_REQUIREMENTS$SECURITY_CONTROL_NAME_REQUIRED';
        }
        if (!values.assessmentLevel) {
            errors.assessmentLevel = 'ASSESSMENTS$ASSESSMENT_LEVEL_REQUIRED';
        }
        if (!values.description) {
            errors.description = 'SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT_DESCRIPTION_REQUIRED';
        }
        if (!values.detailedControlTestingProcedure) {
            errors.detailedControlTestingProcedure = 'SECURITY_REQUIREMENTS$CONTROL_TESTING_PROCEDURE_REQUIRED';
        }
        if (!values.riskStatementExamples) {
            errors.riskStatementExamples = 'SECURITY_REQUIREMENTS$RISK_STATEMENTS_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formValues={}, securityControlNames, changeControlFamily, hints})=>(
    <Paper style={{padding: '2rem'}}>
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="code"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT_CODE')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT_CODE')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="programArea"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('SECURITY_REQUIREMENTS$PROGRAM_AREA')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('SECURITY_REQUIREMENTS$PROGRAM_AREA')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    disabled={disabled}
                    name="securityControlFamily"
                    type={ENTITY_TYPES.SECURITY_CONTROL_FAMILIES}
                    onChange={(e, value) => changeControlFamily(value)}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_FAMILY')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="securityControlName"
                    component={MdAsyncSelect}
                    defaultOptions={securityControlNames}
                    placeholder={translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_NAME')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_NAME')} </strong>)}
                    disabled={disabled || !get(formValues, 'securityControlFamily.id')}
                    loadOptions={(name, done) => {
                        instanceAPI({
                            method: 'post',
                            url: 'security-control-names/filter',
                            data: { page: 0, size: 6, filter: { name, parentId: get(formValues, 'securityControlFamily.id', null) } },
                        }).then(({items})=>done(items)).catch(done.bind(null, []));
                    }}/>
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    disabled={disabled}
                    name="assessmentLevel"
                    type={ENTITY_TYPES.ASSESSMENT_LEVELS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('ASSESSMENTS$ASSESSMENT_LEVEL')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT_DESCRIPTION')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT_DESCRIPTION')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline
                    component={MdInput}
                    disabled={disabled}
                    name="detailedControlTestingProcedure"
                    placeholder={translate('SECURITY_REQUIREMENTS$CONTROL_TESTING_PROCEDURE')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('SECURITY_REQUIREMENTS$CONTROL_TESTING_PROCEDURE')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    multiline
                    component={MdInput}
                    disabled={disabled}
                    name="riskStatementExamples"
                    placeholder={translate('SECURITY_REQUIREMENTS$RISK_STATEMENTS')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('SECURITY_REQUIREMENTS$RISK_STATEMENTS')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="securityRequirementLevels"
                    isMulti={true}
                    disabled={disabled}
                    component={MdAsyncSelect}
                    placeholder="Assessment Levels"
                    getOptionLabel={(item) => {
                            return ASSESSMENT_FRAMEWORK_LEVEL[item];
                        }
                    }
                    label={(<strong> {"Assessment Levels"} </strong>)}
                    loadOptions={(name, done) => {
                        done([0, 1, 2, 3]);
                    }}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_SECURITY_REQUIREMENTS_CREATE' : 'BUTTON_SECURITY_REQUIREMENTS_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints, `BUTTON_SECURITY_REQUIREMENTS_RESET`)} />
                <CancelBtn onClick={cancel} hint={findHint(hints, `BUTTON_SECURITY_REQUIREMENTS_CANCEL`)} />
            </Col>
        </Row>
    </form>
    </Paper>
))));
