/**
 * Risk Tolerance Analysis
 *
 * @author   Eugene A. Kalosha <ekalosha@dfusiontech.com>
 * @version  0.1.1
 * @since    2023-12-12
 */
/**
 * @description method to parse url query
 * @param {String} query
 * @returns {Object}
 * @public
 */
function parseQueryParams ( query ) {
    let list = query.replace(/^[\?]/, '').split('&'), result = {}; // eslint-disable-line
    for ( let item of list ) {
        let [field, value] = item.split('=');
        result[field] = decodeURIComponent(value);
    }
    return result;
}

/**
 * @description method to format object data to url query
 * NOTE only one level of nesting
 * @param {Object} params
 * @returns {String}
 * @public
 */
function formatQueryParams ( params ) {
    let query = '';
    for ( let name in params ) {
        query += `${name}=${encodeURIComponent(String(params[name]))}&`;
    }
    // NOTE remove last "&"
    return !query ? '' : '?'+query.substring(0, query.length-1);
}

// TEST =))
// let test = { qwe: 2, bool: true, some: 'val', obj: {} };
// parseQueryParams(formatQueryParams(test));

// named export * as query
export { parseQueryParams, formatQueryParams };
//
export default {
    parse: parseQueryParams,
    format: formatQueryParams,
};
