// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {CardContent, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {instanceAPI} from "../../../services/api.service";
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import {CONTROL_CATEGORIES} from '../../../constants/routes';
import MdTablePagination from '../../../components/pagination';
import {SimpleAsyncSelect} from '../../../components/md-select';
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {CONTROL_CATEGORIES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list, hints } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ CONTROL_CATEGORIES_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <RichHintTitle update={LIST}  name={`CONTROL_TESTS$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `CONTROL_TESTS_TITLE`)}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} placement="left" to={CONTROL_CATEGORIES.LINK_EDIT()} permission={PERMISSION.CONTROL_TEST.CREATE} hint={findHint(hints, 'BUTTON_CONTROL_TESTS_ADD')}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4"><Col xs={12}> <SearchForm/> </Col></Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.controlCategories.list.expectAnswer, list: state.controlCategories.list.data, hints: state.controlCategories.list.hintsData}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.controlCategories.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search form
 *
 * @public
 */
const SearchForm = withTranslation(connect(
    state => ({ ...state.controlCategories.list }),
    dispatch => ({
        changeSearchValue: filter => dispatch({type: LIST.META, filter}),
        changeFrameworkData: data => dispatch({type: LIST.META, ...data}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ({ expectAnswer, filter, frameworkType, changeSearchValue, changeFrameworkData, disabled, applyFilter }) => (<CardContent>
    <Row>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SearchFilter
                value={filter}
                disabled={disabled}
                apply={applyFilter}
                clear={() => applyFilter('')}
                onInputChange={changeSearchValue}
                placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
            />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={frameworkType}
                disabled={expectAnswer}
                placeholder={translate('SECURITY_FRAMEWORKS$SECURITY_FRAMEWORK')}
                onChange={frameworkType => {
                    changeFrameworkData({frameworkType});
                    applyFilter(filter);
                }}
                // label={(<strong> {translate('SECURITY_FRAMEWORKS$SECURITY_FRAMEWORK')} </strong>)}
                loadOptions={(name, done) => {
                    instanceAPI({ method: 'post', url: `/assessment-types/filter`, data: {filter: {name}} })
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
</CardContent>)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.controlCategories.list, hints: state.controlCategories.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow styile={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'code'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('code')}
                                >
                            {translate('GLOBALS$CODE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('description')}
                                >
                            {translate('GLOBALS$DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'controlFunction'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('controlFunction')}
                                >
                            {translate('CONTROL_FUNCTIONS$CONTROL_FUNCTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <AbbrevTableCell content={item.description} title={item.name} length={30} minWidth={250}/>
                    <TableCell>{get(item, 'controlFunction.name')}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.CONTROL_TEST.UPDATE}
                            to={CONTROL_CATEGORIES.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            hint={findHint(hints, 'BUTTON_CONTROL_TESTS_EDIT')}
                                />
                        <DelBtn permission={PERMISSION.CONTROL_TEST.DELETE} onClick={() => deleteItem(item.id)} className="offset-left-2" hint={findHint(hints, 'BUTTON_CONTROL_TESTS_DELETE')}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));

const ConnectedError = connect(
    state => ({message: state.controlCategories.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
