// outsource dependencies
import moment from 'moment';
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

// local dependencies
import {QUAL_LIST} from '../actions';
import {config} from '../../../constants';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {QUAL_METRICS} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {QUAL_METRICS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class QualMetrics extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list, hints } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ QUAL_METRICS_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <RichHintTitle update={QUAL_LIST}  name={`SCORING_METRICS$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `SCORING_METRICS_TITLE`)}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} placement="left" to={QUAL_METRICS.LINK_EDIT()} permission={PERMISSION.QUALITATIVE_METRIC.CREATE} hint={findHint(hints, `BUTTON_SCORING_METRICS_ADD`)}/>
                        </Col>
                    </Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({ expectAnswer: state.qualMetrics.list.meta.expectAnswer, list: state.qualMetrics.list.data, hints: state.qualMetrics.list.meta.hintsData }),
    dispatch => ({
        clear: () => dispatch({ type: QUAL_LIST.CLEAR }),
        initialize: () => dispatch({ type: QUAL_LIST.INITIALIZE }),
        clearError: () => dispatch({ type: QUAL_LIST.META, errorMessage: null })
    })
)(QualMetrics);

const ConnectedInitializer = connect(
    state => ({initialize: state.qualMetrics.list.meta.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedTable = withTranslation(connect(
    state => (
        {
            list: state.qualMetrics.list.data,
            hints: state.qualMetrics.list.meta.hintsData
        }
    ),
    dispatch => ({
        deleteItem: id => dispatch({type: QUAL_LIST.DELETE_ITEM, id}),
    })
)(
    ({ list, hints, deleteItem }) => (<div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">{translate('GLOBALS$NAME')}</TableCell>
                    <TableCell className="th">{translate('GLOBALS$DESCRIPTION')}</TableCell>
                    <TableCell className="th">{translate('GLOBALS$CREATED_BY')}</TableCell>
                    <TableCell className="th">{translate('GLOBALS$CREATED')}</TableCell>
                    <TableCell className="th">{translate('GLOBALS$UPDATED_BY')}</TableCell>
                    <TableCell className="th">{translate('GLOBALS$UPDATED')}</TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.name}</TableCell>
                    <AbbrevTableCell content={item.description} title={item.name} length={80}/>
                    <TableCell>{get(item, 'metadata.createdBy.title', '')}</TableCell>
                    <TableCell>{moment(get(item, 'metadata.createdAt', Date.now())).format(config.clientTimeFormat)}</TableCell>
                    <TableCell>{get(item, 'metadata.updatedBy.title', '')}</TableCell>
                    <TableCell>{moment(get(item, 'metadata.updatedAt', Date.now())).format(config.clientTimeFormat)}</TableCell>
                    <TableCell align="right">
                        <Link Btn={EditBtn} to={QUAL_METRICS.LINK_EDIT({id: item.id})} permission={PERMISSION.QUALITATIVE_METRIC.UPDATE}  hint={findHint(hints, `BUTTON_SCORING_METRICS_EDIT`)}/>
                        <DelBtn permission={PERMISSION.QUALITATIVE_QUESTION.UPDATE} onClick={() => deleteItem(item.id)} className="offset-left-2"  hint={findHint(hints, `BUTTON_SCORING_METRICS_DELETE`)}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>)
));

const ConnectedError = connect(
    state => ({message: state.qualMetrics.list.meta.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: QUAL_LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
