
// outsource dependencies
import moment from 'moment';
import { get, isEqual } from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Info } from '@mui/icons-material';
import { Row, Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { connectModal, show } from 'redux-modal';
import { Field, getFormValues, reduxForm, fieldArrayPropTypes } from 'redux-form';
import { Paper, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions,  Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import {withStyles} from '@mui/styles'

// local dependencies
import MdInput from './md-input';
import { config } from '../constants';
import MdDatePicker from './md-date-picker';
import SelectEntities from './select-entities';
import {MdAsyncCreatableSelect} from './md-select';
import { ENTITY_TYPES } from '../constants/spec';
import { instanceAPI } from '../services/api.service';
import Button, { AddIconBtn, DeleteIconBtn } from './md-button';
import { translate, withTranslation } from '../services/translate.service';


// config
const FORM_NAME = 'TasksManagement';


/**
 * Component for Tasks management within others entities edit.
 * Allows to select one of an existing tasks or create new one and add it to entity's task list.
 * Specified to work with redux-form 'FieldArray' and requires 'fields' props
 * Excludes tasks that have been already added to current entity from full tasks list
 *
 * @param {Object} props
 * @public
 */
class TasksManagement extends Component {
    constructor (props) {
        super(props);
        this.state = {
            value: null,
            availableTasks: []
        };
        this.selectTask = this.selectTask.bind(this);
        this.getAvailableTasks = this.getAvailableTasks.bind(this);
        // NOTE fix react warnings
        let oss = this.setState.bind(this);
        this.setState = ( ...args ) => !this.isUnmauonted&&oss(...args);
    }

    componentDidMount () {
        //NOTE first available tasks request
        this.getAvailableTasks();
    };

    // NOTE fix react warnings
    componentWillUnmount() { this.isUnmauonted = true; }

    componentDidUpdate (prevProps) {
        let prevTasks = prevProps.fields.getAll() || [];
        let currTasks =  this.props.fields.getAll() || [];
        if (!isEqual(prevTasks, currTasks)) {
            //NOTE update available tasks list
            this.getAvailableTasks();
        }

    }

    getAvailableTasks () {
        let { fields } = this.props;
        let excludeIds = fields.length ? fields.getAll().map(i=>i.id) : [];
        instanceAPI({method: 'post', url: `/tasks/filter`, data: {filter: {excludeIds}} })
            .then(({items}) => this.setState({availableTasks: items}))
            .catch(() => this.setState({availableTasks: []}));
    }

    selectTask (task) {
        this.props.fields.push(task);
        // NOTE clear value
        this.setState({value: null});
    }

    createTask (name) {
        this.setState({disabled: true});
        let { user } = this.props;
        let data = {
            name: name,
            taskAssignee: user,
            taskManager: user,
        };
        instanceAPI({ method: 'post', url: '/tasks', data})
            .then(task => {
                this.props.fields.push(task);
                toastr.success(translate('TASKS$TITLE'), translate('GLOBALS$SUCCESSFUL_ITEM_CREATE'));
                this.setState({disabled: false});
            })
            .catch(({message}) => {
                toastr.error(message);
                this.setState({disabled: false})
            })
    }

    render () {
        let { fields, meta, openDialog } = this.props;
        let { value, availableTasks } = this.state;
        let excludeIds = fields.length ? fields.getAll().map(i=>i.id) : [];
        return (<Paper className="indent-2">
            {/*NOTE connecting dialog for create and add new task */}
            <ConnectedCreateTaskDialog update={fields.push}/>
            <h3 className="text-uppercase">{translate('TASKS_MANAGEMENT$TITLE')}</h3>
            <p>{translate('TASKS_MANAGEMENT$DESCRIPTION')}</p>
            {!meta.error ? '' : (<h4 className="text-center is-invalid"><span className="form-text"> { translate(meta.error) } </span></h4>)}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {/*<SimpleAsyncSelect*/}
                    {/*value={value}*/}
                    {/*fullWidth={false}*/}
                    {/*onChange={this.selectTask}*/}
                    {/*defaultOptions={availableTasks}*/}
                    {/*placeholder={translate('TASKS_MANAGEMENT$SELECT_TASK')}*/}
                    {/*loadOptions={(name, done) => {*/}
                        {/*instanceAPI({method: 'post', url: `/tasks/filter`, data: {filter: {name, excludeIds}} })*/}
                            {/*.then(({items}) => done(items)).catch(done.bind(null, []));*/}
                    {/*}}/>*/}
                <Field
                    value={value}
                    name=""
                    disabled={false}
                    defaultOptions={availableTasks}
                    onChange={this.selectTask}
                    component={MdAsyncCreatableSelect}
                    onCreateOption={name => this.createTask(name)}
                    placeholder={translate('TASKS_MANAGEMENT$PLACEHOLDER')}
                    // label={(<strong> {translate('TASKS$PROJECT')} </strong>)}
                    getNewOptionData={(inputValue, optionLabel)=> ({id: inputValue, name: optionLabel, __isNew__: true})}
                    loadOptions={(name, done) => {
                        instanceAPI({method: 'post', url: `/tasks/filter`, data: {filter: {name, excludeIds}} })
                            .then(({items}) => done(items)).catch(done.bind(null, []));
                    }}/>
                &nbsp;&nbsp;&nbsp;
                <AddIconBtn tooltip={translate('TASKS_MANAGEMENT$CREATE_TASK')} onClick={openDialog} />
            </div>
            {Boolean(fields.length) && (<div style={{overflowX: 'auto', marginBottom: 15}}>
                <Table className="md-table" padding="checkbox">
                    <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                        <TableRow style={{height: 48}}>
                            <TableCell className="th"> {translate('GLOBALS$NAME')} </TableCell>
                            <TableCell className="th"> {translate('TASKS$MANAGER')} </TableCell>
                            <TableCell className="th"> {translate('TASKS$ASSIGNEE')} </TableCell>
                            <TableCell className="th" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((mKey, i) => {
                            let item = fields.get(i);
                            return (<TableRow style={{height: 48}} key={i}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{get(item, 'taskManager.fullName')}</TableCell>
                                <TableCell>{get(item, 'taskAssignee.fullName')}</TableCell>
                                <TableCell align="right" className="text-nowrap">
                                    <Tooltip title={<TaskInfo data={item} />}><span>
                                        <IconButton color="primary" placement="bottom"> <Info /> </IconButton>
                                    </span></Tooltip>
                                    <DeleteIconBtn onClick={() => fields.remove(i)} />
                                </TableCell>
                            </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </div>)}
        </Paper>);
    }
}

export default withTranslation(connect(
    state => ({
            user: state.app.user,
    }),
    dispatch => ({
        openDialog: () => dispatch(show(FORM_NAME)),
    })
)(TasksManagement));

/* propTypes */
TasksManagement.propTypes = fieldArrayPropTypes;

/**
 * component to show more task info as tooltip
 *
 * @param {Object} props
 * @public
 */
const TaskInfo = ({data}) => {
    return (<div>
        <p className="offset-0">
            <strong>{translate('TASKS$ESTIMATED_START_DATE_COLON')} </strong>
            {get(data, 'estimatedStartDate') ? moment(get(data, 'estimatedStartDate')).format(config.clientTimeFormat) : '-'}
        </p>
        <p className="offset-0">
            <strong>{translate('TASKS$ESTIMATED_END_DATE_COLON')} </strong>
            {get(data, 'estimatedEndDate') ? moment(get(data, 'estimatedEndDate')).format(config.clientTimeFormat) : '-'}
        </p>
        <p className="offset-0">
            <strong>{translate('TASKS$ACTUAL_START_DATE_COLON')} </strong>
            {get(data, 'actualStartDate') ? moment(get(data, 'actualStartDate')).format(config.clientTimeFormat) : '-'}
        </p>
        <p className="offset-0">
            <strong>{translate('TASKS$ACTUAL_END_DATE_COLON')} </strong>
            {get(data, 'actualEndDate') ? moment(get(data, 'actualEndDate')).format(config.clientTimeFormat) : '-'}
        </p>
    </div>);
};


/**
 * dialog component for creating new task and adding it to the tasks mapping list
 *
 * NOTE: use separate form to prevent immediate item update.
 * Instead we need to allow user change and then save data or stop editing
 * Don't use <form /> cause nested forms are not available. Use 'handleSubmit' manually.
 *
 * @param {Object} props
 * @public
 */
class CreateTaskDialog extends Component {
    constructor () {
        super();
        this.state = {
            disabled: false
        }
    }

    createTask (data) {
        this.setState({disabled: true});
        let { update, handleHide } = this.props;
        instanceAPI({ method: 'post', url: '/tasks', data})
            .then(data => {
                update(data);
                toastr.success(translate('TASKS$TITLE'), translate('GLOBALS$SUCCESSFUL_ITEM_CREATE'));
                this.setState({disabled: false});
                handleHide();
            })
            .catch(({message}) => {
                toastr.error(message);
                this.setState({disabled: false})
            })
    }

    render () {
        let { show, classes, handleSubmit, handleHide, invalid, formValues = {} } = this.props;
        let { disabled } = this.state;
        return (
            <Dialog
                open={show}
                onClose={(event, reason) => {
                    if(reason === "backdropClick" && reason === "escapeKeyDown") return false;
                    handleHide();
                }}
                classes={{paper: classes.paper}}
                aria-label={translate('TASKS_MANAGEMENT$CREATE_TASK')}
                    >
                <DialogTitle> {translate('TASKS_MANAGEMENT$CREATE_TASK')} </DialogTitle>
                <DialogContent style={{overflow: 'visible'}}>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <Field
                                name="name"
                                id="taskName"
                                component={MdInput}
                                disabled={disabled}
                                placeholder={translate('GLOBALS$NAME')}
                                 required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <SelectEntities
                                name="taskManager"
                                disabled={disabled}
                                type={ENTITY_TYPES.USERS}
                                placeholder={translate('TASKS$MANAGER')}
                                getOptionLabel={option => get(option, 'fullName')}
                                label={(<strong> {translate('TASKS$MANAGER')} </strong>)}
                                    />
                        </Col>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <SelectEntities
                                name="taskAssignee"
                                disabled={disabled}
                                type={ENTITY_TYPES.USERS}
                                placeholder={translate('TASKS$ASSIGNEE')}
                                getOptionLabel={option => get(option, 'fullName')}
                                label={(<strong> {translate('TASKS$ASSIGNEE')} </strong>)}
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <Field
                                disabled={disabled}
                                component={MdDatePicker}
                                name="estimatedStartDate"
                                inputProps={{max: get(formValues, 'estimatedEndDate')}}
                                label={(<strong> {translate('TASKS$ESTIMATED_START_DATE')} </strong>)}
                                    />
                        </Col>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <Field
                                disabled={disabled}
                                name="estimatedEndDate"
                                component={MdDatePicker}
                                inputProps={{min: get(formValues, 'estimatedStartDate')}}
                                label={(<strong> {translate('TASKS$ESTIMATED_END_DATE')} </strong>)}
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <Field
                                disabled={disabled}
                                name="actualStartDate"
                                component={MdDatePicker}
                                inputProps={{max: get(formValues, 'actualEndDate')}}
                                label={(<strong> {translate('TASKS$ACTUAL_START_DATE')} </strong>)}
                                    />
                        </Col>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <Field
                                disabled={disabled}
                                name="actualEndDate"
                                component={MdDatePicker}
                                inputProps={{min: get(formValues, 'actualStartDate')}}
                                label={(<strong> {translate('TASKS$ACTUAL_END_DATE')} </strong>)}
                                    />
                        </Col>
                    </Row>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="text"
                        disabled={disabled || invalid}
                        onClick={handleSubmit(data => this.createTask(data))}
                            >
                        {translate('GLOBALS$APPLY')}
                    </Button>
                    <Button variant="text" onClick={handleHide}> {translate('GLOBALS$CANCEL')} </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const ConnectedCreateTaskDialog = withStyles({ paper: { minWidth: 700, overflow: 'visible' } }) (
    // dialog HOC
    connectModal({name: FORM_NAME})(
        // form HOC
        reduxForm({
            form: FORM_NAME,
            validate: values => {
                let errors = {};
                if (!values.name) {
                    errors.name = 'GLOBALS$NAME_REQUIRED'
                }
                return errors;
            }
        })(
            // connect HOC
            connect(state => ({formValues: getFormValues(FORM_NAME)(state)}), null)(
                CreateTaskDialog
            )
        )
    )
);
