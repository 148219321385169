
// outsource dependencies
import { get } from 'lodash';

// local dependencies
import { is } from './is.service';
import { PERMISSION } from '../constants/spec';

export const NUMBER_FORMAT_CURRENCY = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const NUMBER_FORMAT_PERCENT = new Intl.NumberFormat('en-US', {
    style: 'percent',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const NUMBER_FORMAT_GENERAL_2DIGITS = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

/**
 * @description method to format business unit label including its parents name
 * @param {Object} data
 * @returns {String}
 * @public
 */
export function formatBusinessUnitLabel (data) {
    data = data || {};
    let label = data.name;
    let parent = get(data, 'parent'), parentNames=[];
    while ( parent ) {
        parentNames.push(get(parent, 'name', ''));
        parent = get(parent, 'parent', null);
    }
    if ( parentNames.length ) {
        parentNames = parentNames.join('/');
        label = label + '/' + parentNames;
    }
    return label;
}

/**
 * Obtain Technology Vendor label
 *
 * @param item
 * @returns {String}
 */
export function formatTechnologyLabel(item) {
    let result = item.name;

    if (item.technologyCategory) {
        result = item.technologyCategory.name + ' - ' + result;
    }

    return result;
}

/**
 * @description method to convert 'PERMISSION' constant to array
 * @returns {Array}
 * @public
 */
export function getAllPermissions () {
    let permissions = [];
    for (let NAME in PERMISSION) {
        let GROUP = PERMISSION[NAME];
        for (let SUBNAME in GROUP) {
            permissions.push(GROUP[SUBNAME]);
        }
    }
    return permissions;
}

/**
 * format vendor types string
 * @param { Object } vendor
 * @returns { String }
 * @public
 */
export function getVendorTypes (vendor = {}) {
    let vendorTypes = [];
    if (get(vendor, 'isSystemVendor')) {vendorTypes.push('System');}
    if (get(vendor, 'isServiceVendor')) {vendorTypes.push('Service');}
    if (get(vendor, 'isTechnologyVendor')) {vendorTypes.push('Technology');}
    return vendorTypes.join(', ');
}

/**
 * @description method to format file size(in bytes) to human readable string
 * @param {Number} size
 * @returns {String}
 * @public
 */
export function formatFileSize (size) {
    if (!is._number(size)) {
        console.log(`%c formatFileSize Invalid size "${size}!"`, 'color: #fff; background: #a41e22; font-size: 18px;');
        return '';
    }
    let i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
}

/**
 * @description method to format compliance string
 * @param {Number} compliance
 * @returns {String}
 * @public
 */
export function formatCompliance(compliance) {  
    // TODO -  need to check if formatCompliance is needed in the app
    return formatPercent(compliance, true);
}


/**
 * @description method to format percent string
 *
 * @param {Number} percentValue
 * @param {Boolean} normalize
 * @returns {String}
 * @public
 */
export function formatPercent(percentValue, normalize) {
    if (!is._number(percentValue)) {
        return '';
    }

    if (normalize) {percentValue = percentValue / 100;}

    return percentValue ? NUMBER_FORMAT_PERCENT.format(percentValue) : '';
}
