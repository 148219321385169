
// outsource dependencies
import chunk from 'lodash/chunk';
import remove from 'lodash/remove';
import orderBy from 'lodash/orderBy';
import { call, put, takeEvery, take, select,delay } from 'redux-saga/effects';

// local dependencies
import { LIST } from '../actions';
import { history } from '../../../store';
import list from '../../../constants/mock';
import is from '../../../services/is.service';
import { ANSWERED_TESTS } from '../../../constants/routes';
import queryService from '../../../services/query.service';
import CONFIRM_DIALOG from '../../../components/confirmation-dialog/actions';

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(LIST.INITIALIZE, initializeSaga);
    yield takeEvery(LIST.DELETE_ITEM, deleteItemSaga);
    yield takeEvery(LIST.CHANGE_PAGE, changePageSaga);
    yield takeEvery(LIST.CHANGE_SIZE, changeSizeSaga);
    yield takeEvery(LIST.CHANGE_SORT, changeSortSaga);
    yield takeEvery(LIST.GET_DATA.REQUEST, getDataSaga);
}

function* initializeSaga () {
    yield put({type: LIST.CLEAR});
    // NOTE try to restore data from url
    let params = queryService.parse(history.location.search);
    // NOTE validate query params
    let result = yield call(validateParams, params);
    yield put({type: LIST.META, ...result});
    // NOTE get list
    yield put({type: LIST.GET_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    yield put({type: LIST.META, initialized: true});
}

function* getDataSaga () {
    try {
        let options = yield select( state => state.answeredTests.list );
        // TODO remove. only for mock
        yield delay(200);
        let {data, totalPages} = yield call(getList, options);
        // NOTE setup data
        yield put({type: LIST.DATA, data});
        yield put({type: LIST.META, totalPages});
        // NOTE update location
        yield call(updateLocation, options);
    } catch ( {message} ) {
        yield put({type: LIST.META, errorMessage: message});
    }
    yield put({type: LIST.GET_DATA.FINISH});
}

function* changePageSaga ({page}) {
    yield put({type: LIST.META, expectAnswer: true, page});
    // NOTE get list
    yield put({type: LIST.GET_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    yield put({type: LIST.META, expectAnswer: false});
}

function* changeSizeSaga ({size}) {
    yield put({type: LIST.META, expectAnswer: true, size, page: 0});
    // NOTE get list
    yield put({type: LIST.GET_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    yield put({type: LIST.META, expectAnswer: false});
}

function* changeSortSaga ({field}) {
    yield put({type: LIST.META, expectAnswer: true});
    let { sortF, sortD } = yield select( state => state.answeredTests.list );
    // NOTE change direction if field has already chosen
    sortD = field === sortF ? !sortD : true;
    yield put({type: LIST.META, sortF: field, sortD, page: 0});
    // NOTE get list
    yield put({type: LIST.GET_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    yield put({type: LIST.META, expectAnswer: false});
}

function* deleteItemSaga ({type, ...options}) {
    // NOTE ask confirmation of deleting
    yield put({type: CONFIRM_DIALOG.REQUEST, message: 'Are you sure you want to delete this item?'});
    let answer = yield take([CONFIRM_DIALOG.SUCCESS, CONFIRM_DIALOG.ERROR]);
    // NOTE do nothing if confirmation dismiss
    if ( answer.type === CONFIRM_DIALOG.ERROR ) return;
    yield put({type: LIST.META, expectAnswer: true});
    try {
        yield call( deleteItem, options );
        // NOTE update list
        yield delay(200);
        yield put({type: LIST.GET_DATA.REQUEST});
        yield take(LIST.GET_DATA.FINISH);
        yield put({type: LIST.META, expectAnswer: false});
    } catch ( {message} ) {
        yield put({type: LIST.META, errorMessage: message, expectAnswer: false});
    }
}

/**
 * get list of answered tests
 * @param {Object} options
 * @private
 */
function getList({page, size, sortF, sortD}) {
    let totalPages = list.length;
    let data = [];
    // sort
    let order = sortD ? 'asc' : 'desc';
    data = orderBy(list, [sortF], [order]);
    // pagination
    data = chunk(data, size);
    data = data[page];
    return {data, page, size, totalPages, sortF, sortD};
}

/**
 * delete item
 * @param {Object} data
 * @private
 */
function deleteItem({id}) {
    remove(list, item => (item.id === id));
}

/**
 * validate query params
 * @param {Object} params
 * @private
 */
function validateParams({page, size, sortF, sortD}) {
   page = is.countable(page) ? Number(page) : 0;
   size = is.countable(size) ? Number(size) : 10;
   let availableFields = ['name', 'description', 'createdBy', 'createdAt', 'updatedBy', 'updatedAt'];
   sortF = availableFields.includes(sortF) ? sortF : 'name';
   sortD = sortD ? Boolean(Number(sortD)) : true;
   return {page, size, sortF, sortD};
}

/**
 * update location
 * @param {Object} params
 * @private
 */
function updateLocation({page, size, sortF, sortD}) {
    let query = queryService.format({page, size, sortF, sortD: Number(sortD)});
    history.push(ANSWERED_TESTS.LINK()+query);
}
