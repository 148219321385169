// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import {history} from '../../../store';
import MdInput from '../../../components/md-input';
import MdButton from '../../../components/md-button';
import Preloader from '../../../components/preloader';
import {MdSelect} from '../../../components/md-select';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';

class Edit extends Component {
    componentDidMount() { this.props.initialize( this.props.match.params.id ); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, match } = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span:8,offset:2}}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase offset-bottom-10">
                                    { isNew ? <span>Create answer</span> : <span>Edit answer</span> }
                                    <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                                </h2>
                                <ConnectedError />
                                <ConnectedForm isNew={isNew} />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.riskAnswers.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.riskAnswers.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.riskAnswers.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        answers: state.riskAnswers.edit.answers,
        initialValues: state.riskAnswers.edit.data,
        disabled: state.riskAnswers.edit.expectAnswer
    }),
    dispatch => ({
        getAnswers: id => dispatch({type: EDIT.GET_ANSWERS, id}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editAnswer',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // question
        if (!values.question) {
            errors.question = 'Question is required';
        }
        // answer
        if (!values.answer) {
            errors.answer = 'Answer is required';
        }
        // multiplier
        if (!values.multiplier) {
            errors.multiplier = 'Weight is required';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, getAnswers, answers})=>(
    <form autoComplete="off" name="editAnswer" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-3">
            <Col xs={12} className="offset-bottom-3">
                <SelectEntities
                    name="question"
                    disabled={disabled}
                    placeholder="Question"
                    type={ENTITY_TYPES.QUESTIONS}
                    onChange={({id}) => getAnswers(id)}
                    getOptionLabel={option => get(option, 'question')}
                     required={true}
                                    label={(<strong className="required-asterisk"> Question </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12} md={6} className="offset-bottom-3">
                <Field
                    name="answer"
                    valueKey="id"
                    labelKey="answer"
                    options={answers}
                    component={MdSelect}
                    placeholder="Select answer"
                    disabled={disabled || !answers.length}
                     required={true}
                                    label={(<strong className="required-asterisk"> Answer </strong>)}
                        />
            </Col>
            <Col xs={12} md={6}>
                <Field
                    type="number"
                    name="multiplier"
                    component={MdInput}
                    disabled={disabled}
                    placeholder="Weight"
                     required={true}
                                    label={(<strong className="required-asterisk"> Weight </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <MdButton type="submit" disabled={ pristine || invalid || disabled } className="offset-right-2">
                    { isNew ? (<span> Create </span>) : (<span> Save </span>) }
                </MdButton>
                <MdButton
                    type="reset"
                    color="secondary"
                    disabled={ pristine || disabled }
                    className="offset-right-2"
                    onClick={reset}
                        >
                    Reset
                </MdButton>
                <MdButton className="md-btn md-btn-danger" onClick={ () => history.back()}>
                    Cancel
                </MdButton>
            </Col>
        </Row>
    </form>
)));
