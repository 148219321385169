// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {CYBER_ROLES} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {CYBER_ROLES_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, list, hints} = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={CYBER_ROLES_MAP}/>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <RichHintTitle update={LIST} name={`CYBER_ROLES$TITLE`} expectAnswer={expectAnswer}
                                           data={findHint(hints, `CYBER_ROLES_TITLE`)}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} placement="left" to={CYBER_ROLES.LINK_EDIT()}
                                  permission={PERMISSION.CYBER_ROLE.CREATE}
                                  hint={findHint(hints, 'BUTTON_CYBER_ROLES_ADD')}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected/>
                        </Col>
                    </Row>
                    <ConnectedError/>
                    {list.length ? (
                        <Paper> <ConnectedTable/> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        expectAnswer: state.cyberRoles.list.expectAnswer,
        list: state.cyberRoles.list.data,
        hints: state.cyberRoles.list.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.cyberRoles.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.cyberRoles.list.filter,
        disabled: state.cyberRoles.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION')}
    />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.cyberRoles.list, hints: state.cyberRoles.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({
    data,
    hints,
    page,
    size,
    totalPages,
    sortF,
    sortD,
    expectAnswer,
    deleteItem,
    changePage,
    changeSize,
    changeSort
}) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('name')}
                        >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('description')}
                        >
                            {translate('GLOBALS$DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"/>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell className="text-nowrap">{item.name}</TableCell>
                    <AbbrevTableCell content={item.description} title={item.name} length={100}/>
                    <TableCell align="right" className="text-nowrap">
                        {item.readOnly ? null : (<div>
                            <Link
                                Btn={EditBtn}
                                permission={PERMISSION.CYBER_ROLE.UPDATE}
                                to={CYBER_ROLES.LINK_EDIT({
                                    id: item.id,
                                    query: {back: history.location.pathname + history.location.search}
                                })}
                                hint={findHint(hints, 'BUTTON_CYBER_ROLES_EDIT')}
                            />
                            <DelBtn permission={PERMISSION.CYBER_ROLE.DELETE} onClick={() => deleteItem(item.id)}
                                    className="offset-left-2" hint={findHint(hints, 'BUTTON_CYBER_ROLES_DELETE')}/>
                        </div>)}
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>)));

const ConnectedError = connect(
    state => ({message: state.cyberRoles.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: LIST.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
