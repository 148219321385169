
// outsource dependencies
import React from 'react';
import { Link } from 'react-router-dom';

// local dependencies
// import SignUpForm from './form';
import { PublicLogo } from '../../images';
import { translate } from '../../services/translate.service';
import { FORGOT_PASSWORD, SIGN_IN, PUBLIC_WELCOME } from '../../constants/routes';

// configuration


/**
 * Sign Up page
 *
 * @public
 */
function SignUpPage () {
    return (<div>
        <div className="row top-indent-4 offset-bottom-4">
            <div className="col-xs-10 col-xs-offset-1 text-center">
                <Link to={PUBLIC_WELCOME.LINK()}>
                    <PublicLogo />
                </Link>
            </div>
        </div>
        <div className="row offset-bottom-6">
            <h1 className="col-xs-12 text-center"> {translate('SIGN_UP$DESCRIPTION')} </h1>
        </div>
        <div className="row">
            <div className="col-xs-6 text-left">
                <Link to={SIGN_IN.LINK()} className="link text-uppercase"> {translate('AUTHORIZATION$SIGN_IN')} </Link>
            </div>
            <div className="col-xs-6 text-right">
                <Link to={FORGOT_PASSWORD.LINK()} className="link text-uppercase"> {translate('AUTHORIZATION$FORGOT_PASSWORD')} </Link>
            </div>
        </div>
    </div>);
}

export default SignUpPage;
