// outsource dependencies$
import get from 'lodash/get';
import Dropzone from 'react-dropzone';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {CloudDownload} from '@mui/icons-material';
import {change, Field, FieldArray, getFormValues, reduxForm} from 'redux-form';
import {IconButton, InputLabel, Paper, Tooltip} from '@mui/material';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import {DOWNLOAD} from '../../../constants/routes';
import Preloader from '../../../components/preloader';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import ErrorMessage from '../../../components/alert-error';
import SelectEntities from '../../../components/select-entities';
import withDownloadLink from '../../../components/download-link';
import {formatFileSize} from '../../../services/data-formatting.service';
import {translate, withTranslation} from '../../../services/translate.service';
import {CancelBtn, DeleteIconBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {CONTROL_TEST_RESULTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import TasksManagement from "../../../components/tasks-management";
import {useParams} from "react-router-dom";
import {LIST} from '../../cyber-roles/actions';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

// config
export const FORM_NAME = 'editControlTestResult';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let params = useParams();
    useEffect(() => {
        props.initialize(params);
        return () => {
            props.clear();
        }
    }, [])
     let isNew = params.id === NEW_ID;
    let {message, clearError, hints} = props;
    return (<Container fluid>
        <Breadcrumbs breadCrumbsMap={ CONTROL_TEST_RESULTS_MAP }  />
        <ConnectedInitializer>
            <Row className="offset-top-10">
                <Col xs={12} md={{span:10, offset:1}} lg={{span:8, offset:2}} >
                    <Paper className="indent-5">
                        <h2 className="text-uppercase">
                                <span>
                                    <RichHintTitle
                                        update={LIST}
                                        name={isNew ? translate('ORGANIZATION_CONTROL_TEST_RESULTS$AUDIT_RESULT') : translate('ORGANIZATION_CONTROL_TEST_RESULTS$EDIT_RESULT')}
                                        expectAnswer={expectAnswer}
                                        data={findHint(hints,'ORGANIZATION_CONTROL_TEST_RESULTS_EDIT_TITLE')}
                                    />
                                </span>
                            <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                        </h2>
                        <ErrorMessage active message={message} onChange={clearError}/>
                        <ConnectedForm />
                    </Paper>
                </Col>
            </Row>
        </ConnectedInitializer>
    </Container>);
}

export default connect(
    state => ({
        message: state.controlTestResults.edit.errorMessage,
        expectAnswer: state.controlTestResults.edit.expectAnswer,
        isAudit: state.controlTestResults.edit.isAudit,
        hints: state.controlTestResults.edit.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: params => dispatch({type: EDIT.INITIALIZE, ...params}),
        clearError: () => dispatch({ type: EDIT.META, errorMessage: null})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.controlTestResults.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const DownloadLink = withDownloadLink()(props => (
    <Tooltip title={translate('GLOBALS$DOWNLOAD')} className="offset-bottom-1"><span>
        <IconButton {...props} color="primary" style={{padding: '5px'}} aria-label={translate('GLOBALS$DOWNLOAD')}>
            <CloudDownload fontSize="small" />
        </IconButton>
    </span></Tooltip>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.controlTestResults.edit.data,
        disabled: state.controlTestResults.edit.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
        isAudit: state.controlTestResults.edit.isAudit,
        hints: state.controlTestResults.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        removeDocument: () => dispatch(changeField('document', null)),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // assessment weight
        if (values.assessmentWeight && (values.assessmentWeight < 0 || values.assessmentWeight > 100)) {
            errors.assessmentWeight = 'GLOBALS$PERCENTAGE_ERROR';
        }
        if (!values.controlMaturity) {
            errors.controlMaturity = 'CONTROL_MATURITIES$CONTROL_MATURITY_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, isAudit, update, reset, cancel, formValues={}, removeDocument, hints, isNew})=>(
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-2">
            <Col xs={12} className="offset-bottom-0">
                <h4>
                    <strong>{translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT_COLON')} </strong>
                    {get(formValues, 'securityRequirement.code')}
                </h4>
            </Col>
            <Col xs={12} className="offset-bottom-0">
                <h4>
                    <strong>{translate('SECURITY_REQUIREMENTS$PROGRAM_AREA')}: </strong>
                    {get(formValues, 'securityRequirement.programArea')}
                </h4>
            </Col>
            <Col xs={12} className="offset-bottom-0">
                <h4>
                    <strong>{translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_FAMILY')}: </strong>
                    {get(formValues, 'securityRequirement.securityControlFamily.name')}
                </h4>
            </Col>
            <Col xs={12} className="offset-bottom-0">
                <h4>
                    <strong>{translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_NAME')}: </strong>
                    {get(formValues, 'securityRequirement.securityControlName.name')}
                </h4>
            </Col>
            <Col xs={12} className="offset-bottom-0">
                <h4>
                    <strong>{translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT_DESCRIPTION')}: </strong>
                    <br/>
                    <br/>
                    <Field
                        multiline
                        name="securityRequirement.description"
                        component={MdInput}
                        disabled={true}
                    />
                </h4>
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <h4>
                    <strong className="required-asterisk"> {translate('CONTROL_MATURITIES$CONTROL_MATURITY')}: </strong>
                    <SelectEntities
                        name="controlMaturity"
                        disabled={disabled || isAudit}
                        type={ENTITY_TYPES.CONTROL_MATURITIES}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    />
                </h4>
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline
                    name="comments"
                    component={MdInput}
                    disabled={disabled || isAudit}
                    placeholder={translate('GLOBALS$COMMENTS')}
                    label={(<strong> {translate('GLOBALS$COMMENTS')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4"> <Col xs={12}>
            <h4>
                <strong>{translate('GLOBALS$DOCUMENT_COLON')} </strong>
                {get(formValues, 'document') && (<span>
                    {get(formValues, 'document.fileName')} ({ formatFileSize(get(formValues, 'document.fileSize')) })&nbsp;
                    {get(formValues, 'document.downloadUrl') && (
                        <DownloadLink link={() => DOWNLOAD.EVIDENCE_DOCUMENT_LINK({downloadUrl: get(formValues, 'document.downloadUrl')})} />
                    )}&nbsp;<DeleteIconBtn className="offset-bottom-1" style={{padding: '5px'}} onClick={()=>removeDocument()} />
                </span>)}
            </h4>
        </Col> </Row>
        <Row className="offset-bottom-8"> <Col xs={12}><UploadFile /> </Col> </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}> <FieldArray name="tasks" component={TasksManagement} /> </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={false}
                           disabled={pristine||invalid||disabled}
                           className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_CREATE' : 'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_RESET')} />
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_CANCEL')} />
            </Col>
        </Row>
    </form>
))));

const UploadFile = withTranslation(connect(
    state => ({
        uploaded: state.controlTestResults.edit.uploaded,
        disabled: state.controlTestResults.edit.expectAnswer,
        isAudit: state.controlTestResults.edit.isAudit,
    }),
    dispatch => ({ uploadFile: file => dispatch({type: EDIT.UPLOAD_FILE, file}) })
)( ({uploadFile, uploaded, disabled, isAudit, ...attr}) => (<div>
    <InputLabel htmlFor="fileUpload"> <strong> {translate('GLOBALS$DOCUMENT_UPLOAD')} </strong> </InputLabel>
    <Dropzone
        {...attr}
        id="fileUpload"
        className="dropzone"
        disabled={!uploaded || disabled || isAudit}
        activeClassName="dropzone-active"
        disabledClassName="dropzone-disabled"
        onDrop={(acceptedFiles, rejectedFiles)=> {!rejectedFiles.length&&uploadFile(acceptedFiles[0])}}
            >
        {()=>{
            if (!uploaded) { return (<h3 className="text-center text-muted">{translate('GLOBALS$LOADING')}</h3>); }
            return (<div className="text-center text-muted">
                <p style={{fontSize: '50px'}}> <i className="fa fa-upload" /> </p>
                <h3 className="offset-top-0">{translate('DROPZONE$DEFAULT_MESSAGE')}</h3>
            </div>)
        }}
    </Dropzone>
</div>)));


