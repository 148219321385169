// outsource dependencies
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';
import OrgChart from 'react-orgchart';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Col, Row} from 'react-bootstrap';

// local dependencies
import {SCHEME} from '../actions';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {translate} from '../../../services/translate.service';

class Scheme extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { data } = this.props;
        console.log(data);
        return (<Paper square>
            <ConnectedInitializer>
                <ConnectedError />
                <Row>
                    <Col xs={12}>
                        { !isEmpty(data) ? (
                            <div className="org-chart indent-8" style={{overflow: 'auto'}}>
                                <OrgChart tree={data} NodeComponent={Node}/>
                            </div>
                        ) : (
                            <h3 style={{marginTop: 20}} className="text-uppercase text-center text-highlighted offset-bottom-6"> {translate('GLOBALS$NO_DATA')} </h3>
                        )}
                    </Col>
                </Row>
            </ConnectedInitializer>
         </Paper>);
    }
}

export default connect(
    state => ({data: state.subsidiaries.scheme.data}),
    dispatch => ({
        clear: () => dispatch({type: SCHEME.CLEAR}),
        initialize: () => dispatch({type: SCHEME.INITIALIZE}),
    })
)(Scheme)

const ConnectedInitializer = connect(
    state => ({initialize: state.subsidiaries.scheme.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.subsidiaries.scheme.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: SCHEME.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

/**
 * component for OrgChart nodes
 *
 * @param {Object} props
 * @public
 */
const Node = ({node}) => {
    let { name } = node;
    return (
        <div className="org-node">
            <h5>{ name }</h5>
        </div>
    );
};

