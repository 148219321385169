// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import {Col, Container, Row} from 'react-bootstrap';
import React, {useEffect, Fragment} from 'react';
import {InputLabel, Paper} from '@mui/material';
import {change, Field, FieldArray, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import is from '../../../services/is.service';
import MdInput from '../../../components/md-input';
import MdSwitch from '../../../components/md-switch';
import Preloader from '../../../components/preloader';
import {MdSelect} from '../../../components/md-select';
import ErrorMessage from '../../../components/alert-error';
import SelectEntities from '../../../components/select-entities';
import {ENTITY_TYPES, NEW_ID, PERMISSION, QUESTION_TYPES, QUESTION_TYPES_LIST} from '../../../constants/spec';
import {translate, withTranslation} from '../../../services/translate.service';
import {AddIconBtn, CancelBtn, CloneBtn, DeleteIconBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {QUALITATIVE_QUESTIONS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {useParams} from "react-router-dom";
import {LIST} from '../../cyber-roles/actions';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

// config
export const FORM_NAME = 'qualitativeQuestionEditForm';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const EditMetric = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.init(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let { disabled, initialized, clone, data, hints } = props;

    return (<Preloader expectAnswer={!initialized} type="MIN_HEIGHT" height={800}>
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={QUALITATIVE_QUESTIONS_MAP}  />
            <Row className="offset-top-10">
                <Col xs={12} md={{span:8,offset:2}}>
                    <Paper className="indent-5">
                        <Row className="offset-top-2">
                            <Col md={10} className="offset-bottom-10">
                                <h2 className="text-uppercase">
                                    <span>
                                        <RichHintTitle
                                            update={LIST}
                                            name={isNew ? translate('SCORING_QUESTIONS$CREATE_QUESTION') : translate('SCORING_QUESTIONS$EDIT_QUESTION')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints, isNew ? translate('SCORING_QUESTIONS_CREATE_TITLE') : translate('SCORING_QUESTIONS_EDIT_TITLE'))}
                                        />
                                    </span>
                                    <Preloader expectAnswer={disabled} type="ICON" />
                                </h2>
                            </Col>
                            {!isNew && (<Col md={2} className="text-right">
                                <CloneBtn permission={PERMISSION.QUALITATIVE_QUESTION.CREATE} disabled={disabled} onClick={() => clone(data, isNew)} className="offset-left-2" hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_CLONE')}/>
                            </Col>)}
                            <ConnectedError />
                            <ConnectedForm isNew={isNew} />
                        </Row>
                    </Paper>
                </Col>
            </Row>
        </Container>
    </Preloader>);
}

export default connect(
    state => ({
        disabled: state.qualQuest.edit.expectAnswer,
        initialized: state.qualQuest.edit.initialized,
        hints: state.qualQuest.edit.hintsData,
        data: getFormValues(FORM_NAME)(state),
    }),
    dispatch => ({
        init: id => dispatch({type: EDIT.INITIALIZE, id }),
        clear: () => dispatch({type: EDIT.CLEAR}),
        clone: (formData, id) => dispatch({type: EDIT.UPDATE, ...formData, id}),
    })
)(EditMetric)

const ConnectedError = connect(
    state => ({message: state.qualQuest.edit.errorMessage}),
    dispatch => ({clear: () => dispatch({type: EDIT.META, errorMessage: null})})
)( ({message, clear}) => (<ErrorMessage active={true} message={message} onChange={clear} />));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.qualQuest.edit.data,
        disabled: state.qualQuest.edit.expectAnswer,
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        setupVendorType: vendorType => dispatch({type: EDIT.SETUP_VENDOR_TYPE, vendorType}),
        selectAllVendors: selectedAll => dispatch({type: EDIT.SELECT_ALL_VENDORS, selectedAll})
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } - named errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // question
        if (!values.question) {
            errors.question = 'SCORING_QUESTIONS$QUESTION_REQUIRED';
        }
        // vendorType
        if (!values.vendorType) {
            errors.vendorType = 'SCORING_QUESTIONS$SCORING_TYPE_REQUIRED';
        }
        // qualitativeMetric
        if (!values.qualitativeMetric) {
            errors.qualitativeMetric = 'SCORING_METRICS$METRIC_REQUIRED';
        }
        // branching logic
        let branchingErrors = [];
        if ( values.branchingLogic && values.branchingLogic.length ) {
            values.branchingLogic.forEach((branching, index) => {
                let errors = {};
                if ( !branching.question ) { errors.question = 'SCORING_QUESTIONS$QUESTION_REQUIRED'; }
                if ( !branching.answer ) { errors.answer = 'SCORING_QUESTIONS$ANSWER_REQUIRED';}
                if ( !is.empty(errors) ) {
                    branchingErrors[index] = errors;
                }
            });
        }
        if ( !is.empty(branchingErrors) ) {
            errors.branchingLogic = branchingErrors;
        }
        // question weight
        if ( !values.questionWeight ) {
            errors.questionWeight = 'SCORING_QUESTIONS$QUESTION_WEIGHT_REQUIRED';
        }
        if ( !values.code ) {
            errors.code = 'SCORING_QUESTIONS$QUESTION_CODE_REQUIRED';
        }
        /*
        if ( !values.description ) {
            errors.description = 'SCORING_QUESTIONS$QUESTION_DESCRIPTION_REQUIRED';
        }
        */
        return errors;
    }
})( connect( state => ({ values: state.form.qualitativeQuestionEditForm.values, hints: state.qualQuest.edit.hintsData}), null )(
({handleSubmit, invalid, pristine, disabled, update, reset, isNew, values, setupVendorType, selectAllVendors, cancel, hints})=>{
    let vendorType = get(values, 'vendorType');
    let isOrganizationQuestion = ([QUESTION_TYPES.SYSTEM, QUESTION_TYPES.BOTH].includes(vendorType));
    let isVendorQuestion = ([QUESTION_TYPES.VENDOR, QUESTION_TYPES.CLOUD, QUESTION_TYPES.BOTH].includes(vendorType));
    let showVendorCategories = ([QUESTION_TYPES.VENDOR, QUESTION_TYPES.CLOUD, QUESTION_TYPES.VENDOR_INTERNAL, QUESTION_TYPES.CLOUD_INTERNAL].includes(vendorType));
    let allVendorsSelected = get(values, 'allVendorsSelected', false);
    let selectedSwitcherLabel = vendorType === QUESTION_TYPES.BOTH ? 'SCORING_QUESTIONS$ALL_VENDORS_AND_SYSTEMS' : (
        vendorType === QUESTION_TYPES.SYSTEM ? 'SYSTEMS$ALL_SYSTEMS' : 'VENDORS$ALL_VENDORS'
    );
    return (
        <form autoComplete="off" name="qualitativeQuestionEditForm" onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        multiline
                        skipTouch={!isNew}
                        name="question"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('SCORING_QUESTIONS$QUESTION')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('SCORING_QUESTIONS$QUESTION')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        name="code"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('SCORING_QUESTIONS$QUESTION_CODE')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('SCORING_QUESTIONS$QUESTION_CODE')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        multiline
                        name="description"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('SCORING_QUESTIONS$QUESTION_DESCRIPTION')}
                        label={(<strong className="required-asterisk"> {translate('SCORING_QUESTIONS$QUESTION_DESCRIPTION')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        name="categoryName"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('CATEGORIES$CATEGORY')}
                        label={(<strong> {translate('CATEGORIES$CATEGORY')} </strong>)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <SelectEntities
                        skipTouch={!isNew}
                        name="qualitativeMetric"
                        disabled={disabled}
                        type={ENTITY_TYPES.QUAL}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('SCORING_METRICS$SCORING_METRIC')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        skipTouch={!isNew}
                        labelKey="label"
                        valueKey="value"
                        name="vendorType"
                        disabled={disabled}
                        component={MdSelect}
                        onChange={(e, value) => setupVendorType(value)}
                        placeholder={translate('SCORING_QUESTIONS$SCORING_TYPE')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('SCORING_QUESTIONS$SCORING_TYPE')} </strong>)}
                        options={QUESTION_TYPES_LIST}
                    />
                </Col>
            </Row>
            <Row className="text-center">
                <Col xs={12} lg={3} className="offset-bottom-2">
                    <Field
                        fullWidth={false}
                        disabled={disabled}
                        component={MdSwitch}
                        name="allowUploadAsAnswer"
                        label={(<strong> {translate('SCORING_QUESTIONS$ALLOW_UPLOAD_AS_ANSWER')} </strong>)}
                    />
                </Col>
                <Col xs={12} lg={3} className="offset-bottom-2">
                    <Field
                        fullWidth={false}
                        disabled={disabled}
                        component={MdSwitch}
                        name="allowTextAsAnswer"
                        label={(<strong> {translate('SCORING_QUESTIONS$ALLOW_TEXT_AS_ANSWER')} </strong>)}
                    />
                </Col>
                <Col xs={12} lg={3} className="offset-bottom-2">
                    <Field
                        fullWidth={false}
                        disabled={disabled}
                        component={MdSwitch}
                        name="allowCommentToAnswer"
                        label={(<strong> {translate('SCORING_QUESTIONS$ALLOW_COMMENT_TO_ANSWER')} </strong>)}
                    />
                </Col>
                <Col xs={12} lg={3} className="offset-bottom-2">
                    <Field
                        fullWidth={false}
                        disabled={disabled}
                        component={MdSwitch}
                        name="allowMultipleAnswers"
                        label={(<strong> {translate('SCORING_QUESTIONS$ALLOW_MULTIPLE_ANSWERS')} </strong>)}
                    />
                </Col>
                {showVendorCategories && (
                    <Col xs={12} lg={4} className="offset-bottom-2">
                        <Field
                            name="isInternal"
                            color="primary"
                            fullWidth={false}
                            disabled={disabled}
                            component={MdSwitch}
                            label={(<strong> {translate('SCORING_QUESTIONS$INTERNAL')} </strong>)}
                                />
                    </Col>
                )}
            </Row>
            {showVendorCategories && (<Row className="offset-bottom-4">
                <Col xs={12}> <InputLabel> <strong> {translate('SCORING_QUESTIONS$APPLIES_ONLY_FOR')} </strong> </InputLabel> </Col>
                <Col xs={12} md={4} className="text-center">
                    <Field
                        color="primary"
                        fullWidth={false}
                        disabled={disabled}
                        component={MdSwitch}
                        name="isTechnologyVendor"
                        label={(<strong> {translate('VENDORS$TECHNOLOGY_VENDOR')} </strong>)}
                            />
                </Col>
                <Col xs={12} md={4} className="text-center">
                    <Field
                        color="primary"
                        fullWidth={false}
                        disabled={disabled}
                        component={MdSwitch}
                        name="isSystemVendor"
                        label={(<strong> {translate('VENDORS$SYSTEM_VENDOR')} </strong>)}
                            />
                </Col>
                <Col xs={12} md={4} className="text-center">
                    <Field
                        color="primary"
                        fullWidth={false}
                        disabled={disabled}
                        component={MdSwitch}
                        name="isServiceVendor"
                        label={(<strong> {translate('VENDORS$SERVICE_VENDOR')} </strong>)}
                            />
                </Col>
            </Row>)}
            <Row className="offset-bottom-6">
                <Col xs={12}>
                    <SelectEntities
                        skipTouch={!isNew}
                        disabled={disabled}
                        name="questionWeight"
                        type={ENTITY_TYPES.QUESTIONS_WEIGHT}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('SCORING_QUESTIONS$QUESTION_WEIGHT')} </strong>)}
                    />
                </Col>
            </Row>
            { vendorType && (<Row className="offset-bottom-10">
                <Col xs={12}>
                    <Field
                        fullWidth={false}
                        disabled={disabled}
                        component={MdSwitch}
                        name="allVendorsSelected"
                        label={(<strong> {translate(selectedSwitcherLabel)} </strong>)}
                        onChange={(e, value) => selectAllVendors(value)}
                            />
                </Col>
            </Row>)}
            { isOrganizationQuestion && (<Row className="offset-bottom-4">
                <Col xs={12}>
                    <SelectEntities
                        isMulti={true}
                        name="riskTypes"
                        disabled={disabled}
                        type={ENTITY_TYPES.RISK_TYPES}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('CATEGORIES$RISK_TYPES')} </strong>)}
                            />
                </Col>
            </Row>)}
            { isOrganizationQuestion && !allVendorsSelected && (<Row className="offset-bottom-4">
                <Col xs={12}>
                    <SelectEntities
                        isMulti={true}
                        name="systems"
                        disabled={disabled}
                        type={ENTITY_TYPES.SYSTEMS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('SYSTEMS$TITLE')} </strong>)}
                            />
                </Col>
            </Row>)}
            { isVendorQuestion && !allVendorsSelected && (<Row className="offset-bottom-4">
                <Col xs={12}>
                    <SelectEntities
                        isMulti={true}
                        name="vendors"
                        disabled={disabled}
                        type={ENTITY_TYPES.VENDORS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('VENDORS$TITLE')} </strong>)}
                            />
                </Col>
            </Row>)}
            <Row className="offset-bottom-4"> <Col xs={12}>
                <FieldArray name="answers" rerenderOnEveryChange={true} component={ConnectedItems} />
            </Col> </Row>
            <Row className="offset-bottom-6"> <Col xs={12}>
                    <FieldArray name="branchingLogic" rerenderOnEveryChange={true} component={BranchingLogic} />
            </Col> </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_SCORING_QUESTIONS_CREATE' : 'BUTTON_SCORING_QUESTIONS_SAVE')} />
                    <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_RESET')} />
                    <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_CANCEL')} />
                </Col>
            </Row>
        </form>
)}))));

/**
 *
 * @param { Object } props
 * @public
 */
const ConnectedItems = connect(
    state => ({disabled: state.qualQuest.edit.expectAnswer}),
    null,
)(({fields, meta, disabled}) => (<Fragment>
    <Row> <Col xs={12}>
        <h3 className="text-uppercase">
            <span className="align-middle"> {translate('SCORING_QUESTIONS$ANSWERS')} </span>&nbsp;
            <AddIconBtn onClick={()=>fields.push({})} />
        </h3>
    </Col> </Row>
    {meta.error && (<Row> <Col xs={{span:10,offset:1}} className="text-center is-invalid">
        <label className="form-text h4"> { translate(meta.error) } </label>
    </Col> </Row>)}
    { fields.map((mKey, index) => (<Row key={index} className="offset-bottom-6">
        <Col xs={7}>
            <Field
                component={MdInput}
                disabled={disabled}
                name={`${mKey}.answer`}
                placeholder={translate('SCORING_QUESTIONS$ANSWER')}
                required={true}
                label={(<strong> {translate('SCORING_QUESTIONS$ANSWER')} </strong>)}
            />
        </Col>
        <Col xs={5} className="row-adornment">
            <SelectEntities
                disabled={disabled}
                name={`${mKey}.answerWeight`}
                type={ENTITY_TYPES.ANSWER_WEIGHT}
                getOptionLabel={option => get(option, 'value')}
                placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                required={true}
                label={(<strong> {translate('SCORING_QUESTIONS$ANSWER_WEIGHT')} </strong>)}
            />
            <div className="adornment"> <DeleteIconBtn onClick={()=>fields.remove(index)} /> </div>
        </Col>
    </Row>)) }
</Fragment>));

/**
 *
 * @param { Object } props
 * @public
 */
const BranchingLogic = connect(
    state => ({
        disabled: state.qualQuest.edit.expectAnswer,
        currentId: get(state, 'qualQuest.edit.data.id', null)
    }),
    dispatch => ({update: field => dispatch(change('qualitativeQuestionEditForm', field, null))}),
)(({ fields, disabled, currentId, update }) => (<Fragment>
    <Row className="offset-bottom-4">
        <Col xs={12}>
            <h3 className="text-uppercase">
                <span className="align-middle"> {translate('SCORING_QUESTIONS$BRANCHING_LOGIC')} </span>&nbsp;
                <AddIconBtn onClick={()=> fields.push({})} />
            </h3>
        </Col>
    </Row>
    {fields.map((mKey, index) => {
        let item = fields.get(index);
        // NOTE sort question answers by name
        get(item, 'question.answers', []).sort((a, b) => {
            let x = is.string(a.answer) ? a.answer.toLowerCase() : '';
            let y = is.string(b.answer) ? b.answer.toLowerCase() : '';
            return x < y ? -1 : x > y ? 1 : 0;
        });
        return (<Paper key={index} className="indent-4 offset-bottom-4"><Row>
            <Col xs={12} md={6} className="offset-bottom-2">
                <SelectEntities
                    disabled={disabled}
                    name={`${mKey}.question`}
                    type={ENTITY_TYPES.QUESTIONS}
                    selected={currentId ? [currentId] : []}
                    onChange={()=>update(`${mKey}.answer`)}
                    getOptionLabel={option => get(option, 'question')}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    required={true}
                    label={(<strong> {translate('SCORING_QUESTIONS$QUESTION')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="row-adornment offset-bottom-2">
                <Field
                    valueKey="id"
                    labelKey="answer"
                    component={MdSelect}
                    name={`${mKey}.answer`}
                    disabled={disabled || !get(item, 'question')}
                    placeholder={translate('SCORING_QUESTIONS$ANSWER')}
                    options={get(item, 'question.answers') || get(item, 'answerList', [])}
                    required={true}
                    label={(<strong> {translate('SCORING_QUESTIONS$ANSWER')} </strong>)}
                />
                <div className="adornment"> <DeleteIconBtn onClick={()=>fields.remove(index)} /> </div>
            </Col>
        </Row></Paper>)
    }) }
</Fragment>));
