
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Search, Close } from '@mui/icons-material';
import { FormControl, Input, IconButton, TextField, InputAdornment, OutlinedInput } from '@mui/material';

// local dependencies
import { translate, withTranslation } from '../services/translate.service';
import { Col, Container, Row } from 'react-bootstrap';

// configure
TextFilter.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    apply: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
};

export default withTranslation(TextFilter);

/**
 *
 * @param { Object } props
 * @public
 */
export function TextFilter ({ apply, onInputChange, clear, value, disabled = false, placeholder = translate('GLOBALS$SEARCH_BY_NAME'), ...attr }) {
    return (<form name="searchFilterForm" onSubmit={e => e.preventDefault()||apply(value)}>
        <Container>
            <Row>
            <Col xs={11}>
                <FormControl fullWidth>
                    <Input
                        {...attr}
                        name="search"
                        value={value}
                        autoComplete="off"
                        placeholder={placeholder}
                        onChange={e=> onInputChange(String(e.target.value).trimStart())}
                        endAdornment={value && (
                            <InputAdornment position="end">
                                <IconButton onClick={clear} style={{padding: '6px'}}> <Close fontSize="small"/> </IconButton>
                            </InputAdornment>
                        )}/>
                </FormControl>
            </Col>
            <Col xs={1} className="offset-top-2">
                <IconButton type="submit" disabled={disabled} style={{padding: '6px'}}> <Search /> </IconButton>
            </Col>
            </Row>
        </Container>
    </form>);
}

SimpleSearchField.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    clear: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

/**
 * simple search field which can be used inside filter panel etc
 *
 * @param { Object } props
 * @public
 */
export function SimpleSearchField ({ onChange, clear, value, disabled = false, label, placeholder = translate('GLOBALS$SEARCH_BY_NAME')}) {
    return (
        <TextField
            value={value}
            variant="standard"
            fullWidth={true}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            label={label ? label : (<strong> {translate('GLOBALS$SEARCH')} </strong>)}
            InputProps={{
                endAdornment: (
                    !value ? '' : (<InputAdornment position="end">
                        <IconButton style={{padding: '4px'}} onClick={clear}>
                            <Close fontSize="small" />
                        </IconButton>
                    </InputAdornment>)
                ),
            }}/>
    );
}

/**
 *
 * @param { Object } props
 * @public
 */
export function TextFilterBootstrap ({ apply, onInputChange, clear, value, disabled = false, placeholder = translate('GLOBALS$SEARCH_BY_NAME'), ...attr }) {
    return (<form name="searchFilterForm" onSubmit={e => e.preventDefault()||apply(value)}>
        <FormControl >
            <OutlinedInput
                {...attr}
                name="search"
                value={value}
                autoComplete="off"
                placeholder={placeholder}
                onChange={e=> onInputChange(String(e.target.value).trimStart())}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton type="submit" disabled={disabled} style={{padding: '6px'}}> <Search /> </IconButton>
                    </InputAdornment>
                }/>
        </FormControl>
    </form>);
}
