// outsource dependencies
import {call, put, select, take, takeEvery} from "redux-saga/effects";

// local dependencies
import {QUAL_LIST} from "../actions";
import {instanceAPI} from "../../../services/api.service";
import CONFIRM_DIALOG from "../../../components/confirmation-dialog/actions";
import {translate} from "../../../services/translate.service";
import getHintsList, {SCORING_METRICS_LIST_HINTS} from '../../../services/hints.service';

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(QUAL_LIST.INITIALIZE, initializeSaga);
    yield takeEvery(QUAL_LIST.GET_LIST.REQUEST, getListSaga);
    yield takeEvery(QUAL_LIST.DELETE_ITEM, deleteItemSaga);
    yield takeEvery(QUAL_LIST.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga () {
    yield put({type: QUAL_LIST.CLEAR});
    yield put({type: QUAL_LIST.META, initialized: true });
    // get list of qual metrics
    yield put({type: QUAL_LIST.GET_LIST.REQUEST});
    yield put({type: QUAL_LIST.GET_HINTS_DATA.REQUEST});
    yield take(QUAL_LIST.GET_LIST.FINISH);
    yield put({type: QUAL_LIST.META, initialized: false});
}

function* getListSaga () {
    try {
        // get riskModel for api request
        let { riskModel } = yield select( state => state.app );
        let data = yield call( getList, riskModel );
        // setup qual metrics
        yield put({type: QUAL_LIST.LIST, data});
    } catch ( {message} ) {
        yield put({type: QUAL_LIST.META, errorMessage: message });
    }
    yield put({type: QUAL_LIST.GET_LIST.FINISH});
}

function* deleteItemSaga ({type, ...options}) {
    // NOTE ask confirmation of deleting
    yield put({type: CONFIRM_DIALOG.REQUEST, message: translate('GLOBALS$DELETION_CONFIRMATION')});
    let answer = yield take([CONFIRM_DIALOG.SUCCESS, CONFIRM_DIALOG.ERROR]);
    // NOTE do nothing if confirmation dismiss
    if ( answer.type === CONFIRM_DIALOG.ERROR ) return;
    yield put({type: QUAL_LIST.META, expectAnswer: true});
    try {
        // get riskModel for api request
        let { riskModel } = yield select( state => state.app );
        yield call( deleteItem, riskModel, options );
        // update list
        yield put({type: QUAL_LIST.GET_LIST.REQUEST});
        yield take(QUAL_LIST.GET_LIST.FINISH);
        yield put({type: QUAL_LIST.META, expectAnswer: false});
    } catch ( {message} ) {
        yield put({type: QUAL_LIST.META, errorMessage: message, expectAnswer: false});
    }
}

/**
 * Delete item
 *
 * @param {Object} riskModel
 * @param {Object} data
 * @private
 */
function deleteItem( {id}, data ) {
    return instanceAPI({method: 'delete', url: `/risk-models/${id}/qual-metrics`, data});
}

function* getHintsDataSaga () {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, SCORING_METRICS_LIST_HINTS);

        // NOTE setup hints data
        yield put({type: QUAL_LIST.META, hintsData});
    } catch ( {message} ) {
        yield put({type: QUAL_LIST.META, errorMessage: message});
    }
    yield put({type: QUAL_LIST.GET_HINTS_DATA.FINISH});
}

/**
 * get list of qual metrics of current risk model
 * @param {Object} riskModel
 * @private
 */
function getList( riskModel ) {
    return instanceAPI({method: 'get', url: `/risk-models/${riskModel.id}/qual-metrics`});
}
