
// outsource dependencies
import { differenceBy, uniqBy, remove, get } from 'lodash';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { changeField } from './index';
import { history } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import getHintsList, {ASSESSMENTS_EDIT_HINTS} from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CHANGE_SECURITY_REQUIREMENTS, changeSecurityRequirementSaga);
    yield takeEvery(EDIT.CHANGE_ASSESSMENT_LEVEL, changeAssessmentLevelSaga);
    yield takeEvery(EDIT.CLEAR_ITEMS, clearItemsSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, ASSESSMENTS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}
function* initializeSaga ({type, id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        yield call(changeAssessmentLevelSaga, data);
        data.isAllRequirementsSelected = data.relationToRequirementType === 'ALL_REQUIREMENTS';
        // NOTE build 'requirementsMapping' tree
        if (id !== NEW_ID && !get(data, 'isAllRequirementsSelected') && get(data, 'securityRequirements.length', 0)) {
            let requirementsMapping = yield call(buildRequirementMapping, data);
            yield put({type: EDIT.META, requirementsMapping});
        }
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true, ...params});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* clearItemsSaga () {
    yield put(changeField('systems', []));
    yield put(changeField('processes', []));
    yield put(changeField('technologyCategories', []));
}

function* changeAssessmentLevelSaga ({assessmentLevel}) {
    yield put({type: EDIT.META, expectAnswer: true});
    // NOTE get list of security requirements and update list of securityRequirements if exist assessmentLevel
    if(assessmentLevel) {
        const securityRequirements = yield call(getRequirements, assessmentLevel.id);
        yield put({type: EDIT.META, securityRequirements});
        yield call(clearItemsSaga);
    }
    if (assessmentLevel && assessmentLevel.name !== 'Org') {
        yield put(changeField('isAllSelected', true));
    } else {
        yield put(changeField('isAllSelected', false));
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function* changeSecurityRequirementSaga ({newValue, prevValue}) {
    let added = differenceBy(newValue, prevValue, 'id');
    let removed = differenceBy(prevValue, newValue, 'id');
    let { requirementsMapping } = yield select(state => state.assessments.edit);
    let newMapping = [...requirementsMapping];
    // NOTE update 'requirementsMapping' tree (add new requirements, delete annulled)
    try {
        if (added.length) {
            // NOTE select can add only one item per time
            let map = yield call(getRequirementMapping, added[0]);
            newMapping.push(map);
        }
        if (removed.length) {
            for (let j = 0; j < removed.length; j++) {
                let item = removed[j];
                remove(newMapping, mapping => item.id === get(mapping, 'securityRequirement.id'));
            }
        }
        yield put({type: EDIT.META, requirementsMapping: newMapping});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.assessments.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get assessment by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/assessments/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update assessment
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: `/assessments`, data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: `/assessments`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * update requirements by assessmentLevelId
 * @param {Number} id
 * @private
 */
function getRequirements ( id ) {
    return instanceAPI({method: 'post', url: '/security-requirements/filter', data: {filter: {assessmentLevelId: id}}})
        .then(success => success.items);
}
/**
 * get requirement mapping
 * @param {Object} securityRequirement
 * @private
 */
function getRequirementMapping ( securityRequirement ) {
    return instanceAPI({ method: 'get', url: `/security-requirements/mapping/${securityRequirement.id}`})
        .then(success => {
            let assessmentTypes = success.map(item => item.assessmentType);
            assessmentTypes = uniqBy(assessmentTypes, 'id');
            let mapping = assessmentTypes.map(assessmentType => {
                let dependencies = [];
                success.forEach(item => {
                    if (get(item, 'assessmentType.id') === assessmentType.id) {
                        dependencies.push(item);
                    }
                });
                return {assessmentType, dependencies};
            });
            return {securityRequirement, mapping};
        });
}

/**
 * get requirement mapping
 * @param {Object} data
 * @private
 */
function buildRequirementMapping ( data ) {
    let requirements = get(data, 'securityRequirements') || [];
    return Promise
        .all(requirements.map(item => getRequirementMapping(item)))
        .then(success=>success);
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
