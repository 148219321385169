// outsource dependencies
import rtl from 'jss-rtl';
import React from 'react';
import {create} from 'jss';
import {connect, Provider} from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import ReactDOM from 'react-dom/client';
import {Navigate, Route, Routes, unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import {jssPreset, StylesProvider} from '@mui/styles';
// STYLES inject ...
import './style';
// polyfill
import './polyfill';
// IE <=9 support
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
// local dependencies
import {history, store} from './store';
import * as ROUTES from './constants/routes';
import {
    ANSWERED_TESTS,
    ASSESSMENT_FINDINGS,
    ASSESSMENT_TYPES,
    ASSESSMENTS,
    ASSET_CLASSES,
    ASSOCIATE_MODELS,
    ASSOCIATE_SYSTEMS,
    AUDIT_LOGS,
    BUSINESS_INTERRUPTION,
    CAPITAL_COSTS,
    CATEGORIES,
    CLOUD_SCORING,
    CONSTANTS,
    CONTROL_CATEGORIES,
    CONTROL_FUNCTIONS,
    CONTROL_GUIDELINES,
    CONTROL_MATURITIES,
    CONTROL_SUBCATEGORIES,
    CONTROL_TEST_RESULTS,
    CONTROLS,
    CYBER_RISK_SCORING,
    CYBER_ROLES,
    CYBER_SECURITY_TOOLS,
    DATA_CLASSES,
    DATA_DOMAINS,
    DATA_EXFILTRATION,
    GDPR_COMPLIANCE,
    GDPR_EVIDENCE_DOCUMENTS,
    GDPR_SYSTEM_COMPLIANCE,
    MY_TASKS,
    OPERATIONAL_COSTS,
    ORGANIZATIONS,
    POLICIES,
    PROCESSES,
    QUAL_METRICS,
    QUAL_QUESTIONS,
    QUANT_METRICS,
    QUANT_MODELS,
    REGULATORY_EXPOSURES,
    RISK_ANSWERS,
    RISK_METRICS,
    ROLES,
    SECURITY_REQUIREMENTS,
    SECURITY_TOOLS,
    SUBSIDIARIES,
    SYSTEM_CONTROL_TEST_RESULTS,
    SYSTEM_RISK_REDUCTION,
    SYSTEMS,
    TASK_CATEGORIES,
    TASKS,
    TECH_CATEGORIES,
    TECHNOLOGIES,
    TECHNOLOGY_ASSETS,
    USER_SETTINGS,
    USERS,
    VARIABLE_COSTS,
    VENDOR_CONTRACTS,
    VENDORS,
    VULNERABILITIES
} from './constants/routes';
import {DIRECTION} from './constants/spec';
import registerServiceWorker from './registerServiceWorker';

import CheckHealth from './public-layout/check-health';
import PublicLayout from './public-layout';
import PrivateLayout from './private-layout';
//Public layout imports
import SignIn from "./public-layout/sign-in";
import PublicWelcome from "./public-layout/welcome";
import SignUp from "./public-layout/sign-up";
import ForgotPassword from "./public-layout/forgot-password";
import ChangePassword from "./public-layout/change-password";
import PrivateChangePassword from "./private-layout/change-password";
import EmailConfirmation from "./public-layout/email-confirmation";
import Home from "./private-layout/home";
import Settings from "./private-layout/settings";
import Domains from "./private-layout/domains";
import DomainsList from "./private-layout/domains/list";
import DomainsEdit from "./private-layout/domains/edit";
import Categories from "./private-layout/categories";
import CategoriesList from "./private-layout/categories/list";
import CategoriesEdit from "./private-layout/categories/edit";
import {BusinessUnitsRouteTabs} from "./private-layout/business-units";
import BusinessUnitsList from './private-layout/business-units/list';
import BusinessUnitsEdit from './private-layout/business-units/edit';
import BusinessUnitsScheme from './private-layout/business-units/scheme';
import AssetClasses from "./private-layout/asset-classes";
import QuantificationModelsList from "./private-layout/quantification-models/list";
import QuantificationModelsEdit from "./private-layout/quantification-models/edit";
import Profile from "./private-layout/profile";
import VendorsDashboard from "./private-layout/dashboard-vendor";
import AdminDashboard from "./private-layout/dashboard-admin";
import OrganizationDashboard from "./private-layout/dashboard-organization";
import Organization from "./private-layout/organization";
import OrganizationCyberInsuranceApplication from "./private-layout/organization-cyber-insurance-application";
import OrganizationCyberInsuranceInfo from "./private-layout/organization-cyber-insurance-info";
import OrganizationCyberRiskTolerance from "./private-layout/organization-cyber-risk-tolerance";
import Dashboards from "./private-layout/dashboards";
import VendorQuals from "./private-layout/vendor-quals";
import VendorQualsDomain from "./private-layout/vendor-quals-domain";
import VendorQuants from "./private-layout/vendor-quants";
import VendorQuantsDomain from "./private-layout/vendor-quants-domain";
import SystemQuals from "./private-layout/system-quals";
import SystemQualsDomain from "./private-layout/system-quals-domain";
import SystemQuants from "./private-layout/system-quants";
import SystemQuantsDomain from "./private-layout/system-quants-domain";
import ImportBusinessUnits from "./private-layout/import-business-units";
import ImportControlGuidelines from "./private-layout/import-control-guidelines";
import ImportGdprArticles from "./private-layout/import-gdpr-articles";
import ImportQuestions from "./private-layout/import-questions";
import ImportAnswers from "./private-layout/import-answers";
import ImportSystemsRisk from "./private-layout/import-systems-risk";
import ImportUsers from "./private-layout/import-users";
import ImportVendors from "./private-layout/import-vendors";
import ImportSubsidiaries from "./private-layout/import-subsidiaries";
import ImportAssessmentFramework from "./private-layout/import-assessment-framework";
import ImportGdprEvidenceArticles from "./private-layout/import-gdpr-evidence-articles";
import ImportSecurityRequirements from "./private-layout/import-security-requirements";
import Drilldown from "./private-layout/drilldown-page";
import OrganizationRiskScoring from "./private-layout/organization-risk-scoring";
import CybersecurityMaturity from "./private-layout/cybersecurity-maturity";
import QuickStart from "./private-layout/quick-start";
import KnowledgeBase from "./private-layout/knowledge-base";
import About from "./private-layout/about";

import QuantificationMetrics from "./private-layout/quantification-metrics";
import QuantificationMetricsList from "./private-layout/quantification-metrics/list";
import QuantificationMetricsEdit from "./private-layout/quantification-metrics/edit";
import QualitativeMetrics from "./private-layout/qualitative-metrics";
import QualitativeMetricsList from "./private-layout/qualitative-metrics/list";
import QualitativeMetricsEdit from "./private-layout/qualitative-metrics/edit";
import QualQuestions from "./private-layout/qualitative-questions";
import QualQuestionsList from "./private-layout/qualitative-questions/list";
import QualQuestionsEdit from "./private-layout/qualitative-questions/edit";
import RiskAnswersList from "./private-layout/risk-answers/list";
import RiskAnswersEdit from "./private-layout/risk-answers/edit";
import AssociateModels from "./private-layout/associate-models";
import AssociateModelsList from "./private-layout/associate-models/list";
import AssociateModelsEdit from "./private-layout/associate-models/edit";
import OrganizationsList from "./private-layout/organizations/list";
import OrganizationsEdit from "./private-layout/organizations/edit";
import RolesList from "./private-layout/roles/list";
import RolesEdit from "./private-layout/roles/edit";
import Users from "./private-layout/users";
import UsersList from "./private-layout/users/list";
import UsersEdit from "./private-layout/users/edit";
import AssetClassesList from "./private-layout/asset-classes/list";
import AssetClassesEdit from "./private-layout/asset-classes/edit";
import DataClasses from "./private-layout/data-classes";
import DataClassesList from "./private-layout/data-classes/list";
import DataClassesEdit from "./private-layout/data-classes/edit";
import DataDomains from "./private-layout/data-domains";
import DataDomainsList from "./private-layout/data-domains/list";
import DataDomainsEdit from "./private-layout/data-domains/edit";
import Systems from "./private-layout/systems";
import SystemsList from "./private-layout/systems/list";
import SystemsEdit from "./private-layout/systems/edit";
import TechnologyAssets from "./private-layout/technology-assets";
import TechnologyAssetsList from "./private-layout/technology-assets/list";
import TechnologyAssetsEdit from "./private-layout/technology-assets/edit";
import Processes from "./private-layout/processes";
import ProcessesList from "./private-layout/processes/list";
import ProcessesEdit from "./private-layout/processes/edit";
import TechCategories from "./private-layout/tech-categories";
import TechCategoriesList from "./private-layout/tech-categories/list";
import TechCategoriesEdit from "./private-layout/tech-categories/edit";
import Constants from "./private-layout/constants";
import ConstantsList from "./private-layout/constants/list";
import ConstantsEdit from "./private-layout/constants/edit";
import Technologies from "./private-layout/technologies";
import TechnologiesList from "./private-layout/technologies/list";
import TechnologiesEdit from "./private-layout/technologies/edit";
import AssessmentTypes from "./private-layout/assessment-types";
import AssessmentTypesList from "./private-layout/assessment-types/list";
import AssessmentTypesEdit from "./private-layout/assessment-types/edit";
import Assessments from "./private-layout/assessments";
import AssessmentsList from "./private-layout/assessments/list";
import AssessmentsEdit from "./private-layout/assessments/edit";
import ControlTestResults from "./private-layout/control-test-results";
import ControlTestResultsList from "./private-layout/control-test-results/list";
import ControlTestResultsEdit from "./private-layout/control-test-results/edit";
import AssessmentFindingsList from "./private-layout/assessment-findings/list";
import AssessmentFindingsEdit from "./private-layout/assessment-findings/edit";
import ControlsList from "./private-layout/controls/list";
import ControlsEdit from "./private-layout/controls/edit";
import AnsweredTestsList from "./private-layout/answered-tests/list";
import AnsweredTestsEdit from "./private-layout/answered-tests/edit";
import ControlGuidelinesList from "./private-layout/control-guidelines/list";
import ControlGuidelinesEdit from "./private-layout/control-guidelines/edit";
import ControlMaturities from "./private-layout/control-maturities";
import ControlMaturitiesList from "./private-layout/control-maturities/list";
import ControlMaturitiesEdit from "./private-layout/control-maturities/edit";
import Vendors from "./private-layout/vendors";
import VendorsList from "./private-layout/vendors/list";
import VendorsEdit from "./private-layout/vendors/edit";
import VendorsQuestions from "./private-layout/vendors/questions";
import AssociateSystems from "./private-layout/associate-systems";
import AssociateSystemsList from "./private-layout/associate-systems/list";
import AssociateSystemsEdit from "./private-layout/associate-systems/edit";
import DataExfiltration from "./private-layout/data-exfiltration";
import DataExfiltrationList from "./private-layout/data-exfiltration/list";
import DataExfiltrationEdit from "./private-layout/data-exfiltration/edit";
import BusinessInterruption from "./private-layout/business-interruption";
import BusinessInterruptionList from "./private-layout/business-interruption/list";
import BusinessInterruptionEdit from "./private-layout/business-interruption/edit";
import RegulatoryExposuresList from "./private-layout/regulatory-exposures/list";
import RegulatoryExposuresEdit from "./private-layout/regulatory-exposures/edit";
import ControlFunctions from "./private-layout/control-functions";
import ControlFunctionsList from "./private-layout/control-functions/list";
import ControlFunctionsEdit from "./private-layout/control-functions/edit";
import ControlCategories from "./private-layout/control-categories";
import ControlCategoriesList from "./private-layout/control-categories/list";
import ControlCategoriesEdit from "./private-layout/control-categories/edit";
import ControlSubcategories from "./private-layout/control-subcategories";
import ControlSubcategoriesList from "./private-layout/control-subcategories/list";
import ControlSubcategoriesEdit from "./private-layout/control-subcategories/edit";
import CyberRiskScoring from "./private-layout/cyber-risk-scoring";
import CyberRiskScoringList from "./private-layout/cyber-risk-scoring/list";
import CyberRiskScoringQuestions from "./private-layout/cyber-risk-scoring/questions";
import CloudScoring from "./private-layout/cloud-scoring";
import CloudScoringList from "./private-layout/cloud-scoring/list";
import CloudScoringQuestions from "./private-layout/cloud-scoring/questions";
import OperationalCosts from "./private-layout/operational-costs";
import OperationalCostsList from "./private-layout/operational-costs/list";
import OperationalCostsEdit from "./private-layout/operational-costs/edit";
import CapitalCosts from "./private-layout/capital-costs";
import CapitalCostsList from "./private-layout/capital-costs/list";
import CapitalCostsEdit from "./private-layout/capital-costs/edit";
import VariableCosts from "./private-layout/variable-costs";
import VariableCostsList from "./private-layout/variable-costs/list";
import VariableCostsEdit from "./private-layout/variable-costs/edit";
import SecurityToolsList from "./private-layout/security-tools/list";
import SecurityToolsEdit from "./private-layout/security-tools/edit";
import CyberSecurityToolsList from "./private-layout/cyber-security-tools/list";
import CyberSecurityToolsEdit from "./private-layout/cyber-security-tools/edit";
import CyberRoles from "./private-layout/cyber-roles";
import CyberRolesList from "./private-layout/cyber-roles/list";
import CyberRolesEdit from "./private-layout/cyber-roles/edit";
import SystemRiskReduction from "./private-layout/system-risk-reduction";
import SystemRiskReductionList from "./private-layout/system-risk-reduction/list";
import SystemRiskReductionEdit from "./private-layout/system-risk-reduction/edit";
import AuditLogs from "./private-layout/audit-logs";
import AuditLogsList from "./private-layout/audit-logs/list";
import AuditLogsEdit from "./private-layout/audit-logs/edit";
import GdprCompliance from "./private-layout/gdpr-compliance";
import GdprComplianceList from "./private-layout/gdpr-compliance/list";
import GdprComplianceEdit from "./private-layout/gdpr-compliance/edit";
import GdprComplianceQuestions from "./private-layout/gdpr-compliance/questions";
import GdprComplianceStatusLog from "./private-layout/gdpr-compliance/status-log";
import GdprSystemCompliance from "./private-layout/gdpr-system-compliance";
import GdprSystemComplianceList from "./private-layout/gdpr-system-compliance/list";
import GdprSystemComplianceEdit from "./private-layout/gdpr-system-compliance/edit";
import GdprSystemComplianceDrilldown from "./private-layout/gdpr-system-compliance/drilldown";
import GdprSystemComplianceSystemLog from "./private-layout/gdpr-system-compliance/system-log";
import GdprSystemComplianceQuestions from "./private-layout/gdpr-system-compliance/questions";
import GdprEvidenceDocuments from "./private-layout/gdpr-evidence-documents";
import GdprEvidenceDocumentsList from "./private-layout/gdpr-evidence-documents/list";
import GdprEvidenceDocumentsEdit from "./private-layout/gdpr-evidence-documents/edit";
import UserSettings from "./private-layout/user-settings";
import UserSettingsList from "./private-layout/user-settings/list";
import UserSettingsEdit from "./private-layout/user-settings/edit";
import Tasks from "./private-layout/tasks";
import TasksList from "./private-layout/tasks/list";
import TasksEdit from "./private-layout/tasks/edit";
import TaskCategories from "./private-layout/task-categories";
import TaskCategoriesList from "./private-layout/task-categories/list";
import TaskCategoriesEdit from "./private-layout/task-categories/edit";
import MyTasksList from "./private-layout/my-tasks/list";
import MyTasksEdit from "./private-layout/my-tasks/edit";
import PoliciesList from "./private-layout/policies/list";
import PoliciesEdit from "./private-layout/policies/edit";
import PoliciesAnnualReview from "./private-layout/policies/annualreview";
import PoliciesPreview from "./private-layout/policies/preview";
import VendorContractsList from './private-layout/vendor-contracts/list';
import VendorContractsEdit from './private-layout/vendor-contracts/edit';
import SecurityRequirements from "./private-layout/security-requirements";
import SecurityRequirementsList from "./private-layout/security-requirements/list";
import SecurityRequirementsEdit from "./private-layout/security-requirements/edit";
import SecurityRequirementsMapping from "./private-layout/security-requirements/edit/mapping";
import SecurityRequirementsSettings from "./private-layout/security-requirements/edit/settings";
import SystemControlTestResults from "./private-layout/system-control-test-results";
import SystemControlTestResultsList from "./private-layout/system-control-test-results/list";
import SystemControlTestResultsEdit from "./private-layout/system-control-test-results/edit";
import SystemControlTestResultsDrilldown from "./private-layout/system-control-test-results/drilldown";
import RiskMetrics from "./private-layout/risk-metrics";
import RiskMetricsList from "./private-layout/risk-metrics/list";
import RiskMetricsEdit from "./private-layout/risk-metrics/edit";
import Vulnerabilities from "./private-layout/vulnerabilities";
import VulnerabilitiesList from "./private-layout/vulnerabilities/list";
import VulnerabilitiesEdit from "./private-layout/vulnerabilities/edit";
import SubsidiariesList from "./private-layout/subsidiaries/list";
import SubsidiariesScheme from "./private-layout/subsidiaries/scheme";
import {SubsidiariesRouteTabs} from "./private-layout/subsidiaries";
import SubsidiariesEdit from "./private-layout/subsidiaries/edit";
import ImportProcesses from "./private-layout/import-processes";
import ImportTechnologies from "./private-layout/import-technologies";
import Redirect from "./components/redirect";
import Auth from "./services/auth.service";
import ImportQuantMetrics from "./private-layout/import-quant-metrics";

// configuration
let Root = connect(state => ({...state.app}), null)(RootView);

// Configure RTL
const jss = create({plugins: [...jssPreset().plugins, rtl()]});

// NOTE insert Root Component in ReactDOM
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
    <Provider store={store}>
        <StylesProvider jss={jss}>
            <Root />
        </StylesProvider>
    </Provider>
);

// Trying to store token used for future Auth
Auth.storeToken();

registerServiceWorker();

/**
 * Root view which should contain all common dependencies on views
 *
 * @public
 */

function RootView(props) {
    let {ready = false, health = false, direction} = props;

    // NOTE if API is not available show spec page
    if (!health) return (<CheckHealth />);
    // NOTE if application not initialized show preloader
    if (!ready) return (<PublicPreloader />);

    return (
        <div id="main" dir={direction === DIRECTION.RTL ? 'rtl' : 'ltr'}>
            <HistoryRouter history={history}>
                <Routes>
                    <Route
                        path='*'
                        element={<Navigate
                            to={ROUTES.PRIVATE + '/' + ROUTES.HOME.ROUTE}
                            replace />}
                    />
                    <Route
                        path="/"
                        element={<Navigate to={'public/' + ROUTES.SIGN_IN.ROUTE} replace />}
                    />
                    <Route path={ROUTES.PUBLIC} element={<PublicLayout />}>
                        <Route path={ROUTES.SIGN_IN.ROUTE} element={<SignIn />} />
                        <Route path={ROUTES.PUBLIC_WELCOME.ROUTE} element={<PublicWelcome />} />
                        <Route path={ROUTES.SIGN_UP.ROUTE} element={<SignUp />} />
                        <Route path={ROUTES.FORGOT_PASSWORD.ROUTE} element={<ForgotPassword />} />
                        <Route path={ROUTES.CHANGE_PASSWORD.ROUTE} element={<ChangePassword />} />
                        <Route path={ROUTES.EMAIL_CONFIRMATION.ROUTE} element={<EmailConfirmation />} />
                    </Route>
                    <Route path="*" element={<Redirect />} />
                    <Route path={ROUTES.PRIVATE} element={<PrivateLayout />}>
                        <Route path={ROUTES.HOME.ROUTE} element={<Home />} />
                        <Route path={ROUTES.SETTINGS.ROUTE} element={<Settings />} />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.DOMAINS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.DOMAINS.ROUTE + '/' + ROUTES.DOMAINS.LIST}
                                replace />}
                        />
                        <Route path={ROUTES.DOMAINS.ROUTE} element={<Domains />}>
                            <Route path={ROUTES.DOMAINS.LIST} element={<DomainsList />} />
                            <Route path={ROUTES.DOMAINS.EDIT} element={<DomainsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CATEGORIES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CATEGORIES.ROUTE + '/' + ROUTES.CATEGORIES.LIST}
                                replace />}
                        />
                        <Route path={CATEGORIES.ROUTE} element={<Categories />}>
                            <Route path={CATEGORIES.LIST} element={<CategoriesList />} />
                            <Route path={CATEGORIES.EDIT} element={<CategoriesEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.QUANT_MODELS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.QUANT_MODELS.ROUTE + '/' + ROUTES.QUANT_MODELS.LIST}
                                replace />}
                        />
                        <Route path={QUANT_MODELS.ROUTE} element={<QuantificationModelsList />}>
                            <Route path={QUANT_MODELS.LIST} element={<QuantificationModelsList />} />
                            <Route path={QUANT_MODELS.EDIT} element={<QuantificationModelsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.QUANT_METRICS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.QUANT_METRICS.ROUTE + '/' + ROUTES.QUANT_METRICS.LIST}
                                replace />}
                        />
                        <Route path={QUANT_METRICS.ROUTE} element={<QuantificationMetrics />}>
                            <Route path={QUANT_METRICS.LIST} element={<QuantificationMetricsList />} />
                            <Route path={QUANT_METRICS.EDIT} element={<QuantificationMetricsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.QUAL_METRICS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.QUAL_METRICS.ROUTE + '/' + ROUTES.QUAL_METRICS.LIST}
                                replace />}
                        />
                        <Route path={QUAL_METRICS.ROUTE} element={<QualitativeMetrics />}>
                            <Route path={QUAL_METRICS.LIST} element={<QualitativeMetricsList />} />
                            <Route path={QUAL_METRICS.EDIT} element={<QualitativeMetricsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.QUAL_QUESTIONS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.QUAL_QUESTIONS.ROUTE + '/' + ROUTES.QUAL_QUESTIONS.LIST}
                                replace />}
                        />
                        <Route path={QUAL_QUESTIONS.ROUTE} element={<QualQuestions />}>
                            <Route path={QUAL_QUESTIONS.LIST} element={<QualQuestionsList />} />
                            <Route path={QUAL_QUESTIONS.EDIT} element={<QualQuestionsEdit />} />
                        </Route>

                        {/*<Route path={ROUTES.RISK_ANSWERS.ROUTE} element={<RiskAnswers />} />*/}
                        <Route path={RISK_ANSWERS.LIST} element={<RiskAnswersList />} />
                        <Route path={RISK_ANSWERS.EDIT} element={<RiskAnswersEdit />} />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.ASSOCIATE_MODELS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.ASSOCIATE_MODELS.ROUTE + '/' + ROUTES.ASSOCIATE_MODELS.LIST}
                                replace />}
                        />
                        <Route path={ASSOCIATE_MODELS.ROUTE} element={<AssociateModels />}>
                            <Route path={ASSOCIATE_MODELS.LIST} element={<AssociateModelsList />} />
                            <Route path={ASSOCIATE_MODELS.EDIT} element={<AssociateModelsEdit />} />
                        </Route>

                        {/*<Route path={ROUTES.ORGANIZATIONS.ROUTE} element={<Organizations />} />*/}
                        <Route path={ORGANIZATIONS.LIST} element={<OrganizationsList />} />
                        <Route path={ORGANIZATIONS.EDIT} element={<OrganizationsEdit />} />

                        {/*<Route path={ROUTES.ROLES.ROUTE} element={<Roles />} />*/}
                        <Route path={ROLES.LIST} element={<RolesList />} />
                        <Route path={ROLES.EDIT} element={<RolesEdit />} />


                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.BUSINESS_UNITS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.BUSINESS_UNITS.ROUTE + '/' + ROUTES.BUSINESS_UNITS.LIST}
                                replace />}
                        />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.BUSINESS_UNITS.ROUTE + '/tabs'}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.BUSINESS_UNITS.ROUTE + '/' + ROUTES.BUSINESS_UNITS.LIST}
                                replace />}
                        />

                        <Route path={ROUTES.BUSINESS_UNITS.EDIT} element={<BusinessUnitsEdit />} />
                        <Route path={ROUTES.BUSINESS_UNITS.ROUTE} element={<BusinessUnitsRouteTabs />}>
                            <Route path={ROUTES.BUSINESS_UNITS.LIST} element={<BusinessUnitsList />} />
                            <Route path={ROUTES.BUSINESS_UNITS.SCHEME} element={<BusinessUnitsScheme />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.USERS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.USERS.ROUTE + '/' + ROUTES.USERS.LIST}
                                replace />}
                        />
                        <Route path={USERS.ROUTE} element={<Users />}>
                            <Route path={USERS.LIST} element={<UsersList />} />
                            <Route path={USERS.EDIT} element={<UsersEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.ASSET_CLASSES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.ASSET_CLASSES.ROUTE + '/' + ROUTES.ASSET_CLASSES.LIST}
                                replace />}
                        />

                        <Route path={ROUTES.ASSET_CLASSES.ROUTE} element={<AssetClasses />}>
                            <Route path={ASSET_CLASSES.LIST} element={<AssetClassesList />} />
                            <Route path={ASSET_CLASSES.EDIT} element={<AssetClassesEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.DATA_CLASSES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.DATA_CLASSES.ROUTE + '/' + ROUTES.DATA_CLASSES.LIST}
                                replace />}
                        />

                        <Route path={DATA_CLASSES.ROUTE} element={<DataClasses />}>
                            <Route path={DATA_CLASSES.LIST} element={<DataClassesList />} />
                            <Route path={DATA_CLASSES.EDIT} element={<DataClassesEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.DATA_DOMAINS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.DATA_DOMAINS.ROUTE + '/' + ROUTES.DATA_DOMAINS.LIST}
                                replace />}
                        />
                        <Route path={DATA_DOMAINS.ROUTE} element={<DataDomains />}>
                            <Route path={DATA_DOMAINS.LIST} element={<DataDomainsList />} />
                            <Route path={DATA_DOMAINS.EDIT} element={<DataDomainsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.SYSTEMS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.SYSTEMS.ROUTE + '/' + ROUTES.SYSTEMS.LIST}
                                replace />}
                        />
                        <Route path={SYSTEMS.ROUTE} element={<Systems />}>
                            <Route path={SYSTEMS.LIST} element={<SystemsList />} />
                            <Route path={SYSTEMS.EDIT} element={<SystemsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.TECHNOLOGY_ASSETS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.TECHNOLOGY_ASSETS.ROUTE + '/' + ROUTES.TECHNOLOGY_ASSETS.LIST}
                                replace />}
                        />
                        <Route path={TECHNOLOGY_ASSETS.ROUTE} element={<TechnologyAssets />}>
                            <Route path={TECHNOLOGY_ASSETS.LIST} element={<TechnologyAssetsList />} />
                            <Route path={TECHNOLOGY_ASSETS.EDIT} element={<TechnologyAssetsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.PROCESSES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.PROCESSES.ROUTE + '/' + ROUTES.PROCESSES.LIST}
                                replace />}
                        />
                        <Route path={PROCESSES.ROUTE} element={<Processes />}>
                            <Route path={PROCESSES.LIST} element={<ProcessesList />} />
                            <Route path={PROCESSES.EDIT} element={<ProcessesEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.TECH_CATEGORIES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.TECH_CATEGORIES.ROUTE + '/' + ROUTES.TECH_CATEGORIES.LIST}
                                replace />}
                        />
                        <Route path={TECH_CATEGORIES.ROUTE} element={<TechCategories />}>
                            <Route path={TECH_CATEGORIES.LIST} element={<TechCategoriesList />} />
                            <Route path={TECH_CATEGORIES.EDIT} element={<TechCategoriesEdit />} />
                        </Route>
                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CONSTANTS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CONSTANTS.ROUTE + '/' + ROUTES.CONSTANTS.LIST}
                                replace />}
                        />
                        <Route path={CONSTANTS.ROUTE} element={<Constants />}>
                            <Route path={CONSTANTS.LIST} element={<ConstantsList />} />
                            <Route path={CONSTANTS.EDIT} element={<ConstantsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.TECHNOLOGIES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.TECHNOLOGIES.ROUTE + '/' + ROUTES.TECHNOLOGIES.LIST}
                                replace />}
                        />
                        <Route path={TECHNOLOGIES.ROUTE} element={<Technologies />}>
                            <Route path={TECHNOLOGIES.LIST} element={<TechnologiesList />} />
                            <Route path={TECHNOLOGIES.EDIT} element={<TechnologiesEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.ASSESSMENT_TYPES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.ASSESSMENT_TYPES.ROUTE + '/' + ROUTES.ASSESSMENT_TYPES.LIST}
                                replace />}
                        />
                        <Route path={ASSESSMENT_TYPES.ROUTE} element={<AssessmentTypes />}>
                            <Route path={ASSESSMENT_TYPES.LIST} element={<AssessmentTypesList />} />
                            <Route path={ASSESSMENT_TYPES.EDIT} element={<AssessmentTypesEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.ASSESSMENTS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.ASSESSMENTS.ROUTE + '/' + ROUTES.ASSESSMENTS.LIST}
                                replace />}
                        />
                        <Route path={ASSESSMENTS.ROUTE} element={<Assessments />}>
                            <Route path={ASSESSMENTS.LIST} element={<AssessmentsList />} />
                            <Route path={ASSESSMENTS.EDIT} element={<AssessmentsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CONTROL_TEST_RESULTS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CONTROL_TEST_RESULTS.ROUTE + '/' + ROUTES.CONTROL_TEST_RESULTS.LIST}
                                replace />}
                        />
                        <Route path={CONTROL_TEST_RESULTS.ROUTE} element={<ControlTestResults />}>
                            <Route path={CONTROL_TEST_RESULTS.LIST} element={<ControlTestResultsList />} />
                            <Route path={CONTROL_TEST_RESULTS.EDIT} element={<ControlTestResultsEdit />} />
                        </Route>

                        {/*<Route path={ROUTES.ASSESSMENT_FINDINGS.ROUTE} element={AssessmentFindings} />*/}
                        <Route path={ASSESSMENT_FINDINGS.LIST} element={<AssessmentFindingsList />} />
                        <Route path={ASSESSMENT_FINDINGS.EDIT} element={<AssessmentFindingsEdit />} />

                        {/*<Route path={ROUTES.CONTROLS.ROUTE} element={Controls} />*/}
                        <Route path={CONTROLS.LIST} element={<ControlsList />} />
                        <Route path={CONTROLS.EDIT} element={<ControlsEdit />} />

                        {/*<Route path={ROUTES.ANSWERED_TESTS.ROUTE} element={AnsweredTests} />*/}
                        <Route path={ANSWERED_TESTS.LIST} element={<AnsweredTestsList />} />
                        <Route path={ANSWERED_TESTS.EDIT} element={<AnsweredTestsEdit />} />

                        {/*<Route path={ROUTES.CONTROL_GUIDELINES.ROUTE} element={ControlGuidelines} />*/}
                        <Route path={CONTROL_GUIDELINES.LIST} element={<ControlGuidelinesList />} />
                        <Route path={CONTROL_GUIDELINES.EDIT} element={<ControlGuidelinesEdit />} />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CONTROL_MATURITIES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CONTROL_MATURITIES.ROUTE + '/' + ROUTES.CONTROL_MATURITIES.LIST}
                                replace />}
                        />
                        <Route path={CONTROL_MATURITIES.ROUTE} element={<ControlMaturities />}>
                            <Route path={CONTROL_MATURITIES.LIST} element={<ControlMaturitiesList />} />
                            <Route path={CONTROL_MATURITIES.EDIT} element={<ControlMaturitiesEdit />} />
                        </Route>


                        <Route path={VENDORS.ROUTE} element={<Vendors />}>
                            <Route path={VENDORS.LIST} element={<VendorsList />} />
                            <Route path={VENDORS.EDIT} element={<VendorsEdit />} />
                            <Route path={VENDORS.QUESTIONS} element={<VendorsQuestions />} />
                        </Route>


                        <Route path={ASSOCIATE_SYSTEMS.ROUTE} element={<AssociateSystems />}>
                            <Route path={ASSOCIATE_SYSTEMS.LIST} element={<AssociateSystemsList />} />
                            <Route path={ASSOCIATE_SYSTEMS.EDIT} element={<AssociateSystemsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.DATA_EXFILTRATION.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.DATA_EXFILTRATION.ROUTE + '/' + ROUTES.DATA_EXFILTRATION.LIST}
                                replace />}
                        />
                        <Route path={DATA_EXFILTRATION.ROUTE} element={<DataExfiltration />}>
                            <Route path={DATA_EXFILTRATION.LIST} element={<DataExfiltrationList />} />
                            <Route path={DATA_EXFILTRATION.EDIT} element={<DataExfiltrationEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.BUSINESS_INTERRUPTION.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.BUSINESS_INTERRUPTION.ROUTE + '/' + ROUTES.BUSINESS_INTERRUPTION.LIST}
                                replace />}
                        />
                        <Route path={BUSINESS_INTERRUPTION.ROUTE} element={<BusinessInterruption />}>
                            <Route path={BUSINESS_INTERRUPTION.LIST} element={<BusinessInterruptionList />} />
                            <Route path={BUSINESS_INTERRUPTION.EDIT} element={<BusinessInterruptionEdit />} />
                        </Route>

                        {/*<Route path={ROUTES.REGULATORY_EXPOSURES.ROUTE} element={RegulatoryExposures} />*/}
                        <Route path={REGULATORY_EXPOSURES.LIST} element={<RegulatoryExposuresList />} />
                        <Route path={REGULATORY_EXPOSURES.EDIT} element={<RegulatoryExposuresEdit />} />

                        <Route path={ROUTES.PROFILE.ROUTE} element={<Profile />} />
                        <Route path={ROUTES.PRIVATE_CHANGE_PASSWORD.ROUTE} element={<PrivateChangePassword />} />
                        <Route path={ROUTES.VENDORS_DASHBOARD.ROUTE} element={<VendorsDashboard />} />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CONTROL_FUNCTIONS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CONTROL_FUNCTIONS.ROUTE + '/' + ROUTES.CONTROL_FUNCTIONS.LIST}
                                replace />}
                        />
                        <Route path={CONTROL_FUNCTIONS.ROUTE} element={<ControlFunctions />}>
                            <Route path={CONTROL_FUNCTIONS.LIST} element={<ControlFunctionsList />} />
                            <Route path={CONTROL_FUNCTIONS.EDIT} element={<ControlFunctionsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CONTROL_CATEGORIES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CONTROL_CATEGORIES.ROUTE + '/' + ROUTES.CONTROL_CATEGORIES.LIST}
                                replace />}
                        />
                        <Route path={CONTROL_CATEGORIES.ROUTE} element={<ControlCategories />}>
                            <Route path={CONTROL_CATEGORIES.LIST} element={<ControlCategoriesList />} />
                            <Route path={CONTROL_CATEGORIES.EDIT} element={<ControlCategoriesEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CONTROL_SUBCATEGORIES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CONTROL_SUBCATEGORIES.ROUTE + '/' + ROUTES.CONTROL_SUBCATEGORIES.LIST}
                                replace />}
                        />
                        <Route path={CONTROL_SUBCATEGORIES.ROUTE} element={<ControlSubcategories />}>
                            <Route path={CONTROL_SUBCATEGORIES.LIST} element={<ControlSubcategoriesList />} />
                            <Route path={CONTROL_SUBCATEGORIES.EDIT} element={<ControlSubcategoriesEdit />} />
                        </Route>

                        <Route path={ROUTES.ADMIN_DASHBOARD.ROUTE} element={<AdminDashboard />} />
                        <Route path={ROUTES.ORGANIZATIONS_DASHBOARD.ROUTE} element={<OrganizationDashboard />} />
                        <Route path={ROUTES.ORGANIZATION.ROUTE} element={<Organization />} />
                        <Route path={ROUTES.ORGANIZATION_CYBER_INSURANCE_APPLICATION.ROUTE} element={<OrganizationCyberInsuranceApplication />} />
                        <Route path={ROUTES.ORGANIZATION_CYBER_INSURANCE_INFO.ROUTE} element={<OrganizationCyberInsuranceInfo />} />
                        <Route path={ROUTES.ORGANIZATION_CYBER_RISK_TOLERANCE.ROUTE} element={<OrganizationCyberRiskTolerance />} />
                        <Route
                            path={ROUTES.PRIVATE + '/dashboards'}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/dashboards/3'}
                                replace />}
                        />
                        <Route path={ROUTES.DASHBOARDS.ROUTE} element={<Dashboards />} />
                        <Route path={ROUTES.VENDOR_QUALS.ROUTE} element={<VendorQuals />} />
                        <Route path={ROUTES.VENDOR_QUALS_DOMAIN.ROUTE} element={<VendorQualsDomain />} />
                        <Route path={ROUTES.VENDOR_QUANTS.ROUTE} element={<VendorQuants />} />
                        <Route path={ROUTES.VENDOR_QUANTS_DOMAIN.ROUTE} element={<VendorQuantsDomain />} />
                        <Route path={ROUTES.SYSTEM_QUALS.ROUTE} element={<SystemQuals />} />
                        <Route path={ROUTES.SYSTEM_QUALS_DOMAIN.ROUTE} element={<SystemQualsDomain />} />
                        <Route path={ROUTES.SYSTEM_QUANTS.ROUTE} element={<SystemQuants />} />
                        <Route path={ROUTES.SYSTEM_QUANTS_DOMAIN.ROUTE} element={<SystemQuantsDomain />} />
                        {/*TODO: rewrite component*/}
                        {/*<Route path={ROUTES.SUBSIDIARIES.ROUTE} element={<Subsidiaries/>}>*/}
                        {/*</Route>*/}


                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.SUBSIDIARIES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.SUBSIDIARIES.ROUTE + '/' + ROUTES.SUBSIDIARIES.LIST}
                                replace />}
                        />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.SUBSIDIARIES.ROUTE + '/tabs'}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.SUBSIDIARIES.ROUTE + '/' + ROUTES.SUBSIDIARIES.LIST}
                                replace />}
                        />

                        <Route path={SUBSIDIARIES.EDIT} element={<SubsidiariesEdit />} />
                        <Route path={SUBSIDIARIES.ROUTE} element={<SubsidiariesRouteTabs />}>
                            <Route path={SUBSIDIARIES.LIST} element={<SubsidiariesList />} />
                            <Route path={SUBSIDIARIES.SCHEME} element={<SubsidiariesScheme />} />
                        </Route>


                        <Route path={ROUTES.IMPORT_BUSINESS_UNITS.ROUTE} element={<ImportBusinessUnits />} />
                        <Route path={ROUTES.IMPORT_CONTROL_GUIDELINES.ROUTE} element={<ImportControlGuidelines />} />
                        <Route path={ROUTES.IMPORT_GDPR_ARTICLES.ROUTE} element={<ImportGdprArticles />} />
                        <Route path={ROUTES.IMPORT_QUAL_QUESTIONS.ROUTE} element={<ImportQuestions />} />
                        <Route path={ROUTES.IMPORT_QUAL_QUESTION_ANSWERS.ROUTE} element={<ImportAnswers />} />
                        <Route path={ROUTES.IMPORT_SYSTEMS_RISK.ROUTE} element={<ImportSystemsRisk />} />
                        <Route path={ROUTES.IMPORT_USERS.ROUTE} element={<ImportUsers />} />
                        <Route path={ROUTES.IMPORT_VENDORS.ROUTE} element={<ImportVendors />} />
                        <Route path={ROUTES.IMPORT_SUBSIDIARIES.ROUTE} element={<ImportSubsidiaries />} />
                        <Route path={ROUTES.IMPORT_GDPR_EVIDENCE_ARTICLES.ROUTE}
                               element={<ImportGdprEvidenceArticles />} />
                        <Route path={ROUTES.IMPORT_ASSESSMENT_FRAMEWORK.ROUTE}
                               element={<ImportAssessmentFramework />} />
                        <Route path={ROUTES.IMPORT_SECURITY_REQUIREMENTS.ROUTE}
                               element={<ImportSecurityRequirements />} />
                        <Route path={ROUTES.IMPORT_PROCESSES.ROUTE}
                               element={<ImportProcesses />} />
                        <Route path={ROUTES.IMPORT_TECHNOLOGIES.ROUTE}
                               element={<ImportTechnologies />} />
                        <Route path={ROUTES.IMPORT_QUANT_METRICS.ROUTE}
                               element={<ImportQuantMetrics />} />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CYBER_RISK_SCORING.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CYBER_RISK_SCORING.ROUTE + '/' + ROUTES.CYBER_RISK_SCORING.LIST}
                                replace />}
                        />
                        <Route path={CYBER_RISK_SCORING.ROUTE} element={<CyberRiskScoring />}>
                            <Route path={CYBER_RISK_SCORING.LIST} element={<CyberRiskScoringList />} />
                            <Route path={CYBER_RISK_SCORING.QUESTIONS} element={<CyberRiskScoringQuestions />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CLOUD_SCORING.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CLOUD_SCORING.ROUTE + '/' + ROUTES.CLOUD_SCORING.LIST}
                                replace />}
                        />
                        <Route path={CLOUD_SCORING.ROUTE} element={<CloudScoring />}>
                            <Route path={CLOUD_SCORING.LIST} element={<CloudScoringList />} />
                            <Route path={CLOUD_SCORING.QUESTIONS} element={<CloudScoringQuestions />} />
                        </Route>

                        <Route path={ROUTES.DRILLDOWN_PAGE.ROUTE} element={<Drilldown />} />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.OPERATIONAL_COSTS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.OPERATIONAL_COSTS.ROUTE + '/' + ROUTES.OPERATIONAL_COSTS.LIST}
                                replace />}
                        />
                        <Route path={OPERATIONAL_COSTS.ROUTE} element={<OperationalCosts />}>
                            <Route path={OPERATIONAL_COSTS.LIST} element={<OperationalCostsList />} />
                            <Route path={OPERATIONAL_COSTS.EDIT} element={<OperationalCostsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CAPITAL_COSTS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CAPITAL_COSTS.ROUTE + '/' + ROUTES.CAPITAL_COSTS.LIST}
                                replace />}
                        />
                        <Route path={CAPITAL_COSTS.ROUTE} element={<CapitalCosts />}>
                            <Route path={CAPITAL_COSTS.LIST} element={<CapitalCostsList />} />
                            <Route path={CAPITAL_COSTS.EDIT} element={<CapitalCostsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.VARIABLE_COSTS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.VARIABLE_COSTS.ROUTE + '/' + ROUTES.VARIABLE_COSTS.LIST}
                                replace />}
                        />
                        <Route path={VARIABLE_COSTS.ROUTE} element={<VariableCosts />}>
                            <Route path={VARIABLE_COSTS.LIST} element={<VariableCostsList />} />
                            <Route path={VARIABLE_COSTS.EDIT} element={<VariableCostsEdit />} />
                        </Route>

                        {/*<Route path={ROUTES.SECURITY_TOOLS.ROUTE} element={SecurityTools} />*/}
                        <Route path={SECURITY_TOOLS.LIST} element={<SecurityToolsList />} />
                        <Route path={SECURITY_TOOLS.EDIT} element={<SecurityToolsEdit />} />

                        {/*<Route path={ROUTES.CYBER_SECURITY_TOOLS.ROUTE} element={CyberSecurityTools} />*/}
                        <Route path={CYBER_SECURITY_TOOLS.LIST} element={<CyberSecurityToolsList />} />
                        <Route path={CYBER_SECURITY_TOOLS.EDIT} element={<CyberSecurityToolsEdit />} />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.CYBER_ROLES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.CYBER_ROLES.ROUTE + '/' + ROUTES.CYBER_ROLES.LIST}
                                replace />}
                        />
                        <Route path={CYBER_ROLES.ROUTE} element={<CyberRoles />}>
                            <Route path={CYBER_ROLES.LIST} element={<CyberRolesList />} />
                            <Route path={CYBER_ROLES.EDIT} element={<CyberRolesEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.SYSTEM_RISK_REDUCTION.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.SYSTEM_RISK_REDUCTION.ROUTE + '/' + ROUTES.SYSTEM_RISK_REDUCTION.LIST}
                                replace />}
                        />
                        <Route path={SYSTEM_RISK_REDUCTION.ROUTE} element={<SystemRiskReduction />}>
                            <Route path={SYSTEM_RISK_REDUCTION.LIST} element={<SystemRiskReductionList />} />
                            <Route path={SYSTEM_RISK_REDUCTION.EDIT} element={<SystemRiskReductionEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.AUDIT_LOGS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.AUDIT_LOGS.ROUTE + '/' + ROUTES.AUDIT_LOGS.LIST}
                                replace />}
                        />
                        <Route path={AUDIT_LOGS.ROUTE} element={<AuditLogs />}>
                            <Route path={AUDIT_LOGS.LIST} element={<AuditLogsList />} />
                            <Route path={AUDIT_LOGS.EDIT} element={<AuditLogsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.GDPR_COMPLIANCE.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.GDPR_COMPLIANCE.ROUTE + '/' + ROUTES.GDPR_COMPLIANCE.LIST}
                                replace />}
                        />
                        <Route path={GDPR_COMPLIANCE.ROUTE} element={<GdprCompliance />}>
                            <Route path={GDPR_COMPLIANCE.LIST} element={<GdprComplianceList />} />
                            <Route path={GDPR_COMPLIANCE.EDIT} element={<GdprComplianceEdit />} />
                            <Route path={GDPR_COMPLIANCE.QUESTIONS} element={<GdprComplianceQuestions />} />
                            <Route path={GDPR_COMPLIANCE.STATUS_LOG} element={<GdprComplianceStatusLog />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.GDPR_SYSTEM_COMPLIANCE.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.GDPR_SYSTEM_COMPLIANCE.ROUTE + '/' + ROUTES.GDPR_SYSTEM_COMPLIANCE.LIST}
                                replace />}
                        />
                        <Route path={GDPR_SYSTEM_COMPLIANCE.ROUTE} element={<GdprSystemCompliance />}>
                            <Route path={GDPR_SYSTEM_COMPLIANCE.LIST} element={<GdprSystemComplianceList />} />
                            <Route path={GDPR_SYSTEM_COMPLIANCE.EDIT} element={<GdprSystemComplianceEdit />} />
                            <Route path={GDPR_SYSTEM_COMPLIANCE.DRILLDOWN}
                                   element={<GdprSystemComplianceDrilldown />} />
                            <Route path={GDPR_SYSTEM_COMPLIANCE.SYSTEM_LOG}
                                   element={<GdprSystemComplianceSystemLog />} />
                            <Route path={GDPR_SYSTEM_COMPLIANCE.QUESTIONS}
                                   element={<GdprSystemComplianceQuestions />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.GDPR_EVIDENCE_DOCUMENTS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.GDPR_EVIDENCE_DOCUMENTS.ROUTE + '/' + ROUTES.GDPR_EVIDENCE_DOCUMENTS.LIST}
                                replace />}
                        />
                        <Route path={GDPR_EVIDENCE_DOCUMENTS.ROUTE} element={<GdprEvidenceDocuments />}>
                            <Route path={GDPR_EVIDENCE_DOCUMENTS.LIST} element={<GdprEvidenceDocumentsList />} />
                            <Route path={GDPR_EVIDENCE_DOCUMENTS.EDIT} element={<GdprEvidenceDocumentsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.USER_SETTINGS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.USER_SETTINGS.ROUTE + '/' + ROUTES.USER_SETTINGS.LIST}
                                replace />}
                        />
                        <Route path={USER_SETTINGS.ROUTE} element={<UserSettings />}>
                            <Route path={USER_SETTINGS.LIST} element={<UserSettingsList />} />
                            <Route path={USER_SETTINGS.EDIT} element={<UserSettingsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.TASKS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.TASKS.ROUTE + '/' + ROUTES.TASKS.LIST}
                                replace />}
                        />
                        <Route path={TASKS.ROUTE} element={<Tasks />}>
                            <Route path={TASKS.LIST} element={<TasksList />} />
                            <Route path={TASKS.EDIT} element={<TasksEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.TASK_CATEGORIES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.TASK_CATEGORIES.ROUTE + '/' + ROUTES.TASK_CATEGORIES.LIST}
                                replace />}
                        />
                        <Route path={TASK_CATEGORIES.ROUTE} element={<TaskCategories />}>
                            <Route path={TASK_CATEGORIES.LIST} element={<TaskCategoriesList />} />
                            <Route path={TASK_CATEGORIES.EDIT} element={<TaskCategoriesEdit />} />
                        </Route>


                        <Route path={MY_TASKS.LIST} element={<MyTasksList />} />
                        <Route path={MY_TASKS.EDIT} element={<MyTasksEdit />} />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.POLICIES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.POLICIES.ROUTE + '/' + ROUTES.POLICIES.LIST}
                                replace />}
                        />
                        <Route path={POLICIES.ROUTE}>
                            <Route path={POLICIES.LIST} element={<PoliciesList />} />
                            <Route path={POLICIES.EDIT} element={<PoliciesEdit />} />
                            <Route path={POLICIES.ANNUAL_REVIEW} element={<PoliciesAnnualReview />} />
                            <Route path={POLICIES.PREVIEW} element={<PoliciesPreview />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.VENDOR_CONTRACTS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.VENDOR_CONTRACTS.ROUTE + '/' + ROUTES.VENDOR_CONTRACTS.LIST}
                                replace />}
                        />
                        <Route path={VENDOR_CONTRACTS.ROUTE}>
                            <Route path={VENDOR_CONTRACTS.LIST} element={<VendorContractsList />} />
                            <Route path={VENDOR_CONTRACTS.EDIT} element={<VendorContractsEdit />} />
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.SECURITY_REQUIREMENTS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.SECURITY_REQUIREMENTS.ROUTE + '/' + ROUTES.SECURITY_REQUIREMENTS.LIST}
                                replace />}
                        />
                        <Route path={SECURITY_REQUIREMENTS.ROUTE} element={<SecurityRequirements />}>
                            <Route path={SECURITY_REQUIREMENTS.LIST} element={<SecurityRequirementsList />} />
                            <Route path={ROUTES.SECURITY_REQUIREMENTS.EDIT_ROUTE}
                                   element={<SecurityRequirementsEdit />}>
                                <Route path={ROUTES.SECURITY_REQUIREMENTS.EDIT_MAPPING}
                                       element={<SecurityRequirementsMapping />} />
                                <Route path={ROUTES.SECURITY_REQUIREMENTS.EDIT_SETTINGS}
                                       element={<SecurityRequirementsSettings />} />
                            </Route>
                        </Route>

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.SYSTEM_CONTROL_TEST_RESULTS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.SYSTEM_CONTROL_TEST_RESULTS.ROUTE + '/' + ROUTES.SYSTEM_CONTROL_TEST_RESULTS.LIST}
                                replace />}
                        />
                        <Route path={SYSTEM_CONTROL_TEST_RESULTS.ROUTE} element={<SystemControlTestResults />}>
                            <Route path={SYSTEM_CONTROL_TEST_RESULTS.LIST} element={<SystemControlTestResultsList />} />
                            <Route path={SYSTEM_CONTROL_TEST_RESULTS.EDIT} element={<SystemControlTestResultsEdit />} />
                            <Route path={SYSTEM_CONTROL_TEST_RESULTS.DRILLDOWN}
                                   element={<SystemControlTestResultsDrilldown />} />
                            <Route path={SYSTEM_CONTROL_TEST_RESULTS.AUDIT}
                                   element={<SystemControlTestResultsEdit />} />
                        </Route>


                        <Route path={ROUTES.ORGANIZATION_RISK_SCORING.ROUTE} element={<OrganizationRiskScoring />} />
                        <Route path={ROUTES.CYBERSECURITY_MATURITY.ROUTE} element={<CybersecurityMaturity />} />
                        <Route path={ROUTES.CYBERSECURITY_MATURITY.SUBPAGE_ROUTE} element={<CybersecurityMaturity />} navigator={"push"} />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.RISK_METRICS.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.RISK_METRICS.ROUTE + '/' + ROUTES.RISK_METRICS.LIST}
                                replace />}
                        />
                        <Route path={RISK_METRICS.ROUTE} element={<RiskMetrics />}>
                            <Route path={RISK_METRICS.LIST} element={<RiskMetricsList />} />
                            <Route path={RISK_METRICS.EDIT} element={<RiskMetricsEdit />} />
                        </Route>

                        <Route path={ROUTES.PRIVACY_IMPACT_ASSESSMENT.ROUTE} element={<Dashboards />} />

                        <Route
                            path={ROUTES.PRIVATE + '/' + ROUTES.VULNERABILITIES.ROUTE}
                            element={<Navigate
                                to={ROUTES.PRIVATE + '/' + ROUTES.VULNERABILITIES.ROUTE + '/' + ROUTES.VULNERABILITIES.LIST}
                                replace />}
                        />
                        <Route path={VULNERABILITIES.ROUTE} element={<Vulnerabilities />}>
                            <Route path={VULNERABILITIES.LIST} element={<VulnerabilitiesList />} />
                            <Route path={VULNERABILITIES.EDIT} element={<VulnerabilitiesEdit />} />
                        </Route>

                        <Route path={ROUTES.QUICK_START.ROUTE} element={<QuickStart />} />
                        <Route path={ROUTES.KNOWLEDGE_BASE.ROUTE} element={<KnowledgeBase />} />
                        <Route path={ROUTES.ABOUT.ROUTE} element={<About />} />
                    </Route>
                </Routes>
            </HistoryRouter>
            <ReduxToastr
                timeOut={2000}
                progressBar={true}
                newestOnTop={false}
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                preventDuplicates={true}
            >
            </ReduxToastr>
        </div>
    )
        ;
}

/**
 * NOTE the same preloader as on "public/index.html"
 *
 * @public
 */
function PublicPreloader() {
    return (
        <div className="def-preloader">
            <div id="SPW">
                <div id="SP_1" className="sp"></div>
                <div id="SP_2" className="sp"></div>
                <div id="SP_3" className="sp"></div>
                <div id="SP_4" className="sp"></div>
                <div id="SP_5" className="sp"></div>
                <div id="SP_6" className="sp"></div>
                <div id="SP_7" className="sp"></div>
                <div id="SP_8" className="sp"></div>
            </div>
        </div>
    );
}
