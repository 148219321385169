
// outsource dependencies

// local dependencies
import { LIST } from "../actions";

let initial = {
    item: {},
    list: [],
    modalList: [],
    meta: {
        hintsData: [],
        initialized: false,
        expectAnswer: false,
        modalLoading: false,
        errorMessage: null,
    }
};

export default function ( state = initial, action ) {
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case LIST.CLEAR:
            state = initial;
            break;
        case LIST.LIST:
            state = { ...state, list: options.list };
            break;
        case LIST.MODAL_LIST:
            state = { ...state, modalList: options.modalList };
            break;
        case LIST.META:
            state = { ...state, meta: {...state.meta, ...options} };
            break;
    }
    // /@domains/.test(type)&&
    // console.log(`%c DOMAINS ${type} `, 'color: #fff; background: #232323; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n action:', action
    //     // ,'\n arguments:', arguments
    // );
    return state;
}
