
// outsource dependencies
import { call, put, takeEvery, take, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from './actions';
import { instanceAPI } from '../../services/api.service';

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.GET_DATA.REQUEST, getDataSaga);
}

function* initializeSaga () {
    yield put({type: EDIT.CLEAR});
    // NOTE get list
    yield put({type: EDIT.GET_DATA.REQUEST});
    yield take(EDIT.GET_DATA.FINISH);
    yield put({type: EDIT.META, initialized: true});
}

function* getDataSaga () {
    try {
        let { riskModel } = yield select( state => state.app );
        let data = yield call( getData, riskModel.id );
        // NOTE setup data
        yield put({type: EDIT.DATA, data});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_DATA.FINISH});
}

/**
 * get data
 *
 * param (Number)
 * @private
 */
function getData( riskModelId ) {
    return instanceAPI({method: 'get', url: '/dashboards/2', headers: {'risk-model-id': riskModelId}});
}
