// outsource dependencies
import {every, get} from 'lodash';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
//import { Prompt } from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Tab, Tabs} from '@mui/material';
import {Field, FieldArray, isPristine, reduxForm} from 'redux-form';

// local dependencies
import {QUESTIONS} from '../actions';
import is from '../../../services/is.service';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {QuestionRow} from '../../../components/question-row';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {GDPR_SYSTEM_COMPLIANCE_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import getSystemName from '../../../helpers/getSystemName';
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const Questions = (props) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    // let isNew = id === NEW_ID;
    let {pristine, clearError, message, hints} = props;
    const breadcrumbMap = {
        ...GDPR_SYSTEM_COMPLIANCE_MAP,
        '/private/gdpr-system-compliance/questions': {id: 13, title: `${props.data.name ? props.data.name + ' / ' : ''} ${translate('SCORING_QUESTIONS$TITLE')}  /  ${props.data.metricDomainCode ? getSystemName(props.data.metricDomainCode) : 'All'}`, parent: 11},
    }
    return (<div style={{height: '100%', overflowY: 'auto'}}>
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ breadcrumbMap }  />
            <ConnectedInitializer>
                <Row className="offset-top-2">
                    <Col xs={12}>
                        <Row className="offset-bottom-4">
                            <Col xs={12} lg={pristine ? 12 : 8}>
                                <h2 className="text-uppercase">
                                    <RichHintTitle
                                        update={QUESTIONS}
                                        name={`SCORING_QUESTIONS$TITLE`}
                                        data={findHint(hints, `GDPR_SYSTEM_COMPLIANCE_SCORING_QUESTIONS_TITLE`)}/>
                                </h2>
                            </Col>
                            {!pristine&&<Col xs={12} lg={4}>
                                <h3 className="text-danger text-right top-indent-1"><strong>
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true" />&nbsp;{translate('GLOBALS$UNSAVED_DATA')}
                                </strong></h3>
                            </Col>}
                        </Row>
                        <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError} /> </Col> </Row>
                        <Row> <Col xs={12}> <ConnectedForm /> </Col> </Row>
                        {/*NOTE block navigating away from a page and show message*/}
                        {/*<Prompt when={!pristine} message={translate('GLOBALS$UNSAVED_DATA')} />*/}
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    </div>);
}

export default connect(
    state => ({
        data: state.gdprSystemCompliance.questions.data,
        pristine: isPristine('scoringQuestions')(state),
        message: state.gdprSystemCompliance.questions.errorMessage,
        disabled: state.gdprSystemCompliance.questions.expectAnswer,
        hints: state.gdprSystemCompliance.questions.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: QUESTIONS.CLEAR}),
        initialize: id => dispatch({type: QUESTIONS.INITIALIZE, id}),
        clearError: () => dispatch({ type: QUESTIONS.META, errorMessage: null}),
    })
)(Questions)

const ConnectedInitializer = connect(
    state => ({initialize: state.gdprSystemCompliance.questions.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        metric: state.gdprSystemCompliance.questions.metric,
        initialValues: state.gdprSystemCompliance.questions.data,
        disabled: state.gdprSystemCompliance.questions.expectAnswer,
        metricDomains: state.gdprSystemCompliance.questions.metricDomains,
        questionsLength: get(state, 'gdprSystemCompliance.questions.data.questions.length', 0),
        hints: state.gdprSystemCompliance.questions.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: QUESTIONS.CANCEL}),
        update: formData => dispatch({type: QUESTIONS.UPDATE, ...formData}),
        changeMetric: metric => dispatch({type: QUESTIONS.UPDATE_METRIC, metric}),
        uploadFile: (file, fieldName) => dispatch({type: QUESTIONS.UPLOAD_FILE, file, fieldName}),
    })
)(reduxForm({
    form: 'scoringQuestions',
    enableReinitialize: true,
    warn: values => {
        const warnings = {};
        let map = {};
        // NOTE build question - answer map
        (values.questions||[]).forEach(item => {
            map[item.id] = get(item, 'selectedAnswers.id', null);
        });
        // NOTE check branching logic
        let branchingWarns = [];
        (values.questions||[]).forEach((item, index) => {
            let warns = {};
            let branchingLogic = get(item, 'branchingLogic', []);
            let isVisible = every(branchingLogic, branching => {
                const questionId = get(branching, 'question.id'),
                    answerId = get(branching, 'answer.id');
                return map[questionId] === answerId;
            });
            if (!isVisible) {
                warns.isHidden = true;
            }
            branchingWarns[index] = warns;
        });
        if ( !is.empty(branchingWarns) ) {
            warnings.questions = branchingWarns;
        }
        return warnings;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, questionsLength, uploadFile, changeMetric, metric, metricDomains, cancel, hints})=>(
    <form autoComplete="off" name="scoringQuestions" onSubmit={handleSubmit(update)}>
        <Paper square>
            <Tabs
                value={metric}
                textColor="primary"
                variant="scrollable"
                indicatorColor="primary"
                onChange={(e, value)=>changeMetric(value)}
                    >
                {metricDomains.map((item, key) =>  <Tab key={key} value={item.code} label={item.name} /> )}
            </Tabs>
        </Paper>
        <Paper square className="indent-5"><Container fluid>
            <Row className="offset-bottom-4"><Col xs={12}>
                {!questionsLength ? (
                    <h3 className="text-center text-uppercase offset-bottom-6"> {translate('GLOBALS$NO_QUESTIONS_FOUND')} </h3>
                ): (<div>
                    <FieldArray name="questions" component={Items} uploadFile={uploadFile} disabled={disabled} />
                </div>)}
            </Col></Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn disabled={pristine||invalid||disabled} className="offset-right-2"  hint={findHint(hints, `BUTTON_GDPR_SYSTEM_COMPLIANCEE_SCORING_QUESTIONS_SAVE`)} />
                    <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2"  hint={findHint(hints, `BUTTON_GDPR_SYSTEM_COMPLIANCE_SCORING_QUESTIONS_RESET`)} />
                    <CancelBtn onClick={cancel}  hint={findHint(hints, `BUTTON_GDPR_SYSTEM_COMPLIANCE_SCORING_QUESTIONS_CANCEL`)} />
                </Col>
            </Row>
        </Container></Paper>
    </form>
))));

/**
 * component for item of "questions" list
 *
 * @param {Object} props
 * @private
 */
const Items = ({ fields, disabled, uploadFile }) => {
    return fields.map((mKey, index) => ( <Field key={index} name={mKey} component={QuestionRow} disabled={disabled} uploadFile={uploadFile} /> ))
};

