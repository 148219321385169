// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {FormControl, FormHelperText, TextField} from '@mui/material';

// local dependencies
import is from '../services/is.service';
import {translate} from '../services/translate.service';

// configuration

/**
 * Input component renderer with helpers to display validation results
 * @example
 <Field
    type="mail"
    name="email"
    placeholder="Email"
    component={ Input }
    className="form-control"
    label={ <span> @ </span> }
        />
 * @public
 */
export default function MdInput ({ input, meta, label, filter, helpText = '', skipTouch = false, ...attr }) {
    filter = is.function(filter) ? filter : e => e;
    let message = '';
    if ( skipTouch || meta.touched ) {
        message = translate(meta.error);
    }
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {/* {!label ? '' : (<InputLabel htmlFor={input.name}>{label}</InputLabel>)} */}
        <TextField
            variant='standard'
            autoComplete="off"
            id={input.name}
            label={!label ? '' : label}
            {...input}
            {...attr}
            onChange={e => input.onChange(filter(e))}
                />
        {!helpText ? '': (<FormHelperText component="label" htmlFor={input.name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={input.name}>{message}</FormHelperText>)}
    </FormControl>);
};


/**
 * Material ui input with permission check which disabled component
 *
 * @param {Object} props
 * @public
 */
const ProtectedDisabled = ({ user={}, permission, dispatch, ...options }) => {
    let isAllowed = !permission || (user.permissions || []).includes(permission);
    return <MdInput {...options} style={{...options.style, color: 'black'}} disabled={!isAllowed} />;
};

export const ProtectedInput = connect( state => ({user: state.app.user}), null )(ProtectedDisabled);
