// outsource dependencies
import React, {Component} from 'react';
import {Col, Modal, Row} from 'react-bootstrap';
import {connectModal} from 'redux-modal';
import {toastr} from 'react-redux-toastr';
import ReactCrop, {centerCrop, makeAspectCrop} from 'react-image-crop';

// local dependencies
import {config} from '../constants';
import is from '../services/is.service';
import ErrorMessage from './alert-error';
import {instanceAPI} from '../services/api.service';

// configuration

/**
 *
 *
 * @constructor CropModal
 * @public
 */
class CropModal extends Component {

    constructor(props) {
        super(props);
        let mb = 1000 * 1024;
        // initial state
        this.state = {
            mb,
            src: null,
            image: null,
            file: props.file,
            maxWeight: is.number(props.maxWeight) ? props.maxWeight * mb : 5 * mb,
            // prepare base data
            crop: is.object(props.crop) ? props.crop : {aspect: 16 / 9},
            // meta info
            expectAnswer: true,
            errorMessage: null,
        };
    }

    /**
     * setup data on each modal call
     *
     * @public
     */
    componentDidMount() {
        this.readFile(this.props.file);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.file !== this.props.file) {
            this.readFile(this.props.file);
        }
    }

    /**
     *
     *
     * @param {Object} file
     * @public
     */
    readFile(file) {
        let {mb, maxWeight} = this.state;
        // validation of fie
        if (!file) {
            console.log('Missing file for cropping!');
            return;
            // return this.handleError({message: 'File missing...'});
        }
        // bmp
        if (!(/jpe|jpg|jpeg|gif|png/i.test(file.type))) return this.handleError({message: 'File type is incorrect !!! Available types is jpeg, jpg, gif, png'});
        if (file.size > maxWeight) return this.handleError({message: `File size is too big. Limit for file is ${(maxWeight / mb).toFixed()} MB`});
        // read file
        let reader = new FileReader();
        reader.addEventListener('load', () => {
            this.setState({
                src: reader.result,
                expectAnswer: false
            });
        }, false);
        reader.readAsDataURL(file);
    }

    /**
     * handle and prepare error within the crop modal
     *
     * @param {Object} error
     * @param {function} [callback=null]
     * @public
     */
    handleError(error, callback = null) {
        toastr.error('Error', error.message);
        this.setState({expectAnswer: false, errorMessage: error.message}, callback);
    }

    /**
     *
     *
     * @param {Object} event
     * @public
     */
    handleDismiss(event) {
        let {handleHide, dismiss} = this.props;
        is.function(dismiss) && dismiss(this.state);
        handleHide(event);
    }

    /**
     *
     * @param {Object} event
     * @public
     */
    handleApply(event) {
        let {/*crop: { width, height, x, y },*/ file} = this.state;
        let {handleHide, success} = this.props;
        const data = new FormData();
        data.append('file', file, file.name);
        this.setState({expectAnswer: true});
        instanceAPI({
            method: 'post',
            url: `/documents/upload/IMAGE`,
            data: data,
            // params: {
            //     width: width/100,
            //     height: height/100,
            //     x: x/100,
            //     y: y/100
            // },
            headers: {'Cache-Control': 'no-cache', 'Content-Type': 'multipart/form-data'},
        }).then(result => {
            this.setState({expectAnswer: false, errorMessage: null});
            is.function(success) && success(result);
            handleHide(event);
        }).catch(error => this.handleError(error));
    }

    /**
     *
     * @param {Object} percentage
     * @param {Object} units
     * @public
     */
    onCropComplete(percentage, units) {
        config.DEBUG && // debug log
        console.log('%c class CropModal onCropComplete => (...args)', 'background: #0f9d58; color: #fff; font-size: 12px;'
            , '\n ...args:', arguments
            , '\n state:', this.state
            , '\n props:', this.props
            , '\n percentage:', percentage
            , '\n units:', units
        );
    }

    /**
     *
     * @param {Object} e
     * @public
     */
    onImageLoaded(e) {
        const image = e.currentTarget;

        const defaultCrop = centerCrop(
            makeAspectCrop(
                {
                    // You don't need to pass a complete crop into
                    // makeAspectCrop or centerCrop.
                    unit: '%',
                    width: 90,
                },
                16 / 16,
                image.width,
                image.height
            ),
            image.width,
            image.height
        )

        // prepare aspect ratio following on the documentation guide
        this.setState({image, crop: defaultCrop});
        // this.setState({image, crop: makeAspectCrop(this.state.crop, 16/9, image.width, image.height)});
    }

    getCroppedImg(crop, crop2) {
        let {image} = this.state;

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );
        // As Base64 string
        // const base64Image = canvas.toDataURL('image/jpeg');
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                // resolve(blob);
                resolve(this.setState({file: blob}));
            }, 'image/png', 1);
        });
    }

    render() {

        let {show, title, help} = this.props;
        let {expectAnswer, errorMessage, src, crop} = this.state;

        return (
            <Modal show={show} onHide={e => e && this.handleDismiss(e)} dialogClassName="cbc-modal image-crop-modal">
                <Modal.Header closeButton> <Modal.Title>
                    {title || 'Image cropper'}
                    &nbsp;
                    {!expectAnswer ? '' : (<i className="fa fa-circle-o-notch fa-spin fa-fw"> </i>)}
                </Modal.Title> </Modal.Header>
                <Modal.Body>
                    {/* ERROR */}
                    <div className="clearfix">
                        <div className="col-xs-12">
                            <ErrorMessage
                                title={'Error:'}
                                message={errorMessage}
                                onChange={() => this.setState({errorMessage: null})}
                            />
                        </div>
                    </div>
                    {/* CROP */}
                    {!src ? ('') : (
                        <div className="clearfix">
                            <div className="col-xs-12 offset-bottom-2 text-center">
                                {help || (<code> Please select the picture area. </code>)}
                            </div>
                            <div className="col-xs-12 text-center">
                                <ReactCrop
                                    // src={src}
                                    crop={crop}
                                    disabled={expectAnswer}
                                    // style={{maxHeight: '450px'}}
                                    onChange={crop => this.setState({crop})}
                                    // onImageLoaded={image => this.onImageLoaded(image)}
                                    onComplete={(crop, crop2) => this.getCroppedImg(crop, crop2)}
                                >
                                    <img src={src} onLoad={(e) => this.onImageLoaded(e)} alt="Cropping and resizing" />
                                </ReactCrop>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Row className="clearfix offset-bottom-4 top-indent-4 justify-content-center">
                    <Col xs={4} >
                        <button disabled={expectAnswer || CropModal.invalid(crop)} onClick={e => this.handleApply(e)}
                                className="btn btn-lg w-100 btn-success">
                            Apply
                            &nbsp;
                            {!expectAnswer ? '' : (<i className="fa fa-circle-o-notch fa-spin fa-fw"> </i>)}
                        </button>
                    </Col>
                    <Col xs={4} >
                        <button className="btn btn-lg w-100 btn-danger" onClick={e => this.handleDismiss(e)}> Cancel </button>
                    </Col>
                </Row>
            </Modal>
        );
    }

    /**
     *
     *
     * @param {Object} crop
     * @public
     */
    static invalid(crop) {
        let min = 10;
        return !(crop.width > min && crop.height > min);
    }

}

// connect to modal
export default connectModal({name: 'image-crop-modal'})(CropModal);
