// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {SECURITY_REQUIREMENTS} from '../../../constants/routes';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {SECURITY_REQUIREMENTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { disabled, data, message, clearError, hints } = this.props;
        return (<Container fluid>
            <Breadcrumbs breadCrumbsMap={ SECURITY_REQUIREMENTS_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={9}>
                        <RichHintTitle update={LIST}  name={`SECURITY_REQUIREMENTS$TITLE`} expectAnswer={disabled} data={findHint(hints, `SECURITY_REQUIREMENTS_TITLE`)}/>
                    </Col>
                    <Col xs={3} className="text-right top-indent-4">
                        <Link Btn={AddBtn} placement="left" to={SECURITY_REQUIREMENTS.LINK_EDIT()} permission={PERMISSION.SECURITY_REQUIREMENT.CREATE}  hint={findHint(hints, `BUTTON_SECURITY_REQUIREMENTS_ADD`)} />
                    </Col>
                </Row>
                <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}> <SearchFilterConnected /> </Col> </Row>
                <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col> </Row>
                { data.length ? (
                    <Paper> <ConnectedTable /> </Paper>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                )}
            </ConnectedInitializer>
        </Container>);
    }
}

export default connect(
    state => ({
        data: state.securityRequirements.list.data,
        hints: state.securityRequirements.list.hintsData,
        message: state.securityRequirements.list.errorMessage,
        disabled: state.securityRequirements.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.securityRequirements.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.securityRequirements.list.filter,
        disabled: state.securityRequirements.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('SECURITY_REQUIREMENTS$SEARCH_BY_CODE')}
            />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.securityRequirements.list, hints: state.securityRequirements.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'code'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('code')}
                                >
                            {translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT_CODE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'securityControlFamily'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('securityControlFamily')}
                                >
                            {translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_FAMILY')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'securityControlName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('securityControlName')}
                                >
                            {translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'assessmentLevel'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('assessmentLevel')}
                                >
                            {translate('ASSESSMENTS$ASSESSMENT_LEVEL')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'programArea'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('programArea')}
                                >
                            {translate('SECURITY_REQUIREMENTS$PROGRAM_AREA')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{get(item, 'securityControlFamily.name')}</TableCell>
                    <TableCell>{get(item, 'securityControlName.name')}</TableCell>
                    <TableCell>{get(item, 'assessmentLevel.name')}</TableCell>
                    <TableCell>{item.programArea}</TableCell>
                    <TableCell className="text-nowrap" align="right">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.SECURITY_REQUIREMENT.UPDATE}
                            to={SECURITY_REQUIREMENTS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            hint={findHint(hints, `BUTTON_SECURITY_REQUIREMENTS_EDIT`)}
                        />
                        <DelBtn
                            className="offset-left-2"
                            onClick={() => deleteItem(item.id)}
                            permission={PERMISSION.SECURITY_REQUIREMENT.DELETE}
                            hint={findHint(hints, `BUTTON_SECURITY_REQUIREMENTS_DELETE`)}
                        />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));
