
// outsource dependencies
import get from 'lodash/get';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { history } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import { FORM_NAME, changeField, LICENSE_TYPES } from './index';
import getHintsList, { FIXED_CAPITAL_COSTS_EDIT_HINTS } from '../../../services/hints.service';
import separateWithCommaService from '../../../services/separate-with-comma.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.CHANGE_LICENSE_TYPE, changeLicenseTypeSaga);
    yield takeEvery(EDIT.CALCULATE_TOTAL_COST, calculateTotalCostSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        //NOTE separate with commas
        yield call(separateWithCommaService.separateReal, data);
        yield put({type: EDIT.DATA, data});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, initialized: true, ...params});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}


function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, FIXED_CAPITAL_COSTS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    // NOTE convert to numeric format
    yield call(separateWithCommaService.convertReal, options);
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* changeLicenseTypeSaga () {
    yield put(changeField('licenseCost', null));
    yield put(changeField('licenseCpus', null));
    yield put(changeField('licenseUsers', null));
    yield put(changeField('totalCosts', null));
}

function* calculateTotalCostSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    const values = yield select(state => state.form[FORM_NAME].values);
    // NOTE clear 'totalCosts' field
    yield put(changeField('totalCosts', null));
    // NOTE convert to numeric format
    yield call(separateWithCommaService.convertReal, options);
    yield call(separateWithCommaService.convertReal, values);
    // NOTE calculate 'totalCosts' value
    const totalCost = yield call(calculateTotalCost, options, values);
    //NOTE separate with commas
    yield call(separateWithCommaService.separateReal, options);
    yield call(separateWithCommaService.separateReal, values);
    if (totalCost) {
        yield put(changeField('totalCosts', totalCost));
    }
    yield put({type: EDIT.META, expectAnswer: false });
}

function* cancelSaga () {
    let { back } = yield select(state => state.capitalCosts.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get data by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/budget/fixed-capital-costs/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * calculate total cost
 * @param {Object} updatedField
 * @param {Object} allValues
 * @private
 */
function calculateTotalCost ( updatedField={}, allValues ) {
    let licenseType = get(allValues, 'licenseType.name'), params, totalCost;
    switch(licenseType){
        default: console.log(`%c calculateTotalCost unknown ${licenseType} !!!`, 'color: #fff; background: #a41e22; font-size: 20px;');
            return null;
        case LICENSE_TYPES.ANNUAL:
        case LICENSE_TYPES.PERPETUAL:
            // NOTE only 'licenseCost' field can be changed
            totalCost = updatedField.value;
            break;
        case LICENSE_TYPES.CPU:
            params = {licenseCpus: get(allValues, 'licenseCpus', 0), licenseCost: get(allValues, 'licenseCost', 0)};
            // NOTE update 'licenseCpus' or 'licenseCost' value depends on which field was recently updated
            params[updatedField.field] = updatedField.value;
            totalCost = params.licenseCpus * params.licenseCost;
            break;
        case LICENSE_TYPES.NUMBER_OF_USERS:
            params = {licenseUsers: get(allValues, 'licenseUsers', 0), licenseCost: get(allValues, 'licenseCost', 0)};
            params[updatedField.field] = updatedField.value;
            totalCost = params.licenseUsers * params.licenseCost;
            break;
    }
    return totalCost.toString();
}

/**
 * update data
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: `/budget/fixed-capital-costs`, data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: `/budget/fixed-capital-costs`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
