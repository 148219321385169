import { createTypes } from '../../actions/types';

export const PAGE = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
        GET_APP_VERSION_DATA: createTypes(`${prefix}GET_APP_VERSION_DATA`),
    };
})('@about/');
