// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {CardContent, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {DRILLDOWN} from '../actions';
import {history} from '../../../store';
import {ASSESSMENT_LEVEL, AUDIT_ID, PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
// import FiltersPanel from '../../../components/filters-panel';
import {SimpleAsyncSelect} from '../../../components/md-select';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {DOWNLOAD, SYSTEM_CONTROL_TEST_RESULTS} from '../../../constants/routes';
import {EditBtn, Link, PrimaryBtn, WarningBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatFileSize} from '../../../services/data-formatting.service';
import {instanceAPI} from "../../../services/api.service";
import withDownloadLink from "../../../components/download-link";
import {SYSTEM_CONTROL_TEST_RESULTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import AbbrevTableCell from "../../../components/abbrev-table-cell";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import {LIST} from '../../domains/actions';

const Drilldown = (props, {expectAnswer}) => {
    let params = useParams();
    useEffect(() => {
        props.initialize(params.systemId, params.assessmentId);
        return () => {
            props.clear();
        }
    }, []);
    let { data, clearError, errorMessage, system, back, hints } = props;
    const breadcrumbsMap = {
        ...SYSTEM_CONTROL_TEST_RESULTS_MAP,
        '/private/system-control-test-results/drilldown': {id: 12, title: `${props.system.name}  /  ${translate('SYSTEM_CONTROL_TEST_RESULTS$DRILLDOWN_TITLE')}`, parent: 11},
    };
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ breadcrumbsMap }  />
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={12} lg={8}>
                        <h2>
                            <RichHintTitle
                                update={LIST}
                                name={translate('SYSTEM_CONTROL_TEST_RESULTS$DRILLDOWN')}
                                expectAnswer={expectAnswer}
                                data={findHint(hints, `SYSTEM_CONTROL_TEST_RESULTS_DRILLDOWN_TITLE`)}
                            /><strong>&quot;{system.name}&quot;</strong>
                            {/*translate('SYSTEM_CONTROL_TEST_RESULTS$DRILLDOWN', <strong>&quot;{system.name}&quot;</strong>)} <Preloader expectAnswer={expectAnswer} type="ICON" />*/}
                        </h2>
                    </Col>
                    <Col xs={12} lg={4} className="text-right top-indent-4">
                        <WarningBtn className="offset-left-1" onClick={()=>history.push(back)} tooltip={translate('GLOBALS$BACK')} hint={findHint(hints, 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_BACK')}>
                            <i className="fa fa-reply" style={{fontSize: 20, color: 'white'}} aria-hidden="true" />
                        </WarningBtn>
                        <DownloadLink link={() => DOWNLOAD.SYSTEM_CONTROL_TEST_RESULTS_CSV_LINK({itemId: system.id})} disabled={expectAnswer} className="offset-left-2" hint={findHint(hints, 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_DOWNLOAD_CSV')}/>
                    </Col>
                </Row>
                <Row className="offset-bottom-4"><Col xs={12}> <SearchForm /> </Col></Row>
                <Row> <Col xs={12}> <ErrorMessage active message={errorMessage} onChange={clearError} /> </Col> </Row>
                { data.length ? (
                    <Paper> <ConnectedTable /> </Paper>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                )}
            </ConnectedInitializer>
        </Container>

    );
}

export default connect(
    state => ({...state.systemControlTestResults.drilldown, hints: state.systemControlTestResults.drilldown.hintsData}),
    dispatch => ({
        clear: () => dispatch({ type: DRILLDOWN.CLEAR }),
        initialize: (systemId, assessmentId) => dispatch({ type: DRILLDOWN.INITIALIZE, systemId, assessmentId }),
        clearError: () => dispatch({ type: DRILLDOWN.META, errorMessage: null })
    })
)(Drilldown);

const ConnectedInitializer = connect(
    state => ({initialize: state.systemControlTestResults.drilldown.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const DownloadLink = withTranslation(withDownloadLink()(props => (
    <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_DATA')}{...props}>
        <i className="fa fa-download" style={{fontSize: 20}} aria-hidden="true" />&nbsp;&nbsp;
        <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true" />
    </PrimaryBtn>
)));

/**
 * filters panel
 *
 * @public
 */
// const FiltersPanelConnected = connect(
//     state => ({showAdvanced:  state.systemControlTestResults.drilldown.showAdvanced}),
//     dispatch => ({
//         update: () => dispatch({type: DRILLDOWN.UPDATE_DATA, page: 0}),
//         closeAdvanced: () => dispatch({type: DRILLDOWN.CANCEL_ADVANCED_SEARCH}),
//         openAdvanced: () => dispatch({type: DRILLDOWN.META, showAdvanced: true}),
//         applyFilter: filter => dispatch({type: DRILLDOWN.UPDATE_DATA, filter, page: 0}),
//     })
// )( ( props) => (
//     <FiltersPanel SearchForm={SearchForm} SearchFilter={SearchFilterConnected} {...props} />
// ));

/**
 * search form
 *
 * @public
 */
const SearchForm = withTranslation(connect(
    state => ({ ...state.systemControlTestResults.drilldown }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: DRILLDOWN.META, filter}),
        changeAssessmentData: data => dispatch({type: DRILLDOWN.META, ...data}),
        applyFilter: filter => dispatch({type: DRILLDOWN.UPDATE_DATA, filter, page: 0}),
    })
)( ({ expectAnswer, filter, assessmentType, changeFilterValue, changeAssessmentData, applyFilter, system, disabled }) => (<CardContent>
    <Row>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SearchFilter
                value={filter}
                disabled={disabled}
                apply={applyFilter}
                clear={() => applyFilter('')}
                onInputChange={changeFilterValue}
                placeholder={translate('SECURITY_REQUIREMENTS$SEARCH_BY_REQUIREMENT')}
            />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={assessmentType}
                disabled={expectAnswer}
                placeholder={translate('ASSESSMENTS$ASSESSMENT')}
                onChange={assessmentType => {
                    changeAssessmentData({assessmentType});
                    applyFilter(filter)
                }}
                // label={(<strong> {translate('ASSESSMENTS$ASSESSMENT')} </strong>)}
                loadOptions={(name, done) => {
                    instanceAPI({ method: 'post', url: `/assessments/filter`, data: {filter: {name, assessmentLevelId: ASSESSMENT_LEVEL.SYSTEM, systemId: system.id}} })
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
</CardContent>)));

/**
 * search filter
 *
 * @public
 */
// const SearchFilterConnected = withTranslation(connect(
//     state => ({
//         filter:  state.systemControlTestResults.drilldown.filter,
//         disabled: state.systemControlTestResults.drilldown.expectAnswer,
//     }),
//     dispatch => ({
//         changeFilterValue: filter => dispatch({type: DRILLDOWN.META, filter}),
//         applyFilter: filter => dispatch({type: DRILLDOWN.UPDATE_DATA, filter, page: 0}),
//     })
// )(({disabled, filter, changeFilterValue, applyFilter }) => (
//     <SearchFilter
//         value={filter}
//         disabled={disabled}
//         apply={applyFilter}
//         clear={() => applyFilter('')}
//         onInputChange={changeFilterValue}
//         placeholder={translate('SECURITY_REQUIREMENTS$SEARCH_BY_REQUIREMENT')}
//             />
// )));

const ConnectedTable = withTranslation(connect(
    state => ({...state.systemControlTestResults.drilldown, hints: state.systemControlTestResults.drilldown.hintsData}),
    dispatch => ({
        changePage: page => dispatch({type: DRILLDOWN.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: DRILLDOWN.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: DRILLDOWN.UPDATE_DATA, size, page: 0}),
    })
)(({ data, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort, system, hints }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'code'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('code')}
                                >
                            {translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'securityControlFamily'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('securityControlFamily')}
                        >
                            {translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_FAMILY')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'securityControlName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('securityControlName')}
                                >
                            {translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('SECURITY_REQUIREMENTS$PROGRAM_AREA')} </TableCell>
                    <TableCell className="th"> {translate('TASKS$TITLE')} </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'controlMaturity'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('controlMaturity')}
                        >
                            {translate('CONTROL_MATURITIES$CONTROL_MATURITY')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('GLOBALS$DOCUMENT')} </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{get(item, 'securityRequirement.code')}</TableCell>
                    <TableCell>{get(item, 'securityRequirement.securityControlFamily.name')}</TableCell>
                    <TableCell>{get(item, 'securityRequirement.securityControlName.name')}</TableCell>
                    <AbbrevTableCell content={get(item, 'securityRequirement.programArea')} title={get(item, 'securityRequirement.securityControlName.name')} length={50}/>
                    <TableCell style={{minWidth: 200}}>
                        <ul className="list-unstyled offset-0"> {(item.tasks || []).map((item, index) => (
                            <li key={index}>{get(item, 'name')}</li>
                        ))} </ul>
                    </TableCell>
                    <TableCell>{get(item, 'controlMaturity.name')}</TableCell>
                    <TableCell>
                        {get(item, 'document') && <span>{get(item, 'document.fileName')} ({ formatFileSize(get(item, 'document.fileSize')) })</span>}
                    </TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.SYSTEM_CONTROL_TEST_RESULT.UPDATE}
                            hint={findHint(hints, 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_EDIT')}
                            to={SYSTEM_CONTROL_TEST_RESULTS.LINK_EDIT({
                                id: item.id,
                                systemId: system.id,
                                requirementId: get(item, 'securityRequirement.id', null),
                                query: {back: history.location.pathname + history.location.search}
                            })} />
                        <Link className="offset-left-2"
                              Btn={WarningBtn}
                              permission={PERMISSION.SYSTEM_CONTROL_TEST_RESULT.UPDATE}
                              hint={findHint(hints, 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_EDIT')}
                              to={SYSTEM_CONTROL_TEST_RESULTS.LINK_AUDIT({
                                  id: item.id,
                                  systemId: system.id,
                                  auditId: AUDIT_ID,
                                  requirementId: get(item, 'securityRequirement.id', null),
                                  query: {back: history.location.pathname + history.location.search}
                              })}
                              disabled={item.id <= 0}
                        >
                            <i className="fa fa-comments-o" style={{ fontSize: 18, height: 21, color: 'white' }} aria-hidden="true" />
                        </Link>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));
