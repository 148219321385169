// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import {filters} from '../../../components/filter';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {instanceAPI} from '../../../services/api.service';
import MdDatePicker from '../../../components/md-date-picker';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {MdAsyncCreatableSelect, MdSelect} from '../../../components/md-select';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {TASKS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';

// config
export const FORM_NAME = 'editTask';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (<Container fluid>
        <Breadcrumbs breadCrumbsMap={ TASKS_MAP }  />
        <ConnectedInitializer>
            <Row className="offset-top-10">
                <Col xs={12} md={{span:10, offset:1}} lg={{span:8, offset:2}} >
                    <Paper className="indent-5">
                        <h2 className="text-uppercase">
                            <span>
                                <RichHintTitle
                                    update={EDIT}
                                    name={isNew ? `TASKS$CREATE_TASK` : `TASKS$EDIT_TASK`}
                                    expectAnswer={expectAnswer}
                                    data={findHint(hints, isNew ? `TASKS_CREATE_TITLE` : `TASKS_EDIT_TITLE`)}/>
                            </span>
                            <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                        </h2>
                        <ConnectedError/>
                        <ConnectedForm isNew={isNew} />
                    </Paper>
                </Col>
            </Row>
        </ConnectedInitializer>
    </Container>);
}

export default connect(
    state => ({
        message: state.tasks.edit.errorMessage,
        expectAnswer: state.tasks.edit.expectAnswer,
        hints: state.tasks.edit.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({ type: EDIT.META, errorMessage: null})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.tasks.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.tasks.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        projects: state.tasks.edit.projects,
        initialValues: state.tasks.edit.data,
        disabled: state.tasks.edit.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
        taskCategories: state.tasks.edit.taskCategories,
        hints: state.tasks.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        createProject: name => dispatch({type: EDIT.CREATE_PROJECT, name}),
        createTaskCategory: name => dispatch({type: EDIT.CREATE_TASK_CATEGORY, name}),
        changeEstimatedDate: options => dispatch({type: EDIT.CHANGE_ESTIMATED_DATE, ...options}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }

        if (!values.taskManager) {
            errors.taskManager = 'TASKS$MANAGER_REQUIRED';
        }

        if (!values.taskAssignee) {
            errors.taskAssignee = 'TASKS$ASSIGNEE_REQUIRED';
        }

        if (!values.priority) {
            errors.priority = 'TASKS$PRIORITY_REQUIRED';
        }

        if (!values.estimatedStartDate) {
            errors.estimatedStartDate = 'TASKS$ESTIMATED_START_DATE_REQUIRED';
        }

        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formValues, createTaskCategory, taskCategories, projects, createProject, changeEstimatedDate, hints})=>(
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="project"
                    disabled={disabled}
                    defaultOptions={projects}
                    component={MdAsyncCreatableSelect}
                    onCreateOption={name => createProject(name)}
                    placeholder={translate('TASKS$PROJECT_PLACEHOLDER')}
                    label={(<strong> {translate('TASKS$PROJECT')} </strong>)}
                    getNewOptionData={(inputValue, optionLabel)=>({id: inputValue, name: optionLabel, __isNew__: true})}
                    loadOptions={(name, done) => {
                        instanceAPI({
                            method: 'post',
                            url: '/projects/filter',
                            data: { page: 0, size: 6, filter: { name } },
                        }).then(({items})=>done(items)).catch(done.bind(null, []));
                    }}/>
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="taskCategory"
                    disabled={disabled}
                    defaultOptions={taskCategories}
                    component={MdAsyncCreatableSelect}
                    onCreateOption={name => createTaskCategory(name)}
                    placeholder={translate('TASKS$CATEGORY_PLACEHOLDER')}
                    label={(<strong> {translate('TASKS$CATEGORY')} </strong>)}
                    getNewOptionData={(inputValue, optionLabel)=>({id: inputValue, name: optionLabel, __isNew__: true})}
                    loadOptions={(name, done) => {
                        instanceAPI({
                            method: 'post',
                            url: '/task-categories/filter',
                            data: { page: 0, size: 6, filter: { name } },
                        }).then(({items})=>done(items)).catch(done.bind(null, []));
                    }}/>
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    name="businessUnit"
                    disabled={disabled}
                    type={ENTITY_TYPES.BUSINESS_UNITS}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('BUSINESS_UNITS$BUSINESS_UNIT')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    name="taskManager"
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={option => get(option, 'fullName')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('TASKS$MANAGER')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    name="taskAssignee"
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={option => get(option, 'fullName')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('TASKS$ASSIGNEE')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="status"
                    type="number"
                    component={MdInput}
                    disabled={disabled}
                    inputProps={{min: 0}}
                    placeholder={translate('TASKS$STATUS_PERCENTAGES')}
                    label={(<strong> {translate('TASKS$STATUS_PERCENTAGES')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    labelKey="label"
                    valueKey="value"
                    name="priority"
                    disabled={disabled}
                    component={MdSelect}
                    placeholder={translate('TASKS$PRIORITY')}
                    options={['LOW', 'MEDIUM', 'HIGH', 'URGENT'].map(option => {
                        return {label: filters.humanize(option), value: option};
                    })}
                    sendValueProp
                    simpleValue={(value) => {
                        return {value: value, label: filters.humanize(value)}
                    }}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('TASKS$PRIORITY')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    type="number"
                    name="estimatedHours"
                    component={MdInput}
                    disabled={disabled}
                    inputProps={{min: 0}}
                    placeholder={translate('TASKS$ESTIMATED_HOURS')}
                    label={(<strong> {translate('TASKS$ESTIMATED_HOURS')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    disabled={disabled}
                    component={MdDatePicker}
                    name="estimatedStartDate"
                    inputProps={{max: get(formValues, 'estimatedEndDate')}}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('TASKS$ESTIMATED_START_DATE')} </strong>)}
                    onChange={(e,value) => changeEstimatedDate({field: 'estimatedStartDate', value})}
                        />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    disabled={disabled}
                    name="estimatedEndDate"
                    component={MdDatePicker}
                    inputProps={{min: get(formValues, 'estimatedStartDate')}}
                    label={(<strong> {translate('TASKS$ESTIMATED_END_DATE')} </strong>)}
                    onChange={(e,value) => changeEstimatedDate({field: 'estimatedEndDate', value})}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    disabled={disabled}
                    name="actualStartDate"
                    component={MdDatePicker}
                    inputProps={{max: get(formValues, 'actualEndDate')}}
                    label={(<strong> {translate('TASKS$ACTUAL_START_DATE')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    disabled={disabled}
                    name="actualEndDate"
                    component={MdDatePicker}
                    inputProps={{min: get(formValues, 'actualStartDate')}}
                    label={(<strong> {translate('TASKS$ACTUAL_END_DATE')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    multiline
                    name="taskNotes"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NOTES')}
                    label={(<strong> {translate('GLOBALS$NOTES')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_TASKS_CREATE' : 'BUTTON_TASKS_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_TASKS_RESET')} />
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_TASKS_CANCEL')} />
            </Col>
        </Row>
    </form>
))));
