// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {filter, throttle} from 'lodash';
import {LinkContainer} from 'react-router-bootstrap';
import {
    Badge,
    Col,
    Collapse,
    Container,
    ListGroup,
    ListGroupItem,
    OverlayTrigger,
    Popover,
    Row,
} from 'react-bootstrap';
// local dependencies
import {AdditionalPublicLogo, Logo} from '../images';
import {history} from '../store';
import UserMenu from './user-menu';
import * as ROUTES from '../constants/routes';
import AgreementAlert from './agreement-alert';
import SelectRiskModelModal from './select-model-modal';
import TranslateService, {translate} from '../services/translate.service';
import ReassignDialog from '../components/reassign-dialog';
import CropModal from '../components/image-crop.modal';
import GeoRecordsProcessed from '../components/geo-records-processed-dialog';
import AbbrevDialog from '../components/abbrev-dialog';
import ConfirmDialog from '../components/confirmation-dialog';
import PreviewDialog from '../components/preview-dialog';
import {DIRECTION, MENU_ITEM_TYPE, NEW_ID} from '../constants/spec';
import queryService from "../services/query.service";
import {PRIVATE} from "../actions/types";

// Export
export default connect(
    state => ({
        direction: state.app.direction,
        expanded: state.privateLayout.expanded
    }),
    dispatch => ({
        toggleMenu: expanded => dispatch({type: expanded ? PRIVATE.COLLAPSE_ASIDE : PRIVATE.EXPAND_ASIDE}),
    })
)(Layout);

/**
 * Page for manage Recipes within application
 *
 * @param {Object} props
 * @public
 */
function Layout({children, expanded, toggleMenu, menu = [], direction}) {
    let menuItemsOrdered = [];
    menuItemsOrdered.push(...menu);
    menuItemsOrdered.forEach((menuItem, index) => {
       if (menuItem.name === 'IMPORT_AND_EXPORT$TITLE') {
           menuItem.list.sort((a, b) => TranslateService.translate(a.name).localeCompare(TranslateService.translate(b.name)));
       }
    });
    return (
        <Container fluid id="privateLayout" className={direction === DIRECTION.RTL ? 'direction-rtl' : ''}>
            {/* NOTE includes common confirmation component*/}
            <CropModal />
            <ConfirmDialog />
            <PreviewDialog />
            <ReassignDialog />
            <GeoRecordsProcessed />
            <AbbrevDialog />
            <SelectRiskModelModal />
            <AgreementAlert />
            <Row>
                <div className="private-header">
                    <Row>
                        <Col md={5}>
                            <Link to={ROUTES.HOME.LINK()}>
                                <Logo height="45" tabIndex={-1} />
                            </Link>
                        </Col>
                        <Col md={2} className="text-center">
                            <Link to={ROUTES.HOME.LINK()} className="header-logo">
                                <AdditionalPublicLogo height="45" tabIndex={-1} />
                            </Link>
                        </Col>
                        <Col md={5}>
                            <UserMenu />
                        </Col>
                    </Row>
                </div>
            </Row>
            <Row padding={0}>
                <div className="toggle-menu">
                    <Badge onClick={e => toggleMenu(expanded)} className={'transition ' + (expanded ? '' : 'active')}>
                        <i className={'fa fa-caret-down fa-lg transition ' + (expanded ? 'fa-rotate-90' : 'fa-rotate-270')}
                           aria-hidden="true"> </i> </Badge>
                </div>
                <div className={'private-aside ' + (expanded ? 'expanded' : 'collapsed')}>
                    <div id="menu">
                        <div className="hide-scroll-bar">
                            <ListGroup style={{padding: 0, margin: 0}}>
                                {menuItemsOrdered.map((item, index) => (
                                    <AsideMenuItem {...item} expanded={expanded} key={index} />))}
                            </ListGroup>
                        </div>
                    </div>
                    <div id="content">
                        <div className="hide-scroll-bar" style={{padding:'0px 15px'}}>
                            {children}
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    );
}

/**
 * switcher to make nested menu
 *
 * @param {Object} props
 * @public
 */


function AsideMenuItem(props) {
    let {expanded, type, icon, name, link, isActive, permission, ...attr} = props;

    let options = {
        delayHide: 0.2 * 1000,
        placement: expanded ? 'top' : 'right',
        delayShow: (expanded ? 8 : 0.2) * 1000,
        trigger: ['hover','focus'],
        overlay: (<Popover  style={{display:"none"}}></Popover>)
    };
    switch (type) {
        default:
            return (<ListGroupItem> default </ListGroupItem>);
        case MENU_ITEM_TYPE.MENU:
            // return <>  </>
            return (<CollapsibleMenu {...props} tooltipOptions={options} />);
        case MENU_ITEM_TYPE.ACTION:
            return (
                <OverlayTrigger {...options}>
                    <ListGroupItem {...attr}>
                        {icon ? (<i className={icon} aria-hidden="true"> </i>) : ''}
                        &nbsp;{expanded ? translate(name) : ''}
                    </ListGroupItem>
                </OverlayTrigger>
            );
        case MENU_ITEM_TYPE.LINK:
            return (
                <OverlayTrigger {...options}>
                    {({ref}) => (
                        <LinkContainer  exact="true" to={link} isActive={isActive}>
                            <ListGroupItem ref={ref}  {...attr}>
                                {icon ? (<i className={icon} aria-hidden="true"> </i>) : ''}
                                &nbsp;{expanded ? translate(name) : ''}
                            </ListGroupItem>
                        </LinkContainer>
                    )}

                </OverlayTrigger>
            );
    }
}

class CollapsibleMenu extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {
            open: false,
            active: false
        };
        this.throttledAutoToggle = throttle(() => this.autoToggle(), 600);
    }

    UNSAFE_componentWillMount() {
        this.throttledAutoToggle()
    }

    UNSAFE_componentWillUpdate() {
        this.throttledAutoToggle();
    }

    autoToggle() {
        let {list = []} = this.props;

        let active = false;
        let links = filter(list, {type: MENU_ITEM_TYPE.LINK});
        let submenus = filter(list, {isParent: true});
        if (submenus) {
            for (let s = 0; s < submenus.length; s++) {
                links = links.concat(filter(submenus[s].list, {type: MENU_ITEM_TYPE.LINK}));
            }
        }
        for (let {link, /* isActive */} of links) {
            if (link === history.location.pathname) {
                active = true;
                break;
            }

            // NOTE (CSS) display active submenu on editing
            let {back} = queryService.parse(history.location.search);
            if (link === back) {
                active = true;
                break;
            }

            // NOTE (CSS) display active submenu on creation
            let createLink = link.replace('list', '') + `edit/${NEW_ID}`;
            if (createLink === history.location.pathname) {
                active = true;
                break;
            }
        }
        // NOTE prevent triggering render without needs
        if (active !== this.state.active && !this.props.isParent) {
            this.setState({active, open: active});
        }
    }

    render() {
        let {list = [], name, icon, expanded, type, tooltipOptions, isParent, ...attr} = this.props;
        let {open, active} = this.state;
        return (
            <ListGroup style={{padding: 0, margin: 0}}>
                <OverlayTrigger {...tooltipOptions}>
                    <ListGroupItem
                        className={'sub-menu-toggle ' + (active ? 'active' : '' + (isParent ? ' list-group-tab' : ''))} {...attr}
                        onClick={() => !attr.disabled && this.setState({open: !open})}>
                        {expanded &&
                            <Badge> <i className={'fa fa-caret-down fa-lg transition ' + (open ? '' : 'fa-rotate-90')}
                                       aria-hidden="true"> </i> </Badge>}
                        {icon ? (<i className={icon} aria-hidden="true"> </i>) : ''}
                        &nbsp;{expanded ? translate(name) : ''}
                    </ListGroupItem>
                </OverlayTrigger>
                <Collapse in={open}>
                    <div className="collapse-box">
                        {list.map((item, index) => (<AsideMenuItem {...item} expanded={expanded} key={index} />))}
                    </div>
                </Collapse>
            </ListGroup>
        );
    }
}
