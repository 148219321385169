
// outsource dependencies

// local dependencies
import getMenu from './index';
import ACTIONS from './actions';

let initial = {
    data: getMenu(),
    dashboards: [],
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
};

export default function ( state = initial, action ) {
    //eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case ACTIONS.CLEAR:
            state = initial;
            break;
        case ACTIONS.DATA:
            state = { ...state, data: options.data };
            break;
        case ACTIONS.META:
            state = { ...state, ...options, data: state.data };
            break;
    }
    return state;
}
