
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { PAGE } from './actions';
import { instanceAPI } from '../../services/api.service';
import { translate } from '../../services/translate.service';

export default function* () {
    yield takeEvery(PAGE.INITIALIZE, initializeSaga);
    yield takeEvery(PAGE.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function * initializeSaga () {
    yield put({type: PAGE.CLEAR});
    try {
        yield put({type: PAGE.GET_HINTS_DATA.REQUEST});
        let availableLanguages = yield call(getLanguages);
        yield put({type: PAGE.META, availableLanguages});
    } catch ( {message} ) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: PAGE.META, errorMessage: message});
    }
    yield put({type: PAGE.META, initialized: true});
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language);
        // NOTE setup hints data
        yield put({type: PAGE.META, hintsData});
    } catch ( {message} ) {
        yield put({type: PAGE.META, errorMessage: message});
    }
    yield put({type: PAGE.GET_HINTS_DATA.FINISH});
}

/**
 * get list of hints
 * @param {String} language
 * @private
 */
function getHintsList (language) {
    let data = ['HINTS_KNOWLEDGE_BASE_TITLE'];
    return instanceAPI({method: 'post', url: `/hints/get/${language}`, data});
}

/**
/**
 * get all available supported languages
 * @private
 */
function getLanguages() {
    return instanceAPI({ method: 'post', url: '/supported-languages/filter', data: { size: 10000 } })
        .then(({items}) => items);
}
