
// outsource dependencies
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// local dependencies
import Preloader from './preloader';
import is from '../services/is.service';

/**
 * Component wrapper to make common preparation for pages and provide url params if it present
 *
 * @constructor
 * @public
 */
class Page extends Component {
    componentWillUnmount () { is.function(this.props.clear)&&this.props.clear(); }
    componentDidMount () { this.props.init(this.props.match ? this.props.match.params : {}); }
    render () {
        let { initialized, preloader = "MIN_HEIGHT", children } = this.props;
        return (<Preloader type={preloader} height={400} expectAnswer={!initialized} children={children} />);
    }
}

Page.propTypes = {
    clear: PropTypes.func,
    preloader: PropTypes.string,
    init: PropTypes.func.isRequired,
    initialized: PropTypes.bool.isRequired,
};

export default Page;
