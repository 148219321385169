// outsource dependencies
import get from 'lodash/get';
import Dropzone from 'react-dropzone';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {CloudDownload} from '@mui/icons-material';
import {change, Field, FieldArray, getFormValues, reduxForm} from 'redux-form';
import {IconButton, InputLabel, Paper, Tooltip} from '@mui/material';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import {ENTITY_TYPES} from '../../../constants/spec';
import {DOWNLOAD} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import withDownloadLink from '../../../components/download-link';
import SelectEntities from '../../../components/select-entities';
import {formatFileSize} from '../../../services/data-formatting.service';
import {translate, withTranslation} from '../../../services/translate.service';
import {AddIconBtn, CancelBtn, DeleteIconBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {filters} from "../../../components/filter";
import Radio from "../../../components/md-radio";
import TasksManagement from "../../../components/tasks-management";
import moment from "moment/moment";
import {SYSTEM_CONTROL_TEST_RESULTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import {LIST} from '../../cyber-roles/actions';

// config
export const FORM_NAME = 'editSystemTestResults';
export const AUDIT_FORM_NAME = 'auditSystemTestResults';
export const changeField = (field, value) => change(FORM_NAME, field, value);
export const changeAuditField = (field, value) => change(AUDIT_FORM_NAME, field, value);

const EVIDENCE_TYPE = {
    EVIDENCE_IS_SUFFICIENT : 'true',
    EVIDENCE_IS_NOT_SUFFICIENT : 'false',
};

const Edit = (props, {expectAnswer, hints}) => {
    let params = useParams();
    useEffect(() => {
        props.initialize(params);
        return () => {
            props.clear();
        }
    }, []);

    let {isAudit} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ SYSTEM_CONTROL_TEST_RESULTS_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: isAudit ? 5 : 8, offset: isAudit ? 1 : 2}}>
                        <Paper className="indent-5">
                            <Preloader expectAnswer={expectAnswer} type="ICON"/>
                            <ConnectedForm/>
                        </Paper>
                    </Col>
                    {isAudit && <Col xs={12} md={5}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase offset-bottom-8">
                                <span>{translate('SYSTEM_CONTROL_TEST_RESULTS$AUDIT_RESULT')}</span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"/>
                            </h2>
                            <ConnectedAuditForm/>
                        </Paper>
                    </Col>}
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({
        expectAnswer: state.systemControlTestResults.edit.expectAnswer,
        isAudit: state.systemControlTestResults.edit.isAudit,
    }),
    dispatch => ({
        initialize: params => dispatch({type: EDIT.INITIALIZE, ...params}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.systemControlTestResults.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.systemControlTestResults.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const DownloadLink = withTranslation(withDownloadLink()(props => (
    <Tooltip title={translate('GLOBALS$DOWNLOAD')} className="offset-bottom-1"><span>
        <IconButton {...props} color="primary" style={{padding: '5px'}} aria-label={translate('GLOBALS$DOWNLOAD')}>
            <CloudDownload fontSize="small"/>
        </IconButton>
    </span></Tooltip>
)));

const ConnectedForm = withTranslation(connect(
    state => ({
        formValues: getFormValues(FORM_NAME)(state),
        initialValues: state.systemControlTestResults.edit.data,
        disabled: state.systemControlTestResults.edit.expectAnswer,
        isAudit: state.systemControlTestResults.edit.isAudit,
        hints: state.systemControlTestResults.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        removeDocument: () => dispatch(changeField('document', null)),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values) => {
        let errors = {};
        // assessment weight
        if (values.assessmentWeight && (values.assessmentWeight < 0 || values.assessmentWeight > 100)) {
            errors.assessmentWeight = 'GLOBALS$PERCENTAGE_ERROR';
        }
        if (!values.controlMaturity) {
            errors.controlMaturity = 'CONTROL_MATURITIES$CONTROL_MATURITY_REQUIRED';
        }
        
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, cancel, formValues = {}, removeDocument, isAudit, isNew, hints}) => (
        <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>

            <h2 className="text-uppercase">
                <strong>{get(formValues, 'system.name')}. </strong>
                <span>
                    <RichHintTitle
                        update={LIST}
                        name={translate('SYSTEM_CONTROL_TEST_RESULTS$EDIT_RESULT')}
                        data={findHint(hints,`SYSTEM_CONTROL_TEST_RESULTS_EDIT_TITLE`)}
                    />
                </span>
            </h2>
            <ConnectedError/>

            <Row className="offset-bottom-2">
                <Col xs={12} className="offset-bottom-0">
                    <h4>
                        <strong>{translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT_COLON')} </strong>
                        {get(formValues, 'securityRequirement.code')}
                    </h4>
                </Col>
                <Col xs={12} className="offset-bottom-0">
                    <h4>
                        <strong>{translate('SECURITY_REQUIREMENTS$PROGRAM_AREA')}: </strong>
                        {get(formValues, 'securityRequirement.programArea')}
                    </h4>
                </Col>
                <Col xs={12} className="offset-bottom-0">
                    <h4>
                        <strong>{translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_FAMILY')}: </strong>
                        {get(formValues, 'securityRequirement.securityControlFamily.name')}
                    </h4>
                </Col>
                <Col xs={12} className="offset-bottom-0">
                    <h4>
                        <strong>{translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_NAME')}: </strong>
                        {get(formValues, 'securityRequirement.securityControlName.name')}
                    </h4>
                </Col>
                <Col xs={12} className="offset-bottom-0">
                    <h4>
                        <strong>{translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT_DESCRIPTION')}: </strong>
                        <br/>
                        <br/>
                        <Field
                            multiline
                            name="securityRequirement.description"
                            component={MdInput}
                            disabled={true}
                        />
                    </h4>
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <h4>
                        <strong className="required-asterisk"> {translate('CONTROL_MATURITIES$CONTROL_MATURITY')}: </strong>
                        <SelectEntities
                            name="controlMaturity"
                            disabled={disabled || isAudit}
                            type={ENTITY_TYPES.CONTROL_MATURITIES}
                            placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        />
                    </h4>
                </Col>
            </Row>
            <Row className="offset-bottom-4"> <Col xs={12}>
                <h4>
                    <strong>{translate('GLOBALS$DOCUMENT_COLON')} </strong>
                    {get(formValues, 'document') && (<span>
                        {get(formValues, 'document.fileName')} ({formatFileSize(get(formValues, 'document.fileSize'))})&nbsp;
                        {get(formValues, 'document.downloadUrl') && (
                            <DownloadLink
                                link={() => DOWNLOAD.EVIDENCE_DOCUMENT_LINK({downloadUrl: get(formValues, 'document.downloadUrl')})}/>
                        )}&nbsp;<DeleteIconBtn className="offset-bottom-1" style={{padding: '5px'}}
                                               onClick={() => removeDocument()}/>
                    </span>)}
                </h4>
            </Col> </Row>
            <Row className="offset-bottom-8"> <Col xs={12}  className='dropzone'> <UploadFile/> </Col> </Row>
            <Row className="offset-bottom-8">
                <Col xs={12}> <FieldArray name="tasks" component={TasksManagement} /> </Col>
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={false} disabled={pristine || invalid || disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_CREATE' : 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_SAVE')} />
                    <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"  hint={findHint(hints, 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_RESET')}/>
                    <CancelBtn onClick={cancel}  hint={findHint(hints, 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_CANCEL')}/>
                </Col>
            </Row>
        </form>
    )
)));

const ConnectedAuditForm = withTranslation(connect(
    state => ({
        user: state.app.user,
        formValues: getFormValues(AUDIT_FORM_NAME)(state),
        initialValues: state.systemControlTestResults.edit.data,
        auditData: state.systemControlTestResults.edit.auditData,
        disabled: state.systemControlTestResults.edit.expectAnswer,
        isSufficient: state.systemControlTestResults.edit.isSufficient,
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        auditUpdate: formData => dispatch({type: EDIT.AUDIT_UPDATE, ...formData}),
        changeSufficientStatus: (e, status) => {
            dispatch({type: EDIT.SUFFICIENT_UPDATE, status})
        },
    })
)(reduxForm({
    form: AUDIT_FORM_NAME,
    enableReinitialize: true,
    // validate: (values) => {
    //     let errors = {};
    //     // assessment weight
    //     if (values.assessmentWeight && (values.assessmentWeight < 0 || values.assessmentWeight > 100)) {
    //         errors.assessmentWeight = 'GLOBALS$PERCENTAGE_ERROR';
    //     }
    //     return errors;
    // }
})(({handleSubmit, invalid, pristine, disabled, update, reset, cancel, formValues = {}, auditUpdate, auditData, changeSufficientStatus, isSufficient, removeDocument, isAudit, user}) => {
    return (
        <form autoComplete="off" name={AUDIT_FORM_NAME} onSubmit={handleSubmit(auditUpdate)}>
            {/*<Row className="offset-bottom-2"> <Col xs={12} className="text-center">*/}
                {/*<Field*/}
                    {/*fullWidth={false}*/}
                    {/*// disabled={disabled}*/}
                    {/*component={MdSwitch}*/}
                    {/*name="isSufficient"*/}
                    {/*label={(*/}
                        {/*<strong> {translate('ORGANIZATION_CONTROL_TEST_RESULTS$EVIDENCE_IS_SUFFICIENT')} </strong>)}*/}
                {/*/>*/}
            {/*</Col> </Row>*/}
            <Row className="offset-bottom-2">
                <Col xs={12}>
                    <Field
                        row
                        name="isSufficient"
                        onChange={(e, status)=>changeSufficientStatus(e, status)}
                        formWidth="inherit"
                        style={{ justifyContent: `space-around` }}
                        component={Radio}
                        // disabled={disabled}
                        // label={translate('label label label')}
                        options={Object.keys(EVIDENCE_TYPE).map( item => ({value: EVIDENCE_TYPE[item], label: filters.humanize(item)}))}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-6">
                <Col xs={12}>
                    {/*<FieldArray name="auditorsComments" userName={`${user.firstName} ${user.lastName}`} fields={auditData} component={Items}/>*/}
                    <h3 className="text-uppercase">
                        <span className="align-middle">{translate('ORGANIZATION_CONTROL_TEST_RESULTS$AUDITORS_COMMENTS')}</span>&nbsp;
                    </h3>
                    {/*{meta.error && (<h4 className="text-center is-invalid"> <label className="form-text h4"> { translate(meta.error) } </label> </h4>)}*/}
                    <ul className="list-unstyled">
                        <Row className="offset-bottom-6">
                            <Col xs={11} md={10} className="offset-bottom-2">
                                <Field
                                    multiline={true}
                                    component={MdInput}
                                    disabled={disabled}
                                    name='auditComment'
                                    placeholder={translate('GLOBALS$COMMENT')}
                                    label={(<strong> {translate('GLOBALS$COMMENT')} </strong>)}
                                />
                            </Col>
                            <Col xs={1} md={2} className="offset-top-2">
                                <div className="adornment">
                                    <AddIconBtn disabled={disabled || !get(formValues, 'auditComment')} type="submit"/>
                                </div>
                            </Col>
                        </Row>
                        {auditData.map((type, i) =>{ return (<Row key={i} className="offset-bottom-1">
                            <Col xs={3} md={3} className="offset-bottom-2">
                                <Field
                                    component={MdInput}
                                    disabled={true}
                                    name="auditor"
                                    input={{ value: `${type.createdBy.fullName}` }}
                                    label={(<strong> {translate('Auditor')} </strong>)}
                                />
                            </Col>
                            <Col xs={8} md={8} className="offset-bottom-2">
                                <Field
                                    multiline={true}
                                    component={MdInput}
                                    disabled={true}
                                    input={{ value:type.comment }}
                                    name={`${type}.comment`}
                                    placeholder={translate('GLOBALS$COMMENT')}
                                    label={(<strong> {translate('GLOBALS$COMMENT')}&nbsp;&nbsp;&nbsp;<small className="text-right">{`${type.createdAt && moment(type.createdAt).format('D.MM, h:mm a')}`}</small></strong>)}
                                />
                            </Col>
                        </Row>)})}
                    </ul>
                </Col>
            </Row>
        </form>
    )}
)));

const UploadFile = withTranslation(connect(
    state => ({
        uploaded: state.systemControlTestResults.edit.uploaded,
        disabled: state.systemControlTestResults.edit.expectAnswer
    }),
    dispatch => ({uploadFile: file => dispatch({type: EDIT.UPLOAD_FILE, file})})
)(({uploadFile, uploaded, disabled, ...attr}) => (<div>
    <InputLabel htmlFor="fileUpload"> <strong> {translate('GLOBALS$DOCUMENT_UPLOAD')} </strong> </InputLabel>
    <Dropzone
        {...attr}
        id="fileUpload"
        className="dropzone"
        disabled={!uploaded || disabled}
        activeClassName="dropzone-active"
        disabledClassName="dropzone-disabled"
        onDrop={(acceptedFiles, rejectedFiles) => {
            !rejectedFiles.length && uploadFile(acceptedFiles[0])
        }}
    >
        {() => {
            if (!uploaded) {
                return (<h3 className="text-center text-muted">{translate('GLOBALS$LOADING')}</h3>);
            }
            return (<div className="text-center text-muted">
                <p style={{fontSize: '50px'}}><i className="fa fa-upload"/></p>
                <h3 className="offset-top-0">{translate('DROPZONE$DEFAULT_MESSAGE')}</h3>
            </div>)
        }}
    </Dropzone>
</div>)));
