// outsource dependencies
import _ from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {Campaign, ImportantDevices, Receipt} from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Pagination
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';


// local dependencies
import {EDIT} from './actions';
import UserMenu from './user-menu';
import * as ROUTES from '../../constants/routes';
import {AdditionalPublicLogo} from '../../images';
import Preloader from '../../components/preloader';
import KPIExample from '../../images/kpi-example.png';
import {Link as BtnLink} from '../../components/md-button';
// import {TextFilterBootstrap} from '../../components/search-filter';
// import {translate, withTranslation} from '../../services/translate.service';
import {BookIcon, LikeIcon, MessagesIcon, TasksIcon} from '../../images/dashboard-icons';
import PREVIEW_DIALOG from '../../components/preview-dialog/actions';

/*
const useLocalStyles = makeStyles({
    multiLineEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical"
    }
});
const localClasses = useLocalStyles();
*/

class Edit extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    handleAnalyticItemChange(event, value) {
        let {externalAnalytics} = this.props;
        if (externalAnalytics && externalAnalytics[value - 1]) {
            this.setState({currentAnalytics: externalAnalytics[value - 1]});
        }
    };


    render() {
        // let { externalAnalytics, messages, feeds, tasks, tickets, webinars, news, paces, user } = this.props;
        let {externalAnalytics, messages, feeds, tasks, webinars, news, paces, user, showPreview} = this.props;
        let currentAnalytics = null;
        if (this.state && this.state.currentAnalytics) {
            currentAnalytics = this.state.currentAnalytics;
        }
        if (!currentAnalytics && externalAnalytics && externalAnalytics.length) {
            currentAnalytics = externalAnalytics[0];
        }
        return (
            <Container id="home" fluid className="full-height">
                <div className="content-wrap p-3">
                    <ConnectedInitializer>
                        <Row className="pt-4 pb-5">
                            <Col xs={12} className="d-flex justify-content-between align-items-center">
                                <Link to={ROUTES.HOME.LINK()} className="header-logo">
                                    <AdditionalPublicLogo height="45" tabIndex={-1} style={{maxWidth: 210}}/>
                                </Link>
                                <div className="d-flex">
                                    {/*
                                    <SearchFilterConnected />
                                    <Button tooltip={translate('GLOBALS$ADVANCED_SEARCH')} className="btn-purple ml-3">
                                        {translate('GLOBALS$ADVANCED_SEARCH')}
                                    </Button>
                                    */}
                                </div>
                                <div className="greeting">
                                    Welcome Back! <span className="highlighted">{user.fullName}</span>
                                </div>
                                <div>
                                    <UserMenu/>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4} className="mb-4">
                                <Card className="card-dashboard">
                                    <CardContent className="card-header">
                                        <h4 className="card-title">KPIs</h4>
                                        <span className="mb-0">
                                            {externalAnalytics && externalAnalytics.length ? (
                                                <Box mb={0} display="flex" justifyContent="center" alignItems="center">
                                                    <Pagination count={externalAnalytics.length}
                                                                onChange={(event, value) => this.handleAnalyticItemChange(event, value)}/>
                                                </Box>
                                            ) : ''}
                                        </span>
                                    </CardContent>
                                    <CardContent>
                                        {currentAnalytics ? (
                                            <Box>
                                                <p className="text-center kpi-title">{currentAnalytics ? currentAnalytics.name : ''}</p>
                                                <CardActionArea
                                                    href={`/private/dashboards/${currentAnalytics.dashboardId}`}>
                                                    <Box display="flex" justifyContent="center" alignItems="center">
                                                        <CardMedia
                                                            component="img"
                                                            height="340"
                                                            image={currentAnalytics && currentAnalytics.logoDocument ? currentAnalytics.logoDocument.url : KPIExample}
                                                            alt={currentAnalytics ? currentAnalytics.name : ''}
                                                        />
                                                    </Box>
                                                </CardActionArea>
                                            </Box>
                                        ) : ''}
                                    </CardContent>
                                    <CardContent className="card-footer card-footer-divider">
                                        {currentAnalytics && currentAnalytics.dashboardId ? (
                                            <BtnLink Btn={Button}
                                                     to={`/private/dashboards/${currentAnalytics.dashboardId}`}
                                                     className="card-action mb-0">
                                                All Saved Visualizations
                                            </BtnLink>
                                        ) : ''}
                                        {/*<Button className="card-action card-purple">Build A list</Button>*/}
                                    </CardContent>
                                </Card>
                            </Col>
                            <Col xs={8}>
                                {/*
                                <Row className="mb-4">
                                    <Col xs={12}>
                                        <Card className="card-dashboard">
                                            <CardContent className="d-flex justify-content-between align-items-center">
                                                <p className="mb-0">Featured updates based on your preferences</span>
                                                <BtnLink Btn={Button} to={'#'} className="card-action card-action-lg">
                                                    Manage My Feed
                                                </BtnLink>
                                            </CardContent>
                                        </Card>
                                    </Col>
                                </Row>
                                */}
                                <Row className="mb-4">
                                    <Col xs={6}>
                                        <Card className="card-dashboard">
                                            <CardContent className="card-header card-header-sm">

                                                <h4 className="card-title">Messages</h4>
                                                <IconButton>
                                                    <MessagesIcon/>
                                                </IconButton>
                                            </CardContent>
                                            <CardContent className="p-0"
                                                         style={{minHeight: 406, maxHeight: 406, overflowY: 'scroll'}}>
                                                <List className="list-divider list-messages">
                                                    {messages.map((item, i) => (
                                                        <ListItemButton key={i} divider
                                                                        title={`Message from ${_.get(item, 'messageFrom.fullName')}\n ${_.get(item, 'messageFrom.email')}`}
                                                                        onClick={() => showPreview(item, 'Message Details', 'message')}>
                                                            <ListItemAvatar>
                                                                <Avatar width="35" height="35"
                                                                        src={item && item.messageFrom && item.messageFrom.photo && item.messageFrom.photo.url ? item.messageFrom.photo.url : ''}
                                                                        alt={item.messageFrom.firstName + ' ' + item.messageFrom.lastName}/>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={_.get(item, 'messageFrom.firstName') + ' ' + _.get(item, 'messageFrom.lastName')}
                                                                secondary={item.subject}
                                                                secondaryTypographyProps={{
                                                                    sx: getMessageSecondaryStyle(item)
                                                                }}
                                                            />
                                                            <ListItemIcon>
                                                                {item.messageStatus !== 'Read' ?
                                                                    <MailOutlineIcon sx={{color: '#4a4a4a'}} />
                                                                    :
                                                                    <DraftsOutlinedIcon/>
                                                                }
                                                            </ListItemIcon>
                                                        </ListItemButton>
                                                    ))}
                                                </List>
                                            </CardContent>
                                            <CardContent className="card-footer">
                                                <BtnLink Btn={Button} to={'#'} className="card-action"
                                                         startIcon={<LikeIcon/>}>
                                                    Mark as Relevant
                                                </BtnLink>
                                            </CardContent>
                                        </Card>
                                    </Col>
                                    <Col xs={6}>
                                        <Card className="card-dashboard">
                                            <CardContent className="card-header card-header-sm">
                                                <h4 className="card-title">News Feed</h4>
                                                <IconButton color="primary">
                                                    <Receipt/>
                                                </IconButton>
                                            </CardContent>
                                            <CardContent className="p-0"
                                                         style={{minHeight: 406, maxHeight: 406, overflowY: 'scroll'}}>
                                                <List className="list-divider">
                                                    {feeds.map((item) => (
                                                        <ListItemButton key={item.id} href={item.url} target={'_blank'}>
                                                            <ListItemText secondary={<p>&ndash; {item.title}</p>}/>
                                                        </ListItemButton>
                                                    ))}
                                                </List>
                                            </CardContent>
                                            <CardContent className="card-footer">
                                                <BtnLink Btn={Button} to={'#'} className="card-action"
                                                         startIcon={<LikeIcon/>}>
                                                    Mark as Relevant
                                                </BtnLink>
                                            </CardContent>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Row>
                                    <Col xs={12} className="mb-4">
                                        <Card className="card-dashboard">
                                            <CardContent className="card-header card-header-sm">
                                                <h4 className="card-title">My Tasks</h4>
                                                <IconButton color="primary">
                                                    <TasksIcon/>
                                                </IconButton>
                                            </CardContent>
                                            <CardContent className="p-0"
                                                         style={{minHeight: 300, maxHeight: 300, overflowY: 'scroll'}}>
                                                <List className="list-divider list-tasks">
                                                    {tasks.map((item) => (
                                                        <ListItemButton key={item.id} divider
                                                                        title={`Task assigned by  ${_.get(item, 'taskManager.fullName')}\n ${_.get(item, 'taskManager.email')}`}
                                                                        onClick={() => showPreview(item, 'Task Details', 'task')}>
                                                            <ListItemText secondary={item.name}/>
                                                        </ListItemButton>
                                                    ))}
                                                </List>
                                            </CardContent>
                                        </Card>
                                    </Col>
                                    <Col xs={12} className="mb-4">
                                        <Card className="card-dashboard">
                                            <CardContent className="card-header card-header-sm">
                                                <h4 className="card-title">Support</h4>
                                                {/*<Button className="card-action card-green" startIcon={<i className="fa fa-commenting-o"></i>}>Start Live Chat</Button>*/}
                                            </CardContent>
                                            <CardContent className="p-0">
                                                <List className="list-divider list-tasks">
                                                    <ListItem divider>
                                                        <ListItemButton href={'https://riskq.freshdesk.com/'}
                                                                        target={'_blank'}>
                                                            <ListItemText secondary={'Get Help Now'}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <ListItem divider>
                                                        <ListItemButton
                                                            href={'https://riskq.freshdesk.com/support/solutions/articles/151000016625-valurisq-faqs'}
                                                            target={'_blank'}>
                                                            <ListItemText secondary={'FAQ'}/>
                                                        </ListItemButton>
                                                    </ListItem>
                                                    {/*
                                                    <ListItem divider>
                                                        <ListItemText secondary={'Chat with a Customer Success Agent in real-time 24 hour a day, every day.'} />
                                                    </ListItem>
                                                    */}
                                                </List>
                                            </CardContent>
                                            <CardContent className="card-footer">
                                                <BtnLink Btn={Button} to={'#'} className="card-action"
                                                         startIcon={<LikeIcon/>}>
                                                    Mark as Relevant
                                                </BtnLink>
                                            </CardContent>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={8}>
                                <Row className="mb-4">
                                    {/*
                                    <Col xs={6}>
                                        <Card className="card-dashboard">
                                            <CardContent className="card-header card-header-sm">
                                                <h4 className="card-title">My Tickets</h4>
                                                <IconButton color="primary">
                                                    <TicketsIcon />
                                                </IconButton>
                                            </CardContent>
                                            <CardContent className="p-0">
                                                <Table className="table-ticket" size="medium">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center">Key</TableCell>
                                                            <TableCell>Summary</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {
                                                        (tickets && tickets.length) ? (tickets.map((item) => (
                                                            <TableRow key={item.id}>
                                                                <TableCell align="center">
                                                                    <ChangeHistory sx={{transform: 'rotate(90deg)', color: '#37435D'}}/>&nbsp;{item.code}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.title}
                                                                </TableCell>
                                                            </TableRow>)))
                                                        : (<TableRow><TableCell align="center" colSpan={2}><strong>No tickets found</strong></TableCell></TableRow>)
                                                    }
                                                    </TableBody>
                                                </Table>
                                            </CardContent>
                                        </Card>
                                    </Col>
                                    */}
                                    <Col xs={6}>
                                        <Card className="card-dashboard">
                                            <CardContent className="card-header card-header-sm">
                                                <h4 className="card-title">Recorded Webinars</h4>
                                                <IconButton color="primary">
                                                    <ImportantDevices/>
                                                </IconButton>
                                            </CardContent>
                                            <CardContent className="p-0"
                                                         style={{minHeight: 590, maxHeight: 590, overflowY: 'scroll'}}>
                                                <List className="list-divider list-webinars">
                                                    {webinars.map((item) => (
                                                        <ListItemButton key={item.id} href={item.url} target={'_blank'}>
                                                            <ListItemText secondary={item.title}/>
                                                        </ListItemButton>
                                                    ))}
                                                </List>
                                            </CardContent>
                                            <CardContent className="card-footer">
                                            </CardContent>
                                        </Card>
                                    </Col>

                                    <Col xs={6}>
                                        <Card className="card-dashboard mb-4">
                                            <CardContent className="card-header card-header-sm">
                                                <h4 className="card-title">What's New</h4>
                                                <IconButton color="primary">
                                                    <Campaign/>
                                                </IconButton>
                                            </CardContent>
                                            <CardContent className="p-0"
                                                         style={{minHeight: 220, maxHeight: 220, overflowY: 'scroll'}}>
                                                <List className="list-divider list-news">
                                                    {news.map((item) => (
                                                        <ListItemButton key={item.id} href={item.url} target={'_blank'}>
                                                            <ListItemText secondary={item.title}/>
                                                        </ListItemButton>
                                                    ))}
                                                </List>
                                            </CardContent>
                                            <CardContent className="card-footer">
                                            </CardContent>
                                        </Card>

                                        <Card className="card-dashboard">
                                            <CardContent className="card-header card-header-sm">
                                                <h4 className="card-title">Sign up for Pace Courses</h4>
                                                <IconButton color="primary">
                                                    <BookIcon/>
                                                </IconButton>
                                            </CardContent>
                                            <CardContent className="p-0"
                                                         style={{minHeight: 240, maxHeight: 240, overflowY: 'scroll'}}>
                                                <List className="list-divider list-paces">
                                                    {paces.map((item) => (
                                                        <ListItemButton key={item.id} href={item.url} target={'_blank'}>
                                                            <ListItemText secondary={item.title}/>
                                                        </ListItemButton>
                                                    ))}
                                                </List>
                                            </CardContent>
                                            <CardContent className="card-footer">
                                            </CardContent>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs={6}>
                                    </Col>
                                    <Col xs={6}>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ConnectedInitializer>
                </div>
            </Container>
        );
    }
}

export default connect(
    state => ({
        expectAnswer: state.home.expectAnswer,
        externalAnalytics: state.home.externalAnalytics,
        messages: state.home.messages,
        feeds: state.home.feeds,
        tasks: state.home.tasks,
        webinars: state.home.webinars,
        news: state.home.news,
        paces: state.home.paces,
        tickets: state.home.tickets,
        user: state.app.user
    }),
    dispatch => ({
        initialize: () => dispatch({type: EDIT.INITIALIZE}),
        clear: () => dispatch({type: EDIT.CLEAR}),
        showPreview: (item, title, previewType) => dispatch({type: PREVIEW_DIALOG.INITIALIZE, item, title, previewType})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.home.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

function getMessageSecondaryStyle(message) {
    let style = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    };

    if (message.messageStatus !== 'Read') {
        style.color = '#4a4a4a';
        style.fontWeight = 'bold';
    }

    return style;
}

/**
 * search filter
 *
 * @public
 */
/*
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter:  state.home.filter,
        disabled: state.home.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: EDIT.META, filter}),
        applyFilter: filter => dispatch({type: EDIT.SEARCH, filter}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <TextFilterBootstrap
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        className="control-search"
        placeholder={'Search for companies, contacts, industries, etc'}
    />
)));
*/
