// outsource dependencies
import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import Paper from '@mui/material/Paper';

// local dependencies
import Form from "./form";
import {QUAL_EDIT} from "../actions";
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {translate} from '../../../services/translate.service';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {QUAL_METRICS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {useParams} from "react-router-dom";
import {NEW_ID} from "../../../constants/spec";
import {LIST} from '../../cyber-roles/actions';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const EditMetric = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={QUAL_METRICS_MAP}  />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:8,offset:2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                <span>
                                    <RichHintTitle
                                        update={LIST}
                                        name={isNew ? translate('SCORING_METRICS$CREATE_METRIC') : translate('SCORING_METRICS$EDIT_METRIC')}
                                        expectAnswer={expectAnswer}
                                        data={findHint(hints,`SCORING_METRICS_TITLE`)}
                                    />
                                </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                            <ConnectedError />
                            <Form />
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({ expectAnswer: state.qualMetrics.edit.meta.expectAnswer, newItem: state.qualMetrics.edit.meta.newItem, hints: state.qualMetrics.edit.meta.hintsData }),
    dispatch => ({
        initialize: ( id ) => dispatch({type: QUAL_EDIT.INITIALIZE, id }),
        clear: () => dispatch({type: QUAL_EDIT.CLEAR})
    })
)(EditMetric)

const ConnectedInitializer = connect(
    state => ({initialize: state.qualMetrics.edit.meta.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.qualMetrics.edit.meta.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: QUAL_EDIT.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
