
// outsource dependencies

// local dependencies
import GEO_RECORDS from './actions';

let initial = {
    data: {},
    states: [],
    hintsData: [],
    // meta
    showModal: false,
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
};

export default function ( state = initial, action ) {
    // eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case GEO_RECORDS.CLEAR:
            state = initial;
            break;
        case GEO_RECORDS.DATA:
            state = { ...state, data: options.data };
            break;
        case GEO_RECORDS.META:
            state = { ...state, ...options, data: state.data };
            break;
    }

    return state;
}
