// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import {history} from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import MdInput from '../../../components/md-input';
import MdButton from '../../../components/md-button';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {translate} from "../../../services/translate.service";
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {BUSINESS_INTERRUPTION_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";

class Edit extends Component {
    componentDidMount() { this.props.initialize( this.props.match.params.id ); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, match } = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ BUSINESS_INTERRUPTION_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span:8,offset:2}}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    <span>
                                        {isNew
                                            ? translate('BUSINESS_INTERRUPTION$CREATE_INTERRUPTION')
                                            : translate('BUSINESS_INTERRUPTION$EDIT_INTERRUPTION')
                                        }
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON" />
                                </h2>
                                <ConnectedError />
                                <ConnectedForm isNew={isNew} />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.businessInterruption.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.businessInterruption.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.businessInterruption.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        initialValues: state.businessInterruption.edit.data,
        disabled: state.businessInterruption.edit.expectAnswer
    }),
    dispatch => ({ update: ( formData ) => dispatch({type: EDIT.UPDATE, ...formData}) })
)(reduxForm({
    form: 'editBusinessInterruption',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name || values.name.length < 5) {
            errors.name = 'Name is required and must contain at least 5 symbol character';
        }
        // description
        if (!values.description || values.description.length < 10) {
            errors.description = 'Description is required and must contain at least 10 symbol character';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew})=>(
    <form autoComplete="off" name="editBusinessInterruption" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-3">
            <Col xs={12} md={6} className="offset-bottom-3">
                <Field
                    name="name"
                    placeholder="Name"
                    component={MdInput}
                    disabled={disabled}
                     required={true}
                                    label={(<strong className="required-asterisk"> Name </strong>)}
                        />
            </Col>
            <Col xs={12} md={6}>
                <Field
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder="Description"
                     required={true}
                                    label={(<strong className="required-asterisk"> Description </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <MdButton type="submit" disabled={ pristine || invalid || disabled } className="offset-right-2">
                    { isNew ? (<span> Create </span>) : (<span> Save </span>) }
                </MdButton>
                <MdButton
                    type="reset"
                    color="secondary"
                    disabled={ pristine || disabled }
                    className="offset-right-2"
                    onClick={reset}
                        >
                    Reset
                </MdButton>
                <MdButton className="md-btn md-btn-danger" onClick={ () => history.back()}>
                    Cancel
                </MdButton>
            </Col>
        </Row>
    </form>
)));
