// outsource dependencies
import { get } from 'lodash';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CloudDownload } from '@mui/icons-material';
import { IconButton, InputLabel, Paper, Tooltip } from '@mui/material';
import { arrayRemove, Field, getFormValues, reduxForm } from 'redux-form';

// local dependencies
import { EDIT } from '../actions';
import { NEW_ID } from '../../../constants/spec';
import MdInput from '../../../components/md-input';
import { DOWNLOAD } from '../../../constants/routes';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import withDownloadLink from '../../../components/download-link';
import { formatFileSize } from '../../../services/data-formatting.service';
import { translate, withTranslation } from '../../../services/translate.service';
import { CancelBtn, DeleteIconBtn, PrimaryBtn, ResetBtn, SubmitBtn } from '../../../components/md-button';
import { GDPR_EVIDENCE_DOCUMENTS_MAP } from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import { useParams } from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';

// config
export const FORM_NAME = 'editEvidenceDocuments';
const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    let {disabled, message, clearError, data} = props;
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={GDPR_EVIDENCE_DOCUMENTS_MAP} />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} lg={{span: 8, offset: 2}}>
                        <Paper className="indent-5"> <Container fluid>
                            <Row className="offset-bottom-8">
                                <Col xs={12} md={data.templateLink ? 8 : 12}>
                                    <h2 className="text-uppercase">
                                        <span>
                                            <RichHintTitle
                                                update={EDIT}
                                                name={`GDPR$EDIT_DOCUMENT`}
                                                data={findHint(hints, `GDPR_EVIDENCE_DOCUMENTS_EDIT_TITLE`)}
                                            />
                                        </span>
                                        <Preloader expectAnswer={disabled} type="ICON" />
                                    </h2>
                                </Col>
                                {data.templateLink && (
                                    <Col xs={12} md={4} className="text-nowrap text-right top-indent-4">
                                        <PrimaryBtn download href={data.templateLink}
                                                    tooltip={translate('GLOBALS$DOWNLOAD_TEMPLATE')}
                                                    className="link-unstyled">
                                            {translate('GLOBALS$DOWNLOAD_TEMPLATE')}
                                        </PrimaryBtn>
                                    </Col>
                                )}
                            </Row>
                            <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError} /> </Col>
                            </Row>
                            <Row> <Col xs={12}> <ConnectedForm isNew={isNew} /> </Col> </Row>
                        </Container> </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({
        data: state.gdprEvidenceDocuments.edit.data,
        message: state.gdprEvidenceDocuments.edit.errorMessage,
        disabled: state.gdprEvidenceDocuments.edit.expectAnswer,
        hints: state.gdprEvidenceDocuments.edit.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.gdprEvidenceDocuments.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const DownloadLink = withTranslation(withDownloadLink()(props => (
    <Tooltip title={translate('GLOBALS$DOWNLOAD')} className="offset-bottom-1"><span>
        <IconButton {...props} color="primary" style={{padding: '5px'}} aria-label={translate('GLOBALS$DOWNLOAD')}>
            <CloudDownload fontSize="small" />
        </IconButton>
    </span></Tooltip>
)));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.gdprEvidenceDocuments.edit.data,
        disabled: state.gdprEvidenceDocuments.edit.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
        hints: state.gdprEvidenceDocuments.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        removeDocument: index => dispatch(arrayRemove(FORM_NAME, 'documents', index)),
    })
)(reduxForm({form: FORM_NAME, enableReinitialize: true})(
    ({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formValues = {}, removeDocument, hints}) => (
        <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-4"> <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                    label={(<strong> {translate('GLOBALS$NAME')} </strong>)}
                />
            </Col> </Row>
            <Row className="offset-bottom-4"> <Col xs={12}>
                <Field
                    component={MdInput}
                    disabled={disabled}
                    name="documentType"
                    placeholder={translate('GDPR$DOCUMENT_TYPE')}
                    label={(<strong> {translate('GDPR$DOCUMENT_TYPE')} </strong>)}
                />
            </Col> </Row>
            <Row className="offset-bottom-4"> <Col xs={12}>
                <h4><strong>{translate('GDPR$ARTICLES_COLON')}</strong></h4>
                <ul className="offset-0">
                    {(get(formValues, 'articles', [])).map((item, index) => (
                        <li key={index}>{get(item, 'articleNumber') + ' - ' + get(item, 'name')}</li>
                    ))}
                </ul>
            </Col> </Row>
            <Row className="offset-bottom-4"> <Col xs={12}>
                <h4><strong>{translate('GLOBALS$DOCUMENTS_COLON')} </strong></h4>
                {!Boolean((get(formValues, 'documents.length', 0))) ? (
                    <h4>{translate('GDPR$NO_DOCUMENTS')}</h4>
                ) : (
                    <ul className="offset-0">
                        {(get(formValues, 'documents', [])).map((item, index) => (
                            <li key={index}>
                                <strong>{get(item, 'fileName')}</strong> ({formatFileSize(get(item, 'fileSize'))})&nbsp;
                                {get(item, 'downloadUrl') && (<DownloadLink
                                    link={() => DOWNLOAD.EVIDENCE_DOCUMENT_LINK({downloadUrl: get(item, 'downloadUrl')})} />)}&nbsp;
                                <DeleteIconBtn className="offset-bottom-1" style={{padding: '5px'}}
                                               onClick={() => removeDocument(index)} />
                            </li>
                        ))}
                    </ul>
                )}
            </Col> </Row>
            <Row className="offset-bottom-8"> <Col xs={12} className='dropzone'> <UploadFile /> </Col> </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"  hint={findHint(hints, `BUTTON_GDPR_EVIDENCE_DOCUMENTS_SAVE`)} />
                    <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"  hint={findHint(hints, `BUTTON_GDPR_EVIDENCE_DOCUMENTS_RESET`)} />
                    <CancelBtn onClick={cancel}  hint={findHint(hints, `BUTTON_GDPR_EVIDENCE_DOCUMENTS_CANCEL`)} />
                </Col>
            </Row>
        </form>
    ))));

const UploadFile = withTranslation(connect(
    state => ({
        uploaded: state.gdprEvidenceDocuments.edit.uploaded,
        disabled: state.gdprEvidenceDocuments.edit.expectAnswer
    }),
    dispatch => ({uploadFile: file => dispatch({type: EDIT.UPLOAD_FILE, file})})
)(({uploadFile, uploaded, disabled, ...attr}) => (<div>
    <InputLabel htmlFor="fileUpload"> <strong> {translate('GLOBALS$DOCUMENT_UPLOAD')} </strong> </InputLabel>
    <Dropzone
        {...attr}
        id="fileUpload"
        className="dropzone"
        disabled={!uploaded || disabled}
        accept={{
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xlsx', '.xls'],
            'application/vnd.ms-word': ['.docx', '.doc'],
            'application/pdf': ['.pdf'],
        }}
        activeClassName="dropzone-active"
        disabledClassName="dropzone-disabled"
        onDrop={(acceptedFiles, rejectedFiles) => {
            !rejectedFiles.length && uploadFile(acceptedFiles[0])
        }}
    >
        {({rejectedFiles, getRootProps, getInputProps}) => {
            if (!uploaded) {
                return (<h3 className="text-center text-muted">{translate('GLOBALS$LOADING')}</h3>);
            }
            return (<div {...getRootProps()} className="text-center text-muted">
                <input {...getInputProps()} />
                <p style={{fontSize: '50px'}}><i className="fa fa-upload" /></p>
                <h3 className="offset-top-0">{translate('DROPZONE$DEFAULT_MESSAGE')}</h3>
            </div>)
        }}
    </Dropzone>
</div>)));
