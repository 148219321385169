// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, FieldArray, formValueSelector, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {DEPLOYMENT_TYPES, ENTITY_TYPES, NEW_ID, QUANT_METRIC_LEVEL} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import MetricFormulaItems from '../../../components/metric-formula-items';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {QUANT_METRICS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import get from 'lodash/get';
import {MdSelect} from '../../../components/md-select';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import {LIST} from '../../cyber-roles/actions';

// config
const FORM_NAME = 'editQuantMetric';
export const changeField = (field, value) => change(FORM_NAME, field, value);

// config

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, []);
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={QUANT_METRICS_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                    <span>
                                         <RichHintTitle
                                             update={LIST}
                                             name={isNew ? translate('EXPOSURE_METRICS$CREATE_METRIC') : translate('EXPOSURE_METRICS$EDIT_METRIC')}
                                             expectAnswer={expectAnswer}
                                             data={findHint(hints, isNew ? translate('EXPOSURE_METRICS_CREATE_TITLE') : translate('EXPOSURE_METRICS_EDIT_TITLE'))}
                                         />
                                    </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};

export default connect(
    state => ({expectAnswer: state.quantMetrics.edit.expectAnswer, hints: state.quantMetrics.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.quantMetrics.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.quantMetrics.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.quantMetrics.edit.data,
        disabled: state.quantMetrics.edit.expectAnswer,
        variableTypes: state.quantMetrics.edit.variableTypes,
        riskMetrics: state.quantMetrics.edit.riskMetrics,
        constants: state.quantMetrics.edit.constants,
        hints: state.quantMetrics.edit.hintsData,
        formulaValues: formValueSelector(FORM_NAME)(state, 'metricFormulaItems')
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        changeField: (field, value) => dispatch(change(FORM_NAME, field, value)),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // quant
        if (!values.quant) {
            errors.quant = 'EXPOSURE_MODELS$EXPOSURE_MODEL_REQUIRED';
        }
        if (!values.quantMetricLevel) {
            errors.quantMetricLevel = 'EXPOSURE_MODELS$EXPOSURE_LEVEL_REQUIRED';
        }
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        // metric formula items
        if (!values.metricFormulaItems || !values.metricFormulaItems.length) {
            errors.metricFormulaItems = {_error: 'METRIC_FORMULA$FORMULA_ITEMS_REQUIRED'};
        } else {
            let variablesErrors = [];
            values.metricFormulaItems.forEach((variable, i) => {
                const variableErrors = {};
                if (variable.isOperation && !variable.operation) {
                    variableErrors.operation = 'METRIC_FORMULA$OPERATION_REQUIRED';
                    variablesErrors[i] = variableErrors;
                }
                if (!variable.isOperation && !variable.variableType) {
                    variableErrors.variableType = 'METRIC_FORMULA$VARIABLE_TYPE_REQUIRED';
                    variablesErrors[i] = variableErrors;
                }
            });
            if (variablesErrors.length) {
                errors.metricFormulaItems = variablesErrors;
            }
        }
        return errors;
    }
})(({
    handleSubmit,
    invalid,
    pristine,
    disabled,
    update,
    reset,
    isNew,
    cancel,
    variableTypes,
    riskMetrics,
    constants,
    formulaValues = [],
    changeField,
    hints
}) => (
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={6}>
                <SelectEntities
                    name="quant"
                    disabled={disabled}
                    type={ENTITY_TYPES.QUANT_MODELS}
                    placeholder={translate('EXPOSURE_MODELS$EXPOSURE_MODEL')}
                    label={(
                        <strong className="required-asterisk"> {translate('EXPOSURE_MODELS$EXPOSURE_MODEL')} </strong>)}
                />
            </Col>
            <Col xs={6}>
                <Field
                    labelKey="label"
                    valueKey="value"
                    name="quantMetricLevel"
                    disabled={disabled}
                    component={MdSelect}
                    sendValueProp
                    placeholder={translate('EXPOSURE_MODELS$EXPOSURE_LEVEL')}
                    options={['ORGANIZATION', 'SYSTEM', 'PROCESS'].map(option => {
                        return ({
                            label: QUANT_METRIC_LEVEL[option],
                            value: option
                        });
                    })}
                    optionsLabel={({value}) => QUANT_METRIC_LEVEL[value]}
                    label={(
                        <strong className="required-asterisk"> {translate('EXPOSURE_MODELS$EXPOSURE_LEVEL')} </strong>)}
                    simpleValue={(value) => {
                        return {
                            value: value || '',
                            label: value && QUANT_METRIC_LEVEL[value] ? QUANT_METRIC_LEVEL[value] : '',
                        };
                    }}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$DESCRIPTION')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={8}>
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="regulations"
                    type={ENTITY_TYPES.REGULATIONS}
                    placeholder={translate('EXPOSURE_MODELS$REGULATIONS')}
                    label={(<strong> {translate('EXPOSURE_MODELS$REGULATIONS')} </strong>)}
                    getOptionLabel={option => `${get(option, 'acronym', '')} - ${get(option, 'name', '')}`}
                />
            </Col>
            <Col xs={4}>
                <Field
                    labelKey="label"
                    valueKey="value"
                    name="deploymentType"
                    disabled={disabled}
                    isClearable={true}
                    component={MdSelect}
                    sendValueProp
                    placeholder={translate('SYSTEMS$DEPLOYMENT_TYPE')}
                    options={['ON_PREMISE', 'CLOUD', 'HYBRID'].map(option=>{
                        return ({
                            label: translate(DEPLOYMENT_TYPES[option]) ,
                            value: option
                        })
                    })}
                    optionsLabel={({value}) => value ? translate(DEPLOYMENT_TYPES[value]) : '' }
                    required={false}
                    label={(<strong> {translate('SYSTEMS$DEPLOYMENT_TYPE')} </strong>)}
                    simpleValue={(value) => { return {value: value, label: value && DEPLOYMENT_TYPES[value] ? translate(DEPLOYMENT_TYPES[value]) : '' } }}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="dataTypeClassifications"
                    type={ENTITY_TYPES.DATA_TYPE_CLASSES}
                    placeholder={translate('DATA_CLASSES$TITLE')}
                    label={(<strong> {translate('DATA_CLASSES$TITLE')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="technologyCategories"
                    type={ENTITY_TYPES.TECH_CATEGORIES}
                    placeholder={translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CATEGORY')}
                    label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CATEGORY')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="technologies"
                    type={ENTITY_TYPES.TECHNOLOGIES}
                    placeholder={translate('TECHNOLOGIES$TECHNOLOGY')}
                    label={(<strong> {translate('TECHNOLOGIES$TECHNOLOGY')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="industries"
                    type={ENTITY_TYPES.INDUSTRIES}
                    placeholder={translate('EXPOSURE_METRICS$INDUSTRIES')}
                    label={(<strong> {translate('EXPOSURE_METRICS$INDUSTRIES')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <FieldArray
                    editItem={changeField}
                    values={formulaValues}
                    name="metricFormulaItems"
                    variableTypes={variableTypes}
                    riskMetrics={riskMetrics}
                    constants={constants}
                    component={MetricFormulaItems}

                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_EXPOSURE_METRICS_CREATE' : 'BUTTON_EXPOSURE_METRICS_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"
                          hint={findHint(hints, 'BUTTON_EXPOSURE_METRICS_RESET')}/>
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_EXPOSURE_METRICS_CANCEL')}/>
            </Col>
        </Row>
    </form>
))));
