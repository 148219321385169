
// outsource dependencies

// local dependencies

import { QUAL_EDIT } from "../actions";

let initial = {
    data: {},
    meta: {
        initialized: false,
        expectAnswer: false,
        errorMessage: null,
        newItem: false
    }
};

export default function ( state = initial, action ) {
    //eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case QUAL_EDIT.CLEAR:
            state = initial;
            break;
        case QUAL_EDIT.ITEM:
            state = { ...state, ...options };
            break;
        case QUAL_EDIT.META:
            state = { ...state, meta: {...state.meta, ...options} };
            break;
    }
    return state;
}
