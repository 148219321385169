
// outsource dependencies
import { get } from 'lodash';
import { call, put, take, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { history } from '../../store';
import { APP } from '../../actions/types';
import * as ROUTES from '../../constants/routes';
import {
    login,
    verifyMFA,
    MFA_ERROR,
    getGoogleToken,
    loginWithGoogle,
    getMicrosoftToken,
    loginWithMicrosoft,
    loginWithOkta, MFA_TOTP_NOT_SET_ERROR
} from '../../services/api.service';
import storageService from "../../services/storage.service";

function* signInSaga ({ mode, email, password, code }) {
    yield put({type: APP.SIGN_IN.META, errorMessage: null, notification: null });
    try {
        // NOTE take data from location to restore certain private page for returning
        const from = get(history.location, 'state.from', {});
        let { hasMultiFactorAuth, mfaToken, locale } = yield select(state => state.app);
        let path = yield call(getPath, from.pathname + from.search);
        let user = {};
        // NOTE define authorization type
        if (!hasMultiFactorAuth) {
            if (mode === 'google') {
                // NOTE google authorization
                const token = yield call(getGoogleToken);
                user = yield call(loginWithGoogle, {token, locale});
            } else if (mode === 'microsoft') {
                // NOTE microsoft authorization.
                const {idToken, username, accessToken} = yield call(getMicrosoftToken);
                user = yield call(loginWithMicrosoft, {idToken, username, accessToken, locale});
            } else if (mode === 'okta') {
                // NOTE okta authorization.
                // const {idToken, username, accessToken} = yield call(getMicrosoftToken);
                user = yield call(loginWithOkta, {token: code, locale});
            } else {
                // NOTE common authorization
                user = yield call(login, {email, password, locale});
            }
        } else {
            // NOTE MFA authorization
            user = yield call(verifyMFA, {mfaToken, code, locale});
            yield put({type: APP.META, hasMultiFactorAuth: false, totpAppIsNotSet: false});
        }
        yield put({type: APP.SIGN_IN.SUCCESS, user, expired: user.credentialsExpired, totpAppIsNotSet: false, qrCodeUrl: null});
        // NOTE setup risk model
        yield put({type: APP.SETUP_RISK_MODEL.REQUEST, fromStorage: true});
        let { type, message } = yield take([APP.SETUP_RISK_MODEL.SUCCESS, APP.SETUP_RISK_MODEL.ERROR]);
        if ( type === APP.SETUP_RISK_MODEL.ERROR ) {
            throw new Error(message);
        }
        // NOTE manage languages
        yield put({type: APP.SETUP_LANGUAGES});

        // NOTE go to private page after authorization
        console.log(path, 'after authorization');

        // Trying to apply return url
        if (storageService.get('returnUrl')) {
            path = storageService.get('returnUrl');
            console.log('Found return URL after authorization: ', path);
            storageService.remove('returnUrl');
        }

        yield call(history.push, path);
    } catch ( e ) {
        // NOTE MFA required
        if (e.error === MFA_ERROR) {
            let notification = e.mfa_type === 'TOTP' ? 'AUTHORIZATION$MFA_TOTP_NOTIFICATION' : 'AUTHORIZATION$MFA_NOTIFICATION';
            yield put({type: APP.META, hasMultiFactorAuth: true, totpAppIsNotSet: false});
            yield put({type: APP.SIGN_IN.ERROR, errorMessage: null, notification, mfaToken: e.mfa_token});
        } else if (e.error === MFA_TOTP_NOT_SET_ERROR) {
            let notification = 'AUTHORIZATION$MFA_TOTP_REGISTER';
            yield put({type: APP.META, hasMultiFactorAuth: true, totpAppIsNotSet: true});
            yield put({type: APP.SIGN_IN.ERROR, errorMessage: null, notification, mfaToken: e.mfa_token, qrCodeUrl: e.totp_qr_code_url});
        } else {
            yield put({type: APP.SIGN_IN.ERROR, errorMessage: e.error_description });
        }
    }
    yield put({type: APP.SIGN_IN.FINISH});
}

/**
 * connect page sagas
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(APP.SIGN_IN.REQUEST, signInSaga);
}

/**
 * helper to determine correctness url
 *
 * @param {String} path
 * @return {Object}
 * @public
 */
function getPath (path) {
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(path)) {
            return path;
        }
    }
    return ROUTES.HOME.LINK();
}
