// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {Launch} from '@mui/icons-material';
import {Field, reduxForm} from 'redux-form';
import {
    Card,
    CardActions,
    CardContent,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip
} from '@mui/material';
import {withStyles} from '@mui/styles'

// local dependencies
import {MODELS_MODAL} from './actions';
import {APP} from '../../actions/types';
import MdInput from '../../components/md-input';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {translate, withTranslation} from '../../services/translate.service';
import MdButton, {AddIconBtn, EditIconBtn, RefreshIconBtn, WarningBtn} from '../../components/md-button';


export default withStyles({ paper: { minWidth: '500px' } })( connect(
    state => ({
        list: state.modelsModal.list,
        showNew: state.modelsModal.meta.showNew,
        showModal: state.modelsModal.meta.showModal,
        initialized: state.modelsModal.meta.initialized,
        expectAnswer: state.modelsModal.meta.expectAnswer,
        errorMessage: state.modelsModal.meta.errorMessage,
    }),
    dispatch => ({
        closeModal: () => dispatch({type: MODELS_MODAL.CLEAR}),
        showNewAction: () => dispatch({type: MODELS_MODAL.SHOW_NEW}),
        rebuildRiskModel: () => dispatch({type: APP.REBUILD_RISK_MODEL}),
        clearError: () => dispatch({ type: MODELS_MODAL.META, errorMessage: null }),
    }),
)(({ showModal, initialized, closeModal, list, id, expectAnswer, showNew, showNewAction, errorMessage, clearError, classes, rebuildRiskModel }) => (
    <Dialog
        open={showModal}
        onClose={(event, reason) => {
            if(reason === "backdropClick" && reason === "escapeKeyDown") return false;
            closeModal();
        }}
        classes={{paper: classes.paper}}
        aria-labelledby="selectModalTitle"
            >
        <DialogTitle id="selectModalTitle" disableTypography={true}>
            <h3 className="text-uppercase">
                <span className="align-middle">{translate('RISK_MODEL$DIALOG_TITLE')}</span>&nbsp;
                {!showNew && (<AddIconBtn disabled={!initialized} onClick={showNewAction} />)}
                <WarningBtn tooltip={translate('GLOBALS$REBUILD')} onClick={rebuildRiskModel} className="pull-right" trigger={["hover"]} placement="bottom">
                    Rebuild
                </WarningBtn>
                <Preloader expectAnswer={expectAnswer} type="ICON" />
            </h3>
        </DialogTitle>
        <DialogContent>
            <Preloader expectAnswer={!initialized} type="MIN_HEIGHT" height={200}>
                <ErrorMessage active message={errorMessage} onChange={clearError}/>
                <Row> <Col xs={12}> <ConnectedCreateForm /> </Col> </Row>
                {list.map((item, i) => (
                    <Row key={i} className="offset-bottom-4">
                        <Col xs={12}>
                            <ConnectedItem
                                id={item.id}
                                form={item.formName}
                                initialValues={item}
                                disabled={expectAnswer}
                                expanded={item.expanded}
                                    />
                        </Col>
                    </Row>
                ))}
            </Preloader>
        </DialogContent>
        <DialogActions> <MdButton variant="text" onClick={closeModal}> {translate('GLOBALS$CLOSE')} </MdButton> </DialogActions>
    </Dialog>
)));

/**
 * item component connected to form
 *
 * @param {Object} props
 */
const ConnectedItem = withTranslation(reduxForm({validate, form: 'setupFromParent', enableReinitialize: true})(
    connect( null,
        dispatch => ({
            cancel: () => dispatch({type: MODELS_MODAL.TOGGLE_CARD}),
            update: formData => dispatch({type: MODELS_MODAL.UPDATE_MODEL, ...formData}),
            rebuildRiskModel: id => dispatch({type: APP.REBUILD_RISK_MODEL, id}),
            selectRiskModel: id => {
                dispatch({type: MODELS_MODAL.CLEAR});
                dispatch({type: APP.SETUP_RISK_MODEL.REQUEST, id});
            },
            expandForEdit: id => dispatch({type: MODELS_MODAL.TOGGLE_CARD, id, expanded: true}),
        })
    )(({ update, cancel, expandForEdit, selectRiskModel, rebuildRiskModel, id, expanded, handleSubmit, disabled, invalid }) => (
        <Card>
            <form autoComplete="off" name="formNameFromParent" onSubmit={handleSubmit(update)}>
                <CardContent>
                    <Row>
                        <Col xs={7}>
                            <Field
                                name="name"
                                component={ MdInput }
                                disabled={!expanded||disabled}
                                placeholder={translate('GLOBALS$NAME')}
                                style={!expanded ? {color: 'black'} : {}}
                                label={expanded && (<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                                    />
                        </Col>
                        <Col xs={5} className="text-right">
                            <RefreshIconBtn disabled={expanded} onClick={()=>rebuildRiskModel(id)} />
                            <EditIconBtn disabled={expanded} onClick={()=>expandForEdit(id)} />
                            <Tooltip placement="top" title={translate('GLOBALS$SETUP')}><span>
                                <IconButton aria-label={translate('GLOBALS$SETUP')} disabled={expanded} onClick={()=>selectRiskModel(id)}>
                                    <Launch fontSize="small" />
                                </IconButton>
                            </span></Tooltip>
                        </Col>
                    </Row>
                </CardContent>
                <Collapse in={expanded} timeout="auto">
                    <CardContent>
                        <Row className="offset-bottom-2">
                            <Col xs={12}>
                                <Field
                                    multiline
                                    name="description"
                                    disabled={disabled}
                                    component={MdInput}
                                    placeholder={translate('GLOBALS$DESCRIPTION')}
                                    label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                                        />
                            </Col>
                        </Row>
                    </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <MdButton type="submit" variant="text" disabled={invalid||disabled}> {translate('GLOBALS$APPLY')} </MdButton>
                        <MdButton variant="text" onClick={cancel} disabled={disabled}> {translate('GLOBALS$CANCEL')} </MdButton>
                    </CardActions>
                </Collapse>
            </form>
        </Card>
    )
)));

const ConnectedCreateForm = withTranslation(connect(
    state => ({
        showNew: state.modelsModal.meta.showNew,
        disabled: state.modelsModal.meta.expectAnswer,
    }),
    dispatch => ({
        cancel: () => dispatch({type: MODELS_MODAL.CLEAR_NEW}),
        create: formData => dispatch({type: MODELS_MODAL.CREATE_NEW, ...formData}),
    })
)( reduxForm({validate, form: `editModel-NEW`, enableReinitialize: true})(
    ({ disabled, create, handleSubmit, invalid, cancel, showNew }) => (!showNew ? ('') : <Card className="offset-bottom-4">
        <form autoComplete="off" name="editModel" onSubmit={handleSubmit(create)}>
            <CardContent>
                <Row className="offset-bottom-4">
                    <Col xs={8}>
                        <Field
                            name="name"
                            disabled={disabled}
                            component={MdInput}
                            placeholder={translate('GLOBALS$NAME')}
                             required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                                />
                    </Col>
                </Row>
                <Row className="offset-bottom-2">
                    <Col xs={12}>
                        <Field
                            multiline
                            name="description"
                            component={MdInput}
                            disabled={disabled}
                            placeholder={translate('GLOBALS$DESCRIPTION')}
                            label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                                />
                    </Col>
                </Row>
            </CardContent>
            <CardActions style={{justifyContent: 'flex-end'}}>
                <MdButton type="submit" variant="text" disabled={invalid||disabled}>
                    {translate('GLOBALS$CREATE')}
                </MdButton>
                <MdButton variant="text" onClick={cancel} disabled={disabled}>
                    {translate('GLOBALS$CANCEL')}
                </MdButton>
            </CardActions>
        </form>
    </Card>)
)));


/**
 * common validation for all Risk model forms
 *
 * @param {Object} values
 * @constructor
 */
function validate ( values ) {
    let errors = {};
    // NAME
    if (!values.name) {
        errors.name = 'GLOBALS$NAME_REQUIRED';
    }
    return errors;
}
