
// outsource dependencies
import { fork } from 'redux-saga/effects';

// local dependencies
import list from './list/saga';
import edit from './edit/saga';
import questions from './questions/saga';
import statusLog from './status-log/saga';

/**
 * connect all public sagas
 *
 *
 * @public
 */
export default function* () {
    yield fork(list);
    yield fork(edit);
    yield fork(questions);
    yield fork(statusLog);
}
