
// outsource dependencies
import { reset } from 'redux-form';
import { isEqual, isEmpty } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { call, put, takeEvery, take, select } from 'redux-saga/effects';

// local dependencies
import { LIST } from '../actions';
import { initial } from './reducer';
import is from '../../../services/is.service';
import store, { history } from '../../../store';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import { DATA_EXFILTRATION } from '../../../constants/routes';
import { translate } from '../../../services/translate.service';
import CONFIRM_DIALOG from '../../../components/confirmation-dialog/actions';
import separateService from '../../../services/separate-with-comma.service';
import getHintsList, { DATA_EXFILTRATION_LIST_HINTS } from '../../../services/hints.service';

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(LIST.SAVE, saveDataSaga);
    yield takeEvery(LIST.INITIALIZE, initializeSaga);
    yield takeEvery(LIST.UPDATE_DATA, updateDataSaga);
    yield takeEvery(LIST.CHANGE_SORT, changeSortSaga);
    yield takeEvery(LIST.GET_DATA.REQUEST, getDataSaga);
    yield takeEvery(LIST.GET_HINTS_DATA.REQUEST, getHintsDataSaga);

    // NOTE setup listener on location change to listen history back event (POP)
    yield call(history.listen, historyBackListen);
}

function* initializeSaga () {
    yield put({type: LIST.CLEAR});
    // NOTE take data from location and setup verified params
    const params = yield call(getQueryParams, queryService.parse(history.location.search));
    yield put({type: LIST.META, ...params});
    // NOTE get list
    yield put({type: LIST.GET_DATA.REQUEST});
    yield put({type: LIST.GET_HINTS_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    yield put({type: LIST.META, initialized: true});
}

function* getDataSaga () {
    try {
        let options = yield select( state => state.dataExfiltration.list );
        let {data, totalPages} = yield call(getList, options);
        // NOTE reset form values 'cause form doesn't update
        yield put(reset('dataExfiltration'));
        //NOTE separate with commas
        yield call(separateService.separate, data);
        // NOTE setup data
        yield put({type: LIST.DATA, data});
        yield put({type: LIST.META, totalPages});
        // NOTE update location
        yield call(updateLocation, options);
    } catch ( {message} ) {
        yield put({type: LIST.META, errorMessage: message});
    }
    yield put({type: LIST.GET_DATA.FINISH});
}

function* updateDataSaga ({type, ...options}) {
    // NOTE check unsaved data
    let form = yield select(state => state.form.dataExfiltration);
    if (!isEmpty(options) && !isEqual(form.initial, form.values)) {
        // NOTE ask confirmation about leaving page without data saving
        yield put({type: CONFIRM_DIALOG.REQUEST, message: translate('GLOBALS$UNSAVED_DATA_MESSAGE') });
        let answer = yield take([CONFIRM_DIALOG.SUCCESS, CONFIRM_DIALOG.ERROR]);
        // NOTE do nothing if confirmation dismiss
        if ( answer.type === CONFIRM_DIALOG.ERROR ) return;
    }
    // NOTE setup options
    yield put({type: LIST.META, expectAnswer: true, errorMessage: null, ...options});
    // NOTE get list
    yield put({type: LIST.GET_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    yield put({type: LIST.META, expectAnswer: false});
}


function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, DATA_EXFILTRATION_LIST_HINTS);

        // NOTE setup hints data
        yield put({type: LIST.META, hintsData});
    } catch ( {message} ) {
        yield put({type: LIST.META, errorMessage: message});
    }
    yield put({type: LIST.GET_HINTS_DATA.FINISH});
}

function* changeSortSaga ({field}) {
    let { sortF, sortD } = yield select( state => state.dataExfiltration.list );
    // NOTE change direction if field has already chosen
    sortD = field === sortF ? !sortD : true;
    // NOTE get list
    yield put({type: LIST.UPDATE_DATA, sortF: field, sortD, page: 0});
}

function* saveDataSaga ({type, exfiltrations}) {
    yield put({type: LIST.META, expectAnswer: true });
    try {
        // NOTE convert to default numeric
        yield call(separateService.convert, exfiltrations);
        yield call(updateData, exfiltrations);
        yield put({type: LIST.UPDATE_DATA});
        yield call(toastr.success, translate('DATA_EXFILTRATION$TITLE'), translate('GLOBALS$SUCCESSFUL_DATA_UPDATE'));
        yield put({type: LIST.META, expectAnswer: false});
    }
    catch ({message}) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: LIST.META, expectAnswer: false, errorMessage: message});
    }
}

function historyBackListen ({location : {pathname}}, action) {
    if ( action !== 'POP' || pathname !== DATA_EXFILTRATION.LIST ) return;
    // NOTE reinitialize search from query string after the page path was changed
    // NOTE this event will fired before the url was changed
    setTimeout(()=>store.dispatch({type: LIST.INITIALIZE}), 0);
}

/**
 * get list of data exfiltration
 * @param {Object} options
 * @private
 */
function getList ({sortF, sortD, page, size, filter}) {
    return new Promise ( ( resolve, reject ) => {
        instanceAPI({
            method: 'post',
            url: `/systems/filter`,
            data: { filter: { name: filter }, page, size, sort: { field: sortF, order: sortD ? 'ASC' : 'DESC' } }
        }).then( ({items, pages}) => resolve({data: items, totalPages: pages}) ).catch(reject)
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams({page, size, sortF, sortD, filter}) {
    let availableFields = ['name', 'dataAssetClassification', 'businessUnit', 'numberOfRecProcessed'];
    return {
        page: is.countable(page) ? Number(page) : initial.page,
        size: is.countable(size) ? Number(size) : initial.size,
        sortF: availableFields.includes(sortF) ? sortF : initial.sortF,
        sortD: sortD ? Boolean(Number(sortD)) : initial.sortD,
        filter: is.defined(filter) ? String(filter) : initial.filter,
    };
}

/**
 * helper to setup correctness url params
 *
 * @param {Object} reducer
 * @public
 */
function updateLocation({page, size, sortF, sortD, filter}) {
    let params = {};
    // NOTE setup data to url which has difference with default data
    page !== initial.page && (params.page = page);
    size !== initial.size && (params.size = size);
    sortF !== initial.sortF && (params.sortF = sortF);
    sortD !== initial.sortD && (params.sortD = Number(Boolean(sortD)));
    filter !== initial.filter && (params.filter = filter);
    let search = queryService.format(params);
    // NOTE update url if it has difference
    if ( search !== history.location.search ) {
        history.push(history.location.pathname+search);
    }
}

/**
 * update data
 * @param {Array} data
 * @private
 */
function updateData( data ) {
    return instanceAPI({ method: 'post', url: `/systems/update-number-of-records`, data});
}
