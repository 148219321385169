
/**
 * convert object to query string with url
 *
 * @param {String} url string of url to be returned
 * @param {Object} params params to be iterated
 * @public
 * @returns {String} url with params
 */
export default (url, params) => {
    let queryString = '';
    if (!params) { 
        return queryString; 
    }
    if (!url.includes('?')) {
        queryString = '?';
    } else {
        queryString = '&';
    }
    queryString += new URLSearchParams(params).toString();
    return url + queryString;
};
