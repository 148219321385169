// outsource dependencies
import get from 'lodash/get';
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {EDIT} from '../actions';
import {history} from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import {changeField, FORM_NAME} from './index';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import {instanceAPI} from '../../../services/api.service';
import getHintsList, {VARIABLE_COSTS_EDIT_HINTS} from '../../../services/hints.service';
import separateWithCommaService from '../../../services/separate-with-comma.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.CALCULATE_COST, calculateCostSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        //NOTE separate with commas
        yield call(separateWithCommaService.separateReal, data);
        yield put({type: EDIT.DATA, data});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, initialized: true, ...params});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
    } catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* getHintsDataSaga(hintType) {
    try {
        let {language} = yield select(state => state.app);
        let hintsData = yield call(getHintsList, language, VARIABLE_COSTS_EDIT_HINTS);
        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

function* updateDataSaga({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true});
    // NOTE convert to numeric format
    yield call(separateWithCommaService.convertReal, options);
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    } catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}
function* calculateCostSaga({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true});
    // NOTE define which field is currently updated('equipmentCost' or 'personnelCost' depends on chosen costs)
    // and 'remaining' field which isn't changed now, but influence on 'total cost'
    const updatedField = options.field === 'fixedCapitalCosts' ? 'equipmentCost' : 'personnelCost',
        anotherField = updatedField === 'equipmentCost' ? 'personnelCost' : 'equipmentCost';
    // NOTE clear 'updatedField' field
    yield put(changeField(updatedField, null));
    // NOTE calculate 'updatedField' value
    let calculatedCost = 0;
    (options.value || []).forEach(item => calculatedCost += get(item, 'totalCosts'));
    if (calculatedCost) {
        yield put(changeField(updatedField, calculatedCost));
    }
    const {values} = yield select(state => state.form[FORM_NAME]);
    //NOTE   // NOTE convert to numeric format
    if (values.equipmentCost && values.equipmentCost.toString().split(',').length > 1) {
        values.equipmentCost = Number(values.equipmentCost.toString().replace(/,/g, ''));
    }
    if (values.personnelCost && values.personnelCost.toString().split(',').length > 1) {
        values.personnelCost = Number(values.personnelCost.toString().replace(/,/g, ''));
    }
    // NOTE calculate 'updatedField' value
    values.totalCosts = calculatedCost + get(values, anotherField, 0);
    //NOTE separate with commas
    yield call(separateWithCommaService.separateReal, values);
    yield put(changeField('totalCosts', values.totalCosts));
    yield put({type: EDIT.META, expectAnswer: false});
}
function* cancelSaga() {
    let {back} = yield select(state => state.variableCosts.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get data by id
 * @param {Number|String} id
 * @private
 */
function getData(id) {
    return new Promise((resolve, reject) => {
        if (id === NEW_ID) {
            return resolve({});
        }
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/budget/variable-costs/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update data
 * @param {Object} data
 * @private
 */
function updateData(data) {
    return new Promise((resolve, reject) => {
        let promise;
        if (!data.id || data.id === NEW_ID) { // CREATE
            promise = instanceAPI({method: 'post', url: `/budget/variable-costs`, data});
        } else { // UPDATE
            promise = instanceAPI({method: 'put', url: `/budget/variable-costs`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
