// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {VARIABLE_COSTS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import inputService from '../../../services/input.service';

// config
export const FORM_NAME = 'editVariableCost';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, []);
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={VARIABLE_COSTS_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                <span>
                                    <RichHintTitle
                                        update={EDIT}
                                        name={isNew ? translate('VARIABLE_COSTS$CREATE_COST') : translate('VARIABLE_COSTS$EDIT_COST')}
                                        data={findHint(hints, isNew ? 'VARIABLE_COSTS_CREATE_TITLE' : 'VARIABLE_COSTS_EDIT_TITLE')}
                                    />
                                </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"/>
                            </h2>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};

export default connect(
    state => ({expectAnswer: state.variableCosts.edit.expectAnswer, hints: state.variableCosts.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.variableCosts.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.variableCosts.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.variableCosts.edit.data,
        formValues: getFormValues(FORM_NAME)(state),
        disabled: state.variableCosts.edit.expectAnswer,
        hints: state.variableCosts.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        sameValue: options => dispatch({type: EDIT.SAME_VALUE, ...options}),
        calculateCost: options => dispatch({type: EDIT.CALCULATE_COST, ...options})
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: values => {
        let errors = {};
        // cost type
        if (!values.costType) {
            errors.costType = 'VARIABLE_COSTS$COST_TYPE_REQUIRED';
        }
        return errors;
    },
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, calculateCost, sameValue, hints}) => (
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    skipTouch
                    name="costType"
                    disabled={disabled}
                    type={ENTITY_TYPES.COST_TYPES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('VARIABLE_COSTS$COST_TYPE')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    name="system"
                    disabled={disabled}
                    type={ENTITY_TYPES.SYSTEMS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('SYSTEMS$SYSTEM')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="fixedCapitalCosts"
                    type={ENTITY_TYPES.CAPITAL_COST}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('FIXED_CAPITAL_COSTS$TITLE')} </strong>)}
                    onChange={(e, value) => calculateCost({value, field: 'fixedCapitalCosts'})}
                    getOptionLabel={e => get(e, 'technology.name') + (get(e, 'vendor') ? `/${get(e, 'vendor.name')}` : '')}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="fixedOperationalCosts"
                    type={ENTITY_TYPES.OPERATIONAL_COST}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('FIXED_OPERATIONAL_COSTS$TITLE')} </strong>)}
                    onChange={(e, value) => calculateCost({value, field: 'fixedOperationalCosts'})}
                    getOptionLabel={e => get(e, 'task.name') + (get(e, 'businessUnit') ? `/${get(e, 'businessUnit.name')}` : '')}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    //readOnly
                    component={MdInput}
                    disabled={disabled}
                    name="equipmentCost"
                    placeholder={translate('VARIABLE_COSTS$EQUIPMENT_COST')}
                    label={(<strong> {translate('VARIABLE_COSTS$EQUIPMENT_COST')} </strong>)}
                    normalize={inputService.freeze}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    readOnly
                    component={MdInput}
                    disabled={disabled}
                    name="personnelCost"
                    placeholder={translate('VARIABLE_COSTS$PERSONNEL_COST')}
                    label={(<strong> {translate('VARIABLE_COSTS$PERSONNEL_COST')} </strong>)}
                    normalize={inputService.freeze}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="offset-bottom-4">
                <Field
                    readOnly
                    name="totalCosts"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$TOTAL_COSTS')}
                    label={(<strong> {translate('GLOBALS$TOTAL_COSTS')} </strong>)}
                    normalize={inputService.freeze}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    multiline
                    name="comments"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$COMMENTS')}
                    label={(<strong> {translate('GLOBALS$COMMENTS')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_VARIABLE_COSTS_CREATE' : 'BUTTON_VARIABLE_COSTS_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"  hint={findHint(hints, 'BUTTON_VARIABLE_COSTS_RESET')}/>
                <CancelBtn onClick={cancel}  hint={findHint(hints, 'BUTTON_VARIABLE_COSTS_CANCEL')}/>
            </Col>
        </Row>
    </form>
))));
