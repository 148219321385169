// outsource dependencies
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Tab, Tabs} from '@mui/material';

// local dependencies
import {NEW_ID} from '../../../constants/spec';
import {Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {SECURITY_REQUIREMENTS} from '../../../constants/routes';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';

// nested pages
import {SECURITY_REQUIREMENTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {Outlet, useLocation, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {LIST} from '../actions';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

export default withTranslation(connect(
    state => ({
        mappingHints: state.securityRequirements.edit.mapping.hintsData,
        settingsHints: state.securityRequirements.edit.settings.hintsData,
      //  disabled: state.securityRequirements.mapping.expectAnswer || state.securityRequirements.scheme.expectAnswer,
    }),

    null
)(({disabled, match, mappingHints, settingsHints, history}) => {
    const location = useLocation();
    let {id} = useParams();
    let isNew = id === NEW_ID;
    let settingsLink = SECURITY_REQUIREMENTS.LINK_EDIT({id, tab: SECURITY_REQUIREMENTS.TABS.SETTINGS});
    let mappingLink = SECURITY_REQUIREMENTS.LINK_EDIT({id, tab: SECURITY_REQUIREMENTS.TABS.MAPPING});
    console.log(useLocation());
    return (<Container fluid>
        <Breadcrumbs breadCrumbsMap={SECURITY_REQUIREMENTS_MAP}/>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <h2 className="text-uppercase">
                    <RichHintTitle
                        update={LIST}
                        name={isNew ? translate('SECURITY_REQUIREMENTS$CREATE_REQUIREMENT') : translate('SECURITY_REQUIREMENTS$EDIT_REQUIREMENT')}
                        expectAnswer={disabled}
                        data={findHint(mappingHints, `SECURITY_REQUIREMENTS_TITLE`) || findHint(settingsHints, `SECURITY_REQUIREMENTS_TITLE`)}/>
                </h2>
            </Col>
        </Row>
        <Paper square>
            <Tabs
                value={location.pathname}
                textColor="primary"
                indicatorColor="primary"
            >
                <Link Btn={Tab} label={translate('SETTINGS$TITLE')} value={settingsLink} to={settingsLink}/>
                <Link Btn={Tab} disabled={isNew} label={translate('SECURITY_REQUIREMENTS$FRAMEWORK_MAPPING')}
                      value={mappingLink} to={mappingLink}/>
            </Tabs>
        </Paper>
        <Outlet/>
    </Container>)
}));
