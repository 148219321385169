// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

// local dependencies
import {CATEGORIES} from '../actions';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {CATEGORIES as ROUTE} from '../../../constants/routes';
import {AddBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {CATEGORIES_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class Categories extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list, hints } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={CATEGORIES_MAP} />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <RichHintTitle update={CATEGORIES}  name={`CATEGORIES$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `CATEGORIES_TITLE`)}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} placement="left" to={ROUTE.LINK_EDIT()} permission={PERMISSION.CATEGORY.CREATE} hint={findHint(hints, 'BUTTON_CATEGORIES_ADD')}/>
                        </Col>
                    </Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({ expectAnswer: state.categories.list.meta.expectAnswer,
        list: state.categories.list.list, hints: state.categories.list.meta.hintsData, }),
    dispatch => ({
        clear: () => dispatch({ type: CATEGORIES.CLEAR }),
        initialize: () => dispatch({ type: CATEGORIES.INITIALIZE }),
        clearError: () => dispatch({ type: CATEGORIES.META, errorMessage: null })
    })
)(Categories);

const ConnectedInitializer = connect(
    state => ({initialize: state.categories.list.meta.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedTable = withTranslation(connect(state => ({list: state.categories.list.list, hints: state.categories.list.meta.hintsData}), null)(
    ({ list, hints }) => (<div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">{translate('GLOBALS$NAME')}</TableCell>
                    <TableCell className="th">{translate('GLOBALS$DESCRIPTION')}</TableCell>
                    <TableCell className="th">{translate('DOMAINS$DOMAIN')}</TableCell>
                    <TableCell className="th">{translate('CATEGORIES$RISK_TYPES')}</TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{get(item, 'domain.riskDomainView.name', '')}</TableCell>
                    <TableCell>
                        <ul className="list-unstyled">
                            {item.riskTypes && item.riskTypes.map((type, i) => (
                                <li key={i} className="offset-bottom-2">{type.name}</li>
                                )
                            )}
                        </ul>
                    </TableCell>
                    <TableCell align="right">
                        <Link Btn={EditBtn} to={ROUTE.LINK_EDIT({id: item.id})} permission={PERMISSION.CATEGORY.UPDATE} hint={findHint(hints, 'BUTTON_CATEGORIES_EDIT')} />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>)
));

const ConnectedError = connect(
    state => ({message: state.categories.list.meta.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: CATEGORIES.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
