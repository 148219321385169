// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {Avatar, Button, IconButton} from '@mui/material';
import {ButtonToolbar, Dropdown} from 'react-bootstrap';

// local dependencies
import {history} from '../../store';
// import { Avatar } from '../../images/index';
import * as ROUTES from '../../constants/routes';
import {APP} from '../../actions/types';
import {translate} from '../../services/translate.service';
import {MODELS_MODAL} from '../select-model-modal/actions';

// Export
export default connect(
    state => ({
        ...state.app,
    }),
    dispatch => ({
        signOut: () => dispatch({type: APP.SIGN_OUT.REQUEST}),
        changeModel: () => dispatch({type: MODELS_MODAL.INITIALIZE}),
        changeLanguage: language => dispatch({type: APP.CHANGE_LANGUAGE, language}),
    })
)(UserMenu);


/**
 * Page for manage Recipes within application
 *
 * @param {Object} props
 * @public
 */
function UserMenu({user, language, languages, signOut, changeModel, changeLanguage}) {
    // let userInfo = `${user.fullName ? user.fullName : null} ${user.email ? user.email : null}`;
    // let userInitials = user.fullName ? user.fullName.split(/\s+/).map(w => w[0].toUpperCase()).join('') : user.email.substr(0, 2).toUpperCase();
    return (
        <div>
            <ButtonToolbar className="btn-toolbar-home">
                <Dropdown id="user-action-dropdown" className="pull-right">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <button className="btn btn-user-avatar no-events">
                                <Avatar variant="rounded" src={user && user.profilePicture && user.profilePicture.url ? user.profilePicture.url : user.avatar} alt={user.firstName} className="avatar" />
                            </button>
                            <Button variant="text" onClick={signOut} className="btn-signout">Logout</Button>
                        </div>
                        <div>
                            <IconButton><i className="fa fa-bell-o" aria-hidden="true"></i></IconButton>
                            <Dropdown.Toggle
                                id="user-action-dropdown-btn"
                                align="end"
                                as={React.forwardRef((props, ref) => (
                                    <IconButton ref={ref} {...props}><i className="fa fa-ellipsis-h" aria-hidden="true"></i></IconButton>
                                ))}
                            />
                        </div>
                    </div>
                    <Dropdown.Menu style={{border:"1px solid #ccc", padding:"12px 8px", fontSize:"16px !important"}}>
                        <Dropdown.Item disabled={true}>
                            <i className="fa fa-university" aria-hidden="true"> </i>&nbsp;{translate("ORGANIZATION$TITLE")}
                        </Dropdown.Item>
                        <LinkContainer
                            to={ROUTES.ORGANIZATION.LINK()}
                            isActive={() => (ROUTES.ORGANIZATION.REGEXP.test(history.location.pathname))}
                        >
                            <Dropdown.Item eventKey="2"> {translate('SETTINGS$ORGANIZATION')} </Dropdown.Item>
                        </LinkContainer>
                        {/*<Dropdown.Item eventKey="4" style={{marginBottom: 10}} onClick={() => changeModel()}> {translate('RISK_MODEL$CHANGE_MODEL')} </Dropdown.Item>*/}
                        <Dropdown.Item disabled={true}><i className="fa fa-user" aria-hidden="true"> </i>&nbsp;{user.fullName || user.name}</Dropdown.Item>
                        <LinkContainer
                            to={ROUTES.PROFILE.LINK()}
                            isActive={() => (ROUTES.PROFILE.REGEXP.test(history.location.pathname))}
                        >
                            <Dropdown.Item eventKey="1">
                                {translate('PROFILE$PROFILE')}
                            </Dropdown.Item>
                        </LinkContainer>
                        <LinkContainer
                            to={ROUTES.SETTINGS.LINK()}
                            isActive={() => (ROUTES.SETTINGS.REGEXP.test(history.location.pathname))}
                        >
                            <Dropdown.Item eventKey="2"> {translate('USER_MENU$ACCOUNT_SETTINGS')} </Dropdown.Item>
                        </LinkContainer>
                        <LinkContainer
                            style={{marginBottom: 10}}
                            to={ROUTES.PRIVATE_CHANGE_PASSWORD.LINK()}
                            isActive={() => (ROUTES.PRIVATE_CHANGE_PASSWORD.REGEXP.test(history.location.pathname))}
                        >
                            <Dropdown.Item eventKey="2"> {translate('AUTHORIZATION$CHANGE_PASSWORD')} </Dropdown.Item>
                        </LinkContainer>
                        {/*<Dropdown.Item disabled={true}><i className="fa fa-question-circle" aria-hidden="true"> </i>&nbsp;{translate("USER_MENU$LEARNING_CENTER")}</Dropdown.Item>*/}
                        {/*
                        <LinkContainer
                            to={ROUTES.QUICK_START.LINK()}
                            isActive={() => (ROUTES.QUICK_START.REGEXP.test(history.location.pathname))}
                        >
                            <Dropdown.Item>{translate("USER_MENU$QUICK_START")}</Dropdown.Item>
                        </LinkContainer>
                        <LinkContainer
                            to={ROUTES.KNOWLEDGE_BASE.LINK()}
                            isActive={() => (ROUTES.KNOWLEDGE_BASE.REGEXP.test(history.location.pathname))}
                        >
                            <Dropdown.Item>{translate("USER_MENU$KNOWLEDGE_BASE")}</Dropdown.Item>
                        </LinkContainer>
                        */}
                        <LinkContainer
                            to={ROUTES.ABOUT.LINK()}
                            isActive={() => (ROUTES.ABOUT.REGEXP.test(history.location.pathname))}
                        >
                            <Dropdown.Item>{translate("USER_MENU$ABOUT")}</Dropdown.Item>
                        </LinkContainer>
                        <Dropdown.Item divider="true"/>
                        <Dropdown.Item eventKey="5" onClick={signOut}>
                            <i className="fa fa-sign-out" aria-hidden="true"> </i>
                            &nbsp;{translate('AUTHORIZATION$SIGN_OUT')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonToolbar>
        </div>
    );
}
