
// outsource dependencies
import find from 'lodash/find';
import { put, call, takeEvery } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import list from '../../../constants/mock';
import { historyPush } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import { CONTROL_GUIDELINES } from '../../../constants/routes';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
}

function* initializeSaga ({type, id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        yield put({type: EDIT.DATA, ...data});
        yield put({type: EDIT.META, initialized: true});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, ...data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield call(historyPush, CONTROL_GUIDELINES.LINK());
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

/**
 * get control guideline by id
 * @param {Number|String} id
 * @private
 */
function getData( id ) {
    if ( !id || id === NEW_ID ) return {};
    return find(list, {id: Number(id)});
}

/**
 * update control guideline
 * @param {Object} data
 * @private
 */
function updateData( data ) {
    let { id } = data;
    if ( !id || id === NEW_ID ) {//CREATE
        data.id = list.length + 1;
        data.createdBy = 'Sam Smith';
        data.updatedBy = 'Sam Smith';
        list.push(data);
    } else {//UPDATE
        for ( let i = 0; i < list.length; i++ ) {
            if ( id === list[i].id ) {
                list.splice(i, 1, data);
            }
        }
    }
    return data;
}
