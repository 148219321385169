
// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE_TAB: `${prefix}UPDATE_TAB`,
        INITIALIZE: `${prefix}INITIALIZE`,
        QLIK_INITIALIZE: `${prefix}QLIK_INITIALIZE`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
        UPDATE_INPUT: `${prefix}UPDATE_INPUT`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
        HANDLE_FILTER_PANEL_STATE: `${prefix}HANDLE_FILTER_PANEL_STATE`,
    };
})('@dashboards/');
