// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from './actions';
import {history} from '../../store';
import Preloader from '../../components/preloader';
import Dashboard from '../../components/dashboard';
import {WarningBtn} from '../../components/md-button';
import ErrorMessage from '../../components/alert-error';
import {translate} from '../../services/translate.service';

class Edit extends Component {
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { disabled, data, tab } = this.props;
        return (<div style={{height: '100%', overflowY: 'auto'}}>
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={8}>
                            <h2 className="text-uppercase">
                                {data.name} <Preloader expectAnswer={disabled} type="ICON" />
                            </h2>
                        </Col>
                        <Col xs={4} className="text-right top-indent-4">
                            <WarningBtn onClick={()=>history.back()} tooltip={translate('GLOBALS$BACK')}>
                                <i className="fa fa-reply" aria-hidden="true" />
                            </WarningBtn>
                        </Col>
                    </Row>
                    <ConnectedError />
                    <Dashboard data={data} tab={tab} />
                </ConnectedInitializer>
            </Container>
        </div>);
    }
}

export default connect(
    state => ({
        tab: state.drilldown.tab,
        data: state.drilldown.data,
        disabled: state.drilldown.expectAnswer,
    }),
    dispatch => ({ clear: () => dispatch({type: EDIT.CLEAR}) })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.drilldown.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.drilldown.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
