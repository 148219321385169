// outsource dependencies
import React, {forwardRef} from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Async from 'react-select/async';
import {FormControl, FormHelperText, MenuItem, TextField} from '@mui/material';

// local dependencies
import is from '../services/is.service';
import {translate} from '../services/translate.service';
import {filters} from "./filter";

// configuration
MdSelect.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
};

/**
 * Prepared MdSelect
 *
 * @param { Object } props
 * @public
 */
export function MdSelect ({sendValueProp, input, meta, label, optionsLabel, helpText = '', skipTouch = false, valueKey = 'id', labelKey = 'name', options, simpleValue, onChange, ...attr }) {
    let message = '';
    if ( skipTouch || meta.touched ) {
        message = translate(meta.error);
    }
    simpleValue = is.function(simpleValue) ? simpleValue : v=>v;
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {!label ? '': (<FormHelperText style={{marginTop: '2px',marginLeft: 0}} component="label" htmlFor={input.name}>{label}</FormHelperText>)}
        <Select
            hideSelectedOptions={true}
            id={input.name}
            name={input.name}
            options={options}
            isDisabled={attr.disabled}
            value={simpleValue(input.value, options)}
            getOptionValue={
                (option) => {
                    return get(option, valueKey, option);
                }
            }
            getOptionLabel={option => get(option, labelKey, option)}
            { ...attr }
            error={Boolean(message)}
            optionsLabel={optionsLabel}
            components={{Control, Option}}
            filterOption={(opt, name) => !is.string(opt.label) ? opt : opt.label.toLowerCase().includes(name.toLowerCase())}
            onBlur={()=>input.onBlur()}
            onChange={e => {
                console.log(e);
                if(sendValueProp){
                    input.onChange(e ? e.value : null);
                }else{
                    input.onChange(e);
                }
                is.function(onChange) && onChange(e);
            }}/>
        {!helpText ? '': (<FormHelperText component="label" htmlFor={input.name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={input.name}>{message}</FormHelperText>)}
    </FormControl>);
}

MdAsyncSelect.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    loadOptions: PropTypes.func.isRequired,
};

/**
 * Prepared MdAsyncSelect
 *
 * @param { Object } props
 * @public
 */
export function MdAsyncSelect ({ input, meta, label, optionsLabel, helpText = '', skipTouch = false, valueKey = 'id', labelKey = 'name', onChange, ...attr }) {
    let message = '';
    if ( skipTouch || meta.touched ) {
        message = translate(meta.error);
    }
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {!label ? '': (<FormHelperText style={{marginTop: '2px',marginLeft: 0}} component="label" htmlFor={input.name}>{label}</FormHelperText>)}
        <Async
            cacheOptions={false}
            defaultOptions
            id={input.name}
            name={input.name}
            value={input.value}
            isDisabled={attr.disabled}
            getOptionValue={option => get(option, valueKey, option)}
            getOptionLabel={option => get(option, labelKey, option)}
            { ...attr }
            error={Boolean(message)}
            optionsLabel={optionsLabel}
            components={{ Control, Option }}
            filterOption={(opt, name) => !is.string(opt.label) ? opt : opt.label.toLowerCase().includes(name.toLowerCase())}
            onBlur={()=>input.onBlur()}
            onChange={e => {
                input.onChange(e);
                is.function(onChange)&&onChange(e);
            }}/>
        {!helpText ? '': (<FormHelperText component="label" htmlFor={input.name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={input.name}>{message}</FormHelperText>)}
    </FormControl>);
}

SimpleSelect.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
};

/**
 * Simple MdSelect
 *
 * @param { Object } props
 * @public
 */
export function SimpleSelect ({sendValueProp, name, value, label, optionsLabel, valueKey = 'id', labelKey = 'name', options, simpleValue, onChange, message = '', helpText='', ...attr }) {
    simpleValue = is.function(simpleValue) ? simpleValue : v=>v;
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {!label ? '': (<FormHelperText style={{marginTop: '2px', marginLeft: 0}} component="label" htmlFor={name}>{label}</FormHelperText>)}
        <Select
            hideSelectedOptions={true}
            id={name}
            name={name}
            options={options}
            isDisabled={attr.disabled}
            value={simpleValue(value, options)}
            getOptionValue={option => get(option, valueKey, option)}
            getOptionLabel={option => get(option, labelKey, option)}
            { ...attr }
            error={Boolean(message)}
            optionsLabel={optionsLabel}
            components={{Control, Option}}
            filterOption={(opt, name) => !is.string(opt.label) ? opt : opt.label.toLowerCase().includes(name.toLowerCase())}
            onChange={e =>  {
                if(sendValueProp){
                    is.function(onChange) && (e ? onChange(e.value) : onChange(e));
                }else{
                    is.function(onChange) && onChange(e);
                }
            }}
                />
        {!helpText ? '': (<FormHelperText component="label" htmlFor={name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={name}>{message}</FormHelperText>)}
    </FormControl>);
}


/**
 * Simple MdAsyncSelect
 *
 * @param { Object } props
 * @public
 */
export function SimpleAsyncSelect ({ name, value, label, optionsLabel, helpText = '', valueKey = 'id', labelKey = 'name', simpleValue, onChange, message='', ...attr }) {
    simpleValue = is.function(simpleValue) ? simpleValue : v=>v;
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {!label ? '': (<FormHelperText style={{marginTop: '2px',marginLeft: 0}} component="label" htmlFor={name}>{label}</FormHelperText>)}
        <Async
            cacheOptions={false}
            defaultOptions
            id={name}
            name={name}
            value={simpleValue(value)}
            isDisabled={attr.disabled}
            getOptionValue={option => get(option, valueKey, option)}
            getOptionLabel={option => get(option, labelKey, option)}
            { ...attr }
            error={Boolean(message)}
            optionsLabel={optionsLabel}
            components={{ Control, Option }}
            filterOption={(opt, name) => !is.string(opt.label) ? opt : opt.label.toLowerCase().includes(name.toLowerCase())}
            onChange={e => {is.function(onChange)&&onChange(e);}}/>
        {!helpText ? '': (<FormHelperText component="label" htmlFor={name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={name}>{message}</FormHelperText>)}
    </FormControl>);
}


MdAsyncCreatableSelect.propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    loadOptions: PropTypes.func.isRequired,
    onCreateOption: PropTypes.func.isRequired,
};

/**
 * Prepared AsyncCreatable select component
 *
 * @param { Object } props
 * @public
 */
export function MdAsyncCreatableSelect ({ input, meta, label, optionsLabel, helpText = '', skipTouch = false, valueKey = 'id', labelKey = 'name', onChange, prepareValue, ...attr }) {
    let message = '';
    if ( skipTouch || meta.touched ) {
        message = meta.error;
    }
    return (<FormControl autoComplete="off" error={Boolean(message)} fullWidth>
        {!label ? '': (<FormHelperText style={{marginTop: '2px',marginLeft: 0}}  component="label" htmlFor={input.name}>{label}</FormHelperText>)}
        <AsyncCreatableSelect
            cacheOptions={false}
            defaultOptions
            id={input.name}
            name={input.name}
            value={input.value}
            isDisabled={attr.disabled}
            getOptionValue={option => get(option, valueKey, option)}
            getOptionLabel={option => get(option, labelKey, option)}
            { ...attr }
            error={Boolean(message)}
            optionsLabel={optionsLabel}
            components={{ Control, Option }}
            filterOption={(opt, name) => !is.string(opt.label) ? opt : opt.label.toLowerCase().includes(name.toLowerCase())}
            onBlur={()=>input.onBlur()}
            onChange={e => {
                input.onChange(e);
                is.function(onChange) && onChange(e);
            }}/>
        {!helpText ? '': (<FormHelperText component="label" htmlFor={input.name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={input.name}>{message}</FormHelperText>)}
    </FormControl>);
}

/**
 * custom control component for react-select
 *
 * @param {Object} props
 * @private
 */
function Control ({ children, innerProps, innerRef, selectProps: { name, label, error, disabled } }) {
    return (
        <TextField
            fullWidth
            name={name}
            variant="standard"
            error={error}
            autoComplete="off"
            disabled={disabled}
            // InputLabelProps={{shrink: true}}
            InputProps={{ inputComponent, inputProps: { ref: innerRef, children, ...innerProps} }}
                />
    );
}
/**
 * custom control component for react-select
 *
 * @param {Object} props
 * @private
 */
 const inputComponent = forwardRef(({...attr }, ref) => {
    return (<div ref={ref} {...attr} style={{display: 'flex', padding: 0, height: 'auto'}} />);
})

/**
 * custom option component to provide ability format options item without braking filter logic
 *
 * @param {Object} props
 * @private
 */
function Option ({ innerRef, innerProps, isFocused, options, children, label, value, selectProps: { optionsLabel }, length='70', end='...' }) {
    return (<MenuItem
        {...innerProps}
        selected={isFocused}
        title={is.function(optionsLabel) ? optionsLabel({label, value}, options) : children }
            >
        { filters.truncate(is.function(optionsLabel) ? optionsLabel({label, value}, options) : children, {length, end})}
    </MenuItem>);
}
