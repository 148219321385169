// outsource dependencies
import {call, put, select, take, takeEvery} from 'redux-saga/effects';

// local dependencies
import {QUAL_EDIT} from '../actions';
import {history} from '../../../store';
import {instanceAPI} from '../../../services/api.service';
import {QUAL_METRICS} from "../../../constants/routes";
import getHintsList, {SCORING_METRICS_EDIT_HINTS} from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(QUAL_EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(QUAL_EDIT.GET_ITEM.REQUEST, getMetricSaga);
    yield takeEvery(QUAL_EDIT.CANCEL, cancelSaga);
    yield takeEvery(QUAL_EDIT.UPDATE_ITEM.REQUEST, updateMetricSaga);
    yield takeEvery(QUAL_EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ( {type, ...options} ) {
    yield put({type: QUAL_EDIT.CLEAR});
    yield put({type: QUAL_EDIT.META, initialized: true });
    // check if metric already exist
    if ( options.id !== 'new' ) {
        let id = Number(options.id);
        // get qualitative metric data
        yield put({type: QUAL_EDIT.GET_ITEM.REQUEST, id});
        yield take(QUAL_EDIT.GET_ITEM.FINISH);
    } else {
        yield put({type: QUAL_EDIT.META, newItem: true});
    }
    yield put({type: QUAL_EDIT.GET_HINTS_DATA.REQUEST});
    yield put({type: QUAL_EDIT.META, initialized: false});
}

function* getMetricSaga ( {type, id} ) {
    try {
        // get riskModel for api request
        let {riskModel} = yield select(state => state.app);
        // get qualitative metric
        let data = yield call(getMetric, id, riskModel);
        //set item
        yield put({type: QUAL_EDIT.ITEM, data});
    }
    catch ({message}) {
        yield put({type: QUAL_EDIT.META, errorMessage: message});
    }
    yield put({type: QUAL_EDIT.GET_ITEM.FINISH});
}

function* updateMetricSaga ( {type, ...options} ) {
    yield put({type: QUAL_EDIT.META, expectAnswer: true });
    try {
        // get data for api request
        let { riskModel } = yield select(state => state.app);
        // check if qualitative metric is new
        let { newItem } = yield select(state => state.qualMetrics.edit.meta);
        // select method of updating
        let method = newItem ? createMetric : updateMetric;
        // request for update
        let data = yield call(method, options, riskModel);
        //update item
        yield put({type: QUAL_EDIT.ITEM, data});
        yield put({type: QUAL_EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: QUAL_EDIT.CANCEL});
    }
    catch (error) {
        yield put({type: QUAL_EDIT.META, expectAnswer: false, errorMessage: error.message});
    }
    yield put({type: QUAL_EDIT.UPDATE_ITEM.FINISH});
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, SCORING_METRICS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: QUAL_EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: QUAL_EDIT.META, errorMessage: message});
    }
    yield put({type: QUAL_EDIT.GET_HINTS_DATA.FINISH});
}

/**
 * get qualitative metric by id
 * @param {Number} id - metric id
 * @param {Object} riskModel
 * @private
 */
function getMetric( id, riskModel ) {
    return instanceAPI({method: 'get', url: `/risk-models/${riskModel.id}/qual-metrics/${id}`});
}

/**
 * update qualitative metric
 * @param {Object} metric
 * @param {Object} riskModel
 * @private
 */
function updateMetric( metric, riskModel ) {
    return instanceAPI({
        method: 'put',
        url: `/risk-models/${riskModel.id}/qual-metrics`,
        data: metric
    });
}

/**
 * create qualitative metric
 * @param {Object} metric
 * @param {Object} riskModel
 * @private
 */
function createMetric( metric, riskModel ) {
    return instanceAPI({
        method: 'post',
        url: `/risk-models/${riskModel.id}/qual-metrics`,
        data: metric
    });
}

function* cancelSaga () {
    // NOTE go back
    yield call(history.push, QUAL_METRICS.LINK());
}
