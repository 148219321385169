
// outsource dependencies
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { SETTINGS } from '../actions';
import { changeField } from './index';
import { history } from '../../../../store';
import { NEW_ID } from '../../../../constants/spec';
import * as ROUTES from '../../../../constants/routes';
import queryService from '../../../../services/query.service';
import { instanceAPI } from '../../../../services/api.service';
import getHintsList, { SECURITY_REQUIREMENTS_EDIT_HINTS } from '../../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(SETTINGS.CANCEL, cancelSaga);
    yield takeEvery(SETTINGS.UPDATE, updateDataSaga);
    yield takeEvery(SETTINGS.INITIALIZE, initializeSaga);
    yield takeEvery(SETTINGS.CHANGE_CONTROL_FAMILY, changeControlFamilySaga);
    yield takeEvery(SETTINGS.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({id}) {
    yield put({type: SETTINGS.CLEAR});
    try {
        let data = yield call(getData, id);
        // NOTE get initial security control name list
        if (data.securityControlFamily) {
            yield put({type: SETTINGS.CHANGE_CONTROL_FAMILY, family: data.securityControlFamily});
        }
        yield put({type: SETTINGS.DATA, data});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: SETTINGS.META, initialized: true, ...params});
        yield put({type: SETTINGS.GET_HINTS_DATA.REQUEST});
    }
    catch ({message}) {
        yield put({type: SETTINGS.META, errorMessage: message, initialized: true});
    }
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, SECURITY_REQUIREMENTS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: SETTINGS.META, hintsData});
    } catch ( {message} ) {
        yield put({type: SETTINGS.META, errorMessage: message});
    }
    yield put({type: SETTINGS.GET_HINTS_DATA.FINISH});
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: SETTINGS.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: SETTINGS.DATA, data});
        yield put({type: SETTINGS.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: SETTINGS.CANCEL});
    }
    catch ({message}) {
        yield put({type: SETTINGS.META, expectAnswer: false, errorMessage: message});
    }
}

function* changeControlFamilySaga ({family}) {
    yield put({type: SETTINGS.META, expectAnswer: true });
    yield put(changeField('securityControlName', null));
    try {
        let securityControlNames = yield call(getSecurityControlNames, family);
        yield put({type: SETTINGS.META, securityControlNames, expectAnswer: false});
    }
    catch ({message}) {
        yield put({type: SETTINGS.META, expectAnswer: false, errorMessage: message});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.securityRequirements.edit.settings);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get security requirement by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/security-requirements/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update security requirement data
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: '/security-requirements', data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: '/security-requirements', data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}


/**
 * get security control names list
 * @param {Object} family
 * @private
 */
function getSecurityControlNames ( family={} ) {
    return instanceAPI({method: 'post', url: 'security-control-names/filter', data: { size: 6, filter: { parentId: family.id || null } }})
        .then(({items}) => items);
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
