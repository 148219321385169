// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {AppBar, Paper, Tab, Table, TableBody, TableCell, TableRow, Tabs} from '@mui/material';

// local dependencies
import {EDIT} from './actions';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {DASHBOARD_ITEMS_TYPE} from '../../constants/spec';

// config

class Dashboard extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { disabled, data } = this.props;
        let sections = get(data, 'sections', []);
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <h2 className="text-center text-uppercase">
                                {data.name} <Preloader expectAnswer={disabled} type="ICON"> </Preloader>
                            </h2>
                        </Col>
                    </Row>
                    <ConnectedError />
                    { sections.length ? (
                        <ConnectedTabs />
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> There is no data </h3>
                    )}
            </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        data: state.adminDashboard.data,
        disabled: state.adminDashboard.expectAnswer
    }),
    dispatch => ({
        clear: () => dispatch({ type: EDIT.CLEAR }),
        initialize: () => dispatch({ type: EDIT.INITIALIZE }),
    })
)(Dashboard);

const ConnectedInitializer = connect(
    state => ({initialize: state.adminDashboard.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.adminDashboard.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: EDIT.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

/**
 * tabs component
 *
 * @param {Object} props
 * @private
 */
const ConnectedTabs = connect(
    state => ({
        data: state.adminDashboard.data,
        currentTab: state.adminDashboard.currentTab,
    }),
    dispatch => ({ changeTab: currentTab => dispatch({ type: EDIT.META, currentTab}) })
)( ({ data, currentTab, changeTab }) => {
    let sections = get(data, 'sections', []);
    let dashboardItems = get(sections[currentTab-1], 'dashboardItems', []);
    return (<Paper>
        <AppBar position="static">
            <Tabs
                centered={true}
                value={currentTab}
                indicatorColor="primary"
                onChange={(e, tab) => changeTab(tab)}
                    >
                {sections.map((item, key) => (
                    <Tab key={key} value={key + 1} label={item.name}/>
                ))}
            </Tabs>
        </AppBar>
        <div className="indent-5">
            { dashboardItems.map( (item, key) => {
                switch ( item.dashboardItemType ) {
                    default:
                        return null;
                    case DASHBOARD_ITEMS_TYPE.TABLE:
                        return <DashboardTable key={key} data={item}/>
                    case DASHBOARD_ITEMS_TYPE.BARCHART:
                        return <DashboardBarchart key={key} data={item}/>
            }})}
        </div>
    </Paper>)}
);


/**
 * dashboard table component
 *
 * @param {Object} props
 * @private
 */
const DashboardTable = ( ({ data }) => (<div>
    <Row className="offset-bottom-4">
        <Col xs={12}>
            <h3 className="text-center text-uppercase"> {data.name} </h3>
        </Col>
    </Row>
    <Row className="offset-bottom-4">
        <Col xs={12} lg={{span:10,offset:1}}>
            <Paper>
                <Table className="md-table" padding="checkbox">
                    <TableBody>
                        { data.gridItems.map( (row, key) => (
                            <TableRow key={key}>
                                { row.map((cell, i) => (
                                    !cell ? null : ( <Cell key={i} {...cell} /> )
                                )) }
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </Paper>
        </Col>
    </Row>
</div>));


/**
 * dashboard barchart component
 *
 * @param {Object} props
 * @private
 */
const DashboardBarchart = ( ({ data }) => {
    let { xaxis, yaxis, gridItems=[] } = data;
    let prepared = gridItems.map(item => ({ [xaxis]: get(item, '[0].value', ''), [yaxis]: get(item, '[1].value', '') }));
    return (<div>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <h3 className="text-center text-uppercase"> {data.name} </h3>
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} lg={{span:8,offset:2}}>
                <Paper className="indent-5">
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            data={prepared}
                            barCategoryGap="30%"
                            margin={{ top: 10, bottom: 20, left: 5, right: 30 }}
                                >
                            <Tooltip />
                            <CartesianGrid />
                            <Bar dataKey={yaxis} fill="#213c60" />
                            <XAxis
                                angle={-8}
                                interval={0}
                                tick={{dy: 5}}
                                dataKey={xaxis}
                                    />
                            <YAxis
                                dataKey={yaxis}
                                label={{ value: yaxis, angle: -90, position: 'insideLeft' }}
                                    />
                        </BarChart>
                    </ResponsiveContainer>
                </Paper>
            </Col>
        </Row>
    </div>)
});

/**
 * dashboard table cell component
 *
 * @param {Object} props
 * @private
 */
const Cell = ({ value, symbol, rowSpan, colSpan, header, color='black', backgroundColor='white', textAlign='left' }) => {
    return (
        <TableCell
            rowSpan={rowSpan}
            colSpan={colSpan}
            component={header ? 'th' : 'td'}
            style={{
                color,
                textAlign,
                backgroundColor,
                border: '1px solid #e0e0e0',
                fontWeight: header ? 'bold' : 'normal',
            }}>
            {value} &nbsp; {symbol}
        </TableCell>
    )
};
