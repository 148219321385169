
// outsource dependencies
import moment from 'moment';
import { get, isEqual, reject, every } from 'lodash';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { DIFF_MODE } from './index';
import { history } from '../../../store';
import { config } from '../../../constants';
import { is } from '../../../services/is.service';
import * as ROUTES from '../../../constants/routes';
import { filters } from '../../../components/filter';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import getHintsList, { AUDIT_LOGS_EDIT_HINTS } from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.CHANGE_MODE, changeModeSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, AUDIT_LOGS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}
function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        let diff = buildDiff(JSON.parse(data.oldValue), JSON.parse(data.newValue));
        yield put({type: EDIT.DATA, data});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, initialized: true, ...params, diff, list: diff});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* changeModeSaga ({diffMode}) {
    yield put({type: EDIT.META, expectAnswer: true, diffMode});
    let { diff } = yield select(state => state.auditLogs.edit);
    if (diffMode === DIFF_MODE.ALL) {
        yield put({type: EDIT.META, list: diff});
    }
    if (diffMode === DIFF_MODE.MODIFIED) {
        let filtered = reject(diff, {status: 'same'});
        yield put({type: EDIT.META, list: filtered});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function buildDiff (oldValue, newValue) {
    let res = [];
    oldValue = oldValue || {};
    newValue = newValue || {};
    // NOTE define entity variant which contain all properties (not empty)
    let max = Object.keys(oldValue).length > Object.keys(newValue).length ? oldValue : newValue;
    delete max['id'];
    delete max['metadata'];
    delete max['answerWeights'];
    // NOTE pass through all properties
    for (let key in max ) {
        if (is.defined(oldValue[key]) || is.defined(newValue[key]) ) {
            // object
            if (is.object(oldValue[key]) || is.object(newValue[key])) {
                let oldLabel = getPrimitiveLabel(oldValue[key], key);
                let newLabel = getPrimitiveLabel(newValue[key], key);
                let row = formatPrimitiveDiff(oldLabel, newLabel, key);
                res.push(row);
            } else if (is.array(oldValue[key]) || is.array(newValue[key])) {
                // array
                let oldArr = oldValue[key] ? oldValue[key] : [];
                let newArr = newValue[key] ? newValue[key] : [];
                let allValues = [], tmp = [];
                // NOTE build one common allValues array which contains newValue and oldValue properties for each array item
                // like answers=[{oldValue: 'answer 1 old value', newValue: 'answer 1 new value'}]
                // NOTE add all items from oldValue array and items which present in newValue and oldValue arrays to common diff array
                for (let i = 0; i < oldArr.length; i++) {
                    let item = oldArr[i];
                    let row = {oldValue: item, newValue: null};
                    for (let j = 0; j < newArr.length; j++) {
                        let newItem = newArr[j];
                        if(isEqual(item, newItem)) {
                            row.newValue = newItem;
                        }
                    }
                    allValues.push(row);
                }
                // NOTE add all items which were added to newValue array and weren't in oldValue array to common diff array
                for (let i = 0; i < newArr.length; i++) {
                    let item = newArr[i];
                    if (every(allValues, value => !isEqual(value.newValue, item))) {
                        tmp.push({oldValue: null, newValue: item});
                    }
                }
                allValues = allValues.concat(tmp);
                tmp = [];
                // NOTE format diff row for each allValues array item
                for (let i = 0; i < allValues.length; i++) {
                    let oldLabel = getPrimitiveLabel(allValues[i].oldValue, key);
                    let newLabel = getPrimitiveLabel(allValues[i].newValue, key);
                    let row = formatPrimitiveDiff(oldLabel, newLabel, key);
                    row.property = `Item ${i + 1}`;
                    tmp.push(row);
                }
                // NOTE add delimiters for array
                if (allValues.length) {
                    let status = every(tmp, value => value.status === "same") ? "same" : "modified";
                    tmp.unshift({delimiter: true, property: filters.humanize(key), status});
                    tmp.push({delimiter: true, status});
                }
                res = res.concat(tmp);
            } else {
                // NOTE primitive type handling
                let row = formatPrimitiveDiff(oldValue[key], newValue[key], key);
                res.push(row);
            }
        }
    }
    return res;
}

function* cancelSaga () {
    let { back } = yield select(state => state.auditLogs.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get data by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return instanceAPI({method: 'get', url: `/audit-logs/${id}`});
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}

/**
 * helper to determine simple label for complex entity
 *
 * @param {*} item
 * @param {String} propertyName
 * @return {*}
 * @public
 */
function getPrimitiveLabel (item, propertyName) {
    // NOTE primitive value
    if (!is.object(item)) {
        return item;
    }
    switch (propertyName) {
        default:
            return get(item, 'name', 'Unknown property');
        case 'user':
        case 'owner':
        case 'infosecFocalPerson':
            return get(item, 'fullName');
        case 'domain':
            return get(item, 'riskDomainView.name', '');
        case 'question':
            return get(item, 'question');
        case 'answer':
            return get(item, 'answer');
        case 'answers':
            return get(item, 'answer') + ' ( weight: ' +  get(item, 'answerWeight.value') + ' )';
        case 'metricFormulaItems':
            return item.isOperation
                ? 'operation " ' + getOperationSymbol(get(item, 'operation')) + ' " '
                : 'variable " ' + filters.humanize(get(item, 'variableType.name')) + ' " ' + (item.value ? ' ( value: ' + item.value + ' ) ' : '');
        case 'branchingLogic':
            return get(item, 'question.question') + ' ( answer: ' +  get(item, 'answer.answer') + ' )';
    }
}

/**
 * handle diff row for primitive properties
 *
 * @param {String|Number|Boolean} oldValue
 * @param {String|Number|Boolean} newValue
 * @param {String} propertyName
 * @return {Object}
 * @public
 */
function formatPrimitiveDiff (oldValue, newValue, propertyName) {
    // NOTE check for Boolean values
    oldValue = is.boolean(oldValue)? String(oldValue) : oldValue;
    newValue = is.boolean(newValue)? String(newValue) : newValue;
    // NOTE check for Date values
    oldValue = moment(oldValue, "YYYY-MM-DDTHH:mm:ss.SSSZZ", true).isValid() ? moment(oldValue).format(config.clientTimeFormat+', h:mm:ss a') : oldValue;
    newValue = moment(newValue, "YYYY-MM-DDTHH:mm:ss.SSSZZ", true).isValid() ? moment(newValue).format(config.clientTimeFormat+', h:mm:ss a') : newValue;
    // NOTE format diff row
    let row = {property: filters.humanize(propertyName), oldValue, newValue};
    // NOTE define diff status
    row.status = 'same';
    if (!isEqual(oldValue, newValue)) {
        row.status = 'modified';
    }
    if (!oldValue) {
        row.status = 'added';
    }
    if (!newValue) {
        row.status = 'removed';
    }
    if (!oldValue && !newValue) {
        row.status = 'same';
    }
    return row;
}


function getOperationSymbol(operation) {
    switch (operation) {
        default:
            console.error(`Unknown operation ${operation}`);
            return '';
        case 'OPEN_BRACKET': return '(';
        case 'CLOSE_BRACKET': return ')';
        case 'MULTIPLY': return '*';
        case 'DIVIDE': return '/';
        case 'MINUS': return '-';
        case 'PLUS': return '+';
    }
}
