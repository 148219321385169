// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {Dropdown, OverlayTrigger, Tooltip, DropdownButton, ButtonToolbar} from 'react-bootstrap';

// local dependencies
import {history} from '../store';
import {Avatar, selectImageSrc} from '../images/index';
import * as ROUTES from '../constants/routes';
import {APP} from '../actions/types';
import {translate} from '../services/translate.service';
import {MODELS_MODAL} from './select-model-modal/actions';
import {PERMISSION} from "../constants/spec";
import UserPermissionModel from "../models/user-permission.model";

// Export
export default connect(
    state => ({
        ...state.app,
    }),
    dispatch => ({
        signOut: () => dispatch({type: APP.SIGN_OUT.REQUEST}),
        changeModel: () => dispatch({type: MODELS_MODAL.INITIALIZE}),
        changeLanguage: language => dispatch({type: APP.CHANGE_LANGUAGE, language}),
    })
)(UserMenu);


/**
 * Page for manage Recipes within application
 *
 * @param {Object} props
 * @public
 */
function UserMenu({user, language, languages, signOut, changeModel, changeLanguage}) {
    let userInfo = `${user.fullName ? user.fullName : null} ${user.email ? user.email : null}`;
    let userInitials = user.fullName && user.fullName.trim() ? user.fullName.trim().split(/\s+/).map(w => w[0].toUpperCase()).join('') : user.email.substr(0, 2).toUpperCase();

    let organizationInfo = {};
    if (user && user.organization) {
        organizationInfo.name = user.organization.name;
        organizationInfo.logo = user.organization.logoDocument && user.organization.logoDocument.url ? user.organization.logoDocument.url : "";
    }

    return (
        <div>
            <ButtonToolbar className="pull-right" style={{fontSize:"2rem"}}>
                <OverlayTrigger
                    trigger={["hover","focus"]}
                    placement="bottom"
                    overlay={<Tooltip id="tooltip">{userInfo}</Tooltip>}>
                    <Dropdown id="user-action-dropdown" className="pull-right">
                        <button className="btn btn-user-avatar no-events" >
                            <strong>{userInitials}</strong>&nbsp;&nbsp;
                            <Avatar width="35" height="35" src={user && user.profilePicture && user.profilePicture.url ? user.profilePicture.url : user.avatar} alt={user.firstName}/>
                        </button>
                        <Dropdown.Toggle variant="default"  style={{border:"1px solid #ccc", padding:"12px 8px", fontSize:"1.2rem"}} id="user-action-dropdown-btn" />
                        <Dropdown.Menu style={{border:"1px solid #ccc", padding:"12px 8px", fontSize:"16px !important"}}>
                            <Dropdown.Item disabled={true}>
                                <i className="fa fa-university" aria-hidden="true"> </i>&nbsp;{translate("ORGANIZATION$TITLE")}
                            </Dropdown.Item>
                            <LinkContainer
                                to={ROUTES.ORGANIZATION.LINK()}
                                isActive={() => (ROUTES.ORGANIZATION.REGEXP.test(history.location.pathname))}
                            >
                                <Dropdown.Item eventKey="2"> {translate('SETTINGS$ORGANIZATION')} </Dropdown.Item>
                            </LinkContainer>
                            {
                                UserPermissionModel.checkUserPermission(user, PERMISSION.RISK_MODEL.UPDATE) ?
                                    (<Dropdown.Item eventKey="4" style={{marginBottom: 10}} onClick={() => changeModel()}> {translate('RISK_MODEL$CHANGE_MODEL')} </Dropdown.Item>)
                                    : ""
                            }
                            <Dropdown.Item disabled={true}><i className="fa fa-user" aria-hidden="true"> </i>&nbsp;{user.fullName || user.name}</Dropdown.Item>
                            <LinkContainer
                                to={ROUTES.PROFILE.LINK()}
                                isActive={() => (ROUTES.PROFILE.REGEXP.test(history.location.pathname))}
                            >
                                <Dropdown.Item eventKey="1">
                                    {translate('PROFILE$PROFILE')}
                                </Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer
                                to={ROUTES.SETTINGS.LINK()}
                                isActive={() => (ROUTES.SETTINGS.REGEXP.test(history.location.pathname))}
                            >
                                <Dropdown.Item eventKey="2"> {translate('USER_MENU$ACCOUNT_SETTINGS')} </Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer
                                style={{marginBottom: 10}}
                                to={ROUTES.PRIVATE_CHANGE_PASSWORD.LINK()}
                                isActive={() => (ROUTES.PRIVATE_CHANGE_PASSWORD.REGEXP.test(history.location.pathname))}
                            >
                                <Dropdown.Item eventKey="2"> {translate('AUTHORIZATION$CHANGE_PASSWORD')} </Dropdown.Item>
                            </LinkContainer>
                            {/*<Dropdown.Item disabled={true}><i className="fa fa-question-circle" aria-hidden="true"> </i>&nbsp;{translate("USER_MENU$LEARNING_CENTER")}</Dropdown.Item>*/}
                            {/*
                            <LinkContainer
                                to={ROUTES.QUICK_START.LINK()}
                                isActive={() => (ROUTES.QUICK_START.REGEXP.test(history.location.pathname))}
                            >
                                <Dropdown.Item>{translate("USER_MENU$QUICK_START")}</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer
                                to={ROUTES.KNOWLEDGE_BASE.LINK()}
                                isActive={() => (ROUTES.KNOWLEDGE_BASE.REGEXP.test(history.location.pathname))}
                            >
                                <Dropdown.Item>{translate("USER_MENU$KNOWLEDGE_BASE")}</Dropdown.Item>
                            </LinkContainer>
                            */}
                            <LinkContainer
                                to={ROUTES.ABOUT.LINK()}
                                isActive={() => (ROUTES.ABOUT.REGEXP.test(history.location.pathname))}
                            >
                                <Dropdown.Item>{translate("USER_MENU$ABOUT")}</Dropdown.Item>
                            </LinkContainer>
                            <Dropdown.Item divider="true"/>
                            <Dropdown.Item eventKey="5" onClick={signOut}>
                                <i className="fa fa-sign-out" aria-hidden="true"> </i>
                                &nbsp;{translate('AUTHORIZATION$SIGN_OUT')}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </OverlayTrigger>
            </ButtonToolbar>
            {languages.length > 1 && (
                <ButtonToolbar className="pull-right language-dropdown" style={{margin: 5}}>
                    <OverlayTrigger trigger={["hover","focus"]} placement="bottom"
                                    overlay={<Tooltip id="tooltip">{translate('GLOBALS$CHANGE_LANGUAGE')}</Tooltip>}>
                        <DropdownButton
                            variant="default"
                            id="language-dropdown"
                            className="border rounded"
                            title={<img alt={language} src={selectImageSrc(language)} height={20}/>}
                        >
                            {languages.map((item, key) => (
                                <Dropdown.Item
                                    key={key}
                                    eventKey={key}
                                    onClick={() => changeLanguage(item)}
                                >
                                    <img alt={item.name} src={selectImageSrc(item.code)} height={25}/>
                                    {` - ${item.name}`}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </OverlayTrigger>
                </ButtonToolbar>
            )}
            {organizationInfo.logo ? (
                <ButtonToolbar className="pull-right" style={{fontSize: "2rem", paddingRight: 30, paddingTop: 5}}>
                    <img height="40" src={organizationInfo.logo} alt={organizationInfo.name} />
                </ButtonToolbar>
            ) : ""}
            {/*<h4 className="text-uppercase offset-top-3 offset-right-2 text-highlighted pull-right">*/}
                {/*<strong>{riskModel.name}</strong>*/}
            {/*</h4>*/}
        </div>
    );
}
