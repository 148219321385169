// outsource dependencies
import React from 'react';
import {LinkContainer} from 'react-router-bootstrap';

import {Button, IconButton, Tooltip} from '@mui/material';
import {
    Add,
    AssignmentInd,
    Clear,
    Delete,
    Done,
    Edit,
    EventAvailable,
    FileCopyOutlined,
    Filter1Outlined,
    Filter2Outlined,
    HelpOutlineOutlined,
    InfoOutlined,
    LayersClear,
    LibraryBooksOutlined,
    MoreHoriz,
    Public,
    QuestionAnswerOutlined,
    Refresh,
    Save
} from '@mui/icons-material';

// local dependencies
import Theme from './theme';
import {history} from '../store';
import {RichHintBtn} from "./hints/hints";
import PermissionAccess from './permission-access';
import {translate, withTranslation} from '../services/translate.service';

/**
 * wrapped material buttons with base btn logic within application
 * @example
 <MdButton
 size="large"
 color="secondary"
 variant="text
 />

 // with custom styles
 <MdButton className="md-btn md-btn-danger" />
 * @param {Object} props
 * @public
 */
export default function ({size = 'medium', color = 'primary', hint={}, variant = 'contained', ...attr}) {
    const isRich = hint.hintType === 'Rich';
    return isRich ? (<RichHintBtn data={hint} options={attr} color={color}/>) : (<ProtectedBtn size={size} color={color} variant={variant} {...attr} />)
}

/**
 * Material ui button with translation
 *
 * @param {Object} props
 * @public
 */
export const TranslatedBtn = withTranslation(Button);
export const TranslatedIconBtn = withTranslation(IconButton);

/**
 * Material ui button with permission check
 * @example
 <Link Btn={AddBtn} to={SUBSIDIARIES.LINK_EDIT()} permission={PERMISSION.SUBSIDIARY.CREATE} />
 <DelBtn permission={PERMISSION.SUBSIDIARY.DELETE} onClick={() => deleteItem(item.id)} />
 * @param {Object} props
 * @public
 */
export const ProtectedBtn = ({permission, ...options}) => (
    <PermissionAccess permission={permission}> <TranslatedBtn {...options} /> </PermissionAccess>
);

export const ProtectedIconBtn = ({permission, ...options}) => (
    <PermissionAccess permission={permission}> <TranslatedIconBtn {...options} /> </PermissionAccess>
);

/**
 * Wrapper any item in link container if link path is present
 *
 * @param {Object} props
 * @public
 */
export const Link = ({to, disabled, Btn, ...attr}) => (
    <LinkContainer to={to} disabled={disabled} className="link-unstyled">
        <Btn disabled={disabled} {...attr} />
    </LinkContainer>
);

/**
 * Prepared primary btn
 *
 * @param {Object} props
 * @public
 */
export const PrimaryBtn = ({tooltip = 'tooltip', theme = 'ROOT', hint = {}, ...attr}) => {
    const isRich = hint.hintType === 'Rich';
    return (
        <Theme name={theme}>
            {isRich ?
                (<RichHintBtn data={hint} options={attr} color="primary"/>)
                :
                (<Tooltip title={hint.body || tooltip} placement="top" enterDelay={300}><span>
                    <ProtectedBtn aria-label={tooltip} variant="contained" color="primary" {...attr} />
                </span></Tooltip>)
            }
</Theme>)};

/**
 * Prepared warning btn
 *
 * @param {Object} props
 * @public
 */
export const WarningBtn = ({tooltip = 'tooltip', theme = 'ROOT', hint = {}, ...attr}) => {
    const isRich = hint.hintType === 'Rich';
    return (
        <Theme name={theme}>
            {isRich ?
                (<RichHintBtn data={hint} options={attr} color="secondary"/>)
                :
                (<Tooltip title={hint.body || tooltip} placement="top" enterDelay={300}><span>
                    <ProtectedBtn aria-label={tooltip} variant="contained" color="secondary" {...attr} />
                </span></Tooltip>)
            }
</Theme>)};

/**
 * Prepared info btn
 *
 * @param {Object} props
 * @public
 */
export const SuccessBtn = ({tooltip = 'tooltip', theme = 'ALT',  hint = {}, ...attr}) => {
    const isRich = hint.hintType === 'Rich';
    return (
        <Theme name={theme}>
            {isRich ?
                (<RichHintBtn data={hint} options={attr} color="primary"/>)
                :
                (<Tooltip title={hint.body || tooltip} placement="top" enterDelay={300}><span>
                    <ProtectedBtn aria-label={tooltip} variant="contained" color="primary" {...attr} />
                </span></Tooltip>)
            }
        </Theme>)
};

/**
 * Prepared danger btn
 *
 * @param {Object} props
 * @public
 */
export const DangerBtn = ({tooltip = 'tooltip', theme = 'ALT',  hint = {}, ...attr}) => {
    const isRich = hint.hintType === 'Rich';
    return (
        <Theme name={theme}>
            {isRich ?
                (<RichHintBtn data={hint} options={attr} color="secondary"/>)
                :
                (<Tooltip title={hint.body || tooltip} placement="top" enterDelay={300}><span>
                    <ProtectedBtn aria-label={tooltip} variant="contained" color="secondary" {...attr} />
                </span></Tooltip>)
            }
    </Theme>)
};



// export const AddBtn = ( props ) => (<SuccessBtn {...props} tooltip={translate('GLOBALS$ADD')}> <Add fontSize="small" />&nbsp;{translate('GLOBALS$ADD')} </SuccessBtn>);
// export const CloneBtn = ({ isNew = true, ...attr }) => (<SuccessBtn {...attr} tooltip={translate('GLOBALS$CLONE')}>&nbsp;{translate('GLOBALS$CLONE')}</SuccessBtn>);
// export const EditBtn = ( props ) => (<PrimaryBtn {...props} tooltip={translate('GLOBALS$EDIT')}> <Edit fontSize="small" />&nbsp;{translate('GLOBALS$EDIT')} </PrimaryBtn>);
// export const DelBtn = ( props ) => (<DangerBtn {...props} tooltip={translate('GLOBALS$DELETE')}> <Delete fontSize="small" />&nbsp;{translate('GLOBALS$DELETE')} </DangerBtn>);
// export const CancelBtn = ( props ) => (<DangerBtn {...props} tooltip={translate('GLOBALS$CANCEL')}> <Clear fontSize="small" />&nbsp;{translate('GLOBALS$CANCEL')} </DangerBtn>);
// export const ResetBtn = ( props ) => (<WarningBtn {...props} tooltip={translate('GLOBALS$RESET')}> <LayersClear fontSize="small" />&nbsp;{translate('GLOBALS$RESET')} </WarningBtn>);
// export const SubmitBtn = ({ isNew = false, ...attr }) => (<PrimaryBtn {...attr} type="submit" tooltip={isNew ? translate('GLOBALS$CREATE') : translate('GLOBALS$SAVE')}>
//     {isNew ? <Add fontSize="small" /> : <Save fontSize="small" />}&nbsp;{isNew ? translate('GLOBALS$CREATE') : translate('GLOBALS$SAVE')}
// </PrimaryBtn>);

export const AddBtn = (props) => (<SuccessBtn {...props} tooltip={props.tooltip ? props.tooltip.body : translate('GLOBALS$ADD')}><Add/></SuccessBtn>);
export const CloneBtn = ({isNew = true, ...attr}) => (<SuccessBtn {...attr} tooltip={translate('GLOBALS$CLONE')}><FileCopyOutlined fontSize="small"/></SuccessBtn>);
export const ReassignBtn = (props) => (<SuccessBtn {...props} tooltip={translate('REASSIGN$REASSIGN')}><AssignmentInd fontSize="small"/></SuccessBtn>);
export const EditBtn = (props) => (<PrimaryBtn {...props} tooltip={translate('GLOBALS$EDIT')}> <Edit fontSize="small"/></PrimaryBtn>);
export const EditLnkBtn = (props) => (<ProtectedIconBtn {...props} tooltip={translate('GLOBALS$EDIT')}> <Edit fontSize="small" /></ProtectedIconBtn>);
export const DetailsBtn = (props) => (<PrimaryBtn {...props} tooltip={translate('GLOBALS$DETAILS')}> <InfoOutlined fontSize="small"/></PrimaryBtn>);
export const GeoRecordsBtn = (props) => (<PrimaryBtn {...props} tooltip={translate('SYSTEMS$GEO_RECORDS_PROCESSED')}> <Public fontSize="small"/></PrimaryBtn>);
export const DrilldownBtn = (props) => (<PrimaryBtn {...props} tooltip={translate('GLOBALS$DRILLDOWN')}> <MoreHoriz fontSize="small"/></PrimaryBtn>);
export const LogBtn = (props) => (<WarningBtn {...props} tooltip={translate('GLOBALS$LOG')}> <LibraryBooksOutlined fontSize="small"/></WarningBtn>);
export const ApprovalBtn = (props) => (<SuccessBtn {...props} tooltip={translate('POLICY_MANAGEMENT$APPROVAL')}> <Done fontSize="small"/></SuccessBtn>);
export const AnnualReviewBtn = (props) => (<WarningBtn {...props} tooltip={translate('POLICY_MANAGEMENT$ANNUAL_REVIEW')}> <EventAvailable fontSize="small"/></WarningBtn>);
export const ScoringQuestionsBtn = (props) => (<WarningBtn {...props} tooltip={translate('SCORING_QUESTIONS$TITLE')}> <QuestionAnswerOutlined fontSize="small"/></WarningBtn>);
export const FirstLvlQuestionsBtn = (props) => (<WarningBtn {...props} tooltip={translate('VENDORS$SCORING_QUESTIONS')}> <Filter1Outlined fontSize="small" /></WarningBtn>);
export const SecondLvlQuestionsBtn = (props) => (<WarningBtn {...props} tooltip={translate('VENDORS$INTERNAL_QUESTIONS')}> <Filter2Outlined fontSize="small" /></WarningBtn>);
export const DelBtn = (props) => (<DangerBtn {...props} tooltip={props.tooltip ? props.tooltip.body : translate('GLOBALS$DELETE')}> <Delete fontSize="small"/></DangerBtn>);
export const CancelBtn = (props) => (<DangerBtn {...props} tooltip={translate('GLOBALS$CANCEL')}> <Clear fontSize="small"/></DangerBtn>);
export const ResetBtn = (props) => (<WarningBtn className="md-btn md-btn-success" {...props} tooltip={translate('GLOBALS$RESET')}> <LayersClear fontSize="small"/></WarningBtn>);
export const SubmitBtn = ({isNew = false, ...attr}) => (<PrimaryBtn {...attr} type="submit" tooltip={isNew ? translate('GLOBALS$ADD') : translate('GLOBALS$SAVE')}>
    {isNew ? translate('GLOBALS$ADD') : <Save fontSize="small"/>}</PrimaryBtn>);

/**
 * Prepared add icon button
 *
 * @param {Object} props
 * @public
 */
export const AddIconBtn = ({tooltip = translate('GLOBALS$ADD'), ...attr}) => (
    <Tooltip title={tooltip} placement="top" enterDelay={300}><span>
        <ProtectedIconBtn aria-label={tooltip} {...attr}>
            <Add fontSize="small"/>
        </ProtectedIconBtn>
    </span></Tooltip>
);

/**
 * Prepared refresh(rebuild) icon button
 *
 * @param {Object} props
 * @public
 */
export const RefreshIconBtn = ({tooltip = translate('GLOBALS$REBUILD'), ...attr}) => (
    <Tooltip title={tooltip} placement="top" enterDelay={300}><span>
        <ProtectedIconBtn aria-label={tooltip} {...attr}>
            <Refresh fontSize="8px"/>
        </ProtectedIconBtn>
    </span></Tooltip>
);

/**
 * Prepared info icon button
 *
 * @param {Object} props
 * @public
 */
export const InfoIconBtn = ({tooltip = translate('Info'), ...attr}) => (
    <Tooltip title={tooltip} placement="top" enterDelay={200}><span>
        <ProtectedIconBtn aria-label={tooltip} {...attr}>
            <HelpOutlineOutlined fontSize="small"/>
        </ProtectedIconBtn>
    </span></Tooltip>
);

/**
 * Prepared edit icon button
 *
 * @param {Object} props
 * @public
 */
export const EditIconBtn = ({tooltip = translate('GLOBALS$EDIT'), ...attr}) => (
    <Tooltip title={tooltip} placement="top" enterDelay={300}><span>
        <ProtectedIconBtn aria-label={tooltip} {...attr}>
            <Edit fontSize="small"/>
        </ProtectedIconBtn>
    </span></Tooltip>
);

/**
 * Prepared delete icon button
 *
 * @param {Object} props
 * @public
 */
export const DeleteIconBtn = ({tooltip = translate('GLOBALS$DELETE'), theme = 'ALT', ...attr}) => (<Theme name={theme}>
    <Tooltip title={tooltip} placement="top" enterDelay={300}><span>
        <ProtectedIconBtn aria-label={tooltip} color="secondary" {...attr}>
            <Delete fontSize="small"/>
        </ProtectedIconBtn>
    </span></Tooltip>
</Theme>);

/**
 * Back button
 *
 * @param {Object} props
 * @public
 */
export const BackBtn = (props) => (<CancelBtn {...props} onClick={history.back}/>);
