
// outsource dependencies
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { history } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
}

function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        yield put({type: EDIT.DATA, data});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, initialized: true, ...params});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}


function* cancelSaga () {
    let { back } = yield select(state => state.myTasks.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get my task by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return id === NEW_ID ? {} : instanceAPI({method: 'get', url: `/my-tasks/${id}`});
}

/**
 * update my tasks data
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return instanceAPI({ method: !data.id || data.id === NEW_ID ? 'post' : 'put', url: '/my-tasks', data});
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
