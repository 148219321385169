// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {change, Field, getFormValues, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import {MdAsyncCreatableSelect, MdSelect} from '../../../components/md-select';
import ErrorMessage from '../../../components/alert-error';
import SelectEntities from '../../../components/select-entities';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {DEPLOYMENT_TYPES, ENTITY_TYPES, NEW_ID, ROLES, STATUS} from '../../../constants/spec';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel, formatTechnologyLabel} from '../../../services/data-formatting.service';
import separateService from '../../../services/separate-with-comma.service';
import {TECHNOLOGY_ASSETS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";
import {useParams} from "react-router-dom";
import {instanceAPI} from "../../../services/api.service";
import MdDatePicker from "../../../components/md-date-picker";

export const FORM_NAME = 'editTechnologyAssets';

export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={TECHNOLOGY_ASSETS_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-4">
                    <Col xs={12}>
                        <Paper className="indent-5">
                            <RichHintTitle update={EDIT} name={isNew ? `TECHNOLOGY_ASSETS$CREATE_TECHNOLOGY_ASSET` : `TECHNOLOGY_ASSETS$EDIT_TECHNOLOGY_ASSET`} expectAnswer={expectAnswer} data={findHint(hints,'TECHNOLOGY_ASSETS_TITLE')}/>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({expectAnswer: state.technologyAssets.edit.expectAnswer, hints: state.technologyAssets.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.technologyAssets.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.technologyAssets.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.technologyAssets.edit.data,
        disabled: state.technologyAssets.edit.expectAnswer,
        hints: state.technologyAssets.edit.hintsData,
        technologySubcategories: state.technologyAssets.edit.technologySubcategories,
        technologyClassTypes: state.technologyAssets.edit.technologyClassTypes,
        technologies: state.technologyAssets.edit.technologies,
        formValues: getFormValues(FORM_NAME)(state),
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        setupTechnologyCategory: technologyCategoryId => dispatch({type: EDIT.SETUP_CATEGORY, technologyCategoryId}),
        createTechnologySubcategory: name => dispatch({type: EDIT.CREATE_SUBCATEGORY, name}),
        setupTechnologySubcategory: technologySubcategoryId => dispatch({type: EDIT.SETUP_SUBCATEGORY, technologySubcategoryId}),
        setupTechnologyClassType: technologyClassTypeId => dispatch({type: EDIT.SETUP_CLASS_TYPE, technologyClassTypeId}),
        createTechnologyClassType: name => dispatch({type: EDIT.CREATE_CLASS_TYPE, name}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // status
        if (!values.systemStatus) {
            errors.systemStatus = 'GLOBALS$STATUS_REQUIRED';
        }
        // owner
        if (!values.owner) {
            errors.owner = 'SYSTEMS$SYSTEM_OWNER_NAME_REQUIRED';
        }

        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, hints, formValues = {}, technologySubcategories, technologyClassTypes, technologies, setupTechnologyCategory, createTechnologySubcategory, setupTechnologySubcategory, createTechnologyClassType, setupTechnologyClassType, }) => (
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-top-4">
            <Col xs={12} md={12} className="offset-bottom-4">
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('TECHNOLOGY_ASSETS$NAME')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('TECHNOLOGY_ASSETS$NAME')} </strong>)}
                />
            </Col>
            <Col xs={12} md={12} className="offset-bottom-4">
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('TECHNOLOGY_ASSETS$DESCRIPTION')}
                    label={(<strong> {translate('TECHNOLOGY_ASSETS$DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>

        <Paper className="indent-2 offset-top-8 offset-bottom-8">
            <Row>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <SelectEntities
                        disabled={disabled}
                        name="technologyCategory"
                        type={ENTITY_TYPES.TECH_CATEGORIES}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong className="required-asterisk"> {translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CATEGORY')} </strong>)}
                        onChange={(e) => setupTechnologyCategory(get(e, 'id', null))}
                    />
                </Col>

                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="technologySubcategory"
                        disabled={disabled || !formValues || !formValues.technologyCategory}
                        defaultOptions={technologySubcategories}
                        component={MdAsyncCreatableSelect}
                        onCreateOption={name => createTechnologySubcategory(name)}
                        placeholder={translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_SUBCATEGORY')}
                        label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_SUBCATEGORY')} </strong>)}
                        getNewOptionData={(inputValue, optionLabel)=>({id: inputValue, name: optionLabel, __isNew__: true})}
                        loadOptions={(name, done) => {
                            if (formValues && formValues.technologyCategory && formValues.technologyCategory.id) {
                                instanceAPI({
                                    method: 'post',
                                    url: '/technology-subcategories/filter',
                                    data: { page: 0, size: 6, filter: { name: name, parentId: formValues.technologyCategory.id } },
                                }).then(({items}) => done(items)).catch(done.bind(null, []));
                            } else {
                                done([]);
                            }
                        }}
                        onChange={(e) => setupTechnologySubcategory(get(e, 'id', null))}
                    />
                </Col>

                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="technologyClassType"
                        disabled={disabled || !formValues || !formValues.technologyCategory || !formValues.technologySubcategory}
                        defaultOptions={technologyClassTypes}
                        component={MdAsyncCreatableSelect}
                        onCreateOption={name => createTechnologyClassType(name)}
                        placeholder={translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CLASS_TYPE')}
                        label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CLASS_TYPE')} </strong>)}
                        getNewOptionData={(inputValue, optionLabel)=>({id: inputValue, name: optionLabel, __isNew__: true})}
                        loadOptions={(name, done) => {
                            if (formValues && formValues.technologySubcategory && formValues.technologySubcategory.id) {
                                instanceAPI({
                                    method: 'post',
                                    url: '/technology-class-types/filter',
                                    data: { page: 0, size: 6, filter: { name: name, parentId: formValues.technologySubcategory.id } },
                                }).then(({items}) => done(items)).catch(done.bind(null, []));
                            } else {
                                done([]);
                            }
                        }}
                        onChange={(e) => setupTechnologyClassType(get(e, 'id', null))}
                    />
                </Col>

                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <SelectEntities
                        isMulti={false}
                        disabled={disabled}
                        name="technology"
                        defaultOptions={technologies}
                        type={ENTITY_TYPES.TECHNOLOGIES}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        getOptionLabel={item => formatTechnologyLabel(item)}
                        label={(<strong> {translate('TECHNOLOGY_ASSETS$TECHNOLOGIES')} </strong>)}
                    />
                </Col>
            </Row>
        </Paper>

        <Row>
            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <Field
                    labelKey="label"
                    valueKey="value"
                    name="deploymentType"
                    disabled={disabled}
                    component={MdSelect}
                    sendValueProp
                    placeholder={translate('SYSTEMS$DEPLOYMENT_TYPE')}
                    options={['ON_PREMISE', 'CLOUD', 'HYBRID'].map(option=>{
                        return ({
                            label: translate(DEPLOYMENT_TYPES[option]) ,
                            value: option
                        })
                    })}
                    optionsLabel={({value}) => translate(DEPLOYMENT_TYPES[value]) }
                    required={true}
                    label={(<strong> {translate('SYSTEMS$DEPLOYMENT_TYPE')} </strong>)}
                    simpleValue={(value) => { return {value: value, label: value && DEPLOYMENT_TYPES[value] ? translate(DEPLOYMENT_TYPES[value]) : '' } }}
                />
            </Col>

            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <Field
                    required
                    labelKey="label"
                    valueKey="value"
                    name="systemStatus"
                    disabled={disabled}
                    component={MdSelect}
                    sendValueProp
                    placeholder={translate('GLOBALS$STATUS')}
                    options={[STATUS.ACTIVE, STATUS.DISABLED, STATUS.PENDING].map(option=>{
                        return ({
                            label: option ,
                            value: option
                        })
                    })}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$STATUS')} </strong>)}
                    optionsLabel={({value}) => value}
                    simpleValue={(value) => { return {value: value, label: value} }}

                />
            </Col>

            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <Field
                    type="text"
                    component={MdInput}
                    disabled={disabled}
                    name="costToRestore"
                    placeholder={translate('SYSTEMS$COST_TO_RESTORE')}
                    label={(<strong> {translate('SYSTEMS$COST_TO_RESTORE')} </strong>)}
                    normalize={separateService.normalize}
                />
            </Col>

            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <Field
                    name="rto"
                    type="number"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('SYSTEMS$RTO')}
                    label={(<strong> {translate('SYSTEMS$RTO')} </strong>)}
                />
            </Col>

            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <Field
                    name="rpo"
                    type="number"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('SYSTEMS$RPO')}
                    label={(<strong> {translate('SYSTEMS$RPO')} </strong>)}
                />
            </Col>

            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <SelectEntities
                    isMulti={false}
                    disabled={disabled}
                    name="manufacturer"
                    type={ENTITY_TYPES.VENDORS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={item => item.name}
                    label={(<strong> {translate('TECHNOLOGY_ASSETS$VENDOR')} </strong>)}
                />
            </Col>

            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <SelectEntities
                    name="businessUnit"
                    disabled={disabled}
                    type={ENTITY_TYPES.BUSINESS_UNITS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    required={true}
                    label={(<strong> {translate('BUSINESS_UNITS$BUSINESS_UNIT')} </strong>)}
                />
            </Col>

            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <SelectEntities
                    disabled={disabled}
                    name="dataAssetClassification"
                    type={ENTITY_TYPES.ASSET_CLASSES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    required={true}
                    label={(<strong> {translate('ASSET_CLASSES$ASSET_CLASS')} </strong>)}
                />
            </Col>

            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <SelectEntities
                    name="owner"
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    // additionalFilters={{roles: [ROLES.SYSOWN]}}
                    getOptionLabel={option => get(option, 'fullName')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('TECHNOLOGY_ASSETS$OWNER_NAME')} </strong>)}
                />
            </Col>

            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <SelectEntities
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    name="infosecFocalPerson"
                    additionalFilters={{roles: [ROLES.INFOSEC]}}
                    getOptionLabel={option => get(option, 'fullName')}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('GLOBALS$INFOSEC_FOCAL_PERSON')} </strong>)}
                />
            </Col>

            <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                <Field
                    type="text"
                    component={MdInput}
                    disabled={disabled}
                    name="numberOfRecProcessed"hint = {findHint(hints, 'BUTTON_SYSTEMS_RESET')}
                    placeholder={translate('SYSTEMS$RECORDS_PROCESSED')}
                    label={(<strong> {translate('SYSTEMS$RECORDS_PROCESSED')} </strong>)}
                    normalize={separateService.normalize}
                />
            </Col>
        </Row>

        <Paper className="indent-2 offset-top-8 offset-bottom-8">
            <Row>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="osName"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$OPERATION_SYSTEM')}
                        label={(<strong> {translate('TECHNOLOGY$OPERATION_SYSTEM')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="versionNumber"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$VERSION_NUMBER')}
                        label={(<strong> {translate('TECHNOLOGY$VERSION_NUMBER')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="serialNumber"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$SERIAL_NUMBER')}
                        label={(<strong> {translate('TECHNOLOGY$SERIAL_NUMBER')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        disabled={disabled}
                        name="eolDate"
                        component={MdDatePicker}
                        label={(<strong> {translate('TECHNOLOGY$END_OF_LIFE')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        disabled={disabled}
                        name="warrantyExpiration"
                        component={MdDatePicker}
                        label={(<strong> {translate('TECHNOLOGY$WARRANTY_EXPIRATION')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="assetName"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$ASSET_NAME')}
                        label={(<strong> {translate('TECHNOLOGY$ASSET_NAME')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="ipAddress"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$IP_ADDRESS')}
                        label={(<strong> {translate('TECHNOLOGY$IP_ADDRESS')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="assetDomainFunction"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$ASSET_DOMAIN_FUNCTION')}
                        label={(<strong> {translate('TECHNOLOGY$ASSET_DOMAIN_FUNCTION')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="discoverySource"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$DISCOVERY_SOURCE')}
                        label={(<strong> {translate('TECHNOLOGY$DISCOVERY_SOURCE')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="deviceId"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$DEVICE_ID')}
                        label={(<strong> {translate('TECHNOLOGY$DEVICE_ID')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="hardwareSubstatus"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$HARDWARE_SUBSTATUS')}
                        label={(<strong> {translate('TECHNOLOGY$HARDWARE_SUBSTATUS')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="ownerType"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$OWNER_TYPE')}
                        label={(<strong> {translate('TECHNOLOGY$OWNER_TYPE')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        name="location"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('TECHNOLOGY$LOCATION')}
                        label={(<strong> {translate('TECHNOLOGY$LOCATION')} </strong>)}
                    />
                </Col>
            </Row>
        </Paper>

        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_SYSTEMS_CREATE' : 'BUTTON_SYSTEMS_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2" hint = {findHint(hints, 'BUTTON_SYSTEMS_RESET')} />
                <CancelBtn onClick={cancel} hint = {findHint(hints, 'BUTTON_SYSTEMS_CANCEL')} />
            </Col>
        </Row>
    </form>
))));

// function isAssociateVendorsVisible(systemType) {
//     return (!systemType || systemType === 'NONE' || systemType === 'COTS' || systemType === 'CUSTOM_COTS');
// }
