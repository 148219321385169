// outsource dependencies
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';

// local dependencies
import SignInForm from './form';
import {PublicLogo} from '../../images';
import {APP} from '../../actions/types';
import {translate} from '../../services/translate.service';
import {FORGOT_PASSWORD, PUBLIC_WELCOME, SIGN_UP} from '../../constants/routes';
import Auth from "../../services/auth.service";

// configuration


/**
 * Sign In page
 *
 * @public
 */
class SignInPage extends Component {

    constructor(...args) {
        super(...args);
        this.state = {};
    }

    componentDidMount () {
        this.props.clear();
    }
    componentWillUnmount () {
        this.props.clear();
    }

    render() {
        let { hasMultiFactorAuth } = this.props;
        return (<div>
            <div className="row top-indent-8 offset-bottom-10 offset-top-10">
                <div className="col-xs-10 col-xs-offset-1 text-center">
                    <Link to={PUBLIC_WELCOME.LINK()}>
                        <PublicLogo />
                    </Link>
                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <SignInForm />
                </div>
            </div>
            <div className="row top-indent-4">
                <div className="col col-6 text-uppercase text-left">
                    <Link to={SIGN_UP.LINK()} className={'link' + (hasMultiFactorAuth ? ' no-events' : '')}>
                        {translate('AUTHORIZATION$SIGN_UP')}
                    </Link>
                </div>
                <div className="col col-6 text-uppercase text-right">
                    <Link to={FORGOT_PASSWORD.LINK()} className={'link' + (hasMultiFactorAuth ? ' no-events' : '')}>
                        {translate('AUTHORIZATION$FORGOT_PASSWORD')}
                    </Link>
                </div>
            </div>
        </div>);
    }

    UNSAFE_componentWillMount () {
        let { signInOkta } = this.props;

        if (Auth.isOkta()) {
            signInOkta(Auth.getToken());
        }
    }
}

export default connect(
    state => ({ hasMultiFactorAuth: state.app.hasMultiFactorAuth }),
    dispatch => ({
        clear: () => dispatch({type: APP.SIGN_IN.CLEAR}),
        signInOkta: (token) => dispatch({type: APP.SIGN_IN.REQUEST, mode: 'okta', code: token}),
    })
)(SignInPage);
