
// outsource dependencies
import React, { Component } from 'react';

// local dependencies
import { is } from '../services/is.service';
import {getSelf, getDownloadLink, instanceAPI} from '../services/api.service';
import {translate} from "../services/translate.service";
import {toastr} from "react-redux-toastr";

// configuration

/**
 * @description download link HOC for downloading links which requires latest token
 * 'link' param should be a function, to get latest token in the moment of firing link
 *
 * For another purposes use <a /> tag for external links, and <Link />(react-router) for internal
 *
 * for simple links (without parameters) pass link to HOC options
 * @example
 const DownloadBtn = withDownloadLink({link: DOWNLOAD.SOME_LINK})(props => (
 <PrimaryBtn {...props} tooltip="Download data">
 <i className="fa fa-file-text-o" aria-hidden="true" /> &nbsp; Download data
 </PrimaryBtn>
 ));
 *
 * for compose links (with parameters) create new component withDownloadLink features and pass link as props in place,
 * where component is used
 *
 * @example
 const DownloadBtn = withDownloadLink()(props => (
 <PrimaryBtn {...props} tooltip="Download data">
 <i className="fa fa-file-text-o" aria-hidden="true" /> &nbsp; Download data
 </PrimaryBtn>
 ));
 *
 * ***INSIDE CODE***
 <DownloadBtn link={ () => SUPER_LINK({id: 'super_id'}) }
 *
 *
 * @constructor withDownloadLink
 * @public
 */
export default function withDownloadLink (options={}) {
    const downloadLink = is.function(options.link) ? options.link : v=>v;
    const downloadType = options.downloadType || '';
    return function (WrappedComponent) {
        return class extends Component {
            constructor (props) {
                super(props);
                this.state = {
                    disabled: false,
                };
            }

            download() {
                let { link, itemId, verifyUrl } = this.props;
                link = is.function(link) ? link : downloadLink;
                this.setState({disabled: true});
                // NOTE request to refresh token
                getSelf().then(() => {
                    if (downloadType) {
                        getDownloadLink(downloadType).then(({url}) => {
                            let re = /{itemId}/gi;
                            url = url.replace(re, itemId);

                            if (verifyUrl && is.function(verifyUrl)) {
                                url = verifyUrl(url);
                            }

                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'download';
                            document.body.appendChild(a);
                            let promise = instanceAPI({ method: 'get', url: url});
                            promise.then(() => {a.click(); a.remove()})
                                .catch( (error) => {
                                    toastr.error(translate('Download'), error.message);
                                });
                        });
                        return
                    }
                    let a = document.createElement('a');
                    a.href = link();
                    a.download = 'download';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();

                }).catch( error => {
                    // NOTE do nothing
                    // console.error(error);
                }).finally(()=>this.setState({disabled: false}));
            }

            render() {
                let {link, ...attr} = this.props;
                return (
                    <WrappedComponent
                        {...attr}
                        onClick={()=>this.download()}
                        disabled={this.state.disabled || attr.disabled}
                    />
                )
            }
        }
    }
}

