// outsource dependencies
import {every, get} from 'lodash';
import {connect} from 'react-redux';
import React, {useEffect, useRef} from 'react';
//import { Prompt } from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {Accordion, AccordionDetails, AccordionSummary, Paper, Tab, Tabs} from '@mui/material';
import {Field, FieldArray, getFormValues, isPristine, reduxForm} from 'redux-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// local dependencies
import {ACTIONS} from './actions';
import is from '../../services/is.service';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {CategoryQuestions, QuestionRow} from '../../components/question-row';
import {PrimaryBtn, ResetBtn, SubmitBtn, WarningBtn} from '../../components/md-button';
import {translate, withTranslation} from '../../services/translate.service';
import {findHint, RichHintTitle} from '../../components/hints/hints';
import {CYBERSECURITY_MAP} from '../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumb';
import {useReactToPrint} from "react-to-print";
import {getQueryStringParameter} from "../../helpers/react-utils";
import {history} from "../../store";
import withDownloadLink from "../../components/download-link";
import {DOWNLOAD_TYPES, PERMISSION} from "../../constants/spec";
import {useInView} from "react-intersection-observer";
import {useParams} from "react-router-dom";

const Questions = (props) => {
    let params = useParams();
    let subPage = params ? params.subPage : null;
    useEffect(() => {
        props.initialize(subPage);
        return () => {
            props.clear();
        };
    }, [subPage]);

    let { message, disabled, clearError, pristine, hints, pageTitle } = props;
    if (!pageTitle) pageTitle = 'CYBERSECURITY_MATURITY$TITLE';
    // let metricDomainCode = data && data.metricDomainCode ? data.metricDomainCode : 'ALL';

    return (<div style={{height: '100%', overflowY: 'auto'}}>
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ CYBERSECURITY_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-top-4">
                    <Col xs={12}>
                        <Row className="offset-bottom-4">
                            <Col xs={12} lg={8}>
                                <RichHintTitle update={ACTIONS}  name={pageTitle} expectAnswer={disabled} data={findHint(hints, 'CYBERSECURITY_MATURITY_TITLE')}/>
                            </Col>
                            <Col xs={12} lg={4} className="text-right">
                                {!pristine &&
                                    <h3 className="text-danger text-right top-indent-1">
                                        <strong>
                                            <i className="fa fa-exclamation-triangle" aria-hidden="true" /> {translate('GLOBALS$UNSAVED_DATA')}
                                        </strong>
                                    </h3>
                                }
                            </Col>
                        </Row>
                        <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError} /> </Col> </Row>
                        <Row> <Col xs={12}> <ConnectedForm /> </Col> </Row>
                        {/*NOTE block navigating away from a page and show message*/}
                        {/*<Prompt when={!pristine} message={translate('GLOBALS$UNSAVED_DATA_MESSAGE')} />*/}
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    </div>);
};

export default connect(
    state => ({
        data: state.cybersecurityMaturity.data,
        pristine: isPristine('organizationScoringQuestions')(state),
        message: state.cybersecurityMaturity.errorMessage,
        disabled: state.cybersecurityMaturity.expectAnswer,
        hints: state.cybersecurityMaturity.hintsData,
        pageTitle: state.cybersecurityMaturity.pageTitle,
    }),
    dispatch => ({
        clear: () => dispatch({type: ACTIONS.CLEAR}),
        initialize: (subPage) => dispatch({type: ACTIONS.INITIALIZE, subPage: subPage}),
        clearError: () => dispatch({ type: ACTIONS.META, errorMessage: null}),
    })
)(Questions)

const ConnectedInitializer = connect(
    state => ({initialize: state.cybersecurityMaturity.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        metric: state.cybersecurityMaturity.metric,
        initialValues: state.cybersecurityMaturity.data,
        formValues: getFormValues('organizationScoringQuestions')(state),
        calculations: state.cybersecurityMaturity.data.calculations,
        disabled: state.cybersecurityMaturity.expectAnswer,
        metricDomains: state.cybersecurityMaturity.metricDomains,
        questionsLength: get(state, 'cybersecurityMaturity.data.questions.length', 0),
        hints: state.cybersecurityMaturity.hintsData,
    }),
    dispatch => ({
        update: formData => dispatch({type: ACTIONS.UPDATE, ...formData}),
        changeMetric: metric => dispatch({type: ACTIONS.GET_DATA, metric}),
        uploadFile: (file, fieldName) => dispatch({type: ACTIONS.UPLOAD_FILE, file, fieldName}),
    })
)(reduxForm({
    form: 'organizationScoringQuestions',
    enableReinitialize: true,
    warn: values => {
        const warnings = {};
        let map = {};
        // NOTE build question - answer map
        (values.questions||[]).forEach(item => {
            map[item.id] = get(item, 'selectedAnswers.id', null);
        });
        // NOTE check branching logic
        let branchingWarns = [];

        if (values.questions) {
            if (values.questions.length < 127 || values.categories.length < 2) {
                (values.questions).forEach((item, index) => {
                    let warns = {};
                    let branchingLogic = get(item, 'branchingLogic', []);
                    let isVisible = every(branchingLogic, branching => {
                        const questionId = get(branching, 'question.id'), answerId = get(branching, 'answer.id');
                        return map[questionId] === answerId;
                    });
                    if (!isVisible) {
                        warns.isHidden = true;
                    }
                    branchingWarns[index] = warns;
                });
            } else {
                let index = 0;
                for (let i = 0; i < values.categories.length; i++) {
                    let categoryData = values.categories[i];
                    for (let j = 0; j < categoryData.questions.length; j++) {
                        let item = categoryData.questions[j];
                        let warns = {};
                        let branchingLogic = get(item, 'branchingLogic', []);
                        let isVisible = every(branchingLogic, branching => {
                            const questionId = get(branching, 'question.id'), answerId = get(branching, 'answer.id');
                            return map[questionId] === answerId;
                        });
                        if (!isVisible) {
                            warns.isHidden = true;
                        }
                        branchingWarns[index++] = warns;
                    }
                }
            }
        }


        if ( !is.empty(branchingWarns) ) {
            warnings.questions = branchingWarns;
        }
        return warnings;
    }
})(({handleSubmit, invalid, pristine, disabled, update, hints, reset, questionsLength, calculations, uploadFile, changeMetric, metric, metricDomains, initialValues, formValues }) => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: true,
    });

    const metricDomainCode = metric ? metric : "ALL";

    const [ref, inView] = useInView({triggerOnce: false, rootMargin: '0px 0px',});

    const back = getQueryStringParameter('back');
    let answersMap = {};
    if (formValues && formValues.questions) {
        for (let i = 0; i < formValues.questions.length; i++) {
            let question = formValues.questions[i];
            if (question.selectedAnswers) {
                answersMap[question.id] = question.selectedAnswers;
            }
        }
    }

    return (
    <form autoComplete="off" name="organizationScoringQuestions" onSubmit={handleSubmit(update)}>

        {!inView ? (
            <div className="page-save-block-hover">
                <SubmitBtn disabled={pristine||invalid||disabled} className="offset-right-2" hint = {findHint(hints, 'BUTTON_CYBERSECURITY_MATURITY_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint = {findHint(hints, 'BUTTON_CYBERSECURITY_MATURITY_RESET')}/>
                <DownloadLink className="" itemId={metricDomainCode} hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_DOWNLOAD_DATA')} />
            </div>
        ) : ""}

        {(metricDomains && metricDomains.length > 2 ?
            <Paper square>
                <Row>
                    <Col xs={10}>
                        <Tabs
                            value={metric}
                            textColor="primary"
                            variant="scrollable"
                            indicatorColor="primary"
                            onChange={(e, value) => changeMetric(value)}
                        >
                            {metricDomains.map((item, key) => <Tab key={key} value={item.code} label={item.name}/>)}
                        </Tabs>
                    </Col>
                    <Col xs={2}>
                        <div className="offset-top-2" style={{float: 'right', marginRight: 30}}>
                            <WarningBtn onClick={handlePrint} tooltip={"Print"} hint={findHint(hints, `BUTTON_QUESTIONS_PRINT`)} >
                                <i className="fa fa-print" aria-hidden="true"/>
                            </WarningBtn>
                            {back &&
                                <WarningBtn onClick={() => history.push(back)} tooltip={translate('GLOBALS$BACK')} hint={findHint(hints, `BUTTON_QUESTIONS_BACK`)} >
                                    <i className="fa fa-reply" aria-hidden="true"/>
                                </WarningBtn>
                            }
                        </div>
                    </Col>
                </Row>
            </Paper>
            : ""
        )}
        <Paper square className="indent-5">
            <Container fluid>
                <Row className="offset-bottom-4" ref={componentRef}>
                    {(calculations && calculations[metric] && calculations[metric].hideCalculation ? "" : (
                        <Col xs={12} className="text-center text-uppercase offset-bottom-6">
                            <RichHintTitle classes="h3" placement="bottom" update={ACTIONS}  name={'Calculated value:'} expectAnswer={disabled} data={findHint(hints, 'CYBERSECURITY_MATURITY_CALCULATED_VALUE')}/>
                            <span className="h3 text-uppercase">
                                {(calculations && calculations[metric] && calculations[metric].resultNormalized
                                    ? Number(calculations[metric].resultNormalized * 100).toFixed(2) : "N/A")}
                            </span>
                        </Col>
                    ))}
                    <Col xs={12}>
                        {!questionsLength ? (
                            <h3 className="text-center text-uppercase offset-bottom-6"> {translate('GLOBALS$NO_QUESTIONS_FOUND')} </h3>
                        ) : questionsLength < 127 || initialValues.categories.length < 2 ? (
                            <FieldArray name="questions" component={Items} uploadFile={uploadFile}
                                        initialValues={initialValues} disabled={disabled}/>
                        ) : (
                            <ItemsInCategories uploadFile={uploadFile} disabled={disabled} answersMap={answersMap} />
                        )}
                    </Col>
                </Row>
                <Row ref={ref}>
                    <Col xs={12} className="text-right">
                        <SubmitBtn disabled={pristine||invalid||disabled} className="offset-right-2" hint = {findHint(hints, 'BUTTON_CYBERSECURITY_MATURITY_SAVE')}/>
                        <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint = {findHint(hints, 'BUTTON_CYBERSECURITY_MATURITY_RESET')}/>
                        <DownloadLink className="offset-right-2" itemId={metricDomainCode} hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_DOWNLOAD_DATA')} />
                    </Col>
                </Row>
            </Container>
        </Paper>
    </form>
)}
)));

/**
 * component for item of "questions" list
 *
 * @param {Object} props
 * @private
 */
const Items = ({ fields, disabled, uploadFile, initialValues }) => {
    // console.log(fields.getAll());
    // console.log(initialValues);
    return fields.map((mKey, index) => ( <Field key={index} name={mKey} component={QuestionRow} disabled={disabled} uploadFile={uploadFile} /> ))
};

/**
 * component for item of "questions" list in category
 *
 * @param {Object} props
 * @private
 */
const CategoryItems = ({fields, disabled, uploadFile, questionCategory, answersMap}) => {
    return fields.map((mKey, index) => (
        <Field key={index} name={mKey} component={CategoryQuestions} category={questionCategory} disabled={disabled}
               uploadFile={uploadFile} answersMap={answersMap} />));
};

const ItemsInCategories = withTranslation(connect(
    state => ({categories: state.cybersecurityMaturity.data.categories}),
)(({categories, disabled, uploadFile, answersMap}) => (
    categories.map((item, index) => {
        return (
            <Accordion key={item.categoryName} defaultExpanded={(index === 0)} TransitionProps={{unmountOnExit: true}}>
                <AccordionSummary className="header-row-title" expandIcon={<ExpandMoreIcon sx={{color: '#FFFFFF'}} />}>
                    {`${item.categoryName}`}
                </AccordionSummary>
                <AccordionDetails>
                    <FieldArray name="questions" component={CategoryItems}
                                questionCategory={item.categoryName}
                                uploadFile={uploadFile}
                                disabled={disabled} answersMap={answersMap} />
                </AccordionDetails>
            </Accordion>);
    })
)));

const DownloadLink = withTranslation(withDownloadLink({downloadType: DOWNLOAD_TYPES.SCORING_QUESTIONS.XLSX_REPORT})(props => {
    const { itemId, ...innerProps } = props;
    return (
        <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_DATA')} permission={PERMISSION.EXPORT.QUALITATIVE_QUESTION_ANSWERS} {...innerProps}>
            <i className="fa fa-download" style={{fontSize: 20, marginTop: 2}} aria-hidden="true"/>&nbsp;&nbsp;
            <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true"/>
        </PrimaryBtn>
    );
}));
