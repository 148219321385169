// outsource dependencies
import {find, findIndex} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Field, FieldArray, reduxForm} from 'redux-form';
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Paper} from '@mui/material';

// local dependencies
import Input from '../../components/md-input';
import MdSwitch from '../../components/md-switch';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {MdSelect} from '../../components/md-select';
import {PERMISSION} from '../../constants/spec';
import {Link, PrimaryBtn, ResetBtn, SubmitBtn} from '../../components/md-button';
import PermissionAccess from '../../components/permission-access';
import {translate, withTranslation} from '../../services/translate.service';
import {PRIVATE_CHANGE_PASSWORD, PROFILE} from "../../constants/routes";
import {MODELS_MODAL} from "../select-model-modal/actions";
import separateService from '../../services/separate-with-comma.service';
import {EDIT} from "./actions";
import {findHint, RichHintTitle} from '../../components/hints/hints';


// config
// const selectStyles = {singleValue: provided => ({...provided, color: 'black'})};

export const THRESHOLD_RISK_LEVELS = {
    'undefined': '',
    1: 'Low',
    2: 'Medium Low',
    3: 'Medium',
    4: 'Medium High',
    5: 'High',
};

class AllSettings extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, hints} = this.props;
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span:10,offset:1}} lg={{span:8,offset:2}}>
                            <Paper className="indent-8">
                                <h2 className="text-uppercase offset-bottom-8 text-center">
                                    <span className="align-middle">
                                        <RichHintTitle
                                            update={EDIT}
                                            name={`SETTINGS$TITLE`}
                                            data={findHint(hints, `SETTINGS_TITLE`)}/>
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                                </h2>
                                <ConnectedError/>
                                <Settings/>
                                {/*<Organization/>*/}
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.settings.expectAnswer, hints: state.settings.hintsData}),
    dispatch => ({
        initialize: () => dispatch({type: EDIT.INITIALIZE}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(AllSettings)

const ConnectedInitializer = connect(
    state => ({initialize: state.settings.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.settings.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const Settings = withTranslation(connect(
    state => ({
        user: state.settings.user,
        riskModel: state.app.riskModel,
        initialValues: state.settings.data,
        disabled: state.settings.expectAnswer,
        hints: state.settings.hintsData,
    }),
    dispatch => ({
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        changeModel: () => dispatch({type: MODELS_MODAL.INITIALIZE}),
    })
)(reduxForm({form: 'editOrganization', enableReinitialize: true})(
    ({handleSubmit, update, disabled, reset, pristine, user, riskModel, changeModel, hints}) => {
        return (<form autoComplete="off" name="settings" className="offset-bottom-8" onSubmit={handleSubmit(update)}>
            <Row>
                <Col md={6} xs={12}>
                    <Row className="offset-bottom-4">
                        <h4>
                            <span className="align-middle">{translate('PROFILE$PROFILE')}</span>
                        </h4>
                        <Col xs={4}>
                            <Field
                                component={Input}
                                disabled={true}
                                style={{color: 'black'}}
                                name="user"
                                input={{value: user.firstName ? `${user.firstName} ${user.lastName}` : "Profile name"}}
                                // label={(<strong> {translate('Auditor')} </strong>)}
                            />
                        </Col>
                        <Col xs={8} className="text-center">
                            <Link
                                Btn={PrimaryBtn}
                                tooltip={translate('GLOBALS$EDIT')}
                                to={PROFILE.LINK()}
                                hint={findHint(hints, 'BUTTON_SETTINGS_EDIT')}
                            >
                                {translate('GLOBALS$EDIT')}
                            </Link>
                            <Link
                                Btn={PrimaryBtn}
                                tooltip={translate('AUTHORIZATION$CHANGE_PASSWORD')}
                                to={PRIVATE_CHANGE_PASSWORD.LINK()}
                                hint={findHint(hints, 'BUTTON_SETTINGS_CHANGE_PASSWORD')}
                            >
                                {translate('AUTHORIZATION$CHANGE_PASSWORD')}
                            </Link>
                        </Col>
                    </Row>
                </Col>

                <PermissionAccess permission={PERMISSION.RISK_MODEL.UPDATE}>
                    <Col md={6} xs={12}>
                        <Row className="offset-bottom-4">
                            <h4>
                                <span className="align-middle">{translate('RISK_MODEL$TITLE')}</span>
                            </h4>
                            <Col xs={7}>
                                <Field
                                    component={Input}
                                    disabled={true}
                                    style={{color: 'black'}}
                                    name="model"
                                    input={{value: riskModel.name ? `${riskModel.name}` : "Current model"}}
                                    // label={(<strong> {translate('Auditor')} </strong>)}
                                />
                            </Col>
                            <Col xs={5} className="text-center">
                                <PrimaryBtn tooltip={translate('RISK_MODEL$CHANGE_MODEL')} onClick={e => changeModel()}
                                            disabled={disabled}  hint={findHint(hints, 'BUTTON_SETTINGS_CHANGE_RISK_MODEL')}>
                                    {translate('RISK_MODEL$CHANGE_MODEL')}
                                </PrimaryBtn>
                            </Col>
                        </Row>
                    </Col>
                </PermissionAccess>
            </Row>
        </form>)
    }
)));

export const Organization = withTranslation(connect(
    state => ({
        user: state.app.user,
        riskModel: state.app.riskModel,
        initialValues: state.settings.data,
        disabled: state.settings.expectAnswer,
    }),
    dispatch => ({update: formData => dispatch({type: EDIT.UPDATE, ...formData})})
)(reduxForm({form: 'editOrganization', enableReinitialize: true})(
    ({handleSubmit, update, disabled, reset, pristine, user, riskModel}) => {
        let isAllowed = (user.permissions || []).includes(PERMISSION.ORGANIZATION.UPDATE);
        return (<form autoComplete="off" name="editOrganization" onSubmit={handleSubmit(update)}>
            <h2 className="text-uppercase offset-bottom-8 text-center">
                <span className="align-middle">{translate('SETTINGS$ORGANIZATION')}</span>
                <Preloader expectAnswer={disabled} type="ICON"> </Preloader>
            </h2>
            <Row>
                <Col xs={12} className="offset-bottom-4">
                    <Field
                        readOnly
                        type="text"
                        component={Input}
                        name="name"
                        disabled={true}
                        label={(<strong>{translate('ORGANIZATION$NAMEFIELD')}</strong>)}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="text"
                        component={Input}
                        name="insuranceLimit"
                        style={{color: 'black'}}
                        disabled={disabled || !isAllowed}
                        label={(<strong> {translate('ORGANIZATION$INSURANCE_LIMIT')} </strong>)}
                        normalize={separateService.normalize}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="text"
                        component={Input}
                        name="insuranceDeductible"
                        style={{color: 'black'}}
                        disabled={disabled || !isAllowed}
                        label={(<strong> {translate('ORGANIZATION$CYBER_INSURANCE_DEDUCTIBLE')} </strong>)}
                        normalize={separateService.normalize}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="text"
                        component={Input}
                        name="averageRevenue"
                        style={{color: 'black'}}
                        disabled={disabled || !isAllowed}
                        label={(<strong> {translate('ORGANIZATION$ANNUAL_REVENUE')} </strong>)}
                        normalize={separateService.normalize}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        labelKey="label"
                        valueKey="value"
                        name="qualThreshold"
                        disabled={disabled || !isAllowed}
                        component={MdSelect}
                        placeholder={translate('ORGANIZATION$RISK_THRESHOLD')}
                        options={[1, 2, 3, 4, 5].map(option=>{
                            return ({
                                label: THRESHOLD_RISK_LEVELS[option] ,
                                value: option
                            })
                        })}
                        optionsLabel={({value}) => THRESHOLD_RISK_LEVELS[value]}
                        sendValueProp
                        simpleValue={(value) => {
                            return {
                                value: value,
                                label: value && THRESHOLD_RISK_LEVELS[value] ? THRESHOLD_RISK_LEVELS[value] : ''
                            }
                        }}
                        label={(<strong> {translate('ORGANIZATION$RISK_THRESHOLD')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="text"
                        component={Input}
                        disabled={disabled || !isAllowed}
                        name="marketCapitalizationNumber"
                        placeholder={translate('ORGANIZATION$MARKET_CAPITALIZATION')}
                        label={(<strong> {translate('ORGANIZATION$MARKET_CAPITALIZATION')} </strong>)}
                        normalize={separateService.normalize}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-2">
                    <Field
                        name="zip"
                        component={Input}
                        style={{color: 'black'}}
                        disabled={disabled || !isAllowed}
                        label={(<strong> {translate('ORGANIZATION$ZIP')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Field
                    fullWidth={false}
                    component={MdSwitch}
                    name="isPublicCompany"
                    disabled={disabled || !isAllowed}
                    label={(<strong> {translate('ORGANIZATION$PUBLIC_COMPANY')} </strong>)}
                />
            </Row>
            <PermissionAccess permission={PERMISSION.ORGANIZATION.UPDATE_SUPPORTED_LANGUAGES}>
                <Row><Col xs={12}>
                    <FieldArray
                        disabled={disabled}
                        name="supportedLanguages"
                        component={CheckboxGroup}
                        label={(<strong> {translate('ORGANIZATION$SUPPORTED_LANGUAGES')} </strong>)}
                    />
                </Col></Row>
            </PermissionAccess>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn
                        disabled={pristine || disabled}
                        permission={PERMISSION.ORGANIZATION.UPDATE}
                    />
                    <ResetBtn
                        onClick={reset}
                        className="offset-left-2"
                        disabled={pristine || disabled}
                        permission={PERMISSION.ORGANIZATION.UPDATE}
                    />
                </Col>
            </Row>
        </form>)
    }
)));

const CheckboxGroup = connect(
    state => ({availableLanguages: state.settings.availableLanguages}),
    null
)(({fields, availableLanguages, label}) => {
    const allValues = fields.getAll();
    const toggle = (event, option = {}) => {
        const key = findIndex(allValues, {id: option.id});
        if (event.target.checked) fields.push(option);
        else key !== -1 && fields.remove(key);
    };
    return (<FormControl component="fieldset">
        <FormLabel component="legend"> {label} </FormLabel>
        <FormGroup>
            {availableLanguages.map((option = {}, key) => (
                <FormControlLabel
                    key={key}
                    label={option.name}
                    checked={Boolean(find(allValues, {id: option.id}))}
                    control={<Checkbox style={{padding: '6px 12px'}} value={option.name}
                                       onChange={event => toggle(event, option)}/>}
                />
            ))}
        </FormGroup>
    </FormControl>);
});

// const separateWithComma = value => {
//     if (!value) return value;
//     let newLimit;
//     newLimit = Number(value.replace(/[^\d]/g, ''));
//     return newLimit.toLocaleString('en');
// };
