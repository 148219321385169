import React, {useEffect} from 'react';

const Redirect = () => {
    // let navigate = useNavigate();
    // let location = useLocation();
    useEffect(() => {
        // if (location.pathname === '/private/dashboards') {
        //     navigate('/private/home', {replace: true});
        // } else {
        //     navigate(location.pathname + '/list', {replace: true});
        // }
    }, [])
    return (
        <div>

        </div>
    );
};

export default Redirect;
