// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap';
import {Field, reduxForm} from 'redux-form';

// local dependencies
import config from '../../constants';
import {APP} from '../../actions/types';
import Input from '../../components/input';
import ErrorMessage from '../../components/alert-error';
import {translate, withTranslation} from '../../services/translate.service';
import Auth from "../../services/auth.service";

/**
 * Sign In page
 * @constructor SignInForm
 * @public
 */
function SignInForm ( props ) {
    let { invalid, signInGoogle, signInMicrosoft, handleSubmit, expectAnswer, signIn, errorMessage, clearError, notification, hasMultiFactorAuth, totpAppIsNotSet, qrCodeUrl, hideNotification } = props;
    return (
        <form name="signInForm" onSubmit={ handleSubmit( signIn ) }>
            {/*MFA notification*/}
            {notification && (<div className="text-left offset-bottom-6">
                <Alert  onDismiss={hideNotification}>{translate(notification)}</Alert>
            </div>)}
            {!totpAppIsNotSet && (
                <div className="offset-bottom-6">
                    <Field
                        type="mail"
                        name="email"
                        component={ Input }
                        className="form-control form-control-lg"
                        placeholder={translate('USERS$EMAIL')}
                        disabled={ expectAnswer || hasMultiFactorAuth }
                        label={ <span className="h4"> {translate('USERS$EMAIL')} </span> }
                    />
                </div>
            )}
            {!totpAppIsNotSet && (
                <div className="offset-bottom-8">
                    <Field
                        name="password"
                        type="password"
                        component={ Input }
                        className="form-control form-control-lg"
                        disabled={ expectAnswer || hasMultiFactorAuth }
                        placeholder={translate('GLOBALS$PASSWORD')}
                        label={ <span className="h4"> {translate('GLOBALS$PASSWORD')} </span> }
                    />
                </div>
            )}
            {totpAppIsNotSet && qrCodeUrl && (
                <div className="offset-bottom-8 text-center">
                    <img className="img-responsive" src={qrCodeUrl} alt="Scan to register OTP"/>
                </div>
            )}
            {hasMultiFactorAuth && (<div className="offset-bottom-8">
                <Field
                    skipTouch
                    name="code"
                    placeholder="Code"
                    component={ Input }
                    disabled={ expectAnswer}
                    validate={ validateCode }
                    className="form-control form-control-lg"
                    label={ <span className="h4"> {translate('GLOBALS$CODE')} </span> }
                        />
            </div>)}
            <button
                type="submit"
                disabled={ invalid || expectAnswer }
                className="btn w-100 btn-primary offset-bottom-4"
                    >
                <span> {translate('AUTHORIZATION$LOGIN')} </span>
                { expectAnswer&&(<i className="fa fa-spinner fa-spin fa-fw"> </i>) }
            </button>

            {config.GOOGLE_CLIENT_ID && (
                <button
                    type="button"
                    onClick={ signInGoogle }
                    className="btn w-100 btn-primary offset-bottom-4"
                        >
                    <i className="fa fa-google" aria-hidden="true" />
                    <span> {translate('AUTHORIZATION$LOGIN_GOOGLE')} </span>
                    { expectAnswer&&(<i className="fa fa-spinner fa-spin fa-fw"> </i>) }
                </button>
            )}
            {config.MICROSOFT_CLIENT_ID && (
                <button
                    type="button"
                    onClick={ signInMicrosoft }
                    className="btn w-100 btn-primary offset-bottom-4" >
                    <i className="fa fa-windows" aria-hidden="true" />
                    <span> {translate('AUTHORIZATION$LOGIN_MICROSOFT')} </span>
                    { expectAnswer&&(<i className="fa fa-spinner fa-spin fa-fw"> </i>) }
                </button>
            )}
            {Auth.getIdPInfo().oktaAllowed && (
                <button
                    type="button"
                    onClick={() => {
                        document.location.href = Auth.getIdPInfo().oktaAppLoginUrl;
                    }}
                    className="btn w-100 btn-primary offset-bottom-4" >
                    <i className="fa fa-circle-o" aria-hidden="true" />
                    <span> {translate('AUTHORIZATION$LOGIN_OKTA')} </span>
                    { expectAnswer&&(<i className="fa fa-spinner fa-spin fa-fw"> </i>) }
                </button>
            )}
            <ErrorMessage active message={errorMessage} onChange={clearError}/>
        </form>
    );
}

export default withTranslation(reduxForm({
    form: 'signInForm',
    /**
     * @param { Object } values - named properties of input data
     * @param { Object } meta - information about form status
     * @returns { Object } - named errors
     * @function validate
     * @public
     */
    validate: ( values, meta ) => {
        let errors = {};
        // EMAIL
        if ( !values.email ) {
            errors.email = 'USERS$EMAIL_REQUIRED';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'USERS$EMAIL_INVALID';
        }
        // PASSWORD
        if ( !values.password ) {
            errors.password = 'GLOBALS$PASSWORD_REQUIRED';
        } else if ( values.password.length < 8 ) {
            errors.password = 'GLOBALS$PASSWORD_INVALID';
        }
        return errors;
    },
})( connect(
    // mapStateToProps
    state => ({ ...state.signIn, hasMultiFactorAuth: state.app.hasMultiFactorAuth, totpAppIsNotSet: state.app.totpAppIsNotSet }),
    // mapDispatchToProps
    dispatch => ({
        signInGoogle: () => dispatch({type: APP.SIGN_IN.REQUEST, mode: 'google'}),
        signInMicrosoft: () => dispatch({type: APP.SIGN_IN.REQUEST, mode: 'microsoft'}),
        signIn: formData => dispatch({type: APP.SIGN_IN.REQUEST, ...formData}),
        clearError: () => dispatch({type: APP.SIGN_IN.META, errorMessage: null}),
        hideNotification: () => dispatch({type: APP.SIGN_IN.META, notification: null}),
    })
)(SignInForm) ));

// NOTE can't put function directly in html (max update depth error)
const validateCode = value => !value ? 'GLOBALS$CODE_REQUIRED' : undefined;
