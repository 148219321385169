
// outsource dependencies
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { history } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import getHintsList, { GDPR_ORGANIZATION_COMPLIANCE_EDIT_HINTS } from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({type, ...options}) {
    yield put({type: EDIT.CLEAR});
    try {
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, ...params});
        // NOTE get data
        let data = yield call(getData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, initialized: true});
}


function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, GDPR_ORGANIZATION_COMPLIANCE_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}
function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.gdprCompliance.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get GDPR article status by id
 * @param {Number|String} id
 * @param {Number|String} systemId
 * @param {Number|String} articleId
 * @param {Number|String} paragraphId
 * @private
 */
function getData ({id, articleId, paragraphId}) {
    if ( id === NEW_ID ) { return {}; }
    return instanceAPI({
        method: 'post',
        url: '/gdpr/organization-article/status/check',
        data: {
            id,
            article: articleId === NEW_ID ? null : {id: articleId},
            paragraph: paragraphId === NEW_ID ? null : {id: paragraphId},
        }
    });
}

/**
 * update GDPR article status
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return instanceAPI({method: 'put', data, url: '/gdpr/organization-articles'});
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
