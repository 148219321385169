// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {DOWNLOAD_TYPES, PERMISSION} from '../../../constants/spec';
import withDownloadLink from '../../../components/download-link';
import Preloader from '../../../components/preloader';
import {TECHNOLOGIES} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {AddBtn, DelBtn, EditBtn, Link, PrimaryBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {TECHNOLOGIES_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, list, hints, uploadedFile, uploadFile} = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={TECHNOLOGIES_MAP}/>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <RichHintTitle update={LIST} name={`TECHNOLOGIES$TITLE`} expectAnswer={expectAnswer}
                                           data={findHint(hints, `TECHNOLOGIES_TITLE`)}/>
                        </Col>
                    </Row>

                    <Row className="offset-bottom-4">
                        <Col xs={12} md={4}>
                            <SearchFilterConnected/>
                        </Col>
                        <Col xs={12} md={8} className="text-right offset-top-2">
                            <DownloadLink className="offset-left-2 offset-bottom-1" hint={findHint(hints, 'BUTTON_TECHNOLOGIES_DOWNLOAD_DATA')}/>
                            <DownloadTemplateLink className="offset-left-2 offset-bottom-1" hint={findHint(hints, 'BUTTON_TECHNOLOGIES_DOWNLOAD_TEMPLATE')}/>
                            <PrimaryBtn
                                component="label"
                                htmlFor="fileUpload"
                                tooltip={translate('GLOBALS$UPLOAD_CSV')}
                                className="offset-left-2 offset-bottom-1"
                                permission={PERMISSION.IMPORT.TECHNOLOGY}
                                hint={findHint(hints, 'BUTTON_TECHNOLOGIES_UPLOAD_CSV')}

                            >
                                <i className="fa fa-upload" style={{fontSize: 20}} aria-hidden="true"/>
                                &nbsp;{translate('GLOBALS$CSV')}
                                <input
                                    type="file"
                                    accept=".csv"
                                    id="fileUpload"
                                    value={uploadedFile}
                                    style={{display: 'none'}}
                                    onChange={(e) => {
                                        uploadFile(e.target.files[0]);
                                    }}
                                />
                            </PrimaryBtn>
                            <Link
                                Btn={AddBtn}
                                placement="left"
                                to={TECHNOLOGIES.LINK_EDIT()}
                                className="offset-left-2 offset-bottom-1"
                                permission={PERMISSION.TECHNOLOGY.CREATE}
                                hint={findHint(hints, 'BUTTON_TECHNOLOGIES_ADD')}
                            />
                        </Col>
                    </Row>
                    <ConnectedError/>
                    {list.length ? (
                        <Paper> <ConnectedTable/> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        expectAnswer: state.technologies.list.expectAnswer,
        list: state.technologies.list.data,
        uploadedFile: state.technologies.list.uploadedFile,
        hints: state.technologies.list.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
        uploadFile: file => dispatch({type: LIST.UPLOAD_FILE, file}),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.technologies.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const DownloadLink = withTranslation(withDownloadLink({downloadType: DOWNLOAD_TYPES.TECHNOLOGIES.CSV_LIST})(props => (
    <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_DATA')} permission={PERMISSION.EXPORT.TECHNOLOGIES} {...props}>
        <i className="fa fa-download" style={{fontSize: 20, marginTop: 2}} aria-hidden="true"/>&nbsp;&nbsp;
        <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true"/>
    </PrimaryBtn>
)));


const DownloadTemplateLink = withTranslation(withDownloadLink({downloadType: DOWNLOAD_TYPES.TECHNOLOGIES.CSV_TEMPLATE})(props => (
    <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_TEMPLATE')}
                permission={PERMISSION.EXPORT.TECHNOLOGIES_TEMPLATE} {...props}>
        <i className="fa fa-download" style={{fontSize: 20, marginTop: 2}} aria-hidden="true"/>&nbsp;&nbsp;
        <i className="fa fa-file-o" style={{fontSize: 18}} aria-hidden="true"/>
    </PrimaryBtn>
)));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter: state.technologies.list.filter,
        disabled: state.technologies.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION')}
    />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.technologies.list, hints: state.technologies.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({
    data,
    hints,
    page,
    size,
    totalPages,
    sortF,
    sortD,
    expectAnswer,
    deleteItem,
    changePage,
    changeSize,
    changeSort
}) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('name')}
                        >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'version'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('version')}
                        >
                            {translate('GLOBALS$VERSION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('description')}
                        >
                            {translate('GLOBALS$DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'technologyCategory'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('technologyCategory')}
                        >
                            {translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CATEGORY')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"/>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.version}</TableCell>
                    <AbbrevTableCell content={item.description} title={item.name} length={40}/>
                    <TableCell>{get(item, 'technologyCategory.name') + (item.technologySubcategory ? " / " + item.technologySubcategory.name : '') + (item.technologyClassType ? " / " + item.technologyClassType.name : '')}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.TECHNOLOGY.UPDATE}
                            to={TECHNOLOGIES.LINK_EDIT({
                                id: item.id,
                                query: {back: history.location.pathname + history.location.search}
                            })}
                            hint={findHint(hints, 'BUTTON_TECHNOLOGIES_EDIT')}
                        />
                        <DelBtn permission={PERMISSION.TECHNOLOGY.DELETE} onClick={() => deleteItem(item.id)}
                                hint={findHint(hints, 'BUTTON_TECHNOLOGIES_DELETE')}
                                className="offset-left-2"/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>)));

const ConnectedError = connect(
    state => ({message: state.technologies.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: LIST.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
