// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// local dependencies
import Layout from './layout';
import {DIRECTION} from '../constants/spec';
import ASIDE_MENU from './aside-menu/actions';
import {ROOT} from '../constants/material-themes';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import * as ROUTES from '../constants/routes';

/**
 * list of nested pages for private layout
 *
 * @public
 */
const PrivateLayout = (props) => {
    // Please NOTE !!! that using useEffects() hook, without dependencies array will cause infinite reloading of the menu
    useEffect(() => {
        props.getMenu();
    }, []);

    let navigate = useNavigate();
    let location = useLocation()
    let {auth, menu, direction} = props;
    // NOTE redirect for unauthorized users
    if (!auth) return  navigate('/public/' + ROUTES.SIGN_IN.ROUTE, {replace: true});
    if(location.pathname === '/private/'){
        navigate('/private/' + ROUTES.HOME.ROUTE);
    }else if(location.pathname === '/private/asset-classes/'){
        navigate('/private/' + ROUTES.ASSET_CLASSES.ROUTE + '/list');
    }
    const theme = createTheme({...ROOT, direction: direction === DIRECTION.RTL ? 'rtl' : 'ltr'});
    // NOTE Authorized users layout
    return <ThemeProvider theme={theme}>
        <Layout menu={menu}>
            <Outlet />
        </Layout>
    </ThemeProvider>
}

// Export
export default connect(
    state => ({
        auth: state.app.auth,
        expired: state.app.expired,
        direction: state.app.direction,
        menu: state.asideMenu.data
    }),
    dispatch => ({getMenu: () => dispatch({type: ASIDE_MENU.INITIALIZE})}),
)(PrivateLayout);

