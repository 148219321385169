
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle } from '@mui/material';

// local dependencies
import MdButton from '../md-button';
import CONFIRM_DIALOG from './actions';
import { translate } from '../../services/translate.service';
import {withStyles} from '@mui/styles'

/**
 * confirmation component with message which open by 'CONFIRM_DIALOG.REQUEST' action
 *
 * @example
 dispatch({type: CONFIRM_DIALOG.REQUEST, message: translate('GLOBALS$DELETION_CONFIRM') });

 *
 * @param {Object} props
 * @public
 */
export default withStyles({ paper: { minWidth: '400px' } })(connect(
    state => ({...state.confirmDialog}),
    dispatch => ({ confirm: () => dispatch({type: CONFIRM_DIALOG.SUCCESS}), dismiss: () => dispatch({type: CONFIRM_DIALOG.ERROR})}),
)(({show, title, message, confirm, dismiss, classes}) => {

    let messageLines = [];
    if (!message) {
        messageLines.push(translate('GLOBALS$DEFAULT_CONFIRMATION'));
    } else if (message.indexOf('\n') !== -1) {
        messageLines = message.split('\n');
    } else {
        messageLines.push(message);
    }

    return (
        <Dialog
            open={show}
            onClose={(event, reason) => {
                if(reason === "backdropClick" && reason === "escapeKeyDown") return false;
                dismiss();
            }}
            transitionDuration={{exit: 0}}
            classes={{paper: classes.paper}}
            aria-labelledby="confirmation-dialog"
            aria-describedby="confirmation-dialog"
                >
            { title ? (<DialogTitle id="confirmation-dialog"> {title} </DialogTitle>) : '' }
            <DialogContent>
                <DialogContentText>
                    {
                        messageLines.map((messageLine, key) => {
                            return <p key={key}>{messageLine ? messageLine : <br/>}</p>;
                        })
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <MdButton variant="text" onClick={confirm}> {translate('GLOBALS$AGREE')} </MdButton>
                <MdButton variant="text" onClick={dismiss}> {translate('GLOBALS$DISAGREE')} </MdButton>
            </DialogActions>
        </Dialog>
    )
}))

