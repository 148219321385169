
// outsource dependencies
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { historyPush } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import { QUANT_MODELS } from '../../../constants/routes';
import { instanceAPI } from '../../../services/api.service';
import getHintsList, { EXPOSURE_MODELS_EDIT_HINTS } from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({type, id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let { riskModel } = yield select( state => state.app );
        let data = yield call(getData, id, riskModel.id,);
        yield put({type: EDIT.DATA, ...data});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        yield put({type: EDIT.META, initialized: true});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* getHintsDataSaga (hintType) {
    try {
        let {language} = yield select(state => state.app);
        let hintsData = yield call(getHintsList, language, EXPOSURE_MODELS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let { riskModel } = yield select( state => state.app );
        let data = yield call(updateData, riskModel.id, options);
        yield put({type: EDIT.DATA, ...data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield call(historyPush, QUANT_MODELS.LINK());
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

/**
 * get quantification model by id
 * @param {Number|String} id
 * @param {String|Number} riskModelId
 * @private
 */
function getData ( id, riskModelId ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/risk-models/${riskModelId}/quants/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update quantification model
 * @param {String|Number} riskModelId
 * @param {Object} data
 * @private
 */
function updateData ( riskModelId, data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: `/risk-models/${riskModelId}/quants`, data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: `/risk-models/${riskModelId}/quants`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}
