// outsource dependencies
import {connect} from 'react-redux';
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from './actions';
import {history} from '../../store';
import Dashboard from '../../components/dashboard';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {WarningBtn} from '../../components/md-button';
import {translate} from '../../services/translate.service';
import {get} from "lodash";
import Breadcrumbs from '../../components/breadcrumbs/breadcrumb';

const DashboardPage = (props) => {
    // componentWillUnmount() { this.props.clear(); }

    let {disabled, data, updateInput, tab, stateUUID, updateTab, updateData, handleFilterPanelState} = props;
    let sections = get(data, 'sections', []);
    let currentTab = tab < sections.length ? tab : 0;
    return (<div style={{height: '100%', overflowY: 'auto'}}>
        <Container fluid>
            <Breadcrumbs dashboardData={{sections, currentTab}} />
            <ConnectedInitializer>
                <Row className="offset-bottom-4"> <Col xs={12}>
                    <h2 className="text-center text-uppercase" >
                        {data.name} <Preloader expectAnswer={disabled} type="ICON" />
                        {!stateUUID ? null : (
                            <div style={{float: 'right'}}>
                                <WarningBtn tooltip={translate('GLOBALS$BACK')} onClick={history.back}>
                                    <i className="fa fa-reply" aria-hidden="true" />
                                </WarningBtn>
                            </div>
                        )}
                    </h2>
                </Col> </Row>
                <ConnectedError />
                <Dashboard
                    tab={tab}
                    data={data}
                    disabled={disabled}
                    updateTab={updateTab}
                    updateData={updateData}
                    updateInput={updateInput}
                    handleFilterPanelState={handleFilterPanelState}
                />
            </ConnectedInitializer>
        </Container>
    </div>);
}

export default connect(
    state => ({
        tab: state.dashboards.tab,
        data: state.dashboards.data,
        stateUUID: state.dashboards.stateUUID,
        disabled: state.dashboards.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        updateTab: tab => dispatch({type: EDIT.UPDATE_TAB, tab}),
        updateData: (data, itemIndex) => dispatch({type: EDIT.UPDATE_DATA, itemIndex, ...data}),
        updateInput: input => dispatch({type: EDIT.UPDATE_INPUT, input}),
        handleFilterPanelState: (itemIndex, isOpen) => dispatch({
            type: EDIT.HANDLE_FILTER_PANEL_STATE,
            itemIndex,
            isOpen
        }),
    })
)(DashboardPage);

const ConnectedInitializer = connect(
    state => ({initialize: state.dashboards.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.dashboards.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError} />
));

