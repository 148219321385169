export const NEW_ID = 'new';

export const AUDIT_ID = 'audit';

export const VARIABLE_CONSTANT = 'CONSTANT';
export const QUANT_METRIC_CONSTANT = 'QUANT_METRIC';
export const RISK_MODEL_CONSTANT = 'RISK_MODEL_CONSTANT';

export const DIRECTION = {
    RTL: 'RTL',
    LTR: 'LTR'
};

export const ASSESSMENT_LEVEL = {
    ORG: 1,
    SYSTEM: 2,
    PROCESS: 3,
    TECHNOLOGY: 4
};

export const QUANT_METRIC_LEVEL = {
    ORGANIZATION: 'Organization',
    SYSTEM: 'System',
    PROCESS: 'Process',
};

export const MENU_ITEM_TYPE = {
    ACTION: 'ACTION',
    LINK: 'LINK',
    MENU: 'MENU',
};

export const USER_RATE_TYPES = {
    DAILY: 'DAILY',
    ANNUAL: 'ANNUAL',
    HOURLY: 'HOURLY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

export const THRESHOLD_RISK_LEVELS = {
    'undefined': '',
    1: 'Low',
    2: 'Medium Low',
    3: 'Medium',
    4: 'Medium High',
    5: 'High',
};

// NONE, IG1, IG2, IG3
export const ASSESSMENT_FRAMEWORK_LEVEL = {
    'undefined': '',
    0: 'NONE',
    1: 'IG1',
    2: 'IG2',
    3: 'IG3',
};

// 'ON_PREMISE', 'CLOUD', 'HYBRID'
export const DEPLOYMENT_TYPES = {
    'undefined': '',
    'ON_PREMISE': 'SYSTEMS$ON_PREMISE',
    'CLOUD': 'SYSTEMS$CLOUD',
    'HYBRID': 'SYSTEMS$HYBRID',
};

// 'COTS', 'CUSTOM_COTS', 'HOME_GROWN', 'MOBILE'
export const SYSTEM_TYPES = {
    'undefined': '',
    'COTS': 'SYSTEMS$COTS',
    'CUSTOM_COTS': 'SYSTEMS$CUSTOM_COTS',
    'HOME_GROWN': 'SYSTEMS$HOME_GROWN',
    'MOBILE': 'SYSTEMS$MOBILE'
};

export const QUESTION_TYPES = {
    ORGANIZATION: {value: 'Organization', label: 'Organization'},
    SYSTEM: {value: 'System', label: 'System'},
    VENDOR: {value: 'Vendor', label: 'Vendor Level 1'},
    VENDOR_INTERNAL: {value: 'VendorInternal', label: 'Vendor Level 2'},
    CLOUD: {value: 'Cloud', label: 'Cloud Level 1'},
    CLOUD_INTERNAL: {value: 'CloudInternal', label: 'Cloud Level 2'},
    BOTH: {value: 'Both', label: 'Both (Organization & Vendor)'},
    GDPR_SYSTEM: {value: 'GDPRSystem', label: 'GDPR System'},
    GDPR_ORGANIZATION: {value: 'GDPROrganization', label: 'GDPR Organization'},
    CONTROL_TEST: {value: 'ControlTest', label: 'Control Test'},
    CMMC: {value: 'CMMC', label: 'CMMC'},
};

export const QUESTION_TYPES_LIST = [];
for (let key in QUESTION_TYPES) {
    QUESTION_TYPES_LIST.push(QUESTION_TYPES[key]);
}

export const QUESTION_TYPES_BY_CODE = {};
for (let key in QUESTION_TYPES) {
    QUESTION_TYPES_BY_CODE[QUESTION_TYPES[key].value] = QUESTION_TYPES[key];
}
QUESTION_TYPES_BY_CODE.getLabel = (code) => {
    return (code && QUESTION_TYPES_BY_CODE[code]) ? QUESTION_TYPES_BY_CODE[code].label : '';
};

export const CREDIT_RATINGS = [];

export const OPERATION_TYPES = {
    OPEN_BRACKET: '(',
    CLOSE_BRACKET: ')',
    MULTIPLY: '*',
    DIVIDE: '/',
    MINUS: '-',
    PLUS: '+',
    MAX: 'Max',
    MIN: 'Min',
    COMMA: ',',
    ABS: 'Abs',
    MEDIAN: 'Median',
    AVERAGE: 'Average',
    MODE: 'Mode',
    SQRT: '\u221a',
    SUM: '\u2211',
    RAND: 'Rand',
    POWER: 'Pow',
    EXPONENT: 'Exp',
    LOG: 'ln',
    HYPERBOLA: '1/x'
};

export const DASHBOARD_ITEMS_TYPE = {
    TEXT: 'Text',
    LINK: 'Link',
    TABLE: 'Table',
    INPUT: 'Input',
    BARCHART: 'BarChart',
    TEXTAREA: 'Textarea',
    PIE_CHART: 'PieChart',
    DATA_GRID: 'DataGrid',
    HEAT_CHART: 'HeatChart',
    GRID_LAYOUT: 'GridLayout',
    QLIK_ANALYTICS: 'iFrame',
};

export const VIEW_TYPES = {
    VENDOR_QUANTS_DOMAIN: 'vendor_quants_domain',
    SYSTEM_QUANTS_DOMAIN: 'system_quants_domain',
    VENDOR_QUALS_DOMAIN: 'vendor_quals_domain',
    SYSTEM_QUALS_DOMAIN: 'system_quals_domain',
    VENDOR_QUANTS: 'vendor_quants',
    SYSTEM_QUANTS: 'system_quants',
    VENDOR_QUALS: 'vendor_quals',
    SYSTEM_QUALS: 'system_quals',
};

export const DOWNLOAD_TYPES = {
    ASSESSMENT_FRAMEWORKS: {
        CSV_LIST: 'ASSESSMENT_FRAMEWORKS',
        CSV_TEMPLATE: 'ASSESSMENT_FRAMEWORKS_TEMPLATE'
    },
    BUSINESS_UNITS: {
        CSV_LIST: 'BUSINESS_UNITS',
        CSV_TEMPLATE: 'BUSINESS_UNITS_TEMPLATE',
    },
    CONTROL_GUIDELINES: {
        CSV_LIST: 'CONTROL_GUIDELINES',
        CSV_TEMPLATE: 'CONTROL_GUIDELINES_TEMPLATE'
    },
    GDPR_ARTICLES: {
        CSV_LIST: 'GDPR_ARTICLES',
        CSV_TEMPLATE: 'GDPR_ARTICLES_TEMPLATE'
    },
    PROCESSES: {
        CSV_LIST: 'PROCESSES',
        CSV_TEMPLATE: 'PROCESSES_TEMPLATE',
    },
    QUALITATIVE_QUESTION_ANSWERS: {
        CSV_LIST: 'QUALITATIVE_QUESTION_ANSWERS',                         //without {itemId}
        CSV_TEMPLATE: 'QUALITATIVE_QUESTION_ANSWERS_TEMPLATE'
    },
    QUALITATIVE_QUESTIONS: {
        CSV_LIST: 'QUALITATIVE_QUESTIONS',
        CSV_TEMPLATE: 'QUALITATIVE_QUESTIONS_TEMPLATE'
    },
    QUANT_METRIC: {
        CSV_LIST: 'QUANT_METRIC',
        CSV_TEMPLATE: 'QUANT_METRIC_TEMPLATE'
    },
    SCORING_QUESTION_ANSWERS: {
        CSV_LIST: 'SCORING_QUESTION_ANSWERS',
        CSV_TEMPLATE: 'SCORING_QUESTION_ANSWERS_TEMPLATE',
    },
    SCORING_QUESTIONS: {
        XLSX_REPORT: 'SCORING_QUESTIONS_XLSX_REPORT',
    },
    SECURITY_REQUIREMENTS: {
        CSV_LIST: 'SECURITY_REQUIREMENTS',
        CSV_TEMPLATE: 'SECURITY_REQUIREMENTS_TEMPLATE'
    },
    SUBSIDIARIES: {
        CSV_LIST: 'SUBSIDIARIES',
        CSV_TEMPLATE: 'SUBSIDIARIES_TEMPLATE'
    },
    SYSTEM_RISKS: {
        CSV_LIST: 'SYSTEM_RISKS',
        CSV_TEMPLATE: 'SYSTEM_RISKS_TEMPLATE'
    },
    TECHNOLOGIES: {
        CSV_LIST: 'TECHNOLOGIES',
        CSV_TEMPLATE: 'TECHNOLOGIES_TEMPLATE',
    },
    USERS: {
        CSV_LIST: 'USERS',
        CSV_TEMPLATE: 'USERS_TEMPLATE'
    },
    VENDORS: {
        CSV_LIST: 'VENDORS',
        CSV_TEMPLATE: 'VENDORS_TEMPLATE'
    },
};

export const ENTITY_TYPES = {
    ANSWER_WEIGHT: 'ANSWER_WEIGHT',
    ASSESSMENT_LEVEL: 'ASSESSMENT_LEVEL',
    ASSESSMENT_LEVELS: 'ASSESSMENT_LEVELS',
    ASSESSMENT_TYPES: 'ASSESSMENT_TYPES',
    ASSESSMENT_WEIGHTS: 'ASSESSMENT_WEIGHTS',
    ASSESSMENTS: 'ASSESSMENTS',
    ASSET_CLASSES: 'ASSET_CLASSES',
    BUSINESS_UNITS: 'BUSINESS_UNITS',
    CAPITAL_COST: 'CAPITAL_COST',
    CATEGORIES: 'CATEGORIES',
    CITIES: 'CITIES',
    CONTROL_CATEGORIES: 'CONTROL_CATEGORIES',
    CONTROL_FUNCTIONS: 'CONTROL_FUNCTIONS',
    CONTROL_MATURITIES: 'CONTROL_MATURITIES',
    CONTROL_SUBCATEGORIES: 'CONTROL_SUBCATEGORIES',
    COUNTRIES: 'COUNTRIES',
    COST_TYPES: 'COST_TYPES',
    CURRENCIES: 'CURRENCIES',
    CYBER_ROLES: 'CYBER_ROLES',
    DATA_TYPE_CLASSES: 'DATA_TYPE_CLASSES',
    DATA_DOMAINS: 'DATA_DOMAINS',
    DOMAINS: 'DOMAINS',
    ENVIRONMENT_TYPES: 'ENVIRONMENT_TYPES',
    GDPR_CHAPTERS: 'GDPR_CHAPTERS',
    GDPR_ARTICLES: 'GDPR_ARTICLES',
    INDUSTRIES: 'INDUSTRIES',
    LANGUAGES: 'LANGUAGES',
    LICENSE_TYPES: 'LICENSE_TYPES',
    METRIC_DOMAINS: 'METRIC_DOMAINS',
    OPERATIONAL_COST: 'OPERATIONAL_COST',
    PROCESSES: 'PROCESSES',
    POLICIES: 'POLICIES',
    VENDOR_CONTRACTS: 'VENDOR_CONTRACTS',
    QUAL: 'QUAL',
    QUANT_METRICS: 'QUANT_METRICS',
    QUANT_MODELS: 'QUANT_MODELS',
    QUESTIONS: 'QUESTIONS',
    QUESTIONS_WEIGHT: 'QUESTIONS_WEIGHT',
    RATE_TYPES: 'RATE_TYPES',
    REGULATIONS: 'REGULATIONS',
    RISK_TYPES: 'RISK_TYPES',
    ROLES: 'ROLES',
    SECURITY_CONTROL_FAMILIES: 'SECURITY_CONTROL_FAMILIES',
    SECURITY_CONTROL_NAMES: 'SECURITY_CONTROL_NAMES',
    SECURITY_FRAMEWORKS: 'SECURITY_FRAMEWORKS',
    SECURITY_REQUIREMENTS: 'SECURITY_REQUIREMENTS',
    SECURITY_TOOLS: 'SECURITY_TOOLS',
    STATES: 'STATES',
    STATUSES: 'STATUSES',
    SUBSIDIARIES: 'SUBSIDIARIES',
    SYSTEMS: 'SYSTEMS',
    TASKS: 'TASKS',
    TECH_CATEGORIES: 'TECH_CATEGORIES',
    TECHNOLOGIES: 'TECHNOLOGIES',
    VARIABLE_TYPES: 'VARIABLE_TYPES',
    VENDORS: 'VENDORS',
    USERS: 'USERS',
};

export const ROLES = {
    ORGADMIN: 'ORGADMIN',
    VNDSUP: 'VNDSUP',
    INFOSEC: 'INFOSEC',
    RISKMAN: 'RISKMAN',
    VENDOR: 'VENDOR',
    SYSOWN: 'SYSOWN',
    PRCOWN: 'PRCOWN',
    VNDOWN: 'VNDOWN',
    USER: 'USER',
    CISO: 'CISO',
    CEO: 'CEO',
    CRO: 'CRO',
    CLM: 'CLM',
    DPO: 'DPO',
    REM: 'REM',
    BUO: 'BUO',
    TSM: 'TSM',
    AUD: 'AUD',
    PM: 'PM',
};

export const STATUS = {
    UNPUBLISHED: 'UNPUBLISHED',
    PUBLISHED: 'PUBLISHED',
    APPROVED: 'APPROVED',
    DISABLED: 'DISABLED',
    PENDING: 'PENDING',
    REVOKED: 'REVOKED',
    DELETED: 'DELETED',
    ENABLED: 'ENABLED',
    ACTIVE: 'ACTIVE',
};

export const PAGES = {
    DATA_CLASSES: 'DATA_CLASSES'
    , SYSTEMS: 'SYSTEMS'
};

export const PERMISSION = {
    ORGANIZATION: {
        READ: 'organization_read',
        UPDATE: 'organization_update',
        RECORD_PRICE_LIMIT: 'organization_record_price_limit',
        AGGREGATE_INSURANCE_LIMIT: 'organization_aggregate_insurance_limit',
        CYBER_INSURANCE_DEDUCTIBLE: 'organization_cyber_insurance_deductible',
        AVERAGE_REVENUE: 'organization_average_revenue',
        MARKET_CAPITALIZATION: 'organization_market_capitalization',
        UPDATE_SUPPORTED_LANGUAGES: 'organization_supported_languages_update',
        CYBER_INSURANCE_INFORMATION: 'organization_cyber_insurance_information'
    },
    SUBSIDIARY: {
        READ: 'subsidiary_read',
        CREATE: 'subsidiary_create',
        UPDATE: 'subsidiary_update',
        DELETE: 'subsidiary_delete',
    },
    BUSINESS_UNIT: {
        READ: 'business_unit_read',
        CREATE: 'business_unit_create',
        UPDATE: 'business_unit_update',
        DELETE: 'business_unit_delete',
    },
    ASSET_CLASS: {
        READ: 'asset_class_read',
        CREATE: 'asset_class_create',
        UPDATE: 'asset_class_update',
        DELETE: 'asset_class_delete',
    },
    DATA_CLASS: {
        READ: 'data_class_read',
        CREATE: 'data_class_create',
        UPDATE: 'data_class_update',
        DELETE: 'data_class_delete',
    },
    DATA_DOMAIN: {
        READ: 'data_domain_read',
        CREATE: 'data_domain_create',
        UPDATE: 'data_domain_update',
        DELETE: 'data_domain_delete',
    },
    SYSTEM: {
        READ: 'system_read',
        CREATE: 'system_create',
        UPDATE: 'system_update',
        DELETE: 'system_delete',
    },
    PROCESS: {
        READ: 'process_read',
        CREATE: 'process_create',
        UPDATE: 'process_update',
        DELETE: 'process_delete',
    },
    TECHNOLOGY_CATEGORY: {
        READ: 'technology_category_read',
        CREATE: 'technology_category_create',
        UPDATE: 'technology_category_update',
        DELETE: 'technology_category_delete',
    },
    TECHNOLOGY: {
        READ: 'technology_read',
        CREATE: 'technology_create',
        UPDATE: 'technology_update',
        DELETE: 'technology_delete',
    },
    DATA_EXFILTRATION: {
        READ: 'data_exfiltration_read',
        UPDATE: 'data_exfiltration_update',
    },
    BUSINESS_INTERRUPTION: {
        READ: 'business_interruption_read',
        UPDATE: 'business_interruption_update',
    },
    REGULATORY_EXPOSURE: {
        READ: 'regulatory_exposure_read',
        UPDATE: 'regulatory_exposure_update',
    },
    RISK_SCORING: {
        READ: 'risk_scoring_read',
        QUESTION: 'risk_scoring_question',
    },
    ORGANIZATION_RISK_SCORING: {
        READ: 'organization_risk_scoring_read',
    },
    CYBERSECURITY_MATURITY: {
        READ: 'cybersecurity_maturity_read',
    },
    POLICY: {
        READ: 'policy_read',
        CREATE: 'policy_create',
        UPDATE: 'policy_update',
        DELETE: 'policy_delete',
        ANNUAL_REVIEW: 'policy_annual_review',
        APPROVAL: 'policy_approval',
    },
    VENDOR_CONTRACTS: {
        READ: 'contract_read',
        CREATE: 'contract_create',
        UPDATE: 'contract_update',
        DELETE: 'contract_delete',
    },
    GDPR_ORGANIZATION_COMPLIANCE: {
        READ: 'gdpr_organization_compliance_read',
        UPDATE: 'gdpr_organization_compliance_update',
        SCORING_QUESTION: 'gdpr_organization_compliance_scoring_question',
    },
    GDPR_SYSTEM_COMPLIANCE: {
        READ: 'gdpr_system_compliance_read',
        ARTICLE_READ: 'gdpr_system_article_compliance_read',
        SCORING_QUESTION: 'gdpr_system_compliance_scoring_question',
    },
    GDPR_EVIDENCE_DOCUMENT: {
        READ: 'gdpr_evidence_document_read',
        UPDATE: 'gdpr_evidence_document_update',
    },
    PRIVACY_IMPACT_ASSESSMENT: {
        READ: 'privacy_impact_assessment_read'
    },
    CATEGORY: {
        READ: 'category_read',
        CREATE: 'category_create',
        UPDATE: 'category_update',
    },
    CONSTANT: {
        READ: 'risk_model_constant_read',
        CREATE: 'risk_model_constant_create',
        UPDATE: 'risk_model_constant_update',
    },
    QUANTIFICATION_MODEL: {
        READ: 'quantification_model_read',
    },
    QUANTIFICATION_METRIC: {
        READ: 'quantification_metric_read',
        CREATE: 'quantification_metric_create',
        UPDATE: 'quantification_metric_update',
        DELETE: 'quantification_metric_delete',
    },
    QUALITATIVE_METRIC: {
        READ: 'qualitative_metric_read',
        CREATE: 'qualitative_metric_create',
        UPDATE: 'qualitative_metric_update',
    },
    QUALITATIVE_QUESTION: {
        READ: 'qualitative_question_read',
        CREATE: 'qualitative_question_create',
        UPDATE: 'qualitative_question_update',
        DELETE: 'qualitative_question_delete',
    },
    RISK_ANSWER: {
        READ: 'risk_answer_read',
        UPDATE: 'risk_answer_update',
    },
    RISK_METRIC: {
        READ: 'risk_metric_read',
        CREATE: 'risk_metric_create',
        UPDATE: 'risk_metric_update',
        DELETE: 'risk_metric_delete',
    },
    RISK_MODEL: {
        READ: 'risk_model_read',
        CREATE: 'risk_model_create',
        UPDATE: 'risk_model_update',
        DELETE: 'risk_model_delete',
        RECALCULATE_CACHE: 'risk_model_recalculate_cache',
    },
    ASSOCIATE_MODEL: {
        READ: 'associate_model_read',
        CREATE: 'associate_model_create',
        UPDATE: 'associate_model_update',
        DELETE: 'associate_model_delete',
    },
    ASSESSMENT: {
        READ: 'assessment_read',
        CREATE: 'assessment_create',
        UPDATE: 'assessment_update',
        DELETE: 'assessment_delete',
    },
    ASSESSMENT_TYPE: {
        READ: 'assessment_type_read',
        CREATE: 'assessment_type_create',
        UPDATE: 'assessment_type_update',
        DELETE: 'assessment_type_delete',
    },
    SECURITY_REQUIREMENT: {
        READ: 'security_requirement_read',
        CREATE: 'security_requirement_create',
        UPDATE: 'security_requirement_update',
        DELETE: 'security_requirement_delete',
    },
    NIST_CSF_CATEGORY: {
        READ: 'nist_csf_category_read',
        CREATE: 'nist_csf_category_create',
        UPDATE: 'nist_csf_category_update',
        DELETE: 'nist_csf_category_delete',
    },
    CONTROL_TEST: {
        READ: 'control_test_read',
        CREATE: 'control_test_create',
        UPDATE: 'control_test_update',
        DELETE: 'control_test_delete',
    },
    CONTROL_GUIDELINE: {
        READ: 'control_guideline_read',
        CREATE: 'control_guideline_create',
        UPDATE: 'control_guideline_update',
        DELETE: 'control_guideline_delete',
    },
    CONTROL_MATURITY: {
        READ: 'control_maturity_read',
        CREATE: 'control_maturity_create',
        UPDATE: 'control_maturity_update',
        DELETE: 'control_maturity_delete',
    },
    CONTROL_TEST_RESULT: {
        READ: 'control_test_result_read',
        UPDATE: 'control_test_result_update',
    },
    SYSTEM_CONTROL_TEST_RESULT: {
        READ: 'system_control_test_result_read',
        UPDATE: 'system_control_test_result_update',
        DRILLDOWN: 'system_control_test_result_drilldown',
    },
    ASSESSMENT_FINDING: {
        READ: 'assessment_finding_read',
        CREATE: 'assessment_finding_create',
        UPDATE: 'assessment_finding_update',
        DELETE: 'assessment_finding_delete',
    },
    DOMAIN: {
        READ: 'domain_read',
        CREATE: 'domain_create',
        UPDATE: 'domain_update',
        DELETE: 'domain_delete',
    },
    VULNERABILITY: {
        READ: 'vulnerability_read',
        CREATE: 'vulnerability_create',
        UPDATE: 'vulnerability_update',
        DELETE: 'vulnerability_delete',
    },
    VENDOR: {
        READ: 'vendor_read',
        CREATE: 'vendor_create',
        UPDATE: 'vendor_update',
        DELETE: 'vendor_delete',
        SCORING_QUESTION: 'vendor_scoring_question',
        INTERNAL_QUESTION: 'vendor_internal_question'
    },
    CLOUD_SCORING: {
        READ: 'cloud_scoring_read',
        QUESTION: 'cloud_scoring_question',
        INTERNAL_QUESTION: 'cloud_internal_question',
    },
    ASSOCIATE_SYSTEM: {
        READ: 'associate_system_read',
        UPDATE: 'associate_system_update',
    },
    TASKS: {
        READ: 'task_read',
        CREATE: 'task_create',
        UPDATE: 'task_update',
        DELETE: 'task_delete',
    },
    TASK_CATEGORIES: {
        READ: 'task_category_read',
        CREATE: 'task_category_create',
        UPDATE: 'task_category_update',
        DELETE: 'task_category_delete',
    },
    MY_TASKS: {
        READ: 'my_task_read',
        CREATE: 'my_task_create',
        UPDATE: 'my_task_update',
        DELETE: 'my_task_delete',
    },
    SECURITY_TOOL: {
        READ: 'security_tool_read',
        CREATE: 'security_tool_create',
        UPDATE: 'security_tool_update',
        DELETE: 'security_tool_delete',
    },
    CYBER_SECURITY_TOOL: {
        READ: 'cyber_security_tool_read',
        CREATE: 'cyber_security_tool_create',
        UPDATE: 'cyber_security_tool_update',
        DELETE: 'cyber_security_tool_delete',
    },
    CYBER_ROLE: {
        READ: 'cyber_role_read',
        CREATE: 'cyber_role_create',
        UPDATE: 'cyber_role_update',
        DELETE: 'cyber_role_delete',
    },
    OPERATIONAL_COST: {
        READ: 'operational_cost_read',
        CREATE: 'operational_cost_create',
        UPDATE: 'operational_cost_update',
        DELETE: 'operational_cost_delete',
    },
    CAPITAL_COST: {
        READ: 'capital_cost_read',
        CREATE: 'capital_cost_create',
        UPDATE: 'capital_cost_update',
        DELETE: 'capital_cost_delete',
    },
    VARIABLE_COST: {
        READ: 'variable_cost_read',
        CREATE: 'variable_cost_create',
        UPDATE: 'variable_cost_update',
        DELETE: 'variable_cost_delete',
    },
    SYSTEM_RISK_REDUCTION: {
        READ: 'system_risk_reduction_read',
        UPDATE: 'system_risk_reduction_update',
    },
    USER_SETTINGS: {
        READ: 'user_settings_read',
        UPDATE: 'user_settings_update',
    },
    USER: {
        READ: 'user_read',
        CREATE: 'user_create',
        UPDATE: 'user_update',
        DELETE: 'user_delete',
    },
    AUDIT_LOG: {
        READ: 'audit_log_read',
        DETAILS: 'audit_log_details',
    },
    CONTRACT: {
        READ: 'contract_read',
        CREATE: 'contract_create',
        UPDATE: 'contract_update',
        DELETE: 'contract_delete',
    },
    EXPORT: {
        ASSESSMENT_FRAMEWORK: 'export_assessment_framework',
        BUSINESS_UNIT: 'export_business_unit',
        BUSINESS_UNIT_TEMPLATE: 'export_business_unit_template',
        CONTROL_GUIDELINE: 'export_control_guideline',
        CONTROL_TEST_RESULTS: 'export_control_test_results',
        GDPR_ARTICLE: 'export_gdpr_article',
        QUALITATIVE_QUESTION: 'export_qualitative_question',
        QUALITATIVE_QUESTION_ANSWERS: 'export_qualitative_question_answers',
        PROCESS: 'export_process',
        PROCESS_TEMPLATE: 'export_process_template',
        SECURITY_REQUIREMENTS: 'export_security_requirements',
        SUBSIDIARY: 'export_subsidiary',
        SYSTEM_RISK: 'export_system_risk',
        TECHNOLOGY: 'export_technology',
        TECHNOLOGY_TEMPLATE: 'export_technology_template',
        USER: 'export_user',
        VENDOR: 'export_vendor',
        QUANT_METRIC: 'quantification_metric_export',
    },
    IMPORT: {
        ASSESSMENT_FRAMEWORK: 'import_assessment_framework',
        BUSINESS_UNIT: 'import_business_unit',
        CONTROL_GUIDELINE: 'import_control_guideline',
        CONTROL_TEST_RESULTS: 'import_control_test_results',
        GDPR_ARTICLE: 'import_gdpr_article',
        GDPR_EVIDENCE_ARTICLES: 'import_gdpr_evidence_articles',
        PROCESS: 'import_process',
        QUALITATIVE_QUESTION: 'import_qualitative_question',
        QUALITATIVE_QUESTION_ANSWERS: 'import_qualitative_question_answers',
        SECURITY_REQUIREMENTS: 'import_security_requirements',
        SUBSIDIARY: 'import_subsidiary',
        SYSTEM_RISK: 'import_system_risk',
        TECHNOLOGY: 'import_technology',
        USER: 'import_user',
        VENDOR: 'import_vendor',
        QUANT_METRIC: 'quantification_metric_import',
    }
};
