// outsource dependencies
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {Field, FieldArray, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import {MdSelect} from '../../../components/md-select';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import TasksManagement from '../../../components/tasks-management';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {VULNERABILITIES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from "../../../components/hints/hints";
import ErrorMessage from "../../../components/alert-error";

// config
export const FORM_NAME = 'editVulnerability';

const Edit = (props, {expectAnswer}) => {
    let params = useParams();
    useEffect(() => {
        props.initialize(params.id);
        return () => {
            props.clear();
        }
    }, []);

    let isNew = params.id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={VULNERABILITIES_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:8,offset:2}}>
                        <Paper className="indent-5">
                            <RichHintTitle update={EDIT} name={isNew ? `VULNERABILITIES$CREATE_VULNERABILITY` : `VULNERABILITIES$EDIT_VULNERABILITY`} expectAnswer={expectAnswer} data={findHint(hints,`VULNERABILITIES_TITLE`)}/>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    )
};

export default connect(
    state => ({...state.vulnerabilities.edit, hints: state.vulnerabilities.edit.hintsData}),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.vulnerabilities.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.vulnerabilities.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.vulnerabilities.edit.data,
        disabled: state.vulnerabilities.edit.expectAnswer,
        hints: state.vulnerabilities.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: values => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // percentage
        if (values.percentage && (values.percentage < 0 || values.percentage > 100)) {
            errors.percentage = 'GLOBALS$PERCENTAGE_ERROR';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, hints})=>(
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="code"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$CODE')}
                    label={(<strong> {translate('GLOBALS$CODE')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="status"
                    labelKey="value"
                    valueKey="value"
                    disabled={disabled}
                    component={MdSelect}
                    sendValueProp
                    options={['OPEN', 'CLOSED'].map(option=>{
                        return ({
                            label: option ,
                            value: option
                        })
                    })}
                    placeholder={translate('GLOBALS$STATUS')}
                    simpleValue={(value) => { if (value) return {value: value, label: value} }}
                    optionsLabel={({value}) => value}
                    label={(<strong> {translate('GLOBALS$STATUS')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    type="number"
                    name="percentage"
                    disabled={disabled}
                    component={MdInput}
                    placeholder={translate('GLOBALS$PERCENTAGE')}
                    label={(<strong> {translate('GLOBALS$PERCENTAGE')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    type="number"
                    name="score"
                    disabled={disabled}
                    component={MdInput}
                    placeholder={translate('VULNERABILITIES$SCORE')}
                    label={(<strong> {translate('VULNERABILITIES$SCORE')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="offset-bottom-4">
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="technologyCategories"
                    type={ENTITY_TYPES.TECH_CATEGORIES}
                    placeholder={translate('TECHNOLOGY_CATEGORIES$TITLE')}
                    label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TITLE')} </strong>)}
                        />
            </Col>
            <Col xs={12} className="offset-bottom-4">
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="technologies"
                    type={ENTITY_TYPES.TECHNOLOGIES}
                    placeholder={translate('TECHNOLOGIES$TITLE')}
                    label={(<strong> {translate('TECHNOLOGIES$TITLE')} </strong>)}
                    getOptionLabel={option => option.name + (option.version ? `(${option.version})` : '') }
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-8"><Col xs={12}> <FieldArray name="tasks" component={TasksManagement} /> </Col></Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_VULNERABILITIES_CREATE' : 'BUTTON_VULNERABILITIES_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_VULNERABILITIES_RESET')} />
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_VULNERABILITIES_CANCEL')} />
            </Col>
        </Row>
    </form>
))));
