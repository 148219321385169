// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {
    Card, CardActions, CardContent,
    CardHeader, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {AUDIT_LOGS, SYSTEMS} from '../../../constants/routes';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {instanceAPI} from '../../../services/api.service';
import MdTablePagination from '../../../components/pagination';
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import {PERMISSION, ROLES, STATUS} from '../../../constants/spec';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {AddBtn, DelBtn, DetailsBtn, EditBtn, Link, PrimaryBtn, WarningBtn} from '../../../components/md-button';
import {SimpleAsyncSelect, SimpleSelect} from '../../../components/md-select';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import SearchFilter, {SimpleSearchField} from '../../../components/search-filter';
import {SYSTEMS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";
import {
    CheckCircle, Close,
    Cloud,
    Delete,
    Dvr,
    Home,
    MergeType,
    RadioButtonChecked,
    StoreMallDirectory
} from '@mui/icons-material';

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list, hints } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ SYSTEMS_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <RichHintTitle update={LIST}  name={'SYSTEMS$TITLE'} expectAnswer={expectAnswer} data={findHint(hints, 'SYSTEMS_TITLE')}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} placement="left" to={SYSTEMS.LINK_EDIT()} permission={PERMISSION.SYSTEM.CREATE} hint = {findHint(hints, 'BUTTON_SYSTEMS_ADD')} />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4"><Col xs={12}> <FiltersPanelConnected /> </Col></Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')}  </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.systems.list.expectAnswer, list: state.systems.list.data, hints: state.systems.list.hintsData}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.systems.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = withTranslation(connect(
    state => ({showAdvanced: state.systems.list.showAdvanced, hints: state.systems.list.hintsData}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => {
            dispatch({type: LIST.META, showAdvanced: true});
            dispatch({type: LIST.UPDATE_DATA, filter: ''});
        },
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ({showAdvanced, update, openAdvanced, closeAdvanced, hints}) => (<div>
    { showAdvanced ? (
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Card style={{overflow: 'visible'}}>
                    <CardHeader
                        title={translate('GLOBALS$ADVANCED_SEARCH')}
                        action={
                            <Tooltip title={translate('GLOBALS$CLOSE')}>
                                <IconButton aria-label={translate('GLOBALS$CLOSE')} onClick={closeAdvanced}>
                                    <Close fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }
                    />
                    <CardContent> <SearchForm /> </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn onClick={update} tooltip={translate('GLOBALS$APPLY')} hint={findHint(hints, 'BUTTON_SYSTEMS_ADVANCED_SEARCH_APPLY')}> {translate('GLOBALS$APPLY')}  </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn onClick={closeAdvanced} tooltip={translate('GLOBALS$CLOSE')} hint={findHint(hints, 'BUTTON_SYSTEMS_ADVANCED_SEARCH_CLOSE')}> {translate('GLOBALS$CLOSE')} </WarningBtn>
                    </CardActions>
                </Card>
            </Col>
        </Row>
    ) : (
        <Row>
            <Col xs={12} sm={6} lg={3} className="offset-bottom-2">
                <SearchFilterConnected />
            </Col>
            <Col xs={12} sm={6}>
                <PrimaryBtn hint={findHint(hints, 'BUTTON_SYSTEMS_ADVANCED_SEARCH')} onClick={openAdvanced} tooltip={translate('GLOBALS$ADVANCED_SEARCH')} > {translate('GLOBALS$ADVANCED_SEARCH')} </PrimaryBtn>
            </Col>
        </Row>
    )}
</div>)));

/*
const FiltersPanelConnected = connect(
    state => ({showAdvanced:  state.systems.list.showAdvanced}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => dispatch({type: LIST.META, showAdvanced: true}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ( props) => (
    <FiltersPanel SearchForm={SearchForm} SearchFilter={SearchFilterConnected} {...props} />
));
*/
/**
 * search form
 *
 * @public
 */
const SearchForm = withTranslation(connect(
    state => ({ ...state.systems.list }),
    dispatch => ({ changeFilterValue: data => dispatch({type: LIST.META, ...data}) })
)( ({ expectAnswer, filter, systemStatus, businessUnit, assetClass, dataType, systemOwner, changeFilterValue }) => (<div>
    <Row>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleSearchField
                value={filter}
                disabled={expectAnswer}
                clear={() => changeFilterValue({filter: ''})}
                onChange={e => changeFilterValue({filter: e.target.value})}
                    />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleSelect
                value={systemStatus}
                valueKey="value"
                labelKey="label"
                isClearable={true}
                disabled={expectAnswer}
                placeholder={translate('GLOBALS$STATUS')}
                label={(<strong> {translate('GLOBALS$STATUS')} </strong>)}
                sendValueProp
                options={[STATUS.ACTIVE, STATUS.DISABLED, STATUS.PENDING].map(option=>{
                    return ({
                        label: option ,
                        value: option
                    })
                })}
                simpleValue={(value) => { return {value: value, label: value} }}
                onChange={systemStatus => {changeFilterValue({systemStatus})}}
                    />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={businessUnit}
                disabled={expectAnswer}
                placeholder={translate('BUSINESS_UNITS$BUSINESS_UNIT')}
                getOptionLabel={item => formatBusinessUnitLabel(item)}
                onChange={businessUnit => changeFilterValue({businessUnit})}
                label={(<strong> {translate('BUSINESS_UNITS$BUSINESS_UNIT')} </strong>)}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'business-units/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
    <Row>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={assetClass}
                disabled={expectAnswer}
                placeholder={translate('ASSET_CLASSES$ASSET_CLASS')}
                onChange={assetClass => changeFilterValue({assetClass})}
                label={(<strong> {translate('ASSET_CLASSES$ASSET_CLASS')} </strong>)}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'data-asset-classifications/filter', data: { filter: {name} }})
                    .then(({items}) => {done(items)
                        console.log(items)}).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                value={dataType}
                isClearable={true}
                disabled={expectAnswer}
                placeholder={translate('DATA_CLASSES$DATA_CLASS')}
                onChange={dataType => changeFilterValue({dataType})}
                label={(<strong> {translate('DATA_CLASSES$DATA_CLASS')} </strong>)}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'data-type-classifications/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                valueKey="id"
                labelKey="fullName"
                value={systemOwner}
                isClearable={true}
                disabled={expectAnswer}
                placeholder={translate('GLOBALS$OWNER')}
                label={(<strong> {translate('GLOBALS$OWNER')} </strong>)}
                onChange={systemOwner => changeFilterValue({systemOwner})}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: `/users/filter`, data: {filter: {name, roles: [ROLES.SYSOWN]}} })
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
</div>)));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter:  state.systems.list.filter,
        disabled: state.systems.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
    />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.systems.list, hints: state.systems.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('SYSTEMS$SYSTEM_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" style={{minWidth: '300px'}}>
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('description')}
                                >
                            {translate('SYSTEMS$SYSTEM_DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'businessUnit'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('businessUnit')}
                                >
                            {translate('BUSINESS_UNITS$BUSINESS_UNIT')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            direction={sortD ? 'asc' : 'desc'}
                            active={sortF === 'dataAssetClassification'}
                            onClick={()=>changeSort('dataAssetClassification')}
                                >
                            {translate('ASSET_CLASSES$ASSET_CLASS')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            direction={sortD ? 'asc' : 'desc'}
                            active={sortF === 'owner'}
                            onClick={()=>changeSort('owner')}
                                >
                            {translate('SYSTEMS$SYSTEM_OWNER_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>
                        {
                            item.systemStatus === 'ACTIVE'
                            ? <Tooltip title={translate(item.systemStatus)} className="offset-bottom-1"><CheckCircle style={{color:"#009703" }} className="align-middle" /></Tooltip>
                            : <Tooltip title={translate(item.systemStatus)} className="offset-bottom-1"><Delete style={{color:"#fe0000"}} className="align-middle" /></Tooltip>
                        }
                        &nbsp;
                        {buildSystemTypeIcon(item.systemType)}
                        &nbsp;
                        {item.name}
                    </TableCell>
                    <AbbrevTableCell content={item.description} title={item.name} length={40}/>
                    <TableCell>{formatBusinessUnitLabel(get(item, 'businessUnit'))}</TableCell>
                    <TableCell>{get(item, 'dataAssetClassification.name')}</TableCell>
                    <TableCell>{get(item, 'owner.fullName')}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={DetailsBtn}
                            tooltip={translate('GLOBALS$LOG')}
                            to={AUDIT_LOGS.SYSTEM_LINK({
                                id: item.id,
                            })}
                            hint = {findHint(hints, 'BUTTON_SYSTEMS_DETAILS')}
                        >
                        </Link>
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.SYSTEM.UPDATE}
                            to={SYSTEMS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            hint = {findHint(hints, 'BUTTON_SYSTEMS_EDIT')}
                            className="offset-left-2"
                                />
                        <DelBtn permission={PERMISSION.SYSTEM.DELETE} onClick={() => deleteItem(item.id)} className="offset-left-2" hint = {findHint(hints, 'BUTTON_SYSTEMS_DELETE')}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));

const ConnectedError = connect(
    state => ({message: state.systems.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

function buildSystemTypeIcon(systemType) {
    switch (systemType) {
        case 'ON_PREMISE':
            return <Tooltip title={systemType} className="offset-bottom-1"><StoreMallDirectory  style={{color:"#337ab7" }}  color="#337ab7" className="align-middle" /></Tooltip>;
        case 'CLOUD':
            return <Tooltip title={systemType} className="offset-bottom-1"><Cloud  style={{color:"#337ab7" }} color="#337ab7" className="align-middle" /></Tooltip>;
        case 'HOME_GROWN':
            return <Tooltip title={systemType} className="offset-bottom-1"><Home  style={{color:"#337ab7" }} color="#337ab7" className="align-middle" /></Tooltip>;
        case 'COTS':
            return <Tooltip title={systemType} className="offset-bottom-1"><Dvr  style={{color:"#337ab7" }} color="#337ab7" className="align-middle" /></Tooltip>;
        case 'MA':
            return <Tooltip title={systemType} className="offset-bottom-1"><MergeType  style={{color:"#337ab7" }}  color="#337ab7" className="align-middle" /></Tooltip>;
        default:
            return <Tooltip title={systemType} className="offset-bottom-1"><RadioButtonChecked  style={{color:"#337ab7" }}   className="align-middle" /></Tooltip>;
    }
}
