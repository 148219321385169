// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {
    Card, CardActions, CardContent,
    CardHeader, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {PERMISSION, ROLES} from '../../../constants/spec';
import {instanceAPI} from '../../../services/api.service';
import MdTablePagination from '../../../components/pagination';
import {CYBER_RISK_SCORING} from '../../../constants/routes';
import {SimpleAsyncSelect} from '../../../components/md-select';
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import REASSIGN_DIALOG from '../../../components/reassign-dialog/actions';
import {Link, PrimaryBtn, ReassignBtn, ScoringQuestionsBtn, WarningBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import SearchFilter, {SimpleSearchField} from '../../../components/search-filter';
import {CYBER_RISK_SCORING_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";
import {Close} from '@mui/icons-material';
class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { data, message, expectAnswer, clearError, hints } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ CYBER_RISK_SCORING_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <RichHintTitle update={LIST}  name={`CYBER_RISK_SCORING$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `CYBER_RISK_SCORING_TITLE`)}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4"><Col xs={12}> <FiltersPanelConnected /> </Col></Row>
                    <ErrorMessage active message={message} onChange={clearError}/>
                    { data.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        data: state.cyberRiskScoring.list.data,
        hints: state.cyberRiskScoring.list.hintsData,
        message: state.cyberRiskScoring.list.errorMessage,
        expectAnswer: state.cyberRiskScoring.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.cyberRiskScoring.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));


/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = withTranslation(connect(
    state => ({showAdvanced: state.cyberRiskScoring.list.showAdvanced, hints: state.cyberRiskScoring.list.hintsData}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => {
            dispatch({type: LIST.META, showAdvanced: true});
            dispatch({type: LIST.UPDATE_DATA, filter: ''});
        },
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ({showAdvanced, update, openAdvanced, closeAdvanced, hints}) => (<div>
    { showAdvanced ? (
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Card style={{overflow: 'visible'}}>
                    <CardHeader
                        title={translate('GLOBALS$ADVANCED_SEARCH')}
                        action={
                            <Tooltip title={translate('GLOBALS$CLOSE')}>
                                <IconButton aria-label={translate('GLOBALS$CLOSE')} onClick={closeAdvanced}>
                                    <Close fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }
                    />
                    <CardContent> <SearchForm /> </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn onClick={update} tooltip={translate('GLOBALS$APPLY')} hint={findHint(hints, 'BUTTON_CYBER_RISK_SCORING_ADVANCED_SEARCH_APPLY')}> {translate('GLOBALS$APPLY')}  </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn onClick={closeAdvanced} tooltip={translate('GLOBALS$CLOSE')} hint={findHint(hints, 'BUTTON_CYBER_RISK_SCORING_ADVANCED_SEARCH_CLOSE')}> {translate('GLOBALS$CLOSE')} </WarningBtn>
                    </CardActions>
                </Card>
            </Col>
        </Row>
    ) : (
        <Row>
            <Col xs={12} sm={6} lg={3} className="offset-bottom-2">
                <SearchFilterConnected />
            </Col>
            <Col xs={12} sm={6}>
                <PrimaryBtn hint={findHint(hints, 'BUTTON_CYBER_RISK_SCORING_ADVANCED_SEARCH')} onClick={openAdvanced} tooltip={translate('GLOBALS$ADVANCED_SEARCH')} > {translate('GLOBALS$ADVANCED_SEARCH')} </PrimaryBtn>
            </Col>
        </Row>
    )}
</div>)));


/*
const FiltersPanelConnected = connect(
    state => ({showAdvanced:  state.cyberRiskScoring.list.showAdvanced}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => dispatch({type: LIST.META, showAdvanced: true}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ( props) => (
    <FiltersPanel SearchForm={SearchForm} SearchFilter={SearchFilterConnected} {...props} />
));
*/
/**
 * search form
 *
 * @public
 */
const SearchForm = withTranslation(connect(
    state => ({ ...state.cyberRiskScoring.list }),
    dispatch => ({ changeFilterValue: data => dispatch({type: LIST.META, ...data}) })
)( ({ expectAnswer, filter, systemOwner, businessUnit, assetClass, dataType, changeFilterValue }) => (<div>
    <Row>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleSearchField
                value={filter}
                disabled={expectAnswer}
                clear={() => changeFilterValue({filter: ''})}
                onChange={e => changeFilterValue({filter: e.target.value})}
                    />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={systemOwner}
                labelKey="fullName"
                disabled={expectAnswer}
                placeholder={translate('GLOBALS$OWNER')}
                label={(<strong> {translate('GLOBALS$OWNER')} </strong>)}
                onChange={systemOwner => changeFilterValue({systemOwner})}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: `/users/filter`, data: {filter: {name, roles: [ROLES.SYSOWN]}} })
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={businessUnit}
                disabled={expectAnswer}
                getOptionLabel={item => formatBusinessUnitLabel(item)}
                placeholder={translate('BUSINESS_UNITS$BUSINESS_UNIT')}
                onChange={businessUnit => changeFilterValue({businessUnit})}
                label={(<strong> {translate('BUSINESS_UNITS$BUSINESS_UNIT')} </strong>)}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'business-units/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
    <Row>
        <Col xs={12} md={6} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={assetClass}
                disabled={expectAnswer}
                placeholder={translate('ASSET_CLASSES$ASSET_CLASS')}
                label={(<strong> {translate('ASSET_CLASSES$ASSET_CLASS')} </strong>)}
                onChange={assetClass => changeFilterValue({assetClass})}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'data-asset-classifications/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} md={6} className="offset-bottom-4">
            <SimpleAsyncSelect
                value={dataType}
                isClearable={true}
                disabled={expectAnswer}
                placeholder={translate('DATA_CLASSES$DATA_CLASS')}
                label={(<strong> {translate('DATA_CLASSES$DATA_CLASS')} </strong>)}
                onChange={dataType => changeFilterValue({dataType})}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'data-type-classifications/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
</div>)));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.cyberRiskScoring.list.filter,
        disabled: state.cyberRiskScoring.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
    />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.cyberRiskScoring.list, hints: state.cyberRiskScoring.list.hintsData,}),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
        openReassignModal: item => dispatch({type: REASSIGN_DIALOG.INITIALIZE, item}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort, openReassignModal }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" style={{minWidth: '300px'}}>
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('description')}
                                >
                            {translate('GLOBALS$DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'businessUnit'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('businessUnit')}
                                >
                            {translate('BUSINESS_UNITS$BUSINESS_UNIT')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            direction={sortD ? 'asc' : 'desc'}
                            active={sortF === 'dataAssetClassification'}
                            onClick={()=>changeSort('dataAssetClassification')}
                                >
                            {translate('ASSET_CLASSES$ASSET_CLASS')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            direction={sortD ? 'asc' : 'desc'}
                            active={sortF === 'owner'}
                            onClick={()=>changeSort('owner')}
                                >
                            {translate('GLOBALS$OWNER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.name }</TableCell>
                    <AbbrevTableCell content={item.description} title={item.name} length={40}/>
                    <TableCell>{formatBusinessUnitLabel(get(item, 'businessUnit'))}</TableCell>
                    <TableCell>{get(item, 'dataAssetClassification.name')}</TableCell>
                    <TableCell>{get(item, 'owner.fullName')}</TableCell>
                    <TableCell className="text-nowrap" align="right">
                        {!item.allowedReassign ? '' :(
                            <ReassignBtn
                                className="offset-right-2"
                                tooltip={translate('REASSIGN$REASSIGN')}
                                onClick={()=>openReassignModal(item)}
                                hint={findHint(hints, 'BUTTON_CYBER_RISK_SCORING_REASSIGN')}
                                    >
                            </ReassignBtn>
                        )}
                        <Link
                            Btn={ScoringQuestionsBtn}
                            className="offset-right-2 md-btn md-btn-warning"
                            tooltip={translate('SCORING_QUESTIONS$TITLE')}
                            permission={PERMISSION.RISK_SCORING.QUESTION}
                            to={CYBER_RISK_SCORING.LINK_QUESTIONS({id: item.id, query: {back: history.location.pathname + history.location.search}})}
                            hint={findHint(hints, 'BUTTON_CYBER_RISK_SCORING_QUESTIONS')}
                                >
                        </Link>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));
