
// outsource dependencies
import { get } from 'lodash';
import { reset } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { call, put, select, takeEvery } from 'redux-saga/effects';

// local dependencies
import DIALOG from './actions';
import { instanceAPI } from '../../services/api.service';
import getHintsList, {REASSIGN_DIALOG_HINTS} from '../../services/hints.service';

// configure

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(DIALOG.CANCEL, cancelSaga);
    yield takeEvery(DIALOG.REASSIGN, reassignSaga);
    yield takeEvery(DIALOG.INITIALIZE, initializeSaga);
    yield takeEvery(DIALOG.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({item}) {
    yield put({type: DIALOG.CLEAR});
    yield put({type: DIALOG.META, showModal: true});
    yield put({type: DIALOG.DATA, data: {item, user: get(item, 'owner', null)}});
    yield put({type: DIALOG.META, initialized: true});
    yield put({type: DIALOG.GET_HINTS_DATA.REQUEST});
}

function* reassignSaga ({type, ...options}) {
    yield put({type: DIALOG.META, expectAnswer: true});
    try {
        let { riskModel } = yield select( state => state.app );
        yield call(reassign, options, riskModel.id);
        yield put({type: DIALOG.CANCEL});
        yield call(toastr.success, 'Reassign', 'Reassign has been implemented successfully');
    } catch ( {message} ) {
        yield put({type: DIALOG.META, errorMessage: message, expectAnswer: false});
    }
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, REASSIGN_DIALOG_HINTS);

        // NOTE setup hints data
        yield put({type: DIALOG.META, hintsData});
    } catch ( {message} ) {
        yield put({type: DIALOG.META, errorMessage: message});
    }
    yield put({type: DIALOG.GET_HINTS_DATA.FINISH});
}

function* cancelSaga () {
    yield put({type: DIALOG.CLEAR});
    yield put(reset('reassignForm'));
}

/**
 * reassign person which responsible to answer the questions
 * @param {Object} data
 * @param {Number} riskModelId
 * @private
 */
function reassign (data, riskModelId) {
    return instanceAPI({
        method: 'post',
        data: { itemType: get(data, 'item.itemType'), ...data },
        url: `risk-model/${riskModelId}/cyber-risk-scoring/reassign-to-user`,
    })
}
