
// outsource dependencies
import get from 'lodash/get';
import { change } from 'redux-form';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { historyPush } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import { RISK_ANSWERS } from '../../../constants/routes';
import { instanceAPI } from '../../../services/api.service';
import is from '../../../services/is.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.GET_ANSWERS, getAnswersSaga);
}

function* initializeSaga ({type, id}) {
    yield put({type: EDIT.CLEAR});
    try {
        // get riskModel
        let { riskModel } = yield select( state => state.app );
        let data = yield call(getData, id, riskModel.id,);
        yield put({type: EDIT.DATA, ...data});
        // NOTE prepare data for questions
        let questionId = get(data, 'question.id');
        if ( questionId ) {
            yield put({type: EDIT.GET_ANSWERS, id: questionId, clear: false});
        }
        yield put({type: EDIT.META, initialized: true});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        // get riskModel
        let { riskModel } = yield select( state => state.app );
        let data = yield call(updateData, riskModel.id, options);
        yield put({type: EDIT.DATA, ...data});
        yield put({type: EDIT.META, expectAnswer: false});
        // go to list
        yield call(historyPush, RISK_ANSWERS.LINK());
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* getAnswersSaga ({id, clear=true}) {
    // NOTE clear answers
    yield put({type: EDIT.META, expectAnswer: true, answers: []});
    // NOTE clear answer field when changes question and form is initialized
    if ( clear ) {
        yield put(change('editAnswer', 'answer', ''));
    }
    try {
        // get riskModel
        let { riskModel } = yield select( state => state.app );
        let { answers } = yield call(getQuestionById, id, riskModel.id);
        yield put({type: EDIT.META, answers, expectAnswer: false});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

/**
 * get risk answer by id
 * @param {Number|String} id
 * @param {String|Number} riskModelId
 * @private
 */
function getData ( id, riskModelId ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/risk-models/${riskModelId}/answers/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update risk answer
 * @param {String|Number} riskModelId
 * @param {Object} data
 * @private
 */
function updateData ( riskModelId, data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: `/risk-models/${riskModelId}/answers`, data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: `/risk-models/${riskModelId}/answers`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * get qual question by id
 *
 * @param {String|Number} id
 * @param {String|Number} riskModelId
 * @public
 */
function getQuestionById ( id, riskModelId ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/risk-model/${riskModelId}/qualitative-questions/${id}`})
            .then( success => {
                // NOTE sort question answers by name
                (success.answers || []).sort((a, b) => {
                    let x = is.string(a.answer) ? a.answer.toLowerCase() : '';
                    let y = is.string(b.answer) ? b.answer.toLowerCase() : '';
                    return x < y ? -1 : x > y ? 1 : 0;
                });
                resolve(success);
            }).catch(reject);
    });
}
