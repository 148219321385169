// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {Field, reduxForm} from 'redux-form';

// local dependencies
import {QUAL_EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import {ENTITY_TYPES} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {findHint} from '../../../components/hints/hints';

function Form ( props ) {
    let { invalid, pristine, expectAnswer, handleSubmit, update, reset, cancel, change, newItem, hints } = props;
    return (
        <form autoComplete="off" name="editQualMetrics" onSubmit={handleSubmit(update)}>
            {/*NOTE allow to set default definition of metric*/}
            <Row className="offset-bottom-3">
                <Col xs={12}>
                    <SelectEntities
                        name="metricDomain"
                        disabled={expectAnswer}
                        type={ENTITY_TYPES.METRIC_DOMAINS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('SCORING_METRICS$METRIC_DOMAIN')} </strong>)}
                        onChange={value =>{change('name', value.name );change('description', value.description );}}
                            />
                </Col>
            </Row>
            <Row className="offset-bottom-3">
                <Col xs={12} md={6} className="offset-bottom-3">
                    <Field
                        name="name"
                        component={MdInput}
                        disabled={expectAnswer}
                        placeholder={translate('GLOBALS$NAME')}
                         required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                            />
                </Col>
                <Col xs={12} md={6}>
                    <Field
                        name="description"
                        component={MdInput}
                        disabled={expectAnswer}
                        placeholder={translate('GLOBALS$DESCRIPTION')}
                        label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                            />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={newItem} disabled={pristine||invalid||expectAnswer} className="offset-right-2" hint={findHint(hints, newItem ? 'BUTTON_SCORING_METRICS_CREATE' : 'BUTTON_SCORING_METRICS_SAVE')} />
                    <ResetBtn onClick={reset} disabled={pristine||expectAnswer} className="offset-right-2" hint={findHint(hints, 'BUTTON_SCORING_METRICS_RESET')} />
                    <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_SCORING_METRICS_CANCEL')} />
                </Col>
            </Row>
        </form>
    );
}

export default withTranslation(connect(
    state => ({
        initialValues: state.qualMetrics.edit.data,
        newItem: state.qualMetrics.edit.meta.newItem,
        expectAnswer: state.qualMetrics.edit.meta.expectAnswer,
        hints: state.qualMetrics.edit.meta.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: QUAL_EDIT.CANCEL}),
        update: ( formData ) => dispatch({type: QUAL_EDIT.UPDATE_ITEM.REQUEST, ...formData})
    })
)(reduxForm({
    form: 'editQualMetrics',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } - named errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // METRIC DOMAIN
        if (!values.metricDomain) {
            errors.metricDomain = 'SCORING_METRICS$METRIC_DOMAIN_REQUIRED';
        }
        // NAME
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        return errors;
    }
})(Form)));
