// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {AccountBalance} from '@mui/icons-material';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {Paper} from '@mui/material';

// local dependencies
import {EDIT} from './actions';
import {FORM_NAME} from './saga';
import Input from '../../components/md-input';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {ENTITY_TYPES, PERMISSION} from '../../constants/spec';
import {ResetBtn, SubmitBtn} from '../../components/md-button';
import {translate, withTranslation} from '../../services/translate.service';
import separateService from '../../services/separate-with-comma.service';

import {findHint, RichHintTitle} from "../../components/hints/hints";
import UserPermissionModel from "../../models/user-permission.model";
import {SelectEntities} from "../../components/select-entities";

// config
const selectStyles = {singleValue: provided => ({...provided, textAlign: 'left', color: 'black'})};

class Edit extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, hints } = this.props;
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span: 10, offset: 1}} lg={{span: 10, offset: 1}} >
                            <Paper className="indent-8 text-center">
                                <div>
                                <AccountBalance fontSize="large" className="align-middle"/>&nbsp;
                                <span className="align-middle"><RichHintTitle update={EDIT}  name={'ORGANIZATION$COMPANY_AND_TECHNOLOGY_FOOTPRINT'} expectAnswer={expectAnswer}  data={findHint(hints, 'ORGANIZATION$COMPANY_AND_TECHNOLOGY_FOOTPRINT')}/></span>
                                </div>
                                <ConnectedError />
                                <ConnectedForm />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.organizationCyberInsuranceApplication.expectAnswer, hints: state.organizationCyberInsuranceApplication.hintsData}),
    dispatch => ({
        initialize: () => dispatch({type: EDIT.INITIALIZE}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.organizationCyberInsuranceApplication.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.organizationCyberInsuranceApplication.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        disabled: state.organizationCyberInsuranceApplication.expectAnswer
            || !UserPermissionModel.checkUserPermission(state.app.user, PERMISSION.ORGANIZATION.UPDATE)
            || !UserPermissionModel.checkUserPermission(state.app.user, PERMISSION.ORGANIZATION.CYBER_INSURANCE_INFORMATION),
        formValues: getFormValues(FORM_NAME)(state),
        hints: state.organizationCyberInsuranceApplication.hintsData,
        initialValues: state.organizationCyberInsuranceApplication.data,
        user: state.app.user,
    }),
    dispatch => ({
        update: formData => dispatch({ type: EDIT.UPDATE, ...formData})
       // updateLogo:
    })
)( reduxForm({ 
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values) => {
        let errors = {};

        // currency
        /*
        if (!values.currency) {
            errors.currency = 'ORGANIZATION$CURRENCY_REQUIRED';
        }
        */

        return errors;
    }

    })(
    ({ handleSubmit, update, invalid, disabled, reset, pristine, change, user, initialValues, formValues }) => {
        // console.log(RiskToleranceService.RATING_VALUES_SP().map(key=>({value: key, label: key})));
        return (<form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>

            <Row className="offset-top-10">
                <Col className="offset-bottom-6" xs={12}>
                    <h3 className="text-center text-highlighted">{translate('ORGANIZATION$BUSINESS_INFORMATION')}</h3>
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={6}>
                    <SelectEntities
                        name="industry"
                        disabled={disabled}
                        styles={selectStyles}
                        type={ENTITY_TYPES.INDUSTRIES}
                        placeholder={translate('VENDORS$INDUSTRY')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('VENDORS$INDUSTRY')} </strong>)}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={6}>
                    <Field
                        type="text"
                        component={Input}
                        name="averageRevenue"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$ANNUAL_REVENUE')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="totalHeadCount"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$TOTAL_HEAD_COUNT')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="companyITBudget"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$COMPANY_IT_BUDGET')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="itHeadCount"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$IT_HEAD_COUNT')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="securityHeadCount"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$SECURITY_HEAD_COUNT')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="itSecurityBudget"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$IT_SECURITY_BUDGET')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
            </Row>

            <Row className="offset-top-2">
                <Col className="offset-bottom-6" xs={12}>
                    <h3 className="text-center text-highlighted">{translate('ORGANIZATION$TECHNOLOGY_FOOTPRINT')}</h3>
                </Col>
                <Col className="offset-bottom-6" xs={12} lg={6}>
                    <SelectEntities
                        isMulti
                        name="businessCriticalApplications"
                        disabled={disabled}
                        type={ENTITY_TYPES.SYSTEMS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('ORGANIZATION$BUSINESS_CRITICAL_APPLICATIONS')} </strong>)}
                    />
                </Col>
                <Col className="offset-bottom-6" xs={12} lg={6}>
                    <SelectEntities
                        isMulti
                        name="outsourcedCriticalProviders"
                        disabled={disabled}
                        type={ENTITY_TYPES.VENDORS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('ORGANIZATION$OUTSOURCED_CRITICAL_PROVIDERS')} </strong>)}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="inhouseDataCentersCount"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$NUMBER_OF_INHOUSE_DATA_CENTERS')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="externalDataCentersCount"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$NUMBER_OF_EXTERNAL_DATA_CENTERS')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="personallyIdentifiableInformation"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$PERSONALLY_IDENTIFIABLE_INFORMATION')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="paymentCardIndustry"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$PAYMENT_CARD_INDUSTRY')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="protectedHealthInformation"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$PROTECTED_HEALTH_INFORMATION')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="internetOfThings"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$INTERNET_OF_THINGS')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
                <Col className="offset-bottom-4" xs={12} lg={4} xl={3}>
                    <Field
                        type="text"
                        component={Input}
                        name="industrialControlSystems"
                        style={{color: 'black'}}
                        disabled={disabled}
                        label={(<strong> {translate('ORGANIZATION$INDUSTRIAL_CONTROL_SYSTEMS')} </strong>)}
                        normalize={separateService.normalizeReal}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn
                        disabled={pristine || disabled || invalid}
                        permission={PERMISSION.ORGANIZATION.UPDATE}
                            />
                    <ResetBtn
                        onClick={reset}
                        className="offset-left-2"
                        disabled={pristine||disabled}
                        permission={PERMISSION.ORGANIZATION.UPDATE}
                            />
                </Col>
            </Row>
        </form>)
    }
)));
