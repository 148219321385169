// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {arrayPush, change, Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import get from "lodash/get";
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {DATA_CLASSES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {formatTechnologyLabel} from "../../../services/data-formatting.service";
import {MdAsyncCreatableSelect} from "../../../components/md-select";
import {instanceAPI} from "../../../services/api.service";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={DATA_CLASSES_MAP} />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                            <RichHintTitle update={EDIT} name={isNew ? 'DATA_CLASSES$CREATE_CLASS' : 'DATA_CLASSES$EDIT_CLASS'} expectAnswer={expectAnswer} data={findHint(hints, 'DATA_CLASSES_TITLE')}/>
                            <Preloader expectAnswer={expectAnswer} type="ICON" />
                            <ConnectedError />
                            <ConnectedForm isNew={isNew} />
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({expectAnswer: state.dataClasses.edit.expectAnswer, hints: state.dataClasses.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.dataClasses.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.dataClasses.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError} />
));

export const changeField = (field, value) => change('editDataClass', field, value);
export const arrayFieldPush = (field, value) => arrayPush('editDataClass', field, value);

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.dataClasses.edit.data,
        dataFields: state.dataClasses.edit.data.dataFields,
        hints: state.dataClasses.edit.hintsData,
        disabled: state.dataClasses.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        createDataField: name => dispatch({type: EDIT.CREATE_DATA_FIELD, name}),
    })
)(reduxForm({
    form: 'editDataClass',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, hints, dataFields, createDataField}) => (
    <form autoComplete="off" name="editDataClass" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')}  </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$DESCRIPTION')}
                    label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="dataDomains"
                    type={ENTITY_TYPES.DATA_DOMAINS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={item => formatTechnologyLabel(item)}
                    label={(<strong> {translate('DATA_DOMAINS$TITLE')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12} md={12} className="offset-bottom-4">
                <Field
                    name="dataFields"
                    isMulti={true}
                    disabled={disabled}
                    defaultOptions={dataFields}
                    component={MdAsyncCreatableSelect}
                    onCreateOption={name => createDataField(name)}
                    placeholder={translate('DATA_FIELDS$PLACEHOLDER')}
                    label={(<strong> {translate('DATA_FIELDS$TITLE')} </strong>)}
                    getNewOptionData={(inputValue, optionLabel) => ({
                        id: inputValue,
                        name: optionLabel,
                        __isNew__: true
                    })}
                    loadOptions={(name, done) => {
                        instanceAPI({
                            method: 'post',
                            url: '/data-fields/filter',
                            data: {page: 0, size: 6, filter: {name}},
                        }).then(({items}) => done(items)).catch(done.bind(null, []));
                    }} />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="regulations"
                    type={ENTITY_TYPES.REGULATIONS}
                    placeholder={translate('Regulations')}
                    label={(<strong> {translate('Regulations')} </strong>)}
                    getOptionLabel={option => `${get(option, 'acronym', '')} - ${get(option, 'name', '')}`}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn hint={findHint(hints, isNew ? 'BUTTON_DATA_CLASSES_CREATE' : 'BUTTON_DATA_CLASSES_SAVE')} isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" />
                <ResetBtn hint={findHint(hints, 'BUTTON_DATA_CLASSES_RESET')} onClick={reset} disabled={pristine||disabled} className="offset-right-2" />
                <CancelBtn hint={findHint(hints, 'BUTTON_DATA_CLASSES_CANCEL')} onClick={cancel} />
            </Col>
        </Row>
    </form>
))));
