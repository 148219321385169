// outsource dependencies
import {call, put, select, take, takeEvery} from 'redux-saga/effects';

// local dependencies
import {EDIT} from './actions';
import {forgotPassword, instanceAPI} from '../../services/api.service';
import CONFIRM_DIALOG from "../../components/confirmation-dialog/actions";
import {toastr} from "react-redux-toastr";
import {translate} from "../../services/translate.service";
import getHintsList, { PROFILE_HINTS } from '../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.RESET_PASSWORD, resetPasswordSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga () {
    yield put({type: EDIT.CLEAR});
    try {
        const data = yield call(getData);
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        const { name: organizationName } = yield call(getDataOrganization);
        yield put({type: EDIT.DATA, ...{ ...data, organizationName }});
        yield put({type: EDIT.META, initialized: true});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}


function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, PROFILE_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

function* resetPasswordSaga () {
    // NOTE ask confirmation of reset password
    // yield put({type: CONFIRM_DIALOG.REQUEST, title: 'Confirm reset password', message: <span>Resetting user password - clear existing user sessions and send email to change password. Please note - <code>each</code> call of <code>reset password</code> do it again. And <code>invalidate previous</code> email.</span>});
    yield put({type: CONFIRM_DIALOG.REQUEST, title: translate('USERS$CONFIRM_RESET_PASSWORD'), message: translate('USERS$RESET_PASSWORD_MESSAGE')});
    let answer = yield take([CONFIRM_DIALOG.SUCCESS, CONFIRM_DIALOG.ERROR]);
    // NOTE do nothing if confirmation dismiss
    if ( answer.type === CONFIRM_DIALOG.ERROR ) return;
    yield put({type: EDIT.META, expectAnswer: true});
    try {
        let { email } = yield select(state=> state.profile.data);
        yield call( forgotPassword, { email } );
        yield call(toastr.success, translate('FORGOT_PASSWORD$RESET_PASSWORD'), translate('USERS$SUCCESSFUL_RESET_PASSWORD') + email);
        yield put({type: EDIT.META, expectAnswer: false});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message, expectAnswer: false});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        const data = yield call(updateData, options);
        const { name: organizationName} = yield call(getDataOrganization);
        yield put({type: EDIT.DATA, ...{ ...data, organizationName }});
        yield put({type: EDIT.META, expectAnswer: false});
        yield call(toastr.success, translate('PROFILE$TITLE'), translate('GLOBALS$SUCCESSFUL_DATA_UPDATE'));
        // yield call(historyPush, SETTINGS.LINK());
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

/**
 * get data
 * @private
 */
function getData () {
    return instanceAPI({method: 'get', url: `/user-profile/self`});
}

function getDataOrganization () {
    return instanceAPI({method: 'get', url: `/organizations/self`});
}

/**
 * update data
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return instanceAPI({method: 'post', url: `/user-profile/update`, data});
}
