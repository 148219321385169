import React from 'react';
import { SvgIcon } from '@mui/material';

export const MessagesIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 29.296 28">
            <path id="Path_200" data-name="Path 200" d="M25.634,1.831H3.662A1.831,1.831,0,0,0,1.831,3.662V24.876l3.662-3.662a3.662,3.662,0,0,1,2.589-1.073H25.634a1.831,1.831,0,0,0,1.831-1.831V3.662A1.831,1.831,0,0,0,25.634,1.831ZM3.662,0A3.662,3.662,0,0,0,0,3.662V27.085a.915.915,0,0,0,1.563.646l5.224-5.224a1.831,1.831,0,0,1,1.295-.536H25.634A3.662,3.662,0,0,0,29.3,18.31V3.662A3.662,3.662,0,0,0,25.634,0Z" fill="#37435d" fillRule="evenodd"/>
            <path id="Path_201" data-name="Path 201" d="M6.75,7.665a.915.915,0,0,1,.915-.915H24.144a.915.915,0,1,1,0,1.831H7.665A.915.915,0,0,1,6.75,7.665Zm0,4.577a.915.915,0,0,1,.915-.915H24.144a.915.915,0,1,1,0,1.831H7.665A.915.915,0,0,1,6.75,12.243Zm0,4.577a.915.915,0,0,1,.915-.915H16.82a.915.915,0,0,1,0,1.831H7.665A.915.915,0,0,1,6.75,16.82Z" transform="translate(-1.257 -1.257)" fill="#37435d" fillRule="evenodd"/>
        </SvgIcon>
    );
}

export const LikeIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 21.088 21.391">
            <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M14.635,9.787V5.909A2.909,2.909,0,0,0,11.726,3L7.848,11.726V22.391H18.784a1.939,1.939,0,0,0,1.939-1.648l1.338-8.726a1.939,1.939,0,0,0-1.939-2.23Zm-6.787,12.6H4.939A1.939,1.939,0,0,1,3,20.452V13.665a1.939,1.939,0,0,1,1.939-1.939H7.848" transform="translate(-2 -2)" fill="none" stroke="#37435d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </SvgIcon>
    );
}

export const TasksIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 30.375 22.5">
            <g id="list-task" transform="translate(-3.375 -5.625)">
                <path id="Path_202" data-name="Path 202" d="M4.5,5.625A1.125,1.125,0,0,0,3.375,6.75V9A1.125,1.125,0,0,0,4.5,10.125H6.75A1.125,1.125,0,0,0,7.875,9V6.75A1.125,1.125,0,0,0,6.75,5.625ZM6.75,6.75H4.5V9H6.75Z" fill="#37435d" fillRule="evenodd"/>
                <path id="Path_203" data-name="Path 203" d="M11.25,7.875A1.125,1.125,0,0,1,12.375,6.75h20.25a1.125,1.125,0,0,1,0,2.25H12.375A1.125,1.125,0,0,1,11.25,7.875Zm1.125,7.875a1.125,1.125,0,0,0,0,2.25h20.25a1.125,1.125,0,0,0,0-2.25Zm0,9a1.125,1.125,0,0,0,0,2.25h20.25a1.125,1.125,0,0,0,0-2.25Z" fill="#37435d"/>
                <path id="Path_204" data-name="Path 204" d="M3.375,15.75A1.125,1.125,0,0,1,4.5,14.625H6.75A1.125,1.125,0,0,1,7.875,15.75V18A1.125,1.125,0,0,1,6.75,19.125H4.5A1.125,1.125,0,0,1,3.375,18Zm1.125,0H6.75V18H4.5Zm0,7.875A1.125,1.125,0,0,0,3.375,24.75V27A1.125,1.125,0,0,0,4.5,28.125H6.75A1.125,1.125,0,0,0,7.875,27V24.75A1.125,1.125,0,0,0,6.75,23.625ZM6.75,24.75H4.5V27H6.75Z" fill="#37435d" fillRule="evenodd"/>
            </g>
        </SvgIcon>
    );
}

// export const LoudspeakerIcon = (props) => {
//     return (
//         <SvgIcon {...props} width="46.65" height="46.65" viewBox="0 0 46.65 46.65">
//             <g id="speakerphone" transform="translate(0 11.949) rotate(-19)">
//                 <path id="Path_211" data-name="Path 211" d="M18.234,10.4v20.43a2.689,2.689,0,0,1-5.222.9l-3.288-9.4m19.215-1.048a4.588,4.588,0,0,0,0-9.175M9.724,22.34a6.12,6.12,0,0,1,2.393-11.752h2.8c6.277,0,11.66-1.889,14.023-4.588V27.409c-2.363-2.7-7.745-4.588-14.023-4.588h-2.8A6.219,6.219,0,0,1,9.724,22.34Z" transform="translate(-1.412 -1.412)" fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
//                 <path id="Path_212" data-name="Path 212" d="M0,0H36.7V36.7H0Z" fill="none"/>
//             </g>
//         </SvgIcon>
//     );
// }

export const BookIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 31 31">
            <path id="Path_235" data-name="Path 235" d="M16.563,11.813C17.531,7.989,21.193,6.039,29.156,6a.97.97,0,0,1,.969.969V24.406a.967.967,0,0,1-.969.969c-7.75,0-10.747,1.563-12.594,3.875-1.841-2.3-4.844-3.875-12.594-3.875A.99.99,0,0,1,3,24.29V6.969A.97.97,0,0,1,3.969,6C11.932,6.039,15.594,7.989,16.563,11.813Z" transform="translate(-1.063 -2.125)" fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Path_236" data-name="Path 236" d="M24,15V32.438" transform="translate(-8.5 -5.313)" fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Path_237" data-name="Path 237" d="M0,0H31V31H0Z" fill="none"/>
        </SvgIcon>
    );
}

export const TicketsIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 48 48">
            <g id="ticket" transform="translate(48) rotate(90)">
                <path id="Path_227" data-name="Path 227" d="M9.14,1.11v.03a4.575,4.575,0,0,0,9.15,0l-.01-.03H24a2.288,2.288,0,0,1,2.29,2.28V33.14A2.29,2.29,0,0,1,24,35.43H18.29a4.575,4.575,0,0,0-9.15,0H3.43a2.29,2.29,0,0,1-2.29-2.29V3.39A2.288,2.288,0,0,1,3.43,1.11Z" transform="translate(9.143 4.571)" fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2"/>
                <path id="Path_228" data-name="Path 228" d="M5.71,10.29H8" transform="translate(9.143 4.571)" fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2"/>
                <path id="Path_229" data-name="Path 229" d="M12.57,10.29h2.29" transform="translate(9.143 4.571)" fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2"/>
                <path id="Path_230" data-name="Path 230" d="M19.43,10.29h2.28" transform="translate(9.143 4.571)" fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2"/>
                <path id="Path_231" data-name="Path 231" d="M5.71,26.29H8" transform="translate(9.143 4.571)" fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2"/>
                <path id="Path_232" data-name="Path 232" d="M12.57,26.29h2.29" transform="translate(9.143 4.571)" fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2"/>
                <path id="Path_233" data-name="Path 233" d="M19.43,26.29h2.28" transform="translate(9.143 4.571)" fill="none" stroke="#4a5568" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2"/>
                <path id="Path_234" data-name="Path 234" d="M0,0H48V48H0Z" fill="none"/>
            </g>
        </SvgIcon>
    );
}
