
// outsource dependencies
import { fork } from 'redux-saga/effects';

// local dependencies
import mapping from './mapping/saga';
import settings from './settings/saga';

/**
 * connect all public sagas
 *
 *
 * @public
 */
export default function* () {
    yield fork(mapping);
    yield fork(settings);
}
