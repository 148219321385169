// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {BUSINESS_UNITS} from '../../../constants/routes';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {findHint} from '../../../components/hints/hints';

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let {data, hints} = this.props;
        return (<Paper square className="indent-4">
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={12} md={12} lg={3} className="offset-bottom-2">
                        <SearchFilterConnected />
                    </Col>
                    <Col xs={12} md={12} lg={9} className="text-right">
                        {/*<DownloadLink className="offset-left-2 offset-bottom-1"/>*/}
                        {/*<DownloadTemplateLink className="offset-left-2 offset-bottom-1" />*/}
                        <Link
                            Btn={AddBtn}
                            placement="left"
                            to={BUSINESS_UNITS.LINK_EDIT()}
                            className="offset-left-2 offset-bottom-1"
                            permission={PERMISSION.BUSINESS_UNIT.CREATE}
                            hint = {findHint(hints, 'BUTTON_BUSINESS_UNITS_ADD')}
                                />
                    </Col>
                </Row>
                <ConnectedError />
                { data.length ? (
                    <Paper> <ConnectedTable /> </Paper>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                )}
            </ConnectedInitializer>
        </Paper>);
    }
}

export default connect(
    state => ({
        data: state.businessUnits.list.data,
        uploadedFile: state.businessUnits.list.uploadedFile,
        hints: state.businessUnits.list.hintsData,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        uploadFile: file => dispatch({ type: LIST.UPLOAD_FILE, file }),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.businessUnits.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/*
const DownloadLink = withTranslation(withDownloadLink({downloadType: DOWNLOAD_TYPES.BUSINESS_UNITS.CSV_LIST})(props => (
    <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_DATA')} permission={PERMISSION.EXPORT.BUSINESS_UNIT} {...props}>
        <i className="fa fa-download" style={{fontSize: 20, marginTop: 2}} aria-hidden="true" />&nbsp;&nbsp;
        <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true" />
    </PrimaryBtn>
)));

const DownloadTemplateLink = withTranslation(withDownloadLink({downloadType: DOWNLOAD_TYPES.BUSINESS_UNITS.CSV_TEMPLATE})(props => (
    <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_TEMPLATE')} permission={PERMISSION.EXPORT.BUSINESS_UNIT_TEMPLATE} {...props}>
        <i className="fa fa-download" style={{fontSize: 20, marginTop: 2}} aria-hidden="true" />&nbsp;&nbsp;
        <i className="fa fa-file-o" style={{fontSize: 18}} aria-hidden="true" />
    </PrimaryBtn>
)));
*/

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter:  state.businessUnits.list.filter,
        disabled: state.businessUnits.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        placeholder={translate("BUSINESS_UNITS$SEARCH_BY_NAME_AND_DESCRIPTION")}
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.businessUnits.list, hints: state.businessUnits.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('BUSINESS_UNITS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('description')}
                                >
                            {translate('BUSINESS_UNITS$DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'parent'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('parent')}
                                >
                            {translate('BUSINESS_UNITS$PARENT_UNIT')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('BUSINESS_UNITS$OWNED_PROCESSES')} </TableCell>
                    <TableCell className="th"> {translate('BUSINESS_UNITS$USED_PROCESSES')} </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{formatBusinessUnitLabel(get(item, 'parent'))}</TableCell>
                    <TableCell>{item.ownedProcesses.map( i => i.name ).join(', ')}</TableCell>
                    <TableCell>{item.usedProcesses.map( i => i.name ).join(', ')}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.BUSINESS_UNIT.UPDATE}
                            to={BUSINESS_UNITS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            hint = {findHint(hints, 'BUTTON_BUSINESS_UNITS_EDIT')}
                        />
                        <DelBtn permission={PERMISSION.BUSINESS_UNIT.DELETE} onClick={() => deleteItem(item.id)} className="offset-left-2" hint = {findHint(hints, 'BUTTON_BUSINESS_UNITS_DELETE')}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));

const ConnectedError = connect(
    state => ({message: state.businessUnits.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
