
// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const LIST = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@system-control-test-results/list/');

export const DRILLDOWN = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
        CANCEL_ADVANCED_SEARCH: `${prefix}CANCEL_ADVANCED_SEARCH`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@system-control-test-results/drilldown/');

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE: `${prefix}UPDATE`,
        AUDIT_UPDATE: `${prefix}AUDIT_UPDATE`,
        SUFFICIENT_UPDATE: `${prefix}SUFFICIENT_UPDATE`,
        CANCEL: `${prefix}CANCEL`,
        INITIALIZE: `${prefix}INITIALIZE`,
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
        CHANGE_CHAPTER: `${prefix}CHANGE_CHAPTER`,
        CHANGE_SECTION: `${prefix}CHANGE_SECTION`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@system-control-test-results/edit/');
