
// outsource dependencies
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

// local dependencies
import { PublicLogo } from '../../images';
import { PUBLIC } from '../../actions/types';
import Preloader from '../../components/preloader';
import { translate } from '../../services/translate.service';
import { PUBLIC_WELCOME, SIGN_IN } from '../../constants/routes';

// configuration

/**
 * Change Password using token from email notification
 *
 * @constructor EmailConfirmation
 * @public
 */
class EmailConfirmation extends Component {
    constructor(...args) {
        super(...args);
        this.state = {};
    }

    render () {
        let { expectAnswer, invalidToken } = this.props;
        return (<div>
            <div className="row">
                <div className="col-xs-8 col-xs-offset-2 text-center">
                    <Link to={PUBLIC_WELCOME.LINK()}>
                        <PublicLogo />
                    </Link>
                </div>
            </div>
            <div className="row">
                <h1 className="col-xs-12 text-center">
                    <strong> {translate('AUTHORIZATION$EMAIL_CONFIRMATION')} </strong>
                </h1>
            </div>
            <div className="row offset-bottom-6">
                <Preloader type="BOX" expectAnswer={expectAnswer}>
                    { invalidToken ? (
                        <h3 className="col-xs-12 text-justify">
                            {translate('EMAIL_CONFIRMATION$FAIL')}
                        </h3>
                    ) : (
                        <h3 className="col-xs-12 text-justify">
                            {translate('EMAIL_CONFIRMATION$SUCCESS')}
                        </h3>
                    )}
                </Preloader>
            </div>
            <div className="row">
                <div className="col-xs-6 col-xs-offset-6 text-right text-uppercase">
                    <Link to={SIGN_IN.LINK()} className="link"> {translate('AUTHORIZATION$SIGN_IN')} </Link>
                </div>
            </div>
        </div>);
    }

    UNSAFE_componentWillMount () {
        let { validateToken, match: { params: { token } } } = this.props;
        validateToken({token});
    }
}

export default connect(
    // mapStateToProps
    state => ({ ...state.emailConfirmation }),
    // mapDispatchToProps
    dispatch => ({
        validateToken: ({token}) => dispatch({type: PUBLIC.EMAIL_CONFIRMATION.REQUEST, token }),
    })
)(EmailConfirmation);
