// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Card, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {SUBSIDIARIES} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {findHint} from '../../../components/hints/hints';

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { data, hints } = this.props;
        return (<Card square className="indent-4">
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={12} sm={6} lg={3} className="offset-bottom-2">
                        <SearchFilterConnected />
                    </Col>
                    <Col xs={12} sm={6} lg={{offset:3}}  className="text-right offset-bottom-2">
                        <Link Btn={AddBtn} to={SUBSIDIARIES.LINK_EDIT()} permission={PERMISSION.SUBSIDIARY.CREATE} hint = {findHint(hints, 'BUTTON_SUBSIDIARY_ORGANIZATIONS_ADD')} />
                    </Col>
                </Row>
                <ConnectedError />
                { data.length ? (
                    <Card> <ConnectedTable /> </Card>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                )}
            </ConnectedInitializer>
        </Card>);
    }
}

export default connect(
    state => ({data: state.subsidiaries.list.data, hints: state.subsidiaries.list.hintsData }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.subsidiaries.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter:  state.subsidiaries.list.filter,
        disabled: state.subsidiaries.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.subsidiaries.list, hints: state.subsidiaries.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort, deleteItem }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell style={{padding: 10}} className="th" >
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('SUBSIDIARY_ORGANIZATIONS$ORGANIZATION_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell style={{padding: 10}} className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('description')}
                                >
                            {translate('SUBSIDIARY_ORGANIZATIONS$ORGANIZATION_DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell style={{padding: 10}} className="th">
                        <TableSortLabel
                            active={sortF === 'parent'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('parent')}
                                >
                            {translate('SUBSIDIARY_ORGANIZATIONS$PARENT')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell style={{padding: 10}} className="th">
                        <TableSortLabel
                            active={sortF === 'site'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('site')}
                        >
                            {translate('ORGANIZATION$SITE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell style={{padding: 10}} className="th">
                        <TableSortLabel
                            active={sortF === 'owner'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('owner')}
                        >
                            {translate('GLOBALS$OWNER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"/>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48, padding:10}} key={i}>
                    <TableCell style={{height: 48,  padding:10}} >{item.name}</TableCell>
                    <TableCell style={{height: 48}} className='td'>{item.description}</TableCell>
                    <TableCell style={{height: 48}} className='td'>{get(item, 'parent.name')}</TableCell>
                    <TableCell style={{height: 48}} className='td'>{get(item, 'site')}</TableCell>
                    <TableCell style={{height: 48}} className='td'>{get(item, 'owner.email')}</TableCell>
                    <TableCell style={{height: 48}} align="right" className="text-nowrap">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.SUBSIDIARY.UPDATE}
                            hint = {findHint(hints, 'BUTTON_SUBSIDIARY_ORGANIZATIONS_EDIT')}
                            to={SUBSIDIARIES.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                                />
                        <DelBtn
                            permission={PERMISSION.SUBSIDIARY.DELETE}
                            onClick={() => deleteItem(item.id)}
                            className="offset-left-2"
                            hint = {findHint(hints, 'BUTTON_SUBSIDIARY_ORGANIZATIONS_DELETE')}
                        />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));

const ConnectedError = connect(
    state => ({message: state.subsidiaries.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
