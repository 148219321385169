// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {GDPR_COMPLIANCE} from '../../../constants/routes';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {DescriptionTooltip} from '../../../components/question-row';
import {formatCompliance} from '../../../services/data-formatting.service';
import {translate, withTranslation} from '../../../services/translate.service';
import {EditBtn, Link, LogBtn, ScoringQuestionsBtn} from '../../../components/md-button';
import {GDPR_ORGANIZATION_COMPLIANCE_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, data, hints, clearError, errorMessage, status} = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={GDPR_ORGANIZATION_COMPLIANCE_MAP}/>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={12} lg={8}>
                            <RichHintTitle update={LIST} name={`GDPR$ORGANIZATION_COMPLIANCE`}
                                           expectAnswer={expectAnswer}
                                           data={findHint(hints, `GDPR_ORGANIZATION_COMPLIANCE_TITLE`)}/>
                        </Col>
                        <Col xs={12} lg={4} className="text-right top-indent-4">
                            <Link
                                Btn={ScoringQuestionsBtn}
                                className="offset-left-1 md-btn md-btn-warning"
                                to={GDPR_COMPLIANCE.LINK_QUESTIONS()}
                                tooltip={translate('SCORING_QUESTIONS$TITLE')}
                                permission={PERMISSION.GDPR_ORGANIZATION_COMPLIANCE.SCORING_QUESTION}
                                hint={findHint(hints, `BUTTON_GDPR_ORGANIZATION_COMPLIANCE_SCORING_QUESTIONS`)}
                            >
                            </Link>
                            <Link
                                hint={findHint(hints, `BUTTON_GDPR_ORGANIZATION_COMPLIANCE_LOG`)}
                                Btn={LogBtn}
                                className="offset-left-1 md-btn md-btn-warning"
                                tooltip={translate('GLOBALS$LOG')}
                                to={GDPR_COMPLIANCE.LINK_STATUS_LOG()}
                            >
                            </Link>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4"> <Col xs={12}>
                        <h5>
                            <strong>{translate('GDPR$COMPLIANCE_COLON')}</strong> {formatCompliance(status.compliance) || '-'}
                        </h5>
                        <h5><strong>{translate('GDPR$FILES_NUMBER')}</strong> {status.filesNumber || '-'}</h5>
                        <h5><strong>{translate('GDPR$ARTICLES_NUMBER')}</strong> {status.articlesNumber || '-'}</h5>
                        <h5><strong>{translate('GDPR$ARTICLES_PROCESSED')}</strong> {status.articlesProcessed || '-'}
                        </h5>
                    </Col> </Row>
                    <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}> <SearchFilterConnected/> </Col> </Row>
                    <Row> <Col xs={12}> <ErrorMessage active message={errorMessage} onChange={clearError}/> </Col>
                    </Row>
                    {data.length ? (
                        <Paper> <ConnectedTable/> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>

        );
    }
}

export default connect(
    state => ({...state.gdprCompliance.list, hints: state.gdprCompliance.list.hintsData}),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null})
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.gdprCompliance.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter: state.gdprCompliance.list.filter,
        disabled: state.gdprCompliance.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('GDPR$SEARCH_BY_ARTICLE')}
    />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.gdprCompliance.list, hints: state.gdprCompliance.list.hintsData}),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({data, hints, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort}) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'chapterNumber'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('chapterNumber')}
                        >
                            {translate('GDPR$CHAPTER_NUMBER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'chapter'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('chapter')}
                        >
                            {translate('GDPR$CHAPTER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'articleNumber'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('articleNumber')}
                        >
                            {translate('GDPR$ARTICLE_NUMBER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'article'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('article')}
                        >
                            {translate('GDPR$ARTICLE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('SCORING_QUESTIONS$QUESTION')} </TableCell>
                    <TableCell className="th"> {translate('TASKS$TITLE')} </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'compliance'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('compliance')}
                        >
                            {translate('GDPR$COMPLIANCE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"/>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell align="center">{get(item, 'chapter.chapterNumber')}</TableCell>
                    <TableCell>{get(item, 'chapter.name')}</TableCell>
                    <TableCell align="center">{get(item, 'article.articleNumber')}</TableCell>
                    <TableCell>{get(item, 'article.name')} {get(item, 'paragraph') &&
                        <strong>({get(item, 'paragraph.name')})</strong>} </TableCell>
                    <TableCell style={{minWidth: 250}}>
                        {get(item, 'question.question')}&nbsp;
                        {get(item, 'question.description') && (
                            <DescriptionTooltip title={get(item, 'question.description')}/>)}
                    </TableCell>
                    <TableCell style={{minWidth: 200}}>
                        <ul className="list-unstyled offset-0"> {(get(item, 'tasks', [])).map((item, index) => (
                            <li key={index}>{get(item, 'name')}</li>
                        ))} </ul>
                    </TableCell>
                    <TableCell>{formatCompliance(item.compliance)}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={EditBtn}
                            hint={findHint(hints, `BUTTON_GDPR_ORGANIZATION_COMPLIANCE_EDIT`)}
                            permission={PERMISSION.GDPR_ORGANIZATION_COMPLIANCE.UPDATE}
                            to={GDPR_COMPLIANCE.LINK_EDIT({
                                id: String(get(item, 'id', null)),
                                articleId: get(item, 'article.id'),
                                paragraphId: get(item, 'paragraph.id'),
                                query: {back: history.location.pathname + history.location.search}
                            })}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>)));
