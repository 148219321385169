
// outsource dependencies
import { filter, uniqBy, get } from 'lodash';
import { arrayPush, arrayRemove } from 'redux-form';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { MAPPING } from '../actions';
import { history } from '../../../../store';
import { changeField, FORM_NAME } from './index';
import { instanceAPI } from '../../../../services/api.service';
import getHintsList, { SECURITY_REQUIREMENTS_EDIT_HINTS } from '../../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(MAPPING.CANCEL, cancelSaga);
    yield takeEvery(MAPPING.UPDATE, updateDataSaga);
    yield takeEvery(MAPPING.INITIALIZE, initializeSaga);
    yield takeEvery(MAPPING.ADD_ASSESSMENTS_TYPE, addAssessmentTypeSaga);
    yield takeEvery(MAPPING.REMOVE_ASSESSMENTS_TYPE, removeAssessmentTypeSaga);
    yield takeEvery(MAPPING.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, SECURITY_REQUIREMENTS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: MAPPING.META, hintsData});
    } catch ( {message} ) {
        yield put({type: MAPPING.META, errorMessage: message});
    }
    yield put({type: MAPPING.GET_HINTS_DATA.FINISH});
}
function* initializeSaga ({id}) {
    yield put({type: MAPPING.CLEAR});
    try {
        let data = yield call(getData, id);
        let selectedTypesIds = (data.frameworks || []).map(item => get(item, 'assessmentType.id') );
        let availableAssessmentTypes = yield call(getAssessmentTypes, selectedTypesIds);
        yield put({type: MAPPING.DATA, data});
        yield put({type: MAPPING.META, selectedTypesIds, availableAssessmentTypes});
        yield put({type: MAPPING.GET_HINTS_DATA.REQUEST});
    }
    catch ({message}) {
        yield put({type: MAPPING.META, errorMessage: message});
    }
    yield put({type: MAPPING.META, initialized: true});
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: MAPPING.META, expectAnswer: true});
    try {
        let data = yield call(updateData, options);
        yield put({type: MAPPING.DATA, data});
        yield put({type: MAPPING.META, expectAnswer: false});
    }
    catch ({message}) {
        yield put({type: MAPPING.META, expectAnswer: false, errorMessage: message});
    }
}

function* addAssessmentTypeSaga ({assessmentType}) {
    yield put({type: MAPPING.META, expectAnswer: true});
    try {
        let { selectedTypesIds } = yield select(state => state.securityRequirements.edit.mapping);
        selectedTypesIds = [...selectedTypesIds, assessmentType.id];
        let availableAssessmentTypes = yield call(getAssessmentTypes, selectedTypesIds);
        yield put(changeField('assessmentTypes', null));
        yield put(arrayPush(FORM_NAME, 'frameworks', {assessmentType}));
        yield put({type: MAPPING.META, expectAnswer: false, selectedTypesIds, availableAssessmentTypes});
    }
    catch ({message}) {
        yield put({type: MAPPING.META, expectAnswer: false, errorMessage: message});
    }
}

function* removeAssessmentTypeSaga ({assessmentType, index}) {
    yield put({type: MAPPING.META, expectAnswer: true});
    try {
        let { selectedTypesIds } = yield select(state => state.securityRequirements.edit.mapping);
        selectedTypesIds = filter(selectedTypesIds, item => item !== assessmentType.id);
        let availableAssessmentTypes = yield call(getAssessmentTypes, selectedTypesIds);
        yield put(changeField('assessmentTypes', null));
        yield put(arrayRemove(FORM_NAME, 'frameworks', index));
        yield put({type: MAPPING.META, expectAnswer: false, selectedTypesIds, availableAssessmentTypes});
    }
    catch ({message}) {
        yield put({type: MAPPING.META, expectAnswer: false, errorMessage: message});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.securityRequirements.edit.mapping);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get assessment types which still not be chosen by user
 * @param {Array} selectedTypesIds
 * @private
 */
function getAssessmentTypes ( selectedTypesIds=[] ) {
    return instanceAPI({
        method: 'post',
        url: `/assessment-types/filter`,
        data: {filter: {excludeIds: selectedTypesIds, size: 6}}
    }).then(({items})=>items);
}

/**
 * get security requirement by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return instanceAPI({method: 'get', url: `/security-requirements/mapping/${id}`})
        .then(success => ({id, frameworks: formatData(success)}));
}

/**
 * update security requirement data
 * @param {Object} data
 * @private
 */
function updateData ({id, ...options} ) {
    let prepared = [], frameworks = options.frameworks || [];
    frameworks = frameworks.map(item => item.items || []);
    for (let i = 0; i < frameworks.length; i++ ) {
        prepared = prepared.concat(frameworks[i]);
    }
    return instanceAPI({ method: 'post', url: `/security-requirements/mapping/${id}`, data: prepared})
        .then(success => ({id, frameworks: formatData(success)}));
}

/**
 * prepare data for view
 * @param {Array} data
 * @private
 */
function formatData (data=[]) {
    let temp = (data || []).map((item={}) => item.assessmentType);
    let prepared = uniqBy(temp, 'id');
    prepared = prepared.map(item => {
        let temp={};
        temp.assessmentType = item;
        temp.items = filter(data, el => item.id === el.assessmentType.id);
        return temp;
    });
    return prepared;
}


