// outsource dependencies
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Tab, Tabs} from '@mui/material';
import {Outlet, useLocation} from 'react-router-dom';

// local dependencies
import {Link} from '../../components/md-button';
import {translate, withTranslation} from '../../services/translate.service';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumb';

// nested pages
import {SUBSIDIARIES_MAP} from "../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../components/hints/hints";
import {connect} from "react-redux";
import {LIST, SCHEME} from "./actions";

// config
// const TABS = {
//     LIST: 'list',
//     SCHEME: 'scheme'
// };

// export default function ( props ) {
//     let { location } = props;
//     return (
//         <Switch>
//             <Route path={SUBSIDIARIES.EDIT} component={ Edit } />
//             <Route path={SUBSIDIARIES.TABS} component={ RouteTabs } />
//             {/* OTHERWISE */}
//             <Redirect to={{ pathname: SUBSIDIARIES.LINK({tab:TABS.LIST}), state: { from: location } }}/>
//         </Switch>
//     );
// }

// /**
//  * common component with tabs for 'Schema' and 'List' pages
//  *
//  * @param {Object} props
//  * @public
//  */
// class RouteTabs extends Component  {
//     render() {
//         let { location, match, history, hints } = this.props;
//         let { tab } = match.params;
//         debugger
//     return (<Container fluid>
//         <Breadcrumbs breadCrumbsMap={ SUBSIDIARIES_MAP }  />
//         <Row className="offset-bottom-4">
//             <Col xs={12}>
//                 {/*<RichHintTitle update={LIST}  name={'SUBSIDIARY_ORGANIZATIONS$TITLE'} expectAnswer={expectAnswer} data={findHint(hints, 'SUBSIDIARY_ORGANIZATIONS_TITLE')}/>*/}
//                 <h2 className="text-uppercase"> {translate('SUBSIDIARY_ORGANIZATIONS$TITLE')} </h2>
//             </Col>
//         </Row>
//         <Paper square>
//             <Tabs
//                 value={tab}
//                 textColor="primary"
//                 indicatorColor="primary"
//                     >
//                 <Link Btn={Tab} label={translate('GLOBALS$LIST')} value={TABS.LIST} to={SUBSIDIARIES.LINK({tab:TABS.LIST})} />
//                 <Link Btn={Tab} label={translate('GLOBALS$SCHEME')} value={TABS.SCHEME} to={SUBSIDIARIES.LINK({tab:TABS.SCHEME})} />
//             </Tabs>
//         </Paper>
//         <Switch>
//             <Route path={SUBSIDIARIES.LIST} component={ List } />
//             <Route path={SUBSIDIARIES.SCHEME} component={ Scheme } />
//             {/* OTHERWISE */}
//             <Redirect to={{ pathname: SUBSIDIARIES.LINK({tab:TABS.LIST}), state: { from: location } }}/>
//         </Switch>
//     </Container>)}
// }
//
// connect(
//     state => ({hints: state.subsidiaries.list.hintsData,}),
//     null
// )(RouteTabs);

const SubsidiariesRouteTabs = withTranslation(connect(
    state => ({
        listHints: state.subsidiaries.list.hintsData,
        schemeHints: state.subsidiaries.scheme.hintsData,
        disabled: state.subsidiaries.list.expectAnswer || state.subsidiaries.scheme.expectAnswer
    }),
    null
)(({disabled, match, listHints, history, schemeHints, hints}) => {
    let location = useLocation();
    const listLink = '/private/subsidiaries/tabs/list';
    const schemeLink = '/private/subsidiaries/tabs/scheme';
    let isScheme = schemeLink === location.pathname;

    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={SUBSIDIARIES_MAP} />
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <RichHintTitle update={isScheme ? SCHEME : LIST}
                                   name={isScheme ? 'SUBSIDIARY_ORGANIZATIONS$SCHEME' : 'SUBSIDIARY_ORGANIZATIONS$TITLE'}
                                   expectAnswer={disabled}
                                   data={findHint(isScheme ? schemeHints : listHints, isScheme ? 'SUBSIDIARY_ORGANIZATIONS_SCHEME' : 'SUBSIDIARY_ORGANIZATIONS_TITLE')} />
                    {/*<h2 className="text-uppercase"> {translate('SUBSIDIARY_ORGANIZATIONS$TITLE')} </h2>*/}
                </Col>
            </Row>
            <Paper square>
                <Tabs
                    value={location.pathname}
                    textColor="primary"
                    indicatorColor="primary"
                >
                    <Link Btn={Tab} label={translate('GLOBALS$SCHEME')} value={schemeLink} to={schemeLink} />
                    <Link Btn={Tab} label={translate('GLOBALS$LIST')} value={listLink} to={listLink} />
                </Tabs>
                {<Outlet/>}

            </Paper>
            {/*<Switch>*/}
            {/*    <Route path={SUBSIDIARIES.LIST} component={ List } />*/}
            {/*    <Route path={SUBSIDIARIES.SCHEME} component={ Scheme } />*/}
            {/*    /!* OTHERWISE *!/*/}
            {/*    <Redirect to={{ pathname: SUBSIDIARIES.LINK({tab:TABS.LIST}), state: { from: location } }}/>*/}
            {/*</Switch>*/}
        </Container>
    )
}));

export { SubsidiariesRouteTabs };