
// outsource dependencies
import get from 'lodash/get';
// local dependencies
import is from '../services/is.service';
// import { config } from '../constants';
import store from '../store';
import {NEW_ID} from './spec';
import storage from '../services/storage.service';
import queryService from '../services/query.service';
import { API_PATH, AUTH_STORE, ACCESS_TOKEN } from '../services/api.service';
// TODO remove - it is a bad solution
export const getRiskModelId = () => get(store.getState(), 'app.riskModel.id');
const accessToken = () => get(storage.get(AUTH_STORE), ACCESS_TOKEN);
export const PUBLIC = 'public';
export const PRIVATE = '/private';

/*-------------------------------------------------
        PUBLIC nested routes
---------------------------------------------------*/
export const PUBLIC_WELCOME = {
    LINK: linkTo.bind({url: ()=> (`${PUBLIC}/public-welcome`)}),
    ROUTE: `${PUBLIC}/public-welcome`,
    REGEXP: new RegExp(`${PUBLIC}/public-welcome`, 'i'),
};

export const SIGN_IN = {
    LINK: linkTo.bind({url: ()=> (`/${PUBLIC}/sign-in`)}),
    ROUTE: `sign-in`,
    REGEXP: new RegExp(`${PUBLIC}/sign-in`, 'i'),
};

export const SIGN_UP = {
    LINK: linkTo.bind({url: ()=> (`${PUBLIC}/sign-up`)}),
    ROUTE: `sign-up`,
    REGEXP: new RegExp(`${PUBLIC}/sign-up`, 'i'),
};

export const FORGOT_PASSWORD = {
    LINK: linkTo.bind({url: ()=> (`/${PUBLIC}/forgot-password`)}),
    ROUTE: 'forgot-password',
};

export const CHANGE_PASSWORD = {
    LINK: linkTo.bind({url: ({token}) => (`/${PUBLIC}/change-password/${token}`)}),
    ROUTE: 'change-password/:token',
    // ROUTE: PUBLIC + '/change-password/:token',
    REGEXP: new RegExp(`${PUBLIC}/change-password/.*`, 'i'),
};

export const EMAIL_CONFIRMATION = {
    LINK: linkTo.bind({url: ({token}) => (`/${PUBLIC}/email-confirmation/${token}`)}),
    ROUTE: 'email-confirmation/:token',
    REGEXP: new RegExp(`${PUBLIC}/email-confirmation/.*`, 'i'),
};

/*-------------------------------------------------
        PRIVATE nested routes
---------------------------------------------------*/

export const HOME = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/home`)}),
    ROUTE: 'home',
    REGEXP: new RegExp(`${PRIVATE}/home.*`, 'i'),
};

export const SETTINGS = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/settings`)}),
    ROUTE: 'settings',
    REGEXP: new RegExp(`${PRIVATE}/settings.*`, 'i'),
};

export const QUICK_START = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/quick-start`)}),
    ROUTE: 'quick-start',
    REGEXP: new RegExp(`${PRIVATE}/quick-start.*`, 'i'),
};

export const KNOWLEDGE_BASE = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/knowledge-base`)}),
    ROUTE: 'knowledge-base',
    REGEXP: new RegExp(`${PRIVATE}/knowledge-base.*`, 'i'),
};

export const ABOUT = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/about`)}),
    ROUTE: 'about',
    REGEXP: new RegExp(`${PRIVATE}/about.*`, 'i'),
};

export const PRIVATE_RELOAD = {
    LINK: linkTo.bind({url: ({path}) => (`${PRIVATE}/reload/${path}`)}),
    ROUTE: 'reload/:path',
    REGEXP: new RegExp('never match'),
};

export const DOMAINS = (function () {
    const ROUTE = `domains`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CATEGORIES = (function () {
    const ROUTE = `categories`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CONSTANTS = (function () {
    const ROUTE = `constants`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const QUANT_MODELS = (function () {
    const ROUTE = `quantification-models`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const QUANT_METRICS = (function () {
    const ROUTE = `quantification-metrics`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const IMPORT_QUANT_METRICS = {
    REGEXP: new RegExp(`${PRIVATE}/import-quant-metrics.*`, 'i'),
    ROUTE: PRIVATE+'/import-quant-metrics',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-quant-metrics`)}),
};


export const QUAL_METRICS = (function () {
    const ROUTE = `qualitative-metrics`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const QUAL_QUESTIONS = (function () {
    const ROUTE = `qualitative-questions`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const RISK_ANSWERS = (function () {
    const ROUTE = `risk-answers`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const ASSOCIATE_MODELS = (function () {
    const ROUTE = `associate-models`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const ORGANIZATIONS = (function () {
    const ROUTE = `organizations`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const ROLES = (function () {
    const ROUTE = `roles`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const BUSINESS_UNITS = (function () {
    const ROUTE = `business-units`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        EDIT: ROUTE + `/edit/:id`,
        TABS: `tabs/:tab`,
        LIST: `tabs/list`,
        SCHEME: `tabs/scheme`,
        LINK: linkTo.bind({url: ({tab}) => (`${PRIVATE}/${ROUTE}/tabs/${tab || 'list'}`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const USERS = (function () {
    const ROUTE = `users`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const ASSET_CLASSES = (function () {
    const ROUTE = `asset-classes`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const DATA_DOMAINS = (function () {
    const ROUTE = `data-domains`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const DATA_CLASSES = (function () {
    const ROUTE = `data-classes`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const SYSTEMS = (function () {
    const ROUTE = `systems`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const TECHNOLOGY_ASSETS = (function () {
    const ROUTE = `technology-assets`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const PROCESSES = (function () {
    const ROUTE = `processes`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const TECH_CATEGORIES = (function () {
    const ROUTE = `tech-categories`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const TECHNOLOGIES = (function () {
    const ROUTE = `technologies`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const ASSESSMENT_TYPES = (function () {
    const ROUTE = `assessment-types`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const ASSESSMENTS = (function () {
    const ROUTE = `assessments`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CONTROL_TEST_RESULTS = (function () {
    const ROUTE = `organization-control-test-results`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        EDIT: `edit/:id/requirement/:requirementId`,
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID, requirementId}) => (`${PRIVATE}/${ROUTE}/edit/${id}/requirement/${requirementId}`)}),
    };
})();

export const ASSESSMENT_FINDINGS = (function () {
    const ROUTE = `assessment-findings`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CONTROLS = (function () {
    const ROUTE = `controls`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const ANSWERED_TESTS = (function () {
    const ROUTE = `answered-tests`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CONTROL_GUIDELINES = (function () {
    const ROUTE = `control-guidelines`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const VENDORS = (function () {
    const ROUTE = `vendors`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        QUESTIONS: `questions/:group/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)}),
        LINK_QUESTIONS: linkTo.bind({url: ({id, group = 'scoring'}) => (`${PRIVATE}/${ROUTE}/questions/${group}/${id}`)}),
    };
})();

export const ASSOCIATE_SYSTEMS = (function () {
    const ROUTE = `associate-systems`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id/vendor/:vendorId`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id, vendorId}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}/vendor/${vendorId || ''}`)})
    };
})();

export const DATA_EXFILTRATION = (function () {
    const ROUTE = `data-exfiltration`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const BUSINESS_INTERRUPTION = (function () {
    const ROUTE = `business-interruption`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const REGULATORY_EXPOSURES = (function () {
    const ROUTE = `regulatory-exposures`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const PROFILE = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/profile`)}),
    ROUTE: PRIVATE+'/profile',
    REGEXP: new RegExp(`${PRIVATE}/profile.*`, 'i'),
};

export const PRIVATE_CHANGE_PASSWORD = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/change-password`)}),
    ROUTE: 'change-password',
    REGEXP: new RegExp(`${PRIVATE}/change-password.*`, 'i'),
};

export const VENDORS_DASHBOARD = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/dashboard-vendors`)}),
    ROUTE: PRIVATE+'/dashboard-vendors',
    REGEXP: new RegExp(`${PRIVATE}/dashboard-vendors.*`, 'i'),
};

export const CONTROL_FUNCTIONS = (function () {
    const ROUTE = `control-functions`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CONTROL_CATEGORIES = (function () {
    const ROUTE = `control-categories`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CONTROL_SUBCATEGORIES = (function () {
    const ROUTE = `control-subcategories`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const ADMIN_DASHBOARD = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/dashboard-admin`)}),
    ROUTE: PRIVATE+'/dashboard-admin',
    REGEXP: new RegExp(`${PRIVATE}/dashboard-admin.*`, 'i'),
};

export const ORGANIZATIONS_DASHBOARD = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/dashboard-organization`)}),
    ROUTE: PRIVATE+'/dashboard-organization',
    REGEXP: new RegExp(`${PRIVATE}/dashboard-organization.*`, 'i'),
};

export const ORGANIZATION = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/organization`)}),
    ROUTE: 'organization',
    REGEXP: new RegExp(`${PRIVATE}/organization.*`, 'i'),
};

export const ORGANIZATION_CYBER_INSURANCE_APPLICATION = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/cyber-insurance-application-organization`)}),
    ROUTE: 'cyber-insurance-application-organization',
    REGEXP: new RegExp(`${PRIVATE}/cyber-insurance-application-organization.*`, 'i'),
};

export const ORGANIZATION_CYBER_INSURANCE_INFO = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/cyber-insurance-info-organization`)}),
    ROUTE: 'cyber-insurance-info-organization',
    REGEXP: new RegExp(`${PRIVATE}/cyber-insurance-info-organization.*`, 'i'),
};

export const ORGANIZATION_CYBER_RISK_TOLERANCE = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/cyber-risk-tolerance-organization`)}),
    ROUTE: 'cyber-risk-tolerance-organization',
    REGEXP: new RegExp(`${PRIVATE}/cyber-risk-tolerance-organization.*`, 'i'),
};

export const DASHBOARDS = {
    ROUTE: 'dashboards/:id',
    REGEXP: new RegExp(`${PRIVATE}/dashboards.*`, 'i'),
    LINK: linkTo.bind({url: ({id}) => (`${PRIVATE}/dashboards/${id}`)})
};

export const VENDOR_QUALS = (function () {
    const ROUTE = `vendor-quals`;
    return {
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        ROUTE: `${ROUTE}/:vendor/:vendorName`,
        LINK: linkTo.bind({url: ({vendor, vendorName}) => (`${ROUTE}/${vendor}/${vendorName}`)}),
    };
})();

export const VENDOR_QUALS_DOMAIN = (function () {
    const ROUTE = `vendor-quals-domain`;
    return {
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        ROUTE: `${ROUTE}/:vendor/:vendorName/:metricDomain`,
        LINK: linkTo.bind({url: ({vendor, vendorName, metricDomain}) => (`${ROUTE}/${vendor}/${vendorName}/${metricDomain}`)}),
    };
})();

export const VENDOR_QUANTS = (function () {
    const ROUTE = `vendor-quants`;
    return {
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        ROUTE: `${ROUTE}/:vendor/:vendorName`,
        LINK: linkTo.bind({url: ({vendor, vendorName}) => (`${ROUTE}/${vendor}/${vendorName}`)}),
    };
})();

export const VENDOR_QUANTS_DOMAIN = (function () {
    const ROUTE = `vendor-quants-domain`;
    return {
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        ROUTE: `${ROUTE}/:vendor/:vendorName/:metricDomain`,
        LINK: linkTo.bind({url: ({vendor, vendorName, metricDomain}) => (`${ROUTE}/${vendor}/${vendorName}/${metricDomain}`)}),
    };
})();

export const SYSTEM_QUALS = (function () {
    const ROUTE = `system-quals`;
    return {
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        ROUTE: `${ROUTE}/:system/:systemName`,
        LINK: linkTo.bind({url: ({system, systemName}) => (`${ROUTE}/${system}/${systemName}`)}),
    };
})();

export const SYSTEM_QUALS_DOMAIN = (function () {
    const ROUTE = `system-quals-domain`;
    return {
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        ROUTE: `${ROUTE}/:system/:systemName/:metricDomain`,
        LINK: linkTo.bind({url: ({system, systemName, metricDomain}) => (`${ROUTE}/${system}/${systemName}/${metricDomain}`)}),
    };
})();

export const SYSTEM_QUANTS = (function () {
    const ROUTE = `system-quants`;
    return {
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        ROUTE: `${ROUTE}/:system/:systemName`,
        LINK: linkTo.bind({url: ({system, systemName}) => (`${ROUTE}/${system}/${systemName}`)}),
    };
})();

export const SYSTEM_QUANTS_DOMAIN = (function () {
    const ROUTE = `system-quants-domain`;
    return {
        REGEXP: new RegExp(`${ROUTE}.*`, 'i'),
        ROUTE: `${ROUTE}/:system/:systemName/:metricDomain`,
        LINK: linkTo.bind({url: ({system, systemName, metricDomain}) => (`${ROUTE}/${system}/${systemName}/${metricDomain}`)}),
    };
})();

export const SUBSIDIARIES = (function () {
    const ROUTE = `subsidiaries`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        EDIT: `subsidiaries/edit/:id`,
        TABS: `tabs/:tab`,
        LIST: `tabs/list`,
        SCHEME: `tabs/scheme`,
        LINK: linkTo.bind({url: ({tab}) => (`${PRIVATE}/${ROUTE}/tabs/${tab || 'list'}`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const IMPORT_BUSINESS_UNITS = {
    REGEXP: new RegExp(`${PRIVATE}/import-business-units.*`, 'i'),
    ROUTE: PRIVATE+'/import-business-units',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-business-units`)}),
};

export const IMPORT_CONTROL_GUIDELINES = {
    REGEXP: new RegExp(`${PRIVATE}/import-control-guidelines.*`, 'i'),
    ROUTE: PRIVATE+'/import-control-guidelines',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-control-guidelines`)}),
};

export const IMPORT_GDPR_ARTICLES = {
    REGEXP: new RegExp(`${PRIVATE}/import-gdpr-articles.*`, 'i'),
    ROUTE: PRIVATE+'/import-gdpr-articles',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-gdpr-articles`)}),
};

export const IMPORT_PROCESSES = {
    REGEXP: new RegExp(`${PRIVATE}/import-processes.*`, 'i'),
    ROUTE: 'import-processes',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-processes`)}),
};

export const IMPORT_QUAL_QUESTIONS = {
    REGEXP: new RegExp(`${PRIVATE}/import-questions.*`, 'i'),
    ROUTE: PRIVATE+'/import-questions',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-questions`)}),
};

export const IMPORT_QUAL_QUESTION_ANSWERS = {
    REGEXP: new RegExp(`${PRIVATE}/import-answers.*`, 'i'),
    ROUTE: PRIVATE+'/import-answers',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-answers`)}),
};

export const IMPORT_SYSTEMS_RISK = {
    REGEXP: new RegExp(`${PRIVATE}/import-systems-risk.*`, 'i'),
    ROUTE: PRIVATE+'/import-systems-risk',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-systems-risk`)}),
};

export const IMPORT_USERS = {
    REGEXP: new RegExp(`${PRIVATE}/import-users.*`, 'i'),
    ROUTE: 'import-users',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-users`)}),
};

export const IMPORT_VENDORS = {
    REGEXP: new RegExp(`${PRIVATE}/import-vendors.*`, 'i'),
    ROUTE: PRIVATE+'/import-vendors',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-vendors`)}),
};

export const IMPORT_SUBSIDIARIES = {
    REGEXP: new RegExp(`${PRIVATE}/import-subsidiaries.*`, 'i'),
    ROUTE: PRIVATE+'/import-subsidiaries',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-subsidiaries`)}),
};

export const IMPORT_TECHNOLOGIES = {
    REGEXP: new RegExp(`${PRIVATE}/import-technologies.*`, 'i'),
    ROUTE:'import-technologies',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-technologies`)}),
};

export const IMPORT_GDPR_EVIDENCE_ARTICLES = {
    REGEXP: new RegExp(`${PRIVATE}/import-gdpr-evidence-articles.*`, 'i'),
    ROUTE: 'import-gdpr-evidence-articles',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-gdpr-evidence-articles`)}),
};

export const IMPORT_ASSESSMENT_FRAMEWORK = {
    REGEXP: new RegExp(`${PRIVATE}/import-assessment-framework.*`, 'i'),
    ROUTE: PRIVATE+'/import-assessment-framework',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-assessment-framework`)}),
};

export const IMPORT_SECURITY_REQUIREMENTS = {
    REGEXP: new RegExp(`${PRIVATE}/import-security-requirements.*`, 'i'),
    ROUTE: PRIVATE+'/import-security-requirements',
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/import-security-requirements`)}),
};

export const CYBER_RISK_SCORING = (function () {
    const ROUTE = `cyber-risk-scoring`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        QUESTIONS: `questions/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_QUESTIONS: linkTo.bind({url: ({id}) => (`${PRIVATE + '/' + ROUTE}/questions/${id}`)}),
    };
})();

export const CLOUD_SCORING = (function () {
    const ROUTE = `cloud-scoring`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        QUESTIONS: `questions/:group/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_QUESTIONS: linkTo.bind({url: ({id, group = 'scoring'}) => (`${PRIVATE}/${ROUTE}/questions/${group}/${id}`)}),
    };
})();

export const DRILLDOWN_PAGE = {
    REGEXP: new RegExp(`${PRIVATE}/drilldown.*`, 'i'),
    ROUTE: PRIVATE + '/drilldown',
    LINK: linkTo.bind({url: () => (`${PRIVATE}/drilldown`)}),
};

export const OPERATIONAL_COSTS = (function () {
    const ROUTE = `operational-costs`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CAPITAL_COSTS = (function () {
    const ROUTE = `capital-costs`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const VARIABLE_COSTS = (function () {
    const ROUTE = `variable-costs`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const SECURITY_TOOLS = (function () {
    const ROUTE = `security-tools`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CYBER_SECURITY_TOOLS = (function () {
    const ROUTE = `cyber-security-tools`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CYBER_ROLES = (function () {
    const ROUTE = `cyber-roles`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const SYSTEM_RISK_REDUCTION = (function () {
    const ROUTE = `system-risk-reduction`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const AUDIT_LOGS = (function () {
    const ROUTE = `audit-logs`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `details/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/details/${id}`)}),
        SYSTEM_LINK: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/list?itemId=${id}&tId=51&tName=System`)}),
    };
})();

export const GDPR_COMPLIANCE = (function () {
    const ROUTE = `gdpr-compliance`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        STATUS_LOG: `status-log`,
        QUESTIONS: `questions`,
        EDIT: `edit/:id/article/:articleId/paragraph/:paragraphId`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_STATUS_LOG: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/status-log`)}),
        LINK_QUESTIONS: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/questions`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID, articleId = NEW_ID, paragraphId = NEW_ID}) => (`/private/${ROUTE}/edit/${id}/article/${articleId}/paragraph/${paragraphId}`) }),
    };
})();

export const GDPR_SYSTEM_COMPLIANCE = (function () {
    const ROUTE = `gdpr-system-compliance`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        QUESTIONS: `questions/:id`,
        DRILLDOWN: `drilldown/:systemId`,
        SYSTEM_LOG: `system-log/:systemId`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_QUESTIONS: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/questions/${id}`)}),
        EDIT: `edit/:id/system/:systemId/article/:articleId/paragraph/:paragraphId`,
        LINK_DRILLDOWN: linkTo.bind({url: ({systemId}) => (`${PRIVATE}/${ROUTE}/drilldown/${systemId}`)}),
        LINK_SYSTEM_LOG: linkTo.bind({url: ({systemId}) => (`${PRIVATE}/${ROUTE}/system-log/${systemId}`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID, systemId = NEW_ID, articleId = NEW_ID, paragraphId = NEW_ID}) => (`${PRIVATE}/${ROUTE}/edit/${id}/system/${systemId}/article/${articleId}/paragraph/${paragraphId}`) }),
    };
})();

export const GDPR_EVIDENCE_DOCUMENTS = (function () {
    const ROUTE = `gdpr-evidence-documents`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id}`)})
    };
})();

export const USER_SETTINGS = (function () {
    const ROUTE = `user-settings`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id}`)})
    };
})();

export const TASKS = (function () {
    const ROUTE = `tasks`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const TASK_CATEGORIES = (function () {
    const ROUTE = `task-categories`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const POLICIES = (function () {
    const ROUTE = `policies`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        ANNUAL_REVIEW: `annual-review/:id`,
        PREVIEW: `preview/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)}),
        LINK_ANNUAL_REVIEW: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/annual-review/${id || NEW_ID}`)}),
        LINK_PREVIEW: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/preview/${id}`)})
    };
})();

export const VENDOR_CONTRACTS = (function () {
    const ROUTE = `vendor-contracts`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const SECURITY_REQUIREMENTS = (function () {
    const ROUTE = `security-requirements`;
    const routes = {};
    const TABS = {
        MAPPING: 'mapping',
        SETTINGS: 'settings',
    };
    for (const name in TABS) { routes[name] = `${ROUTE}/edit/:id/tab/${TABS[name]}`; }
    return {
        TABS,
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id/tab/:tab`,
        EDIT_ROUTE: `edit`,
        EDIT_SETTINGS: `:id/tab/settings`,
        EDIT_MAPPING: `:id/tab/mapping`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID, tab = TABS.SETTINGS}) => (`${PRIVATE}/${ROUTE}/edit/${id}/tab/${tab}`)}),
        ...routes
    };
})();

export const SYSTEM_CONTROL_TEST_RESULTS = (function () {
    const ROUTE = `system-control-test-results`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        DRILLDOWN: `drilldown/:systemId/:assessmentId`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        EDIT: `edit/:id/system/:systemId/requirement/:requirementId`,
        AUDIT: `:auditId/:id/system/:systemId/requirement/:requirementId`,
        LINK_DRILLDOWN: linkTo.bind({url: ({systemId, assessmentId}) => (`${PRIVATE}/${ROUTE}/drilldown/${systemId}/${assessmentId}`)}),
        LINK_EDIT: linkTo.bind({url: ({id = NEW_ID, systemId, requirementId}) => (`${PRIVATE}/${ROUTE}/edit/${id}/system/${systemId}/requirement/${requirementId}`) }),
        LINK_AUDIT: linkTo.bind({url: ({id, systemId, requirementId, auditId}) => (`${PRIVATE}/${ROUTE}/${auditId}/${id}/system/${systemId}/requirement/${requirementId}`) }),
    };
})();

export const ORGANIZATION_RISK_SCORING = {
    ROUTE: `${PRIVATE}/organization-risk-scoring`,
    REGEXP: new RegExp(`${PRIVATE}/organization-risk-scoring`, 'i'),
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/organization-risk-scoring`)}),
};

export const CYBERSECURITY_MATURITY = {
    ROUTE: `${PRIVATE}/cybersecurity-maturity`,
    SUBPAGE_ROUTE_NAVIGATE: `${PRIVATE}/cybersecurity-maturity/:subPage`,
    SUBPAGE_ROUTE: `${PRIVATE}/:subPage/cybersecurity-maturity`,
    REGEXP: new RegExp(`${PRIVATE}/cybersecurity-maturity`, 'i'),
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/cybersecurity-maturity`)}),
};

export const RISK_METRICS = (function () {
    const ROUTE = `risk-metrics`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const CONTROL_MATURITIES = (function () {
    const ROUTE = `control-maturities`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const VULNERABILITIES = (function () {
    const ROUTE = `vulnerabilities`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `list`,
        EDIT: `edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const MY_TASKS = (function () {
    const ROUTE = `my-tasks`;
    return {
        ROUTE,
        REGEXP: new RegExp(`${ROUTE}/*`, 'i'),
        LIST: `${ROUTE}/list`,
        EDIT: `${ROUTE}/edit/:id`,
        LINK: linkTo.bind({url: () => (`${PRIVATE + '/' + ROUTE}/list`)}),
        LINK_EDIT: linkTo.bind({url: ({id}) => (`${PRIVATE}/${ROUTE}/edit/${id || NEW_ID}`)})
    };
})();

export const PRIVACY_IMPACT_ASSESSMENT = {
    ROUTE: PRIVATE+'/privacy-impact-assessment/:id',
    REGEXP: new RegExp(`${PRIVATE}/privacy-impact-assessment.*`, 'i'),
    LINK: linkTo.bind({url: ({id}) => (`${PRIVATE}/privacy-impact-assessment/${id}`)})
};

const downloadLink = (endpoint) => (params) => (
    `${API_PATH}/${endpoint}?${queryParams({token: accessToken(), riskModelId: getRiskModelId(), ...params})}`
);
export const DOWNLOAD = {
    BUSINESS_UNITS: {
        CSV_TEMPLATE_LINK: linkTo.bind({url: downloadLink('business-units/download-csv-template')}),
        CSV_LIST_LINK: linkTo.bind({url: downloadLink('business-units/download-as-csv')}),
        CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/business-units/export')}),
    },
    USERS_CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/users/export')}),
    SYSTEM_CONTROL_TEST_RESULTS_CSV_LINK: linkTo.bind({url: ({ itemId }) => downloadLink(`system-requirement-control-test-results/download/csv/${itemId}`)()}),
    VENDORS_CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/vendors/export')}),
    SUBSIDIARIES_CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/subsidiaries/export')}),
    GDPR_ARTICLES_CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/gdpr-articles/export')}),
    SYSTEMS_RISK_CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/systems-risk-data/export')}),
    QUAL_QUESTIONS_CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/qual-questions/export')}),
    CONTROL_GUIDELINES_CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/control-guidlines/export')}),
    SECURITY_REQUIREMENTS_CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/security-requirements/export')}),
    QUANT_METRICS_CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/quant-metrics/export')}),
    QUAL_QUESTION_ANSWERS_CSV_LINK: linkTo.bind({url: params => downloadLink('data-export/csv/qual-questions/answers/export')(params) }),
    EVIDENCE_DOCUMENT_LINK: ({downloadUrl}) => {
        let delimiter = downloadUrl.indexOf("?") !== -1 ? "&" : "?";
        return `${downloadUrl}${delimiter}${queryParams({token: accessToken(), riskModelId: getRiskModelId() })}`;
    },
    QUALITATIVE_QUESTION_ANSWER_DOCUMENT_LINK: ({downloadUrl}) => {
        let delimiter = downloadUrl.indexOf("?") !== -1 ? "&" : "?";
        return `${downloadUrl}${delimiter}${queryParams({token: accessToken(), riskModelId: getRiskModelId() })}`;
    },
    ASSESSMENT_FRAMEWORK_CSV_LINK: linkTo.bind({url: ({itemId}) => downloadLink(`data-export/csv/assessment-frameworks/export/${itemId}`)() }),
    PROCESSES: {
        CSV_TEMPLATE_LINK: linkTo.bind({url: downloadLink('data-export/csv/processes/download-template')}),
        CSV_LIST_LINK: linkTo.bind({url: downloadLink('data-export/csv/processes/export')}),
        CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/processes/export')}),
    },
    TECHNOLOGIES: {
        CSV_TEMPLATE_LINK: linkTo.bind({url: downloadLink('data-export/csv/technologies/download-template')}),
        CSV_LIST_LINK: linkTo.bind({url: downloadLink('data-export/csv/technologies/export')}),
        CSV_LINK: linkTo.bind({url: downloadLink('data-export/csv/technologies/export')}),
    },
};

// for debugging only
export const MATERIAL_DEMO = {
    LINK: linkTo.bind({url: ()=> (`${PRIVATE}/material-demo`)}),
    ROUTE: PRIVATE+'/material-demo',
    REGEXP: new RegExp(`${PRIVATE}/material-demo.*`, 'i'),
};

/**
 * @param { Object } options -
 * @returns { String }
 * @function linkTo
 * @private
 */
function linkTo ( options={} ) {
    let opt = {}; // prepare options to setting in url
    let query = options.query || {};
    let search = queryService.format(query);
    for ( let key in options ) if ( is.string(options[key]) || is._number(options[key]) ) {
        opt[key] = encodeURIComponent(options[key]);
    }

    // config.DEBUG&& // log debug
    // console.log('%c linkTo => ( options ) ', 'color: #0747a6; font-weigth: bolder; font-size: 12px;'
    //     ,'\n routeData:', this
    //     ,'\n options:', options
    //     ,'\n formatted:', this.url(opt)
    // );
    return this.url(opt)+search;
}

/**
 * method to format object data to url query without encodeURIComponent
 * @param {Object} params
 * @returns {String}
 */
function queryParams ( params ) {
    let query = '';
    for ( const name in params ) {
        if (params[name]) {
            query += `${name}=${params[name]}&`;
        }
    }
    // NOTE remove last "&"
    return query.substring(0, query.length-1);
}
