
// outsource dependencies
import React from 'react';

// local dependencies
import logoImage from './riskQ.svg';
// import bookLogoImage from './book_logo.png';
import bookLogoImage from './home-page-image-Pin-Point-your-Cyber-RiskQ.jpg';
import publicLogoImage from './public_logo.svg';
import defaultAvatar from './default_avatar.svg';
import additionalPublicLogoImage from './valurisq-logo.svg';
import HebFlag from '../images/heb.png'
import RusFlag from '../images/rus.png'
import LcdFlag from '../images/lcd.png'
import EngFlag from '../images/eng.png'


// aliases for export
export {
    Logo,
    Avatar,
    BookLogo,
    PublicLogo,
    AdditionalPublicLogo,
    selectImageSrc
};

function selectImageSrc(language) {
    let languageSrc;
    switch (language) {
        default:
            languageSrc = LcdFlag;
            break;
        case 'eng':
            languageSrc = EngFlag;
            break;
        case 'lcd':
            languageSrc = LcdFlag;
            break;
        case 'heb':
            languageSrc = HebFlag;
            break;
        case 'rus':
            languageSrc = RusFlag;
            break;
    }
    return languageSrc;
}

/**
 * Wrapped logo image from book cover
 * @public
 */
function BookLogo ( props ) {
    return ( <img
        src={bookLogoImage}
        alt="Cyber Innovative Technologies Logo"
        style={{
            opacity: .85,
            maxWidth: '100%',
            borderRadius: '5%',
            // boxShadow: '0 0 50px 10px #081C3D',
            boxShadow: 'rgb(8, 28, 61) 0px 0px 30px 20px',
        }}
        {...props}
            />
    );
}

/**
 * Wrapped logo image
 * @public
 */
function Logo ( props ) {
    return ( <img src={logoImage} {...props} alt={props.alt||'Cyber Innovative Technologies Logo'} /> );
}

/**
 * Wrapped public logo image
 * @public
 */
function PublicLogo ( props ) {
    return ( <img src={publicLogoImage} {...props} alt={props.alt||'Cyber Innovative Technologies Logo'} /> );
}

/**
 * Wrapped additional public logo image
 * @public
 */
function AdditionalPublicLogo ( props ) {
    return ( <img src={additionalPublicLogoImage} {...props} alt={props.alt||'Cyber Innovative Technologies Logo'} /> );
}

/**
 * Wrapped default avatar
 * @public
 */
function DefAvatar ( props ) {
    return ( <img style={{'borderRadius': '50%'}} {...props} src={defaultAvatar} alt={props.alt||'User'} /> );
}

/**
 * Wrapped Avatar with default image image
 * @public
 */
function Avatar ( props ) {
    return (props.src ? <img {...props} style={{'borderRadius': '50%'}} alt={props.alt} /> : <DefAvatar {...props} />);
}

