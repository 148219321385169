// outsource dependencies
import is from '../services/is.service';

// local dependencies
import BaseModel from './base.model';

/**
 *
 *
 * @constructor UserRoleModel
 * @type {UserRoleModel}
 * @public
 */
class UserPermissionModel extends BaseModel {
    constructor ( data = {} ) {
        super(data);
        // copy all inherited
    }

    /**
     * setup default data for model
     *
     * @public
     */
    init () {
        !is.array(this.permissions) && (this.permissions = []);
    }

    /**
     * Check is user have corresponding permission
     *
     * @param user
     * @param permission
     * @returns {boolean|boolean}
     */
    static checkUserPermission(user, permission) {
        if (!user || !user.permissions) return false;

        return !permission || (user.permissions || []).includes(permission);
    }

}

export default UserPermissionModel;
