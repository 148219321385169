
// outsource dependencies
import { connect } from 'react-redux';
import { get, camelCase } from 'lodash';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { Card, CardContent, CardActions, CardHeader } from '@mui/material';

// local dependencies
import { filters } from './filter';
import MdSwitch from './md-switch';
import SelectEntities from './select-entities';
import { ENTITY_TYPES } from '../constants/spec';
import { WarningBtn, PrimaryBtn } from './md-button';
import { instanceAPI } from '../services/api.service';
import { MdSelect, MdAsyncSelect } from './md-select';
import { translate, withTranslation } from '../services/translate.service';
import { formatBusinessUnitLabel } from '../services/data-formatting.service';

// config
const QUESTION_TYPES = {
    CLOUD: 'Cloud',
    VENDOR: 'Vendor',
    SYSTEM: 'Organization'
};

const FILTER_TYPES = {
    NAME : 'NAME',
    PRIVACY: 'PRIVACY',
    QUESTION: 'QUESTION',
    BUSINESS_UNIT: 'BUSINESS_UNIT',
    SYSTEM_QUESTION: 'SYSTEM_QUESTION',
    VENDOR_QUESTION: 'VENDOR_QUESTION',
    BUSINESS_UNITS_AND_USERS: 'BUSINESS_UNITS_AND_USERS',
};

export const DashboardFilterPanel = withTranslation(({values, handleFilterPanelState, index, isOpen, dashboardFilterType, name, ...attr}) => {
    let FilterComponent = getFilterByType(dashboardFilterType);
    // NOTE doesn't show filter if it's close or unknown type
    if ( !isOpen || !FilterComponent ) { return null; }
    return <FilterComponent {...attr} index={index} form={camelCase(name+index)} initialValues={values} cancel={()=>handleFilterPanelState(index, false)} dashboardFilterType={dashboardFilterType} />;
});

/**
 * Associate filter with on FILTER_TYPES constant to provide ability determine Models programmatically
 *
 * @param { String } type
 * @public
 */
function getFilterByType ( type ) {
    switch ( type ) {
        default: console.log(`%c getFilterByType ${type} !!!`, 'color: #fff; background: #a41e22; font-size: 20px;');
            return null;
        case FILTER_TYPES.QUESTION:
        case FILTER_TYPES.VENDOR_QUESTION:
            return QuestionsFilter;
        case FILTER_TYPES.PRIVACY: return PrivacyFilter;
        case FILTER_TYPES.SYSTEM_QUESTION: return SystemQuestionFilter;
        case FILTER_TYPES.BUSINESS_UNITS_AND_USERS: return BusinessUnitsAndUsersFilter;
    }
}

/**
 * Question Form for dashboard filter
 *
 * @param {Object} props
 */
class QuestionForm extends Component {
    constructor ( props ) {
        super(props);
        this.state = {
            questions: [],
        }
    }
    componentDidMount() {
        this.getQuestions();
    }

    getQuestions (options) {
        let { riskModel, change, formsReducer, form } = this.props;
        let values = formsReducer[form].values;
        let filters = {...values, ...options};
        // NOTE get filtered questions list and update state
        instanceAPI({
            method: 'post',
            url: `/risk-model/${riskModel.id}/qualitative-questions/filter`,
            data: { filter: {question: '', metricDomain: get(filters, 'metricDomain.code', null), vendorType: get(filters, 'vendorType.value', null)} }
        }).then(({items})=>this.setState({questions: items}));
        // NOTE clear 'questions' filter
        change('question', null);
    }

    render() {
        let { questions } = this.state;
        let { updateData, cancel, handleSubmit, index, dashboardFilterType, invalid, disabled, riskModel, formsReducer, form } = this.props;
        let { metricDomain = null, vendorType = null } = formsReducer[form].values;
        return (
            <form autoComplete="off" name="questionFilter" onSubmit={handleSubmit(data=>updateData(data, index))}>
                <Card style={{overflow: 'visible'}} className="offset-bottom-4">
                    <CardHeader title={translate('GLOBALS$SEARCH')} />
                    <CardContent>
                        <Row>
                            <Col xs={12} md={6} className="offset-bottom-2">
                                <SelectEntities
                                    disabled={disabled}
                                    isClearable={true}
                                    name="metricDomain"
                                    type={ENTITY_TYPES.METRIC_DOMAINS}
                                    placeholder={translate('SCORING_METRICS$METRIC_DOMAIN')}
                                    onChange={metricDomain=>this.getQuestions({metricDomain})}
                                    label={(<strong> {translate('SCORING_METRICS$METRIC_DOMAIN')} </strong>)}
                                        />
                            </Col>
                            {dashboardFilterType === FILTER_TYPES.VENDOR_QUESTION && (
                                <Col xs={12} md={6} className="offset-bottom-2">
                                    <Field
                                        valueKey="value"
                                        labelKey="label"
                                        name="vendorType"
                                        isClearable={true}
                                        component={MdSelect}
                                        disabled={disabled}
                                        onChange={vendorType=>this.getQuestions({vendorType})}
                                        placeholder={translate('SCORING_QUESTIONS$QUESTION_TYPE')}
                                        label={(<strong> {translate('SCORING_QUESTIONS$QUESTION_TYPE')} </strong>)}
                                        options={[QUESTION_TYPES.CLOUD, QUESTION_TYPES.VENDOR].map(key=>({value: key, label: key}))}
                                            />
                                </Col>
                            )}
                            {dashboardFilterType === FILTER_TYPES.QUESTION && (
                                <Col xs={12} md={6} className="offset-bottom-2">
                                    <Field
                                        valueKey="value"
                                        labelKey="label"
                                        name="vendorType"
                                        isClearable={true}
                                        component={MdSelect}
                                        disabled={disabled}
                                        onChange={vendorType=>this.getQuestions({vendorType})}
                                        placeholder={translate('SCORING_QUESTIONS$QUESTION_TYPE')}
                                        label={(<strong> {translate('SCORING_QUESTIONS$QUESTION_TYPE')} </strong>)}
                                        options={Object.keys(QUESTION_TYPES).map(key=>({value: QUESTION_TYPES[key], label: filters.humanize(key)}))}
                                            />
                                </Col>
                            )}
                            <Col xs={12} className="offset-bottom-2">
                                <Field
                                    name="question"
                                    labelKey="question"
                                    isClearable={true}
                                    disabled={disabled}
                                    component={MdAsyncSelect}
                                    defaultOptions={questions}
                                    placeholder={translate('SCORING_QUESTIONS$QUESTION')}
                                    required={true}
                                    label={(<strong className="required-asterisk"> {translate('SCORING_QUESTIONS$QUESTION')} </strong>)}
                                    loadOptions={(question, done) => {
                                        instanceAPI({
                                            method: 'post',
                                            url: `/risk-model/${riskModel.id}/qualitative-questions/filter`,
                                            data: { filter: { question, metricDomain: get(metricDomain, 'code', null), vendorType: get(vendorType, 'value', null)}},
                                        }).then(({items})=>done(items)).catch(done.bind(null, []));
                                    }}/>
                            </Col>
                        </Row>
                    </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn type="submit" tooltip={translate('GLOBALS$APPLY')} disabled={invalid||disabled}>
                            {translate('GLOBALS$APPLY')}
                        </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn onClick={cancel} tooltip={translate('GLOBALS$CANCEL')}> {translate('GLOBALS$CANCEL')} </WarningBtn>
                    </CardActions>
                </Card>
            </form>
        );
    }

}

const QuestionsFilter = reduxForm({form: 'setupFromParent', enableReinitialize: true, validate})(
    connect( state => ({riskModel: state.app.riskModel, formsReducer: state.form}), null )(QuestionForm)
);

/**
 * Question Form for dashboard filter
 *
 * @param {Object} props
 */
class SystemQuestionForm extends Component {
    constructor ( props ) {
        super(props);
        this.state = {
            questions: [],
        }
    }
    componentDidMount() {
        this.getQuestions();
    }

    getQuestions (options) {
        let { riskModel, change, formsReducer, form } = this.props;
        let values = formsReducer[form].values;
        let filters = {...values, ...options};
        // NOTE get filtered questions list and update state
        instanceAPI({
            method: 'post',
            url: `/risk-model/${riskModel.id}/qualitative-questions/filter`,
            data: { filter: {question: '', metricDomain: get(filters, 'metricDomain.code', null), vendorType: QUESTION_TYPES.SYSTEM} }
        }).then(({items})=>this.setState({questions: items}));
        // NOTE clear 'questions' filter
        change('question', null);
    }

    render() {
        let { questions } = this.state;
        let { updateData, cancel, handleSubmit, index, invalid, disabled, riskModel, form, formsReducer } = this.props;
        let { metricDomain = null } = formsReducer[form].values;
        return (
            <form autoComplete="off" name="questionFilter" onSubmit={handleSubmit(data=>updateData(data, index))}>
                <Card style={{overflow: 'visible'}} className="offset-bottom-4">
                    <CardHeader title={translate('GLOBALS$SEARCH')} />
                    <CardContent>
                        <Row>
                            <Col xs={12} lg={4} className="offset-bottom-2">
                                <SelectEntities
                                    disabled={disabled}
                                    isClearable={true}
                                    name="metricDomain"
                                    type={ENTITY_TYPES.METRIC_DOMAINS}
                                    onChange={metricDomain=>this.getQuestions({metricDomain})}
                                    placeholder={translate('SCORING_METRICS$METRIC_DOMAIN')}
                                    label={(<strong> {translate('SCORING_METRICS$METRIC_DOMAIN')} </strong>)}
                                        />
                            </Col>
                            <Col xs={12} lg={8} className="offset-bottom-2">
                                <Field
                                    name="question"
                                    labelKey="question"
                                    isClearable={true}
                                    disabled={disabled}
                                    component={MdAsyncSelect}
                                    defaultOptions={questions}
                                    placeholder={translate('SCORING_QUESTIONS$QUESTION')}
                                    required={true}
                                    label={(<strong className="required-asterisk"> {translate('SCORING_QUESTIONS$QUESTION')} </strong>)}
                                    loadOptions={(question, done) => {
                                        instanceAPI({
                                            method: 'post',
                                            url: `/risk-model/${riskModel.id}/qualitative-questions/filter`,
                                            data: { filter: { question, metricDomain: get(metricDomain, 'code', null), vendorType: QUESTION_TYPES.SYSTEM}},
                                        }).then(({items})=>done(items)).catch(done.bind(null, []));
                                    }}/>
                            </Col>
                        </Row>
                    </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn type="submit" tooltip={translate('GLOBALS$APPLY')} disabled={invalid||disabled}>
                            {translate('GLOBALS$APPLY')}
                        </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn onClick={cancel} tooltip={translate('GLOBALS$CANCEL')}> {translate('GLOBALS$CANCEL')} </WarningBtn>
                    </CardActions>
                </Card>
            </form>
        );
    }
}

const SystemQuestionFilter = reduxForm({form: 'setupFromParent', enableReinitialize: true, validate})(
    connect( state => ({riskModel: state.app.riskModel, formsReducer: state.form}), null )(SystemQuestionForm)
);

function validate (values) {
    let errors = {};
    // name
    if (!values.question) {
        errors.question = 'SCORING_QUESTIONS$QUESTION_REQUIRED';
    }
    return errors;
}

/**
 * Privacy Filter for dashboard
 *
 * @param {Object} props
 */
const PrivacyFilter = reduxForm({form: 'setupFromParent', enableReinitialize: true})(
    ({ updateData, cancel, handleSubmit, index, invalid, disabled, form }) => (
        <form autoComplete="off" name="privacyFilter" onSubmit={handleSubmit(data=>updateData(data, index))}>
            <Card style={{overflow: 'visible'}} className="offset-bottom-4">
                <CardHeader title={translate('GLOBALS$SEARCH')} />
                <CardContent>
                    <Row>
                        <Col xs={12} className="offset-bottom-2">
                            <SelectEntities
                                isMulti={true}
                                disabled={disabled}
                                isClearable={true}
                                name="businessUnits"
                                type={ENTITY_TYPES.BUSINESS_UNITS}
                                placeholder={translate('BUSINESS_UNITS$TITLE')}
                                getOptionLabel={item => formatBusinessUnitLabel(item)}
                                label={(<strong> {translate('BUSINESS_UNITS$TITLE')} </strong>)}
                                    />
                        </Col>
                        <Col xs={12} className="offset-bottom-2">
                            <SelectEntities
                                isMulti={true}
                                disabled={disabled}
                                isClearable={true}
                                name="dataAssetClassification"
                                type={ENTITY_TYPES.ASSET_CLASSES}
                                placeholder={translate('ASSET_CLASSES$TITLE')}
                                label={(<strong> {translate('ASSET_CLASSES$TITLE')} </strong>)}
                                    />
                        </Col>
                        <Col xs={12} className="text-center offset-bottom-2">
                            <Field
                                color="primary"
                                fullWidth={false}
                                disabled={disabled}
                                component={MdSwitch}
                                name="isPrivacyData"
                                label={(<strong> {translate('DASHBOARDS_FILTER$PRIVACY_DATA')} </strong>)}
                                    />
                        </Col>
                    </Row>
                </CardContent>
                <CardActions style={{justifyContent: 'flex-end'}}>
                    <PrimaryBtn type="submit" tooltip={translate('GLOBALS$APPLY')} disabled={invalid||disabled}>
                        {translate('GLOBALS$APPLY')}
                    </PrimaryBtn>
                    &nbsp;&nbsp;
                    <WarningBtn onClick={cancel} tooltip={translate('GLOBALS$CANCEL')}> {translate('GLOBALS$CANCEL')} </WarningBtn>
                </CardActions>
            </Card>
        </form>
    )
);

/**
 * Business Units and Users Filter for dashboard
 *
 * @param {Object} props
 */
const BusinessUnitsAndUsersFilter = reduxForm({form: 'setupFromParent', enableReinitialize: true})(
    ({ updateData, cancel, handleSubmit, index, invalid, disabled }) => (
        <form autoComplete="off" name="BusinessUnitsAndUsersFilter" onSubmit={handleSubmit(data=>updateData(data, index))}>
            <Card style={{overflow: 'visible'}} className="offset-bottom-4">
                <CardHeader title={translate('GLOBALS$SEARCH')} />
                <CardContent>
                    <Row>
                        <Col xs={12} className="offset-bottom-2">
                            <SelectEntities
                                isMulti={true}
                                disabled={disabled}
                                isClearable={true}
                                name="businessUnits"
                                type={ENTITY_TYPES.BUSINESS_UNITS}
                                placeholder={translate('BUSINESS_UNITS$TITLE')}
                                getOptionLabel={item => formatBusinessUnitLabel(item)}
                                label={(<strong> {translate('BUSINESS_UNITS$TITLE')} </strong>)}
                                    />
                        </Col>
                        <Col xs={12} className="offset-bottom-2">
                            <SelectEntities
                                name="users"
                                isMulti={true}
                                labelKey="fullName"
                                disabled={disabled}
                                isClearable={true}
                                type={ENTITY_TYPES.USERS}
                                placeholder={translate('USERS$TITLE')}
                                label={(<strong> {translate('USERS$TITLE')} </strong>)}
                                    />
                        </Col>
                    </Row>
                </CardContent>
                <CardActions style={{justifyContent: 'flex-end'}}>
                    <PrimaryBtn type="submit" tooltip={translate('GLOBALS$APPLY')} disabled={invalid||disabled}>
                        {translate('GLOBALS$APPLY')}
                    </PrimaryBtn>
                    &nbsp;&nbsp;
                    <WarningBtn onClick={cancel} tooltip={translate('GLOBALS$CANCEL')}> {translate('GLOBALS$CANCEL')} </WarningBtn>
                </CardActions>
            </Card>
        </form>
    )
);
