
// outsource dependencies

// local dependencies

import {createTypes} from '../../actions/types';

export default (prefix => {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@import-assessment-framework/');
