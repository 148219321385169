// outsource dependencies
import {cloneDeep, filter, reject, some, uniq} from 'lodash';

// local dependencies
import * as ROUTES from '../../constants/routes';
import {MENU_ITEM_TYPE, PERMISSION} from '../../constants/spec';

// config
const PERMISSION_CHECK_TYPES = {
    SOME: 'some',
    INCLUDE: 'include',
    NO_CHECK: 'no_check',
};

let MENU = [
{
    disabled: false,
    type: MENU_ITEM_TYPE.LINK,
    name: 'MENU$HOME_PAGE',
    icon: 'fa fa-home',
    link: ROUTES.HOME.LINK(),
    permission: {type: PERMISSION_CHECK_TYPES.NO_CHECK},
    // NOTE ability to custom detection of active state
    isActive: (s, location) => ROUTES.HOME.REGEXP.test(location.pathname)
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'ORGANIZATION$TITLE',
    icon: 'fa fa-university',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'ORGANIZATION$TITLE',
        icon: 'fa fa-university',
        link: ROUTES.ORGANIZATION.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ORGANIZATION.READ},
        isActive: (s, location) => ROUTES.ORGANIZATION.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'ORGANIZATION$CYBER_INSURANCE_INFORMATION',
        icon: 'fa fa-dollar',
        link: ROUTES.ORGANIZATION_CYBER_INSURANCE_INFO.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ORGANIZATION.CYBER_INSURANCE_INFORMATION},
        isActive: (s, location) => ROUTES.ORGANIZATION_CYBER_INSURANCE_INFO.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'ORGANIZATION$CYBER_RISK_TOLERANCE',
        icon: 'fa fa-money',
        link: ROUTES.ORGANIZATION_CYBER_RISK_TOLERANCE.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ORGANIZATION.CYBER_INSURANCE_INFORMATION},
        isActive: (s, location) => ROUTES.ORGANIZATION_CYBER_RISK_TOLERANCE.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SUBSIDIARY_ORGANIZATIONS$TITLE',
        icon: 'fa fa-building',
        link: ROUTES.SUBSIDIARIES.LINK({tab: 'scheme'}),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.SUBSIDIARY.READ},
        isActive: (s, location) => ROUTES.SUBSIDIARIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'BUSINESS_UNITS$TITLE',
        icon: 'fa fa-briefcase',
        link: ROUTES.BUSINESS_UNITS.LINK({tab: 'scheme'}),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.BUSINESS_UNIT.READ},
        isActive: (s, location) => ROUTES.BUSINESS_UNITS.REGEXP.test(location.pathname)
    }]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'MENU$DIGITAL_ASSET_INVENTORY',
    icon: 'fa fa-files-o',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'ASSET_CLASSES$TITLE',
        icon: 'fa fa-files-o',
        link: ROUTES.ASSET_CLASSES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ASSET_CLASS.READ},
        isActive: (s, location) => ROUTES.ASSET_CLASSES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'DATA_CLASSES$TITLE',
        icon: 'fa fa-database',
        link: ROUTES.DATA_CLASSES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.DATA_CLASS.READ},
        isActive: (s, location) => ROUTES.DATA_CLASSES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'DATA_DOMAINS$TITLE',
        icon: 'fa fa-server',
        link: ROUTES.DATA_DOMAINS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.DATA_DOMAIN.READ},
        isActive: (s, location) => ROUTES.DATA_DOMAINS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SYSTEMS$TITLE',
        icon: 'fa fa-sitemap',
        link: ROUTES.SYSTEMS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.SYSTEM.READ},
        isActive: (s, location) => ROUTES.SYSTEMS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'PROCESSES$TITLE',
        icon: 'fa fa-code-fork',
        link: ROUTES.PROCESSES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.PROCESS.READ},
        isActive: (s, location) => ROUTES.PROCESSES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'TECHNOLOGY_CATEGORIES$TITLE',
        icon: 'fa fa-th-large',
        link: ROUTES.TECH_CATEGORIES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.TECHNOLOGY_CATEGORY.READ},
        isActive: (s, location) => ROUTES.TECH_CATEGORIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'TECHNOLOGIES$TITLE',
        icon: 'fa fa-wrench',
        link: ROUTES.TECHNOLOGIES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.TECHNOLOGY.READ},
        isActive: (s, location) => ROUTES.TECHNOLOGIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'TECHNOLOGY_ASSETS$TITLE',
        icon: 'fa fa-desktop',
        link: ROUTES.TECHNOLOGY_ASSETS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.TECHNOLOGY.READ},
        isActive: (s, location) => ROUTES.TECHNOLOGY_ASSETS.REGEXP.test(location.pathname)
    },]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'MENU$DATA_CAPTURE',
    icon: 'fa fa-random',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'DATA_EXFILTRATION$TITLE',
        icon: 'fa fa-random',
        link: ROUTES.DATA_EXFILTRATION.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.DATA_EXFILTRATION.READ},
        isActive: (s, location) => ROUTES.DATA_EXFILTRATION.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'BUSINESS_INTERRUPTION$TITLE',
        icon: 'fa fa-chain-broken',
        link: ROUTES.BUSINESS_INTERRUPTION.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.BUSINESS_INTERRUPTION.READ},
        isActive: (s, location) => ROUTES.BUSINESS_INTERRUPTION.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'CYBER_RISK_SCORING$TITLE',
        icon: 'fa fa-tachometer',
        link: ROUTES.CYBER_RISK_SCORING.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.RISK_SCORING.READ},
        isActive: (s, location) => ROUTES.CYBER_RISK_SCORING.REGEXP.test(location.pathname)
    }]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'MENU$DOCUMENTATION_MANAGEMENT',
    icon: 'fa fa-compass',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'CONTRACT_MANAGEMENT$TITLE',
        icon: 'fa fa-clipboard',
        link: ROUTES.VENDOR_CONTRACTS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CONTRACT.READ},
        isActive: (s, location) => ROUTES.VENDOR_CONTRACTS.REGEXP.test(location.pathname)
    },{
        type: MENU_ITEM_TYPE.LINK,
        name: 'POLICY_MANAGEMENT$TITLE',
        icon: 'fa fa-compass',
        link: ROUTES.POLICIES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.POLICY.READ},
        isActive: (s, location) => ROUTES.POLICIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'ORGANIZATION$CYBER_INSURANCE_APPLICATION',
        icon: 'fa fa-money',
        link: ROUTES.ORGANIZATION_CYBER_INSURANCE_APPLICATION.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ORGANIZATION.CYBER_INSURANCE_INFORMATION},
        isActive: (s, location) => ROUTES.ORGANIZATION_CYBER_INSURANCE_APPLICATION.REGEXP.test(location.pathname)
    },

        /*, {
            type: MENU_ITEM_TYPE.LINK,
            name: 'ORGANIZATION_RISK_SCORING$TITLE',
            icon: 'fa fa-braille',
            link: ROUTES.ORGANIZATION_RISK_SCORING.LINK(),
            permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ORGANIZATION_RISK_SCORING.READ},
            isActive: (s, location) => ROUTES.ORGANIZATION_RISK_SCORING.REGEXP.test(location.pathname)
        }*/
    ]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'MENU$ADMINISTRATION',
    icon: 'fa fa-address-book',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'CYBER_ROLES$TITLE',
        icon: 'fa fa-address-book',
        link: ROUTES.CYBER_ROLES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CYBER_ROLE.READ},
        isActive: (s, location) => ROUTES.CYBER_ROLES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'CONTROL_FUNCTIONS$TITLE',
        icon: 'fa fa-dot-circle-o',
        link: ROUTES.CONTROL_FUNCTIONS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.NIST_CSF_CATEGORY.READ},
        isActive: (s, location) => ROUTES.CONTROL_FUNCTIONS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'CONTROL_TESTS$TITLE',
        icon: 'fa fa-linode',
        link: ROUTES.CONTROL_CATEGORIES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CONTROL_TEST.READ},
        isActive: (s, location) => ROUTES.CONTROL_CATEGORIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'CONTROL_GUIDELINES$TITLE',
        icon: 'fa fa-list-alt',
        link: ROUTES.CONTROL_SUBCATEGORIES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CONTROL_GUIDELINE.READ},
        isActive: (s, location) => ROUTES.CONTROL_SUBCATEGORIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'CONTROL_MATURITIES$TITLE',
        icon: 'fa fa-clock-o',
        link: ROUTES.CONTROL_MATURITIES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CONTROL_MATURITY.READ},
        isActive: (s, location) => ROUTES.CONTROL_MATURITIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'USERS$TITLE',
        icon: 'fa fa-users',
        link: ROUTES.USERS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.USER.READ},
        isActive: (s, location) => ROUTES.USERS.REGEXP.test(location.pathname)
    }]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'RISK_MODEL$TITLE',
    icon: 'fa fa-adjust',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'DOMAINS$TITLE',
        icon: 'fa fa-adjust',
        link: ROUTES.DOMAINS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.DOMAIN.READ},
        isActive: (s, location) => ROUTES.DOMAINS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'CATEGORIES$TITLE',
        icon: 'fa fa-folder-open',
        link: ROUTES.CATEGORIES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CATEGORY.READ},
        isActive: (s, location) => ROUTES.CATEGORIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'CONSTANTS$TITLE',
        icon: 'fa fa-at',
        link: ROUTES.CONSTANTS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CONSTANT.READ},
        isActive: (s, location) => ROUTES.CONSTANTS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'EXPOSURE_MODELS$TITLE',
        icon: 'fa fa-cubes',
        link: ROUTES.QUANT_MODELS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.QUANTIFICATION_MODEL.READ},
        isActive: (s, location) => ROUTES.QUANT_MODELS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'EXPOSURE_METRICS$TITLE',
        icon: 'fa fa-bar-chart',
        link: ROUTES.QUANT_METRICS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.QUANTIFICATION_METRIC.READ},
        isActive: (s, location) => ROUTES.QUANT_METRICS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SCORING_METRICS$TITLE',
        icon: 'fa fa-pie-chart',
        link: ROUTES.QUAL_METRICS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.QUALITATIVE_METRIC.READ},
        isActive: (s, location) => ROUTES.QUAL_METRICS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SCORING_QUESTIONS$TITLE',
        icon: 'fa fa-question-circle',
        link: ROUTES.QUAL_QUESTIONS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.QUALITATIVE_QUESTION.READ},
        isActive: (s, location) => ROUTES.QUAL_QUESTIONS.REGEXP.test(location.pathname)
    }, /*{
        type: MENU_ITEM_TYPE.LINK,
        name: 'Risk answers',
        icon: 'fa fa-info-circle',
        link: ROUTES.RISK_ANSWERS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.RISK_ANSWER.READ},
        isActive: (s, location) => ROUTES.RISK_ANSWERS.REGEXP.test(location.pathname)
    },*/ {
        type: MENU_ITEM_TYPE.LINK,
        name: 'RISK_METRICS$TITLE',
        icon: 'fa fa-gg',
        link: ROUTES.RISK_METRICS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.RISK_METRIC.READ},
        isActive: (s, location) => ROUTES.RISK_METRICS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'ASSOCIATE_MODELS$TITLE',
        icon: 'fa fa-link',
        link: ROUTES.ASSOCIATE_MODELS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ASSOCIATE_MODEL.READ},
        isActive: (s, location) => ROUTES.ASSOCIATE_MODELS.REGEXP.test(location.pathname)
    }]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'MENU$SECURITY_ASSESSMENTS',
    icon: 'fa fa-list-ol',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'SECURITY_ASSESSMENTS$TITLE',
        icon: 'fa fa-list-ol',
        link: ROUTES.ASSESSMENT_TYPES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ASSESSMENT_TYPE.READ},
        isActive: (s, location) => ROUTES.ASSESSMENT_TYPES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SECURITY_REQUIREMENTS$TITLE',
        icon: 'fa fa-hand-o-up',
        link: ROUTES.SECURITY_REQUIREMENTS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.SECURITY_REQUIREMENT.READ},
        isActive: (s, location) => ROUTES.SECURITY_REQUIREMENTS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'ASSESSMENTS$TITLE',
        icon: 'fa fa-calculator',
        link: ROUTES.ASSESSMENTS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ASSESSMENT.READ},
        isActive: (s, location) => ROUTES.ASSESSMENTS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'CYBERSECURITY_MATURITY$TITLE',
        icon: 'fa fa-lock',
        link: ROUTES.CYBERSECURITY_MATURITY.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CYBERSECURITY_MATURITY.READ},
        isActive: (s, location) => ROUTES.CYBERSECURITY_MATURITY.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'ORGANIZATION_CONTROL_TEST_RESULTS$TITLE',
        icon: 'fa fa-check-circle',
        link: ROUTES.CONTROL_TEST_RESULTS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CONTROL_TEST_RESULT.READ},
        isActive: (s, location) => ROUTES.CONTROL_TEST_RESULTS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SYSTEM_CONTROL_TEST_RESULTS$TITLE',
        icon: 'fa fa-check-square',
        link: ROUTES.SYSTEM_CONTROL_TEST_RESULTS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.SYSTEM_CONTROL_TEST_RESULT.READ},
        isActive: (s, location) => ROUTES.SYSTEM_CONTROL_TEST_RESULTS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'ASSESSMENT_FINDINGS$TITLE',
        icon: 'fa fa-balance-scale',
        link: ROUTES.ASSESSMENT_FINDINGS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ASSESSMENT_FINDING.READ},
        isActive: (s, location) => ROUTES.ASSESSMENT_FINDINGS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'VULNERABILITIES$TITLE',
        icon: 'fa fa-bug',
        link: ROUTES.VULNERABILITIES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.VULNERABILITY.READ},
        isActive: (s, location) => ROUTES.VULNERABILITIES.REGEXP.test(location.pathname)
    }
    ]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'MENU$VALURISQ_MANAGEMENT',
    icon: 'fa fa-truck',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'TASKS$TITLE',
        icon: 'fa fa-tasks',
        link: ROUTES.TASKS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.TASKS.READ},
        isActive: (s, location) => ROUTES.TASKS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'TASK_CATEGORIES$TITLE',
        icon: 'fa fa-list-alt',
        link: ROUTES.TASK_CATEGORIES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.TASK_CATEGORIES.READ},
        isActive: (s, location) => ROUTES.TASK_CATEGORIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'MY_TASKS$TITLE',
        icon: 'fa fa-book',
        link: ROUTES.MY_TASKS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.MY_TASKS.READ},
        isActive: (s, location) => ROUTES.MY_TASKS.REGEXP.test(location.pathname)
    }]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'MENU$VENDOR_MANAGEMENT',
    icon: 'fa fa-user-secret',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'VENDORS$TITLE',
        icon: 'fa fa-user-secret',
        link: ROUTES.VENDORS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.VENDOR.READ},
        isActive: (s, location) => ROUTES.VENDORS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'CLOUD_SCORING$TITLE',
        icon: 'fa fa-cloud',
        link: ROUTES.CLOUD_SCORING.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CLOUD_SCORING.READ},
        isActive: (s, location) => ROUTES.CLOUD_SCORING.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'ASSOCIATE_SYSTEMS$TITLE',
        icon: 'fa fa-object-ungroup',
        link: ROUTES.ASSOCIATE_SYSTEMS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.ASSOCIATE_SYSTEM.READ},
        isActive: (s, location) => ROUTES.ASSOCIATE_SYSTEMS.REGEXP.test(location.pathname)
    }]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'GDPR$GDPR',
    icon: 'fa fa-object-group',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'MENU$GDPR_ORGANIZATION_COMPLIANCE',
        icon: 'fa fa-object-group',
        link: ROUTES.GDPR_COMPLIANCE.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.GDPR_ORGANIZATION_COMPLIANCE.READ},
        isActive: (s, location) => ROUTES.GDPR_COMPLIANCE.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'MENU$GDPR_SYSTEM_COMPLIANCE',
        icon: 'fa fa-thermometer-half',
        link: ROUTES.GDPR_SYSTEM_COMPLIANCE.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.GDPR_SYSTEM_COMPLIANCE.READ},
        isActive: (s, location) => ROUTES.GDPR_SYSTEM_COMPLIANCE.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'MENU$GDPR_EVIDENCE_DOCUMENTS',
        icon: 'fa fa-clipboard',
        link: ROUTES.GDPR_EVIDENCE_DOCUMENTS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.GDPR_EVIDENCE_DOCUMENT.READ},
        isActive: (s, location) => ROUTES.GDPR_EVIDENCE_DOCUMENTS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'MENU$PRIVACY_IMPACT_ASSESSMENT',
        icon: 'fa fa-id-card-o',
        link: ROUTES.PRIVACY_IMPACT_ASSESSMENT.LINK({id: 2006}),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.PRIVACY_IMPACT_ASSESSMENT.READ},
        isActive: (s, location) => ROUTES.PRIVACY_IMPACT_ASSESSMENT.REGEXP.test(location.pathname)
    }]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'MENU$CYBER_BUDGET',
    icon: 'fa fa-gavel',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'CYBER_SECURITY_TOOLS$TITLE',
        icon: 'fa fa-gavel',
        link: ROUTES.CYBER_SECURITY_TOOLS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CYBER_SECURITY_TOOL.READ},
        isActive: (s, location) => ROUTES.CYBER_SECURITY_TOOLS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'FIXED_OPERATIONAL_COSTS$TITLE',
        icon: 'fa fa-credit-card',
        link: ROUTES.OPERATIONAL_COSTS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.OPERATIONAL_COST.READ},
        isActive: (s, location) => ROUTES.OPERATIONAL_COSTS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'FIXED_CAPITAL_COSTS$TITLE',
        icon: 'fa fa-money',
        link: ROUTES.CAPITAL_COSTS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.CAPITAL_COST.READ},
        isActive: (s, location) => ROUTES.CAPITAL_COSTS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'VARIABLE_COSTS$TITLE',
        icon: 'fa fa-usd',
        link: ROUTES.VARIABLE_COSTS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.VARIABLE_COST.READ},
        isActive: (s, location) => ROUTES.VARIABLE_COSTS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SYSTEM_RISK_REDUCTION$TITLE',
        icon: 'fa fa-level-down',
        link: ROUTES.SYSTEM_RISK_REDUCTION.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.SYSTEM_RISK_REDUCTION.READ},
        isActive: (s, location) => ROUTES.SYSTEM_RISK_REDUCTION.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'USER_SETTINGS$TITLE',
        icon: 'fa fa-cog',
        link: ROUTES.USER_SETTINGS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.USER_SETTINGS.READ},
        isActive: (s, location) => ROUTES.USER_SETTINGS.REGEXP.test(location.pathname)
    }]
}, {
    type: MENU_ITEM_TYPE.MENU,
    name: 'IMPORT_AND_EXPORT$TITLE',
    icon: 'fa fa-inbox',
    list: [{
        type: MENU_ITEM_TYPE.LINK,
        name: 'BUSINESS_UNITS$TITLE',
        icon: 'fa fa-inbox',
        link: ROUTES.IMPORT_BUSINESS_UNITS.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.BUSINESS_UNIT, PERMISSION.IMPORT.BUSINESS_UNIT]
        },
        isActive: (s, location) => ROUTES.IMPORT_BUSINESS_UNITS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'CONTROL_GUIDELINES$TITLE',
        icon: 'fa fa-book',
        link: ROUTES.IMPORT_CONTROL_GUIDELINES.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.CONTROL_GUIDELINE, PERMISSION.IMPORT.CONTROL_GUIDELINE]
        },
        isActive: (s, location) => ROUTES.IMPORT_CONTROL_GUIDELINES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'MENU$GDPR_ARTICLES',
        icon: 'fa fa-file-o',
        link: ROUTES.IMPORT_GDPR_ARTICLES.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.GDPR_ARTICLE, PERMISSION.IMPORT.GDPR_ARTICLE]
        },
        isActive: (s, location) => ROUTES.IMPORT_GDPR_ARTICLES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'MENU$GDPR_DOCUMENTS_MAPPING',
        icon: 'fa fa-map-o',
        link: ROUTES.IMPORT_GDPR_EVIDENCE_ARTICLES.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.IMPORT.GDPR_EVIDENCE_ARTICLES},
        isActive: (s, location) => ROUTES.IMPORT_GDPR_EVIDENCE_ARTICLES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'PROCESSES$TITLE',
        icon: 'fa fa-inbox',
        link: ROUTES.IMPORT_PROCESSES.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.PROCESS, PERMISSION.IMPORT.PROCESS]
        },
        isActive: (s, location) => ROUTES.IMPORT_PROCESSES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SCORING_QUESTIONS$TITLE',
        icon: 'fa fa-question',
        link: ROUTES.IMPORT_QUAL_QUESTIONS.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.QUALITATIVE_QUESTION, PERMISSION.IMPORT.QUALITATIVE_QUESTION]
        },
        isActive: (s, location) => ROUTES.IMPORT_QUAL_QUESTIONS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SCORING_QUESTIONS$ANSWERS',
        icon: 'fa fa-question',
        link: ROUTES.IMPORT_QUAL_QUESTION_ANSWERS.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.QUALITATIVE_QUESTION_ANSWERS, PERMISSION.IMPORT.QUALITATIVE_QUESTION_ANSWERS]
        },
        isActive: (s, location) => ROUTES.IMPORT_QUAL_QUESTION_ANSWERS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'MENU$SYSTEMS_RISK',
        icon: 'fa fa-signal',
        link: ROUTES.IMPORT_SYSTEMS_RISK.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.SYSTEM_RISK, PERMISSION.IMPORT.SYSTEM_RISK]
        },
        isActive: (s, location) => ROUTES.IMPORT_SYSTEMS_RISK.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'TECHNOLOGIES$TITLE',
        icon: 'fa fa-inbox',
        link: ROUTES.IMPORT_TECHNOLOGIES.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.TECHNOLOGY, PERMISSION.IMPORT.TECHNOLOGY]
        },
        isActive: (s, location) => ROUTES.IMPORT_TECHNOLOGIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'USERS$TITLE',
        icon: 'fa fa-users',
        link: ROUTES.IMPORT_USERS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.SOME, value: [PERMISSION.EXPORT.USER, PERMISSION.IMPORT.USER]},
        isActive: (s, location) => ROUTES.IMPORT_USERS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'VENDORS$TITLE',
        icon: 'fa fa-user-o',
        link: ROUTES.IMPORT_VENDORS.LINK(),
        permission: {type: PERMISSION_CHECK_TYPES.SOME, value: [PERMISSION.EXPORT.VENDOR, PERMISSION.IMPORT.VENDOR]},
        isActive: (s, location) => ROUTES.IMPORT_VENDORS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SUBSIDIARY_ORGANIZATIONS$TITLE',
        icon: 'fa fa-building-o',
        link: ROUTES.IMPORT_SUBSIDIARIES.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.SUBSIDIARY, PERMISSION.IMPORT.SUBSIDIARY]
        },
        isActive: (s, location) => ROUTES.IMPORT_SUBSIDIARIES.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SECURITY_ASSESSMENTS$TITLE',
        icon: 'fa fa-plus-square-o',
        link: ROUTES.IMPORT_ASSESSMENT_FRAMEWORK.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.ASSESSMENT_FRAMEWORK, PERMISSION.IMPORT.ASSESSMENT_FRAMEWORK]
        },
        isActive: (s, location) => ROUTES.IMPORT_ASSESSMENT_FRAMEWORK.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'EXPOSURE_METRICS$TITLE',
        icon: 'fa fa-bar-chart',
        link: ROUTES.IMPORT_QUANT_METRICS.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.QUANT_METRIC, PERMISSION.IMPORT.QUANT_METRIC]
        },
        isActive: (s, location) => ROUTES.IMPORT_QUANT_METRICS.REGEXP.test(location.pathname)
    }, {
        type: MENU_ITEM_TYPE.LINK,
        name: 'SECURITY_REQUIREMENTS$TITLE',
        icon: 'fa fa-check-circle-o',
        link: ROUTES.IMPORT_SECURITY_REQUIREMENTS.LINK(),
        permission: {
            type: PERMISSION_CHECK_TYPES.SOME,
            value: [PERMISSION.EXPORT.SECURITY_REQUIREMENTS, PERMISSION.IMPORT.SECURITY_REQUIREMENTS]
        },
        isActive: (s, location) => ROUTES.IMPORT_SECURITY_REQUIREMENTS.REGEXP.test(location.pathname)
    },]
}, {
    type: MENU_ITEM_TYPE.LINK,
    name: 'AUDIT_LOGS$TITLE',
    icon: 'fa fa-calendar-check-o',
    link: ROUTES.AUDIT_LOGS.LINK(),
    permission: {type: PERMISSION_CHECK_TYPES.INCLUDE, value: PERMISSION.AUDIT_LOG.READ},
    isActive: (s, location) => ROUTES.AUDIT_LOGS.REGEXP.test(location.pathname)
}, {
    type: MENU_ITEM_TYPE.LINK,
    name: 'MENU$SETTINGS',
    icon: 'fa fa-cogs',
    permission: {type: PERMISSION_CHECK_TYPES.NO_CHECK},
    link: ROUTES.SETTINGS.LINK(),
}];


/**
 * get aside menu transform with options
 *
 * @param {Object} options
 * @return {Array}
 * @public
 */
export default function getMenu(options = {}) {
    let {dashboards = [], permissions = []} = options;
    let menu = cloneDeep(MENU);
    // NOTE add dashboard menu item
    if (dashboards.length) {
        // NOTE selecting all values of "parentName" without repetition
        let allParentNames = uniq(dashboards.map(dashboard => dashboard.parentName));
        let nullList = [];

        let dashboardsMenu = {
            type: MENU_ITEM_TYPE.MENU,
            name: 'MENU$DASHBOARDS',
            icon: 'fa fa-table',
            list: [],
        };

        // NOTE combine array of dashboards into tabs with same "parentName" and put into list of dashboards menu
        let list = allParentNames.map(name => {
            let tabs = [];
            dashboards.forEach(item => {
                if (name === item.parentName) {
                    (name ? tabs : nullList).push({
                        type: MENU_ITEM_TYPE.LINK,
                        name: item.name,
                        icon: item.icon || 'fa fa-th-list',
                        permission: {type: PERMISSION_CHECK_TYPES.NO_CHECK},
                        link: ROUTES.DASHBOARDS.LINK({id: item.id}),
                    });
                }
            });
            return {
                type: MENU_ITEM_TYPE.MENU,
                name: name,
                icon: tabs[0] ? tabs[0].icon : 'fa fa-th-list',
                list: tabs,
                isParent: true
            };
        });
        dashboardsMenu.list = filter(list, item => item.name);
        if (nullList && nullList.length) {
            for (let i = 0; i < nullList.length; i++) {
                dashboardsMenu.list.push(nullList[i]);
            }
        }
        menu.splice(7, 0, dashboardsMenu);
    }
    // NOTE check permissions
    if (permissions.length) {
        menu = filter(menu, item => {
            if (item.type === MENU_ITEM_TYPE.MENU) {
                item.list = filter(item.list, item => checkPermission(item.permission, permissions));
                return item;
            }
            return checkPermission(item.permission, permissions);
        });
    }
    // NOTE hide 'menu' items with empty list
    menu = reject(menu, item => item.type === MENU_ITEM_TYPE.MENU && !item.list.length);
    return menu;
}


/**
 * check menu item permission
 *
 * @param {Object} requiredPermission
 * @param {Array} permissions - user permissions
 * @return {Boolean}
 * @public
 */
function checkPermission(requiredPermission = {}, permissions) {
    switch (requiredPermission.type) {
        default:
        case PERMISSION_CHECK_TYPES.NO_CHECK:
            return true;
        case PERMISSION_CHECK_TYPES.INCLUDE:
            return permissions.includes(requiredPermission.value);
        case PERMISSION_CHECK_TYPES.SOME:
            return some(requiredPermission.value, v => permissions.includes(v));
    }
}
