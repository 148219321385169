// outsource dependencies
import moment from 'moment';
import { call, put, takeEvery, take, select } from 'redux-saga/effects';

// local dependencies
import { LIST } from '../actions';
import { initial } from './reducer';
import { DATE_RANGE } from './index';
import is from '../../../services/is.service';
import store, { history } from '../../../store';
import { AUDIT_LOGS } from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import getHintsList, {AUDIT_LOGS_LIST_HINTS} from '../../../services/hints.service';

const format = 'YYYY-MM-DD';

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(LIST.INITIALIZE, initializeSaga);
    yield takeEvery(LIST.UPDATE_DATA, updateDataSaga);
    yield takeEvery(LIST.CHANGE_SORT, changeSortSaga);
    yield takeEvery(LIST.CHANGE_DATE, changeDateSaga);
    yield takeEvery(LIST.GET_DATA.REQUEST, getDataSaga);
    yield takeEvery(LIST.CANCEL_ADVANCED_SEARCH, cancelAdvancedSearchSaga);
    yield takeEvery(LIST.GET_HINTS_DATA.REQUEST, getHintsDataSaga);

    // NOTE setup listener on location change to listen history back event (POP)
    yield call(history.listen, historyBackListen);
}

function* initializeSaga() {
    yield put({type: LIST.CLEAR});
    // NOTE take data from location and setup verified params
    const params = yield call(getQueryParams, queryService.parse(history.location.search));
    yield put({type: LIST.META, ...params});
    // NOTE get list
    yield put({type: LIST.GET_DATA.REQUEST});
    yield put({type: LIST.GET_HINTS_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    // NOTE show advanced search depends on filtering params
    let showAdvanced = Boolean(params.dateTo || params.dateFrom);
    yield put({type: LIST.META, initialized: true, showAdvanced});
}

function* getDataSaga() {
    try {
        let options = yield select(state => state.auditLogs.list);
        let {data, totalPages} = yield call(getList, options);

        // NOTE setup data
        yield put({type: LIST.DATA, data});
        yield put({type: LIST.META, totalPages});

        if (options.hasOwnProperty('itemId')) {
            if (options.itemType) {
                if (options.itemId && options.itemType.name === "System") {
                    let {name} = yield call(getSystemName, options);
                    yield put({
                        type: LIST.META, systemName: name
                    });
                }
            }
        }


        // NOTE update location
        yield call(updateLocation, options);
    } catch ({message}) {
        yield put({type: LIST.META, errorMessage: message});
    }
    yield put({type: LIST.GET_DATA.FINISH});
}

function* updateDataSaga({type, ...options}) {
    // NOTE setup options
    yield put({type: LIST.META, expectAnswer: true, errorMessage: null, ...options});
    // NOTE get list
    yield put({type: LIST.GET_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    yield put({type: LIST.META, expectAnswer: false});
}

function* changeSortSaga({field}) {
    let {sortF, sortD} = yield select(state => state.auditLogs.list);
    // NOTE change direction if field has already chosen
    sortD = field === sortF ? !sortD : true;
    // NOTE get list
    yield put({type: LIST.UPDATE_DATA, sortF: field, sortD, page: 0});
}

function* changeDateSaga({dateRange}) {
    // NOTE setup date range
    yield put({type: LIST.META, dateRange});
    if (dateRange === DATE_RANGE.CHOOSE_DATE) {
        yield put({type: LIST.META, dateTo: null, dateFrom: null});
    }
    let {dateTo, dateFrom} = yield select(state => state.auditLogs.list);
    let date = getDate(dateRange, dateTo, dateFrom);
    // NOTE setup new date
    yield put({type: LIST.META, ...date});
}

function* cancelAdvancedSearchSaga() {
    // NOTE clear filters
    yield put({type: LIST.META, dateTo: initial.dateTo, dateFrom: initial.dateFrom, dateRange: initial.dateRange});
    // NOTE update data
    yield put({type: LIST.UPDATE_DATA});
    // NOTE close search panel
    yield put({type: LIST.META, showAdvanced: false});
}

function* getHintsDataSaga(hintType) {
    try {
        let {language} = yield select(state => state.app);
        let hintsData = yield call(getHintsList, language, AUDIT_LOGS_LIST_HINTS);

        // NOTE setup hints data
        yield put({type: LIST.META, hintsData});
    } catch ({message}) {
        yield put({type: LIST.META, errorMessage: message});
    }
    yield put({type: LIST.GET_HINTS_DATA.FINISH});
}

function historyBackListen({location : {pathname}}, action) {
    if (action !== 'POP' || pathname !== AUDIT_LOGS.LIST) return;
    // NOTE reinitialize search from query string after the page path was changed
    // NOTE this event will fired before the url was changed
    setTimeout(() => store.dispatch({type: LIST.INITIALIZE}), 0);
}

/**
 * get list
 * @param {Object} options
 * @private
 */
function getList({sortF, sortD, page, size, filter, user, itemType, dateTo, dateFrom, itemId}) {
    let users = user ? [user] : [],
        itemTypes = itemType ? [itemType] : [];
    return new Promise((resolve, reject) => {
        const data = {
            page,
            size,
            sort: {field: sortF, order: sortD ? 'ASC' : 'DESC'},
            filter: {name: filter, users, itemTypes, dateTo, dateFrom},
        };
        itemId && (data.filter.itemId = itemId)

        instanceAPI({
            method: 'post',
            url: '/audit-logs/filter',
            data
        }).then(({items, pages}) => resolve({data: items, totalPages: pages})).catch(reject)
    });
}

/**
 * get system object
 * @param {itemId} options
 * @private
 */
function getSystemName({itemId}) {
    return new Promise((resolve, reject) => {
        instanceAPI({
            method: 'get',
            url: `/systems/${itemId}`
        }).then(({name}) => resolve({name: name})).catch(reject)
    })
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams({
                            page,
                            size,
                            sortF,
                            sortD,
                            filter,
                            uId,
                            uName,
                            tId,
                            tName,
                            dateRange,
                            dateTo,
                            dateFrom,
                            itemId
                        }) {
    let availableFields = ['userName', 'logDate'];
    const availableDateRange = Object.keys(DATE_RANGE).map(v => DATE_RANGE[v]);

    return {
        page: is.countable(page) ? Number(page) : initial.page,
        itemId: is.countable(itemId) ? Number(itemId) : initial.itemId,
        size: is.countable(size) ? Number(size) : initial.size,
        sortF: availableFields.includes(sortF) ? sortF : initial.sortF,
        sortD: sortD ? Boolean(Number(sortD)) : initial.sortD,
        filter: is.defined(filter) ? String(filter) : initial.filter,
        dateTo: is.defined(dateTo) ? moment(dateTo) : initial.dateTo,
        dateFrom: is.defined(dateFrom) ? moment(dateFrom) : initial.dateFrom,
        dateRange: availableDateRange.includes(dateRange) ? dateRange : initial.dateRange,
        user: is.countable(uId) && is.defined(uName) ? {id: Number(uId), fullName: String(uName)} : initial.user,
        itemType: is.countable(tId) && is.defined(tName) ? {id: Number(tId), name: String(tName)} : initial.itemType
    }
}

/**
 * helper to setup correctness url params
 *
 * @param {Object} reducer
 * @public
 */
function updateLocation({page, size, sortF, sortD, filter, user, itemType, dateRange, dateFrom, dateTo, itemId}) {
    let params = {};
    // NOTE setup data to url which has difference with default data
    page !== initial.page && (params.page = page);
    itemId !== initial.itemId && (params.itemId = itemId);
    size !== initial.size && (params.size = size);
    sortF !== initial.sortF && (params.sortF = sortF);
    sortD !== initial.sortD && (params.sortD = Number(Boolean(sortD)));
    filter !== initial.filter && (params.filter = filter);
    dateTo !== initial.dateTo && (params.dateTo = moment(dateTo).format(format));
    dateFrom !== initial.dateFrom && (params.dateFrom = moment(dateFrom).format(format));
    dateRange !== initial.dateRange && (params.dateRange = dateRange);
    user !== initial.user && (params.uId = user.id) && (params.uName = user.fullName);
    itemType !== initial.itemType && (params.tId = itemType.id) && (params.tName = itemType.name);

    let search = queryService.format(params);
    // NOTE update url if it has difference
    if (search !== history.location.search) {
        history.push(history.location.pathname + search);
    }
}

/**
 * helper to setup correctness date range
 *
 * @param {String} dateRange
 * @param {Object} dateTo
 * @param {Object} dateFrom
 * @return {Object}
 * @public
 */
function getDate(dateRange, dateTo, dateFrom) {
    switch (dateRange) {
        default:
        case DATE_RANGE.CHOOSE_DATE:
            dateFrom = dateFrom ? moment(dateFrom) : null;
            dateTo = dateTo ? moment(dateTo) : null;
            break;
        case DATE_RANGE.TODAY:
            dateFrom = moment().startOf('d');
            dateTo = moment().endOf('d');
            break;
        case DATE_RANGE.YESTERDAY:
            dateFrom = moment().subtract(1, 'd').startOf('d');
            dateTo = moment().subtract(1, 'd').endOf('d');
            break;
        case DATE_RANGE.THIS_WEEK:
            dateFrom = moment().startOf('isoWeek');
            dateTo = moment().endOf('isoWeek');
            break;
        case DATE_RANGE.LAST_WEEK:
            dateFrom = moment().subtract(1, 'w').startOf('isoWeek');
            dateTo = moment().subtract(1, 'w').endOf('isoWeek');
            break;
        case DATE_RANGE.THIS_MONTH:
            dateFrom = moment().startOf('month');
            dateTo = moment().endOf('month');
            break;
        case DATE_RANGE.LAST_MONTH:
            dateFrom = moment().subtract(1, 'M').startOf('month');
            dateTo = moment().subtract(1, 'M').endOf('month');
            break;
    }
    return {dateFrom, dateTo};
}
