
// outsource dependencies
import React, {Fragment} from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import {withStyles} from '@mui/styles'

// local dependencies
import GEO_RECORDS from './actions';
import Preloader from '../preloader';
import ErrorMessage from '../alert-error';
import SelectEntities from '../select-entities';
import { ENTITY_TYPES } from '../../constants/spec';
import { SubmitBtn, ResetBtn, CancelBtn, AddIconBtn, DeleteIconBtn } from '../md-button';
import { translate, withTranslation } from '../../services/translate.service';
import MdInput from "../md-input";
import separateService from "../../services/separate-with-comma.service";
import {RichHintTitle, findHint} from "../hints/hints";

export default withTranslation(connect(
    state => ({
        ...state.geoRecordsProcessedDialog,
        loaded: state.geoRecordsProcessedDialog.initialized,
        initialValues: state.geoRecordsProcessedDialog.data,
        hints: state.geoRecordsProcessedDialog.hintsData,
    }),
    dispatch => ({
        cancel: () => dispatch({type: GEO_RECORDS.CANCEL}),
        clearError: () => dispatch({type: GEO_RECORDS.META, errorMessage: null}),
        reassign: formData => dispatch({type: GEO_RECORDS.REASSIGN, ...formData}),
    })
)( reduxForm ({
    form: 'geoRecordsForm',
    enableReinitialize: true,
    validate: values => {
        let errors = {};
        // user
        if (!values.user) {
            errors.user = 'USERS$USER_REQUIRED';
        }
        return errors;
    }
})( withStyles({paper: { minWidth: '700px', overflow: 'visible' }})(
    ({expectAnswer, hints, loaded, showModal, errorMessage, clearError, cancel, reassign, handleSubmit, pristine, invalid, reset, classes, isNew}) => {
        return(
            <Dialog
                open={showModal}
                onClose={(event, reason) => {
                    if(reason === "backdropClick" && reason === "escapeKeyDown") return false;
                    cancel();
                }}
                scroll={'body'}
                classes={{paper: classes.paper}}
                aria-labelledby="form-dialog-title"
                    >
                <Preloader expectAnswer={!loaded} type="MIN_HEIGHT" height={200}>
                    <form onSubmit={handleSubmit(reassign)}>
                        <DialogContent style={{overflow: 'visible'}}>
                            <ErrorMessage active message={errorMessage} onChange={clearError}/>
                            <Row className="offset-bottom-4"> <Col xs={12}>
                                <FieldArray name="geoRecordsProcessed" rerenderOnEveryChange={true}
                                            component={ConnectedGeoRecordsProcessed}/>
                            </Col> </Row>
                        </DialogContent>
                        <DialogActions>
                            <SubmitBtn isNew={isNew} disabled={pristine || invalid || expectAnswer} className="offset-right-2"
                                       hint={findHint(hints, isNew ? 'BUTTON_GEO_RECORDS_PROCESSED_CREATE' : 'BUTTON_GEO_RECORDS_PROCESSED_SAVE')}/>
                            <ResetBtn onClick={reset} disabled={pristine||expectAnswer} className="offset-right-2" hint={findHint(hints, 'BUTTON_GEO_RECORDS_PROCESSED_RESET')}/>
                            <CancelBtn onClick={cancel} disabled={expectAnswer} hint={findHint(hints, 'BUTTON_GEO_RECORDS_PROCESSED_CANCEL')} />
                        </DialogActions>
                    </form>
                </Preloader>
            </Dialog>
        )
    }
))));

/**
 *
 * @param { Object } props
 * @public
 */
const ConnectedGeoRecordsProcessed = connect(
    state => ({
        states: state.geoRecordsProcessedDialog.states,
        values: state.form.geoRecordsForm.values,
        hints: state.geoRecordsProcessedDialog.hintsData,
        disabled: state.geoRecordsProcessedDialog.expectAnswer,
    }),
    dispatch => ({
        setupCountry: (countryId, mKey) => dispatch({type: GEO_RECORDS.SETUP_COUNTRY.REQUEST, countryId, mKey}),
    }),
)(({fields, hints, meta, disabled, values, states, setupCountry}) => (<Fragment>
    <Row> <Col xs={12}>
        <h3 className="text-uppercase">
            <RichHintTitle update={GEO_RECORDS}  name={`SYSTEMS$GEO_RECORDS_PROCESSED`} expectAnswer={disabled} data={findHint(hints, 'GEO_RECORDS_PROCESSED_TITLE')}/>
            {/*<span className="align-middle"> {translate('SYSTEMS$GEO_RECORDS_PROCESSED')} </span>&nbsp;*/}
            <AddIconBtn onClick={() => fields.push({})} />
        </h3>
    </Col> </Row>
    {meta.error && (<Row> <Col xs={{span:10,offset:1}} className="text-center is-invalid">
        <label className="form-text h4"> {translate(meta.error)} </label>
    </Col> </Row>)}
    {!fields.length
        ?
        <h3 className="text-center text-uppercase"> {translate('GLOBALS$NO_DATA')} </h3>
        :
        <Fragment>
            {fields.map((mKey, index) => (
                <Row key={index} className="offset-bottom-6">
                    <Col xs={12} md={4}>
                        <SelectEntities
                            name={`${mKey}.country`}
                            disabled={disabled}
                            type={ENTITY_TYPES.COUNTRIES}
                            placeholder={translate('ORGANIZATION$COUNTRY')}
                            onChange={(e) => setupCountry(get(e, 'id', null), mKey)}
                            label={(<strong> {translate('ORGANIZATION$COUNTRY')} </strong>)}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <SelectEntities
                            isClearable={!!get(values, `${mKey}.state.id`)}
                            name={`${mKey}.state`}
                            defaultOptions={states}
                            type={ENTITY_TYPES.STATES}
                            placeholder={translate('ORGANIZATION$STATE')}
                            label={(<strong> {translate('ORGANIZATION$STATE')} </strong>)}
                            disabled={disabled || !get(values, `${mKey}.country.id`, null)}
                            additionalFilters={{countryId: get(values, `${mKey}.country.id`, null)}}
                        />
                    </Col>
                    <Col xs={12} md={4} className="row-adornment">
                        <Field
                            type="text"
                            component={MdInput}
                            disabled={disabled || !get(values, `${mKey}.country.id`, null)}
                            name={`${mKey}.numberOfRecProcessed`}
                            placeholder={translate('SYSTEMS$RECORDS_PROCESSED')}
                            label={(<strong> {translate('SYSTEMS$RECORDS_PROCESSED')} </strong>)}
                            normalize={separateService.normalize}
                        />
                        <div className="adornment"><DeleteIconBtn onClick={() => fields.remove(index)} /></div>
                    </Col>
                </Row>

            ))}
        </Fragment>
    }

</Fragment>));
