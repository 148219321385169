
// outsource dependencies

// local dependencies
import { MAPPING } from '../actions';
import { SECURITY_REQUIREMENTS } from '../../../../constants/routes';

let initial = {
    data: {},
    hintsData: [],
    selectedTypesIds: [],
    availableAssessmentTypes: [],
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
    // previous path
    back: SECURITY_REQUIREMENTS.LINK(),
};

export default function ( state = initial, action ) {
    //eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case MAPPING.CLEAR:
            state = initial;
            break;
        case MAPPING.DATA:
            state = { ...state, data: options.data };
            break;
        case MAPPING.META:
            state = { ...state, ...options, data: state.data };
            break;
    }

    return state;
}
