
// outsource dependencies

// local dependencies

export default (prefix => {
    return {
        PREFIX: new RegExp(prefix, 'i'),
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
    };
})('@aside-menu/');
