
// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const LIST = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@gdpr-system-compliance/list/');

export const DRILLDOWN = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@gdpr-system-compliance/drilldown/');

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE: `${prefix}UPDATE`,
        CANCEL: `${prefix}CANCEL`,
        INITIALIZE: `${prefix}INITIALIZE`,
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
        CHANGE_CHAPTER: `${prefix}CHANGE_CHAPTER`,
        CHANGE_SECTION: `${prefix}CHANGE_SECTION`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@gdpr-system-compliance/edit/');

export const SYSTEM_LOG = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@gdpr-system-compliance/system-log/');

export const QUESTIONS = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE: `${prefix}UPDATE`,
        CANCEL: `${prefix}CANCEL`,
        GET_DATA: `${prefix}GET_DATA`,
        INITIALIZE: `${prefix}INITIALIZE`,
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
        UPDATE_METRIC: `${prefix}UPDATE_METRIC`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@gdpr-system-compliance/questions/');
