
// outsource dependencies

// local dependencies

import {CATEGORIES} from "../actions";

let initial = {
    item: {},
    meta: {
        hintsData: [],
        initialized: false,
        expectAnswer: false,
        errorMessage: null,
        newItem: false
    }
};

export default function ( state = initial, action ) {
    //eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case CATEGORIES.EDIT.CLEAR:
            state = initial;
            break;
        case CATEGORIES.ITEM:
            state = { ...state, item: options.item };
            break;
        case CATEGORIES.EDIT_META:
            state = { ...state, meta: {...state.meta, ...options} };
            break;
    }
    // /@categories/.test(type)&&
    // console.log(`%c CATEGORIES EDIT ${type} `, 'color: #fff; background: red; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n action:', action
    //     // ,'\n arguments:', arguments
    // );
    return state;
}
