// outsource dependencies
import {every, get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
// import { Prompt } from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Tab, Tabs} from '@mui/material';
import {Field, FieldArray, isPristine, reduxForm} from 'redux-form';

// local dependencies
import {ACTIONS} from './actions';
import is from '../../services/is.service';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {QuestionRow} from '../../components/question-row';
import {ResetBtn, SubmitBtn} from '../../components/md-button';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumb';
import {translate, withTranslation} from '../../services/translate.service';
import {SCORING_QUESTIONS_MAP} from "../../components/breadcrumbs/breadcrumbsMap";


class Questions extends Component {
    componentDidMount() {
        this.props.initialize();
    }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { message, disabled, clearError, pristine } = this.props;
        return (<div style={{height: '100%', overflowY: 'auto'}}>
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ SCORING_QUESTIONS_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-top-2">
                        <Col xs={12}>
                            <Row className="offset-bottom-4">
                                <Col xs={12} lg={pristine ? 12 : 8}>
                                    <h2 className="text-uppercase">
                                        {translate('SCORING_QUESTIONS$TITLE')} <Preloader expectAnswer={disabled} type="ICON" />
                                    </h2>
                                </Col>
                                {!pristine&&<Col xs={12} lg={4}>
                                    <h3 className="text-danger text-right top-indent-1"><strong>
                                        <i className="fa fa-exclamation-triangle" aria-hidden="true" /> {translate('GLOBALS$UNSAVED_DATA')}
                                    </strong></h3>
                                </Col>}
                            </Row>
                            <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError} /> </Col> </Row>
                            <Row> <Col xs={12}> <ConnectedForm /> </Col> </Row>
                            {/*NOTE block navigating away from a page and show message*/}
                            {/*<Prompt when={!pristine} message={translate('GLOBALS$UNSAVED_DATA_MESSAGE')} />*/}
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        </div>);
    }
}

export default connect(
    state => ({
        data: state.organizationRiskScoring.data,
        pristine: isPristine('scoringQuestions')(state),
        message: state.organizationRiskScoring.errorMessage,
        disabled: state.organizationRiskScoring.expectAnswer
    }),
    dispatch => ({
        clear: () => dispatch({type: ACTIONS.CLEAR}),
        initialize: () => dispatch({type: ACTIONS.INITIALIZE}),
        clearError: () => dispatch({ type: ACTIONS.META, errorMessage: null}),
    })
)(Questions)

const ConnectedInitializer = connect(
    state => ({initialize: state.organizationRiskScoring.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        metric: state.organizationRiskScoring.metric,
        initialValues: state.organizationRiskScoring.data,
        disabled: state.organizationRiskScoring.expectAnswer,
        metricDomains: state.organizationRiskScoring.metricDomains,
        questionsLength: get(state, 'organizationRiskScoring.data.questions.length', 0)
    }),
    dispatch => ({
        update: formData => dispatch({type: ACTIONS.UPDATE, ...formData}),
        changeMetric: metric => dispatch({type: ACTIONS.GET_DATA, metric}),
        uploadFile: (file, fieldName) => dispatch({type: ACTIONS.UPLOAD_FILE, file, fieldName}),
    })
)(reduxForm({
    form: 'scoringQuestions',
    enableReinitialize: true,
    warn: values => {
        const warnings = {};
        let map = {};
        // NOTE build question - answer map
        (values.questions||[]).forEach(item => {
            map[item.id] = get(item, 'selectedAnswers.id', null);
        });
        // NOTE check branching logic
        let branchingWarns = [];
        (values.questions||[]).forEach((item, index) => {
            let warns = {};
            let branchingLogic = get(item, 'branchingLogic', []);
            let isVisible = every(branchingLogic, branching => {
                const questionId = get(branching, 'question.id'),
                    answerId = get(branching, 'answer.id');
                return map[questionId] === answerId;
            });
            if (!isVisible) {
                warns.isHidden = true;
            }
            branchingWarns[index] = warns;
        });
        if ( !is.empty(branchingWarns) ) {
            warnings.questions = branchingWarns;
        }
        return warnings;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, questionsLength, uploadFile, changeMetric, metric, metricDomains})=>(
    <form autoComplete="off" name="scoringQuestions" onSubmit={handleSubmit(update)}>
        <Paper square>
            <Tabs
                value={metric}
                textColor="primary"
                variant="scrollable"
                indicatorColor="primary"
                onChange={(e, value)=>changeMetric(value)}
                    >
                {metricDomains.map((item, key) =>  <Tab key={key} value={item.code} label={item.name} /> )}
            </Tabs>
        </Paper>
        <Paper square className="indent-5"><Container fluid>
            <Row className="offset-bottom-4"><Col xs={12}>
                {!questionsLength ? (
                    <h3 className="text-center text-uppercase offset-bottom-6"> {translate('GLOBALS$NO_QUESTIONS_FOUND')} </h3>
                ): (<div>
                    <FieldArray name="questions" component={Items} uploadFile={uploadFile} disabled={disabled} />
                </div>)}
            </Col></Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn disabled={pristine||invalid||disabled} className="offset-right-2" />
                    <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" />
                </Col>
            </Row>
        </Container></Paper>
    </form>
))));

/**
 * component for item of "questions" list
 *
 * @param {Object} props
 * @private
 */
const Items = ({ fields, disabled, uploadFile }) => {
    return fields.map((mKey, index) => ( <Field key={index} name={mKey} component={QuestionRow} disabled={disabled} uploadFile={uploadFile} /> ))
};

