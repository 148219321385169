
// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const QUAL_LIST = (function ( prefix ) {
    return {
        // simple
        CLEAR: `${prefix}CLEAR`,
        META: `${prefix}META`,
        LIST: `${prefix}LIST`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        DELETE_ITEM: `${prefix}DELETE_ITEM`,
        GET_LIST: createTypes(`${prefix}GET_LIST`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@qual/list/');

export const QUAL_EDIT = (function ( prefix ) {
    return {
        // simple
        CLEAR: `${prefix}CLEAR`,
        META: `${prefix}META`,
        ITEM: `${prefix}ITEM`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        CANCEL: `${prefix}CANCEL`,
        GET_ITEM: createTypes(`${prefix}GET_ITEM`),
        UPDATE_ITEM: createTypes(`${prefix}UPDATE_ITEM`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@qual/edit/');
