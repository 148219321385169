// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';

// local dependencies
import { LIST } from '../actions';
import { history } from '../../../store';
import { PERMISSION } from '../../../constants/spec';
import { config } from '../../../constants';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import { AddBtn, DelBtn, EditBtn, Link } from '../../../components/md-button';
import MdTablePagination from '../../../components/pagination';
import { VENDOR_CONTRACTS } from '../../../constants/routes';
import { translate, withTranslation } from '../../../services/translate.service';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import { VENDOR_CONTRACT_MAP } from '../../../components/breadcrumbs/breadcrumbsMap';
import { findHint, RichHintTitle } from '../../../components/hints/hints';
import moment from 'moment/moment';

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {disabled, data, message, clearError, hints} = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={VENDOR_CONTRACT_MAP}/>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <RichHintTitle update={LIST} name={`VENDOR_CONTRACT$TITLE`} expectAnswer={disabled}
                                           data={findHint(hints, `VENDOR_CONTRACTS_TITLE`)}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} placement="left" to={VENDOR_CONTRACTS.LINK_EDIT()} permission={PERMISSION.VENDOR_CONTRACTS.CREATE} hint={findHint(hints, 'BUTTON_VENDOR_CONTRACT_ADD')}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}> <SearchFilterConnected/> </Col> </Row>
                    <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col> </Row>
                    {data.length ? (
                        <Paper> <ConnectedTable/> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        data: state.vendorContracts.list.data,
        hints: state.vendorContracts.list.hintsData,
        message: state.vendorContracts.list.errorMessage,
        disabled: state.vendorContracts.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null})
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.vendorContracts.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.vendorContracts.list.filter,
        disabled: state.vendorContracts.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('GLOBALS$SEARCH_BY_NAME_AND_DOCUMENT_TYPE')}
    />
));

const ConnectedTable = withTranslation(connect(
        state => ({...state.vendorContracts.list, hints: state.vendorContracts.list.hintsData}),
        dispatch => ({
            deleteItem: (id, item) => dispatch({type: LIST.DELETE_ITEM, id, item}),
            changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
            changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
            changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
        })
    )(({data, hints, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort, deleteItem}) => (<div>
        <div style={{overflowX: 'auto'}}>
            <Table className="md-table" padding="checkbox">
                <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                    <TableRow style={{height: 48}}>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'name'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={() => changeSort('name')}
                            >
                                {translate('GLOBALS$NAME')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'description'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={() => changeSort('description')}
                            >
                                {translate('GLOBALS$DESCRIPTION')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'number'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={() => changeSort('number')}
                            >
                                {translate('VENDOR_CONTRACT$CONTRACT_NUMBER')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'startDate'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={() => changeSort('startDate')}
                            >
                                {translate('VENDOR_CONTRACT$START_DATE')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'expiryDate'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={() => changeSort('expiryDate')}
                            >
                                {translate('VENDOR_CONTRACT$EXPIRY_DATE')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'vendor.name'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={() => changeSort('vendor.name')}
                            >
                                {translate('VENDORS$VENDOR')}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.number}</TableCell>
                        <TableCell>{item.startDate && moment.utc(moment(item.startDate)).format(config.clientTimeFormat)}</TableCell>
                        <TableCell>{item.expiryDate && moment.utc(moment(item.expiryDate)).format(config.clientTimeFormat)}</TableCell>
                        <TableCell>
                            {get(item, 'vendor.name')}

                            {item && item.documents && item.documents.length ? (<div><span className={"cell-text-light-blue"}>{translate('GLOBALS$DOCUMENTS_COLON')}</span> {(item.documents || []).map(i=> i.fileName).join(', ')}</div>) : ""}
                        </TableCell>
                        <TableCell className="text-nowrap" align="right">
                            <Link
                                Btn={EditBtn}
                                hint={findHint(hints, `BUTTON_VENDOR_CONTRACT_EDIT`)}
                                permission={PERMISSION.VENDOR_CONTRACTS.UPDATE}
                                to={VENDOR_CONTRACTS.LINK_EDIT({
                                    id: item.id,
                                    query: {back: history.location.pathname + history.location.search}
                                })}
                            />
                            <DelBtn
                                onClick={() => deleteItem(item.id, item)}
                                permission={PERMISSION.VENDOR_CONTRACTS.DELETE}
                                hint={findHint(hints, 'BUTTON_VENDOR_CONTRACT_DELETE')}
                            />
                        </TableCell>
                    </TableRow>))}
                </TableBody>
            </Table>
        </div>
        <MdTablePagination
            page={page}
            size={size}
            disabled={expectAnswer}
            totalPages={totalPages}
            changeSize={changeSize}
            changePage={changePage}
        />
    </div>)));
