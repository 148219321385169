
// outsource dependencies

// local dependencies
import { MODELS_MODAL } from "./actions";

let initial = {
    list: [],
    meta: {
        showNew: false,
        showModal: false,
        initialized: false,
        expectAnswer: false,
        errorMessage: null,
    }
};

export default function ( state = initial, action ) {
    // eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case MODELS_MODAL.CLEAR:
            state = initial;
            break;
        case MODELS_MODAL.META:
            state = { ...state, meta: {...state.meta, ...options} };
            break;
        case MODELS_MODAL.LIST:
            state = { ...state, ...options };
            break;
    }
    // /@models-modal/.test(type)&&
    // console.log(`%c MODELS_MODAL LIST ${type} `, 'color: #fff; background: #232323; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n options:', options
    // );
    return state;
}

/**
 * description API entity "RiskModel"
 * @typedef {Object} RiskModel
 * @property {Number} id
 * @property {String} name
 * @property {String} description
 * addition client side fields
 * @property {Boolean} expanded
 * @property {String} formName - to get an access to the redux form data for each entity separated
 */

