// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {CardContent, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {ASSESSMENT_LEVEL, PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import {EditBtn, Link} from '../../../components/md-button';
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import MdTablePagination from '../../../components/pagination';
import {CONTROL_TEST_RESULTS} from '../../../constants/routes';
import {SimpleAsyncSelect} from '../../../components/md-select';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatFileSize} from '../../../services/data-formatting.service';
import {instanceAPI} from "../../../services/api.service";
import {CONTROL_TEST_RESULTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { disabled, data, message, clearError, hints } = this.props;
        return (<Container fluid>
            <Breadcrumbs breadCrumbsMap={ CONTROL_TEST_RESULTS_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <RichHintTitle
                            update={LIST}
                            name={`ORGANIZATION_CONTROL_TEST_RESULTS$TITLE`}
                            expectAnswer={disabled}
                            data={findHint(hints, `ORGANIZATION_CONTROL_TEST_RESULTS_TITLE`)}
                        />
                    </Col>
                </Row>
                <Row className="offset-bottom-4"><Col xs={12}> <SearchForm/> </Col></Row>
                <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col> </Row>
                { data.length ? (
                    <Paper> <ConnectedTable /> </Paper>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')}  </h3>
                )}
            </ConnectedInitializer>
        </Container>);
    }
}

export default connect(
    state => ({
        data: state.controlTestResults.list.data,
        hints: state.controlTestResults.list.hintsData,
        message: state.controlTestResults.list.errorMessage,
        disabled: state.controlTestResults.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.controlTestResults.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));



/**
 * search form
 *
 * @public
 */
const SearchForm = withTranslation(connect(
    state => ({ ...state.controlTestResults.list }),
    dispatch => ({
        changeSearchValue: filter => dispatch({type: LIST.META, filter}),
        changeAssessmentData: data => dispatch({type: LIST.META, ...data}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ({ expectAnswer, filter, assessmentType, changeSearchValue, changeAssessmentData, disabled, applyFilter }) => (<CardContent>
    <Row>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SearchFilter
                value={filter}
                disabled={disabled}
                apply={applyFilter}
                clear={() => applyFilter('')}
                onInputChange={changeSearchValue}
                placeholder={translate('SECURITY_REQUIREMENTS$SEARCH_BY_REQUIREMENT')}
            />
        </Col>
        <Col xs={12} md={3} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={assessmentType}
                disabled={expectAnswer}
                placeholder={translate('ASSESSMENTS$ASSESSMENT')}
                onChange={assessmentType => {
                    changeAssessmentData({assessmentType});
                    applyFilter(filter);
                }}
                // label={(<strong> {translate('ASSESSMENTS$ASSESSMENT')} </strong>)}
                loadOptions={(name, done) => {
                    instanceAPI({ method: 'post', url: `/assessments/filter`, data: {filter: {name, assessmentLevelId: ASSESSMENT_LEVEL.ORG}} })
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
</CardContent>)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.controlTestResults.list, hints: state.controlTestResults.list.hintsData,}),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'code'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('code')}
                        >
                            {translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'securityControlFamily'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('securityControlFamily')}
                        >
                            {translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_FAMILY')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'securityControlName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('securityControlName')}
                        >
                            {translate('SECURITY_REQUIREMENTS$SECURITY_CONTROL_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('SECURITY_REQUIREMENTS$PROGRAM_AREA')} </TableCell>
                    <TableCell className="th"> {translate('TASKS$TITLE')} </TableCell>
                    <TableCell className="th"> {translate('CONTROL_MATURITIES$CONTROL_MATURITY')} </TableCell>
                    <TableCell className="th"> {translate('GLOBALS$DOCUMENT')} </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{get(item, 'securityRequirement.code')}</TableCell>
                    <TableCell>{get(item, 'securityRequirement.securityControlFamily.name')}</TableCell>
                    <TableCell>{get(item, 'securityRequirement.securityControlName.name')}</TableCell>
                    <AbbrevTableCell content={get(item, 'securityRequirement.programArea')} title={get(item, 'securityRequirement.securityControlName.name')} length={50}/>
                    <TableCell style={{minWidth: 200}}>
                        <ul className="list-unstyled offset-0"> {(item.tasks || []).map((item, index) => (
                            <li key={index}>{get(item, 'name')}</li>
                        ))} </ul>
                    </TableCell>
                    <TableCell>{get(item, 'controlMaturity.name')}</TableCell>
                    <TableCell>
                        {get(item, 'document') && <span>{get(item, 'document.fileName')} ({ formatFileSize(get(item, 'document.fileSize')) })</span>}
                    </TableCell>
                    <TableCell className="text-nowrap" align="right">
                        <Link
                            Btn={EditBtn}
                            hint={findHint(hints,`BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_EDIT`)}
                            permission={PERMISSION.CONTROL_TEST_RESULT.UPDATE}
                            to={CONTROL_TEST_RESULTS.LINK_EDIT({
                                id: item.id,
                                requirementId: get(item, 'securityRequirement.id', null),
                                query: {back: history.location.pathname + history.location.search}
                            })}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>)));
