/**
 * Risk Tolerance Analysis
 *
 * @author   Eugene A. Kalosha <ekalosha@dfusiontech.com>
 * @version  0.1.1
 * @since    2023-12-12
 */
export const EBITDA_MULTIPLIER_SP = {
    'AAA': 5,
    'AA+': 4,
    'AA': 3,
    'AA-': 2.75,
    'A+': 2.5,
    'A': 2,
    'A-': 1.75,
    'BBB+': 1.5,
    'BBB': 1.25,
    'BBB-': 1,
    'BB+': 0.75,
    'BB': 0.5,
    'BB-': 0.25,
};

/**
 * Risk Tolerance Analysis Service
 *
 * @author   Eugene A. Kalosha <ekalosha@dfusiontech.com>
 * @version  0.1.1
 * @since    2023-12-12
 */
export class RiskToleranceService {

    /**
     * Calculate Gross Rick Bearing Capacity based on EBITDA
     * @param ebidta
     * @param creditRating
     * @param debt
     * @param pensionDebt
     * @returns {number}
     */
    static calculateGrossRiskBearingCapacity(ebitda, creditRating, debt, pensionDebt) {
        let result = 0;

        let multiplier = EBITDA_MULTIPLIER_SP[creditRating.toUpperCase()] ? Number(EBITDA_MULTIPLIER_SP[creditRating.toUpperCase()]) : 0;
        let normalEbitda = RiskToleranceService.toNumber(ebitda);
        let normalDebt = RiskToleranceService.toNumber(debt);
        let normalPensionDebt = RiskToleranceService.toNumber(pensionDebt);

        result += normalEbitda * multiplier - normalDebt - normalPensionDebt;

        return result ? result : 0;
    }

    /**
     * Calculate Cyber Rick Bearing Capacity
     *
     * @param ebidta
     * @param creditRating
     * @param debt
     * @param pensionDebt
     * @returns {number}
     */
    static calculateCyberRiskBearingCapacity(grossRiskBearingCapacity, businessRiskBearingCapacity) {
        let result = 0;

        let normalGrossRiskBearingCapacity = RiskToleranceService.toNumber(grossRiskBearingCapacity);
        let normalBusinessRiskBearingCapacity = RiskToleranceService.toNumber(businessRiskBearingCapacity);

        result += normalGrossRiskBearingCapacity - normalBusinessRiskBearingCapacity;

        return result ? result : 0;
    }

    static toNumber(value) {
        let result = 0;

        if (value) {
            let formatted = String(value).replace(/[,]/g, '');

            result = Number(formatted);
        }

        return result;
    }

    static EBITDA_MULTIPLIER_SP() {
        return EBITDA_MULTIPLIER_SP;
    }

    static RATING_VALUES_SP() {
        return Object.keys(EBITDA_MULTIPLIER_SP);
    }

}