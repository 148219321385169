//
// // outsource dependencies
//
// // local dependencies
// import { createTypes } from "../../actions/types";
//
// export const DOMAINS = (function ( prefix ) {
//     return {
//         // simple
//         CLEAR: `${prefix}CLEAR`,
//         META: `${prefix}META`,
//         LIST: `${prefix}LIST`,
//         MODAL_LIST: `${prefix}MODAL_LIST`,
//         // complex
//         INITIALIZE: `${prefix}INITIALIZE`,
//         GET_LIST: createTypes(`${prefix}GET_LIST`),
//         GET_MODAL_LIST: `${prefix}GET_MODAL_LIST`,
//         ADD_DOMAIN: `${prefix}ADD_DOMAIN`,
//         UPDATE_ITEM: createTypes(`${prefix}UPDATE_ITEM`),
//     }
// })('@domains/');


// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const LIST = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        LIST: `${prefix}LIST`,
        CLEAR: `${prefix}CLEAR`,
        MODAL_LIST: `${prefix}MODAL_LIST`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        DELETE_ITEM: `${prefix}DELETE_ITEM`,
        CHANGE_SORT: `${prefix}CHANGE_SORT`,
        UPDATE_DATA: `${prefix}UPDATE_DATA`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),

        GET_LIST: createTypes(`${prefix}GET_LIST`),
        GET_MODAL_LIST: `${prefix}GET_MODAL_LIST`,
        ADD_DOMAIN: `${prefix}ADD_DOMAIN`,
        UPDATE_ITEM: createTypes(`${prefix}UPDATE_ITEM`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@domains/list/');

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE: `${prefix}UPDATE`,
        CANCEL: `${prefix}CANCEL`,
        INITIALIZE: `${prefix}INITIALIZE`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@domains/edit/');
