// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Card, CardActions, CardContent, CardHeader, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {TECHNOLOGY_ASSETS} from '../../../constants/routes';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {instanceAPI} from '../../../services/api.service';
import MdTablePagination from '../../../components/pagination';
import {PERMISSION, STATUS} from '../../../constants/spec';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {AddBtn, DelBtn, EditBtn, Link, PrimaryBtn, WarningBtn} from '../../../components/md-button';
import {SimpleAsyncSelect, SimpleSelect} from '../../../components/md-select';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import SearchFilter, {SimpleSearchField} from '../../../components/search-filter';
import {TECHNOLOGY_ASSETS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";
import {CheckCircle, Close, Cloud, Delete, Dvr, Home, MergeType, RadioButtonChecked, StoreMallDirectory} from '@mui/icons-material';
import {SimpleDataPicker} from "../../../components/md-date-picker";
import moment from "moment";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list, hints } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={TECHNOLOGY_ASSETS_MAP}  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <RichHintTitle update={LIST}  name={'TECHNOLOGY_ASSETS$TITLE'} expectAnswer={expectAnswer} data={findHint(hints, 'TECHNOLOGY_ASSETS$TITLE')}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} md={10}>
                            <FiltersPanelConnected />
                        </Col>
                        <Col xs={12} md={2} className="text-right offset-top-2">
                            <Link Btn={AddBtn} placement="left" to={TECHNOLOGY_ASSETS.LINK_EDIT()} permission={PERMISSION.SYSTEM.CREATE} hint = {findHint(hints, 'BUTTON_TECHNOLOGY_ASSET_ADD')} />
                        </Col>
                    </Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')}  </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.technologyAssets.list.expectAnswer, list: state.technologyAssets.list.data, hints: state.technologyAssets.list.hintsData}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.technologyAssets.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = withTranslation(connect(
    state => ({showAdvanced: state.technologyAssets.list.showAdvanced, hints: state.technologyAssets.list.hintsData}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => {
            dispatch({type: LIST.META, showAdvanced: true});
            dispatch({type: LIST.UPDATE_DATA, filter: ''});
        },
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ({showAdvanced, update, openAdvanced, closeAdvanced, hints}) => (<div>
    { showAdvanced ? (
        <Row>
            <Col xs={12}>
                <Card style={{overflow: 'visible'}}>
                    <CardHeader
                        title={translate('GLOBALS$ADVANCED_SEARCH')}
                        action={
                            <Tooltip title={translate('GLOBALS$CLOSE')}>
                                <IconButton aria-label={translate('GLOBALS$CLOSE')} onClick={closeAdvanced}>
                                    <Close fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }
                    />
                    <CardContent> <SearchForm /> </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn onClick={update} tooltip={translate('GLOBALS$APPLY')} hint={findHint(hints, 'BUTTON_TECHNOLOGY_ASSETS_ADVANCED_SEARCH_APPLY')}> {translate('GLOBALS$APPLY')}  </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn onClick={closeAdvanced} tooltip={translate('GLOBALS$CLOSE')} hint={findHint(hints, 'BUTTON_TECHNOLOGY_ASSETS_ADVANCED_SEARCH_CLOSE')}> {translate('GLOBALS$CLOSE')} </WarningBtn>
                    </CardActions>
                </Card>
            </Col>
        </Row>
    ) : (
        <Row>
            <Col xs={12} sm={6} lg={3} className="offset-bottom-2">
                <SearchFilterConnected />
            </Col>
            <Col xs={12} sm={6} lg={9} className="offset-top-2">
                <PrimaryBtn hint={findHint(hints, 'BUTTON_TECHNOLOGY_ASSETS_ADVANCED_SEARCH')} onClick={openAdvanced} tooltip={translate('GLOBALS$ADVANCED_SEARCH')} > {translate('GLOBALS$ADVANCED_SEARCH')} </PrimaryBtn>
            </Col>
        </Row>
    )}
</div>)));

/**
 * search form
 *
 * @public
 */
const SearchForm = withTranslation(connect(
    state => ({ ...state.technologyAssets.list }),
    dispatch => ({ changeFilterValue: data => dispatch({type: LIST.META, ...data}) })
)( ({ expectAnswer, filter, systemStatus, ipAddress, technologyCategory, technology, loadedTechnologies, manufacturer, assetOwner, endOfLife, location, changeFilterValue }) => {
    console.log(technologyCategory);
    return (<div>
        <Row>
            <Col xs={12} md={4} className="offset-bottom-4">
                <SimpleSearchField
                    value={filter}
                    disabled={expectAnswer}
                    clear={() => changeFilterValue({filter: ''})}
                    onChange={e => changeFilterValue({filter: e.target.value})}
                />
            </Col>
            <Col xs={12} md={4} className="offset-bottom-4">
                <SimpleSelect
                    value={systemStatus}
                    valueKey="value"
                    labelKey="label"
                    isClearable={true}
                    disabled={expectAnswer}
                    placeholder={translate('GLOBALS$STATUS')}
                    label={(<strong> {translate('GLOBALS$STATUS')} </strong>)}
                    sendValueProp
                    options={[STATUS.ACTIVE, STATUS.DISABLED, STATUS.PENDING].map(option=>{
                        return ({
                            label: option ,
                            value: option
                        })
                    })}
                    simpleValue={(value) => { return {value: value, label: value} }}
                    onChange={systemStatus => {changeFilterValue({systemStatus})}}
                    // clear={() => {changeFilterValue({systemStatus: null})}}
                />
            </Col>
            <Col xs={12} md={4} className="offset-bottom-4">
                <SimpleSearchField
                    value={ipAddress}
                    disabled={expectAnswer}
                    placeholder={translate('TECHNOLOGY$IP_ADDRESS')}
                    label={(<strong> {translate('TECHNOLOGY$IP_ADDRESS')} </strong>)}
                    clear={() => changeFilterValue({ipAddress: ''})}
                    onChange={e => changeFilterValue({ipAddress: e.target.value})}
                />
            </Col>
            <Col xs={12} md={4} className="offset-bottom-4">
                <SimpleAsyncSelect
                    isClearable={true}
                    value={technologyCategory}
                    disabled={expectAnswer}
                    placeholder={translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CATEGORY')}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    onChange={technologyCategory => {
                        changeFilterValue({technologyCategory});
                        loadTechnologies('', technologyCategory, (items) => {}, changeFilterValue)
                    }}
                    label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CATEGORY')} </strong>)}
                    loadOptions={(name, done) => {
                        instanceAPI({method: 'post', url: 'technology-categories/filter', data: { filter: {name} }})
                            .then(({items}) => {
                                done(items);
                            }).catch(done.bind(null, []));
                    }}/>
            </Col>
            <Col xs={12} md={4} className="offset-bottom-4">
                <SimpleAsyncSelect
                    isClearable={true}
                    value={technology}
                    defaultOptions={loadedTechnologies}
                    disabled={expectAnswer}
                    placeholder={translate('TECHNOLOGY_ASSETS$TECHNOLOGIES')}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    onChange={technology => changeFilterValue({technology})}
                    label={(<strong> {translate('TECHNOLOGY_ASSETS$TECHNOLOGIES')} </strong>)}
                    loadOptions={(name, done) => {
                        loadTechnologies(name, technologyCategory, done, changeFilterValue);
                    }}/>
            </Col>
            <Col xs={12} md={4} className="offset-bottom-4">
                <SimpleAsyncSelect
                    isClearable={true}
                    value={manufacturer}
                    disabled={expectAnswer}
                    placeholder={translate('TECHNOLOGY_ASSETS$VENDOR')}
                    onChange={manufacturer => changeFilterValue({manufacturer})}
                    label={(<strong> {translate('TECHNOLOGY_ASSETS$VENDOR')} </strong>)}
                    loadOptions={(name, done) => {
                        instanceAPI({method: 'post', url: 'vendors/filter', data: { filter: {name} }})
                            .then(({items}) => {done(items)
                                console.log(items)}).catch(done.bind(null, []));
                    }}/>
            </Col>
            {/*<Col xs={12} md={4} className="offset-bottom-4">*/}
            {/*    <SimpleAsyncSelect*/}
            {/*        valueKey="id"*/}
            {/*        labelKey="fullName"*/}
            {/*        value={systemOwner}*/}
            {/*        isClearable={true}*/}
            {/*        disabled={expectAnswer}*/}
            {/*        placeholder={translate('GLOBALS$OWNER')}*/}
            {/*        label={(<strong> {translate('GLOBALS$OWNER')} </strong>)}*/}
            {/*        onChange={systemOwner => changeFilterValue({systemOwner})}*/}
            {/*        loadOptions={(name, done) => {*/}
            {/*            instanceAPI({method: 'post', url: `/users/filter`, data: {filter: {name, roles: [ROLES.SYSOWN]}} })*/}
            {/*                .then(({items}) => done(items)).catch(done.bind(null, []));*/}
            {/*        }}/>*/}
            {/*</Col>*/}
            <Col xs={12} md={4} className="offset-bottom-4">
                <SimpleSearchField
                    value={assetOwner}
                    disabled={expectAnswer}
                    placeholder={translate('TECHNOLOGY_ASSETS$OWNER_NAME')}
                    label={(<strong> {translate('TECHNOLOGY_ASSETS$OWNER_NAME')} </strong>)}
                    clear={() => changeFilterValue({assetOwner: ''})}
                    onChange={e => changeFilterValue({assetOwner: e.target.value})}
                />
            </Col>
            <Col xs={12} md={4} className="offset-bottom-4">
                <SimpleDataPicker
                    value={endOfLife}
                    disabled={expectAnswer}
                    placeholder={translate('TECHNOLOGY$END_OF_LIFE')}
                    label={(<strong> {translate('TECHNOLOGY$END_OF_LIFE')} </strong>)}
                    clear={() => changeFilterValue({endOfLife: ''})}
                    onChange={endOfLife => {
                        console.log('endOfLife', endOfLife);
                        changeFilterValue({endOfLife});
                    }}
                />
            </Col>
            {/* name="eolDate"
                        component={MdDatePicker}
                        label={(<strong> {translate('TECHNOLOGY$END_OF_LIFE')} </strong>)} */}
            <Col xs={12} md={4} className="offset-bottom-4">
                <SimpleSearchField
                    value={location}
                    disabled={expectAnswer}
                    placeholder={translate('TECHNOLOGY$LOCATION')}
                    label={(<strong> {translate('TECHNOLOGY$LOCATION')} </strong>)}
                    clear={() => changeFilterValue({location: ''})}
                    onChange={e => changeFilterValue({location: e.target.value})}
                />
            </Col>

        </Row>
    </div>)
}));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter:  state.technologyAssets.list.filter,
        disabled: state.technologyAssets.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
    />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.technologyAssets.list, hints: state.technologyAssets.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th" style={{width: '30%'}}>
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('TECHNOLOGY_ASSETS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'manufacturer'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('manufacturer')}
                                >
                            {translate('TECHNOLOGY_ASSETS$VENDOR')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" style={{width: '30%'}}>
                        <TableSortLabel
                            direction={sortD ? 'asc' : 'desc'}
                            active={sortF === 'technology'}
                            onClick={()=>changeSort('technology')}
                                >
                            {translate('TECHNOLOGY_ASSETS$TECHNOLOGIES')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        {translate('TECHNOLOGY_ASSETS$SUMMARY')}
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>
                        {
                            item.systemStatus === 'ACTIVE'
                            ? <Tooltip title={translate(item.systemStatus)} className="offset-bottom-1"><CheckCircle style={{color:"#009703" }} className="align-middle" /></Tooltip>
                            : <Tooltip title={translate(item.systemStatus)} className="offset-bottom-1"><Delete style={{color:"#fe0000"}} className="align-middle" /></Tooltip>
                        }
                        &nbsp;
                        {buildSystemTypeIcon(item.systemType)}
                        &nbsp;
                        {item.name + (item.versionNumber ? " (" + item.versionNumber + ")" : '')}
                    </TableCell>
                    {/*<AbbrevTableCell content={item.description} title={item.name} length={40}/>*/}
                    <TableCell>{get(item, 'manufacturer.name')}</TableCell>
                    <TableCell>
                        {(<span className="cell-text-blue-gray">{get(item, 'technologyCategory.name')}</span>)}
                        {item.technologySubcategory ? (<span> / <span className="cell-text-blue-gray">{item.technologySubcategory.name}</span></span>) : ""}
                        {item.technologyClassType ? (<span> / <span className="cell-text-blue-gray">{item.technologyClassType.name}</span></span>) : ""}
                        {item.technology ? (<span> / <span className="cell-text-blue-gray">{item.technology.name}</span></span>) : ""}
                    </TableCell>
                    <TableCell>
                        {get(item, 'ipAddress') ? (<p>{translate('TECHNOLOGY$IP_ADDRESS')}: <span className={"cell-text-light-blue"}>{get(item, 'ipAddress')}</span></p>) : ""}
                        {get(item, 'eolDate') ? (
                            <p>{translate('TECHNOLOGY$END_OF_LIFE')}: <span className={moment(get(item, 'eolDate')).isBefore(moment()) ? "cell-text-pink" : "cell-text-light-blue"}>{moment(get(item, 'eolDate')).format('YYYY-MM-DD')}</span></p>
                        ) : ""}
                        {get(item, 'location') ? (<p>{translate('TECHNOLOGY$LOCATION')}: <span className={"cell-text-light-blue"}>{get(item, 'location')}</span></p>) : ""}
                    </TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.SYSTEM.UPDATE}
                            to={TECHNOLOGY_ASSETS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            hint = {findHint(hints, 'BUTTON_TECHNOLOGY_ASSETS_EDIT')}
                            className="offset-left-2"
                                />
                        <DelBtn permission={PERMISSION.SYSTEM.DELETE} onClick={() => deleteItem(item.id)} className="offset-left-2" hint = {findHint(hints, 'BUTTON_TECHNOLOGY_ASSETS_DELETE')}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));

const ConnectedError = connect(
    state => ({message: state.technologyAssets.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

function buildSystemTypeIcon(systemType) {
    switch (systemType) {
        case 'ON_PREMISE':
            return <Tooltip title={systemType} className="offset-bottom-1"><StoreMallDirectory  style={{color:"#337ab7" }}  color="#337ab7" className="align-middle" /></Tooltip>;
        case 'CLOUD':
            return <Tooltip title={systemType} className="offset-bottom-1"><Cloud  style={{color:"#337ab7" }} color="#337ab7" className="align-middle" /></Tooltip>;
        case 'HOME_GROWN':
            return <Tooltip title={systemType} className="offset-bottom-1"><Home  style={{color:"#337ab7" }} color="#337ab7" className="align-middle" /></Tooltip>;
        case 'COTS':
            return <Tooltip title={systemType} className="offset-bottom-1"><Dvr  style={{color:"#337ab7" }} color="#337ab7" className="align-middle" /></Tooltip>;
        case 'MA':
            return <Tooltip title={systemType} className="offset-bottom-1"><MergeType  style={{color:"#337ab7" }}  color="#337ab7" className="align-middle" /></Tooltip>;
        default:
            return <Tooltip title={systemType} className="offset-bottom-1"><RadioButtonChecked  style={{color:"#337ab7" }}   className="align-middle" /></Tooltip>;
    }
}

function loadTechnologies(name, technologyCategory, done, changeFilterValue) {
    instanceAPI({method: 'post', url: 'technologies/filter', data: { filter: {name, technologyCategoryId: (technologyCategory ? technologyCategory.id : null)} }})
        .then(({items}) => {
            done(items);
            changeFilterValue({loadedTechnologies: items})
        }).catch(done.bind(null, []));
}
