// outsource dependencies
import moment from 'moment';
import {get, isEmpty} from 'lodash';
import {connect} from 'react-redux';
import {Col, Container, Row} from 'react-bootstrap';
import React, {Fragment, useEffect} from 'react';
import {List as ListIcon, PlaylistAddCheck} from '@mui/icons-material';
import {Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from '@mui/material';

// local dependencies
import {EDIT} from '../actions';
import {config} from '../../../constants';
import Preloader from '../../../components/preloader';
import {CancelBtn} from '../../../components/md-button';
import ErrorMessage from '../../../components/alert-error';
import {translate, withTranslation} from '../../../services/translate.service';
import {AUDIT_LOGS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

// config
export const DIFF_MODE = {
    ALL: 'ALL',
    MODIFIED: 'MODIFIED',
};

const Edit = (props) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        console.log(props);
        return () => {
            props.clear();
        };
    }, []);
    let {disabled, message, clearError, data, hints} = props;

    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={AUDIT_LOGS_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12}>
                        <Paper className="indent-5">
                            <Row className="offset-bottom-6">
                                <Col xs={12}>
                                    <h2 className="text-uppercase">
                                        <RichHintTitle
                                            update={EDIT}
                                            name={`AUDIT_LOGS$DETAILS`}
                                            data={findHint(hints, `AUDIT_LOGS_TITLE`)}/>
                                        &nbsp;<Preloader expectAnswer={disabled} type="ICON"/>
                                    </h2>
                                </Col>
                            </Row>
                            <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col>
                            </Row>
                            {isEmpty(data) ? (
                                <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                            ) : (
                                <Row><Col xs={12}> <Info/> </Col></Row>
                            )}
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};


export default connect(
    state => ({
        data: state.auditLogs.edit.data,
        message: state.auditLogs.edit.errorMessage,
        disabled: state.auditLogs.edit.expectAnswer,
        hints: state.auditLogs.edit.hintsData,
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.auditLogs.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const Info = withTranslation(connect(
    state => ({
        data: state.auditLogs.edit.data,
        diffMode: state.auditLogs.edit.diffMode,
        disabled: state.auditLogs.edit.expectAnswer,
        hints: state.auditLogs.edit.hintsData,
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        changeMode: diffMode => dispatch({type: EDIT.CHANGE_MODE, diffMode})
    })
)(({data, cancel, changeMode, diffMode, disabled, hints}) => {
    return (<Fragment>
        <Row><Col xs={12}>
            <h4><strong>{translate('AUDIT_LOGS$AUDITOR_NAME')}</strong> {data.auditUserName}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>{translate('AUDIT_LOGS$AUDITOR_EMAIL')}</strong> {data.auditUserEmail}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>{translate('AUDIT_LOGS$OPERATION_TYPE_COLON')}</strong> {data.operationTypeInfo}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4><strong>{translate('AUDIT_LOGS$ITEM_TYPE_COLON')}</strong> {get(data, 'itemTypeInfo.name')}</h4>
        </Col></Row>
        <Row><Col xs={12}>
            <h4>
                <strong>{translate('AUDIT_LOGS$UPDATED_DATE_COLON')}</strong> {data.logDate && moment(data.logDate).format(config.clientTimeFormat)}
            </h4>
        </Col></Row>
        <Row className="offset-bottom-4"> <Col xs={12}>
            <h3 className="text-center text-uppercase">{translate('AUDIT_LOGS$DIFF')}</h3>
        </Col> </Row>
        <Row className="offset-bottom-4"> <Col xs={12} className="text-center">
            <div className="md-btn-group">
                <Tooltip title={translate('GLOBALS$ALL_ITEMS')}><span>
                    <Button
                        variant="contained"
                        disabled={disabled}
                        onClick={() => changeMode(DIFF_MODE.ALL)}
                        className={diffMode === DIFF_MODE.ALL ? 'md-btn active' : 'md-btn'}
                    >
                        <ListIcon/>
                    </Button>
                </span></Tooltip>
                <Tooltip title={translate('AUDIT_LOGS$MODIFIED_ITEMS')}><span>
                    <Button
                        variant="contained"
                        disabled={disabled}
                        onClick={() => changeMode(DIFF_MODE.MODIFIED)}
                        className={diffMode === DIFF_MODE.MODIFIED ? 'md-btn active' : 'md-btn'}
                    >
                        <PlaylistAddCheck/>
                    </Button>
                </span></Tooltip>
            </div>
        </Col> </Row>
        <Row className="offset-bottom-8"><Col xs={12}><Diff/></Col></Row>
        <Row><Col xs={12} className="text-right"> <CancelBtn onClick={cancel}  hint={findHint(hints, 'BUTTON_AUDIT_LOGS_CANCEL')}/> </Col></Row>
    </Fragment>);
}));

const Diff = withTranslation(connect(
    state => ({diff: state.auditLogs.edit.list}),
    null
)(({diff}) => {
    return (<Table className="md-table diff" padding="checkbox">
        <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
            <TableRow style={{height: 48}}>
                <TableCell className="th"> {translate('AUDIT_LOGS$PROPERTY')} </TableCell>
                <TableCell className="th text-nowrap"> {translate('AUDIT_LOGS$OLD_VALUE')} </TableCell>
                <TableCell className="th text-nowrap"> {translate('AUDIT_LOGS$NEW_VALUE')} </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {diff.length ? null : (<TableRow style={{height: 48}}><TableCell colSpan={100} className="cell">
                <h3 className="text-center text-uppercase">{translate('GLOBALS$NO_CHANGES')}</h3>
            </TableCell></TableRow>)}
            {diff.map((item, i) => item.delimiter ? (
                <TableRow key={i} className="delimiter">
                    <TableCell colSpan={100} className="cell"> <strong>{item.property}</strong> </TableCell>
                </TableRow>
            ) : (
                <TableRow key={i} className={item.status}>
                    <TableCell>{item.property}</TableCell>
                    <TableCell>{item.oldValue}</TableCell>
                    <TableCell>{item.newValue}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>);
}));
