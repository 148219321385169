// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import Page from '../../../components/page';
import {PERMISSION} from '../../../constants/spec';
import {VULNERABILITIES} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {VULNERABILITIES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

export default connect(
    state => ({...state.vulnerabilities.list,  hints: state.vulnerabilities.list.hintsData}),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null})
    })
)(({ data, hints, initialized, expectAnswer, errorMessage, initialize, clearError }) => (
    <Page init={initialize} initialized={initialized}>
        <Breadcrumbs breadCrumbsMap={ VULNERABILITIES_MAP }  />
        <Row className="offset-bottom-4">
            <Col xs={8}>
                <RichHintTitle update={LIST}  name={`VULNERABILITIES$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `VULNERABILITIES_TITLE`)}/>
            </Col>
            <Col xs={4} className="text-right top-indent-4">
                <Link Btn={AddBtn} placement="left" to={VULNERABILITIES.LINK_EDIT()} permission={PERMISSION.VULNERABILITY.CREATE} hint={findHint(hints, 'BUTTON_VULNERABILITIES_ADD')}/>
            </Col>
        </Row>
        <Row className="offset-bottom-4"> <Col xs={12}>
            <div style={{width: 300}}> <SearchFilterConnected /> </div>
        </Col> </Row>
        <Row> <Col xs={12}> <ErrorMessage active message={errorMessage} onChange={clearError}/> </Col> </Row>
        <Row> <Col xs={12}>
            {data.length ? (<ConnectedTable />) : (<h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>)}
        </Col> </Row>
    </Page>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.vulnerabilities.list.filter,
        disabled: state.vulnerabilities.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.vulnerabilities.list, hints: state.vulnerabilities.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<Paper>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'code'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('code')}
                                >
                            {translate('GLOBALS$CODE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'status'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('status')}
                                >
                            {translate('GLOBALS$STATUS')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'percentage'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('percentage')}
                                >
                            {translate('GLOBALS$PERCENTAGE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'score'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('score')}
                                >
                            {translate('VULNERABILITIES$SCORE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.percentage}</TableCell>
                    <TableCell>{item.score}</TableCell>
                    <TableCell className="text-nowrap" align="right">
                        <Link
                            Btn={EditBtn}
                            className="offset-right-2"
                            permission={PERMISSION.VULNERABILITY.UPDATE}
                            to={VULNERABILITIES.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            hint={findHint(hints, 'BUTTON_VULNERABILITIES_EDIT')}
                                />
                        <DelBtn
                            onClick={() => deleteItem(item.id)}
                            permission={PERMISSION.VULNERABILITY.DELETE}
                            hint={findHint(hints, 'BUTTON_VULNERABILITIES_DELETE')}
                                />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</Paper>)));
