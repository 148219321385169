
// outsource dependencies

// local dependencies
import { QUESTIONS } from '../actions';
import { VENDORS } from '../../../constants/routes';

export const initial = {
    data: {},
    hintsData: [],
    metric: 'all',
    group: 'scoring',
    metricDomains: [],
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
    // previous path
    back: VENDORS.LINK(),
};

export default function ( state = initial, action ) {
    //eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case QUESTIONS.CLEAR:
            state = initial;
            break;
        case QUESTIONS.DATA:
            state = { ...state, data: options.data };
            break;
        case QUESTIONS.META:
            state = { ...state, ...options, data: state.data };
            break;
    }

    return state;
}
