// outsource dependencies
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import React, {Component, Fragment} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Search} from '@mui/icons-material';
import {get, cloneDeep, debounce, chunk, some, filter as lodashFilter} from 'lodash';
import {
    Paper,
    AppBar,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    TextField,
    IconButton,
    Tooltip as MdTooltip,
    Card,
} from '@mui/material';
import {withStyles} from '@mui/styles'
import { createBreakpoint } from "react-use";

import {
    BarChart,
    XAxis,
    YAxis,
    ZAxis,
    Brush,
    Cell as ChartCell,
    Tooltip,
    Bar,
    ResponsiveContainer,
    CartesianGrid,
    ScatterChart,
    Scatter,
    ReferenceLine,
    PieChart,
    Pie
} from 'recharts';

// local dependencies
import {historyPush, history} from '../store';
import {SimpleRadio} from './md-radio';
import SearchFilter from './search-filter';
import {is} from '../services/is.service';
import MdTablePagination from './pagination';
import * as ROUTES from '../constants/routes';
import {DASHBOARD_ITEMS_TYPE} from '../constants/spec';
import {SuccessBtn, Link as BtnLink, PrimaryBtn} from './md-button';
import {DashboardFilterPanel} from './dashboard-filters';
import {translate, withTranslation} from '../services/translate.service';
import queryService from '../services/query.service';

import {EDIT} from '../private-layout/dashboards/actions';
import {connect} from 'react-redux';
import convertToQueryString from '../helpers/converToQueryString';
import dompurify from "dompurify";

// config
const DEFAULT_SORT = 'Default';

/**
 * dashboard table with client-side pagination
 *
 */
class AdvancedDashboardTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            page: 0,
            size: 10,
            filter: '',
            // NOTE to not match with api 'null' value
            sortF: -1,
            sortD: true,
            totalPages: 0,
            list: this.props.gridItems || [],
        };
        this.changeSort = this.changeSort.bind(this);
    }

    componentDidMount() {
        // NOTE first getting of list with pagination
        this.getList();
    }

    componentDidUpdate(prevProps) {
        if (get(prevProps, 'gridItems', []) !== get(this.props, 'gridItems', [])) {
            // NOTE update items list and setup default table settings when dashboard section was updated
            this.updateList({
                list: get(this.props, 'gridItems', []),
                page: 0,
                size: 10,
                filter: '',
                sortF: -1,
                sortD: true,
                totalPages: 0
            });
        }
    }

    getList() {
        let {list, page, size} = this.state;
        // pagination
        let items = chunk(list, size);
        let totalPages = items.length;
        items = items[page] || [];
        this.setState({items, totalPages});
    }

    updateList(options = {}) {
        this.setState(options, this.getList);
    }

    changeSort(field) {
        let {sortF, sortD, list} = this.state;
        // NOTE change direction if field has already chosen
        sortD = field === sortF ? !sortD : true;
        // NOTE sort data
        let sorted = is.number(field) ? sortTable(list, field, sortD) : list;
        // NOTE update state
        this.updateList({sortF: field, sortD, list: sorted, page: 0});
    };

    applyFilter(filter) {
        let {gridItems} = this.props;
        let initialList = is.array(gridItems) ? gridItems : [];
        // NOTE filter whole list by search string (search in each cell) or return initial list
        let filtered = filter
            ? lodashFilter(initialList, row => some(row, item => String(item.value || '').toLowerCase().includes(filter.toLowerCase())))
            : initialList;
        this.updateList({list: filtered, filter, page: 0, sortF: -1});
    }

    render() {
        let {items, page, size, totalPages, sortF, sortD, filter} = this.state;
        let {name, dashboardItemFilter, updateInput, updateData, index, handleFilterPanelState, disabled, gridHeaders, gridFooters} = this.props;
        return (<div>
            {(name || (dashboardItemFilter && !dashboardItemFilter.isOpen)) && (<Row className="offset-bottom-2">
                <Col xs={12}>
                    <h3 className="row-adornment text-center text-uppercase">
                        {name ? (<span className="align-middle">{name}</span>) : ""}
                        {dashboardItemFilter && !dashboardItemFilter.isOpen ? (
                            <MdTooltip title={translate('GLOBALS$SEARCH')} placement="top" className="adornment"><span>
                                <IconButton
                                    color="primary"
                                    style={{padding: 5}}
                                    aria-label={translate('GLOBALS$SEARCH')}
                                    onClick={() => handleFilterPanelState(index, true)}
                                >
                                    <Search fontSize="small"/>
                                </IconButton>
                            </span></MdTooltip>
                        ) : null}
                    </h3>
                </Col>
            </Row>)}
            {!dashboardItemFilter ? null : (
                <Row> <Col xs={12}>
                    <DashboardFilterPanel
                        {...dashboardItemFilter}
                        index={index}
                        disabled={disabled}
                        updateData={updateData}
                        handleFilterPanelState={handleFilterPanelState}
                    />
                </Col> </Row>
            )}
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Card>
                        <div className="indent-2" style={{width: 300}}>
                            {/*MOTE reset 'filter' on 'Escape' press*/}
                            <SearchFilter
                                value={filter}
                                clear={() => this.applyFilter('')}
                                apply={() => this.applyFilter(filter)}
                                placeholder={translate('GLOBALS$SEARCH')}
                                onInputChange={e => this.setState({filter: e})}
                                onKeyUp={e => e.key === 'Escape' && this.applyFilter('')}
                            />
                        </div>
                        <div style={{overflowX: 'auto'}}>
                            <Table className="md-table" padding="checkbox">
                                <TableBody>
                                    {/*HEADERS*/}
                                    {(gridHeaders || []).map((row, key) => (
                                        <TableRow  key={key} style={{height: 48}}>
                                            {row.map((cell = {}, index) => {
                                               return <Cell  key={index} {...cell} header sortOptions={{
                                                    index: cell.sortIndex,
                                                    sortF,
                                                    sortD,
                                                    changeSort: this.changeSort
                                                }} />
                                                })}
                                        </TableRow>
                                    ))}
                                    {/*EMPTY LIST*/}
                                    {!items.length && (<TableRow style={{height: 48}}>
                                        <TableCell colSpan={100}>
                                            <h4 className="text-uppercase text-center">
                                                <strong> {translate('GLOBALS$NO_DATA')} </strong></h4>
                                        </TableCell>
                                    </TableRow>)}
                                    {/*ITEMS LIST*/}
                                    {items.map((row, key) => (
                                        <TableRow key={key}>
                                            {row.map((cell, index) => (
                                                <Cell key={index} updateInput={updateInput} updateData={updateData} {...cell} {...{record: cell}} />
                                            ))}
                                        </TableRow>
                                    ))}
                                    {/*FOOTER*/}
                                    {(gridFooters || []).map((row, key) => (
                                        <TableRow key={key}>
                                            {row.map((cell, index) => (
                                                <Cell key={index} {...cell} />
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <MdTablePagination
                            page={page}
                            size={size}
                            totalPages={totalPages}
                            changePage={page => this.updateList({page})}
                            changeSize={size => this.updateList({size, page: 0})}
                        />
                    </Card>
                </Col>
            </Row>
        </div>);
    }
}


/**
 * sortable dashboard table component
 *
 * @param {Object} props
 * @public
 */
class DashboardTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.gridItems || [],
            sortF: null,
            sortD: true
        };
    }

    componentDidUpdate(prevProps) {
        if (get(prevProps, 'gridItems', []) !== get(this.props, 'gridItems', [])) {
            // NOTE update state when dashboard section was updated
            this.setState({rows: get(this.props, 'gridItems', [])});
        }
    }

    changeSort = (field) => {
        let {sortF, sortD, rows} = this.state;
        // NOTE change direction if field has already chosen
        sortD = field === sortF ? !sortD : true;
        // NOTE sort data
        let sorted = is.number(field) ? sortTable(rows, field, sortD) : rows;
        // NOTE update state
        this.setState({sortF: field, sortD, rows: sorted});
    };

    render() {
        let {sortF, sortD, rows} = this.state;
        let {name, dashboardItemFilter, updateData, index, handleFilterPanelState, disabled} = this.props;
        return (<div>
            {(name || (dashboardItemFilter && !dashboardItemFilter.isOpen)) && (<Row className="offset-bottom-2">
                <Col xs={12}>
                    <h3 className="row-adornment text-center text-uppercase">
                        {name ? (<span className="align-middle">{name}</span>) : ""}
                        {dashboardItemFilter && !dashboardItemFilter.isOpen ? (
                            <MdTooltip title={translate('GLOBALS$SEARCH')} placement="top" className="adornment"><span>
                                <IconButton
                                    color="primary"
                                    style={{padding: 5}}
                                    aria-label={translate('GLOBALS$SEARCH')}
                                    onClick={() => handleFilterPanelState(index, true)}
                                >
                                    <Search fontSize="small"/>
                                </IconButton>
                            </span></MdTooltip>
                        ) : null}
                    </h3>
                </Col>
            </Row>)}
            {!dashboardItemFilter ? null : (
                <Row> <Col xs={12}>
                    <DashboardFilterPanel
                        {...dashboardItemFilter}
                        index={index}
                        disabled={disabled}
                        updateData={updateData}
                        handleFilterPanelState={handleFilterPanelState}
                    />
                </Col> </Row>
            )}
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Paper style={{overflowX: 'auto'}}>
                        <Table className="md-table" padding="checkbox">
                            <TableBody>
                                {rows.map((row, key) => (
                                    <TableRow key={key}>
                                        {row.map((cell, index) => (
                                            <Cell
                                                key={index}
                                                {...cell}
                                                sortOptions={{
                                                    index,
                                                    sortF,
                                                    sortD,
                                                    changeSort: this.changeSort
                                                }}/>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Col>
            </Row>
        </div>);
    }
}

class DashboardBarchart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // NOTE data with possible filters
            items: [],
            // NOTE initial data
            initial: [],
            sortF: DEFAULT_SORT,
        };
    }

    componentDidMount() {
        this.setItems();
    }

    componentDidUpdate(prevProps) {
        if (get(prevProps, 'gridItems', []) !== get(this.props, 'gridItems', [])) {
            // NOTE update items
            this.setItems();
            // NOTE reset sorting
            this.setState({sortF: DEFAULT_SORT});
        }
    }

    setItems() {
        let {gridItems, xaxis, yaxis} = this.props;
        // NOTE prepare data for view
        let items = (gridItems || []).map(item => ({
            [xaxis]: get(item, '[0].value', ''),
            [yaxis]: get(item, '[1].value', ''),
            link: get(item, '[0].link', null),
            drilldown: get(item, '[0].drilldown', null)
        }));
        this.setState({items, initial: [...items]});
    }

    changeSort(field) {
        let {items, initial} = this.state;
        let filtered = field === DEFAULT_SORT ? [...initial] : items.sort((a, b) => {
            // NOTE sorting for numbers
            if (is.countable(Number(a[field])) || is.countable(Number(b[field]))) {
                return Number(a[field]) - Number(b[field]);
            }
            // NOTE sorting for string
            let x = is.string(a[field]) ? (a[field]).toLowerCase() : '';
            let y = is.string(b[field]) ? (b[field]).toLowerCase() : '';
            return x < y ? -1 : x > y ? 1 : 0;
        });
        this.setState({items: filtered, sortF: field});
    }

    render() {
        let {items, sortF} = this.state;
        let {name, xaxis, yaxis, threshold} = this.props;
        return (
            <div>
                {name ? (<Row className="offset-bottom-4"><Col xs={12}><h3 className="text-center text-uppercase">{name}</h3></Col></Row>) : ""}
                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Paper className="indent-5">
                            <div className="text-center offset-bottom-1">
                                <h4 style={{
                                    display: 'inline-block',
                                    padding: '4px 0',
                                    margin: '10px 15px',
                                    color: '#777'
                                }}>
                                    <strong>{translate('DASHBOARDS$SORT_BY')}</strong>
                                </h4>
                                <SimpleRadio
                                    row
                                    name="sortF"
                                    value={sortF}
                                    onChange={field => this.changeSort(field)}
                                    options={[DEFAULT_SORT, xaxis, yaxis].map(v => ({value: v, label: v}))}
                                />
                            </div>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    data={items}
                                    barCategoryGap="30%"
                                    margin={{top: 10, bottom: 20, left: 5, right: 30}}
                                >
                                    <Tooltip/>
                                    <CartesianGrid/>
                                    {/*NOTE bar component with drilldown*/}
                                    <Bar
                                        dataKey={yaxis}
                                        fill="#213c60"
                                        onClick={({drilldown, link}) => navigateToDrillDown({drilldown, link})}
                                    />
                                    <XAxis
                                        angle={-8}
                                        tick={{dy: 5}}
                                        dataKey={xaxis}
                                    />
                                    <YAxis
                                        dataKey={yaxis}
                                        label={{value: yaxis, angle: -90, position: 'insideLeft'}}
                                    />
                                    <Brush dataKey={xaxis} height={30} stroke="#8884d8"/>
                                    {!threshold ? '' : (
                                        <ReferenceLine
                                            stroke="red"
                                            strokeWidth="3"
                                            x={threshold.x}
                                            y={threshold.y}
                                            label={{
                                                position: 'top',
                                                value: threshold.label,
                                                fill: 'red',
                                                fontSize: 14,
                                                fontWeight: 'bold'
                                            }}
                                        />
                                    )}
                                </BarChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Col>
                </Row>
            </div>
        );
    }
}

/**
 * dashboard component
 *
 * @param {Object} props
 * @private
 */

 const useBreakpoint = createBreakpoint({
   xl: 1563,
   lg: 1200,
   md: 900,
   sm: 600,
   xs: 0,
 });
const Dashboard = ({data, updateInput, tab, updateTab, updateData, handleFilterPanelState, disabled, classes}) => {
    let sections = get(data, 'sections', []);
    let currentTab = tab < sections.length ? tab : 0;
    let dashboardItems = get(sections[currentTab], 'dashboardItems', []);
    let width = useBreakpoint() 
    let variant = ['lg', 'xl'].includes(width) ? 'fullWidth' : 'scrollable';
    return (<Fragment>
        <Paper style={{borderRadius: `12px 12px 0 0`}} >
                {sections.length > 1 && (
                    <AppBar position="static" style={{minHeight: 48, borderRadius: `14px 14px 0 0`}}>
                        <Tabs
                            className="dashboard-tab-container"
                            variant={variant}
                            classes={classes}
                            value={currentTab}
                            onChange={(e, tab) => updateTab(tab)}
                        >
                            {sections.map((item, key) => (
                                <Tab className="dashboard-tab" key={key} value={key} label={item.name}/>
                            ))}
                        </Tabs>
                    </AppBar>
                )}
            <div className="indent-5">
                {!dashboardItems.length ? (
                    <h3 className="text-center text-uppercase"> {translate('GLOBALS$NO_DATA')} </h3>
                ) : (
                    dashboardItems.map((item = {}, key) => {
                        let ItemComponent = getDasboardItemByType(item.dashboardItemType);
                        if (!ItemComponent) {
                            return null;
                        }
                        return (
                            <ItemComponent
                                key={key}
                                {...item}
                                index={key}
                                disabled={disabled}
                                updateData={updateData}
                                updateInput={updateInput}
                                handleFilterPanelState={handleFilterPanelState}
                            />
                        );
                    })
                )}
            </div>
        </Paper>
    </Fragment>);
};

Dashboard.propTypes = {
    tab: PropTypes.number,
    updateTab: PropTypes.func,
    updateInput: PropTypes.func,
    data: PropTypes.object.isRequired,
};

export default withTranslation(withStyles({indicator: {backgroundColor: '#ccc'}})(Dashboard));

/**
 * dashboard Grid Layout component
 *
 * @param {Object} props
 * @public
 */
const DashboardGridLayout = ({gridItems = [], ...attr}) => {
    return (<Paper className="offset-bottom-2 indent-2" style={{overflowX: 'auto'}}>
        <Table padding="checkbox">
            <TableBody>
                {gridItems.map((row, key) => (
                    <TableRow key={key}>
                        {row.map(({rowSpan, colSpan, color, textAlign, backgroundColor, value = {}, header, params}, index) => (
                            <TableCell
                                key={index}
                                rowSpan={rowSpan}
                                colSpan={colSpan}
                                component={header ? 'th' : 'td'}
                                style={{
                                    color,
                                    textAlign,
                                    backgroundColor,
                                    border: 'none',
                                    fontWeight: header ? 'bold' : 'normal',
                                    width: params && params.width ? params.width : 'auto',
                                }}>
                                {(() => {
                                    let ItemComponent = getDasboardItemByType(value.dashboardItemType);
                                    if (!ItemComponent) {
                                        return null;
                                    }
                                    return (<ItemComponent key={index} {...attr} {...value} />);
                                })()}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Paper>);
};

/**
 * dashboard text component
 *
 * @param {Object} props
 * @public
 */
const DashboardText = ({name, description}) => (
    <Row className="offset-bottom-4"><Col xs={12}>
        {name && <h3 className="offset-bottom-4">{name}</h3>}
        <h4>{description}</h4>
    </Col></Row>
);


/**
 * dashboard link component
 *
 * @param {Object} props
 * @public
 */

const DashboardLink = ({name, parameters = {}}) => {
    let {isExternal, href, textAlign, ...options} = parameters;
    return (<Row className="offset-bottom-4"><Col xs={12} className={`text-${textAlign}`}>
        {isExternal ? (
        <PrimaryBtn className="link-unstyled" component="a" href={href} tooltip={translate('GLOBALS$DOWNLOAD_DATA')} {...options}>
            <i className="fa fa-download" style={{fontSize: 20}} aria-hidden="true" />&nbsp;&nbsp;
            <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true" />
        </PrimaryBtn>
        ) : (
            <BtnLink Btn={SuccessBtn} to={href} tooltip={name} {...options}> {name} </BtnLink>
        )}
    </Col></Row>);
};


/**
 * dashboard input component
 *
 * @param {Object} props
 * @public
 */
const DashboardInput = props => {
    let {name, description, multiline = false, updateInput} = props;
    let update = debounce(data => updateInput(data), 3000);
    if (!description) description = '';
    return (<Row className="offset-bottom-4">
        <Col xs={12} className="top-indent-6">
            {name && <h3>{name}</h3>}
            <TextField
                fullWidth
                variant='standard'
                multiline={multiline}
                value={description}
                onChange={e => update({...props, description: e.target.value})}
            />
        </Col>
    </Row>);
};

const DashboardTextarea = props => <DashboardInput multiline={true} {...props}/>;


/**
 * dashboard input component
 *
 * @param {Object} props
 * @public
 */
const DashboardInputMinHeight = props => {
    let {name, description, multiline = false, updateInput} = props;
    let update = debounce(data => updateInput(data), 3000);
    if (!description || typeof(description) === "undefined") {
        description = '';
    }
    return (<div>
        {name && <h3>{name}</h3>}
        <TextField
            fullWidth
            variant='standard'
            multiline={multiline}
            defaultValue={description}
            onChange={e => update({...props, description: e.target.value})}
        />
    </div>);
};

/**
 * dashboard Qlik Analytics component
 *
 * @param {Object} props
 * @public
 */
class AnalyticsComponent extends Component {
    componentDidMount() {
        this.props.analyticsInit(this.props);
    }

    render() {
        const {name, src, height, width, isQlikAuth} = this.props;

        let iFrameHeight = height === '100%' ? '1080px' : height;

        if (this.props.analyticsType === 'POWER_BI') {
            return (<Fragment>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <div id="pnlPowerBIHolder" className="text-uppercase text-center text-highlighted">Loading Power BI Report</div>
                        </Col>
                    </Row>
                </Fragment>
            );
        } else {
            return (<Fragment>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            {isQlikAuth ?
                                <iframe title={name} style={{width, height: iFrameHeight, border: 'none'}} src={src}/>
                                :
                                <h3 className="text-uppercase text-center text-highlighted">Loading qLik data ...</h3>}
                        </Col>
                    </Row>
                </Fragment>
            );
        }

    }
}


// Export
let DashboardQlikAnalytics = connect(
    state => ({ isQlikAuth: state.dashboards.isQlikAuth, isPowerBIAuth: state.dashboards.isPowerBIAuth, accessConfig: state.dashboards.accessConfig }),
    dispatch => ({
        analyticsInit: (analyticsData) => dispatch({type: EDIT.QLIK_INITIALIZE, analyticsData})
    }),
)(AnalyticsComponent);

/**
 * dashboard pie chart component
 *
 * @param {Object} props
 * @public
 */
const DashboardPieChart = ({name, xaxis, yaxis, gridItems = [], parameters}) => {
    let items = gridItems.map(item => ({
        [yaxis]: get(item, '[0].value', ''),
        [xaxis]: is._number(Number(get(item, '[1].value'))) ? Number(get(item, '[1].value')) : 0,
        symbol: get(item, '[1].symbol'),
        link: get(item, '[0].link', null),
        drilldown: get(item, '[0].drilldown', null),
        backgroundColor: get(item, '[0].backgroundColor', '#213c60'),
    }));
    const height = get(parameters, 'height', 400);
    return (<div className="offset-bottom-2">
        {name && (<h3 className="text-center text-uppercase offset-bottom-2">{name}</h3>)}
        <Paper className="indent-2 text-center">
            <ResponsiveContainer width="100%" height={height} minWidth={450}>
                <PieChart>
                    <Pie
                        data={items}
                        fill="#213c60"
                        dataKey={xaxis}
                        nameKey={yaxis}
                        label={PieChartLabel}
                        isAnimationActive={false}
                    >
                        {items.map(({backgroundColor, drilldown, link}, index) =>
                            <ChartCell
                                key={index}
                                fill={backgroundColor}
                                onClick={() => navigateToDrillDown({drilldown, link})}
                            />
                        )}
                    </Pie>
                    <Tooltip
                        formatter={(value, name, props) => {
                            const symbol = get(props, 'payload.symbol');
                            return ([`${value}${symbol || ''}`, name]);
                        }
                        }/>
                </PieChart>
            </ResponsiveContainer>
        </Paper>
    </div>);
};

const PieChartLabel = ({cx, cy, midAngle, innerRadius, outerRadius, value, symbol}) => {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {value + (symbol ? symbol : '')}
        </text>
    );
};


/**
 * dashboard heat chart component
 *
 * @param {Object} props
 * @public
 */
const DashboardHeatChart = ({name, xaxis, yaxis, gridItems = []}) => {
    let prepared = (gridItems[0] || []).map(item => ({
        xaxis,
        yaxis,
        value: get(item, 'value', ''),
        link: get(item, 'link', null),
        drilldown: get(item, 'drilldown', null),
        radius: Number(get(item, 'params.radius')),
        x: Number(get(item, 'params.x')).toFixed(2),
        y: Number(get(item, 'params.y')).toFixed(2),
        color: get(item, 'params.color', '#8884d8'),
        totalExposure: get(item, 'params.totalExposure'),
    }));
    return (<div>
        {name ? (<Row className="offset-bottom-4"><Col xs={12}><h3 className="text-center text-uppercase">{name}</h3></Col></Row>) : ""}
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Paper className="indent-5">
                    <ResponsiveContainer width="100%" height={400}>
                        <ScatterChart margin={{top: 5, right: 5, bottom: 20, left: 5}}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis
                                dataKey="x"
                                type="number"
                                label={{value: xaxis, offset: -10, position: 'insideBottom'}}
                            />
                            <YAxis
                                dataKey="y"
                                type="number"
                                label={{value: yaxis, angle: -90, position: 'insideLeft'}}
                            />
                            <ZAxis type="number" scale="linear" dataKey="radius" range={[16, 8192]}/>
                            <Tooltip content={HeatChartTooltip}/>
                            <Scatter data={prepared}>
                                {prepared.map(({color, drilldown, link}, index) =>
                                    <ChartCell
                                        key={index}
                                        fill={color}
                                        onClick={() => navigateToDrillDown({drilldown, link})}
                                    />
                                )}
                            </Scatter>
                        </ScatterChart>
                    </ResponsiveContainer>
                </Paper>
            </Col>
        </Row>
    </div>);
};

/**
 * custom heat chart tooltip
 *
 * @param {Object} props
 * @public
 */
const HeatChartTooltip = ({active, payload = []}) => {
    if (!active || !payload.length) {
        return null;
    }
    let data = get(payload, '[0].payload', {});
    let {value, totalExposure} = data;
    return (
        <div style={{backgroundColor: '#fff', border: '1px solid #999', margin: 0, padding: 10}}>
            <p><strong> {value} </strong></p>
            <p>{translate('DASHBOARDS$TOTAL_EXPOSURE')}: {totalExposure}</p>
        </div>
    );
};

/**
 * dashboard table cell component
 *
 * @param {Object} props
 * @public
 */
const Cell = ({value, symbol, rowSpan, colSpan, header, color = 'black', backgroundColor = 'white', textAlign = 'left', drilldown, record, link, sortable, sortOptions, params, type, updateData, updateInput}) => {
    // console.log('Value:', value, ', params:', params);
    return (
        <TableCell
            rowSpan={rowSpan}
            colSpan={colSpan}
            component={header ? 'th' : 'td'}
            style={{
                color,
                textAlign,
                backgroundColor,
                border: '1px solid #e0e0e0',
                fontWeight: header ? 'bold' : 'normal',
                paddingLeft: header && '12px',
                paddingRight: header && '12px',
                width: params && params.width ? params.width : 'auto',
                height: params && params.height ? params.height : '48px',
            }}>
            <SortableComponent sortable={sortable} {...sortOptions}>
                {
                    (type === 'TEXTAREA') ?
                        (
                            <DashboardInputMinHeight
                                description={value}
                                updateData={updateData}
                                updateInput={updateInput}
                                multiline={true}
                                {...params}
                            />
                        )
                        :
                        (
                            <DrillDown link={link} {...drilldown} {...{record: record}}>
                                <span dangerouslySetInnerHTML={{__html: dompurify.sanitize((value ? value : '') + (symbol ? " " + symbol : ""))}}></span>
                            </DrillDown>
                        )
                }
            </SortableComponent>
        </TableCell>
    );
};

/**
 * sortable component for table cell
 *
 * @param {Object} props
 * @public
 */
function SortableComponent({sortable = false, index, sortF, sortD, changeSort, children}) {
    if (!sortable) {
        return children;
    }
    return (
        <TableSortLabel
            active={sortF === index}
            onClick={() => changeSort(index)}
            direction={sortD ? 'asc' : 'desc'}
            hideSortIcon
        >
            {children}
        </TableSortLabel>
    );
}

/**
 * dashboard drilldown component
 *
 * @param {Object} props
 * @public
 */
function DrillDown({view = '', params = {}, link = {}, children, record}) {
    const {pathname, search} = history.location;
    const {section} = queryService.parse(search);
    // NOTE adding "back" as "pathname" and "backSection" as "search" query params to display properly working breadcrumbs
    // NOTE if section tab selected -> add number of selected section to "backSection" query param
    // section ? params.backSection = section : null;
    if (section) {
        params.backSection = section;
    }
    params.back = pathname;
    if (view) {
        return <Link to={ROUTES.DRILLDOWN_PAGE.LINK({query: {...params, view}})}> {children} </Link>;
    }
    if (link && link.url) {
        const urlWithParams = convertToQueryString(link.url, {...params, view});
        const styles = {};
        if (record && record.color) {
            styles.color = record.color;
        }
        return <Link to={urlWithParams} style={styles}> {children} </Link>;
    }
    return children;
}

/**
 * navigate to drilldown or another link
 *
 * @param {Object} props
 * @private
 */
function navigateToDrillDown({drilldown, link}) {
    if (drilldown) {
        let {view = '', params = {}} = drilldown;
        historyPush(ROUTES.DRILLDOWN_PAGE.LINK({query: {...params, view}}));
        return;
    }
    if (link && link.url) {
        historyPush(link.url);
    }
}

/**
 * sort table
 *
 * @param {Array} data
 * @param {Number} sortF
 * @param {Boolean} sortD
 * @private
 */
function sortTable(data = [], sortF, sortD) {
    let sortable = cloneDeep(data);
    // NOTE sort by field (column index)
    sortable.sort((a, b) => {
        let result;
        // NOTE skip sorting for headers and immutable cell
        if (get(a[sortF], 'header', true) || get(b[sortF], 'header', true)) {
            return 0;
        }
        // NOTE sorting for numbers (valueDouble !== null)
        if (get(a[sortF], 'valueDouble')) {
            result = get(a[sortF], 'valueDouble') - get(b[sortF], 'valueDouble');
        } else {
            // NOTE sorting for string
            let x = is.string(get(a[sortF], 'value')) ? (get(a[sortF], 'value')).toLowerCase() : '';
            let y = is.string(get(b[sortF], 'value')) ? (get(b[sortF], 'value')).toLowerCase() : '';
            result = x < y ? -1 : x > y ? 1 : 0;
        }
        // NOTE change sort direction
        result = !sortD ? -result : result;
        return result;
    });
    return sortable;
}

/**
 * Associate Dasboard Item with on DASHBOARD_ITEMS_TYPE constant to provide ability determine item type programmatically
 *
 * @param { String } type
 * @public
 */
function getDasboardItemByType(type) {
    switch (type) {
        default:
            console.log(`%c Unknown getDasboardItemByType ${type} !!!`, 'color: #fff; background: #a41e22; font-size: 20px;');
            return null;
        case DASHBOARD_ITEMS_TYPE.TEXT:
            return DashboardText;
        case DASHBOARD_ITEMS_TYPE.LINK:
            return DashboardLink;
        case DASHBOARD_ITEMS_TYPE.TEXTAREA:
            return DashboardTextarea;
        case DASHBOARD_ITEMS_TYPE.BARCHART:
            return DashboardBarchart;
        case DASHBOARD_ITEMS_TYPE.HEAT_CHART:
            return DashboardHeatChart;
        case DASHBOARD_ITEMS_TYPE.INPUT:
            return DashboardInput;
        case DASHBOARD_ITEMS_TYPE.GRID_LAYOUT:
            return DashboardGridLayout;
        case DASHBOARD_ITEMS_TYPE.TABLE:
            return DashboardTable;
        case DASHBOARD_ITEMS_TYPE.DATA_GRID:
            return AdvancedDashboardTable;
        case DASHBOARD_ITEMS_TYPE.PIE_CHART:
            return DashboardPieChart;
        case DASHBOARD_ITEMS_TYPE.QLIK_ANALYTICS:
            return DashboardQlikAnalytics;
    }
}