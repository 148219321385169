// outsource dependencies
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Paper, Tab, Tabs } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// local dependencies
import { LIST } from './actions'
import { Link } from '../../components/md-button';
import { translate, withTranslation } from '../../services/translate.service';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumb';

// nested pages
import { BUSINESS_UNITS_MAP } from "../../components/breadcrumbs/breadcrumbsMap";
import { connect } from "react-redux";
import { findHint, RichHintTitle } from "../../components/hints/hints";
import { SCHEME } from "../subsidiaries/actions";
// config
// const TABS = {
//     LIST: 'list',
//     SCHEME: 'scheme'
// };
// export default function ( props ) {
//     let { location } = props;
//     return (
//         <Routes>
//             <Route path={BUSINESS_UNITS.EDIT} element={ Edit } />
//             <Route path={BUSINESS_UNITS.TABS} element={ RouteTabs } />
//             {/* OTHERWISE */}
//             <Redirect to={{ pathname: BUSINESS_UNITS.LINK({tab:TABS.LIST}), state: { from: location } }}/>
//         </Routes>
//     );
// }

const BusinessUnitsRouteTabs = withTranslation(connect(
    state => ({
        listHints: state.businessUnits.list.hintsData,
        schemeHints: state.businessUnits.scheme.hintsData,
        disabled: state.businessUnits.list.expectAnswer || state.businessUnits.scheme.expectAnswer,
    }),

    null
)(({disabled, match, listHints, schemeHints, history, hints}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const listLink = '/private/business-units/tabs/list';
    const schemeLink = '/private/business-units/tabs/scheme';
    let isScheme = schemeLink === location.pathname;
    if (location.pathname === '/private/business-units/') {
        navigate(listLink, {replace: true})
    }
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={BUSINESS_UNITS_MAP} />
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <RichHintTitle update={isScheme ? SCHEME : LIST}
                                   name={isScheme ? 'BUSINESS_UNITS$SCHEME' : 'BUSINESS_UNITS$TITLE'}
                                   expectAnswer={disabled}
                                   data={findHint(isScheme ? schemeHints : listHints, isScheme ? 'BUSINESS_UNITS_SCHEME' : 'BUSINESS_UNITS_TITLE')} />
                </Col>
            </Row>
            <Paper square>
                <Tabs
                    value={location.pathname}
                    textColor="primary"
                    indicatorColor="primary"
                >
                    <Link Btn={Tab}  label={translate('GLOBALS$SCHEME')} value={schemeLink}
                          to={schemeLink}
                    />
                    <Link Btn={Tab} label={translate('GLOBALS$LIST')} value={listLink}
                          to={listLink}
                    />
                </Tabs>
            </Paper>
            <Outlet />
        </Container>
    )
}));

export { BusinessUnitsRouteTabs }