// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {Outlet,} from "react-router-dom";
import {ButtonToolbar, Dropdown, DropdownButton, OverlayTrigger, Tooltip} from 'react-bootstrap';

// local dependencies
import {APP} from '../actions/types';
import CenteredBox from '../components/centered-box';
import {translate} from '../services/translate.service';
import {selectImageSrc} from "../images";

// configuration
export default connect(
    state => ({...state.app}),
    dispatch => ({changeLanguage: language => dispatch({type: APP.CHANGE_LANGUAGE, language})})
)(PublicLayout);

/**
 * wrapper for public pages
 * @constructor PublicLayout
 * @public
 */
function PublicLayout ({language, languages, direction, changeLanguage, location}) {
    return (<div id="publicLayout">
        <CenteredBox>
            {languages.length > 1 && (
                <ButtonToolbar className="pull-right" style={{margin: 10}}>
                    <OverlayTrigger trigger={["hover","focus"]} placement="bottom" overlay={(props)=><Tooltip id="tooltip-bottom" {...props}>{translate('GLOBALS$CHANGE_LANGUAGE')}</Tooltip>}>
                        <DropdownButton
                            variant='default'
                            className="border rounded"
                            id="language-dropdown"
                            title={<img alt={language} src={selectImageSrc(language)} height={20} />}
                                >
                            {languages.map((item, key) => (
                                <Dropdown.Item
                                    key={key}
                                    eventKey={key}
                                    onClick={()=>changeLanguage(item)}
                                        >
                                    <img alt={item.name} src={selectImageSrc(item.code)} height={25} />
                                    {` - ${item.name}`}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </OverlayTrigger>
                </ButtonToolbar>
            )}
            <Outlet />
        </CenteredBox>
    </div>);
}
