// outsource dependencies
import {reset} from 'redux-form';
import {toastr} from 'react-redux-toastr';
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {EDIT} from './actions';
import {historyPush} from '../../store';
import {SETTINGS} from '../../constants/routes';
import {instanceAPI} from '../../services/api.service';
import {translate} from '../../services/translate.service';
import getHintsList, {CHANGE_PASSWORD_HINTS} from '../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CHANGE_PASSWORD, changePasswordSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}


function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, CHANGE_PASSWORD_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}
function* changePasswordSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null });
    try {
        yield call(changePassword, options);
        yield call(toastr.success, translate('CHANGE_PASSWORD$OLD_PASSWORD'), translate('CHANGE_PASSWORD$SUCCESS_MESSAGE'));
        yield put(reset('changePassword'));
        yield put({type: EDIT.META, expectAnswer: false});
        yield call(historyPush, SETTINGS.LINK());
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

/**
 * change user password
 * @param {Object} data
 * @private
 */
function changePassword ( data ) {
    return instanceAPI({method: 'post', url: `/user-profile/change-password`, data});
}

