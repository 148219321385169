// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import moment from 'moment/moment';
import {config} from '../../../constants';
import {historyPush} from '../../../store';
import MdButton from '../../../components/md-button';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {CONTROL_GUIDELINES} from '../../../constants/routes';
import {findHint, RichHintTitle} from "../../../components/hints/hints";

// configure
const pageTitle = "CONTROL_GUIDELINES";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { history, expectAnswer, list, hints } = this.props;
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={6}>
                            <RichHintTitle update={LIST}  name={'Control dsd guidelines'} expectAnswer={expectAnswer} data={findHint(hints, `${pageTitle}_TITLE`)}/>
                        </Col>
                        <Col xs={6} className="text-right top-indent-4">
                            <MdButton placement="left" hint={findHint(hints, `BUTTON_${pageTitle}_ADD`)} className="md-btn md-btn-success" onClick={ () => history.push(CONTROL_GUIDELINES.LINK_EDIT())}>
                                <i className="fa fa-plus" aria-hidden="true"> </i>&nbsp; Add guideline
                            </MdButton>
                        </Col>
                    </Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> There is no control guidelines </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.controlGuidelines.list.expectAnswer, list: state.controlGuidelines.list.data, hints: state.controlGuidelines.list.hintsData}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.controlGuidelines.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedTable = connect(
    state => ({...state.controlGuidelines.list, hints: state.controlGuidelines.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changePage: page => dispatch({type: LIST.CHANGE_PAGE, page}),
        changeSize: size => dispatch({type: LIST.CHANGE_SIZE, size}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, deleteItem, changePage, changeSize, changeSort }) => (
    <Table className="md-table" padding="checkbox">
        <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
            <TableRow style={{height: 48}}>
                <TableCell className="th">
                    <TableSortLabel
                        active={sortF === 'name'}
                        direction={sortD ? 'asc' : 'desc'}
                        onClick={()=>changeSort('name')}
                            >
                        Name
                    </TableSortLabel>
                </TableCell>
                <TableCell className="th">
                    <TableSortLabel
                        active={sortF === 'description'}
                        direction={sortD ? 'asc' : 'desc'}
                        onClick={()=>changeSort('description')}
                            >
                        Description
                    </TableSortLabel>
                </TableCell>
                <TableCell className="th">
                    <TableSortLabel
                        active={sortF === 'createdBy'}
                        direction={sortD ? 'asc' : 'desc'}
                        onClick={()=>changeSort('createdBy')}
                            >
                        Created by
                    </TableSortLabel>
                </TableCell>
                <TableCell className="th">
                    <TableSortLabel
                        active={sortF === 'createdAt'}
                        direction={sortD ? 'asc' : 'desc'}
                        onClick={()=>changeSort('createdAt')}
                            >
                        Created
                    </TableSortLabel>
                </TableCell>
                <TableCell className="th">
                    <TableSortLabel
                        active={sortF === 'updatedBy'}
                        direction={sortD ? 'asc' : 'desc'}
                        onClick={()=>changeSort('updatedBy')}
                            >
                        Updated by
                    </TableSortLabel>
                </TableCell>
                <TableCell className="th">
                    <TableSortLabel
                        active={sortF === 'updatedAt'}
                        direction={sortD ? 'asc' : 'desc'}
                        onClick={()=>changeSort('updatedAt')}
                            >
                        Updated
                    </TableSortLabel>
                </TableCell>
                <TableCell className="th">&nbsp;</TableCell>
                <TableCell className="th">&nbsp;</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.description || 'No description'}</TableCell>
                <TableCell>{item.createdBy}</TableCell>
                <TableCell>{moment(item.createdAt || (Date.now())).format(config.clientTimeFormat)}</TableCell>
                <TableCell>{item.updatedBy}</TableCell>
                <TableCell>{moment(item.updatedAt || Date.now()).format(config.clientTimeFormat)}</TableCell>
                <TableCell>
                    <MdButton hint={findHint(hints,`BUTTON_${pageTitle}_EDIT`)} onClick={() => historyPush(CONTROL_GUIDELINES.LINK_EDIT({id: item.id}))}> Edit </MdButton>
                </TableCell>
                <TableCell>
                    <MdButton hint={findHint(hints, `BUTTON_${pageTitle}_DELETE`)} className="md-btn md-btn-danger" onClick={() => deleteItem(item.id)}> Delete </MdButton>
                </TableCell>
            </TableRow>))}
        </TableBody>
        <TableFooter>
            <TableRow style={{height: 48}}>
                <TablePagination
                    page={page}
                    count={totalPages}
                    rowsPerPage={size}
                    rowsPerPageOptions={[5, 10, 25]}
                    onPageChange={(e, page) => e&&changePage(page)}
                    // onChangeRowsPerPage={e => changeSize(e.target.value)}
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                        />
            </TableRow>
        </TableFooter>
    </Table>
));

const ConnectedError = connect(
    state => ({message: state.controlGuidelines.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
