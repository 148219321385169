// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {CardContent, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {ASSESSMENT_LEVEL, PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {DrilldownBtn, Link} from '../../../components/md-button';
import {SYSTEM_CONTROL_TEST_RESULTS} from '../../../constants/routes';
import {formatPercent} from '../../../services/data-formatting.service';
import {translate, withTranslation} from '../../../services/translate.service';
import {instanceAPI} from "../../../services/api.service";
import {SimpleAsyncSelect} from "../../../components/md-select";
import {SYSTEM_CONTROL_TEST_RESULTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, data, clearError, hints, message } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ SYSTEM_CONTROL_TEST_RESULTS_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4"> <Col xs={12}>
                        <RichHintTitle update={LIST}  name={`SYSTEM_CONTROL_TEST_RESULTS$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `SYSTEM_CONTROL_TEST_RESULTS_TITLE`)}/>
                    </Col> </Row>
                    <Row className="offset-bottom-4"> <Col xs={12}> <SearchForm /> </Col> </Row>
                    <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError} /> </Col> </Row>
                    { data.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>

        );
    }
}

export default connect(
    state => ({
        data: state.systemControlTestResults.list.data,
        hints: state.systemControlTestResults.list.hintsData,
        message: state.systemControlTestResults.list.errorMessage,
        expectAnswer: state.systemControlTestResults.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.systemControlTestResults.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
// const SearchFilterConnected = withTranslation(connect(
//     state => ({
//         filter:  state.systemControlTestResults.list.filter,
//         disabled: state.systemControlTestResults.list.expectAnswer,
//     }),
//     dispatch => ({
//         changeFilterValue: filter => dispatch({type: LIST.META, filter}),
//         applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
//     })
// )(({disabled, filter, changeFilterValue, applyFilter }) => (
//     <SearchFilter
//         value={filter}
//         disabled={disabled}
//         apply={applyFilter}
//         clear={() => applyFilter('')}
//         onInputChange={changeFilterValue}
//         placeholder={translate('SYSTEMS$SEARCH_BY_SYSTEM')}
//             />
// )));

/**
 * search form
 *
 * @public
 */
const SearchForm = withTranslation(connect(
    state => ({ ...state.systemControlTestResults.list }),
    dispatch => ({
        changeSearchValue: filter => dispatch({type: LIST.META, filter}),
        changeAssessmentData: data => dispatch({type: LIST.META, ...data}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ({ expectAnswer, filter, assessmentType, changeSearchValue, changeAssessmentData, disabled, applyFilter }) =>(<CardContent>
    <Row>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SearchFilter
                value={filter}
                disabled={disabled}
                apply={applyFilter}
                clear={() => applyFilter('')}
                onInputChange={changeSearchValue}
                placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
            />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={assessmentType}
                disabled={expectAnswer}
                placeholder={translate('ASSESSMENTS$ASSESSMENT')}
                onChange={assessmentType => {
                    changeAssessmentData({assessmentType});
                    applyFilter(filter);
                }}
                // label={(<strong> {translate('ASSESSMENTS$ASSESSMENT')} </strong>)}
                loadOptions={(name, done) => {
                    instanceAPI({ method: 'post', url: `/assessments/filter`, data: {filter: {name, assessmentLevelId: ASSESSMENT_LEVEL.SYSTEM}} })
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
</CardContent>)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.systemControlTestResults.list,  hints: state.systemControlTestResults.list.hintsData,}),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort, assessmentType }) =>(<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell align="center" className="th" style={{width: "30%"}}>
                        <TableSortLabel
                            active={sortF === 'systemName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('systemName')}
                                >
                            {translate('SYSTEMS$SYSTEM')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className="th" style={{width: "50%"}}>
                        {translate('SYSTEMS$SYSTEM_DESCRIPTION')}
                    </TableCell>
                    <TableCell align="center" className="th" style={{width: 200}}>
                        <TableSortLabel
                            active={sortF === 'assessmentWeight'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('assessmentWeight')}
                                >
                            {translate('ASSESSMENTS$ASSESSMENT_WEIGHT')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{get(item, 'system.name')}</TableCell>
                    <TableCell>{get(item, 'system.description')}</TableCell>
                    <TableCell align="center" style={{width: 200}}>{formatPercent(item.assessmentWeight, true)}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={DrilldownBtn}
                            hint={findHint(hints, `BUTTON_SYSTEM_CONTROL_TEST_RESULTS_DRILLDOWN`)}
                            tooltip={translate('GLOBALS$DRILLDOWN')}
                            permission={PERMISSION.SYSTEM_CONTROL_TEST_RESULT.DRILLDOWN}
                            to={SYSTEM_CONTROL_TEST_RESULTS.LINK_DRILLDOWN({
                                assessmentId: assessmentType ? assessmentType.id : 'all',
                                systemId: get(item, 'system.id', null),
                                query: {back: history.location.pathname + history.location.search}
                            })}>
                        </Link>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));
