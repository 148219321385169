// outsource dependencies
import { find, isEmpty } from "lodash";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import React, { Fragment, useState } from 'react';

// local dependencies
import { translate } from "../../services/translate.service";
import { ProtectedBtn } from "../md-button"
import Preloader from "../preloader"
import store from '../../store';
import { connect } from "react-redux";
import dompurify from "dompurify";

export function findHint(data, code) {
    return find(data, {'code': code});
}

const Btn = ({data = {}, options, color}) => {
    const [show, setShow] = useState(false);

    // React.useEffect(() => {
    //     store.dispatch({ type: action.GET_HINTS_DATA.REQUEST });
    //     console.log('language', language)
    //     // getHintsData();
    // }, [language]);

    const {title, body, link} = data;
    const {placement = "top"} = options;

    const popover = (
        <Popover onMouseLeave={() => {
            setShow(false)
        }} id="rich-hint" title={title}>
            {/*<p>{body}</p>*/}
            <div dangerouslySetInnerHTML={{__html: dompurify.sanitize(body)}} />
            <a target="_blank" href={link} rel="noopener noreferrer">For more information here</a>
        </Popover>
    );


    return (
        <Fragment>
            <OverlayTrigger
                show={show}
                trigger={['hover', 'focus']}
                placement={placement}
                overlay={popover}
            >
                <span onMouseLeave={() => {
                    setShow(!show)
                }}>
                    <ProtectedBtn variant="contained" color={color} {...options} />
                </span>

            </OverlayTrigger>
        </Fragment>
    );
};

const Title = ({
                   data = {},
                   name,
                   expectAnswer,
                   language,
                   update,
                   placement = "right",
                   classes = "text-uppercase h2"
               }) => {
    const [show, setShow] = useState(false);

    React.useEffect(() => {
        update && store.dispatch({type: update.GET_HINTS_DATA.REQUEST});
    }, [language]);


    // {find(hints, { 'code': 'DATA_CLASSES_TITLE'}) ? (<PopoverCustom data={hints}/>) : (<span className="text-uppercase h2">{translate('DATA_CLASSES$TITLE')}</span>)}

    const {title, body, link} = data;
    const isEmptyContent = title === '' && body === '' && link === '';

    const popover = (
        <Popover onMouseLeave={() => {
            setShow(false)
        }}
                 onMouseEnter={() => {
                     setShow(true);
                 }}

                 id="rich-hint" title={title}>
            <Popover.Header style={{fontSize: 15}} className='popover-title' as="h3">{translate(name)}</Popover.Header>
            {/* <div className='popover-title'>{translate(name)}</div> */}
            <Popover.Body style={{fontSize: 13}} className='popover-content'>
                <div dangerouslySetInnerHTML={{__html: dompurify.sanitize(body)}} />
            </Popover.Body>
            {link && (
                <a target="_blank" style={{padding: 10, fontSize: 13}} href={link} rel="noopener noreferrer">For more
                    information here</a>)}
        </Popover>
    );


    return !isEmptyContent && !isEmpty(data) ? (
        <Fragment>
            {/*<span className="text-uppercase h2">{translate('DATA_CLASSES$TITLE')}</span>*/}
            <OverlayTrigger
                show={show}
                placement={placement}
                overlay={popover}
                rootClose
            >
                <span
                    onMouseEnter={() => {
                        setShow(true);
                    }}
                    onMouseLeave={() => {
                        setShow(false)
                    }}
                    className={classes}>{translate(name)}<Preloader expectAnswer={expectAnswer}
                                                                    type="ICON"> </Preloader></span>
            </OverlayTrigger>
        </Fragment>
    ) : (<span className="text-uppercase h2">{translate(name)}<Preloader expectAnswer={expectAnswer}
                                                                         type="ICON"> </Preloader></span>);
};

export const RichHintTitle = connect(
    state => ({language: state.app.language}),
    null
)(Title);

export const RichHintBtn = connect(
    state => ({language: state.app.language}),
    null
)(Btn);
