
/* RISK MODEL */

// import {CONTROL_CATEGORIES} from "../../constants/routes";

export const QUALITATIVE_QUESTIONS_MAP = {
    '/private/qualitative-questions': {id: 1,  title: 'RISK_MODEL$TITLE', parent: null},
    '/private/qualitative-questions/list': {id: 11, title: 'SCORING_QUESTIONS$TITLE', parent: 1},
    '/private/qualitative-questions/edit': {id: 12, title: 'SCORING_QUESTIONS$EDIT_QUESTION', parent: 11},
    '/private/qualitative-questions/edit/new': {id: 13, title: 'SCORING_QUESTIONS$CREATE_QUESTION', parent: 11},
};

export const DOMAINS_MAP = {
    '/private/domains': {id: 1,  title: 'RISK_MODEL$TITLE', parent: null},
    '/private/domains/list': {id: 11, title: 'DOMAINS$TITLE', parent: 1},
    '/private/domains/edit': {id: 12, title: 'DOMAINS$EDIT_DOMAIN', parent: 11},
    '/private/domains/edit/new': {id: 13, title: 'DOMAINS$CREATE_DOMAIN', parent: 11},
};

export const CATEGORIES_MAP = {
    '/private/categories': {id: 1,  title: 'RISK_MODEL$TITLE', parent: null},
    '/private/categories/list': {id: 11, title: 'CATEGORIES$TITLE', parent: 1},
    '/private/categories/edit': {id: 12, title: 'CATEGORIES$EDIT_CATEGORY', parent: 11},
    '/private/categories/edit/new': {id: 13, title: 'CATEGORIES$CREATE_CATEGORY', parent: 11},
};

export const CONSTANTS_MAP = {
    '/private/constants': {id: 1,  title: 'RISK_MODEL$TITLE', parent: null},
    '/private/constants/list': {id: 11, title: 'CONSTANTS$TITLE', parent: 1},
    '/private/constants/edit': {id: 12, title: 'CONSTANTS$EDIT_CONSTANT', parent: 11},
    '/private/constants/edit/new': {id: 13, title: 'CONSTANTS$CREATE_CONSTANT', parent: 11},
};

export const QUANT_MODELS_MAP = {
    '/private/quantification-models': {id: 1,  title: 'RISK_MODEL$TITLE', parent: null},
    '/private/quantification-models/list': {id: 11, title: 'EXPOSURE_MODELS$TITLE', parent: 1},
    '/private/quantification-models/edit': {id: 12, title: 'EXPOSURE_MODELS$EDIT_MODEL', parent: 11},
    '/private/quantification-models/edit/new': {id: 13, title: 'EXPOSURE_MODELS$CREATE_MODEL', parent: 11},
};

export const QUANT_METRICS_MAP = {
    '/private/quantification-metrics': {id: 1,  title: 'RISK_MODEL$TITLE', parent: null},
    '/private/quantification-metrics/list': {id: 11, title: 'EXPOSURE_METRICS$TITLE', parent: 1},
    '/private/quantification-metrics/edit': {id: 12, title: 'EXPOSURE_METRICS$EDIT_METRIC', parent: 11},
    '/private/quantification-metrics/edit/new': {id: 13, title: 'EXPOSURE_METRICS$CREATE_METRIC', parent: 11},
};

export const QUAL_METRICS_MAP = {
    '/private/qualitative-metrics': {id: 1,  title: 'RISK_MODEL$TITLE', parent: null},
    '/private/qualitative-metrics/list': {id: 11, title: 'SCORING_METRICS$TITLE', parent: 1},
    '/private/qualitative-metrics/edit': {id: 12, title: 'SCORING_METRICS$EDIT_METRIC', parent: 11},
    '/private/qualitative-metrics/edit/new': {id: 13, title: 'SCORING_METRICS$CREATE_METRIC', parent: 11},
};

export const RISK_METRICS_MAP = {
    '/private/risk-metrics': {id: 1,  title: 'RISK_MODEL$TITLE', parent: null},
    '/private/risk-metrics/list': {id: 11, title: 'RISK_METRICS$TITLE', parent: 1},
    '/private/risk-metrics/edit': {id: 12, title: 'RISK_METRICS$EDIT_METRIC', parent: 11},
    '/private/risk-metrics/edit/new': {id: 13, title: 'RISK_METRICS$CREATE_METRIC', parent: 11},
};

export const ASSOCIATE_MODELS_MAP = {
    '/private/associate-models': {id: 1,  title: 'RISK_MODEL$TITLE', parent: null},
    '/private/associate-models/list': {id: 11, title: 'ASSOCIATE_MODELS$TITLE', parent: 1},
    '/private/associate-models/edit': {id: 12, title: 'ASSOCIATE_MODELS$EDIT_MODEL', parent: 11},
    '/private/associate-models/edit/new': {id: 13, title: 'ASSOCIATE_MODELS$CREATE_MODEL', parent: 11},
};

/* ORGANIZATION */

export const SUBSIDIARIES_MAP = {
    '/private/organization': {id: 1,  title: 'ORGANIZATION$TITLE', parent: null},
    '/private/subsidiaries/tabs/list': {id: 11, title: 'SUBSIDIARY_ORGANIZATIONS$TITLE', parent: 1},
    '/private/subsidiaries/tabs/scheme': {id: 12, title: 'SUBSIDIARY_ORGANIZATIONS$SCHEME', parent: 1},
    '/private/subsidiaries/edit': {id: 13, title: 'SUBSIDIARY_ORGANIZATIONS$EDIT_SUBSIDIARY', parent: 11},
    '/private/subsidiaries/edit/new': {id: 14, title: 'SUBSIDIARY_ORGANIZATIONS$CREATE_SUBSIDIARY', parent: 11},
};

export const BUSINESS_UNITS_MAP = {
    '/private/organization': {id: 1,  title: 'ORGANIZATION$TITLE', parent: null},
    '/private/business-units/tabs/list': {id: 11, title: 'BUSINESS_UNITS$TITLE', parent: 1},
    '/private/business-units/tabs/scheme': {id: 12, title: 'BUSINESS_UNITS$SCHEME', parent: 1},
    '/private/business-units/edit': {id: 13, title: 'BUSINESS_UNITS$EDIT_UNIT', parent: 11},
    '/private/business-units/edit/new': {id: 14, title: 'BUSINESS_UNITS$CREATE_UNIT', parent: 11},
};

/* DIGITAL ASSETS INVENTORY */

export const ASSET_CLASSES_MAP = {
    '/private/asset-classes': {id: 1,  title: 'MENU$DIGITAL_ASSET_INVENTORY', parent: null},
    '/private/asset-classes/list': {id: 11, title: 'ASSET_CLASSES$TITLE', parent: 1},
    '/private/asset-classes/edit': {id: 12, title: 'ASSET_CLASSES$EDIT_CLASS', parent: 11},
    '/private/asset-classes/edit/new': {id: 13, title: 'ASSET_CLASSES$CREATE_CLASS', parent: 11},
};

export const DATA_CLASSES_MAP = {
    '/private/data-classes': {id: 1,  title: 'MENU$DIGITAL_ASSET_INVENTORY', parent: null},
    '/private/data-classes/list': {id: 11, title: 'DATA_CLASSES$TITLE', parent: 1},
    '/private/data-classes/edit': {id: 12, title: 'DATA_CLASSES$EDIT_CLASS', parent: 11},
    '/private/data-classes/edit/new': {id: 13, title: 'DATA_CLASSES$CREATE_CLASS', parent: 11},
};

export const DATA_DOMAINS_MAP = {
    '/private/data-domains': {id: 1,  title: 'MENU$DIGITAL_ASSET_INVENTORY', parent: null},
    '/private/data-domains/list': {id: 11, title: 'DATA_DOMAINS$TITLE', parent: 1},
    '/private/data-domains/edit': {id: 12, title: 'DATA_DOMAINS$EDIT', parent: 11},
    '/private/data-domains/edit/new': {id: 13, title: 'DATA_DOMAINS$CREATE', parent: 11},
};

export const SYSTEMS_MAP = {
    '/private/systems': {id: 1,  title: 'MENU$DIGITAL_ASSET_INVENTORY', parent: null},
    '/private/systems/list': {id: 11, title: 'SYSTEMS$TITLE', parent: 1},
    '/private/systems/edit': {id: 12, title: 'SYSTEMS$EDIT_SYSTEM', parent: 11},
    '/private/systems/edit/new': {id: 13, title: 'SYSTEMS$CREATE_SYSTEM', parent: 11},
};

export const TECHNOLOGY_ASSETS_MAP = {
    '/private/technology-assets': {id: 1,  title: 'MENU$DIGITAL_ASSET_INVENTORY', parent: null},
    '/private/technology-assets/list': {id: 11, title: 'TECHNOLOGY_ASSETS$TITLE', parent: 1},
    '/private/technology-assets/edit': {id: 12, title: 'TECHNOLOGY_ASSETS$EDIT_TECHNOLOGY_ASSET', parent: 11},
    '/private/technology-assets/edit/new': {id: 13, title: 'TECHNOLOGY_ASSETS$CREATE_TECHNOLOGY_ASSET', parent: 11},
};

export const PROCESSES_MAP = {
    '/private/processes': {id: 1,  title: 'MENU$DIGITAL_ASSET_INVENTORY', parent: null},
    '/private/processes/list': {id: 11, title: 'PROCESSES$TITLE', parent: 1},
    '/private/processes/edit': {id: 12, title: 'PROCESSES$EDIT_PROCESS', parent: 11},
    '/private/processes/edit/new': {id: 13, title: 'PROCESSES$CREATE_PROCESS', parent: 11},
};

export const TECH_CATEGORIES_MAP = {
    '/private/tech-categories': {id: 1,  title: 'MENU$DIGITAL_ASSET_INVENTORY', parent: null},
    '/private/tech-categories/list': {id: 11, title: 'TECHNOLOGY_CATEGORIES$TITLE', parent: 1},
    '/private/tech-categories/edit': {id: 12, title: 'TECHNOLOGY_CATEGORIES$EDIT_TECHNOLOGY_CATEGORY', parent: 11},
    '/private/tech-categories/edit/new': {id: 13, title: 'TECHNOLOGY_CATEGORIES$CREATE_TECHNOLOGY_CATEGORY', parent: 11},
};

export const TECHNOLOGIES_MAP = {
    '/private/technologies': {id: 1,  title: 'MENU$DIGITAL_ASSET_INVENTORY', parent: null},
    '/private/technologies/list': {id: 11, title: 'TECHNOLOGIES$TITLE', parent: 1},
    '/private/technologies/edit': {id: 12, title: 'TECHNOLOGIES$EDIT_TECHNOLOGY', parent: 11},
    '/private/technologies/edit/new': {id: 13, title: 'TECHNOLOGIES$CREATE_TECHNOLOGY', parent: 11},
};

export const QUICK_START_MAP = {
    '/private/quick-start': {id: 1,  title: 'LEARNING_CENTER$QUICK_START', parent: null},
};

export const KNOWLEDGE_BASE_MAP = {
    '/private/knowledge-base': {id: 1,  title: 'LEARNING_CENTER$KNOWLEDGE_BASE', parent: null},
};

export const ABOUT_MAP = {
    '/private/about': {id: 1,  title: 'LEARNING_CENTER$ABOUT', parent: null},
};

export const SECURITY_TOOLS_MAP = {
    '/private/security-tools': {id: 1,  title: 'MENU$DIGITAL_ASSET_INVENTORY', parent: null},
    '/private/security-tools/list': {id: 11, title: 'SECURITY_TOOLS$TITLE', parent: 1},
    '/private/security-tools/edit': {id: 12, title: 'SECURITY_TOOLS$EDIT_TOOL', parent: 11},
    '/private/security-tools/edit/new': {id: 13, title: 'SECURITY_TOOLS$CREATE_TOOL', parent: 11},
};

/* DIGITAL ASSET DATA */

export const DATA_EXFILTRATION_MAP = {
    '/private/data-exfiltration': {id: 1,  title: 'MENU$DATA_CAPTURE', parent: null},
    '/private/data-exfiltration/list': {id: 11, title: 'DATA_EXFILTRATION$TITLE', parent: 1},
    '/private/data-exfiltration/edit': {id: 12, title: 'DATA_EXFILTRATION$EDIT_EXFILTRATION', parent: 11},
    '/private/data-exfiltration/edit/new': {id: 13, title: 'DATA_EXFILTRATION$CREATE_EXFILTRATION', parent: 11},
};

export const BUSINESS_INTERRUPTION_MAP = {
    '/private/business-interruption': {id: 1,  title: 'MENU$DATA_CAPTURE', parent: null},
    '/private/business-interruption/list': {id: 11, title: 'BUSINESS_INTERRUPTION$TITLE', parent: 1},
    '/private/business-interruption/edit': {id: 12, title: 'BUSINESS_INTERRUPTION$EDIT_INTERRUPTION', parent: 11},
    '/private/business-interruption/edit/new': {id: 13, title: 'BUSINESS_INTERRUPTION$CREATE_INTERRUPTION', parent: 11},
};

export const REGULATORY_EXPOSURE_MAP = {
    '/private/regulatory-exposures': {id: 1,  title: 'MENU$DATA_CAPTURE', parent: null},
    '/private/regulatory-exposures/list': {id: 11, title: 'REGULATORY_EXPOSURES$TITLE', parent: 1},
    '/private/regulatory-exposures/edit': {id: 12, title: 'REGULATORY_EXPOSURES$EDIT_EXPOSURE', parent: 11},
    '/private/regulatory-exposures/edit/new': {id: 13, title: 'REGULATORY_EXPOSURES$CREATE_EXPOSURE', parent: 11},
};

export const CYBER_RISK_SCORING_MAP = {
    '/private/cyber-risk-scoring': {id: 1,  title: 'MENU$DATA_CAPTURE', parent: null},
    '/private/cyber-risk-scoring/list': {id: 11, title: 'CYBER_RISK_SCORING$TITLE', parent: 1},
    '/private/cyber-risk-scoring/questions': {id: 12, title: 'SCORING_QUESTIONS$TITLE', parent: 11},
    '/private/cyber-risk-scoring/questions/new': {id: 13, title: 'SCORING_QUESTIONS$CREATE_', parent: 11},
};

/* DOCUMENTATION MANAGEMENT */

export const POLICIES_MAP = {
    '/private/policies': {id: 1,  title: 'MENU$DOCUMENTATION_MANAGEMENT', parent: null},
    '/private/policies/list': {id: 11, title: 'POLICY_MANAGEMENT$TITLE', parent: 1},
    '/private/policies/edit': {id: 12, title: 'POLICY_MANAGEMENT$EDIT_POLICY', parent: 11},
    '/private/policies/edit/new': {id: 13, title: 'POLICY_MANAGEMENT$CREATE_POLICY', parent: 11},
};

export const SCORING_QUESTIONS_MAP = {
    '/private/organization-risk-scoring': {id: 1,  title: 'MENU$DOCUMENTATION_MANAGEMENT', parent: null},
    '/private/organization-risk-scoring/list': {id: 11, title: 'SCORING_QUESTIONS$TITLE', parent: 1},
    '/private/organization-risk-scoring/edit': {id: 12, title: 'SCORING_QUESTIONS$EDIT_QUESTION', parent: 11},
    '/private/organization-risk-scoring/edit/new': {id: 13, title: 'SCORING_QUESTIONS$CREATE_QUESTION', parent: 11},
};

/* Administration */

export const CYBER_ROLES_MAP = {
    '/private/cyber-roles': {id: 1,  title: 'MENU$ADMINISTRATION', parent: null},
    '/private/cyber-roles/list': {id: 11, title: 'CYBER_ROLES$TITLE', parent: 1},
    '/private/cyber-roles/edit': {id: 12, title: 'CYBER_ROLES$EDIT_ROLE', parent: 11},
    '/private/cyber-roles/edit/new': {id: 13, title: 'CYBER_ROLES$CREATE_ROLE', parent: 11},
};

export const CONTROL_FUNCTIONS_MAP = {
    '/private/control-functions': {id: 1,  title: 'MENU$ADMINISTRATION', parent: null},
    '/private/control-functions/list': {id: 11, title: 'CONTROL_FUNCTIONS$TITLE', parent: 1},
    '/private/control-functions/edit': {id: 12, title: 'CONTROL_FUNCTIONS$EDIT_FUNCTION', parent: 11},
    '/private/control-functions/edit/new': {id: 13, title: 'CONTROL_FUNCTIONS$CREATE_FUNCTION', parent: 11},
};

export const CONTROL_CATEGORIES_MAP = {
    '/private/control-categories': {id: 1,  title: 'MENU$ADMINISTRATION', parent: null},
    '/private/control-categories/list': {id: 11, title: 'CONTROL_TESTS$TITLE', parent: 1},
    '/private/control-categories/edit': {id: 12, title: 'CONTROL_TESTS$EDIT_TEST', parent: 11},
    '/private/control-categories/edit/new': {id: 13, title: 'CONTROL_TESTS$CREATE_TEST', parent: 11},
};

export const CONTROL_SUBCATEGORIES_MAP = {
    '/private/control-subcategories': {id: 1,  title: 'MENU$ADMINISTRATION', parent: null},
    '/private/control-subcategories/list': {id: 11, title: 'CONTROL_GUIDELINES$TITLE', parent: 1},
    '/private/control-subcategories/edit': {id: 12, title: 'CONTROL_GUIDELINES$EDIT_GUIDELINE', parent: 11},
    '/private/control-subcategories/edit/new': {id: 13, title: 'CONTROL_GUIDELINES$CREATE_GUIDELINE', parent: 11},
};

export const CONTROL_MATURITIES_MAP = {
    '/private/control-maturities': {id: 1,  title: 'MENU$ADMINISTRATION', parent: null},
    '/private/control-maturities/list': {id: 11, title: 'CONTROL_MATURITIES$TITLE', parent: 1},
    '/private/control-maturities/edit': {id: 12, title: 'CONTROL_MATURITIES$EDIT_MATURITY', parent: 11},
    '/private/control-maturities/edit/new': {id: 13, title: 'CONTROL_MATURITIES$CREATE_MATURITY', parent: 11},
};

export const USERS_MAP = {
    '/private/users': {id: 1,  title: 'MENU$ADMINISTRATION', parent: null},
    '/private/users/list': {id: 11, title: 'USERS$TITLE', parent: 1},
    '/private/users/edit': {id: 12, title: 'USERS$EDIT_USER', parent: 11},
    '/private/users/edit/new': {id: 13, title: 'USERS$CREATE_USER', parent: 11},
};

export const PROFILE_MAP = {
    '/private/profile': {id: 1,  title: 'PROFILE$TITLE', parent: null},
    '/private/profile/list': {id: 11, title: 'PROFILE$TITLE', parent: 1},
    '/private/profile/edit': {id: 12, title: 'PROFILE$EDIT_PROFILE', parent: 11},
};

/* SECURITY ASSESSMENTS */

export const ASSESSMENT_TYPES_MAP = {
    '/private/assessment-types': {id: 1,  title: 'MENU$SECURITY_ASSESSMENTS', parent: null},
    '/private/assessment-types/list': {id: 11, title: 'SECURITY_ASSESSMENTS$TITLE', parent: 1},
    '/private/assessment-types/edit': {id: 12, title: 'SECURITY_ASSESSMENTS$EDIT_ASSESSMENT', parent: 11},
    '/private/assessment-types/edit/new': {id: 13, title: 'SECURITY_ASSESSMENTS$CREATE_ASSESSMENT', parent: 11},
};

export const SECURITY_REQUIREMENTS_MAP = {
    '/private/security-requirements': {id: 1,  title: 'MENU$SECURITY_ASSESSMENTS', parent: null},
    '/private/security-requirements/list': {id: 11, title: 'SECURITY_REQUIREMENTS$TITLE', parent: 1},
    '/private/security-requirements/edit': {id: 12, title: 'SECURITY_REQUIREMENTS$EDIT_REQUIREMENT', parent: 11},
    '/private/security-requirements/edit/new/tab/settings': {id: 13, title: 'SECURITY_REQUIREMENTS$CREATE_REQUIREMENT', parent: 11},
};

export const ASSESSMENTS_MAP = {
    '/private/assessments': {id: 1,  title: 'MENU$SECURITY_ASSESSMENTS', parent: null},
    '/private/assessments/list': {id: 11, title: 'ASSESSMENTS$TITLE', parent: 1},
    '/private/assessments/edit': {id: 12, title: 'ASSESSMENTS$EDIT_ASSESSMENT', parent: 11},
    '/private/assessments/edit/new': {id: 13, title: 'ASSESSMENTS$CREATE_ASSESSMENT', parent: 11},
};

export const CONTROL_TEST_RESULTS_MAP = {
    '/private/assessments': {id: 1,  title: 'MENU$SECURITY_ASSESSMENTS', parent: null},
    '/private/organization-control-test-results/list': {id: 11, title: 'ORGANIZATION_CONTROL_TEST_RESULTS$TITLE', parent: 1},
    '/private/organization-control-test-results/edit': {id: 12, title: 'ORGANIZATION_CONTROL_TEST_RESULTS$EDIT_RESULT', parent: 11},
    '/private/organization-control-test-results/edit/new': {id: 13, title: 'ORGANIZATION_CONTROL_TEST_RESULTS$CREATE_RESULT', parent: 11},
};

export const SYSTEM_CONTROL_TEST_RESULTS_MAP = {
    '/private/assessments': {id: 1,  title: 'MENU$SECURITY_ASSESSMENTS', parent: null},
    '/private/system-control-test-results/list': {id: 11, title: 'SYSTEM_CONTROL_TEST_RESULTS$TITLE', parent: 1},
    '/private/system-control-test-results/drilldown': {id: 12, title: 'SYSTEM_CONTROL_TEST_RESULTS$DRILLDOWN_TITLE', parent: 11},
    '/private/system-control-test-results/edit': {id: 13, title: 'SYSTEM_CONTROL_TEST_RESULTS$EDIT_RESULT', parent: 12},
    '/private/system-control-test-results/audit': {id: 14, title: 'SYSTEM_CONTROL_TEST_RESULTS$AUDIT_RESULT', parent: 12},
};

export const ASSESSMENT_FINDINGS_MAP = {
    '/private/assessments': {id: 1,  title: 'MENU$SECURITY_ASSESSMENTS', parent: null},
    '/private/assessment-findings/list': {id: 11, title: 'ASSESSMENT_FINDINGS$TITLE', parent: 1},
    '/private/assessment-findings/edit': {id: 12, title: 'ASSESSMENT_FINDINGS$EDIT_FINDING', parent: 11},
    '/private/assessment-findings/edit/new': {id: 13, title: 'ASSESSMENT_FINDINGS$CREATE_FINDING', parent: 11},
};

export const CYBERSECURITY_MAP = {
    '/private/organization': {id: 1,  title: 'ORGANIZATION$TITLE', parent: null},
    '/private/cybersecurity-maturity': {id: 11,  title: 'CYBERSECURITY_MATURITY$TITLE', parent: 1},
};

export const VULNERABILITIES_MAP = {
    '/private/vulnerabilities': {id: 1,  title: 'MENU$SECURITY_ASSESSMENTS', parent: null},
    '/private/vulnerabilities/list': {id: 11, title: 'VULNERABILITIES$TITLE', parent: 1},
    '/private/vulnerabilities/edit': {id: 12, title: 'VULNERABILITIES$EDIT_VULNERABILITY', parent: 11},
    '/private/vulnerabilities/edit/new': {id: 13, title: 'VULNERABILITIES$CREATE_VULNERABILITY', parent: 11},
};

export const TASKS_MAP = {
    '/private/tasks': {id: 1,  title: 'MENU$SECURITY_ASSESSMENTS', parent: null},
    '/private/tasks/list': {id: 11, title: 'TASKS$TITLE', parent: 1},
    '/private/tasks/edit': {id: 12, title: 'TASKS$EDIT_TASK', parent: 11},
    '/private/tasks/edit/new': {id: 13, title: 'TASKS$CREATE_TASK', parent: 11},
};

export const TASK_CATEGORIES_MAP = {
    '/private/task-categories': {id: 1,  title: 'MENU$SECURITY_ASSESSMENTS', parent: null},
    '/private/task-categories/list': {id: 11, title: 'TASK_CATEGORIES$TITLE', parent: 1},
    '/private/task-categories/edit': {id: 12, title: 'TASK_CATEGORIES$EDIT_TASK_CATEGORY', parent: 11},
    '/private/task-categories/edit/new': {id: 13, title: 'TASK_CATEGORIES$CREATE_TASK_CATEGORY', parent: 11},
};

export const MY_TASKS_MAP = {
    '/private/my-tasks': {id: 1,  title: 'MENU$SECURITY_ASSESSMENTS', parent: null},
    '/private/my-tasks/list': {id: 11, title: 'MY_TASKS$TITLE', parent: 1},
};

/* VENDOR MANAGEMENT */

export const VENDORS_MAP = {
    '/private/vendors': {id: 1,  title: 'MENU$VENDOR_MANAGEMENT', parent: null},
    '/private/vendors/list': {id: 11, title: 'VENDORS$TITLE', parent: 1},
    '/private/vendors/edit': {id: 12, title: 'VENDORS$EDIT_VENDOR', parent: 11},
    '/private/vendors/edit/new': {id: 13, title: 'VENDORS$CREATE_VENDOR', parent: 11},
    '/private/vendors/questions/scoring': {id: 14, title: 'VENDORS$SCORING_QUESTIONS', parent: 11},
    '/private/vendors/questions/internal': {id: 15, title: 'VENDORS$INTERNAL_QUESTIONS', parent: 11},
};

export const CLOUD_SCORING_MAP = {
    '/private/cloud-scoring': {id: 1,  title: 'MENU$VENDOR_MANAGEMENT', parent: null},
    '/private/cloud-scoring/list': {id: 11, title: 'CLOUD_SCORING$TITLE', parent: 1},
    '/private/cloud-scoring/questions/scoring': {id: 12, title: 'VENDORS$SCORING_QUESTIONS', parent: 11},
    '/private/cloud-scoring/questions/internal': {id: 13, title: 'VENDORS$INTERNAL_QUESTIONS', parent: 11},
};

export const ASSOCIATE_SYSTEMS_MAP = {
    '/private/associate-systems': {id: 1,  title: 'MENU$VENDOR_MANAGEMENT', parent: null},
    '/private/associate-systems/list': {id: 11, title: 'ASSOCIATE_SYSTEMS$TITLE', parent: 1},
    '/private/associate-systems/edit': {id: 12, title: 'ASSOCIATE_SYSTEMS$EDIT_SYSTEM', parent: 11},
    '/private/associate-systems/edit/new': {id: 13, title: 'ASSOCIATE_SYSTEMS$CREATE_SYSTEM', parent: 11},
};

/* GDPR */

export const GDPR_ORGANIZATION_COMPLIANCE_MAP = {
    '/private/gdpr-compliance': {id: 1,  title: 'GDPR$GDPR', parent: null},
    '/private/gdpr-compliance/list': {id: 11, title: 'GDPR$ORGANIZATION_COMPLIANCE', parent: 1},
    '/private/gdpr-compliance/edit': {id: 12, title: 'GDPR$EDIT_COMPLIANCE', parent: 11},
    '/private/gdpr-compliance/questions': {id: 13, title: 'SCORING_QUESTIONS$TITLE', parent: 11},
    '/private/gdpr-compliance/status-log': {id: 14, title: 'GDPR$COMPLIANCE_LOG', parent: 11},
};

export const GDPR_SYSTEM_COMPLIANCE_MAP = {
    '/private/gdpr-system-compliance': {id: 1,  title: 'GDPR$GDPR', parent: null},
    '/private/gdpr-system-compliance/list': {id: 11, title: 'GDPR$SYSTEM_COMPLIANCE', parent: 1},
    '/private/gdpr-system-compliance/drilldown': {id: 12, title: 'GDPR$SYSTEM_COMPLIANCE_DRILLDOWN_TITLE', parent: 11},
    '/private/gdpr-system-compliance/questions': {id: 13, title: 'SCORING_QUESTIONS$TITLE', parent: 11},
    '/private/gdpr-system-compliance/system-log': {id: 14, title: 'GDPR$COMPLIANCE_LOG', parent: 11},
    '/private/gdpr-system-compliance/edit': {id: 12, title: 'ASSOCIATE_SYSTEMS$EDIT_SYSTEM', parent: 11},
    '/private/gdpr-system-compliance/edit/new': {id: 13, title: 'ASSOCIATE_SYSTEMS$CREATE_SYSTEM', parent: 11},
};

export const GDPR_EVIDENCE_DOCUMENTS_MAP = {
    '/private/gdpr-evidence-documents': {id: 1,  title: 'GDPR$GDPR', parent: null},
    '/private/gdpr-evidence-documents/list': {id: 11, title: 'GDPR$EVIDENCE_DOCUMENTS', parent: 1},
    '/private/gdpr-evidence-documents/edit': {id: 12, title: 'GDPR$EDIT_DOCUMENT', parent: 11},
    '/private/gdpr-evidence-documents/edit/new': {id: 13, title: 'GDPR$CREATE_DOCUMENT', parent: 11},
};

/* CYBER BUDGET */

export const CYBER_SECURITY_TOOLS_MAP = {
    '/private/cyber-security-tools': {id: 1,  title: 'MENU$CYBER_BUDGET', parent: null},
    '/private/cyber-security-tools/list': {id: 11, title: 'CYBER_SECURITY_TOOLS$TITLE', parent: 1},
    '/private/cyber-security-tools/edit': {id: 12, title: 'CYBER_SECURITY_TOOLS$EDIT_TOOL', parent: 11},
    '/private/cyber-security-tools/edit/new': {id: 13, title: 'CYBER_SECURITY_TOOLS$CREATE_TOOL', parent: 11},
};

export const OPERATIONAL_COSTS_MAP = {
    '/private/operational-costs': {id: 1,  title: 'MENU$CYBER_BUDGET', parent: null},
    '/private/operational-costs/list': {id: 11, title: 'FIXED_OPERATIONAL_COSTS$TITLE', parent: 1},
    '/private/operational-costs/edit': {id: 12, title: 'FIXED_OPERATIONAL_COSTS$EDIT_COST', parent: 11},
    '/private/operational-costs/edit/new': {id: 13, title: 'FIXED_OPERATIONAL_COSTS$CREATE_COST', parent: 11},
};

export const CAPITAL_COSTS_MAP = {
    '/private/capital-costs': {id: 1,  title: 'MENU$CYBER_BUDGET', parent: null},
    '/private/capital-costs/list': {id: 11, title: 'FIXED_CAPITAL_COSTS$TITLE', parent: 1},
    '/private/capital-costs/edit': {id: 12, title: 'FIXED_CAPITAL_COSTS$EDIT_COST', parent: 11},
    '/private/capital-costs/edit/new': {id: 13, title: 'FIXED_CAPITAL_COSTS$CREATE_COST', parent: 11},
};

export const VARIABLE_COSTS_MAP = {
    '/private/variable-costs': {id: 1,  title: 'MENU$CYBER_BUDGET', parent: null},
    '/private/variable-costs/list': {id: 11, title: 'VARIABLE_COSTS$TITLE', parent: 1},
    '/private/variable-costs/edit': {id: 12, title: 'VARIABLE_COSTS$EDIT_COST', parent: 11},
    '/private/variable-costs/edit/new': {id: 13, title: 'VARIABLE_COSTS$CREATE_COST', parent: 11},
};

export const SYSTEM_RISK_REDUCTION_MAP = {
    '/private/system-risk-reduction': {id: 1,  title: 'MENU$CYBER_BUDGET', parent: null},
    '/private/system-risk-reduction/list': {id: 11, title: 'SYSTEM_RISK_REDUCTION$TITLE', parent: 1},
};

export const USER_SETTINGS_MAP = {
    '/private/user-settings': {id: 1,  title: 'MENU$CYBER_BUDGET', parent: null},
    '/private/user-settings/list': {id: 11, title: 'USER_SETTINGS$TITLE', parent: 1},
    '/private/user-settings/edit': {id: 12, title: 'USER_SETTINGS$EDIT_SETTINGS', parent: 11},
    '/private/user-settings/edit/new': {id: 13, title: 'VARIABLE_COSTS$CREATE_COST', parent: 11},
};

/* AUDIT LOGS */

export const AUDIT_LOGS_MAP = {
    '/private/audit-logs/list': {id: 1, title: 'AUDIT_LOGS$TITLE', parent: null},
    '/private/audit-logs/details': {id: 11, title: 'AUDIT_LOGS$DETAILS', parent: 1},
};

export const VENDOR_CONTRACT_MAP = {
    '/private/vendor-contracts': {id: 1,  title: 'MENU$DOCUMENTATION_MANAGEMENT', parent: null},
    '/private/vendor-contracts/list': {id: 11, title: 'VENDOR_CONTRACT$TITLE', parent: 1},
    '/private/vendor-contracts/edit': {id: 12, title: 'VENDOR_CONTRACT$EDIT_CONTRACT', parent: 11},
    '/private/vendor-contracts/edit/new': {id: 13, title: 'VENDOR_CONTRACT$CREATE_CONTRACT', parent: 11},
};
