// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component, useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {arrayPush, change, Field, FieldArray, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {MAPPING} from '../actions';
import MdInput from '../../../../components/md-input';
import Preloader from '../../../../components/preloader';
import {instanceAPI} from '../../../../services/api.service';
import {MdAsyncSelect} from '../../../../components/md-select';
import {ENTITY_TYPES, NEW_ID} from '../../../../constants/spec';
import SelectEntities from '../../../../components/select-entities';
import {translate, withTranslation} from '../../../../services/translate.service';
import {AddIconBtn, CancelBtn, DeleteIconBtn, ResetBtn, SubmitBtn} from '../../../../components/md-button';
import {useParams} from "react-router-dom";
import {findHint} from '../../../../components/hints/hints';

// config
export const FORM_NAME = 'mapSecurityRequirements';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    return (<ConnectedInitializer> <ConnectedForm isNew={isNew} /> </ConnectedInitializer>);
}

export default connect(
    state => ({
        message: state.securityRequirements.edit.mapping.errorMessage,
        expectAnswer: state.securityRequirements.edit.mapping.expectAnswer,
        hints: state.securityRequirements.edit.mapping.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: MAPPING.CLEAR}),
        initialize: id => dispatch({type: MAPPING.INITIALIZE, id}),
        clearError: () => dispatch({ type: MAPPING.META, errorMessage: null})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.securityRequirements.edit.mapping.initialized, hints: state.securityRequirements.edit.mapping.hintsData}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        formValues: getFormValues(FORM_NAME)(state),
        initialValues: state.securityRequirements.edit.mapping.data,
        disabled: state.securityRequirements.edit.mapping.expectAnswer,
        selectedTypesIds: state.securityRequirements.edit.mapping.selectedTypesIds,
        availableAssessmentTypes: state.securityRequirements.edit.mapping.availableAssessmentTypes,
        hints: state.securityRequirements.edit.mapping.hintsData,
    }),
    dispatch => ({
        cancel: () => dispatch({type: MAPPING.CANCEL}),
        update: formData => dispatch({type: MAPPING.UPDATE, ...formData}),
        addAssessmentType: assessmentType => dispatch({type: MAPPING.ADD_ASSESSMENTS_TYPE, assessmentType}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        if (values.frameworks && values.frameworks.length) {
            const frameworksArrayErrors = [];
            values.frameworks.forEach((framework, frameworkIndex) => {
                const frameworkErrors = {};
                if (framework && framework.items && framework.items.length) {
                    const itemsArrayErrors = [];
                    framework.items.forEach((item, itemIndex) => {
                        const itemErrors = {};
                        if (!item.controlFunction) {
                            itemErrors.controlFunction = 'CONTROL_FUNCTIONS$CONTROL_FUNCTION_REQUIRED'
                        }
                        if (!item.controlCategory) {
                            itemErrors.controlCategory = 'CONTROL_TESTS$CONTROL_TEST_REQUIRED'
                        }
                        if (!item.controlSubcategory) {
                            itemErrors.controlSubcategory = 'CONTROL_GUIDELINES$CONTROL_GUIDELINE_REQUIRED'
                        }
                        itemsArrayErrors[itemIndex] = itemErrors;
                    });
                    if (itemsArrayErrors.length) {
                        frameworkErrors.items = itemsArrayErrors;
                        frameworksArrayErrors[frameworkIndex] = frameworkErrors;
                    }
                }
            });
            if (frameworksArrayErrors.length) {
                errors.frameworks = frameworksArrayErrors;
            }
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formValues={}, addAssessmentType, availableAssessmentTypes, selectedTypesIds, hints})=>(
    <Paper style={{padding: '2rem'}}>
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h4 style={{marginTop: 'auto', marginBottom: '-0.5', marginRight: '5px'}}> {translate('SECURITY_REQUIREMENTS$ADD_FRAMEWORK')} </h4>&nbsp;
                    <div style={{width: 300}}>
                        <Field
                            disabled={disabled}
                            name="assessmentTypes"
                            component={MdAsyncSelect}
                            defaultOptions={availableAssessmentTypes}
                            onChange={(e, value) => addAssessmentType(value)}
                            placeholder={translate('SECURITY_REQUIREMENTS$SECURITY_REQUIREMENT')}
                            loadOptions={(name, done) => {
                                instanceAPI({
                                    method: 'post',
                                    url: `/assessment-types/filter`,
                                    data: {filter: {name, excludeIds: selectedTypesIds}}
                                }).then(({items})=>done(items)).catch(done.bind(null, []));
                            }}/>
                    </div>
                </div>
            </Col>
            <Col xs={12}>
               <FieldArray name="frameworks" component={Frameworks} />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_SECURITY_REQUIREMENTS_CREATE' : 'BUTTON_SECURITY_REQUIREMENTS_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints, `BUTTON_SECURITY_REQUIREMENTS_RESET`)} />
                <CancelBtn onClick={cancel} hint={findHint(hints, `BUTTON_SECURITY_REQUIREMENTS_CANCEL`)} />
            </Col>
        </Row>
    </form>
    </Paper>
))));

const Frameworks = connect(
    state => ({ disabled: state.securityRequirements.edit.mapping.expectAnswer, hints: state.securityRequirements.edit.mapping.hintsData }),
    dispatch => ({
        add: (name, data) => dispatch(arrayPush(FORM_NAME, `${name}.items`, data)),
        remove: (assessmentType, index) => dispatch({type: MAPPING.REMOVE_ASSESSMENTS_TYPE, assessmentType, index})
    })
)(({fields, add, remove, disabled}) => {
    return fields.map((mKey, i) => {
        let item = fields.get(i);
        return (<div key={i}>
            <h3>
                <span>{get(item, 'assessmentType.name')}</span>
                <AddIconBtn disabled={disabled} onClick={() => add(mKey, {assessmentType: item.assessmentType, isNew: true})} />
                <DeleteIconBtn disabled={disabled} onClick={() => remove(item.assessmentType, i)} />
            </h3>
            <FieldArray name={`${mKey}.items`} component={Items} parent={item} />
        </div>)
    })
});


class ItemForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            controlCategories: [],
            controlSubcategories: [],
        }
    }

    changeFunction ( controlFunction ){
        let { changeField, mKey } = this.props;
        changeField(`${mKey}.controlCategory`, null);
        changeField(`${mKey}.controlSubcategory`, null);
        instanceAPI({ method: 'post', url: `/control-categories/filter`, data: {filter: {parentId: get(controlFunction, 'id', null)}} })
            .then(({items}) => this.setState({controlCategories: items}));
    }

    changeCategory ( controlCategory ){
        let { changeField, mKey } = this.props;
        changeField(`${mKey}.controlSubcategory`, null);
        instanceAPI({ method: 'post', url: `/control-subcategories/filter`, data: {filter: {parentId: get(controlCategory, 'id', null)}} })
            .then(({items}) => this.setState({controlSubcategories: items}));
    }

    render() {
        let { controlCategories, controlSubcategories } = this.state;
        let { mKey, item, parent, disabled } = this.props;
        return (<Container fluid><Row>
            <Col xs={12} lg={4} className="offset-bottom-2">
                <SelectEntities
                    disabled={disabled}
                    name={`${mKey}.controlFunction`}
                    type={ENTITY_TYPES.CONTROL_FUNCTIONS}
                    onChange={(e, value) => this.changeFunction(value)}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    additionalFilters={{parentId: get(parent, 'assessmentType.id', null)}}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('CONTROL_FUNCTIONS$CONTROL_FUNCTION')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6} lg={4} className="offset-bottom-2">
                <Field
                    component={MdAsyncSelect}
                    name={`${mKey}.controlCategory`}
                    defaultOptions={controlCategories}
                    disabled={disabled || !item.controlFunction}
                    onChange={(e, value) => this.changeCategory(value)}
                    placeholder={translate('CONTROL_TESTS$CONTROL_TEST')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('CONTROL_TESTS$CONTROL_TEST')} </strong>)}
                    loadOptions={(name, done) => {
                        instanceAPI({
                            method: 'post',
                            url: `/control-categories/filter`,
                            data: {filter: {name, parentId: get(item, 'controlFunction.id', null)}}
                        }).then(({items})=>done(items)).catch(done.bind(null, []));
                    }}/>
            </Col>
            <Col xs={12} md={6} lg={4} className="offset-bottom-2">
                <Field
                    component={MdAsyncSelect}
                    name={`${mKey}.controlSubcategory`}
                    defaultOptions={controlSubcategories}
                    disabled={disabled || !item.controlFunction}
                    placeholder={translate('CONTROL_GUIDELINES$CONTROL_GUIDELINE')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('CONTROL_GUIDELINES$CONTROL_GUIDELINE')} </strong>)}
                    loadOptions={(name, done) => {
                        instanceAPI({
                            method: 'post',
                            url: `/control-subcategories/filter`,
                            data: {filter: {name, parentId: get(item, 'controlCategory.id', null)}}
                        }).then(({items})=>done(items)).catch(done.bind(null, []));
                    }}/>
            </Col>
        </Row></Container>)
    }
}

const ConnectedItemForm = connect(
    state => ({ disabled: state.securityRequirements.edit.mapping.expectAnswer, hints: state.securityRequirements.edit.mapping.hintsData }),
    dispatch => ({
        changeField: (field, value) => dispatch(change(FORM_NAME, field, value)),
    })
)(ItemForm);

const Items = connect(
    state => ({ disabled: state.securityRequirements.edit.mapping.expectAnswer, hints: state.securityRequirements.edit.mapping.hintsData }),
    null
)( ({fields, parent, disabled}) => {
    return fields.map((mKey, i) => {
        let item = fields.get(i);
        return (<Paper key={i} className="indent-3 offset-bottom-2"><div className="row-adornment">
            {item.isNew ? (
                <ConnectedItemForm parent={parent} item={item.toString()} mKey={mKey}/>
            ) : (<Container fluid><Row>
                <Col xs={12} lg={4} className="offset-bottom-2">
                    <Field
                        disabled
                        component={MdInput}
                        name={`${mKey}.controlFunction.name`}
                        label={(<strong> {translate('CONTROL_FUNCTIONS$CONTROL_FUNCTION')} </strong>)}
                            />
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-2">
                    <Field
                        disabled
                        component={MdInput}
                        name={`${mKey}.controlCategory.name`}
                        label={(<strong> {translate('CONTROL_TESTS$CONTROL_TEST')} </strong>)}
                            />
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-2">
                    <Field
                        disabled
                        component={MdInput}
                        name={`${mKey}.controlSubcategory.name`}
                        label={(<strong> {translate('CONTROL_GUIDELINES$CONTROL_GUIDELINE')} </strong>)}
                            />
                </Col>
            </Row></Container>)}
            <div className="adornment">
                <DeleteIconBtn disabled={disabled} onClick={() => fields.remove(i)}/>
            </div>
        </div></Paper>)
    });
});
