
// outsource dependencies
import { combineReducers } from 'redux';

// local dependencies
import edit from './edit/reducer';
import list from './list/reducer';
import scheme from './scheme/reducer';

export default combineReducers({
    edit,
    list,
    scheme
});
