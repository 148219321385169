
// outsource dependencies
import { call, put, select, takeEvery, take } from 'redux-saga/effects';

// local dependencies
import { CATEGORIES } from '../actions';
import { instanceAPI } from '../../../services/api.service';
import getHintsList, {CATEGORIES_LIST_HINTS} from '../../../services/hints.service';

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(CATEGORIES.INITIALIZE, initializeSaga);
    yield takeEvery(CATEGORIES.GET_LIST.REQUEST, getListSaga);
    yield takeEvery(CATEGORIES.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga () {
    yield put({type: CATEGORIES.CLEAR});
    yield put({type: CATEGORIES.META, initialized: true });
    // get list of categories
    yield put({type: CATEGORIES.GET_LIST.REQUEST});
    yield take(CATEGORIES.GET_LIST.FINISH);
    yield put({type: CATEGORIES.META, initialized: false});
}

function* getListSaga () {
    try {
        // get riskModel for api request
        let { riskModel } = yield select( state => state.app );
        let list = yield call( getList, riskModel );
        yield put({type: CATEGORIES.GET_HINTS_DATA.REQUEST});
        // setup category
        yield put({type: CATEGORIES.LIST, list});
    } catch ( {message} ) {
        yield put({type: CATEGORIES.META, errorMessage: message });
    }
    yield put({type: CATEGORIES.GET_LIST.FINISH});
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, CATEGORIES_LIST_HINTS);

        // NOTE setup hints data
        yield put({type: CATEGORIES.META, hintsData});
    } catch ( {message} ) {
        yield put({type: CATEGORIES.META, errorMessage: message});
    }
    yield put({type: CATEGORIES.GET_HINTS_DATA.FINISH});
}

/**
 * get list of categories of current risk model
 * @param {Object} riskModel
 * @private
 */
function getList( riskModel ) {
    return instanceAPI({method: 'get', url: `/risk-models/${riskModel.id}/categories`});
}
