
// outsource dependencies

// local dependencies
import { SYSTEM_LOG } from '../actions';
import { GDPR_SYSTEM_COMPLIANCE } from '../../../constants/routes';

export const initial = {
    data: [],
    hintsData: [],
    system: {},
    // meta
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
    // pagination
    page: 0,
    size: 10,
    totalPages: 0,
    // sort
    sortF: 'createdAt',
    sortD: false,
    // filter
    filter: '',
    // previous path
    back: GDPR_SYSTEM_COMPLIANCE.LINK(),
};

export default function ( state = initial, action ) {
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case SYSTEM_LOG.CLEAR:
            state = initial;
            break;
        case SYSTEM_LOG.DATA:
            state = { ...state, data: options.data };
            break;
        case SYSTEM_LOG.META:
            state = { ...state, ...options, data: state.data  };
            break;
    }

    return state;
}
