
// outsource dependencies
import React from 'react';
import { FormControl, FormControlLabel, Switch } from '@mui/material';

// local dependencies

// configuration

/**
 * Switch component with material design styles and redux form logic
 *
 * @public
 */
export default function ({ input, label, fullWidth = true, ...attr }) {
    return ( <FormControl fullWidth={fullWidth}>
        <FormControlLabel
            label={label}
            control={
                <Switch
                    color="primary"
                    {...attr}
                    id={input.name}
                    name={input.name}
                    checked={input.value}
                    onChange={e => input.onChange(e)}/>
            }/>
    </FormControl>);
};
