// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {CYBER_SECURITY_TOOLS} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {CYBER_SECURITY_TOOLS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ CYBER_SECURITY_TOOLS_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <h2 className="text-uppercase">
                                {translate('CYBER_SECURITY_TOOLS$TITLE')}&nbsp;<Preloader expectAnswer={expectAnswer} type="ICON" />
                            </h2>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} to={CYBER_SECURITY_TOOLS.LINK_EDIT()}  />
                            {/*permission={PERMISSION.SECURITY_TOOL.CREATE}*/}
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected />
                        </Col>
                    </Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.cyberSecurityTools.list.expectAnswer, list: state.cyberSecurityTools.list.data}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.cyberSecurityTools.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.cyberSecurityTools.list.filter,
        disabled: state.cyberSecurityTools.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.cyberSecurityTools.list}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">{translate('EXPOSURE_METRICS$TITLE')}</TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'riskReductionPercent'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('riskReductionPercent')}
                                >
                            {translate('CYBER_SECURITY_TOOLS$RISK_REDUCTION_PERCENTAGES')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'toolPrice'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('toolPrice')}
                                >
                            {translate('CYBER_SECURITY_TOOLS$LICENSE_COST')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell className="text-nowrap">{item.name}</TableCell>
                    <TableCell>{(item.quantMetrics||[]).map(i=>i.name).join(', ')}</TableCell>
                    <TableCell>{item.riskReductionPercent}</TableCell>
                    <TableCell>{item.toolPrice}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                            <div>
                            <Link
                                Btn={EditBtn}
                                permission={PERMISSION.SECURITY_TOOL.UPDATE}
                                to={CYBER_SECURITY_TOOLS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                                    />
                            <DelBtn permission={PERMISSION.SECURITY_TOOL.DELETE} onClick={() => deleteItem(item.id)} className="offset-left-2" />
                        </div>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));

const ConnectedError = connect(
    state => ({message: state.cyberSecurityTools.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
