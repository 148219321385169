
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';

// local dependencies
import * as THEME from '../constants/material-themes';

/**
 *
 * @param {Object} props
 * @public
 */
function Theme ({ name = 'ROOT', ...attr}) {
    return (<ThemeProvider theme={THEME[name]} {...attr} />);
}

Theme.propTypes = {
    name: PropTypes.oneOf( Object.keys(THEME) ).isRequired,
};

export default Theme;
