// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, FieldArray, formValueSelector, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT, LIST} from '../actions';
import Radio from '../../../components/md-radio';
import MdInput from '../../../components/md-input';
import {filters} from '../../../components/filter';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {instanceAPI} from '../../../services/api.service';
import {MdAsyncSelect, MdSelect} from '../../../components/md-select';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import TasksManagement from '../../../components/tasks-management';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import MdSwitch from '../../../components/md-switch';
import {ASSESSMENT_FINDINGS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {SUBJECTIVE_RISK_LEVELS} from '../list';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

// config
const FORM_NAME = 'editAssessmentFinding';
export const changeField = (field, value) => change(FORM_NAME, field, value);
export const LINK_TYPE = {
    NONE: 'NONE',
    ASSESSMENT: 'ASSESSMENT',
    REQUIREMENT: 'REQUIREMENT'
};
// const subjective = ['Low', 'Medium', 'High', 'Critical'];

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, []);
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ASSESSMENT_FINDINGS_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                    <span>
                                        <RichHintTitle
                                            update={LIST}
                                            name={isNew
                                                ? translate('ASSESSMENT_FINDINGS$CREATE_FINDING')
                                                : translate('ASSESSMENT_FINDINGS$EDIT_FINDING')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints, isNew ? `ASSESSMENT_FINDINGS_CREATE_TITLE` : `ASSESSMENT_FINDINGS_EDIT_TITLE`)}
                                        />
                                    </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};

export default connect(
    state => ({
        expectAnswer: state.assessmentFindings.edit.expectAnswer,
        hints: state.assessmentFindings.edit.hintsData
    }),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.assessmentFindings.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.assessmentFindings.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        formValues: getFormValues(FORM_NAME)(state),
        initialValues: state.assessmentFindings.edit.data,
        disabled: state.assessmentFindings.edit.expectAnswer,
        technologies: state.assessmentFindings.edit.technologies,
        hints: state.assessmentFindings.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        changeTechCategory: technologyCategory => dispatch({type: EDIT.CHANGE_TECH_CATEGORY, technologyCategory}),
        changeLinkedTo: () => {
            dispatch(changeField('assessments', []));
            dispatch(changeField('securityRequirements', []));
        }
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // percentage
        /*
        if (!values.percentage) {
            errors.percentage = 'GLOBALS$PERCENTAGE_REQUIRED';
        } else if (values.percentage < 0 || values.percentage > 100) {
            errors.percentage = 'GLOBALS$PERCENTAGE_ERROR';
        }
        */

        // value
        /*
        if (!values.value) {
            errors.value = 'GLOBALS$VALUE_REQUIRED';
        }
        */

        return errors;
    }
})(({
        handleSubmit,
        invalid,
        pristine,
        disabled,
        update,
        reset,
        isNew,
        formValues = {},
        technologies,
        changeLinkedTo,
        changeTechCategory,
        cancel,
        hints
    }) => (
        <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        name="name"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('GLOBALS$NAME')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                    />
                </Col>
            </Row>
            {/*<Row>*/}
            {/*<Col xs={12} md={6} className="offset-bottom-4">*/}
            {/*<Field*/}
            {/*type="number"*/}
            {/*name="percentage"*/}
            {/*disabled={disabled}*/}
            {/*component={MdInput}*/}
            {/*placeholder={translate('GLOBALS$PERCENTAGE')}*/}
            {/* required={true}
                                    label={(<strong> {translate('GLOBALS$PERCENTAGE')} </strong>)}*/}
            {/*/>*/}
            {/*</Col>*/}
            {/*<Col xs={12} md={6} className="offset-bottom-4">*/}
            {/*<Field*/}
            {/*type="number"*/}
            {/*name="value"*/}
            {/*disabled={disabled}*/}
            {/*component={MdInput}*/}
            {/*placeholder={translate('GLOBALS$VALUE')}*/}
            {/* required={true}
                                    label={(<strong> {translate('GLOBALS$VALUE')} </strong>)}*/}
            {/*/>*/}
            {/*</Col>*/}
            {/*</Row>*/}
            {/*<Row className="offset-bottom-4">*/}
            {/*<Col xs={12}>*/}
            {/*<Field*/}
            {/*labelKey="value"*/}
            {/*valueKey="value"*/}
            {/*placeholder="Type"*/}
            {/*name="findingType"*/}
            {/*disabled={disabled}*/}
            {/*component={MdSelect}*/}
            {/*label={(<strong> Type </strong>)}*/}
            {/*simpleValue={value => value&&({value})}*/}
            {/*options={['Manual', 'Vulnerability Scan']}*/}
            {/*/>*/}
            {/*</Col>*/}
            {/*</Row>*/}
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <SelectEntities
                        name="controlMaturity"
                        disabled={disabled}
                        type={ENTITY_TYPES.CONTROL_MATURITIES}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('CONTROL_MATURITIES$CONTROL_MATURITY')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    {/*<Field*/}
                    {/*name="subjectiveRiskLevel"*/}
                    {/*component={MdAsyncSelect}*/}
                    {/*defaultOptions={subjective}*/}
                    {/*placeholder={translate('subjectiveRiskLevel')}*/}
                    {/*label={(<strong> {translate('subjectiveRiskLevel')} </strong>)}*/}
                    {/*// loadOptions={(name, done) => {*/}
                    {/*//     instanceAPI({*/}
                    {/*//         method: 'post',*/}
                    {/*//         url: '/technologies/filter',*/}
                    {/*//         data: {size: 6, filter: {name, technologyCategoryId: get(formValues, 'technologyCategory.id', null)}}*/}
                    {/*//     }).then(({items})=>done(items)).catch(done.bind(null, []));*/}
                    {/*// }}*/}
                    {/*/>*/}
                    <Field
                        labelKey="label"
                        valueKey="value"
                        name="subjectiveRiskLevel"
                        disabled={disabled}
                        component={MdSelect}
                        placeholder={translate('Subjective Risk Level')}
                        options={[0, 1, 2, 3].map(option => {
                            return ({
                                label: SUBJECTIVE_RISK_LEVELS[option],
                                value: option
                            });
                        })}
                        label={(<strong> {translate('Subjective Risk Level')} </strong>)}
                        sendValueProp
                        simpleValue={(value) => {
                            return {
                                value: value,
                                label: value && SUBJECTIVE_RISK_LEVELS[value] ? SUBJECTIVE_RISK_LEVELS[value] : ''
                            };
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <SelectEntities
                        disabled={disabled}
                        name="technologyCategory"
                        type={ENTITY_TYPES.TECH_CATEGORIES}
                        onChange={(e, value) => changeTechCategory(value)}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CATEGORY')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        name="technology"
                        component={MdAsyncSelect}
                        defaultOptions={technologies}
                        placeholder={translate('TECHNOLOGIES$TECHNOLOGY')}
                        label={(<strong> {translate('TECHNOLOGIES$TECHNOLOGY')} </strong>)}
                        disabled={disabled || !get(formValues, 'technologyCategory')}
                        loadOptions={(name, done) => {
                            instanceAPI({
                                method: 'post',
                                url: '/technologies/filter',
                                data: {
                                    size: 6,
                                    filter: {name, technologyCategoryId: get(formValues, 'technologyCategory.id', null)}
                                }
                            }).then(({items}) => done(items)).catch(done.bind(null, []));
                        }}/>
                </Col>
            </Row>
            <Row className="offset-bottom-2">
                <Col xs={12}>
                    <Field
                        disabled={disabled}
                        component={MdSwitch}
                        name="isGDPR"
                        label={(<strong> {translate('GDPR')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-2">
                <Col xs={12}>
                    <Field
                        row
                        name="linkType"
                        component={Radio}
                        disabled={disabled}
                        onChange={() => changeLinkedTo()}
                        label={translate('ASSESSMENT_FINDINGS$LINK_TYPE')}
                        options={Object.keys(LINK_TYPE).map(item => ({
                            value: item.toString(),
                            label: filters.humanize(item)
                        }))}
                    />
                </Col>
            </Row>
            {formValues.linkType === LINK_TYPE.ASSESSMENT && (<Row className="offset-bottom-4">
                <Col xs={12}>
                    <SelectEntities
                        isMulti
                        name="assessments"
                        disabled={disabled}
                        type={ENTITY_TYPES.ASSESSMENTS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('ASSESSMENTS$TITLE')} </strong>)}
                    />
                </Col>
            </Row>)}
            {formValues.linkType === LINK_TYPE.REQUIREMENT && (<Row className="offset-bottom-4">
                <Col xs={12}>
                    <SelectEntities
                        isMulti
                        disabled={disabled}
                        name="securityRequirements"
                        type={ENTITY_TYPES.SECURITY_REQUIREMENTS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('SECURITY_REQUIREMENTS$TITLE')} </strong>)}
                        getOptionLabel={option => `${get(option, 'code', '')}.${get(option, 'securityControlName.name', '')}`}
                    />
                </Col>
            </Row>)}
            <AssessmentInfo/>
            <Row className="offset-bottom-8"><Col xs={12}> <FieldArray name="tasks" component={TasksManagement}/>
            </Col></Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                               hint={findHint(hints, isNew ? 'BUTTON_ASSESSMENT_FINDINGS_CREATE' : 'BUTTON_ASSESSMENT_FINDINGS_SAVE')} />
                    <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_ASSESSMENT_FINDINGS_RESET')} />
                    <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_ASSESSMENT_FINDINGS_CANCEL')} />
                </Col>
            </Row>
        </form>)
)));

const AssessmentInfo = withTranslation(connect(
    state => ({assessments: formValueSelector(FORM_NAME)(state, 'assessments') || []}),
    null
)(({assessments}) => {
    if (assessments.length) {
        return (<Row> <Col xs={12}>
            <ul className="list-unstyled">
                {(assessments || []).map((item = {}, key) => {
                    if (get(item, 'assessmentLevel.name') === 'System') {
                        return (<li key={key}>
                            <h4>{item.name}</h4>
                            {item.isAllSelected ? (
                                <h5 className="offset-left-5"><strong>{translate('SYSTEMS$ALL_SYSTEMS')}</strong></h5>
                            ) : (
                                <ul>
                                    {(item.systems || []).map((system, key) => (
                                        <li key={key}>{system.name}</li>
                                    ))}
                                </ul>
                            )}
                        </li>);
                    }
                    return null;
                })}
            </ul>
        </Col> </Row>);
    }
    return null;
}));
