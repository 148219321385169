
// outsource dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

// local dependencies
import config from '../constants';
import { MdAsyncSelect } from './md-select';
import { ENTITY_TYPES } from '../constants/spec';
import { instanceAPI } from '../services/api.service';

// configure
SelectEntities.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.PropTypes.oneOf(Object.keys(ENTITY_TYPES).map(name=>ENTITY_TYPES[name])).isRequired,
    selected: PropTypes.array,
};

/**
 *
 * @param { Object } props
 * @public
 */
export function SelectEntities ({type, name, selected = [], additionalFilters = {}, riskModel, component = MdAsyncSelect, ...attr}) {
    return (
        <Field
            name={name}
            cacheOptions
            component={component}
            { ...attr }
            loadOptions={(name, done) => {
                let excludes = [];
                selected.map(i => i&&excludes.push(i));
                config.DEBUG &&
                console.log(`%c Select entity ${type} `, 'color: #eea236; background: #232323; font-size: 14px;'
                    ,'\n selected:', selected
                    ,'\n excludes:', excludes
                    ,'\n name:', name
                );
                getDataByType(type, name, (riskModel ? riskModel.id : null), excludes, additionalFilters).then((data) => {
                    if (type === "DOMAINS") {
                        return done(data.filter(domain => domain.riskDomainView))
                    }
                    done(data)
                }).catch(() => done([]));
        }}/>
    );
}
export default connect( state => ({ riskModel: state.app.riskModel }), null )(SelectEntities);

// let regulationsList = [{name: 'HIPAA'}, {name: 'PCI'}, {name: 'GDPR'}, {name: 'CCPA'}, {name: 'NYS DFS Part 500'}, {name: 'Insurance Data Security Act'}]

/**
 * request for server to get list of special entities depends on chosen type
 * @param {String} type
 * @param {String} name
 * @param {Number|String} riskModelId*
 * @param {Array} [excludeIds=[]]
 * @param {Array} [additionalFilters]
 * @return {Promise}
 * @private
 */
function getDataByType( type, name, riskModelId, excludeIds=[], additionalFilters ) {
    switch(type) {
        default:
            return new Promise((r, reject) => reject({message: `Incorrect entity TYPE ${type}`}) );

        case ENTITY_TYPES.ANSWER_WEIGHT:
            return instanceAPI({method: 'get', url: `/answer-weights`});
        case ENTITY_TYPES.ASSESSMENT_LEVELS:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/assessment-levels/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.ASSESSMENT_TYPES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/assessment-types/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.ASSESSMENT_WEIGHTS:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/assessment-weights/filter`, data: {filter: {name, excludeIds, ...additionalFilters}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.ASSESSMENTS:
            return instanceAPI({ method: 'post', url: '/assessments/filter', data: {filter: {name, excludeIds, ...additionalFilters}} })
                .then(success => success.items);
        case ENTITY_TYPES.ASSET_CLASSES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/data-asset-classifications/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.BUSINESS_UNITS:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/business-units/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.CAPITAL_COST:
            return instanceAPI({ method: 'post', url: '/budget/fixed-capital-costs/filter', data: {filter: {name, excludeIds, ...additionalFilters}} })
                .then(success => success.items);
        case ENTITY_TYPES.CITIES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/cities/filter`, data: {filter: {name, excludeIds, ...additionalFilters}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.CONTROL_CATEGORIES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/control-categories/filter`, data: {filter: {name, excludeIds, ...additionalFilters}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.CONTROL_FUNCTIONS:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/control-functions/filter`, data: {filter: {name, excludeIds, ...additionalFilters}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.CONTROL_MATURITIES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/control-maturities/filter`, data: {filter: {name, excludeIds, ...additionalFilters}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.CONTROL_SUBCATEGORIES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/control-subcategories/filter`, data: {filter: {name, excludeIds, ...additionalFilters}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.COST_TYPES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/cost-type/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.COUNTRIES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/countries/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.CURRENCIES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/currencies/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.CYBER_ROLES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/budget/cyber-roles/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.DATA_DOMAINS:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/data-domains/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.DATA_TYPE_CLASSES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/data-type-classifications/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.DOMAINS:
            return instanceAPI({method: 'get', url: `/risk-models/${riskModelId}/domains`});
        case ENTITY_TYPES.ENVIRONMENT_TYPES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/environment-types/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.GDPR_CHAPTERS:
            return instanceAPI({ method: 'post', url: 'gdpr/chapter/filter', data: {filter: {name, excludeIds, ...additionalFilters}} })
                .then(success => success.items);
        case ENTITY_TYPES.INDUSTRIES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/industries/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.LANGUAGES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/languages/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.LICENSE_TYPES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/license-type/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.METRIC_DOMAINS:
            return instanceAPI({method: 'get', url: `/metric-domains`});
        case ENTITY_TYPES.OPERATIONAL_COST:
            return instanceAPI({ method: 'post', url: '/budget/fixed-operational-costs/filter', data: {filter: {name, excludeIds, ...additionalFilters}} })
                .then(success => success.items);
        case ENTITY_TYPES.PROCESSES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/processes/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.POLICIES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/policy/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.VENDOR_CONTRACTS:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/vendor-contract/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.QUAL:
            return instanceAPI({method: 'get', url: `/risk-models/${riskModelId}/qual-metrics`});
        case ENTITY_TYPES.QUANT_METRICS:
            return instanceAPI({ method: 'post', url: `/risk-models/${riskModelId}/quant-metrics/filter`, data: {filter: {name, excludeIds}} })
                .then(success => success.items);
        case ENTITY_TYPES.QUANT_MODELS:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/risk-models/${riskModelId}/quants/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.QUESTIONS_WEIGHT:
            return instanceAPI({method: 'get', url: `/question-weights`});
        case ENTITY_TYPES.QUESTIONS:
            return new Promise((resolve, reject) =>
                instanceAPI({
                    method: 'post',
                    url: `/risk-model/${riskModelId}/qualitative-questions/filter`,
                    data: { filter: {question: name, excludeIds} }
                }).then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.RATE_TYPES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/rate-type/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.REGULATIONS:
            return instanceAPI({ method: 'get', url: `/regulations`});
        case ENTITY_TYPES.RISK_TYPES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/risk-types/risk-model/${riskModelId}`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.ROLES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/roles/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.SECURITY_CONTROL_FAMILIES:
            return instanceAPI({ method: 'post', url: '/security-control-families/filter', data: {filter: {name, excludeIds, ...additionalFilters}} })
                .then(success => success.items);
        case ENTITY_TYPES.SECURITY_CONTROL_NAMES:
            return instanceAPI({ method: 'post', url: '/security-control-names/filter', data: {filter: {name, excludeIds, ...additionalFilters}} })
                .then(success => success.items);
        case ENTITY_TYPES.SECURITY_FRAMEWORKS:
            return instanceAPI({ method: 'post', url: `/assessment-types/filter`, data: {filter: {name, excludeIds}} })
                .then(success => success.items);
        case ENTITY_TYPES.SECURITY_REQUIREMENTS:
            return instanceAPI({ method: 'post', url: '/security-requirements/filter', data: {filter: {name, excludeIds, ...additionalFilters}} })
                .then(success => success.items);
        case ENTITY_TYPES.SECURITY_TOOLS:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/budget/cybersecurity-tools/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.STATES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/states/filter`, data: {filter: {name, excludeIds, ...additionalFilters}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.STATUSES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/statuses/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.SUBSIDIARIES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/subsidiary-organizations/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.SYSTEMS:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/systems/filter`, data: { filter: {name, excludeIds} } })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.TASKS:
            return instanceAPI({ method: 'post', url: '/tasks/filter', data: {filter: {name, excludeIds, ...additionalFilters}} })
                .then(success => success.items);
        case ENTITY_TYPES.TECHNOLOGIES:
            return instanceAPI({ method: 'post', url: 'technologies/filter', data: {filter: {name, excludeIds}} }).then(success => success.items);
        case ENTITY_TYPES.TECH_CATEGORIES:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/technology-categories/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.USERS:
            return new Promise((resolve, reject) =>
                instanceAPI({method: 'post', url: `/users/filter`, data: {filter: {name, excludeIds, ...additionalFilters}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
        case ENTITY_TYPES.VARIABLE_TYPES:
            return instanceAPI({method: 'get', url: `/variable-types/`});
        case ENTITY_TYPES.VENDORS:
            return new Promise((resolve, reject) =>
                instanceAPI({ method: 'post', url: `/vendors/filter`, data: {filter: {name, excludeIds}} })
                    .then( success => resolve(success.items) ).catch(reject)
            );
    }
}

