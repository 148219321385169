// outsource dependencies
import {cloneDeep, get, isEmpty} from 'lodash';
import {call, put, select, take, takeEvery} from 'redux-saga/effects';

import enigma from 'enigma.js';
import schema from 'enigma.js/schemas/12.20.0.json';

// local dependencies
import {EDIT} from './actions';
import {initial} from './reducer';
import {APP} from '../../actions/types';
import is from '../../services/is.service';
import store, {history} from '../../store';
import queryService from '../../services/query.service';
import {instanceAPI} from '../../services/api.service';
import {DASHBOARDS, PRIVACY_IMPACT_ASSESSMENT} from '../../constants/routes';
import * as pbi from 'powerbi-client';

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE_TAB, updateTabSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.QLIK_INITIALIZE, analyticsInitializeSaga);
    yield takeEvery(EDIT.UPDATE_DATA, updateDataSaga);
    yield takeEvery(EDIT.GET_DATA.REQUEST, getDataSaga);
    yield takeEvery(EDIT.UPDATE_INPUT, updateInputSaga);
    yield takeEvery(EDIT.HANDLE_FILTER_PANEL_STATE, handleFilterPanelStateSaga);
    // NOTE setup listener on location change
    yield call(history.listen, listenHistory);
    // NOTE on initializing application with dashboard url location action does not fired
    // NOTE emulate
    if ( DASHBOARDS.REGEXP.test(history.location.pathname) || PRIVACY_IMPACT_ASSESSMENT.REGEXP.test(history.location.pathname) ) {
        // NOTE waiting until auth and risk model would be getting
        yield take(APP.INIT.FINISH);
        yield put({type: EDIT.INITIALIZE});
    }
}

export const QLIK_SESSION_DETAILS = {}

/**
 * history change listener
 * @param {Object} location
 * @private
 */
function listenHistory ({location : {pathname}}) {
    if (!(DASHBOARDS.REGEXP.test(pathname) || PRIVACY_IMPACT_ASSESSMENT.REGEXP.test(pathname))) {return;}
    // NOTE reinitialize page
    // NOTE this event will fired before the url was changed
    setTimeout(() => store.dispatch({type: EDIT.INITIALIZE}), 0);
}

function* initializeSaga () {


    // NOTE get current dashboard id
    let { currentId, stateUUID } = yield select( state => state.dashboards );
    // NOTE find 'id' param from current location
    let urlParams = history.location.pathname.split('/');
    let id = Number(urlParams[urlParams.length-1]);
    // NOTE take location data and setup verified params
    const params = yield call(getQueryParams, queryService.parse(history.location.search));
    // NOTE flow for new dashboard (param 'stateUUID' lead to new dashboard but with same id)
    // NOTE second and third conditions handling transition between usual page and filtered(with 'stateUUID')
    // if ( currentId !== id || (stateUUID && !params.stateUUID) || (!stateUUID && params.stateUUID) ) {
    if ( currentId !== id || (stateUUID !== params.stateUUID) ) {
        yield put({type: EDIT.CLEAR});
        yield put({type: EDIT.META, ...params});
        // NOTE get data
        yield put({type: EDIT.GET_DATA.REQUEST, id});
        yield take(EDIT.GET_DATA.FINISH);
        // NOTE stop preloader
        yield put({type: EDIT.META, initialized: true, currentId: id});
    } else {
        // NOTE current dashboard should only update params
        yield put({type: EDIT.META, ...params, initialized: true, });
    }


}

function* analyticsInitializeSaga ({analyticsData}) {
    try {
        // let {preloadCalls, accessDetails} = analyticsData;
        let {accessDetails} = analyticsData;
        let { riskModel } = yield select(state => state.app);

        if (analyticsData.analyticsType === 'QLIK') {
            if (accessDetails) {
                QLIK_SESSION_DETAILS.accessDetails = accessDetails;
            }

            const isLoggedIn = yield call(qlikLogin, QLIK_SESSION_DETAILS.accessDetails);
            QLIK_SESSION_DETAILS.isLoggedIn = isLoggedIn;

            if (!isLoggedIn) {
                throw new Error('Failed to authorize in qLik. Please try again later.');
            }

            const qcsHeaders = yield call(getQlikCSHeaders, QLIK_SESSION_DETAILS.accessDetails);
            const {session, application} = yield call(connectEnigma, qcsHeaders, QLIK_SESSION_DETAILS.accessDetails);
            QLIK_SESSION_DETAILS.session = session;
            QLIK_SESSION_DETAILS.application = application;

            handleDisconnect(session);

            //Auth Qlik
            yield put({type: EDIT.META, isQlikAuth: true});
        } else if (analyticsData.analyticsType === 'POWER_BI') {
            const accessConfig = yield call(getPowerBIConfig, riskModel.id, analyticsData);

            // pnlPowerBIHolder
            let powerBIHolder = document.getElementById('pnlPowerBIHolder');

            // Initialize iframe for embedding report
            const powerbi = window.powerbi;
            const models = pbi.models;

            powerBIHolder.style.height = (window.innerHeight - 250) + 'px';

            powerbi.bootstrap(powerBIHolder, {type: "report"});

            console.log(accessConfig);

            const reportLoadConfig = {
                type: "report",
                tokenType: models.TokenType.Embed,
                accessToken: accessConfig.embedToken.token,
                permissions: models.Permissions.Read,

                // Use other embed report config based on the requirement. We have used the first one for demo purpose
                embedUrl: accessConfig.embedReports[0].embedUrl,

                // Set Active Page Name
                // pageName: 'ReportSection9e10be59ca38a0f89fb4',

                // Enable this setting to remove gray shoulders from embedded report
                settings: {
                    background: models.BackgroundType.Transparent,
                    panes: {
                        pageNavigation: {
                            visible: true,
                            // position: models.PageNavigationPosition.Left
                        },
                    }
                }
            };

            // Display only one page
            if (accessDetails.pageId && accessDetails.pageId.toLowerCase() !== 'all') {
                reportLoadConfig.settings.panes.pageNavigation.visible = false;
                reportLoadConfig.pageName = accessDetails.pageId;
            }

            // Use the token expiry to regenerate Embed token for seamless end user experience
            // Refer https://aka.ms/RefreshEmbedToken
            // const tokenExpiry = accessConfig.embedToken.expiration;

            // Embed Power BI report when Access token and Embed URL are available
            const report = powerbi.embed(powerBIHolder, reportLoadConfig);

            // Triggers when a report schema is successfully loaded
            report.on("loaded", function () {
                console.log("Report load successful");
            });

            // Triggers when a report is successfully embedded in UI
            report.on("rendered", function () {
                console.log("Report render successful");
            });

            // Clear any other error handler event
            report.off("error");

            // Below patch of code is for handling errors that occur during embedding
            report.on("error", function (event) {
                // Use errorMsg variable to log error in any destination of choice
                console.error(event.detail);
                return;
            });

        } else {

            // Set DONE state
            yield put({type: EDIT.META, isQlikAuth: true});
        }

    } catch ({error}){
        yield put({type: EDIT.META, errorMessage: error});
    }
}

function* getDataSaga ({id, fromSaga=false}) {
    try {
        let { riskModel } = yield select(state => state.app);
        // NOTE get previous dashboard data
        let reducer = yield select(state => state.dashboards);
        // NOTE get new dashboard data
        let newData = yield call( getData, riskModel.id, id, reducer );
        // NOTE setup data
        yield put({type: EDIT.DATA, data: newData});
        // NOTE addition section param validation
        let tab = reducer.tab < get(newData, 'sections.length', 0) ? reducer.tab : initial.tab;
        // NOTE to cancel second request in initialize saga
        if (fromSaga) {
            yield put({type: EDIT.META, stateUUID: get(newData, 'referenceUUID', null)});
        }
        // NOTE update location
        yield call(updateLocation, {...reducer, tab, stateUUID: get(newData, 'referenceUUID', null)});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_DATA.FINISH});
}

function* updateDataSaga ({type, itemIndex, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let { data, tab } = yield select( state => state.dashboards );
        let filter = get(data, `sections[${tab}].dashboardItems[${itemIndex}].dashboardItemFilter`);
        filter.values = {...filter.values, ...options};
        // NOTE rewrite data to update 'dashboardItemFilter' data
        yield put({type: EDIT.DATA, data: {...data}});
        // NOTE get list
        yield put({type: EDIT.GET_DATA.REQUEST, id: data.id, fromSaga: true});
        yield take(EDIT.GET_DATA.FINISH);
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function* updateTabSaga ({tab}) {
    let { data, tab: prevTab, ...options } = yield select( state => state.dashboards );
    // NOTE close all filters for previous section
    let prevSectionItems = get(data, `sections[${prevTab}].dashboardItems`, []);
    prevSectionItems.forEach(({dashboardItemFilter}) => {
        if ( dashboardItemFilter ) {
            dashboardItemFilter.isOpen = false;
        }
    });
    // NOTE rewrite data to update 'dashboardItemFilter' statuses and call re-render
    yield put({type: EDIT.DATA, data: {...data}});
    // NOTE update location
    yield call(updateLocation, {...options, tab});
}

function* handleFilterPanelStateSaga ({itemIndex, isOpen}) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    let { data, tab } = yield select( state => state.dashboards );
    let filter = get(data, `sections[${tab}].dashboardItems[${itemIndex}].dashboardItemFilter`);
    filter.isOpen = isOpen;
    // NOTE reset filters if panel has been closed
    if (!isOpen) {
        filter.values = {};
    }
    yield put({type: EDIT.DATA, data: {...data}});
    yield put({type: EDIT.META, expectAnswer: false});
}

function* updateInputSaga ({input}) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let { riskModel } = yield select( state => state.app );
        yield call( updateInput, riskModel.id, input );
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

/**
 * get dashboard data
 * @param {String|Number} riskModelId
 * @param {String|Number} id
 * @param {Object} reducer
 * @private
 */
function getData( riskModelId, id, {data, stateUUID = null} ) {
    // NOTE remove excess data
    let simplified = cloneDeep(data);
    (simplified.sections || []).forEach(section => {
        (section.dashboardItems || []).forEach(dashboardItem => delete dashboardItem['gridItems']);
    });
    return new Promise((resolve, reject) =>
        instanceAPI({
            method: 'post',
            url: `/dashboards/${id}/filter`,
            headers: {'risk-model-id': riskModelId},
            data: {...simplified, referenceUUID: stateUUID},
        }).then(data => {
            // NOTE compute filter isOpen state
            (data.sections || []).forEach(section => {
                (section.dashboardItems || []).forEach(({dashboardItemFilter}) => {
                    if ( dashboardItemFilter ) {
                        dashboardItemFilter.isOpen = !isEmpty(dashboardItemFilter.values);
                    }
                });
            });
            resolve(data);
        }).catch(reject)
    );
}

/**
 * get dashboard data
 * @param {String|Number} riskModelId
 * @param {String|Number} id
 * @param {Object} reducer
 * @private
 */
function getPowerBIConfig(riskModelId, data) {
    return new Promise((resolve, reject) =>
        instanceAPI({
            method: 'post',
            url: `/dashboards/powerbi/embed-config`,
            headers: {'risk-model-id': riskModelId},
            data: data,
        }).then(data => {
            resolve(data);
        }).catch(reject)
    );
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams({section, stateUUID}) {
    return {
        stateUUID,
        tab: is.countable(section) ? Number(section) : initial.tab,
    };
}

/**
 * helper to setup correctness url params
 *
 * @param {Object} reducer
 * @public
 */
function updateLocation({tab, stateUUID}) {
    let params = {};
    // NOTE setup data to url which has difference with default data
    tab !== initial.tab && (params.section = tab);
    stateUUID && (params.stateUUID = stateUUID);
    let search = queryService.format(params);
    // NOTE update url if it has difference
    if ( search !== history.location.search ) {
        history.push(history.location.pathname+search);
    }
}

/**
 * update dashboard input
 *
 * @param {String|Number} riskModelId
 * @param {Object} input
 * @private
 */
function updateInput (riskModelId, input) {
    let dataItem = {description: input.description, parameters: {...input}}
    return instanceAPI({method: 'post', url: '/dashboards/save-items-data', headers: {'risk-model-id': riskModelId}, data: [dataItem]});
}

async function checkQlikLoggedIn(accessDetails) {
    return await fetch(`https://${accessDetails.tenant}/api/v1/users/me`, {
        mode: 'cors',
        credentials: 'include',
        headers: {
            'qlik-web-integration-id': accessDetails.webIntegrationId
        },
    })
}

async function qlikLogin(accessDetails) {
    const loggedIn = await checkQlikLoggedIn(accessDetails);
    if (loggedIn.status !== 200) {
        const loginRes = await qLikJwtLogin(accessDetails);
        if (loginRes.status !== 200) {
            const message = 'Something went wrong while logging in to Qlik.';
            throw new Error(message);
        }
        const recheckLoggedIn = await checkQlikLoggedIn(accessDetails);
        if (recheckLoggedIn.status !== 200) {
            const message = 'Third-party cookies are not enabled in your browser settings and/or browser mode.';
            throw new Error(message);
        }
    }
    return true;
}

async function qLikJwtLogin(accessDetails) {
    const authHeader = `Bearer ${accessDetails.token}`;
    return await fetch(`https://${accessDetails.tenant}/login/jwt-session?qlik-web-integration-id=${accessDetails.webIntegrationId}`, {
        credentials: 'include',
        mode: 'cors',
        method: 'POST',
        headers: {
            'Authorization': authHeader,
            'qlik-web-integration-id': accessDetails.webIntegrationId
        },
    })
}

async function getQlikCSHeaders(accessDetails) {
    const response = await fetch(`https://${accessDetails.tenant}/api/v1/csrf-token`, {
        mode: 'cors',
        credentials: 'include',
        headers: {
            'qlik-web-integration-id': accessDetails.webIntegrationId
        },
    })

    const csrfToken = new Map(response.headers).get('qlik-csrf-token');
    return {
        'qlik-web-integration-id': accessDetails.webIntegrationId,
        'qlik-csrf-token': csrfToken,
    };
}

// ENIGMA ENGINE CONNECTION
async function connectEnigma(qcsHeaders, accessDetails) {
    const result = await getEnigmaSessionAndApp(qcsHeaders, accessDetails);
    return result;
}

async function getEnigmaSessionAndApp(headers, accessDetails) {
    const params = Object.keys(headers).map((key) => `${key}=${headers[key]}`).join('&');

    return (async () => {
        try {
            return await createEnigmaAppSession(schema, accessDetails, params);
        }
        catch {
            // Handle race condition with new users who do not have permissions to access the application. The code makes another attempt after a 1.5 seconds.
            const waitSecond = await new Promise(resolve => setTimeout(resolve, 1500));
            waitSecond.toString();
            try {
                return await createEnigmaAppSession(schema, accessDetails, params);
            } catch (e) {
                throw new Error(e);
            }
        }
    })();
}

async function createEnigmaAppSession(schema, accessDetails, params) {
    const session = enigma.create({
        schema,
        url: `wss://${accessDetails.tenant}/app/${accessDetails.applicationId}?${params}`,
        identity: accessDetails.sessionId
    });
    const enigmaGlobal = await session.open();
    const application = await enigmaGlobal.openDoc(accessDetails.applicationId);
    return {session, application};
}

function handleDisconnect(session) {
    session.on('closed', () => {
        const message = 'qLik application is closed Due to inactivity or loss of connection, this session has ended.';
        throw new Error(message);
    });

    session.on('suspended', () => {
        const message = 'qLik application is closed Due to loss of connection, this session has been suspended.';
        throw new Error(message);
    });

    window.addEventListener('offline', () => {
        session.close();
    });
}
