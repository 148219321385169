// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {ASSOCIATE_MODELS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, []);
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ASSOCIATE_MODELS_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                <span>
                                    <RichHintTitle
                                        update={EDIT}
                                        name={isNew ? `ASSOCIATE_MODELS$CREATE_MODEL` : `ASSOCIATE_MODELS$EDIT_MODEL`}
                                        expectAnswer={expectAnswer}
                                        data={findHint(hints, `ASSOCIATE_MODELS_TITLE`)}/>
                                </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};

export default connect(
    state => ({expectAnswer: state.associateModels.edit.expectAnswer, hints: state.associateModels.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.associateModels.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.associateModels.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.associateModels.edit.data,
        disabled: state.associateModels.edit.expectAnswer,
        hints: state.associateModels.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editAssociate',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // description
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        // owner
        if (!values.owner) {
            errors.owner = 'GLOBALS$OWNER_REQUIRED';
        }
        // quant metrics
        if (!values.quantMetrics || !values.quantMetrics.length) {
            errors.quantMetrics = 'EXPOSURE_MODELS$EXPOSURE_MODEL_REQUIRED';
        }
        // qual metrics
        if (!values.qualMetrics || !values.qualMetrics.length) {
            errors.qualMetrics = 'SCORING_METRICS$METRIC_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, hints}) => (
    <form autoComplete="off" name="editAssociate" onSubmit={handleSubmit(update)}>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    name="owner"
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    placeholder={translate('GLOBALS$OWNER')}
                    getOptionLabel={option => get(option, 'fullName')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$OWNER')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    required
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$DESCRIPTION')}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    name="quantMetrics"
                    disabled={disabled}
                    type={ENTITY_TYPES.QUANT_MODELS}
                    placeholder={translate('EXPOSURE_MODELS$TITLE')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('EXPOSURE_MODELS$TITLE')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    name="qualMetrics"
                    disabled={disabled}
                    type={ENTITY_TYPES.QUAL}
                    placeholder={translate('SCORING_METRICS$TITLE')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('SCORING_METRICS$TITLE')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_ASSOCIATE_MODELS_CREATE' : 'BUTTON_ASSOCIATE_MODELS_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2" hint={findHint(hints, `BUTTON_ASSOCIATE_MODELS_RESET`)} />
                <CancelBtn onClick={cancel} hint={findHint(hints, `BUTTON_ASSOCIATE_MODELS_CANCEL`)} />
            </Col>
        </Row>
    </form>
))));
