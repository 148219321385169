
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Slide } from '@mui/material';

// local dependencies
import MdButton from '../md-button';
import ABBREV_DIALOG from './actions';
import { translate } from '../../services/translate.service';
import {withStyles} from '@mui/styles'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

/**
 * confirmation component with message which open by 'ABBREV.REQUEST' action
 *
 * @example
 dispatch({type: ABBREV.REQUEST, message: translate('GLOBALS$DELETION_CONFIRM') });

 *
 * @param {Object} props
 * @public
 */
export default withStyles({ paper: { minWidth: '400px' } })(connect(
    state => ({...state.abbrevDialog}),
    dispatch => ({ dismiss: () => dispatch({type: ABBREV_DIALOG.CANCEL})}),
)(({showModal, title, content, dismiss, classes}) => {
    return (
        <Dialog
            open={showModal}
            onClose={(event, reason) => {
                if(reason === "backdropClick" && reason === "escapeKeyDown") return false;
                dismiss();
            }}
            TransitionComponent={Transition}
            classes={{paper: classes.paper}}
            aria-labelledby="abbrev-dialog"
            aria-describedby="abbrev-dialog"
        >
            { title ? (<DialogTitle id="confirmation-dialog"> {title} </DialogTitle>) : '' }
            <DialogContent>
                <DialogContentText> {content || translate('GLOBALS$DEFAULT_CONFIRMATION')} </DialogContentText>
            </DialogContent>
            <DialogActions>
                {/*<MdButton variant="text" onClick={confirm}> {translate('GLOBALS$AGREE')} </MdButton>*/}
                <MdButton variant="text" onClick={dismiss}> {translate('GLOBALS$CANCEL')} </MdButton>
            </DialogActions>
        </Dialog>
    )
}))

