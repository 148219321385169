
// outsource dependencies
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from './actions';
import { VIEW_TYPES } from '../../constants/spec';
import { instanceAPI } from '../../services/api.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
}

function* initializeSaga ({params}) {
    yield put({type: EDIT.CLEAR});
    try {
        let { riskModel } = yield select( state => state.app );
        let data = yield call(getData, riskModel.id, params);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

/**
 * get data
 *
 * @param {Number} riskModelId
 * @param {Object} params
 * @private
 */
function getData (riskModelId, params) {
    return instanceAPI({
        method: 'post',
        headers: {'risk-model-id': riskModelId},
        url: '/dashboards/get-drilldown-dashboard',
        data: {view: VIEW_TYPES.VENDOR_QUALS, params},
    });
}
