
// outsource dependencies
import { combineReducers } from 'redux';

// local dependencies
import list from './list/reducer';
import edit from './edit/reducer';
import drilldown from './drilldown/reducer';
import systemLog from './system-log/reducer';
import questions from './questions/reducer';

export default combineReducers({
    list,
    edit,
    drilldown,
    systemLog,
    questions,
});
