
// outsource dependencies
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { history } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true, ...params});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.securityTools.edit);
    // NOTE go back
    yield call(history.push, back);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let options = ['SAVE', 'CREATE', 'RESET', 'CANCEL'];
        let page = 'SECURITY_TOOLS';
        let hintsData = yield call(getHintsList, language, options, page);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

/**
 * get list of hints
 * @param {String} language
 * @param {Array} options
 * @param {String} page
 * @private
 */
function getHintsList (language, options, page) {
    let data = options.map(opt => `BUTTON_${page}_${opt}`);
    return instanceAPI({method: 'post', url: `/hints/get/${language}`, data});
}
/**
 * get security tools by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/budget/cybersecurity-tools/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update data security tools
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: `/budget/cybersecurity-tools`, data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: `/budget/cybersecurity-tools`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
