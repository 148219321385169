// outsource dependencies
import get from 'lodash/get';
import {change} from 'redux-form';
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {EDIT} from '../actions';
import {history} from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import {SUBSIDIARIES} from '../../../constants/routes';
import queryService from '../../../services/query.service';
import {instanceAPI} from '../../../services/api.service';
import separateService from '../../../services/separate-with-comma.service';
import getHintsList, {SUBSIDIARIES_EDIT_HINTS} from '../../../services/hints.service';


/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.SETUP_STATE, setupStateSaga);
    yield takeEvery(EDIT.SETUP_COUNTRY, setupCountrySaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        yield call(separateService.separate, data);
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        // NOTE: initialize lists of states and cities for current country
        let countryId = get(data, 'country.id', null);
        if ( countryId ) {
            let stateId = get(data, 'state.id', null);
            yield put({type: EDIT.SETUP_COUNTRY, countryId});
            yield put({type: EDIT.SETUP_STATE, countryId, stateId});
        }
        // NOTE: setup data
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true, ...params});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true});
    try {
        yield call(separateService.convert, options);
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, SUBSIDIARIES_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

function* setupCountrySaga ({countryId}) {
    yield put({type: EDIT.META, expectAnswer: true, states: [], cities: []});
    try {
        // NOTE clear old field values
        yield put(change('editSubsidiary', 'state', null));
        yield put(change('editSubsidiary', 'city', null));
        // NOTE get list of states for chosen country
        let states = yield call(getStates, countryId);
        // NOTE get list of cities for chosen country
        // cause not all countries have states but can have cities
        let cities = yield call(getCities, countryId);
        yield put({type: EDIT.META, expectAnswer: false, states, cities});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* setupStateSaga ({countryId, stateId}) {
    yield put({type: EDIT.META, expectAnswer: true, cities: []});
    try {
        // NOTE clear old field values
        yield put(change('editSubsidiary', 'city', null));
        // NOTE get list of cities for chosen country and state
        let cities = yield call(getCities, countryId, stateId);
        yield put({type: EDIT.META, expectAnswer: false, cities});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* cancelSaga () {
    // NOTE go back
    yield call(history.push, SUBSIDIARIES.LINK());
}

/**
 * get subsidiary by id
 * @param {Number|String} id
 * @private
 */
function getData( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/subsidiary-organizations/${id}`}).then(resolve).catch(reject);
    });
}


/**
 * update subsidiary
 * @param {Object} data
 * @private
 */
function updateData( data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: `/subsidiary-organizations`, data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: `/subsidiary-organizations`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * get list of states for current country
 * @param {Number} countryId
 * @private
 */
function getStates( countryId ) {
    return new Promise ( ( resolve, reject ) => {
        instanceAPI({ method: 'post', url: `/states/filter`, data: { filter: { countryId } } })
            .then( ({items}) => resolve(items) ).catch(reject)
    });
}

/**
 * get list of cities for current country and state
 * @param {Number} countryId
 * @param {Number} stateId
 * @private
 */
function getCities( countryId, stateId ) {
    return new Promise ( ( resolve, reject ) => {
        instanceAPI({ method: 'post', url: `/cities/filter`, data: { filter: { countryId, stateId } } })
            .then( ({items}) => resolve(items) ).catch(reject)
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
