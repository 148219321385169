
// outsource dependencies
import { reset } from 'redux-form';
import { call, put, takeEvery } from 'redux-saga/effects';

// local dependencies
import PREVIEW_DIALOG from './actions';
import {instanceAPI} from "../../services/api.service";

// configure

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(PREVIEW_DIALOG.CANCEL, cancelSaga);
    yield takeEvery(PREVIEW_DIALOG.INITIALIZE, initializeSaga);
}

function* initializeSaga ({item, title, previewType}) {
    yield put({type: PREVIEW_DIALOG.CLEAR});
    yield put({type: PREVIEW_DIALOG.META, showModal: true});
    yield put({type: PREVIEW_DIALOG.DATA, data: item, title: title});
    yield put({type: PREVIEW_DIALOG.META, initialized: true});

    if (previewType === 'message') {
        let updatedItem = yield call(instanceAPI, {method: 'put', url: `/user-messages/read`, data: item});
        if (updatedItem != null) {
            item.messageStatus = updatedItem.messageStatus;
        }
    }
}


function* cancelSaga () {
    yield put({type: PREVIEW_DIALOG.CLEAR});
    yield put(reset('previewForm'));
}
