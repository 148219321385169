
// outsource dependencies

// local dependencies

export default (prefix => {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
    };
})('@import-quant-metrics/');
