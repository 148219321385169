// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SelectEntities from '../../../components/select-entities';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {ENTITY_TYPES, NEW_ID, ROLES} from '../../../constants/spec';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {BUSINESS_UNITS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
    }, []);
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={BUSINESS_UNITS_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                                    <span>
                                        <RichHintTitle
                                            update={EDIT}
                                            name={isNew ? translate('BUSINESS_UNITS$CREATE_UNIT') : translate('BUSINESS_UNITS$EDIT_UNIT')}
                                            data={findHint(hints, 'BUSINESS_UNITS_TITLE')}
                                        />
                                    </span>
                            <Preloader expectAnswer={expectAnswer} type="ICON"/>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );

};

export default connect(
    state => ({expectAnswer: state.businessUnits.edit.expectAnswer, hints: state.businessUnits.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.businessUnits.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.businessUnits.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.businessUnits.edit.data,
        disabled: state.businessUnits.edit.expectAnswer,
        currentId: state.businessUnits.edit.data.id || null,
        hints: state.businessUnits.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editBusinessUnit',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        if (!values.description) {
            errors.description = 'BUSINESS_UNITS$DESCRIPTION_REQUIRED';
        }
        /*
        if (!values.parent) {
            errors.parent = 'BUSINESS_UNITS$PARENT_UNIT_REQUIRED';
        }
        if (!values.owner) {
            errors.owner = 'GLOBALS$CONTACT_REQUIRED';
        }
        if (!values.infosecFocalPerson) {
            errors.infosecFocalPerson = 'GLOBALS$INFOSEC_FOCAL_PERSON_REQUIRED';
        }
        */
        return errors;
    }
})(({handleSubmit, hints, invalid, pristine, disabled, update, reset, isNew, currentId, cancel}) => (
    <form autoComplete="off" name="editBusinessUnit" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('BUSINESS_UNITS$NAME')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('BUSINESS_UNITS$NAME')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('BUSINESS_UNITS$DESCRIPTION')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('BUSINESS_UNITS$DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    name="parent"
                    disabled={disabled}
                    type={ENTITY_TYPES.BUSINESS_UNITS}
                    selected={!currentId ? [] : [currentId]}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('BUSINESS_UNITS$PARENT_UNIT')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    disabled={disabled}
                    name="subsidiaryOrganization"
                    type={ENTITY_TYPES.SUBSIDIARIES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('SUBSIDIARY_ORGANIZATIONS$SUBSIDIARY')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} className="offset-bottom-4">
                <SelectEntities
                    name="owner"
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    additionalFilters={{roles: [ROLES.BUO]}}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={option => (get(option, 'fullName') + ' <' + get(option, 'email') + '>')}
                    label={(<strong> {translate('GLOBALS$OWNER')} </strong>)}
                />
            </Col>
            <Col xs={12} className="offset-bottom-4">
                <SelectEntities
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    name="infosecFocalPerson"
                    additionalFilters={{roles: [ROLES.INFOSEC]}}
                    getOptionLabel={option => (get(option, 'fullName') + ' <' + get(option, 'email') + '>')}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('GLOBALS$INFOSEC_FOCAL_PERSON')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    name="ownedSystems"
                    disabled={disabled}
                    type={ENTITY_TYPES.SYSTEMS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('PROCESSES$SYSTEMS_OWNED')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} md={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="ownedProcesses"
                    type={ENTITY_TYPES.PROCESSES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('BUSINESS_UNITS$OWNED_PROCESSES')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} md={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="usedProcesses"
                    type={ENTITY_TYPES.PROCESSES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('BUSINESS_UNITS$USED_PROCESSES')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_BUSINESS_UNITS_CREATE' : 'BUTTON_BUSINESS_UNITS_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"
                          hint={findHint(hints, 'BUTTON_BUSINESS_UNITS_RESET')} />
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_BUSINESS_UNITS_CANCEL')} />
            </Col>
        </Row>
    </form>
))));
