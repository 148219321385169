// outsource dependencies
import {call, put, select, take, takeEvery} from "redux-saga/effects";
import {hide} from 'redux-modal'
import filter from 'lodash/filter';
import get from 'lodash/get';

// local dependencies
import {LIST} from "../actions";
import {instanceAPI} from "../../../services/api.service";
import CONFIRM_DIALOG from "../../../components/confirmation-dialog/actions";
import {translate} from "../../../services/translate.service";
import getHintsList, {DOMAINS_LIST_HINTS} from '../../../services/hints.service';

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(LIST.INITIALIZE, initializeSaga);
    yield takeEvery(LIST.DELETE_ITEM, deleteItemSaga);
    yield takeEvery(LIST.GET_LIST.REQUEST, getListSaga);
    yield takeEvery(LIST.GET_MODAL_LIST, getModalListSaga);
    yield takeEvery(LIST.ADD_DOMAIN, addDomainSaga);
    yield takeEvery(LIST.UPDATE_ITEM.REQUEST, updateDomainSaga);
    yield takeEvery(LIST.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga () {
    yield put({type: LIST.CLEAR});
    yield put({type: LIST.META, initialized: true});
    yield put({type: LIST.GET_LIST.REQUEST});
    yield put({type: LIST.GET_HINTS_DATA.REQUEST});
    yield take(LIST.GET_LIST.FINISH);
    yield put({type: LIST.META, initialized: false});
}

function* getListSaga () {
    try {
        // get riskModel for api request
        let { riskModel } = yield select( state => state.app );
        let list = yield call( getList, riskModel );
        yield put({type: LIST.LIST, list});
    } catch ( {message} ) {
        yield put({type: LIST.META, errorMessage: message});
    }
    yield put({type: LIST.GET_LIST.FINISH});
}

function* getModalListSaga () {
    yield put({type: LIST.META, modalLoading: true, errorMessage: null });
    try {
        let fullList = yield call(getFullList);
        let { list } = yield select( state => state.domains.list );
        //NOTE get id of domains of current risk model to future comparisons
        let selectedIds = list.map(i => get(i, 'riskDomainView.id', null));
        //NOTE get list of domains which aren't present in current risk model yet
        let modalList = filter(fullList, ({id}) => selectedIds.indexOf(id) === -1 );
        yield put({type: LIST.MODAL_LIST, modalList });
        yield put({type: LIST.META, modalLoading: false });
    } catch ( {message} ) {
        yield put(hide('add-domain-modal'));
        yield put({type: LIST.META, errorMessage: message, modalLoading: false });
    }
}

function* updateDomainSaga ( {type, ...options} ) {
    yield put({type: LIST.EDIT_META, expectAnswer: true });
    try {
        // get data for api request
        let { riskModel, user } = yield select(state => state.app);
        // request for update
        let item = yield call(createDomain, options, riskModel, user );
        //update item
        yield put({type: LIST.ITEM, item});
        // update list
        let list = yield call( getList, riskModel );
        yield put({type: LIST.LIST, list});
        yield put({type: LIST.EDIT_META, expectAnswer: false });
        yield put(hide('add-domain-modal'));
    }
    catch ({message}) {
        yield put({type: LIST.EDIT_META, expectAnswer: false, errorMessage: message});
    }
    yield put({type: LIST.UPDATE_ITEM.FINISH});
}

function* addDomainSaga ( { type, ...options } ) {
    yield put({type: LIST.META, expectAnswer: true, errorMessage: null });
    try {
        // get riskModelId and user data for api request
        let { riskModel, user } = yield select( state => state.app );
        console.log(riskModel, user,90);
        // add domain to risk model domains
        yield call( addDomain, options, riskModel, user );
        // update list
        yield put({type: LIST.GET_LIST.REQUEST});
        yield take(LIST.GET_LIST.FINISH);
        yield put({type: LIST.META, expectAnswer: false });
    } catch ( {message} ) {
        yield put({type: LIST.META, errorMessage: message, expectAnswer: false });
    }
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, DOMAINS_LIST_HINTS);
        // NOTE setup hints data
        yield put({type: LIST.META, hintsData});
    } catch ( {message} ) {
        yield put({type: LIST.META, errorMessage: message});
    }
    yield put({type: LIST.GET_HINTS_DATA.FINISH});
}

function* deleteItemSaga ({type, ...options}) {
    // NOTE ask confirmation of deleting
    yield put({type: CONFIRM_DIALOG.REQUEST, message: translate('GLOBALS$DELETION_CONFIRMATION')});
    let answer = yield take([CONFIRM_DIALOG.SUCCESS, CONFIRM_DIALOG.ERROR]);
    // NOTE do nothing if confirmation dismiss
    if ( answer.type === CONFIRM_DIALOG.ERROR ) return;
    yield put({type: LIST.META, expectAnswer: true});
    try {
        let {riskModel} = yield select(state => state.app);
        yield call(deleteItem, riskModel, options);
        // update list
        yield put({type: LIST.GET_LIST.REQUEST});
        yield take(LIST.GET_LIST.FINISH);
        yield put({type: LIST.META, expectAnswer: false});
    } catch ( {message} ) {
        yield put({type: LIST.META, errorMessage: message, expectAnswer: false});
    }
}

/**
 * get list of domains of current risk model
 * @param {Object} riskModel
 * @private
 */
function getList( riskModel ) {
    return instanceAPI({method: 'get', url: `/risk-models/${riskModel.id}/domains`});
}

/**
 * delete item
 * @param {Object} data
 * @private
 */
function deleteItem(riskModel, data) {
    return instanceAPI({method: 'delete', url: `/risk-models/${riskModel.id}/domains`, data});
}

/**
 * get full list of domains
 *
 * @private
 */
function getFullList() {
    return instanceAPI({method: 'get', url: `/risk-domains`});
}

/**
 * add domain to current risk model domains
 * @param {Object} domain
 * @param {Object} riskModel
 * @param {Object} user
 * @private
 */
function addDomain ( domain, riskModel, user ) {
    return instanceAPI({
        method: 'post',
        url: `/risk-models/${riskModel.id}/domains`,
        data: {
            riskDomainId: domain.id,
            riskManagementOwnerUserId: user.id
        }
    });
}

/**
 * create domain
 * @param {Object} domain
 * @param {Object} riskModel
 * @param {Object} user
 * @private
 */
function createDomain( domain, riskModel, user ) {
    return instanceAPI({
        method: 'POST',
        url: `/risk-models/${riskModel.id}/domains`,
        data: {
            ...domain,
            riskDomainId: null,
            riskManagementOwnerUserId: user.id
        }
    });
}
