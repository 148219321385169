
// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const MODELS_MODAL = (function ( prefix ) {
    return {
        // simple
        CLEAR: `${prefix}CLEAR`,
        META: `${prefix}META`,
        LIST: `${prefix}LIST`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        SHOW_NEW: `${prefix}SHOW_NEW`,
        CLEAR_NEW: `${prefix}CLEAR_NEW`,
        CREATE_NEW: `${prefix}CREATE_NEW`,
        TOGGLE_CARD: `${prefix}TOGGLE_CARD`,
        UPDATE_MODEL: `${prefix}UPDATE_DATA`,
        GET_LIST: createTypes(`${prefix}GET_LIST`),
        CLOSE_ALL: createTypes(`${prefix}CLOSE_ALL`),
    };
})('@models-modal/');
