// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SelectEntities from '../../../components/select-entities';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {ENTITY_TYPES, NEW_ID, ROLES} from '../../../constants/spec';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {PROCESSES_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import separateService from '../../../services/separate-with-comma.service';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, []);
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={PROCESSES_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                            <RichHintTitle update={EDIT}
                                           name={isNew ? 'PROCESSES$CREATE_PROCESS' : 'PROCESSES$EDIT_PROCESS'}
                                           expectAnswer={expectAnswer}
                                           data={findHint(hints, 'PROCESSES_TITLE')}
                            />
                            <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};

export default connect(
    state => ({expectAnswer: state.processes.edit.expectAnswer, hints: state.processes.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.processes.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.processes.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.processes.edit.data,
        disabled: state.processes.edit.expectAnswer,
        hints: state.processes.edit.hintsData,
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editProcess',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // Name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // Description
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        // Business Unit
        if (!values.businessUnit) {
            errors.businessUnit = 'BUSINESS_UNITS$BUSINESS_UNIT_REQUIRED';
        }
        // Data Type Classifications
        if (!values.dataTypeClassifications) {
            errors.dataTypeClassifications = 'DATA_CLASSES$REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, hints, invalid, pristine, disabled, update, reset, isNew, cancel}) => (
    <form autoComplete="off" name="editProcess" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$DESCRIPTION')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} md={6}>
                <SelectEntities
                    name="owner"
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    additionalFilters={{roles: [ROLES.PRCOWN, ROLES.CEO, ROLES.CRO, ROLES.CISO, ROLES.ORGADMIN]}}
                    getOptionLabel={option => get(option, 'fullName')}
                    label={(<strong> {translate('GLOBALS$OWNER')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    type="text"
                    component={MdInput}
                    disabled={disabled}
                    name="revenueProcessed"
                    placeholder={translate('PROCESSES$REVENUE_PROCESSED')}
                    label={(<strong> {translate('PROCESSES$REVENUE_PROCESSED')} </strong>)}
                    normalize={separateService.normalize}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    name="businessUnit"
                    disabled={disabled}
                    type={ENTITY_TYPES.BUSINESS_UNITS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    label={(
                        <strong className="required-asterisk"> {translate('PROCESSES$BUSINESS_UNIT_OWNS')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    name="businessUnits"
                    disabled={disabled}
                    type={ENTITY_TYPES.BUSINESS_UNITS}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('PROCESSES$USES_BUSINESS_UNITS')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col md={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="dataTypeClassifications"
                    type={ENTITY_TYPES.DATA_TYPE_CLASSES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong className="required-asterisk"> {translate('DATA_CLASSES$TITLE')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col md={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="dataAssetClassifications"
                    type={ENTITY_TYPES.ASSET_CLASSES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('ASSET_CLASSES$ASSET_CLASS')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    name="systems"
                    disabled={disabled}
                    type={ENTITY_TYPES.SYSTEMS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('PROCESSES$SYSTEMS_OWNED')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="notes"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NOTES')}
                    label={(<strong> {translate('GLOBALS$NOTES')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn
                    isNew={isNew}
                    disabled={pristine || invalid || disabled}
                    className="offset-right-2"
                    hint={findHint(hints, isNew ? 'BUTTON_PROCESSES_CREATE' : 'BUTTON_PROCESSES_SAVE')}/>
                <ResetBtn
                    onClick={reset}
                    disabled={pristine || disabled} className="offset-right-2"
                    hint={findHint(hints, 'BUTTON_PROCESSES_RESET')}/>
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_PROCESSES_CANCEL')}/>
            </Col>
        </Row>
    </form>
))));
