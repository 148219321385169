
// outsource dependencies
import { combineReducers } from 'redux';

// local dependencies

import mapping from './mapping/reducer';
import settings from './settings/reducer';

export default combineReducers({
    mapping,
    settings
});
