// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import {NEW_ID} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {SYSTEM_RISK_REDUCTION_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

class Edit extends Component {
    componentDidMount() { this.props.initialize( this.props.match.params.id ); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { disabled, message, clearError, match } = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ SYSTEM_RISK_REDUCTION_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span:8,offset:2}}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    { isNew ? <span>Create reduction</span> : <span>Edit reduction</span> }
                                    <Preloader expectAnswer={disabled} type="ICON" />
                                </h2>
                                <ErrorMessage active message={message} onChange={clearError}/>
                                {/*NOTE dummy for 'reduction' edit page if requirement would be changed*/}
                                {/*<ConnectedForm isNew={isNew} />*/}
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        message: state.systemRiskReduction.edit.errorMessage,
        disabled: state.systemRiskReduction.edit.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({ type: EDIT.META, errorMessage: null})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.systemRiskReduction.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));
