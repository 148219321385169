// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Fragment, useEffect} from 'react';
import {Paper} from '@mui/material';
import {change, Field, FieldArray, getFormValues, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import {MdSelect} from '../../../components/md-select';
import MdSwitch from '../../../components/md-switch';
import ErrorMessage from '../../../components/alert-error';
import SelectEntities from '../../../components/select-entities';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {DEPLOYMENT_TYPES, ENTITY_TYPES, NEW_ID, ROLES, STATUS, SYSTEM_TYPES} from '../../../constants/spec';
import {AddIconBtn, CancelBtn, DeleteIconBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel, formatTechnologyLabel} from '../../../services/data-formatting.service';
import separateService from '../../../services/separate-with-comma.service';
import {SYSTEMS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";
import {useParams} from "react-router-dom";

const FORM_NAME = 'editSystem';

export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={SYSTEMS_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:8,offset:2}}>
                        <Paper className="indent-5">
                            <RichHintTitle update={EDIT} name={isNew ? `SYSTEMS$CREATE_SYSTEM` : `SYSTEMS$EDIT_SYSTEM`} expectAnswer={expectAnswer} data={findHint(hints,'SYSTEMS_TITLE')}/>

                            {/*<h2 className="text-uppercase">*/}
                                {/*<span>*/}
                                    {/*{isNew*/}
                                        {/*? translate('SYSTEMS$CREATE_SYSTEM')*/}
                                        {/*: translate('SYSTEMS$EDIT_SYSTEM')*/}
                                    {/*}*/}
                                {/*</span>*/}
                                {/*<Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>*/}
                            {/*</h2>*/}
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({expectAnswer: state.systems.edit.expectAnswer, hints: state.systems.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.systems.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.systems.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.systems.edit.data,
        disabled: state.systems.edit.expectAnswer,
        hints: state.systems.edit.hintsData,
        formValues: getFormValues(FORM_NAME)(state),
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // description
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        // status
        if (!values.systemStatus) {
            errors.systemStatus = 'GLOBALS$STATUS_REQUIRED';
        }
        // data classification
        if (!values.dataTypeClassifications || !values.dataTypeClassifications.length) {
            errors.dataTypeClassifications = 'DATA_CLASSES$REQUIRED';
        }
        // business Unit
        if (!values.businessUnit) {
            errors.businessUnit = 'BUSINESS_UNITS$BUSINESS_UNIT_REQUIRED';
        }
        // data class
        if (!values.dataAssetClassification) {
            errors.dataAssetClassification = 'ASSET_CLASSES$REQUIRED';
        }
        // owner
        if (!values.owner) {
            errors.owner = 'SYSTEMS$SYSTEM_OWNER_NAME_REQUIRED';
        }
        // deploymentType
        if (!values.deploymentType || values.deploymentType === 'NONE') {
            errors.deploymentType = 'SYSTEMS$DEPLOYMENT_TYPE_REQUIRED';
        }
        // systemType
        if (!values.systemType || values.systemType === 'NONE') {
            errors.systemType = 'SYSTEMS$SYSTEM_TYPE_REQUIRED';
        }

        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formValues = {}, hints}) => (
    <form autoComplete="off" name="editSystem" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('SYSTEMS$SYSTEM_NAME')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('SYSTEMS$SYSTEM_NAME')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('SYSTEMS$SYSTEM_DESCRIPTION')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('SYSTEMS$SYSTEM_DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={4} className="offset-bottom-2">
                <Field
                    labelKey="label"
                    valueKey="value"
                    name="deploymentType"
                    disabled={disabled}
                    component={MdSelect}
                    sendValueProp
                    placeholder={translate('SYSTEMS$DEPLOYMENT_TYPE')}
                    options={['ON_PREMISE', 'CLOUD', 'HYBRID'].map(option=>{
                        return ({
                            label: translate(DEPLOYMENT_TYPES[option]) ,
                            value: option
                        })
                    })}
                    optionsLabel={({value}) => translate(DEPLOYMENT_TYPES[value]) }
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('SYSTEMS$DEPLOYMENT_TYPE')} </strong>)}
                    simpleValue={(value) => { return {value: value, label: value && DEPLOYMENT_TYPES[value] ? translate(DEPLOYMENT_TYPES[value]) : '' } }}
                />
            </Col>
            <Col xs={12} md={4} className="offset-bottom-2">
                <Field
                    labelKey="label"
                    valueKey="value"
                    name="systemType"
                    disabled={disabled}
                    component={MdSelect}
                    placeholder={translate('SYSTEMS$SYSTEM_TYPE')}
                    sendValueProp
                    options={['NONE', 'COTS', 'CUSTOM_COTS', 'HOME_GROWN', 'MOBILE'].map(option=>{
                        return ({
                            label: translate(SYSTEM_TYPES[option]) ,
                            value: option
                        })
                    })}
                    optionsLabel={({value}) => translate(SYSTEM_TYPES[value]) }
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('SYSTEMS$SYSTEM_TYPE')} </strong>)}
                    simpleValue={(value) => { return {value: value, label: value && SYSTEM_TYPES[value] ? translate(SYSTEM_TYPES[value]) : '' } }}
                />
            </Col>
            <Col xs={12} md={4} className="offset-bottom-2">
                <Field
                    fullWidth={false}
                    component={MdSwitch}
                    name="isMAAsset"
                    disabled={disabled}
                    label={(<strong> {translate('SYSTEMS$M_AND_A_ASSET')} </strong>)}
                />
            </Col>
        </Row>

        <Row className="offset-bottom-4">
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    required
                    labelKey="label"
                    valueKey="value"
                    name="systemStatus"
                    disabled={disabled}
                    component={MdSelect}
                    sendValueProp
                    placeholder={translate('GLOBALS$STATUS')}
                    options={[STATUS.ACTIVE, STATUS.DISABLED, STATUS.PENDING].map(option=>{
                        return ({
                            label: option ,
                            value: option
                        })
                    })}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$STATUS')} </strong>)}
                    optionsLabel={({value}) => value}
                    simpleValue={(value) => { return {value: value, label: value} }}

                />
            </Col>

            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    type="text"
                    component={MdInput}
                    disabled={disabled}
                    name="costToRestore"
                    placeholder={translate('SYSTEMS$COST_TO_RESTORE')}
                    label={(<strong> {translate('SYSTEMS$COST_TO_RESTORE')} </strong>)}
                    normalize={separateService.normalize}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12} md={6}>
                <Field
                    name="rto"
                    type="number"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('SYSTEMS$RTO')}
                    label={(<strong> {translate('SYSTEMS$RTO')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6}>
                <Field
                    name="rpo"
                    type="number"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('SYSTEMS$RPO')}
                    label={(<strong> {translate('SYSTEMS$RPO')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="dataDomains"
                    type={ENTITY_TYPES.DATA_DOMAINS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={item => formatTechnologyLabel(item)}
                    label={(<strong> {translate('DATA_DOMAINS$TITLE')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="technologies"
                    type={ENTITY_TYPES.TECHNOLOGIES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={item => formatTechnologyLabel(item)}
                    label={(<strong> {translate('SYSTEMS$TECHNOLOGIES')} </strong>)}
                />
            </Col>
        </Row>

        {
            isAssociateVendorsVisible(formValues.systemType) ?
                (
                    <Row className="offset-bottom-4">
                        <Col xs={12} md={12} className="offset-bottom-4">
                            <SelectEntities
                                isMulti={true}
                                disabled={disabled}
                                name="associateVendors"
                                type={ENTITY_TYPES.VENDORS}
                                placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                                getOptionLabel={item => item.name}
                                label={(<strong> {translate('VENDORS$TITLE')} </strong>)}
                            />
                        </Col>
                    </Row>
                )
                : ""
        }

        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="dataTypeClassifications"
                    type={ENTITY_TYPES.DATA_TYPE_CLASSES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('DATA_CLASSES$TITLE')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    disabled={disabled}
                    name="processes"
                    type={ENTITY_TYPES.PROCESSES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('PROCESSES$TITLE')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    name="businessUnit"
                    disabled={disabled}
                    type={ENTITY_TYPES.BUSINESS_UNITS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('BUSINESS_UNITS$BUSINESS_UNIT')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    disabled={disabled}
                    name="dataAssetClassification"
                    type={ENTITY_TYPES.ASSET_CLASSES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('ASSET_CLASSES$ASSET_CLASS')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    name="owner"
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    additionalFilters={{roles: [ROLES.SYSOWN]}}
                    getOptionLabel={option => get(option, 'fullName')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('SYSTEMS$SYSTEM_OWNER_NAME')} </strong>)}
                />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    disabled={disabled}
                    type={ENTITY_TYPES.USERS}
                    name="infosecFocalPerson"
                    additionalFilters={{roles: [ROLES.INFOSEC]}}
                    getOptionLabel={option => get(option, 'fullName')}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('GLOBALS$INFOSEC_FOCAL_PERSON')} </strong>)}
                />
            </Col>
        </Row>

        <Row>
            <Col xs={12}>
                <Field
                    type="text"
                    component={MdInput}
                    disabled={disabled}
                    name="numberOfRecProcessed"hint = {findHint(hints, 'BUTTON_SYSTEMS_RESET')}
                    placeholder={translate('SYSTEMS$RECORDS_PROCESSED')}
                    label={(<strong> {translate('SYSTEMS$RECORDS_PROCESSED')} </strong>)}
                    normalize={separateService.normalize}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4"> <Col xs={12}>
            <FieldArray name="geoRecordsProcessed" rerenderOnEveryChange={true}
                        component={ConnectedGeoRecordsProcessed}/>
        </Col> </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_SYSTEMS_CREATE' : 'BUTTON_SYSTEMS_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2" hint = {findHint(hints, 'BUTTON_SYSTEMS_RESET')} />
                <CancelBtn onClick={cancel} hint = {findHint(hints, 'BUTTON_SYSTEMS_CANCEL')} />
            </Col>
        </Row>
    </form>
))));

/**
 *
 * @param { Object } props
 * @public
 */
const ConnectedGeoRecordsProcessed = connect(
    state => ({
        states: state.systems.edit.states,
        values: state.form[FORM_NAME].values,
        disabled: state.systems.edit.expectAnswer,
        hints: state.systems.edit.hintsData
    }),
    dispatch => ({
        setupCountry: countryId => dispatch({type: EDIT.SETUP_COUNTRY.REQUEST, countryId}),
        // setupState: (countryId, stateId) => dispatch({type: EDIT.SETUP_STATE, countryId, stateId}),
    }),
)(({fields, meta, disabled, values, states, hints, setupCountry}) => (<Fragment>
    <Row> <Col className="align-middle top-indent-4" xs={12}>
        <RichHintTitle placement="top" update={EDIT} name={`SYSTEMS$GEO_RECORDS_PROCESSED`} expectAnswer={disabled} data={findHint(hints, 'GEO_RECORDS_PROCESSED_TITLE')}/>
        <AddIconBtn data={findHint(hints, 'GEO_RECORDS_PROCESSED_ADD')} onClick={() => fields.push({})}/>

        {/*<h3 className="text-uppercase">*/}
            {/*<span className="align-middle"> {translate('SYSTEMS$GEO_RECORDS_PROCESSED')} </span>&nbsp;*/}
            {/*<AddIconBtn onClick={() => fields.push({})}/>*/}
        {/*</h3>*/}
    </Col> </Row>
    {meta.error && (<Row> <Col xs={{span:10,offset:1}} className="text-center is-invalid">
        <label className="form-text h4"> {translate(meta.error)} </label>
    </Col> </Row>)}
    {fields.map((mKey, index) => (
        <Row key={index} className="offset-bottom-6">
            <Col xs={12} md={4}>
                <SelectEntities
                    name={`${mKey}.country`}
                    disabled={disabled}
                    type={ENTITY_TYPES.COUNTRIES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    onChange={(e) => setupCountry(get(e, 'id', null))}
                    label={(<strong> {translate('ORGANIZATION$COUNTRY')} </strong>)}
                />
            </Col>
            <Col xs={12} md={4}>
                <SelectEntities
                    name={`${mKey}.state`}
                    defaultOptions={states}
                    type={ENTITY_TYPES.STATES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('ORGANIZATION$STATE')} </strong>)}
                    disabled={disabled || !get(values, `${mKey}.country.id`, null)}
                    additionalFilters={{countryId: get(values, `${mKey}.country.id`, null)}}
                />
            </Col>
            <Col xs={12} md={4} className="row-adornment">
                <Field
                    type="text"
                    component={MdInput}
                    disabled={disabled || !get(values, `${mKey}.country.id`, null)}
                    name={`${mKey}.numberOfRecProcessed`}
                    placeholder={translate('SYSTEMS$RECORDS_PROCESSED')}
                    label={(<strong> {translate('SYSTEMS$RECORDS_PROCESSED')} </strong>)}
                    normalize={separateService.normalize}
                />
                <div className="adornment"><DeleteIconBtn onClick={() => fields.remove(index)}/></div>
            </Col>
        </Row>

    ))}
</Fragment>));

function isAssociateVendorsVisible(systemType) {
    return (!systemType || systemType === 'NONE' || systemType === 'COTS' || systemType === 'CUSTOM_COTS');
}
