// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import {Container} from 'react-bootstrap';
import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {withStyles} from '@mui/styles'

// local dependencies
import ACTIONS from './actions';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {translate} from '../../services/translate.service';


// configuration

/**
 * AgreementModal for managing organization agreements for users
 * @public
 */
class AgreementAlert extends Component {
    componentDidMount () {
        this.props.initialize()
    }

    render () {
        let { data, showModal, currentIndex, accept, decline, initialized, errorMessage, clearError, expectAnswer } = this.props;
        let { classes } = this.props;
        let item = data[currentIndex] || {};
        return (
            <Dialog
                open={showModal}
                onClose={(event, reason) => {
                    if(reason === "backdropClick" && reason === "escapeKeyDown") return false;
                    decline();
                }}
                classes={{paper: classes.paper}}
                aria-labelledby="form-dialog-title"
                    >
                <Preloader expectAnswer={!initialized} type="MIN_HEIGHT" height={200}>
                    { errorMessage && (<div className="top-indent-3">
                        <Container fluid> <ErrorMessage active message={errorMessage} onChange={clearError} /> </Container>
                    </div>)}
                    { get(item, 'organizationAgreement.name') && (
                        <DialogTitle id="form-dialog-title">
                            <span>{get(item, 'organizationAgreement.name')}</span>
                            <Preloader expectAnswer={expectAnswer} type="ICON" />
                        </DialogTitle>
                    )}
                    { get(item, 'organizationAgreement.content') && (
                        <DialogContent style={{whiteSpace: 'pre-wrap'}}>
                            {get(item, 'organizationAgreement.content')}
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button color="primary" onClick={accept} disabled={expectAnswer}>{translate('GLOBALS$AGREE')}</Button>
                        <Button color="primary" onClick={decline} disabled={expectAnswer}>{translate('GLOBALS$DISAGREE')}</Button>
                    </DialogActions>
                </Preloader>
            </Dialog>

        );
    }
}

export default connect(
    state => ({...state.agreementAlert}),
    dispatch => ({
        accept: () => dispatch({type: ACTIONS.ACCEPT}),
        decline: () => dispatch({type: ACTIONS.DECLINE}),
        initialize: () => dispatch({type: ACTIONS.INITIALIZE}),
        clearError: () => dispatch({ type: ACTIONS.META, errorMessage: null }),
    }),
)(withStyles({paper: { minWidth: 300 }})(AgreementAlert));
