// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Avatar,
    ListItemText, ListItemAvatar, List, ListItem, ListItemIcon, ListItemButton
} from '@mui/material';
import {withStyles} from '@mui/styles';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import moment from 'moment';
import _ from 'lodash';
import {Col} from 'react-bootstrap';

// local dependencies
import PREVIEW_DIALOG from './actions';
import {CancelBtn} from '../md-button';
import {withTranslation} from '../../services/translate.service';
import {config} from '../../constants';
import {TasksIcon} from '../../images/dashboard-icons';


export default withTranslation(connect(
    state => ({
        ...state.previewDialog,
        loaded: state.previewDialog.initialized,
        initialValues: state.previewDialog.data,
        title: state.previewDialog.title,
    }),
    dispatch => ({
        cancel: () => dispatch({type: PREVIEW_DIALOG.CANCEL}),
        clearError: () => dispatch({type: PREVIEW_DIALOG.META, errorMessage: null}),
    })
)(reduxForm({
    form: 'previewForm',
    enableReinitialize: true,
})(withStyles({paper: {minWidth: '500px', overflow: 'visible'}})(
    ({expectAnswer, showModal, cancel, title, classes, initialValues}) => {
        let messageLines = [];
        //messageLines.shift();
        const keys = Object.keys(initialValues);
        keys.forEach(key => {
            if (itemsToIgnore.includes(key)) {
            } else if (dateItems.includes(key)) {
                let date = initialValues[key] && moment.utc(moment(initialValues[key])).format(config.clientTimeFormat);
                messageLines.push({itemName: fromItem(key), itemValue: date});
            } else if (userItems.includes(key)) {
                messageLines.push({
                    itemName: fromItem(key),
                    itemValue: `${initialValues[key].fullName}  ${initialValues[key].email}`
                });
            } else if (initialValues[key] && objectWithNameItems.includes(key)) {
                messageLines.push({itemName: `${fromItem(key)}`, itemValue: `${initialValues[key].name}`});
            } else if (initialValues[key] && ['documents'].includes(key)) {
                Object.keys(initialValues[key]).forEach(docKey => {
                    messageLines.push({
                        itemName: `${fromItem(key)}`,
                        itemValue: `${initialValues[key][docKey].fileName}`
                    });
                });
            } else {
                messageLines.push({itemName: `${fromItem(key)}`, itemValue: `${initialValues[key]}`});
            }
        });
        return (
            <ShowPreviewDialog initialValues={initialValues} messageLines={messageLines} classes={classes}
                               showModal={showModal} cancel={cancel} title={title} expectAnswer={expectAnswer}/>

        );
    }
))));

const itemsToIgnore = ['id', 'metadata', 'taskAssigneeDetails'];
const dateItems = ['estimatedEndDate', 'estimatedStartDate', 'createdAt', 'updatedAt', 'actualStartDate', 'actualStartDate'];
const userItems = ['messageFrom', 'messageTo', 'taskAssignee', 'taskManager'];
const objectWithNameItems = ['project', 'businessUnit', 'taskCategory'];

function fromItem(itemName) {
    let itemStringMap = new Map([
        ['messageFrom', 'Message from:'],
        ['messageTo', 'Message to:'],
        ['createdAt', 'Created at:'],
        ['updatedAt', 'Updated at:'],
        ['subject', 'Subject:'],
        ['body', 'Message Body:'],
        ['messageStatus', 'Message Status:'],
        ['documents', 'Attached Document:'],
        ['priority', 'Priority:'],
        ['businessUnit', 'Business Unit:'],
        ['name', 'Task Name:'],
        ['taskNotes', 'Task Notes:'],
        ['status', 'Task Status:'],
        ['estimatedHours', 'Estimated Hours:'],
        ['actualHours', 'Actual Hours:'],
        ['estimatedStartDate', 'Estimated Start Date:'],
        ['estimatedEndDate', 'Estimated End Date:'],
        ['actualStartDate', 'Actual Start Date:'],
        ['actualEndDate', 'Actual End Date:'],
        ['taskManager', 'Task Manager:'],
        ['taskAssignee', 'Task Assignee:'],
        ['project', 'Project:'],
        ['taskCategory', 'Task Category:']
    ]);

    if (itemStringMap.has(itemName)) {
        return itemStringMap.get(itemName);
    } else {
        return itemName;
    }
}

const ShowPreviewDialog = ({expectAnswer, showModal, cancel, title, classes, initialValues, messageLines}) => {
    switch (title) {
        default:
            return <Dialog
                open={showModal}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' && reason === 'escapeKeyDown') {
                        return false;
                    }
                    cancel();
                }}
                transitionDuration={{exit: 0}}
                maxWidth={'md'}
                classes={{paper: classes.paper}}
                aria-labelledby="form-dialog-title"
            >
                {title ? (
                    <DialogTitle className="col-xs-12 text-center" id="form-dialog-title"> {title} </DialogTitle>) : ''}
                <DialogContent style={{overflow: 'visible'}}>

                    <DialogContentText>
                        {
                            messageLines.map((item, key) => {
                                return <div key={key} style={{padding: '5px', fontSize: '17px'}}>

                                    <div key={item.key} style={{
                                        fontWeight: 'bold',
                                        color: 'darkslateblue',
                                        padding: '0px',
                                    }}>{item.itemName ? item.itemName : <br/>}</div>
                                    <div key={item.itemName}
                                         style={{padding: '0px'}}>{(item.itemValue || item.itemValue === ' ') ? item.itemValue :
                                        <br/>}</div>
                                </div>;
                            })
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CancelBtn onClick={cancel} disabled={expectAnswer}/>
                </DialogActions>
            </Dialog>;

        case 'Message Details':
            return <Dialog
                open={showModal}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' && reason === 'escapeKeyDown') {
                        return false;
                    }
                    cancel();
                }}
                transitionDuration={{exit: 0}}
                maxWidth={'md'}
                classes={{paper: classes.paper}}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle className="col-xs-12 text-center" id="form-dialog-title"> {title} </DialogTitle>
                <DialogContent style={{overflow: 'visible'}}>

                    <List className="list-divider list-messages">
                        <ListItem divider>
                            <ListItemIcon>
                                <DraftsOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText title={'Message Subject'} style={{fontWeight: 'bold'}}
                                          primary={_.get(initialValues, 'subject')}
                                          primaryTypographyProps={{fontSize: '20px'}}
                            />
                        </ListItem>
                        <ListItem title={'Message Sender'}>
                            <ListItemAvatar>
                                <Avatar width="35" height="35"
                                        src={initialValues && initialValues.messageFrom && initialValues.messageFrom.photo && initialValues.messageFrom.photo.url ? initialValues.messageFrom.photo.url : ''}
                                        alt={initialValues && initialValues.messageFrom ? initialValues.messageFrom.fullName : ''}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={_.get(initialValues, 'messageFrom.fullName')}
                                secondary={_.get(initialValues, 'messageFrom.email')}
                                secondaryTypographyProps={{
                                    sx: {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '2',
                                        WebkitBoxOrient: 'vertical',
                                    }
                                }}
                            />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                style={{textAlign: 'right'}}
                                secondary={`Sent: ${moment.utc(moment(_.get(initialValues, 'createdAt'))).format(config.clientTimeFormat)}`}
                                secondaryTypographyProps={{
                                    sx: {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '2',
                                        WebkitBoxOrient: 'vertical',
                                    }
                                }}
                            />
                        </ListItem>
                        <ListItem divider title={'Message'}>
                            <p> {_.get(initialValues, 'body')} </p>
                        </ListItem>
                        {initialValues.documents && initialValues.documents.map((item, key) => {
                            return <ListItemButton key={key}
                                                   href={_.get(item, 'downloadUrl') ? _.get(item, 'downloadUrl') : ''}>
                                <ListItem title={'Attachment'}>
                                    <ListItemIcon>
                                        <AttachmentIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={_.get(item, 'fileName')}
                                        secondaryTypographyProps={{
                                            sx: {
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: '2',
                                                WebkitBoxOrient: 'vertical',
                                            }
                                        }}
                                    />
                                </ListItem>
                            </ListItemButton>;
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <CancelBtn onClick={cancel} disabled={expectAnswer}/>
                </DialogActions>
            </Dialog>;

        case 'Task Details':
            return <Dialog
                open={showModal}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' && reason === 'escapeKeyDown') {
                        return false;
                    }
                    cancel();
                }}
                transitionDuration={{exit: 0}}
                maxWidth={'md'}
                classes={{paper: classes.paper}}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle className="col-xs-12 text-center" id="form-dialog-title"> {title} </DialogTitle>
                <DialogContent style={{overflow: 'visible'}}>

                    <List className="list-divider list-messages">
                        <ListItem divider>
                            <ListItemIcon color={'primary'}>
                                <TasksIcon/>
                            </ListItemIcon>
                            <ListItemText style={{fontWeight: 'bold'}}
                                          primary={_.get(initialValues, 'name')}
                                          primaryTypographyProps={{fontSize: '20px'}}
                                          secondary={`Priority: ${_.get(initialValues, 'priority') !== null ? _.get(initialValues, 'priority') : '(not set)'}  
                                            ${'Status:'.padStart(20, '\u00A0')} ${_.get(initialValues, 'status') !== null ? _.get(initialValues, 'status') : '0'} %`}
                            />
                        </ListItem>
                        <ListItem>
                            <Col>
                                {_.get(initialValues, 'project') !== null ?
                                    <ListItemText secondary={`Project: ${_.get(initialValues, 'project.name')}`}/>
                                    : ''}
                            </Col>
                            <Col>
                                {_.get(initialValues, 'taskCategory') !== null ?
                                    <ListItemText
                                        secondary={`Task Category: ${_.get(initialValues, 'taskCategory.name')}`}/>
                                    : ''}
                            </Col>
                        </ListItem>
                        {(_.get(initialValues, 'project') || _.get(initialValues, 'taskCategory') || _.get(initialValues, 'businessUnit')) ? (
                        <ListItem divider>
                            {_.get(initialValues, 'businessUnit') !== null ?
                                <ListItemText
                                    secondary={`Business Unit: ${_.get(initialValues, 'businessUnit.name')}`}/>
                                : ''}

                        </ListItem>
                            ) : ''}
                        <ListItem title={'Task Manager'} divider>
                            <ListItemAvatar>
                                <Avatar width="35" height="35"
                                        src={initialValues && initialValues.taskManager && initialValues.taskManager.photo && initialValues.taskManager.photo.url ? initialValues.taskManager.photo.url : ''}
                                        alt={initialValues && initialValues.taskManager ? initialValues.taskManager.fullName : ''}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={_.get(initialValues, 'taskManager.fullName')}
                                secondary={_.get(initialValues, 'taskManager.email')}
                                secondaryTypographyProps={{
                                    sx: {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '2',
                                        WebkitBoxOrient: 'vertical',
                                    }
                                }}
                            />
                        </ListItem>
                        <ListItem divider title={'Task Notes'}>
                            <p> {_.get(initialValues, 'taskNotes')} </p>
                        </ListItem>
                        {(_.get(initialValues, 'estimatedHours') || _.get(initialValues, 'actualHours')) ? (
                            <ListItem divider>
                                <Col>
                                    <ListItemText
                                        style={{textAlign: 'left'}}
                                        primary={'Estimated Hours:'}
                                        primaryTypographyProps={{fontSize: '15px'}}
                                        secondary={`${_.get(initialValues, 'estimatedHours') ? _.get(initialValues, 'estimatedHours') : 'not specified'}`}
                                        secondaryTypographyProps={{
                                            sx: {
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: '2',
                                                WebkitBoxOrient: 'vertical',
                                            },
                                            fontSize: '17px'
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <ListItemText
                                        style={{textAlign: 'right'}}
                                        primary={'Actual Hours:'}
                                        primaryTypographyProps={{fontSize: '15px'}}
                                        secondary={`${_.get(initialValues, 'actualHours') ? _.get(initialValues, 'actualHours') : 'not finished'}`}
                                        secondaryTypographyProps={{
                                            sx: {
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: '2',
                                                WebkitBoxOrient: 'vertical',
                                            },
                                            fontSize: '17px'
                                        }}
                                    />
                                </Col>
                            </ListItem>
                        ) : ''}
                        <ListItem divider>
                            <Col>
                                <ListItemText
                                    style={{textAlign: 'left'}}
                                    primary={'Estimated Start Date:'}
                                    primaryTypographyProps={{fontSize: '15px'}}
                                    secondary={`${_.get(initialValues, 'estimatedStartDate') ? moment.utc(moment(_.get(initialValues, 'estimatedStartDate'))).format(config.clientTimeFormat) : 'not specified'}`}
                                    secondaryTypographyProps={{
                                        sx: {
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: '2',
                                            WebkitBoxOrient: 'vertical',
                                        },
                                        fontSize: '17px'
                                    }}
                                />
                            </Col>
                            <Col>
                                <ListItemText
                                    style={{textAlign: 'right'}}
                                    primary={'Estimated End Date:'}
                                    primaryTypographyProps={{fontSize: '15px'}}
                                    secondary={`${_.get(initialValues, 'estimatedEndDate') ? moment.utc(moment(_.get(initialValues, 'estimatedEndDate'))).format(config.clientTimeFormat) : 'not specified'}`}
                                    secondaryTypographyProps={{
                                        sx: {
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: '2',
                                            WebkitBoxOrient: 'vertical',
                                        },
                                        fontSize: '17px'
                                    }}
                                />
                            </Col>
                        </ListItem>
                        <ListItem divider>
                            <Col>
                                <ListItemText
                                    style={{textAlign: 'left'}}
                                    primary={'Actual Start Date:'}
                                    primaryTypographyProps={{fontSize: '15px'}}
                                    secondary={`${_.get(initialValues, 'actualStartDate') ? moment.utc(moment(_.get(initialValues, 'actualStartDate'))).format(config.clientTimeFormat) : 'not started'}`}
                                    secondaryTypographyProps={{
                                        sx: {
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: '2',
                                            WebkitBoxOrient: 'vertical',
                                        },
                                        fontSize: '17px'
                                    }}
                                />
                            </Col>
                            <Col>
                                <ListItemText
                                    style={{textAlign: 'right'}}
                                    primary={'Actual End Date:'}
                                    primaryTypographyProps={{fontSize: '15px'}}
                                    secondary={`${_.get(initialValues, 'actualEndDate') ? moment.utc(moment(_.get(initialValues, 'actualEndDate'))).format(config.clientTimeFormat) : 'not finished'}`}
                                    secondaryTypographyProps={{
                                        sx: {
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: '2',
                                            WebkitBoxOrient: 'vertical',
                                        },
                                        fontSize: '17px'
                                    }}
                                />
                            </Col>
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <CancelBtn onClick={cancel} disabled={expectAnswer}/>
                </DialogActions>
            </Dialog>;
    }

};
