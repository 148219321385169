
// outsource dependencies
import React, { Component } from 'react';

// local dependencies
import is from '../services/is.service';
import { translate } from '../services/translate.service';

// configuration

/**
 * @description input component with helpers to display validation results
 * @example
 <Field
    required
    type="mail"
    name="email"
    placeholder="Email"
    component={ Input }
    className="form-control"
    label={ <span> @ </span> }
        />
 * @constructor Input
 * @public
 */
class Input extends Component {

    render () {
        let { input, meta, label, filter, skipTouch = false, ...attr } = this.props;
        filter = is.function(filter) ? filter : e => e;
        let message = '', statusClassName = '';
        if ( skipTouch || meta.touched ) {
            message = translate(meta.error);
            statusClassName = meta.valid ? 'is-valid' : 'is-invalid';
        }

        return (
            <div className={ 'form-group offset-bottom-1 '+ statusClassName }>
                <div>
                    <label htmlFor={input.name} className="input-group"> { label } </label>
                    <input
                        className="form-control"
                        id={input.name}
                        { ... input }
                        { ... attr }
                        onChange={e => input.onChange(filter(e))}
                            />
                </div>
                { !message ? ('') : (
                    <div className={'input-group '+statusClassName }>
                        <label htmlFor={input.name} className="form-text offset-bottom-0 text-danger"> { message } </label>
                    </div>
                )}
            </div>
        )
    }
}

export default Input;
