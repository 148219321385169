
// outsource dependencies

// local dependencies
import { PRIVATE } from '../actions/types';
import asideMenu from './aside-menu/reducer';
import domains from './domains/reducers';
import settings from './settings/reducer';
import categories from './categories/reducer';
import quantModels from './quantification-models/reducer';
import quantMetrics from './quantification-metrics/reducer';
import qualMetrics from './qualitative-metrics/reducer';
import riskAnswers from './risk-answers/reducer';
import associateModels from './associate-models/reducer';
import organizations from './organizations/reducer';
import roles from './roles/reducer';
import businessUnits from './business-units/reducer';
import users from './users/reducer';
import assetClasses from './asset-classes/reducer';
import dataClasses from './data-classes/reducer';
import dataDomains from './data-domains/reducer';
import systems from './systems/reducer';
import technologyAssets from './technology-assets/reducer';
import processes from './processes/reducer';
import techCategories from './tech-categories/reducer';
import technologies from './technologies/reducer';
import modelsModal from './select-model-modal/reducer';
import confirmDialog from '../components/confirmation-dialog/reducer';
import previewDialog from '../components/preview-dialog/reducer';
import qualQuest from './qualitative-questions/reducer';
import assessmentTypes from './assessment-types/reducer';
import assessments from './assessments/reducer';
import controlTestResults from './control-test-results/reducers';
import assessmentFindings from './assessment-findings/reducer';
import controls from './controls/reducer';
import answeredTests from './answered-tests/reducer';
import controlGuidelines from './control-guidelines/reducer';
import controlMaturities from './control-maturities/reducers';
import vendors from './vendors/reducer';
import associateSystems from './associate-systems/reducer';
import dataExfiltration from './data-exfiltration/reducer';
import businessInterruption from './business-interruption/reducer';
import regulatoryExposures from './regulatory-exposures/reducer';
import profile from './profile/reducer';
import changePassword from './change-password/reducer';
import vendorsDashboard from './dashboard-vendor/reducer';
import controlFunctions from './control-functions/reducer';
import controlCategories from './control-categories/reducer';
import controlSubcategories from './control-subcategories/reducer';
import adminDashboard from './dashboard-admin/reducer';
import organizationDashboard from './dashboard-organization/reducer';
import organization from './organization/reducer';
import organizationCyberInsuranceApplication from './organization-cyber-insurance-application/reducer';
import organizationCyberInsuranceInfo from './organization-cyber-insurance-info/reducer';
import organizationCyberRiskTolerance from './organization-cyber-risk-tolerance/reducer';
import dashboards from './dashboards/reducer';
import vendorQuals from './vendor-quals/reducer';
import vendorQuants from './vendor-quants/reducer';
import systemQuals from './system-quals/reducer';
import systemQuants from './system-quants/reducer';
import vendorQualsDomain from './vendor-quals-domain/reducer';
import vendorQuantsDomain from './vendor-quants-domain/reducer';
import systemQualsDomain from './system-quals-domain/reducer';
import systemQuantsDomain from './system-quants-domain/reducer';
import subsidiaries from './subsidiaries/reducer';
import importBusinessUnits from './import-business-units/reducer';
import importControlGuidelines from './import-control-guidelines/reducer';
import importGdprArticles from './import-gdpr-articles/reducer';
import importProcesses from './import-processes/reducer';
import importQuestions from './import-questions/reducer';
import importAnswers from './import-answers/reducer';
import importSystemsRisk from './import-systems-risk/reducer';
import importUsers from './import-users/reducer';
import importVendors from './import-vendors/reducer';
import importSubsidiaries from './import-subsidiaries/reducer';
import importTechnologies from './import-technologies/reducer';
import importSecurityRequirements from './import-security-requirements/reducer';
import importAssessmentFramework from './import-assessment-framework/reducer';
import importGdprEvidenceArticles from './import-gdpr-evidence-articles/reducer';
import importQuantMetrics from './import-quant-metrics/reducer';
import cyberRiskScoring from './cyber-risk-scoring/reducers';
import cloudScoring from './cloud-scoring/reducers';
import drilldown from './drilldown-page/reducer';
import reassignDialog from '../components/reassign-dialog/reducer';
import geoRecordsProcessedDialog from '../components/geo-records-processed-dialog/reducer';
import abbrevDialog from '../components/abbrev-dialog/reducer';
import operationalCosts from './operational-costs/reducers';
import capitalCosts from './capital-costs/reducers';
import variableCosts from './variable-costs/reducers';
import securityTools from './security-tools/reducers';
import cyberSecurityTools from './cyber-security-tools/reducers';
import cyberRoles from './cyber-roles/reducers';
import systemRiskReduction from './system-risk-reduction/reducers';
import credentialsExpiredModal from './credentials-expired-modal/reducer';
import auditLogs from './audit-logs/reducers';
import gdprCompliance from './gdpr-compliance/reducers';
import gdprSystemCompliance from './gdpr-system-compliance/reducers';
import gdprEvidenceDocuments from './gdpr-evidence-documents/reducers';
import userSettings from './user-settings/reducers';
import tasks from './tasks/reducers';
import taskCategories from './task-categories/reducers';
import myTasks from './my-tasks/reducers';
import policies from './policies/reducers';
import vendorContracts from './vendor-contracts/reducers';
import securityRequirements from './security-requirements/reducers';
import agreementAlert from './agreement-alert/reducer';
import systemControlTestResults from './system-control-test-results/reducers';
import organizationRiskScoring from './organization-risk-scoring/reducer';
import cybersecurityMaturity from './cybersecurity-maturity/reducer';
import riskMetrics from './risk-metrics/reducers';
import vulnerabilities from './vulnerabilities/reducers';
import constants from './constants/reducer';
import about from './about/reducer';
import knowledgeBase from './knowledge-base/reducer';
import quickStart from './quick-start/reducer';
import home from './home/reducer';

export default {
    privateLayout,
    domains,
    settings,
    categories,
    quantModels,
    quantMetrics,
    qualMetrics,
    riskAnswers,
    associateModels,
    organizations,
    roles,
    businessUnits,
    users,
    assetClasses,
    dataClasses,
    dataDomains,
    systems,
    technologyAssets,
    processes,
    techCategories,
    technologies,
    modelsModal,
    confirmDialog,
    previewDialog,
    qualQuest,
    assessmentTypes,
    assessments,
    controlTestResults,
    assessmentFindings,
    controls,
    answeredTests,
    controlGuidelines,
    controlMaturities,
    vendors,
    associateSystems,
    dataExfiltration,
    businessInterruption,
    regulatoryExposures,
    profile,
    changePassword,
    vendorsDashboard,
    controlFunctions,
    controlCategories,
    controlSubcategories,
    adminDashboard,
    organizationDashboard,
    organization,
    organizationCyberInsuranceApplication,
    organizationCyberInsuranceInfo,
    organizationCyberRiskTolerance,
    dashboards,
    asideMenu,
    vendorQuals,
    vendorQuants,
    systemQuals,
    systemQuants,
    vendorQualsDomain,
    vendorQuantsDomain,
    systemQualsDomain,
    systemQuantsDomain,
    subsidiaries,
    importBusinessUnits,
    importControlGuidelines,
    importGdprArticles,
    importProcesses,
    importQuestions,
    importAnswers,
    importSystemsRisk,
    importUsers,
    importVendors,
    importSubsidiaries,
    importTechnologies,
    importSecurityRequirements,
    importAssessmentFramework,
    importGdprEvidenceArticles,
    importQuantMetrics,
    cyberRiskScoring,
    cloudScoring,
    drilldown,
    reassignDialog,
    geoRecordsProcessedDialog,
    abbrevDialog,
    operationalCosts,
    capitalCosts,
    variableCosts,
    securityTools,
    cyberSecurityTools,
    cyberRoles,
    systemRiskReduction,
    credentialsExpiredModal,
    auditLogs,
    gdprCompliance,
    gdprSystemCompliance,
    gdprEvidenceDocuments,
    vendorContracts,
    userSettings,
    tasks,
    taskCategories,
    myTasks,
    policies,
    agreementAlert,
    securityRequirements,
    systemControlTestResults,
    organizationRiskScoring,
    cybersecurityMaturity,
    riskMetrics,
    vulnerabilities,
    constants,
    about,
    knowledgeBase,
    quickStart,
    home,
};

function privateLayout ( state = { expanded: true }, action ) {
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case PRIVATE.EXPAND_ASIDE:
            state = { ...state, ...options, expanded: true };
            break;
        case PRIVATE.COLLAPSE_ASIDE:
            state = { ...state, ...options, expanded: false };
            break;
    }
    // /@private/.test(type)&&
    // console.log(`%c REDUCER privateLayout ${type} `, 'color: #fff; background: #232323; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n action:', action
    //     // ,'\n arguments:', arguments
    // );
    return state;
}
