
// outsource dependencies
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

// local dependencies
import { store } from '../store';
import defaultDictionary from '../constants/dictionaries';

/**
 * Translate service provide translation functionality within application
 */
export class TranslateService {

    static _translate (key = '') {
        let dictionary = store.getState().app.dictionary;
        if (!_.isString(key)) {
            console.error(`[Translate service] received argument is not a string ${key}`);
            return '';
        }
        if (key === '') { return key; }
        if (!dictionary[key]) { return key; }
        return dictionary[key];
    }

    /**
     * @description translate function (based on react-localization lib)
     * Accepts dictionary key as 'GLOBALS$CREATE' and looking a match in current dictionary or in default one(english)
     * Allows to pass dynamic variables as sequence of params or object.
     * In dictionary's string we mark dynamic variable in curly braces('text {*there is our variable*} another text').
     * it's available to choose only one option. Don't mix them or function won't work
     *
     * When you pass dynamic variables as sequence of params check that variable number in translated string should match
     * with order of variable in sequence.(count starts from 0(zero))
     *
     * @example
     * **HTML**
     * <p>{translate('EXAMPLE$TEXT_WITH_DYNAMIC_VARIABLE', 'dynamic variable', 'second dynamic variable')}</p>
     * **DICTIONARY**
     * "EXAMPLE$TEXT_WITH_DYNAMIC_VARIABLE": "Text with {0} and {1}!"
     * **RESULT ON SCREEN**
     * "Text with dynamic variable and second dynamic variable!"
     *
     * When you pass dynamic variables as object don't forget that variable name in translated string should match
     * with field in object
     *
     * @example
     * **HTML**
     * <p>{translate('EXAMPLE$TEXT_WITH_DYNAMIC_VARIABLE', {firstVariable: 'dynamic variable', secondVariable: 'second dynamic variable'})}</p>
     * **DICTIONARY**
     * "EXAMPLE$TEXT_WITH_DYNAMIC_VARIABLE": "Text with {firstVariable} and {secondVariable}!"
     * **RESULT ON SCREEN**
     * "Text with dynamic variable and second dynamic variable!"
     *
     * It's available to pass simple string with tags(span, strong etc.),
     * but only as as sequence of params. Don't pass complex component
     *
     * @example
     * **HTML**
     * <p>{translate('EXAMPLE$TEXT_WITH_TAG', <strong>some emphasized text</strong>)}</p>
     * **DICTIONARY**
     * "EXAMPLE$EXT_WITH_TAG": "Text with tag: {0}"
     * **RESULT ON SCREEN**
     * "Text with tag: some emphasized text(in will be bold)"
     *
     */
    static translate (key, ...valuesForPlaceholders) {
        const placeholderRegex = /(\{[\d|\w]+\})/;
        let str, hasObject = false;
        // get current language from storage
        let dictionary = store.getState().app.dictionary;
        // get translation from dictionary
        if (!_.isString(key)) {
            // console.error(`[Translate service] received argument is not a string ${key}`);
            str = '';
        } else {
            str = dictionary[key] || defaultDictionary[key] || key;
        }
        // simple string
        if (_.isEmpty(valuesForPlaceholders)) {
            return str;
        }
        // string with dynamic parts
        const res = (str || '')
            .split(placeholderRegex)
            .filter(textPart => !!textPart)
            .map((textPart, index) => {
                // match dynamic parts
                if (textPart.match(placeholderRegex)) {
                    const matchedKey = textPart.slice(1, -1);
                    let valueForPlaceholder = valuesForPlaceholders[matchedKey];
                    // if no value found, check if working with an object instead
                    if(valueForPlaceholder === undefined) {
                        const valueFromObjectPlaceholder = valuesForPlaceholders[0][matchedKey];
                        if(valueFromObjectPlaceholder !== undefined) {
                            valueForPlaceholder = valueFromObjectPlaceholder;
                        } else {
                            // if value still isn't found, then it must have been undefined/null
                            return valueForPlaceholder;
                        }
                    }
                    // if react element
                    if(React.isValidElement(valueForPlaceholder)) {
                        hasObject = true;
                        return React.Children.toArray(valueForPlaceholder).map(component => ({...component, key: index.toString()}));
                    }

                    return valueForPlaceholder;
                }
                return textPart;
            });
        // If the results contains a object return an array otherwise return a string
        if (hasObject) return res;
        return res.join('');
    };

    static withTranslation (Component) {
        return connect(state => ({language: state.app.language, direction: state.app.direction}), dispatch => ({}))(Component)
    }
}

export const translate = TranslateService.translate;
export const withTranslation = TranslateService.withTranslation;
export default TranslateService;
