// outsource dependencies
import moment from 'moment';
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {toastr} from 'react-redux-toastr';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {config} from '../../../constants';
import {PERMISSION} from '../../../constants/spec';
import {POLICIES} from '../../../constants/routes';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {translate, withTranslation} from '../../../services/translate.service';
import {AddBtn, AnnualReviewBtn, ApprovalBtn, DelBtn, DetailsBtn, EditBtn, Link} from '../../../components/md-button';
import {POLICIES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { disabled, data, message, clearError, hints } = this.props;
        return (<Container fluid>
            <Breadcrumbs breadCrumbsMap={ POLICIES_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={9}>
                        <RichHintTitle update={LIST}  name={`POLICY_MANAGEMENT$TITLE`} expectAnswer={disabled} data={findHint(hints, 'POLICY_MANAGEMENT_TITLE')}/>
                    </Col>
                    <Col xs={3} className="text-right top-indent-4">
                        <Link Btn={AddBtn} placement="left" to={POLICIES.LINK_EDIT()} permission={PERMISSION.POLICY.CREATE} hint={findHint(hints, 'BUTTON_POLICY_MANAGEMENT_ADD')}/>
                    </Col>
                </Row>
                <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}> <SearchFilterConnected /> </Col> </Row>
                <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col> </Row>
                { data.length ? (
                    <Paper> <ConnectedTable /> </Paper>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                )}
            </ConnectedInitializer>
        </Container>);
    }
}

export default connect(
    state => ({
        data: state.policies.list.data,
        hints: state.policies.list.hintsData,
        message: state.policies.list.errorMessage,
        disabled: state.policies.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.policies.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.policies.list.filter,
        disabled: state.policies.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
    />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.policies.list, hints: state.policies.list.hintsData,}),
    dispatch => ({
        deleteItem: (id, item) => dispatch({type: LIST.DELETE_ITEM, id, item}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
        showWarn: () => toastr.warning(translate('POLICY_MANAGEMENT$TITLE'), translate('GLOBALS$UNDER_DEVELOPMENT')),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort, showWarn }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('POLICY_MANAGEMENT$POLICY_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'version'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('version')}
                                >
                            {translate('GLOBALS$VERSION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'createdBy'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('createdBy')}
                                >
                            {translate('GLOBALS$IMPORTED_BY')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'createdAt'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('createdAt')}
                                >
                            {translate('GLOBALS$IMPORTED_ON')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'approvedBy'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('approvedBy')}
                                >
                            {translate('POLICY_MANAGEMENT$APPROVED_BY')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'approvedAt'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('approvedAt')}
                                >
                            {translate('POLICY_MANAGEMENT$APPROVED_ON')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'annualReviewDate'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('annualReviewDate')}
                                >
                            {translate('POLICY_MANAGEMENT$ANNUAL_REVIEW_DATE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell style={{width: "35%"}}>{item.name}</TableCell>
                    <TableCell>{item.version}</TableCell>
                    <TableCell>{get(item, 'createdBy.fullName')}</TableCell>
                    <TableCell>{item.createdAt && moment(item.createdAt).utc().format(config.clientTimeFormat)}</TableCell>
                    <TableCell>{get(item, 'approvedBy.fullName')}</TableCell>
                    <TableCell>{item.approvedAt && moment.utc(moment(item.approvedAt)).format(config.clientTimeFormat)}</TableCell>
                    <TableCell>{item.annualReviewDate && moment.utc(moment(item.annualReviewDate)).format(config.clientTimeFormat)}</TableCell>
                    <TableCell className="text-nowrap" align="right">
                        <ApprovalBtn
                            className="offset-right-2"
                            permission={PERMISSION.POLICY.APPROVAL}
                            tooltip={translate('POLICY_MANAGEMENT$APPROVAL')}
                            onClick={showWarn}
                            hint={findHint(hints, 'BUTTON_POLICY_MANAGEMENT_APPROVAL')}>
                            {translate('POLICY_MANAGEMENT$APPROVAL')}
                        </ApprovalBtn>
                        <AnnualReviewBtn
                            className="offset-right-2"
                            permission={PERMISSION.POLICY.ANNUAL_REVIEW}
                            tooltip={translate('POLICY_MANAGEMENT$ANNUAL_REVIEW')}
                            onClick={ () => history.push(POLICIES.LINK_ANNUAL_REVIEW({id: item.id, query: {back: history.location.pathname + history.location.search}}))}
                            hint={findHint(hints, 'BUTTON_POLICY_MANAGEMENT_ANNUAL_REVIEW')}>
                            {translate('POLICY_MANAGEMENT$ANNUAL_REVIEW')}
                        </AnnualReviewBtn>
                        <Link
                            Btn={DetailsBtn}
                            className="offset-right-2"
                            permission={PERMISSION.POLICY.READ}
                            to={POLICIES.LINK_PREVIEW({id: item.id, query: {back: history.location.pathname + history.location.search}})}
                            hint={findHint(hints, 'POLICY_MANAGEMENT_TITLE')}
                                />
                        <Link
                            Btn={EditBtn}
                            className="offset-right-2"
                            permission={PERMISSION.POLICY.UPDATE}
                            to={POLICIES.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search}})}
                            hint={findHint(hints, 'BUTTON_POLICY_MANAGEMENT_EDIT')}
                                />
                        <DelBtn
                            onClick={() => deleteItem(item.id, item)}
                            permission={PERMISSION.POLICY.DELETE}
                            hint={findHint(hints, 'BUTTON_POLICY_MANAGEMENT_DELETE')}
                                />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));
