
// outsource dependencies
import { fork } from 'redux-saga/effects';

// local dependencies
import asideMenu from './aside-menu/saga';
import domains from './domains/sagas';
import settings from './settings/saga';
import categories from './categories/sagas';
import quantModels from './quantification-models/sagas';
import quantMetrics from './quantification-metrics/sagas';
import qualMetrics from './qualitative-metrics/sagas';
import riskAnswers from './risk-answers/sagas';
import associateModels from './associate-models/sagas';
import organizations from './organizations/sagas';
import roles from './roles/sagas';
import businessUnits from './business-units/sagas';
import users from './users/sagas';
import assetClasses from './asset-classes/sagas';
import dataClasses from './data-classes/sagas';
import dataDomains from './data-domains/sagas';
import systems from './systems/sagas';
import technologyAssets from './technology-assets/sagas';
import processes from './processes/sagas';
import techCategories from './tech-categories/sagas';
import technologies from './technologies/sagas';
import modelsModal from './select-model-modal/saga';
import qualQuest from './qualitative-questions/sagas';
import assessmentTypes from './assessment-types/sagas';
import assessments from './assessments/sagas';
import controlTestResults from './control-test-results/sagas';
import assessmentFindings from './assessment-findings/sagas';
import controls from './controls/sagas';
import answeredTests from './answered-tests/sagas';
import controlGuidelines from './control-guidelines/sagas';
import controlMaturities from './control-maturities/sagas';
import vendors from './vendors/sagas';
import associateSystems from './associate-systems/sagas';
import dataExfiltration from './data-exfiltration/sagas';
import businessInterruption from './business-interruption/sagas';
import regulatoryExposures from './regulatory-exposures/sagas';
import profile from './profile/saga';
import changePassword from './change-password/saga';
import vendorsDashboard from './dashboard-vendor/saga';
import controlFunctions from './control-functions/sagas';
import controlCategories from './control-categories/sagas';
import controlSubcategories from './control-subcategories/sagas';
import adminDashboard from './dashboard-admin/saga';
import organizationDashboard from './dashboard-organization/saga';
import organization from './organization/saga';
import organizationCyberInsuranceApplication from './organization-cyber-insurance-application/saga';
import organizationCyberInsuranceInfo from './organization-cyber-insurance-info/saga';
import organizationCyberRiskTolerance from './organization-cyber-risk-tolerance/saga';
import dashboards from './dashboards/saga';
import vendorQuals from './vendor-quals/saga';
import vendorQuants from './vendor-quants/saga';
import systemQuals from './system-quals/saga';
import systemQuants from './system-quants/saga';
import vendorQualsDomain from './vendor-quals-domain/saga';
import vendorQuantsDomain from './vendor-quants-domain/saga';
import systemQualsDomain from './system-quals-domain/saga';
import systemQuantsDomain from './system-quants-domain/saga';
import subsidiaries from './subsidiaries/sagas';
import importBusinessUnits from './import-business-units/saga';
import importControlGuidelines from './import-control-guidelines/saga';
import importGdprArticles from './import-gdpr-articles/saga';
import importProcesses from './import-processes/saga';
import importQuestions from './import-questions/saga';
import importAnswers from './import-answers/saga';
import importSystemsRisk from './import-systems-risk/saga';
import importVendors from './import-vendors/saga';
import importUsers from './import-users/saga';
import importSubsidiaries from './import-subsidiaries/saga';
import importTechnologies from './import-technologies/saga';
import importAssessmentFramework from './import-assessment-framework/saga';
import importSecurityRequirements from './import-security-requirements/saga';
import importGdprEvidenceArticles from './import-gdpr-evidence-articles/saga';
import importQuantMetrics from './import-quant-metrics/saga';
import cyberRiskScoring from './cyber-risk-scoring/sagas';
import cloudScoring from './cloud-scoring/sagas';
import drilldown from './drilldown-page/saga';
import reassignDialog from '../components/reassign-dialog/saga';
import previewDialog from '../components/preview-dialog/saga';
import geoRecordsProcessedDialog from '../components/geo-records-processed-dialog/saga';
import abbrevDialog from '../components/abbrev-dialog/saga';
import operationalCosts from './operational-costs/sagas';
import capitalCosts from './capital-costs/sagas';
import variableCosts from './variable-costs/sagas';
import securityTools from './security-tools/sagas';
import cyberSecurityTools from './cyber-security-tools/sagas';
import cyberRoles from './cyber-roles/sagas';
import systemRiskReduction from './system-risk-reduction/sagas';
import credentialsExpiredModal from './credentials-expired-modal/saga';
import auditLogs from './audit-logs/sagas';
import gdprCompliance from './gdpr-compliance/sagas';
import gdprSystemCompliance from './gdpr-system-compliance/sagas';
import gdprEvidenceDocuments from './gdpr-evidence-documents/sagas';
import userSettings from './user-settings/sagas';
import tasks from './tasks/sagas';
import taskCategories from './task-categories/sagas';
import myTasks from './my-tasks/sagas';
import policies from './policies/sagas';
import securityRequirements from './security-requirements/sagas';
import agreementAlert from './agreement-alert/saga';
import systemControlTestResults from './system-control-test-results/sagas';
import organizationRiskScoring from './organization-risk-scoring/saga';
import cybersecurityMaturity from './cybersecurity-maturity/saga';
import riskMetrics from './risk-metrics/sagas';
import vulnerabilities from './vulnerabilities/sagas';
import constants from './constants/sagas';
import about from './about/saga';
import knowledgeBase from './knowledge-base/saga';
import quickStart from './quick-start/saga';
import home from './home/saga';
import vendorContracts from './vendor-contracts/sagas';

/**
 * connect all public sagas
 *
 *
 * @public
 */
export default function* () {
    yield fork(asideMenu);
    yield fork(domains);
    yield fork(settings);
    yield fork(categories);
    yield fork(quantModels);
    yield fork(quantMetrics);
    yield fork(qualMetrics);
    yield fork(riskAnswers);
    yield fork(associateModels);
    yield fork(organizations);
    yield fork(roles);
    yield fork(businessUnits);
    yield fork(users);
    yield fork(assetClasses);
    yield fork(dataClasses);
    yield fork(dataDomains);
    yield fork(systems);
    yield fork(technologyAssets);
    yield fork(processes);
    yield fork(techCategories);
    yield fork(technologies);
    yield fork(modelsModal);
    yield fork(qualQuest);
    yield fork(assessmentTypes);
    yield fork(assessments);
    yield fork(controlTestResults);
    yield fork(assessmentFindings);
    yield fork(controls);
    yield fork(answeredTests);
    yield fork(controlGuidelines);
    yield fork(controlMaturities);
    yield fork(vendors);
    yield fork(associateSystems);
    yield fork(dataExfiltration);
    yield fork(businessInterruption);
    yield fork(regulatoryExposures);
    yield fork(profile);
    yield fork(changePassword);
    yield fork(vendorsDashboard);
    yield fork(controlFunctions);
    yield fork(controlCategories);
    yield fork(controlSubcategories);
    yield fork(adminDashboard);
    yield fork(organizationDashboard);
    yield fork(organization);
    yield fork(organizationCyberInsuranceApplication);
    yield fork(organizationCyberInsuranceInfo);
    yield fork(organizationCyberRiskTolerance);
    yield fork(dashboards);
    yield fork(vendorQuals);
    yield fork(vendorQuants);
    yield fork(systemQuals);
    yield fork(systemQuants);
    yield fork(vendorQualsDomain);
    yield fork(vendorQuantsDomain);
    yield fork(systemQualsDomain);
    yield fork(systemQuantsDomain);
    yield fork(subsidiaries);
    yield fork(importBusinessUnits);
    yield fork(importControlGuidelines);
    yield fork(importGdprArticles);
    yield fork(importProcesses);
    yield fork(importQuestions);
    yield fork(importAnswers);
    yield fork(importSystemsRisk);
    yield fork(importVendors);
    yield fork(importUsers);
    yield fork(importSubsidiaries);
    yield fork(importTechnologies);
    yield fork(importSecurityRequirements);
    yield fork(importAssessmentFramework);
    yield fork(importGdprEvidenceArticles);
    yield fork(importQuantMetrics);
    yield fork(cyberRiskScoring);
    yield fork(cloudScoring);
    yield fork(drilldown);
    yield fork(reassignDialog);
    yield fork(previewDialog);
    yield fork(geoRecordsProcessedDialog);
    yield fork(abbrevDialog);
    yield fork(operationalCosts);
    yield fork(capitalCosts);
    yield fork(variableCosts);
    yield fork(securityTools);
    yield fork(cyberSecurityTools);
    yield fork(cyberRoles);
    yield fork(systemRiskReduction);
    yield fork(credentialsExpiredModal);
    yield fork(auditLogs);
    yield fork(gdprCompliance);
    yield fork(gdprSystemCompliance);
    yield fork(gdprEvidenceDocuments);
    yield fork(userSettings);
    yield fork(tasks);
    yield fork(taskCategories);
    yield fork(myTasks);
    yield fork(policies);
    yield fork(vendorContracts);
    yield fork(agreementAlert);
    yield fork(securityRequirements);
    yield fork(systemControlTestResults);
    yield fork(organizationRiskScoring);
    yield fork(cybersecurityMaturity);
    yield fork(riskMetrics);
    yield fork(vulnerabilities);
    yield fork(constants);
    yield fork(about);
    yield fork(knowledgeBase);
    yield fork(quickStart);
    yield fork(home);
}
