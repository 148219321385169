
// outsource dependencies
import { put, call, takeEvery, select, take } from 'redux-saga/effects';

// local dependencies
import { EDIT } from './actions';
import { APP } from '../../actions/types';
import store, { history } from '../../store';
import queryService from '../../services/query.service';
import { DRILLDOWN_PAGE } from '../../constants/routes';
import { instanceAPI } from '../../services/api.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);

    // NOTE setup listener on location change
    yield call(history.listen, listenHistory);
    // NOTE on initializing application with dashboard url location action does not fired
    // NOTE emulate
    if ( DRILLDOWN_PAGE.REGEXP.test(history.location.pathname) ) {
        // NOTE waiting until auth and risk model would be getting
        yield take(APP.INIT.FINISH);
        yield put({type: EDIT.INITIALIZE});
    }
}

function* initializeSaga () {
    yield put({type: EDIT.CLEAR});
    try {
        // NOTE take data from location
        const params = yield call(queryService.parse, history.location.search);
        // NOTE get data
        let { riskModel } = yield select( state => state.app );
        let data = yield call(getData, riskModel.id, params);
        // NOTE setup data
        yield put({type: EDIT.DATA, data});
    } catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, initialized: true,});
}

/**
 * history change listener
 *
 * param (Object) location
 * @private
 */
function listenHistory ({location : {pathname}}) {
    if ( !DRILLDOWN_PAGE.REGEXP.test(pathname) ) return;
    // NOTE reinitialize page
    // NOTE this event will fired before the url was changed
    setTimeout(()=> store.dispatch({type: EDIT.INITIALIZE}), 0);
}

/**
 * get drilldown data
 *
 * @param {Number} riskModelId
 * @param {Object} options
 * @private
 */
function getData (riskModelId, {view, ...params}) {
    return instanceAPI({
        method: 'post',
        headers: {'risk-model-id': riskModelId},
        url: '/dashboards/get-drilldown-dashboard',
        data: {view, params},
    });
}
