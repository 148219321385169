// outsource dependencies
import {change} from 'redux-form';
import {difference} from 'lodash';
import {toastr} from 'react-redux-toastr';
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {EDIT} from './actions';
import {APP} from '../../actions/types';
import {instanceAPI} from '../../services/api.service';
import {translate} from '../../services/translate.service';
import separateService from '../../services/separate-with-comma.service';

export const FORM_NAME = 'editOrganizationCyberInsuranceInfo';
export const changeField = (field, value) => change(FORM_NAME, field, value);

/**
 *
 *
 * @publicexport const FORM_NAME = 'editVendor';
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function * initializeSaga () {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData);
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        let availableLanguages = yield call(getLanguages);

        //NOTE separate with commas
        yield call(separateService.separate, data);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, availableLanguages});
    } catch ( {message} ) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, initialized: true});
}

function* updateDataSaga ({type, ...options}) {
    // NOTE convert to default numeric
    yield call(separateService.convert, options);
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let prevData = yield select(state => state.organizationCyberInsuranceInfo.data);
        let data = yield call(updateData, options);
        // NOTE check difference between old and new supported languages
        const unequal = Boolean(difference(prevData.supportedLanguages, options.supportedLanguages).length
            || difference(options.supportedLanguages, prevData.supportedLanguages).length);
        // NOTE update supported languages
        if (unequal) {yield put({type: APP.SETUP_LANGUAGES});}
        // NOTE separate with commas
        yield call(separateService.separate, data);
        yield put({type: EDIT.DATA, data});
        yield call(toastr.success, translate('ORGANIZATION$TITLE'), translate('GLOBALS$SUCCESSFUL_DATA_UPDATE'));
        yield put({type: EDIT.META, expectAnswer: false});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

/**
 * get data
 *
 * @private
 */
function getData () {
    return instanceAPI({method: 'get', url: '/organizations/self'});
}

/**
 * get list of hints
 * @param {String} language
 * @private
 */
function getHintsList (language) {
    let data = ["ORGANIZATION_TITLE", "BUTTON_ORGANIZATION_SAVE", "BUTTON_ORGANIZATION_RESET"];
    return instanceAPI({method: 'post', url: `/hints/get/${language}`, data});
}

/**
 * update organization
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return instanceAPI({ method: 'post', url: '/organizations/update-cyber-insurance-info', data});
}

/**
 * get all available supported languages
 * @private
 */
function getLanguages() {
    return instanceAPI({ method: 'post', url: '/supported-languages/filter', data: { size: 10000 } })
        .then(({items}) => items);
}
