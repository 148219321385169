// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
// import { Prompt } from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {Field, FieldArray, Form, isPristine, reduxForm, submit} from 'redux-form';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {PERMISSION} from '../../../constants/spec';
import {SubmitBtn} from '../../../components/md-button';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import {ProtectedInput} from '../../../components/md-input';
import MdTablePagination from '../../../components/pagination';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {BUSINESS_INTERRUPTION_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import separateService from "../../../services/separate-with-comma.service";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list, update, pristine, hints } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ BUSINESS_INTERRUPTION_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={8}>
                            <RichHintTitle update={LIST}  name={`BUSINESS_INTERRUPTION$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `BUSINESS_INTERRUPTION_TITLE`)}/>
                        </Col>
                        <Col xs={4} className="text-right top-indent-4 align-middle">
                            {!pristine&&<strong className="text-danger h4">
                                <i className="fa fa-exclamation-triangle" aria-hidden="true" /> {translate('GLOBALS$UNSAVED_DATA')}
                            </strong>}&nbsp;
                            <SubmitBtn
                                placement="left"
                                onClick={update}
                                disabled={pristine||expectAnswer}
                                permission={PERMISSION.BUSINESS_INTERRUPTION.UPDATE}
                                hint={findHint(hints, 'BUTTON_BUSINESS_INTERRUPTION_SAVE')}
                                    >
                            </SubmitBtn>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected />
                        </Col>
                    </Row>
                    {/*NOTE block navigating away from a page and show message*/}
                    {/*<Prompt when={!pristine} message={translate('GLOBALS$UNSAVED_DATA_MESSAGE')} />*/}
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        list: state.businessInterruption.list.data,
        hints: state.businessInterruption.list.hintsData,
        pristine: isPristine('businessInterruption')(state),
        expectAnswer: state.businessInterruption.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        update: () => dispatch(submit('businessInterruption')),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.businessInterruption.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter:  state.businessInterruption.list.filter,
        disabled: state.businessInterruption.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
)));

const ConnectedTable = withTranslation(connect(
    state => ({
        ...state.businessInterruption.list,
        initialValues: { businessInterruptions: state.businessInterruption.list.data }
    }),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
        update: ({businessInterruptions}) => dispatch({ type: LIST.SAVE, businessInterruptions })
    })
)( reduxForm({ form: 'businessInterruption', enableReinitialize: 'true'}) (
    ({ page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort, handleSubmit, update }) => (
    <Form autoComplete="off" name="businessInterruption" onSubmit={handleSubmit(update)}>
        <div style={{overflowX: 'auto'}}>
            <Table className="md-table" padding="checkbox">
                <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                    <TableRow style={{height: 48}}>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'name'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={()=>changeSort('name')}
                                    >
                                {translate('PROCESSES$PROCESS')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'description'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={()=>changeSort('description')}
                            >
                                {translate('GLOBALS$DESCRIPTION')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'businessUnit'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={()=>changeSort('businessUnit')}
                                    >
                                {translate('BUSINESS_UNITS$BUSINESS_UNIT')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'revenueProcessed'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={()=>changeSort('revenueProcessed')}
                                    >
                                {translate('PROCESSES$REVENUE_PROCESSED')}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {/*NOTE list items with input fields*/}
                <FieldArray name="businessInterruptions" component={Items}/>
            </Table>
        </div>
        <MdTablePagination
            page={page}
            size={size}
            disabled={expectAnswer}
            totalPages={totalPages}
            changeSize={changeSize}
            changePage={changePage}
                />
    </Form>
))));

const ConnectedError = connect(
    state => ({message: state.businessInterruption.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const Items = connect(
    state => ({ disabled: state.businessInterruption.list.expectAnswers }),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ fields, disabled }) => (
    <TableBody>
        {fields.map((mKey, i) => {
            let item = fields.get(i);
            return (<TableRow style={{height: 48}} key={i}>
                <TableCell>{get(item, 'name')}</TableCell>
                <TableCell>{get(item, 'description')}</TableCell>
                <TableCell>{formatBusinessUnitLabel(get(item, 'businessUnit'))}</TableCell>
                <TableCell>
                    <Field
                        type="text"
                        disabled={disabled}
                        component={ProtectedInput}
                        style={{maxWidth: '250px'}}
                        classes={{input: 'text-right'}}
                        name={`${mKey}.revenueProcessed`}
                        // normalize={value => value && Number(value)}
                        normalize={separateService.normalize}
                        permission={PERMISSION.BUSINESS_INTERRUPTION.UPDATE}
                        placeholder={translate('PROCESSES$REVENUE_PROCESSED')}
                            />
                </TableCell>
            </TableRow>)
        })}
    </TableBody>
));
