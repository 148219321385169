
// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const CATEGORIES = (function ( prefix ) {
    return {
        // simple
        CLEAR: `${prefix}CLEAR`,
        META: `${prefix}META`,
        EDIT_META: `${prefix}EDIT_META`,
        LIST: `${prefix}LIST`,
        ITEM: `${prefix}ITEM`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        GET_LIST: createTypes(`${prefix}GET_LIST`),
        GET_ITEM: createTypes(`${prefix}GET_ITEM`),
        UPDATE_ITEM: createTypes(`${prefix}UPDATE_ITEM`),
        EDIT: createTypes(`${prefix}EDIT`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@categories/');
