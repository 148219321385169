// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import {NEW_ID} from '../../../constants/spec';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {MdSelect} from '../../../components/md-select';
import {ASSET_CLASSES_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

//config
export const THRESHOLD_RISK_LEVELS = {
    'undefined': '',
    1: 'Low',
    2: 'Medium Low',
    3: 'Medium',
    4: 'Medium High',
    5: 'High',
};

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, []);
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ASSET_CLASSES_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                                        <RichHintTitle
                                            update={EDIT}
                                            name={isNew ? translate('ASSET_CLASSES$CREATE_CLASS') : translate('ASSET_CLASSES$EDIT_CLASS')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints, 'ASSET_CLASSES_TITLE')}
                                        />
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};

export default connect(
    state => ({expectAnswer: state.assetClasses.edit.expectAnswer, hints: state.assetClasses.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.assetClasses.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.assetClasses.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.assetClasses.edit.data,
        disabled: state.assetClasses.edit.expectAnswer,
        hints: state.assetClasses.edit.hintsData,
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData})
    })
)(reduxForm({
    form: 'editAssetClass',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, hints, cancel}) => (
    <form autoComplete="off" name="editAssetClass" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$DESCRIPTION')}
                    label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <Field
                    labelKey="label"
                    valueKey="value"
                    name="threshold"
                    disabled={disabled}
                    component={MdSelect}
                    options={[1, 2, 3, 4, 5].map(option => {
                        return ({
                            label: THRESHOLD_RISK_LEVELS[option],
                            value: option
                        });
                    })}
                    optionsLabel={({value}) => THRESHOLD_RISK_LEVELS[value]}
                    label={(<strong> {translate('ORGANIZATION$RISK_THRESHOLD')} </strong>)}
                    sendValueProp
                    simpleValue={(value) => {
                        return {
                            value: value,
                            label: value && THRESHOLD_RISK_LEVELS[value] ? THRESHOLD_RISK_LEVELS[value] : ''
                        };
                    }}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_ASSET_CLASSES_CREATE' : 'BUTTON_ASSET_CLASSES_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"
                          hint={findHint(hints, 'BUTTON_ASSET_CLASSES_RESET')}/>
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_ASSET_CLASSES_CANCEL')}/>
            </Col>
        </Row>
    </form>
))));
