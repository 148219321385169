
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { put, call, takeEvery, take, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { history } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { translate } from '../../../services/translate.service';
import { instanceAPI, forgotPassword } from '../../../services/api.service';
import CONFIRM_DIALOG from '../../../components/confirmation-dialog/actions';
import getHintsList, {USERS_EDIT_HINTS} from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.RESET_PASSWORD, resetPasswordSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({type, id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        yield put({type: EDIT.META, initialized: true, ...params});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* resetPasswordSaga () {
    // NOTE ask confirmation of reset password
    // yield put({type: CONFIRM_DIALOG.REQUEST, title: 'Confirm reset password', message: <span>Resetting user password - clear existing user sessions and send email to change password. Please note - <code>each</code> call of <code>reset password</code> do it again. And <code>invalidate previous</code> email.</span>});
    yield put({type: CONFIRM_DIALOG.REQUEST, title: translate('USERS$CONFIRM_RESET_PASSWORD'), message: translate('USERS$RESET_PASSWORD_MESSAGE')});
    let answer = yield take([CONFIRM_DIALOG.SUCCESS, CONFIRM_DIALOG.ERROR]);
    // NOTE do nothing if confirmation dismiss
    if ( answer.type === CONFIRM_DIALOG.ERROR ) return;
    yield put({type: EDIT.META, expectAnswer: true});
    try {
        let { email } = yield select(state=> state.users.edit.data);
        yield call( forgotPassword, { email } );
        yield call(toastr.success, translate('FORGOT_PASSWORD$RESET_PASSWORD'), translate('USERS$SUCCESSFUL_RESET_PASSWORD') + email);
        yield put({type: EDIT.META, expectAnswer: false});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message, expectAnswer: false});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.users.edit);
    // NOTE go back
    yield call(history.push, back);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, USERS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

/**
 * get user by id
 * @param {Number|String} id
 * @private
 */
function getData( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/users/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update user
 * @param {Object} data
 * @private
 */
function updateData( data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({method: 'post', url: `/users`, data});
        } else { // UPDATE
            promise = instanceAPI({method: 'put', url: `/users`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
