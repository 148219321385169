
// outsource dependencies

// local dependencies

export default (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE: `${prefix}UPDATE`,
    };
})('@credentials-expired-modal/');
