
// outsource dependencies

// local dependencies

export default (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        ACCEPT: `${prefix}ACCEPT`,
        DECLINE: `${prefix}DECLINE`,
        INITIALIZE: `${prefix}INITIALIZE`,
    };
})('@agreement-alert/');
