
// outsource dependencies

// local dependencies
import { EDIT } from './actions';

let initial = {
    data: {},
    currentTab: 1,
    // meta
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
};

export default function ( state = initial, action ) {
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case EDIT.CLEAR:
            state = initial;
            break;
        case EDIT.DATA:
            state = { ...state, ...options };
            break;
        case EDIT.META:
            state = { ...state, ...options, data: state.data  };
            break;
    }

    return state;
}
