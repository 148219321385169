// outsource dependencies
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {config} from '../../constants';
import {ABOUT_MAP} from "../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../components/hints/hints";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumb";
import {PAGE} from './actions';
import {connect} from 'react-redux';
import CenteredBox from '../../components/centered-box';
import {Paper} from '@mui/material';
import {translate} from "../../services/translate.service";

class About extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let {hints, versionInfo} = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ ABOUT_MAP }  />
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:10, offset:1}} lg={{span:8, offset:2}} >
                        <Paper className="indent-8">
                            <CenteredBox>
                                <RichHintTitle name={`ABOUT$TITLE`} data={findHint(hints, `ABOUT_TITLE`)} />
                            </CenteredBox>
                            <br />
                            <h1 className="text-center text-highlighted">
                                {translate('HOME_PAGE$TITLE', <span className="text-highlighted">RiskQ Inc. </span>)}
                            </h1>
                            <br />
                            <h3 className="text-left">
                                UI Build: <span className="text-highlighted">{config.version}</span>, release date: <span className="text-highlighted">{config.releaseDate}</span>
                            </h3>
                            {versionInfo ? (
                                <h3 className="text-left">
                                    Backend Build: <span className="text-highlighted">{versionInfo.version}</span>, release date: <span className="text-highlighted">{versionInfo.releaseDate}</span>
                                </h3>
                            ) : ''}
                        </Paper>
                    </Col>
                </Row> 
         </Container>
        );
    }
}

export default connect(
    state => ({ hints: state.about.hintsData, versionInfo: state.about.versionInfo }),
    dispatch => ({
        initialize: () => dispatch({type: PAGE.INITIALIZE}),
        clear: () => dispatch({type: PAGE.CLEAR})
    })
)(About)

