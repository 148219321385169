// outsource dependencies
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {ANNUAL_REVIEW} from '../actions';
import {history} from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import {instanceAPI} from '../../../services/api.service';
import getHintsList, {POLICY_MANAGEMENT_ANNUAL_REVIEW_HINTS} from '../../../services/hints.service';
import {toastr} from "react-redux-toastr";
import {translate} from "../../../services/translate.service";

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(ANNUAL_REVIEW.CANCEL, cancelSaga);
    yield takeEvery(ANNUAL_REVIEW.UPDATE, updateDataSaga);
    yield takeEvery(ANNUAL_REVIEW.INITIALIZE, initializeSaga);
    yield takeEvery(ANNUAL_REVIEW.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({id}) {
    yield put({type: ANNUAL_REVIEW.CLEAR});
    try {
        let data = yield call(getData, id);
        yield put({type: ANNUAL_REVIEW.GET_HINTS_DATA.REQUEST});
        yield put({type: ANNUAL_REVIEW.DATA, data});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: ANNUAL_REVIEW.META, initialized: true, readOnly: true, ...params});
    }
    catch ({message}) {
        yield put({type: ANNUAL_REVIEW.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: ANNUAL_REVIEW.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: ANNUAL_REVIEW.DATA, data});
        yield put({type: ANNUAL_REVIEW.META, expectAnswer: false});

        yield call(toastr.success, translate('POLICY_MANAGEMENT$REVIEW'), translate('POLICY_MANAGEMENT$REVIEW_CREATE_GREETING'));

        // NOTE go to list
        yield put({type: ANNUAL_REVIEW.CANCEL});
    }
    catch ({message}) {
        yield put({type: ANNUAL_REVIEW.META, expectAnswer: false, errorMessage: message});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.policies.annualreview);
    // NOTE go back
    yield call(history.push, back);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, POLICY_MANAGEMENT_ANNUAL_REVIEW_HINTS);

        // NOTE setup hints data
        yield put({type: ANNUAL_REVIEW.META, hintsData});
    } catch ( {message} ) {
        yield put({type: ANNUAL_REVIEW.META, errorMessage: message});
    }
    yield put({type: ANNUAL_REVIEW.GET_HINTS_DATA.FINISH});
}

/**
 * get policy by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/policy/review/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update policy data
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return new Promise((resolve, reject) => {
        let promise;
        promise = instanceAPI({ method: 'post', url: '/policy/review', data});
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
