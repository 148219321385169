// outsource dependencies
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Col, Row} from 'react-bootstrap';

// local dependencies
import {SCHEME} from '../actions';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {translate} from '../../../services/translate.service';
import {EditLnkBtn, Link} from "../../../components/md-button";
import {PERMISSION} from "../../../constants/spec";
import {BUSINESS_UNITS} from "../../../constants/routes";

class Scheme extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { data } = this.props;
        return (<Paper square>
            <ConnectedInitializer>
                <ConnectedError />
                <Row><Col xs={12} className="indent-5">
                    { !isEmpty(data) ? (
                        <ul className="tree"> <Node data={data} /> </ul>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </Col></Row>
            </ConnectedInitializer>
        </Paper>);
    }
}

export default connect(
    state => ({data: state.businessUnits.scheme.data}),
    dispatch => ({
        clear: () => dispatch({type: SCHEME.CLEAR}),
        initialize: () => dispatch({type: SCHEME.INITIALIZE}),
    })
)(Scheme)

const ConnectedInitializer = connect(
    state => ({initialize: state.businessUnits.scheme.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.businessUnits.scheme.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: SCHEME.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));


/**
 * node component
 *
 * @param {Object} props
 * @public
 */
const Node = ({data}) => (
    <li className="node">
        <div className="info">
            {data.name}
            {data.id > 0 ? <Link Btn={EditLnkBtn} permission={PERMISSION.BUSINESS_UNIT.UPDATE} to={BUSINESS_UNITS.LINK_EDIT({id: data.id})} /> : ''}
        </div>
        {!data.children ? '' : (<ul className="children">
            { data.children.map( (item, index) => (
                <Node key={index} data={item} />
            ))}
        </ul>)}
    </li>
);

