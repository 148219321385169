// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {NEW_ID} from '../../../constants/spec';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {CONTROL_MATURITIES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {useParams} from "react-router-dom";
import {LIST} from '../../cyber-roles/actions';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={CONTROL_MATURITIES_MAP} />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                    <span>
                                        <RichHintTitle
                                            update={LIST}
                                            name={isNew ? translate('CONTROL_MATURITIES$CREATE_MATURITY') : translate('CONTROL_MATURITIES$EDIT_MATURITY')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints, `CONTROL_MATURITIES_TITLE`)}
                                        />
                                    </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                            <ConnectedError />
                            <ConnectedForm isNew={isNew} />
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({expectAnswer: state.controlMaturities.edit.expectAnswer, hints: state.controlMaturities.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.controlMaturities.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.controlMaturities.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError} />
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.controlMaturities.edit.data,
        hints: state.controlMaturities.edit.hintsData,
        disabled: state.controlMaturities.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editControlMaturity',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        if (values.weight && (values.weight < 0 || values.weight > 100)) {
            errors.weight = 'GLOBALS$PERCENTAGE_ERROR';
        }
        return errors;
    }
})(({handleSubmit, hints, invalid, pristine, disabled, update, reset, isNew, cancel}) => (
    <form autoComplete="off" name="editControlCategory" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={6}>
                <Field
                    type="number"
                    name="weight"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('CONTROL_MATURITIES$WEIGHT')}
                    label={(<strong> {translate('CONTROL_MATURITIES$WEIGHT')} </strong>)}
                />
            </Col>
            <Col xs={6}>
                <Field
                    type="number"
                    name="value"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$VALUE')}
                    label={(<strong> {translate('GLOBALS$VALUE')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_CONTROL_MATURITIES_CREATE' : 'BUTTON_CONTROL_MATURITIES_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_CONTROL_MATURITIES_RESET')} />
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_CONTROL_MATURITIES_CANCEL')} />
            </Col>
        </Row>
    </form>
))));
