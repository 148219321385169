
// outsource dependencies
import { combineReducers } from 'redux';

// local dependencies
import edit from './edit/reducer';
import list from './list/reducer';
import annualreview from './annualreview/reducer';
import preview from './preview/reducer';

export default combineReducers({
    edit,
    list,
    annualreview,
    preview
});
