
// outsource dependencies
import { call, put, select, takeEvery } from 'redux-saga/effects';

// local dependencies
import getMenu from './index';
import ACTIONS from './actions';
import { instanceAPI } from '../../services/api.service';
import * as ROUTES from '../../constants/routes';
import {MENU_ITEM_TYPE, PERMISSION} from '../../constants/spec';


export default function* () {
    yield takeEvery(ACTIONS.INITIALIZE, initializeSaga);
}

function* initializeSaga () {
    try {
        let { riskModel, user } = yield select(state => state.app);
        let dashboards = yield call(getDashboards, riskModel.id);

        // NOTE transform menu with dashboards
        let data = yield call(getMenu, {dashboards, permissions: user.permissions});

        if (user.permissions && user.permissions.includes(PERMISSION.CYBERSECURITY_MATURITY.READ)) {
            let additionalDomains = yield call(getAdditionalDocumentManagement, riskModel.id);
            try {
                if (additionalDomains && additionalDomains.length) {
                    let documentsManagementMenuItem = null;
                    for (let i = 0; i <= data.length; i++) {
                        if (data[i].name === 'MENU$DOCUMENTATION_MANAGEMENT') {
                            documentsManagementMenuItem = data[i];
                            break;
                        }
                    }
                    for (let i = 0; i < additionalDomains.length; i++) {
                        let domainCode = additionalDomains[i];

                        let newMenuItem = {
                            type: MENU_ITEM_TYPE.LINK,
                            name: domainCode,
                            icon: 'fa fa-lock',
                            link: `/private/${domainCode}/cybersecurity-maturity`,
                            permission: {type: 'include', value: PERMISSION.CYBERSECURITY_MATURITY.READ},
                            isActive: (s, location) => ROUTES.CYBERSECURITY_MATURITY.REGEXP.test(location.pathname)
                        };
                        documentsManagementMenuItem.list.push(newMenuItem);
                    }
                }
            } catch (e) {
                console.error('Failed to obtain Document Management menu Item');
            }
        }

        // NOTE setup side menu data
        yield put({type: ACTIONS.DATA, data});
    }
    catch ( e ) {
        // NOTE do nothing
    }
}

/**
 * get dashboards list
 *
 * @param {Number} riskModelId
 * @private
 */
function getDashboards( riskModelId ) {
    return instanceAPI({ method: 'get', url: '/dashboards', headers: { 'risk-model-id': riskModelId } });
}

/**
 * get dashboards list
 *
 * @param {Number} riskModelId
 * @private
 */
function getAdditionalDocumentManagement( riskModelId ) {
    return instanceAPI({ method: 'get', url: `/metric-domains/list-pages/risk-model/${riskModelId}/Organization`, headers: { 'risk-model-id': riskModelId } });
}
