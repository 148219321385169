// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {DOWNLOAD_TYPES, PERMISSION} from '../../../constants/spec';
import withDownloadLink from '../../../components/download-link';
import {PROCESSES} from '../../../constants/routes';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {instanceAPI} from '../../../services/api.service';
import MdTablePagination from '../../../components/pagination';
import {SimpleAsyncSelect} from '../../../components/md-select';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {AddBtn, DelBtn, EditBtn, Link, PrimaryBtn, WarningBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import SearchFilter, {SimpleSearchField} from '../../../components/search-filter';
import {PROCESSES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";
import {Close} from '@mui/icons-material';

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list, hints, uploadedFile, uploadFile } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ PROCESSES_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={8}>
                            <RichHintTitle update={LIST}  name={'PROCESSES$TITLE'} expectAnswer={expectAnswer} data={findHint(hints, 'PROCESSES_TITLE')}/>
                        </Col>
                        <Col xs={4} className="text-right top-indent-4">
                            <DownloadLink className="offset-left-2 offset-bottom-1"  hint={findHint(hints, 'BUTTON_PROCESSES_DOWNLOAD_DATA')} />
                            <DownloadTemplateLink className="offset-left-2 offset-bottom-1"  hint={findHint(hints, 'BUTTON_PROCESSES_DOWNLOAD_TEMPLATE')} />
                            <PrimaryBtn
                                component="label"
                                htmlFor="fileUpload"
                                tooltip={translate('GLOBALS$UPLOAD_CSV')}
                                className="offset-left-2 offset-bottom-1"
                                permission={PERMISSION.IMPORT.PROCESS}
                                hint={findHint(hints, 'BUTTON_PROCESSES_UPLOAD_CSV')}
                                    >
                                <i className="fa fa-upload" style={{fontSize: 20}} aria-hidden="true" />
                                &nbsp;{translate('GLOBALS$CSV')}
                                <input
                                    type="file"
                                    accept=".csv"
                                    id="fileUpload"
                                    value={uploadedFile}
                                    style={{display: 'none'}}
                                    onChange={(e)=>{uploadFile(e.target.files[0])}}
                                        />
                            </PrimaryBtn>
                            <Link
                                Btn={AddBtn}
                                placement="left"
                                to={PROCESSES.LINK_EDIT()}
                                className="offset-left-2 offset-bottom-1"
                                permission={PERMISSION.PROCESS.CREATE}
                                hint={findHint(hints, 'BUTTON_PROCESSES_ADD')}
                                    />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4"><Col xs={12}> <FiltersPanelConnected /> </Col></Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        expectAnswer: state.processes.list.expectAnswer,
        list: state.processes.list.data,
        uploadedFile: state.processes.list.uploadedFile,
        hints: state.processes.list.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        uploadFile: file => dispatch({ type: LIST.UPLOAD_FILE, file }),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.processes.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const DownloadLink = withTranslation(withDownloadLink({downloadType: DOWNLOAD_TYPES.PROCESSES.CSV_LIST})(props => (
    <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_DATA')} permission={PERMISSION.EXPORT.PROCESSES} {...props}>
        <i className="fa fa-download" style={{fontSize: 20, marginTop: 2}} aria-hidden="true" />&nbsp;&nbsp;
        <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true" />
    </PrimaryBtn>
)));

const DownloadTemplateLink = withTranslation(withDownloadLink({downloadType: DOWNLOAD_TYPES.PROCESSES.CSV_TEMPLATE})(props => (
    <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_TEMPLATE')} permission={PERMISSION.EXPORT.PROCESSES_TEMPLATE} {...props}>
        <i className="fa fa-download" style={{fontSize: 20, marginTop: 2}} aria-hidden="true" />&nbsp;&nbsp;
        <i className="fa fa-file-o" style={{fontSize: 18}} aria-hidden="true" />
    </PrimaryBtn>
)));


/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = withTranslation(connect(
    state => ({showAdvanced: state.processes.list.showAdvanced, hints: state.processes.list.hintsData}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => {
            dispatch({type: LIST.META, showAdvanced: true});
            dispatch({type: LIST.UPDATE_DATA, filter: ''});
        },
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ({showAdvanced, update, openAdvanced, closeAdvanced, hints}) => (<div>
    { showAdvanced ? (
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Card style={{overflow: 'visible'}}>
                    <CardHeader
                        title={translate('GLOBALS$ADVANCED_SEARCH')}
                        action={
                            <Tooltip title={translate('GLOBALS$CLOSE')}>
                                <IconButton aria-label={translate('GLOBALS$CLOSE')} onClick={closeAdvanced}>
                                    <Close fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }
                    />
                    <CardContent> <SearchForm /> </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn onClick={update} tooltip={translate('GLOBALS$APPLY')} hint={findHint(hints, 'BUTTON_PROCESSES_ADVANCED_SEARCH_APPLY')}> {translate('GLOBALS$APPLY')}  </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn onClick={closeAdvanced} tooltip={translate('GLOBALS$CLOSE')} hint={findHint(hints, 'BUTTON_PROCESSES_ADVANCED_SEARCH_CLOSE')}> {translate('GLOBALS$CLOSE')} </WarningBtn>
                    </CardActions>
                </Card>
            </Col>
        </Row>
    ) : (
        <Row>
            <Col xs={12} sm={6} lg={3} className="offset-bottom-2">
                <SearchFilterConnected />
            </Col>
            <Col xs={12} sm={6}>
                <PrimaryBtn hint={findHint(hints, 'BUTTON_PROCESSES_ADVANCED_SEARCH')} onClick={openAdvanced} tooltip={translate('GLOBALS$ADVANCED_SEARCH')} > {translate('GLOBALS$ADVANCED_SEARCH')} </PrimaryBtn>
            </Col>
        </Row>
    )}
</div>)));


/*
const FiltersPanelConnected = connect(
    state => ({showAdvanced:  state.processes.list.showAdvanced}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => dispatch({type: LIST.META, showAdvanced: true}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ( props) => (
    <FiltersPanel SearchForm={SearchForm} SearchFilter={SearchFilterConnected} {...props} />
));
*/
/**
 * search form
 *
 * @public
 */
const SearchForm = withTranslation(connect(
    state => ({ ...state.processes.list }),
    dispatch => ({ changeFilterValue: data => dispatch({type: LIST.META, ...data}) })
)( ({ expectAnswer, filter, system, dataType, businessUnitOwns, businessUnitUses, changeFilterValue }) => (<div>
    <Row>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleSearchField
                value={filter}
                disabled={expectAnswer}
                clear={() => changeFilterValue({filter: ''})}
                onChange={e => changeFilterValue({filter: e.target.value})}
                    />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                value={system}
                isClearable={true}
                disabled={expectAnswer}
                placeholder={translate('SYSTEMS$SYSTEM')}
                label={(<strong> {translate('SYSTEMS$SYSTEM')} </strong>)}
                onChange={system => changeFilterValue({system})}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'systems/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleAsyncSelect
                value={dataType}
                isClearable={true}
                disabled={expectAnswer}
                placeholder={translate('DATA_CLASSES$DATA_CLASS')}
                label={(<strong> {translate('DATA_CLASSES$DATA_CLASS')} </strong>)}
                onChange={dataType => changeFilterValue({dataType})}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'data-type-classifications/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
    <Row>
        <Col xs={12} md={6} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={businessUnitOwns}
                disabled={expectAnswer}
                placeholder={translate('PROCESSES$BUSINESS_UNIT_OWNS')}
                label={(<strong> {translate('PROCESSES$BUSINESS_UNIT_OWNS')} </strong>)}
                getOptionLabel={item => formatBusinessUnitLabel(item)}
                onChange={businessUnitOwns => changeFilterValue({businessUnitOwns})}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'business-units/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} md={6} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={businessUnitUses}
                disabled={expectAnswer}
                placeholder={translate('PROCESSES$USES_BUSINESS_UNITS')}
                label={(<strong> {translate('PROCESSES$USES_BUSINESS_UNITS')} </strong>)}
                getOptionLabel={item => formatBusinessUnitLabel(item)}
                onChange={businessUnitUses => changeFilterValue({businessUnitUses})}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'business-units/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
</div>)));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter:  state.processes.list.filter,
        disabled: state.processes.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
    />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.processes.list, hints: state.processes.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('description')}
                                >
                            {translate('GLOBALS$DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('SYSTEMS$TITLE')} </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'businessUnit'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('businessUnit')}
                                >
                            {translate('PROCESSES$BUSINESS_UNIT_OWNS')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('PROCESSES$USES_BUSINESS_UNITS')} </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{(item.systems||[]).map(i=>i.name).join(', ')}</TableCell>
                    <TableCell>{formatBusinessUnitLabel(get(item, 'businessUnit'))}</TableCell>
                    <TableCell>{(item.businessUnitUses||[]).map(i=>formatBusinessUnitLabel(i)).join(', ')}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.PROCESS.UPDATE}
                            hint={findHint(hints, 'BUTTON_PROCESSES_EDIT')}
                            to={PROCESSES.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                                />
                        <DelBtn permission={PERMISSION.PROCESS.DELETE} onClick={() => deleteItem(item.id)} className="offset-left-2"  hint={findHint(hints, 'BUTTON_PROCESSES_DELETE')} />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));

const ConnectedError = connect(
    state => ({message: state.processes.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
