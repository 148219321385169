// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import ACTIONS from './actions';
import {DOWNLOAD_TYPES, PERMISSION, QUESTION_TYPES_LIST} from '../../constants/spec';
import Preloader from '../../components/preloader';
import UploadFile from '../../components/data-import';
import {PrimaryBtn} from '../../components/md-button';
import ErrorMessage from '../../components/alert-error';
import {translate} from '../../services/translate.service';
import withDownloadLink from '../../components/download-link';
import PermissionAccess from '../../components/permission-access';
import {SimpleAsyncSelect} from "../../components/md-select";
import {instanceAPI} from "../../services/api.service";
import {findHint, RichHintTitle} from '../../components/hints/hints';

class Edit extends Component {
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { disabled, message, clearError, uploadFile, changeQualitativeMetric, changeScoringType, data, qualitativeMetric, scoringType, riskModelId, hints } = this.props;
        return (<Container fluid>
            <Row className="offset-top-10">
                <Col xs={12} md={{span:10,offset:1}} lg={{span:6,offset:3}}>
                    <Paper className="indent-8">
                        <Row>
                            <Col xs={12} className="offset-bottom-4">
                                <h2 className="text-center text-uppercase">
                                    <RichHintTitle update={ACTIONS} name={`IMPORT_AND_EXPORT$ANSWERS`}
                                                   data={findHint(hints, `ANSWERS_IMPORT_EXPORT_TITLE`)}/>
                                    &nbsp;<Preloader expectAnswer={disabled} type="ICON" />
                                </h2>
                            </Col>
                        </Row>

                        <ErrorMessage active message={message} onChange={clearError} />

                        <PermissionAccess permission={PERMISSION.EXPORT.QUALITATIVE_QUESTION_ANSWERS}>
                            <Row className="text-center offset-bottom-5">
                                <Col xs={12}>
                                    <SimpleAsyncSelect
                                        isMulti={true}
                                        name="qualitativeMetric"
                                        placeholder={translate('SCORING_METRICS$SCORING_METRIC')}
                                        //  label={(<strong> {translate('SCORING_METRICS$SCORING_METRIC')} </strong>)}
                                        // value={qualitativeMetric}
                                        onChange={v => changeQualitativeMetric(v)}
                                        loadOptions={(name, done) => {
                                            instanceAPI({
                                                method: 'get',
                                                url: `/risk-models/${riskModelId}/qual-metrics`
                                            }).then((items) => done(items)).catch(done.bind(null, []));
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="text-center offset-bottom-5">
                                <Col xs={12}>
                                    <SimpleAsyncSelect
                                        isMulti={true}
                                        name="questionType"
                                        placeholder={translate('SCORING_QUESTIONS$SCORING_TYPE')}
                                        //  label={(<strong> {translate('SCORING_METRICS$SCORING_METRIC')} </strong>)}
                                        // value={qualitativeMetric}
                                        onChange={v => changeScoringType(v)}
                                        sendValueProp
                                        loadOptions={(name, done) => {
                                            let optionsList = [];
                                            for (let i = 0; i < QUESTION_TYPES_LIST.length; i++) {
                                                let item = {id: QUESTION_TYPES_LIST[i].value, name: QUESTION_TYPES_LIST[i].label};
                                                optionsList.push(item);
                                            }
                                            done(optionsList);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="text-center offset-bottom-5">
                                <Col xs={12}>
                                    <DownloadLink disabled={disabled} hint={findHint(hints, `BUTTON_ANSWERS_IMPORT_EXPORT_DOWNLOAD`)} verifyUrl={(url) => {
                                        let result = url;

                                        if (scoringType && scoringType.length) {
                                            for (let j = 0; j < scoringType.length; j++) {
                                                result += '&scoringType=' + scoringType[j].id;
                                            }
                                        }

                                        if (qualitativeMetric && qualitativeMetric.length) {
                                            for (let j = 0; j < qualitativeMetric.length; j++) {
                                                result += '&qualitativeMetric=' + qualitativeMetric[j].id;
                                            }
                                        }

                                        return result;
                                    }} />
                                </Col>
                            </Row>
                        </PermissionAccess>

                        <PermissionAccess permission={PERMISSION.IMPORT.QUALITATIVE_QUESTION_ANSWERS}>
                            <Row>
                                <Col xs={12}  className='dropzone'>
                                    <UploadFile data={data} loading={disabled} uploadFile={uploadFile} />
                                </Col>
                            </Row>
                        </PermissionAccess>

                    </Paper>
                </Col>
            </Row>
        </Container>);
    }
}

export default connect(
    state => ({
        data: state.importAnswers.data,
        qualitativeMetric: state.importAnswers.qualitativeMetric,
        scoringType: state.importAnswers.scoringType,
        message: state.importAnswers.errorMessage,
        disabled: state.importAnswers.expectAnswer,
        riskModelId: state.app.riskModel.id,
        hints: state.importAnswers.hintsData,
    }),
    dispatch => ({
        clear: () => dispatch({type: ACTIONS.CLEAR}),
        uploadFile: file => dispatch({type: ACTIONS.UPLOAD_FILE, file}),
        clearError: () => dispatch({ type: ACTIONS.META, errorMessage: null}),
        changeQualitativeMetric: qualitativeMetric => { dispatch({ type: ACTIONS.DATA, data: {}, qualitativeMetric}); dispatch({ type: ACTIONS.META, qualitativeMetric}) },
        changeScoringType: scoringType => { dispatch({ type: ACTIONS.DATA, data: {}, scoringType}); dispatch({ type: ACTIONS.META, scoringType}) }
    })
)(Edit)

const DownloadLink = withDownloadLink({downloadType: DOWNLOAD_TYPES.SCORING_QUESTION_ANSWERS.CSV_LIST})(props => (
    <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_DATA')} permission={PERMISSION.EXPORT.QUALITATIVE_QUESTION} {...props}>
        <i style={{fontSize: 20}} className="fa fa-download" aria-hidden="true" />&nbsp;&nbsp;
        <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true" />
    </PrimaryBtn>
));

