// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {Field, FieldArray, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import is from '../../../services/is.service';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {translate, withTranslation} from '../../../services/translate.service';
import {AddIconBtn, CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {CONTROL_SUBCATEGORIES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {useParams} from "react-router-dom";
import {LIST} from '../../cyber-roles/actions';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={CONTROL_SUBCATEGORIES_MAP} />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                    <span>
                                        <RichHintTitle
                                            update={LIST}
                                            name={isNew ? translate('CONTROL_GUIDELINES$CREATE_GUIDELINE') : translate('CONTROL_GUIDELINES$EDIT_GUIDELINE')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints, `CONTROL_GUIDELINES_TITLE`)}
                                        />
                                    </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                            <ConnectedError />
                            <ConnectedForm isNew={isNew} />
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({expectAnswer: state.controlSubcategories.edit.expectAnswer, hints: state.controlSubcategories.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.controlSubcategories.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.controlSubcategories.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError} />
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.controlSubcategories.edit.data,
        hints: state.controlSubcategories.edit.hintsData,
        disabled: state.controlSubcategories.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editControlSubcategory',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // control category
        if (!values.controlCategory) {
            errors.controlCategory = 'CONTROL_TESTS$CONTROL_TEST_REQUIRED';
        }
        // assessment weights
        if (values.assessmentWeights && values.assessmentWeights.length) {
            const weightsArrayErrors = [];
            values.assessmentWeights.forEach((weight, i) => {
                const weightErrors = {};
                if (!weight.name) {
                    weightErrors.name = 'GLOBALS$NAME_REQUIRED';
                    weightsArrayErrors[i] = weightErrors;
                }
                if (is.undefined(weight.value)) {
                    weightErrors.value = 'GLOBALS$VALUE_REQUIRED';
                    weightsArrayErrors[i] = weightErrors;
                }
            });
            if (weightsArrayErrors.length) {
                errors.assessmentWeights = weightsArrayErrors;
            }
        }
        return errors;
    }
})(({handleSubmit, hints, invalid, pristine, disabled, update, reset, isNew, cancel}) => (
    <form autoComplete="off" name="editControlSubcategory" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="code"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('CONTROL_GUIDELINES$GUIDELINE_CODE')}
                    label={(<strong> {translate('CONTROL_GUIDELINES$GUIDELINE_CODE')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('CONTROL_GUIDELINES$GUIDELINE_NAME')}
                    label={(<strong
                        className="required-asterisk"> {translate('CONTROL_GUIDELINES$GUIDELINE_NAME')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    disabled={disabled}
                    name="controlCategory"
                    type={ENTITY_TYPES.CONTROL_CATEGORIES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('CONTROL_TESTS$CONTROL_TEST')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-6">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('CONTROL_GUIDELINES$GUIDELINE_DESCRIPTION')}
                    label={(<strong> {translate('CONTROL_GUIDELINES$GUIDELINE_DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <FieldArray name="assessmentWeights" component={ConnectedItems} />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_CONTROL_GUIDELINES_CREATE' : 'BUTTON_CONTROL_GUIDELINES_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_CONTROL_GUIDELINES_RESET')} />
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_CONTROL_GUIDELINES_CANCEL')} />
            </Col>
        </Row>
    </form>
))));

/**
 * component for items of "assessmentWeights" list
 *
 * @param { Object } props
 * @public
 */
const ConnectedItems = withTranslation(connect(
    state => ({disabled: state.controlSubcategories.edit.expectAnswer}),
    null,
)(({fields, meta, disabled}) => (<div className="offset-bottom-4">
    <h3 className="text-uppercase">
        <span className="align-middle">{translate('ASSESSMENTS$ASSESSMENT_WEIGHTS')}</span>&nbsp;
        <AddIconBtn disabled={disabled} onClick={() => fields.push({})} />
    </h3>
    {!meta.error ? '' : (
        <h4 className="text-center is-invalid"><span className="form-text"> {translate(meta.error)} </span></h4>)}
    {fields.map((mKey, index) => (<Row key={index} className="offset-bottom-6">
        <Col xs={6} md={4} className="offset-bottom-2">
            <Field
                component={MdInput}
                disabled={disabled}
                name={`${mKey}.name`}
                placeholder={translate('CONTROL_GUIDELINES$GUIDELINE_NAME')}
                label={(
                    <strong className="required-asterisk"> {translate('CONTROL_GUIDELINES$GUIDELINE_NAME')} </strong>)}
            />
        </Col>
        <Col xs={6} md={4} className="offset-bottom-2">
            <Field
                type="number"
                component={MdInput}
                disabled={disabled}
                name={`${mKey}.value`}
                placeholder={translate('GLOBALS$VALUE')}
                 required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$VALUE')} </strong>)}
            />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-2">
            <Field
                multiline={true}
                component={MdInput}
                disabled={disabled}
                name={`${mKey}.description`}
                placeholder={translate('CONTROL_GUIDELINES$GUIDELINE_DESCRIPTION')}
                label={(<strong> {translate('CONTROL_GUIDELINES$GUIDELINE_DESCRIPTION')} </strong>)}
            />
        </Col>
    </Row>))}
</div>)));
