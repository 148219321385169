import {instanceAPI} from './api.service';

export const ABOUT_HINTS = ['ABOUT_TITLE'];
export const PROFILE_HINTS = ['PROFILE_TITLE', 'BUTTON_PROFILE_CHANGE_PASSWORD', 'BUTTON_PROFILE_RESET_PASSWORD', 'BUTTON_PROFILE_SAVE', 'BUTTON_PROFILE_RESET'];
export const CHANGE_PASSWORD_HINTS = ['CHANGE_PASSWORD_TITLE', 'BUTTON_CHANGE_PASSWORD_SAVE', 'BUTTON_CHANGE_PASSWORD_RESET'];
export const ORGANIZATION_HINTS = ['ORGANIZATION_TITLE', 'BUTTON_ORGANIZATION_SAVE', 'BUTTON_ORGANIZATION_RESET'];
export const SUBSIDIARIES_LIST_HINTS = ['SUBSIDIARY_ORGANIZATIONS_TITLE', 'BUTTON_SUBSIDIARY_ORGANIZATIONS_SCHEME', 'BUTTON_SUBSIDIARY_ORGANIZATIONS_ADD',
    'BUTTON_SUBSIDIARY_ORGANIZATIONS_DELETE', 'BUTTON_SUBSIDIARY_ORGANIZATIONS_EDIT'];
export const SUBSIDIARIES_SCHEME_HINTS = ['SUBSIDIARY_ORGANIZATIONS_SCHEME'];
export const SUBSIDIARIES_EDIT_HINTS = ['SUBSIDIARY_ORGANIZATIONS_TITLE', 'CREATE_SUBSIDIARY_TITLE', 'EDIT_SUBSIDIARY_TITLE', 'BUTTON_SUBSIDIARY_ORGANIZATIONS_CREATE',
    'BUTTON_SUBSIDIARY_ORGANIZATIONS_SAVE', 'BUTTON_SUBSIDIARY_ORGANIZATIONS_RESET', 'BUTTON_SUBSIDIARY_ORGANIZATIONS_CANCEL'];
export const BUSINESS_UNITS_LIST_HINTS = ['BUSINESS_UNITS_TITLE', 'BUTTON_BUSINESS_UNITS_ADD', 'BUTTON_BUSINESS_UNITS_DOWNLOAD_DATA',
    'BUTTON_BUSINESS_UNITS_DOWNLOAD_TEMPLATE', 'BUTTON_BUSINESS_UNITS_UPLOAD_CSV', 'BUTTON_BUSINESS_UNITS_EDIT', 'BUTTON_BUSINESS_UNITS_DELETE'];
export const BUSINESS_UNITS_SCHEME_HINTS = ['BUSINESS_UNITS_SCHEME'];
export const BUSINESS_UNITS_EDIT_HINTS = ['BUSINESS_UNITS_TITLE', 'BUTTON_BUSINESS_UNITS_CREATE', 'BUTTON_BUSINESS_UNITS_SAVE', 'BUTTON_BUSINESS_UNITS_RESET', 'BUTTON_BUSINESS_UNITS_CANCEL'];
export const CYBERSECURITY_MATURITY_HINTS = ['CYBERSECURITY_MATURITY_TITLE', 'CYBERSECURITY_MATURITY_CALCULATED_VALUE', 'BUTTON_CYBERSECURITY_MATURITY_SAVE',
    'BUTTON_CYBERSECURITY_MATURITY_RESET'];
export const ASSET_CLASSES_LIST_HINTS = ['ASSET_CLASSES_TITLE', 'BUTTON_ASSET_CLASSES_ADD', 'BUTTON_ASSET_CLASSES_EDIT', 'BUTTON_ASSET_CLASSES_DELETE'];
export const ASSET_CLASSES_EDIT_HINTS = ['ASSET_CLASSES_TITLE', 'BUTTON_ASSET_CLASSES_CREATE', 'BUTTON_ASSET_CLASSES_SAVE', 'BUTTON_ASSET_CLASSES_RESET', 'BUTTON_ASSET_CLASSES_CANCEL'];
export const DATA_CLASSES_LIST_HINTS = ['DATA_CLASSES_TITLE', 'BUTTON_DATA_CLASSES_ADD', 'BUTTON_DATA_CLASSES_EDIT', 'BUTTON_DATA_CLASSES_DELETE'];
export const DATA_CLASSES_EDIT_HINTS = ['DATA_CLASSES_TITLE', 'BUTTON_DATA_CLASSES_CREATE', 'BUTTON_DATA_CLASSES_SAVE', 'BUTTON_DATA_CLASSES_RESET', 'BUTTON_DATA_CLASSES_CANCEL'];
export const DATA_DOMAINS_LIST_HINTS = ['DATA_DOMAINS_TITLE', 'BUTTON_DATA_DOMAINS_ADD', 'BUTTON_DATA_DOMAINS_EDIT', 'BUTTON_DATA_DOMAINS_DELETE'];
export const DATA_DOMAINS_EDIT_HINTS = ['DATA_DOMAINS_TITLE', 'DATA_DOMAINS_CREATE_TITLE', 'DATA_DOMAINS_EDIT_TITLE', 'BUTTON_DATA_DOMAINS_CREATE',
    'BUTTON_DATA_DOMAINS_SAVE', 'BUTTON_DATA_DOMAINS_RESET', 'BUTTON_DATA_DOMAINS_CANCEL'];
export const SYSTEMS_LIST_HINTS = ['SYSTEMS_TITLE', 'BUTTON_SYSTEMS_ADVANCED_SEARCH', 'BUTTON_SYSTEMS_ADVANCED_SEARCH_APPLY', 'BUTTON_SYSTEMS_ADVANCED_SEARCH_CLOSE',
    'BUTTON_SYSTEMS_DETAILS', 'BUTTON_SYSTEMS_ADD', 'BUTTON_SYSTEMS_EDIT', 'BUTTON_SYSTEMS_DELETE'];
export const SYSTEMS_EDIT_HINTS = ['SYSTEMS_TITLE', 'BUTTON_SYSTEMS_CREATE', 'BUTTON_SYSTEMS_SAVE', 'BUTTON_SYSTEMS_RESET', 'BUTTON_SYSTEMS_CANCEL', 'GEO_RECORDS_PROCESSED_TITLE',
    'GEO_RECORDS_PROCESSED_ADD'];
export const PROCESSES_LIST_HINTS = ['PROCESSES_TITLE', 'BUTTON_PROCESSES_ADVANCED_SEARCH', 'BUTTON_PROCESSES_ADVANCED_SEARCH_APPLY', 'BUTTON_PROCESSES_ADVANCED_SEARCH_CLOSE',
    'BUTTON_PROCESSES_DOWNLOAD_DATA', 'BUTTON_PROCESSES_ADD', 'BUTTON_PROCESSES_EDIT', 'BUTTON_PROCESSES_DELETE', 'BUTTON_PROCESSES_UPLOAD_CSV', 'BUTTON_PROCESSES_DOWNLOAD_TEMPLATE'];
export const PROCESSES_EDIT_HINTS = ['PROCESSES_TITLE', 'BUTTON_PROCESSES_CREATE', 'BUTTON_PROCESSES_SAVE', 'BUTTON_PROCESSES_RESET', 'BUTTON_PROCESSES_CANCEL', 'GEO_RECORDS_PROCESSED_TITLE',
    'GEO_RECORDS_PROCESSED_ADD'];
export const TECHNOLOGY_CATEGORIES_LIST_HINTS = ['TECHNOLOGY_CATEGORIES_TITLE', 'BUTTON_TECHNOLOGY_CATEGORIES_ADD', 'BUTTON_TECHNOLOGY_CATEGORIES_EDIT', 'BUTTON_TECHNOLOGY_CATEGORIES_DELETE'];
export const TECHNOLOGY_CATEGORIES_EDIT_HINTS = ['TECHNOLOGY_CATEGORIES_TITLE', 'BUTTON_TECHNOLOGY_CATEGORIES_CREATE', 'BUTTON_TECHNOLOGY_CATEGORIES_SAVE', 'BUTTON_TECHNOLOGY_CATEGORIES_RESET',
    'BUTTON_TECHNOLOGY_CATEGORIES_CANCEL'];
export const TECHNOLOGIES_LIST_HINTS = ['TECHNOLOGIES_TITLE', 'BUTTON_TECHNOLOGIES_DOWNLOAD_DATA', 'BUTTON_TECHNOLOGIES_ADD', 'BUTTON_TECHNOLOGIES_EDIT', 'BUTTON_TECHNOLOGIES_DELETE',
    'BUTTON_TECHNOLOGIES_UPLOAD_CSV', 'BUTTON_TECHNOLOGIES_DOWNLOAD_TEMPLATE'];
export const TECHNOLOGIES_EDIT_HINTS = ['TECHNOLOGIES_TITLE', 'BUTTON_TECHNOLOGIES_CREATE', 'BUTTON_TECHNOLOGIES_SAVE', 'BUTTON_TECHNOLOGIES_RESET', 'BUTTON_TECHNOLOGIES_CANCEL'];

export const DATA_EXFILTRATION_LIST_HINTS = ['DATA_EXFILTRATION_TITLE', 'BUTTON_DATA_EXFILTRATION_ADD', 'BUTTON_DATA_EXFILTRATION_EDIT', 'BUTTON_DATA_EXFILTRATION_DELETE',
    'BUTTON_DATA_EXFILTRATION_GEO_RECORDS_PROCESSED', 'BUTTON_DATA_EXFILTRATION_SAVE'];
export const DATA_EXFILTRATION_EDIT_HINTS = ['DATA_EXFILTRATION_TITLE', 'BUTTON_DATA_EXFILTRATION_CREATE', 'BUTTON_DATA_EXFILTRATION_SAVE', 'BUTTON_DATA_EXFILTRATION_RESET',
    'BUTTON_DATA_EXFILTRATION_CANCEL'];
export const GEO_RECORDS_PROCESSED_HINTS = ['GEO_RECORDS_PROCESSED_TITLE', 'BUTTON_GEO_RECORDS_PROCESSED_SAVE', 'BUTTON_GEO_RECORDS_PROCESSED_ADD', 'BUTTON_GEO_RECORDS_PROCESSED_CREATE',
    'BUTTON_GEO_RECORDS_PROCESSED_CANCEL', 'BUTTON_GEO_RECORDS_PROCESSED_RESET', 'BUTTON_GEO_RECORDS_PROCESSED_DELETE'];
export const BUSINESS_INTERRUPTION_HINTS = ['BUSINESS_INTERRUPTION_TITLE', 'BUTTON_BUSINESS_INTERRUPTION_EDIT', 'BUTTON_BUSINESS_INTERRUPTION_SAVE', 'BUTTON_BUSINESS_INTERRUPTION_DELETE'];
export const CYBER_RISK_SCORING_HINTS = ['CYBER_RISK_SCORING_TITLE', 'BUTTON_CYBER_RISK_SCORING_EDIT', 'BUTTON_CYBER_RISK_SCORING_ADD', 'BUTTON_CYBER_RISK_SCORING_DELETE',
    'BUTTON_CYBER_RISK_SCORING_REASSIGN', 'BUTTON_CYBER_RISK_SCORING_QUESTIONS', 'BUTTON_CYBER_RISK_SCORING_ADVANCED_SEARCH', 'BUTTON_CYBER_RISK_SCORING_ADVANCED_SEARCH_APPLY',
    'BUTTON_CYBER_RISK_SCORING_ADVANCED_SEARCH_CLOSE'];
export const CYBER_RISK_SCORING_QUESTIONS_HINTS = ['SCORING_QUESTIONS_TITLE', 'BUTTON_SCORING_QUESTIONS_ADD', 'BUTTON_SCORING_QUESTIONS_DELETE', 'BUTTON_SCORING_QUESTIONS_EDIT',
    'BUTTON_SCORING_QUESTIONS_CANCEL', 'BUTTON_SCORING_QUESTIONS_RESET', 'BUTTON_SCORING_QUESTIONS_SAVE'];
export const REASSIGN_DIALOG_HINTS = ['REASSIGN_DIALOG_TITLE', 'BUTTON_REASSIGN_DIALOG_SAVE', 'BUTTON_REASSIGN_DIALOG_RESET', 'BUTTON_REASSIGN_DIALOG_CANCEL'];
export const POLICY_MANAGEMENT_LIST_HINTS = ['POLICY_MANAGEMENT_TITLE', 'BUTTON_POLICY_MANAGEMENT_ADD', 'BUTTON_POLICY_MANAGEMENT_ANNUAL_REVIEW',  'BUTTON_POLICY_MANAGEMENT_APPROVAL',
    'BUTTON_POLICY_MANAGEMENT_EDIT', 'BUTTON_POLICY_MANAGEMENT_DELETE'];
export const POLICY_MANAGEMENT_EDIT_HINTS = ['POLICY_MANAGEMENT_TITLE', 'BUTTON_POLICY_MANAGEMENT_CREATE', 'BUTTON_POLICY_MANAGEMENT_SAVE', 'BUTTON_POLICY_MANAGEMENT_RESET',
    'BUTTON_POLICY_MANAGEMENT_CANCEL'];
export const POLICY_MANAGEMENT_ANNUAL_REVIEW_HINTS = ['BUTTON_POLICY_MANAGEMENT_ANNUAL_REVIEW', 'POLICY_MANAGEMENT_ANNUAL_REVIEW_TITLE', 'POLICY_MANAGEMENT_TITLE', 'BUTTON_POLICY_MANAGEMENT_CREATE', 'BUTTON_POLICY_MANAGEMENT_SAVE', 'BUTTON_POLICY_MANAGEMENT_RESET',
    'BUTTON_POLICY_MANAGEMENT_CANCEL'];
export const POLICY_MANAGEMENT_PREVIEW_HINTS = ['BUTTON_POLICY_MANAGEMENT_ANNUAL_REVIEW', 'POLICY_MANAGEMENT_ANNUAL_REVIEW_TITLE', 'POLICY_MANAGEMENT_TITLE', 'BUTTON_POLICY_MANAGEMENT_CREATE', 'BUTTON_POLICY_MANAGEMENT_SAVE', 'BUTTON_POLICY_MANAGEMENT_RESET',
    'BUTTON_POLICY_MANAGEMENT_CANCEL'];
export const CYBER_ROLES_LIST_HINTS = ['CYBER_ROLES_TITLE', 'BUTTON_CYBER_ROLES_ADD', 'BUTTON_CYBER_ROLES_EDIT', 'BUTTON_CYBER_ROLES_DELETE'];
export const CYBER_ROLES_EDIT_HINTS = ['CYBER_ROLES_TITLE', 'BUTTON_CYBER_ROLES_CREATE', 'BUTTON_CYBER_ROLES_SAVE', 'BUTTON_CYBER_ROLES_RESET', 'BUTTON_CYBER_ROLES_CANCEL'];
export const CONTROL_FUNCTIONS_LIST_HINTS = ['CONTROL_FUNCTIONS_TITLE', 'BUTTON_CONTROL_FUNCTIONS_ADD', 'BUTTON_CONTROL_FUNCTIONS_EDIT', 'BUTTON_CONTROL_FUNCTIONS_DELETE'];
export const CONTROL_FUNCTIONS_EDIT_HINTS = ['CONTROL_FUNCTIONS_TITLE', 'BUTTON_CONTROL_FUNCTIONS_CREATE', 'BUTTON_CONTROL_FUNCTIONS_SAVE', 'BUTTON_CONTROL_FUNCTIONS_RESET',
    'BUTTON_CONTROL_FUNCTIONS_CANCEL'];
export const CONTROL_TESTS_LIST_HINTS = ['CONTROL_TESTS_TITLE', 'BUTTON_CONTROL_TESTS_ADD', 'BUTTON_CONTROL_TESTS_EDIT', 'BUTTON_CONTROL_TESTS_DELETE'];
export const CONTROL_TESTS_EDIT_HINTS = ['CONTROL_TESTS_TITLE', 'BUTTON_CONTROL_TESTS_CREATE', 'BUTTON_CONTROL_TESTS_SAVE', 'BUTTON_CONTROL_TESTS_RESET', 'BUTTON_CONTROL_TESTS_CANCEL'];
export const CONTROL_GUIDELINES_LIST_HINTS = ['CONTROL_GUIDELINES_TITLE', 'BUTTON_CONTROL_GUIDELINES_ADD', 'BUTTON_CONTROL_GUIDELINES_EDIT', 'BUTTON_CONTROL_GUIDELINES_DELETE'];
export const CONTROL_GUIDELINES_EDIT_HINTS = ['CONTROL_GUIDELINES_TITLE', 'BUTTON_CONTROL_GUIDELINES_CREATE', 'BUTTON_CONTROL_GUIDELINES_SAVE', 'BUTTON_CONTROL_GUIDELINES_RESET',
    'BUTTON_CONTROL_GUIDELINES_CANCEL'];
export const CONTROL_MATURITIES_LIST_HINTS = ['CONTROL_MATURITIES_TITLE', 'BUTTON_CONTROL_MATURITIES_ADD', 'BUTTON_CONTROL_MATURITIES_EDIT', 'BUTTON_CONTROL_MATURITIES_DELETE'];
export const CONTROL_MATURITIES_EDIT_HINTS = ['CONTROL_MATURITIES_TITLE', 'BUTTON_CONTROL_MATURITIES_CREATE', 'BUTTON_CONTROL_MATURITIES_SAVE', 'BUTTON_CONTROL_MATURITIES_RESET',
    'BUTTON_CONTROL_MATURITIES_CANCEL'];
export const USERS_LIST_HINTS = ['USERS_TITLE', 'BUTTON_USERS_ADD', 'BUTTON_USERS_ADVANCED_SEARCH', 'BUTTON_USERS_ADVANCED_SEARCH_APPLY', 'BUTTON_USERS_ADVANCED_SEARCH_CLOSE',
    'BUTTON_USERS_EDIT', 'BUTTON_USERS_DELETE', 'BUTTON_USERS_DOWNLOAD_DATA', 'BUTTON_USERS_UPLOAD_CSV'];
export const USERS_EDIT_HINTS = ['USERS_TITLE', 'USERS_CREATE_TITLE', 'USERS_EDIT_TITLE', 'BUTTON_USERS_CREATE', 'BUTTON_USERS_SAVE', 'BUTTON_USERS_RESET', 'BUTTON_USERS_CANCEL'];
export const DOMAINS_LIST_HINTS = ['DOMAINS_TITLE', 'BUTTON_DOMAINS_ADD', 'BUTTON_DOMAINS_EDIT', 'BUTTON_DOMAINS_DELETE'];
export const DOMAINS_EDIT_HINTS = ['DOMAINS_TITLE', 'BUTTON_DOMAINS_CREATE', 'BUTTON_DOMAINS_SAVE', 'BUTTON_DOMAINS_RESET', 'BUTTON_DOMAINS_CANCEL'];
export const CATEGORIES_LIST_HINTS = ['CATEGORIES_TITLE', 'BUTTON_CATEGORIES_ADD', 'BUTTON_CATEGORIES_EDIT', 'BUTTON_CATEGORIES_DELETE'];
export const CATEGORIES_EDIT_HINTS = ['CATEGORIES_TITLE', 'BUTTON_CATEGORIES_CREATE', 'BUTTON_CATEGORIES_SAVE', 'BUTTON_CATEGORIES_RESET', 'BUTTON_CATEGORIES_CANCEL'];
export const CONSTANTS_LIST_HINTS = ['CONSTANTS_TITLE', 'BUTTON_CONSTANTS_ADD', 'BUTTON_CONSTANTS_EDIT', 'BUTTON_CONSTANTS_DELETE'];
export const CONSTANTS_EDIT_HINTS = ['CONSTANTS_TITLE', 'BUTTON_CONSTANTS_CREATE', 'BUTTON_CONSTANTS_SAVE', 'BUTTON_CONSTANTS_RESET', 'BUTTON_CONSTANTS_CANCEL'];
export const EXPOSURE_MODELS_LIST_HINTS = ['EXPOSURE_MODELS_TITLE', 'BUTTON_EXPOSURE_MODELS_ADD', 'BUTTON_EXPOSURE_MODELS_EDIT', 'BUTTON_EXPOSURE_MODELS_DELETE'];
export const EXPOSURE_MODELS_EDIT_HINTS = ['EXPOSURE_MODELS_TITLE', 'BUTTON_EXPOSURE_MODELS_CREATE', 'BUTTON_EXPOSURE_MODELS_SAVE', 'BUTTON_EXPOSURE_MODELS_RESET',
    'BUTTON_EXPOSURE_MODELS_CANCEL'];
export const EXPOSURE_METRICS_LIST_HINTS = ['EXPOSURE_METRICS_TITLE', 'BUTTON_EXPOSURE_METRICS_ADD', 'BUTTON_EXPOSURE_METRICS_EDIT', 'BUTTON_EXPOSURE_METRICS_DELETE'];
export const EXPOSURE_METRICS_EDIT_HINTS = ['EXPOSURE_METRICS_TITLE', 'EXPOSURE_METRICS_CREATE_TITLE', 'EXPOSURE_METRICS_EDIT_TITLE',
    'BUTTON_EXPOSURE_METRICS_CREATE', 'BUTTON_EXPOSURE_METRICS_SAVE', 'BUTTON_EXPOSURE_METRICS_RESET', 'BUTTON_EXPOSURE_METRICS_CANCEL'];
export const SCORING_METRICS_LIST_HINTS = ['SCORING_METRICS_TITLE', 'BUTTON_SCORING_METRICS_ADD', 'BUTTON_SCORING_METRICS_EDIT', 'BUTTON_SCORING_METRICS_DELETE'];
export const SCORING_METRICS_EDIT_HINTS = ['SCORING_METRICS_TITLE', 'BUTTON_SCORING_METRICS_CREATE', 'BUTTON_SCORING_METRICS_SAVE', 'BUTTON_SCORING_METRICS_RESET',
    'BUTTON_SCORING_METRICS_CANCEL'];
export const SCORING_QUESTIONS_LIST_HINTS = ['SCORING_QUESTIONS_TITLE', 'BUTTON_SCORING_QUESTIONS_ADD', 'BUTTON_SCORING_QUESTIONS_EDIT', 'BUTTON_SCORING_QUESTIONS_DELETE',
    'BUTTON_SCORING_QUESTIONS_DOWNLOAD_CSV', 'BUTTON_SCORING_QUESTIONS_ADVANCED_SEARCH', 'BUTTON_SCORING_QUESTIONS_ADVANCED_SEARCH_APPLY', 'BUTTON_SCORING_QUESTIONS_ADVANCED_SEARCH_CLOSE'];
export const SCORING_QUESTIONS_EDIT_HINTS = ['SCORING_QUESTIONS_TITLE', 'SCORING_QUESTIONS_CREATE_TITLE', 'SCORING_QUESTIONS_EDIT_TITLE','BUTTON_SCORING_QUESTIONS_CREATE', 'BUTTON_SCORING_QUESTIONS_SAVE', 'BUTTON_SCORING_QUESTIONS_RESET',
    'BUTTON_SCORING_QUESTIONS_CANCEL', 'BUTTON_SCORING_QUESTIONS_CLONE'];
export const RISK_METRICS_LIST_HINTS = ['RISK_METRICS_TITLE', 'BUTTON_RISK_METRICS_ADD', 'BUTTON_RISK_METRICS_EDIT', 'BUTTON_RISK_METRICS_DELETE'];
export const RISK_METRICS_EDIT_HINTS = ['RISK_METRICS_TITLE', 'BUTTON_RISK_METRICS_CREATE', 'BUTTON_RISK_METRICS_SAVE', 'BUTTON_RISK_METRICS_RESET', 'BUTTON_RISK_METRICS_CANCEL'];
export const ASSOCIATE_MODELS_LIST_HINTS = ['ASSOCIATE_MODELS_TITLE', 'BUTTON_ASSOCIATE_MODELS_ADD', 'BUTTON_ASSOCIATE_MODELS_EDIT', 'BUTTON_ASSOCIATE_MODELS_DELETE'];
export const ASSOCIATE_MODELS_EDIT_HINTS = ['ASSOCIATE_MODELS_TITLE', 'BUTTON_ASSOCIATE_MODELS_CREATE', 'BUTTON_ASSOCIATE_MODELS_SAVE', 'BUTTON_ASSOCIATE_MODELS_RESET',
    'BUTTON_ASSOCIATE_MODELS_CANCEL'];
export const SECURITY_REQUIREMENTS_LIST_HINTS = ['SECURITY_REQUIREMENTS_TITLE', 'BUTTON_SECURITY_REQUIREMENTS_ADD', 'BUTTON_SECURITY_REQUIREMENTS_EDIT', 'BUTTON_SECURITY_REQUIREMENTS_DELETE'];
export const SECURITY_REQUIREMENTS_EDIT_HINTS = ['SECURITY_REQUIREMENTS_TITLE', 'BUTTON_SECURITY_REQUIREMENTS_CREATE', 'BUTTON_SECURITY_REQUIREMENTS_SAVE',
    'BUTTON_SECURITY_REQUIREMENTS_RESET', 'BUTTON_SECURITY_REQUIREMENTS_CANCEL'];
export const ASSESSMENTS_LIST_HINTS = ['ASSESSMENTS_TITLE', 'BUTTON_ASSESSMENTS_ADD', 'BUTTON_ASSESSMENTS_EDIT', 'BUTTON_ASSESSMENTS_DELETE'];
export const ASSESSMENTS_EDIT_HINTS = ['ASSESSMENTS_TITLE', 'BUTTON_ASSESSMENTS_CREATE', 'BUTTON_ASSESSMENTS_SAVE', 'BUTTON_ASSESSMENTS_RESET', 'BUTTON_ASSESSMENTS_CANCEL'];
export const ORGANIZATION_CONTROL_TEST_RESULTS_LIST_HINTS = ['ORGANIZATION_CONTROL_TEST_RESULTS_TITLE', 'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_ADD',
    'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_EDIT', 'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_DELETE'];
export const ORGANIZATION_CONTROL_TEST_RESULTS_EDIT_HINTS = ['ORGANIZATION_CONTROL_TEST_RESULTS_TITLE', 'ORGANIZATION_CONTROL_TEST_RESULTS_EDIT_TITLE', 'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_CREATE',
    'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_SAVE', 'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_RESET', 'BUTTON_ORGANIZATION_CONTROL_TEST_RESULTS_CANCEL'];
export const SYSTEM_CONTROL_TEST_RESULTS_LIST_HINTS = ['SYSTEM_CONTROL_TEST_RESULTS_TITLE', 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_DRILLDOWN'];
export const SYSTEM_CONTROL_TEST_RESULTS_DRILLDOWN_HINTS = ['SYSTEM_CONTROL_TEST_RESULTS_DRILLDOWN_TITLE', 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_BACK',
    'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_DOWNLOAD_CSV', 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_EDIT'];
export const SYSTEM_CONTROL_TEST_RESULTS_EDIT_HINTS = ['SYSTEM_CONTROL_TEST_RESULTS_EDIT_TITLE', 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_CREATE',
    'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_SAVE', 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_RESET', 'BUTTON_SYSTEM_CONTROL_TEST_RESULTS_CANCEL'];
export const ASSESSMENT_FINDINGS_LIST_HINTS = ['ASSESSMENT_FINDINGS_TITLE', 'BUTTON_ASSESSMENT_FINDINGS_ADD', 'BUTTON_ASSESSMENT_FINDINGS_EDIT', 'BUTTON_ASSESSMENT_FINDINGS_DELETE'];
export const ASSESSMENT_FINDINGS_EDIT_HINTS = ['ASSESSMENT_FINDINGS_EDIT_TITLE', 'ASSESSMENT_FINDINGS_CREATE_TITLE', 'BUTTON_ASSESSMENT_FINDINGS_CREATE',
    'BUTTON_ASSESSMENT_FINDINGS_SAVE', 'BUTTON_ASSESSMENT_FINDINGS_RESET', 'BUTTON_ASSESSMENT_FINDINGS_CANCEL'];
export const VULNERABILITIES_LIST_HINTS = ['VULNERABILITIES_TITLE', 'BUTTON_VULNERABILITIES_ADD', 'BUTTON_VULNERABILITIES_EDIT', 'BUTTON_VULNERABILITIES_DELETE'];
export const VULNERABILITIES_EDIT_HINTS = ['VULNERABILITIES_TITLE', 'VULNERABILITIES_EDIT_TITLE', 'VULNERABILITIES_CREATE_TITLE', 'BUTTON_VULNERABILITIES_CREATE',
    'BUTTON_VULNERABILITIES_SAVE', 'BUTTON_VULNERABILITIES_RESET', 'BUTTON_VULNERABILITIES_CANCEL'];
export const TASKS_LIST_HINTS = ['TASKS_TITLE', 'BUTTON_TASKS_ADD', 'BUTTON_TASKS_EDIT', 'BUTTON_TASKS_DELETE'];
export const TASKS_EDIT_HINTS = ['TASKS_TITLE', 'TASKS_EDIT_TITLE', 'TASKS_CREATE_TITLE', 'BUTTON_TASKS_CREATE', 'BUTTON_TASKS_SAVE', 'BUTTON_TASKS_RESET', 'BUTTON_TASKS_CANCEL'];
export const TASK_CATEGORIES_LIST_HINTS = ['TASK_CATEGORIES_TITLE', 'BUTTON_TASK_CATEGORIES_ADD', 'BUTTON_TASK_CATEGORIES_EDIT', 'BUTTON_TASK_CATEGORIES_DELETE'];
export const TASK_CATEGORIES_EDIT_HINTS = ['TASK_CATEGORIES_TITLE', 'TASK_CATEGORIES_EDIT_TITLE', 'TASK_CATEGORIES_CREATE_TITLE', 'BUTTON_TASK_CATEGORIES_CREATE',
    'BUTTON_TASK_CATEGORIES_SAVE', 'BUTTON_TASK_CATEGORIES_RESET', 'BUTTON_TASK_CATEGORIES_CANCEL'];
export const VENDORS_LIST_HINTS = ['VENDORS_TITLE', 'BUTTON_VENDORS_ADD', 'BUTTON_VENDORS_EDIT', 'BUTTON_VENDORS_DELETE', 'BUTTON_VENDORS_REASSIGN',
    'BUTTON_VENDORS_LEVEL_1_QUESTIONS', 'BUTTON_VENDORS_LEVEL_2_QUESTIONS'];
export const VENDORS_EDIT_HINTS = ['VENDORS_TITLE', 'VENDORS_EDIT_TITLE', 'VENDORS_CREATE_TITLE', 'BUTTON_VENDORS_CREATE',
    'BUTTON_VENDORS_SAVE', 'BUTTON_VENDORS_RESET', 'BUTTON_VENDORS_CANCEL'];
export const VENDORS_QUESTIONS_HINTS = ['VENDORS_TITLE', 'VENDORS_LEVEL_1_QUESTIONS_TITLE', 'VENDORS_LEVEL_2_QUESTIONS_TITLE',
    'BUTTON_QUESTIONS_SAVE', 'BUTTON_QUESTIONS_RESET', 'BUTTON_QUESTIONS_CANCEL', 'BUTTON_QUESTIONS_BACK'];
export const CLOUD_SCORING_LIST_HINTS = ['CLOUD_SCORING_TITLE', 'BUTTON_CLOUD_SCORING_ADD', 'BUTTON_CLOUD_SCORING_EDIT', 'BUTTON_CLOUD_SCORING_DELETE',
    'BUTTON_CLOUD_SCORING_REASSIGN', 'BUTTON_CLOUD_SCORING_LEVEL_1_QUESTIONS', 'BUTTON_CLOUD_SCORING_LEVEL_2_QUESTIONS'];
export const CLOUD_SCORING_QUESTIONS_HINTS = ['CLOUD_SCORING_TITLE', 'CLOUD_SCORING_LEVEL_1_QUESTIONS_TITLE', 'CLOUD_SCORING_LEVEL_2_QUESTIONS_TITLE',
    'BUTTON_CLOUD_SCORING_QUESTIONS_SAVE', 'BUTTON_CLOUD_SCORING_QUESTIONS_RESET', 'BUTTON_CLOUD_SCORING_QUESTIONS_CANCEL', 'BUTTON_CLOUD_SCORING_QUESTIONS_BACK'];
export const ASSOCIATE_SYSTEMS_LIST_HINTS = ['ASSOCIATE_SYSTEMS_TITLE', 'BUTTON_ASSOCIATE_SYSTEMS_ADD', 'BUTTON_ASSOCIATE_SYSTEMS_EDIT', 'BUTTON_ASSOCIATE_SYSTEMS_DELETE'];
export const ASSOCIATE_SYSTEMS_EDIT_HINTS = ['ASSOCIATE_SYSTEMS_TITLE', 'ASSOCIATE_SYSTEMS_EDIT_TITLE', 'ASSOCIATE_SYSTEMS_CREATE_TITLE', 'BUTTON_ASSOCIATE_SYSTEMS_CREATE',
    'BUTTON_ASSOCIATE_SYSTEMS_SAVE', 'BUTTON_ASSOCIATE_SYSTEMS_RESET', 'BUTTON_ASSOCIATE_SYSTEMS_CANCEL'];
export const GDPR_ORGANIZATION_COMPLIANCE_LIST_HINTS = ['GDPR_ORGANIZATION_COMPLIANCE_TITLE', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_ADD', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_EDIT',
    'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_DELETE', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_SCORING_QUESTIONS', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_LOG'];
export const GDPR_ORGANIZATION_COMPLIANCE_EDIT_HINTS = ['GDPR_ORGANIZATION_COMPLIANCE_TITLE', 'GDPR_ORGANIZATION_COMPLIANCE_EDIT_TITLE', 'GDPR_ORGANIZATION_COMPLIANCE_CREATE_TITLE',
    'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_CREATE', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_SAVE', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_RESET', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_CANCEL'];
export const GDPR_ORGANIZATION_COMPLIANCE_QUESTIONS_HINTS = ['GDPR_ORGANIZATION_COMPLIANCE_SCORING_QUESTIONS_TITLE', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_SCORING_QUESTIONS_SAVE',
    'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_SCORING_QUESTIONS_RESET', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_SCORING_QUESTIONS_CANCEL', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_SCORING_QUESTIONS_BACK'];
export const GDPR_ORGANIZATION_COMPLIANCE_STATUS_LOG_HINTS = ['GDPR_ORGANIZATION_COMPLIANCE_STATUS_LOG_TITLE', 'BUTTON_GDPR_ORGANIZATION_COMPLIANCE_STATUS_LOG_BACK'];
export const GDPR_SYSTEM_COMPLIANCE_LIST_HINTS = ['GDPR_SYSTEM_COMPLIANCE_TITLE', 'BUTTON_GDPR_SYSTEM_COMPLIANCE_DRILLDOWN', 'BUTTON_GDPR_SYSTEM_COMPLIANCE_SCORING_QUESTIONS',
    'BUTTON_GDPR_SYSTEM_COMPLIANCE_STATUS_LOG'];
export const GDPR_SYSTEM_COMPLIANCE_DRILLDOWN_HINTS = ['GDPR_SYSTEM_COMPLIANCE_DRILLDOWN_TITLE', 'BUTTON_GDPR_SYSTEM_COMPLIANCE_SCORING_QUESTIONS_BACK'];
export const GDPR_SYSTEM_COMPLIANCE_QUESTIONS_HINTS = ['GDPR_SYSTEM_COMPLIANCE_SCORING_QUESTIONS_TITLE', 'BUTTON_GDPR_SYSTEM_COMPLIANCEE_SCORING_QUESTIONS_SAVE',
    'BUTTON_GDPR_SYSTEM_COMPLIANCE_SCORING_QUESTIONS_RESET', 'BUTTON_GDPR_SYSTEM_COMPLIANCE_SCORING_QUESTIONS_CANCEL'];
export const GDPR_SYSTEM_COMPLIANCE_STATUS_LOG_HINTS = ['GDPR_SYSTEM_COMPLIANCE_STATUS_LOG_TITLE', 'BUTTON_GDPR_SYSTEM_COMPLIANCE_STATUS_LOG_BACK'];
export const GDPR_EVIDENCE_DOCUMENTS_LIST_HINTS = ['GDPR_EVIDENCE_DOCUMENTS_TITLE', 'BUTTON_GDPR_EVIDENCE_DOCUMENTS_ADD', 'BUTTON_GDPR_EVIDENCE_DOCUMENTS_EDIT',
    'BUTTON_GDPR_EVIDENCE_DOCUMENTS_DELETE'];
export const GDPR_EVIDENCE_DOCUMENTS_EDIT_HINTS = ['GDPR_EVIDENCE_DOCUMENTS_TITLE', 'GDPR_EVIDENCE_DOCUMENTS_EDIT_TITLE', 'GDPR_EVIDENCE_DOCUMENTS_CREATE_TITLE', 'BUTTON_GDPR_EVIDENCE_DOCUMENTS_CREATE',
    'BUTTON_GDPR_EVIDENCE_DOCUMENTS_SAVE', 'BUTTON_GDPR_EVIDENCE_DOCUMENTS_RESET', 'BUTTON_GDPR_EVIDENCE_DOCUMENTS_CANCEL'];
export const VENDOR_CONTRACTS_LIST_HINTS = ['VENDOR_CONTRACTS_TITLE', 'BUTTON_VENDOR_CONTRACT_ADD', 'BUTTON_VENDOR_CONTRACT_EDIT', 'BUTTON_VENDOR_CONTRACT_DELETE'];
export const VENDOR_CONTRACTS_EDIT_HINTS = ['VENDOR_CONTRACT_EDIT_TITLE', 'VENDOR_CONTRACT_CREATE_TITLE', 'BUTTON_VENDOR_CONTRACT_SAVE', 'BUTTON_VENDOR_CONTRACT_RESET', 'BUTTON_VENDOR_CONTRACT_CANCEL'];
export const FIXED_CAPITAL_COSTS_LIST_HINTS = ['FIXED_CAPITAL_COSTS_TITLE', 'BUTTON_FIXED_CAPITAL_COSTS_ADD', 'BUTTON_FIXED_CAPITAL_COSTS_EDIT', 'BUTTON_FIXED_CAPITAL_COSTS_DELETE'];
export const FIXED_CAPITAL_COSTS_EDIT_HINTS = ['FIXED_CAPITAL_COSTS_TITLE', 'FIXED_CAPITAL_COSTS_EDIT_TITLE', 'FIXED_CAPITAL_COSTS_CREATE_TITLE', 'BUTTON_FIXED_CAPITAL_COSTS_CREATE',
    'BUTTON_FIXED_CAPITAL_COSTS_SAVE', 'BUTTON_FIXED_CAPITAL_COSTS_RESET', 'BUTTON_FIXED_CAPITAL_COSTS_CANCEL'];
export const VARIABLE_COSTS_LIST_HINTS = ['VARIABLE_COSTS_TITLE', 'BUTTON_VARIABLE_COSTS_ADD', 'BUTTON_VARIABLE_COSTS_EDIT', 'BUTTON_VARIABLE_COSTS_DELETE'];
export const VARIABLE_COSTS_EDIT_HINTS = ['VARIABLE_COSTS_TITLE', 'VARIABLE_COSTS_EDIT_TITLE', 'VARIABLE_COSTS_CREATE_TITLE', 'BUTTON_VARIABLE_COSTS_CREATE',
    'BUTTON_VARIABLE_COSTS_SAVE', 'BUTTON_VARIABLE_COSTS_RESET', 'BUTTON_VARIABLE_COSTS_CANCEL'];
export const SYSTEM_RISK_REDUCTION_LIST_HINTS = ['SYSTEM_RISK_REDUCTION_TITLE', 'BUTTON_SYSTEM_RISK_REDUCTION_SAVE'];
export const USER_SETTINGS_LIST_HINTS = ['USER_SETTINGS_TITLE', 'BUTTON_USER_SETTINGS_EDIT'];
export const USER_SETTINGS_EDIT_HINTS = ['USER_SETTINGS_TITLE', 'USER_SETTINGS_EDIT_TITLE', 'BUTTON_USER_SETTINGS_SAVE', 'BUTTON_VUSER_SETTINGS_RESET', 'BUTTON_USER_SETTINGS_CANCEL'];
export const BUSINESS_UNITS_IMPORT_EXPORT_HINTS = ['BUSINESS_UNITS_IMPORT_EXPORT_TITLE', 'BUTTON_BUSINESS_UNITS_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_BUSINESS_UNITS_IMPORT_EXPORT_UPLOAD'];
export const CONTROL_GUIDELINES_IMPORT_EXPORT_HINTS = ['CONTROL_GUIDELINES_IMPORT_EXPORT_TITLE', 'BUTTON_CONTROL_GUIDELINES_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_CONTROL_GUIDELINES_IMPORT_EXPORT_UPLOAD'];
export const GDPR_ARTICLES_IMPORT_EXPORT_HINTS = ['GDPR_ARTICLES_IMPORT_EXPORT_TITLE', 'BUTTON_GDPR_ARTICLES_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_GDPR_ARTICLES_IMPORT_EXPORT_UPLOAD'];
export const GDPR_EVIDENCE_ARTICLES_IMPORT_EXPORT_HINTS = ['GDPR_EVIDENCE_ARTICLES_IMPORT_EXPORT_TITLE', 'BUTTON_GDPR_EVIDENCE_ARTICLES_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_GDPR_EVIDENCE_ARTICLES_IMPORT_EXPORT_UPLOAD'];
export const PROCESSES_IMPORT_EXPORT_HINTS = ['PROCESSES_IMPORT_EXPORT_TITLE', 'BUTTON_PROCESSES_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_PROCESSES_IMPORT_EXPORT_UPLOAD'];
export const SCORING_QUESTIONS_IMPORT_EXPORT_HINTS = ['SCORING_QUESTIONS_IMPORT_EXPORT_TITLE', 'BUTTON_SCORING_QUESTIONS_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_SCORING_QUESTIONS_IMPORT_EXPORT_UPLOAD'];
export const ANSWERS_IMPORT_EXPORT_HINTS = ['ANSWERS_IMPORT_EXPORT_TITLE', 'BUTTON_ANSWERS_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_ANSWERS_IMPORT_EXPORT_UPLOAD'];
export const SYSTEMS_RISK_IMPORT_EXPORT_HINTS = ['SYSTEMS_RISK_IMPORT_EXPORT_TITLE', 'BUTTON_SYSTEMS_RISK_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_SYSTEMS_RISK_IMPORT_EXPORT_UPLOAD'];
export const TECHNOLOGIES_IMPORT_EXPORT_HINTS = ['TECHNOLOGIES_IMPORT_EXPORT_TITLE', 'BUTTON_TECHNOLOGIES_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_TECHNOLOGIES_IMPORT_EXPORT_UPLOAD'];
export const USERS_IMPORT_EXPORT_HINTS = ['USERS_IMPORT_EXPORT_TITLE', 'BUTTON_USERS_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_USERS_IMPORT_EXPORT_UPLOAD'];
export const VENDORS_IMPORT_EXPORT_HINTS = ['VENDORS_IMPORT_EXPORT_TITLE', 'BUTTON_VENDORS_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_VENDORS_IMPORT_EXPORT_UPLOAD'];
export const SUBSIDIARIES_IMPORT_EXPORT_HINTS = ['SUBSIDIARIES_IMPORT_EXPORT_TITLE', 'BUTTON_SUBSIDIARIES_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_SUBSIDIARIES_IMPORT_EXPORT_UPLOAD'];
export const SECURITY_FRAMEWORKS_IMPORT_EXPORT_HINTS = ['SECURITY_FRAMEWORKS_IMPORT_EXPORT_TITLE', 'BUTTON_SECURITY_FRAMEWORKS_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_SECURITY_FRAMEWORKS_IMPORT_EXPORT_UPLOAD'];
export const SECURITY_REQUIREMENTS_IMPORT_EXPORT_HINTS = ['SECURITY_REQUIREMENTS_IMPORT_EXPORT_TITLE', 'BUTTON_SECURITY_REQUIREMENTS_IMPORT_EXPORT_DOWNLOAD', 'BUTTON_SECURITY_REQUIREMENTS_IMPORT_EXPORT_UPLOAD'];
export const AUDIT_LOGS_LIST_HINTS = ['AUDIT_LOGS_TITLE', 'BUTTON_AUDIT_LOGS_APPLY', 'BUTTON_AUDIT_LOGS_CLOSE', 'BUTTON_AUDIT_LOGS_DETAILS',
    'BUTTON_AUDIT_LOGS_ADVANCED_SEARCH', 'BUTTON_AUDIT_LOGS_ADVANCED_SEARCH_CANCEL'];
export const AUDIT_LOGS_EDIT_HINTS = ['AUDIT_LOGS_TITLE', 'BUTTON_AUDIT_LOGS_CANCEL'];
export const SETTINGS_EDIT_HINTS = ['SETTINGS_TITLE', 'BUTTON_SETTINGS_CANCEL', 'BUTTON_SETTINGS_CHANGE_PASSWORD', 'BUTTON_SETTINGS_EDIT', 'BUTTON_SETTINGS_CHANGE_RISK_MODEL'];

/**
 * get list of hints
 * @param {String} language
 * @param {Array} hintsList
 * @private
 */
export default function getHintsList(language, hintsList) {
    let data = hintsList.map(opt => `${opt}`);
    return instanceAPI({method: 'post', url: `/hints/get/${language}`, data});

}

