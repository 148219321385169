
// outsource dependencies
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { history } from '../../../store';
import is from '../../../services/is.service';
import { FORM_NAME, changeField } from './index';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import { translate } from '../../../services/translate.service';
import getHintsList, { TASKS_EDIT_HINTS } from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.CREATE_PROJECT, createProjectsSaga);
    yield takeEvery(EDIT.CREATE_TASK_CATEGORY, createTaskCategorySaga);
    yield takeEvery(EDIT.CHANGE_ESTIMATED_DATE, changeEstimatedDateSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, TASKS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}
function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        // NOTE get initial task categories list
        let taskCategories = yield call(getTaskCategories);
        let projects = yield call(getProjects);
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true, ...params, taskCategories, projects});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* createProjectsSaga ({name}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        const project = yield call(createProject, name);
        yield put(changeField('project', project));
        // NOTE update projects list
        const projects = yield call(getProjects);
        yield call(toastr.success, translate('TASKS$PROJECT'), translate('GLOBALS$SUCCESSFUL_ITEM_CREATE'));
        yield put({type: EDIT.META, expectAnswer: false, projects});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* createTaskCategorySaga ({name}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        const taskCategory = yield call(createTaskCategory, name);
        yield put(changeField('taskCategory', taskCategory));
        // NOTE update task categories list
        const taskCategories = yield call(getTaskCategories);
        yield call(toastr.success, translate('TASKS$CATEGORY'), translate('GLOBALS$SUCCESSFUL_ITEM_CREATE'));
        yield put({type: EDIT.META, expectAnswer: false, taskCategories});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* changeEstimatedDateSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    // NOTE find second estimated date field name, which wasn't changed
    const anotherDateField = options.field === 'estimatedStartDate' ? 'estimatedEndDate' : 'estimatedStartDate';
    // NOTE get form values
    const { values } = yield select(state => state.form[FORM_NAME]);
    // NOTE calculate 'estimatedHours' if it isn't specified, and estimated duration are known
    if (!is._number(values.estimatedHours) && options.value && values[anotherDateField]) {
        const calculatedEstimatedHours = getEstimatedHours(options.value, values[anotherDateField]);
        yield put(changeField('estimatedHours', calculatedEstimatedHours));
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function* cancelSaga () {
    let { back } = yield select(state => state.tasks.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get task by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/tasks/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update task data
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: '/tasks', data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: '/tasks', data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * create task category
 * @param {String} name
 * @private
 */
function createTaskCategory ( name ) {
    return instanceAPI({method: 'post', url: '/task-categories', data: {name}});
}

/**
 * create project
 * @param {String} name
 * @private
 */
function createProject ( name ) {
    return instanceAPI({method: 'post', url: '/projects', data: {name}});
}

/**
 * calculate estimated hours
 * @private
 */
function getEstimatedHours (firstDate, secondDate) {
    const HOURS_PER_DAY = 8;
    let startDate = moment.min(moment(firstDate), moment(secondDate));
    let endDate = moment.max(moment(firstDate), moment(secondDate));
    startDate = startDate.startOf('day');
    endDate = endDate.endOf('day');
    let weekdayCounter = 0;
    while (startDate <= endDate) {
        // NOTE add 1 to your counter if its not a weekend day
        if (startDate.format('ddd') !== 'Sat' && startDate.format('ddd') !== 'Sun'){
            weekdayCounter++;
        }
        // NOTE increment by one day
        startDate = moment(startDate, 'YYYY-MM-DD').add(1, 'days');
    }
    return HOURS_PER_DAY * weekdayCounter;
}

/**
 * get task categories list
 * @private
 */
function getTaskCategories () {
    return instanceAPI({method: 'post', url: '/task-categories/filter', data: {size: 6}}).then(({items}) => items);
}

/**
 * get projects list
 * @private
 */
function getProjects () {
    return instanceAPI({method: 'post', url: '/projects/filter', data: {size: 6}}).then(({items}) => items);
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
