// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';
import {List as ListIcon, PlaylistAddCheck, SpeakerNotesOff} from '@mui/icons-material';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {PERMISSION} from '../../../constants/spec';
import {filters} from '../../../components/filter';
import MdButton from '../../../components/md-button';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import EditableCell from '../../../components/editable-cell';
import MdTablePagination from '../../../components/pagination';
import {MdSelect, SimpleSelect} from '../../../components/md-select';

//config
const METRIC_DOMAINS = {
    IMPACT: 'impact',
    LIKELIHOOD: 'likelihood',
    LEGAL: 'amplified_legal',
    REPUTATION: 'amplified_reputation',
    OPERATIONAL: 'amplified_operational',
};

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list } = this.props;
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={8}>
                            <h2 className="text-uppercase">
                                Risk answers <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                        </Col>
                    </Row>
                    <ConnectedError />
                    <Row>
                        <Col xs={12} lg={3} className="offset-bottom-4">
                            <SearchFilterConnected />
                        </Col>
                        <Col xs={12} lg={6} className="text-center offset-bottom-4">
                            <StatusFilterConnected />
                        </Col>
                        <Col xs={12} lg={3} className="offset-bottom-4">
                            <MetricFilterConnected />
                        </Col>
                    </Row>
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> There is no risk answers </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.riskAnswers.list.expectAnswer, list: state.riskAnswers.list.data}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.riskAnswers.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.riskAnswers.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.riskAnswers.list.filter,
        disabled: state.riskAnswers.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter =>dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
            />
));

/**
 * metric filter
 *
 * @public
 */
const MetricFilterConnected = connect(
    state => ({
        disabled: state.riskAnswers.list.expectAnswer,
        initialValues: { metricDomain: state.riskAnswers.list.metricDomain },
    }),
    dispatch => ({ update: (metricDomain) => dispatch({type: LIST.UPDATE_DATA, metricDomain, page:0}) })
)( reduxForm({form: 'metricDomain'} )( ({disabled, update }) => (
    <Field
        labelKey="value"
        valueKey="value"
        isClearable={true}
        disabled={disabled}
        name="metricDomain"
        component={MdSelect}
        placeholder="Metric Domain"
        optionsLabel={({value})=>filters.humanize(value)}
        onChange={(e, metricDomain)=>update(metricDomain)}
        simpleValue={value => (value ? {value: filters.humanize(value)} : '')}
        options={[METRIC_DOMAINS.IMPACT, METRIC_DOMAINS.LIKELIHOOD, METRIC_DOMAINS.LEGAL, METRIC_DOMAINS.REPUTATION, METRIC_DOMAINS.OPERATIONAL ].map(option=>{
            return ({
                label: filters.humanize(option) ,
                value: filters.humanize(option)
            })
        })}
            />
)));

/**
 * component to chose a type of questions list (all questions, only answered etc)
 *
 * @public
 */
const StatusFilterConnected = connect(
    state => ({
        selected: state.riskAnswers.list.status,
        disabled: state.riskAnswers.list.expectAnswer,
    }),
    dispatch => ({ setupStatus: status => dispatch({type: LIST.UPDATE_DATA, status, page: 0}) })
)(({disabled, selected, setupStatus}) => {
    return (<div className="md-btn-group">
        <Tooltip title="All questions"><span>
            <MdButton
                disabled={disabled}
                onClick={()=>setupStatus('BOTH')}
                className={selected==='BOTH'? 'md-btn active': 'md-btn'}
                    >
                <ListIcon />
            </MdButton>
        </span></Tooltip>
        <Tooltip title="Answered questions"><span>
            <MdButton
                disabled={disabled}
                onClick={()=>setupStatus('ANSWERED')}
                className={selected==='ANSWERED'? 'md-btn active': 'md-btn'}
                    >
                <PlaylistAddCheck />
            </MdButton>
        </span></Tooltip>
        <Tooltip title="Unanswered questions"><span>
            <MdButton
                disabled={disabled}
                onClick={()=>setupStatus('UNANSWERED')}
                className={selected==='UNANSWERED'? 'md-btn active': 'md-btn'}
                    >
                <SpeakerNotesOff />
            </MdButton>
        </span></Tooltip>
    </div>)
});

/**
 * table component
 *
 * @public
 */
const ConnectedTable = connect(
    state => ({...state.riskAnswers.list}),
    dispatch => ({
        cancel: () => dispatch({type: LIST.CANCEL}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
        apply: (index, property, value) => dispatch({type: LIST.SAVE_DATA, index, property, value}),
        setupEditable: (index, property) => dispatch({type: LIST.SETUP_EDITABLE, index, property }),
    })
)( ({ data, editableItem, page, size, totalPages, sortF, sortD, changePage, changeSize, changeSort, expectAnswer, setupEditable, apply, cancel }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'question'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('question')}
                                >
                            Question
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'md.code'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('md.code')}
                                >
                            Metric
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'answer'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('answer')}
                                >
                            Answer
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, index) => (<TableRow key={index}>
                    <TableCell>{get(item, 'question.question')}</TableCell>
                    <TableCell>{get(item, 'question.qualitativeMetric.name')}</TableCell>
                    <TableCell style={{minWidth: '350px'}}>
                        <EditableCell
                            data={item}
                            cancel={cancel}
                            property="answer"
                            disabled={expectAnswer}
                            Component={SimpleSelect}
                            editOptions={{
                                valueKey: 'id',
                                labelKey: 'answer',
                                isClearable: true,
                                options: item.answers,
                                menuPlacement: 'auto',
                                menuPortalTarget: document.body,
                            }}
                            permission={PERMISSION.RISK_ANSWER.UPDATE}
                            apply={(value)=>apply(index, 'answer', value)}
                            setupEditable={()=>setupEditable(index, 'answer')}
                            preview={() =>(<span>{get(item, `answer.answer`, 'No answer')}</span>)}
                            editable={editableItem && editableItem.index === index && editableItem.property === "answer"}
                                />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>));

