
// outsource dependencies
import React from 'react';
import { Link } from 'react-router-dom';

// local dependencies
import { PublicLogo } from '../../images';
import { SIGN_UP } from '../../constants/routes';
import { translate } from '../../services/translate.service';

/**
 * Welcome page available for unauthorized users
 * @constructor PublicWelcomePage
 * @public
 */
function PublicWelcomePage () {
    return (<div className="content-wrap">
        <div className="row top-indent-4 offset-bottom-4 offset-top-4">
            <div className="col-xs-10 col-xs-offset-1 text-center">
                <PublicLogo />
            </div>
        </div>
        <div className="row"> <div className="col-xs-12 text-center">
            <h2 className="offset-bottom-1"> {translate('WELCOME$TITLE')} </h2>
            <h3 className="offset-0 offset-bottom-3"> {translate('WELCOME$DESCRIPTION')} </h3>
            <p> <Link to={SIGN_UP.LINK()} className="link text-uppercase"> {translate('AUTHORIZATION$SIGN_UP')} </Link> </p>
        </div> </div>
    </div>);
}

export default PublicWelcomePage;
