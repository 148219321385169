// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
/*
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    Tabs,
    Tab,
    AppBar,
    TableRow,
    TableCell,
    TableSortLabel
} from '@mui/material';
*/
import {Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// import {Chart} from "react-google-charts";
// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {TASKS} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
// import Calendar from '../../../components/calendar';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {TASKS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, data, message, clearError, hints} = this.props;
        return (<Container fluid>
            <Breadcrumbs breadCrumbsMap={TASKS_MAP} />
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={9}>
                        <RichHintTitle update={LIST} name={`TASKS$TITLE`} expectAnswer={expectAnswer}
                                       data={findHint(hints, `TASKS_TITLE`)} />
                    </Col>
                    <Col xs={3} className="text-right top-indent-4">
                        <Link Btn={AddBtn} placement="left" permission={PERMISSION.TASKS.CREATE}
                              to={TASKS.LINK_EDIT()} hint={findHint(hints, 'BUTTON_TASKS_ADD')} />
                    </Col>
                </Row>
                <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}> <SearchFilterConnected /> </Col> </Row>
                <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError} /> </Col> </Row>
                {data.length ? (
                    //<Paper>
                    //<ConnectedTabs data={data}/>
                    <ConnectedTable />
                    // <GanttChart data={data}/>
                    //</Paper>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                )}
            </ConnectedInitializer>
        </Container>);
    }
}

// let mockData = [{name: 'Table'}, {name: 'Calendar'}, {name: 'Gantt'}];

/**
 * tabs component
 *
 * @param {Object} props
 * @private
 */
/*
const ConnectedTabs = connect(
    state => ({
        // data: state.tasks.list.data,
        currentTab: state.tasks.list.currentTab,
    }),
    dispatch => ({changeTab: currentTab => dispatch({type: LIST.META, currentTab})})
)(({data, currentTab, changeTab}) => {
        return (<Fragment>
            <AppBar position="static" style={{marginBottom: 20}}>
                <Tabs
                    centered={true}
                    value={currentTab}
                    indicatorColor="primary"
                    onChange={(e, tab) => {
                        changeTab(tab)
                    }}
                >
                    {mockData.map((item, key) => (
                        <Tab key={key} value={key + 1} label={item.name}/>
                    ))}
                </Tabs>
            </AppBar>
            <Paper>
                {renderCurrentTab(mockData[currentTab - 1].name, data)}
            </Paper>
        </Fragment>)
    }
);

function renderCurrentTab(name, data) {
    switch (name) {
        case 'Table':
            return <ConnectedTable/>;
        case 'Calendar':
            // return <CalendarChart data={data}/>;
            return <Calendar events={eventsMock}/>;
        case 'Gantt':
            return <GanttChart  data={data}/>;
        default:
            return 'foo';
    }
}

const eventsMock = [
    {
        'title': 'Meeting',
        'start': new Date(2020, 11, 12, 10, 30, 0, 0),
        'end': new Date(2020, 11, 12, 12, 30, 0, 0),
        desc: 'Pre-meeting meeting, to prepare for the meeting'
    },
]
*/

export default connect(
    state => ({
        data: state.tasks.list.data,
        hints: state.tasks.list.hintsData,
        message: state.tasks.list.errorMessage,
        expectAnswer: state.tasks.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null})
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.tasks.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.tasks.list.filter,
        disabled: state.tasks.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_TASK_NOTES")}
    />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.tasks.list, hints: state.tasks.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({
       data,
       hints,
       page,
       size,
       totalPages,
       sortF,
       sortD,
       expectAnswer,
       deleteItem,
       changePage,
       changeSize,
       changeSort
   }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('name')}
                        >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'taskCategory'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('taskCategory')}
                        >
                            {translate('TASKS$CATEGORY')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'project'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('project')}
                        >
                            {translate('TASKS$PROJECT')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'priority'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('priority')}
                        >
                            {translate('TASKS$PRIORITY')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'status'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('status')}
                        >
                            {translate('TASKS$STATUS_PERCENTAGES')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'taskManager'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('taskManager')}
                        >
                            {translate('TASKS$MANAGER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'taskAssignee'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('taskAssignee')}
                        >
                            {translate('TASKS$ASSIGNEE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>
                        <a href={TASKS.LINK_EDIT({
                            id: item.id,
                            query: {back: history.location.pathname + history.location.search}
                        })}>{item.name}</a>
                    </TableCell>
                    <TableCell>{get(item, 'taskCategory.name')}</TableCell>
                    <TableCell>{get(item, 'project.name')}</TableCell>
                    <TableCell>{item.priority}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{get(item, 'taskManager.fullName')}</TableCell>
                    <TableCell>{get(item, 'taskAssignee.fullName')}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.TASKS.UPDATE}
                            hint={findHint(hints, 'BUTTON_TASKS_EDIT')}
                            to={TASKS.LINK_EDIT({
                                id: item.id,
                                query: {back: history.location.pathname + history.location.search}
                            })}
                        />
                        <DelBtn
                            permission={PERMISSION.TASKS.DELETE}
                            onClick={() => deleteItem(item.id)} className="offset-left-2"
                            hint={findHint(hints, 'BUTTON_TASKS_DELETE')}
                        />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>)));

/*
class GanttChart extends Component {
    render() {
        let {data} = this.props;
        let tasksData = [
            [
                {type: 'string', label: 'Task ID'},
                {type: 'string', label: 'Task Name'},
                {type: 'string', label: 'Priority'},
                {type: 'date', label: 'Start Date'},
                {type: 'date', label: 'End Date'},
                {type: 'number', label: 'Duration'},
                {type: 'number', label: 'Percent Complete'},
                {type: 'string', label: 'Dependencies'},
            ]
        ];
        data.forEach(i => {
            tasksData.push([
                i.id,
                i.name,
                i.priority,
                new Date(i.estimatedStartDate),
                new Date(i.estimatedEndDate),
                i.estimatedHours,
                i.status || 0,
                null
            ])
        });
        return (
            <Chart
                width={'100%'}
                style={{padding: `20px 50px 0`}}
                // height={'400px'}
                chartType="Gantt"
                // loader={<div>Loading Chart</div>}
                data={tasksData}
                options={{
                    // height: 400,
                    gantt: {
                        trackHeight: 30,
                    },
                }}
                rootProps={{'data-testid': '2'}}
            />
        );
    }
}
*/
