
// outsource dependencies
import { get, filter } from 'lodash';
import {put, call, takeEvery, select} from 'redux-saga/effects';

// local dependencies
import { SCHEME } from '../actions';
import { instanceAPI } from '../../../services/api.service';
import getHintsList, {BUSINESS_UNITS_SCHEME_HINTS} from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(SCHEME.INITIALIZE, initializeSaga);
    yield takeEvery(SCHEME.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga () {
    yield put({type: SCHEME.CLEAR});
    try {
        let data = yield call(getData);
        yield put({type: SCHEME.DATA, data});
        yield put({type: SCHEME.GET_HINTS_DATA.REQUEST});
        yield put({type: SCHEME.META, initialized: true});
    }
    catch ({message}) {
        yield put({type: SCHEME.META, errorMessage: message, initialized: true});
    }
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, BUSINESS_UNITS_SCHEME_HINTS);
        // NOTE setup hints data
        yield put({type: SCHEME.META, hintsData});
    } catch ( {message} ) {
        yield put({type: SCHEME.META, errorMessage: message});
    }
    yield put({type: SCHEME.GET_HINTS_DATA.FINISH});
}

/**
 * get list of subsidiary organizations
 *
 * @private
 */
function getData () {
    return new Promise((resolve, reject) => {
        Promise.all([
            instanceAPI({method: 'get', url: '/organizations/self'}),
            instanceAPI({ method: 'post', url: '/business-units/filter', data: { filter: {}, size: 1000 }}),
        ]).then(([organization, list]) => {
            let { items } = list;
            // NOTE build tree only if we have items
            if ( items.length ) {
                // NOTE find root elements
                let roots = filter(items, (item => !item.parent));
                let root = roots[0];
                // NOTE set current organization as root element if there is few root business units
                if (roots.length > 1) {
                    // NOTE to avoid organization id match with entities id
                    organization.id = -1;
                    roots.map(item => item.parent = organization);
                    root = organization;
                }
                // NOTE build tree
                resolve(addNodes(items, root));
            }
            // NOTE for empty data
            resolve({});
        }).catch(reject);
    });
}


/**
 * find and add nodes for all list items
 *
 * @param {Array} items
 * @param {Object} parent
 * @private
 */
function addNodes (items, parent) {
    let children = filter(items, item => get(item, 'parent.id') === get(parent, 'id'));
    parent.children = children.length ? children : null;
    if (parent.children) {
        parent.children.forEach(item => addNodes(items, item));
    }
    return parent;
}
