// outsource dependencies
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';

// local dependencies
import {PublicLogo} from '../../images';
import Input from '../../components/input';
import {PUBLIC} from '../../actions/types';
import Preloader from '../../components/preloader';
import ErrorMessage from '../../components/alert-error';
import {translate, withTranslation} from '../../services/translate.service';
import {FORGOT_PASSWORD, PUBLIC_WELCOME, SIGN_IN} from '../../constants/routes';

/**
 * Change Password using token from email notification
 *
 * @constructor ChangePassword
 * @public
 */
const ChangePassword = (props) => {
    let {token} = useParams();
    useEffect(() => {
        props.initialize(token);
        props.validateToken({token});
        return () => {
            // props.clear();
        }
    }, [])

    console.log(useParams());
    console.log(props);
    let { invalid, handleSubmit, expectAnswer, changePassword, errorMessage, invalidToken, clearError } = props;
    return (<div>
        <div className="row">
            <div className="col-xs-8 col-xs-offset-2 text-center">
                <Link to={PUBLIC_WELCOME.LINK()}>
                    <PublicLogo />
                </Link>
            </div>
        </div>
        <div className="row offset-bottom-4">
            <h1 className="col-xs-12 text-center">
                <strong> {translate('AUTHORIZATION$CHANGE_PASSWORD')} </strong>
            </h1>
        </div>
        <div className="row">
            <Preloader type="BOX" expectAnswer={expectAnswer}>
                { invalidToken ? (
                    <h4 className="col-xs-12 text-justify offset-bottom-6">
                        {translate('CHANGE_PASSWORD$INVALID_TOKEN')}
                        {/*Whoa there! The request token for this page is invalid.*/}
                        {/*It may have already been used, or expired because it is too old.*/}
                        {/*Please go back to the {' '}*/}
                        {/*<Link to={FORGOT_PASSWORD.LINK()} className="link">forgot password page</Link> {' '}*/}
                        {/*and try again.*/}
                        {/*<br/>*/}
                    </h4>
                ) : (
                    <div className="col-xs-12">
                        <form name="changePassword" onSubmit={ handleSubmit( changePassword ) }>
                            <div className="offset-bottom-6">
                                <Field
                                    skipTouch
                                    name="password"
                                    type="password"
                                    component={ Input }
                                    disabled={ expectAnswer }
                                    className="form-control form-control-lg"
                                    placeholder={translate('GLOBALS$PASSWORD')}
                                    label={ <span className="h4"> {translate('GLOBALS$PASSWORD')} </span> }
                                        />
                            </div>
                            <div className="offset-bottom-8">
                                <Field
                                    type="password"
                                    component={ Input }
                                    name="confirmPassword"
                                    disabled={ expectAnswer }
                                    className="form-control form-control-lg"
                                    placeholder={translate('CHANGE_PASSWORD$CONFIRM_PASSWORD')}
                                    label={ <span className="h4"> {translate('CHANGE_PASSWORD$CONFIRM_PASSWORD')} </span> }
                                        />
                            </div>
                            <button
                                type="submit"
                                disabled={ invalid || expectAnswer }
                                className="btn btn-lg w-100 btn-primary offset-bottom-8"
                                    >
                                <span> {translate('AUTHORIZATION$CHANGE_PASSWORD')} </span>
                                { expectAnswer&&(<i className="fa fa-spinner fa-spin fa-fw"> </i>) }
                            </button>
                            <ErrorMessage active title={'Error:'} message={errorMessage} onChange={clearError}/>
                        </form>
                    </div>
                )}
            </Preloader>
        </div>
        <div className="row">
            <div className="col-xs-6 text-left">
                <Link to={SIGN_IN.LINK()} className="link text-uppercase"> {translate('AUTHORIZATION$SIGN_IN')} </Link>
            </div>
            <div className="col-xs-6 text-right">
                <Link to={FORGOT_PASSWORD.LINK()} className="link text-uppercase"> {translate('AUTHORIZATION$FORGOT_PASSWORD')} </Link>
            </div>
        </div>
    </div>);

    /*
    UNSAFE_componentWillMount () {
        console.log(this.props);
        let { validateToken, match: { params: { token } } } = this.props;
        validateToken({token});
    }
    */
}

export default withTranslation(reduxForm({
    form: 'changePassword',
    /**
     * @param { Object } values - named properties of input data
     * @param { Object } meta - information about form status
     * @returns { Object } - named errors
     * @function validate
     * @public
     */
    validate: ( values, meta ) => {
        let errors = {};
        // PASSWORD
        if ( !values.password ) {
            errors.password = 'GLOBALS$PASSWORD_REQUIRED';
        } else if ( !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9])(?=.{8,}$)/.test(values.password) ) {
            errors.password = 'CHANGE_PASSWORD$NEW_PASSWORD_HELP_TEXT';
        }
        // CONFIRM PASSWORD
        if ( !values.confirmPassword ) {
            errors.confirmPassword = 'CHANGE_PASSWORD$CONFIRM_PASSWORD_REQUIRED';
        } else if ( values.confirmPassword !== values.password ) {
            errors.confirmPassword = 'CHANGE_PASSWORD$CONFIRM_PASSWORD_INVALID';
        }
        return errors;
    },
})( connect(
    // mapStateToProps
    state => ({ ...state.changePasswordPUBLICK }),
    // mapDispatchToProps
    dispatch => ({
        changePassword: ({password}) => dispatch({type: PUBLIC.CHANGE_PASSWORD.REQUEST, password }),
        validateToken: ({token}) => dispatch({type: PUBLIC.VALIDATE_PASSWORD_TOKEN.REQUEST, token }),
        clearError: () => dispatch({type: PUBLIC.CHANGE_PASSWORD.CLEAR}),
    })
)(ChangePassword) ));
