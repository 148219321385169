
// outsource dependencies

// local dependencies
import PREVIEW_DIALOG from './actions';

let initial = {
    data: {},
    title: '',
    hintsData: [],
    // meta
    showModal: false,
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
};

export default function ( state = initial, action ) {
    // eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case PREVIEW_DIALOG.CLEAR:
            state = initial;
            break;
        case PREVIEW_DIALOG.DATA:
            state = { ...state, data: options.data, title: options.title };
            break;
        case PREVIEW_DIALOG.META:
            state = { ...state, ...options, data: state.data };
            break;
    }

    return state;
}
