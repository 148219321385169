// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Battery50, CellWifi, Cloud, SystemUpdate} from '@mui/icons-material';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {VENDORS} from '../../../constants/routes';
import Preloader from '../../../components/preloader';
import {PERMISSION} from '../../../constants/spec';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import REASSIGN_DIALOG from '../../../components/reassign-dialog/actions';
import {translate, withTranslation} from '../../../services/translate.service';
import {
    AddBtn,
    DelBtn,
    EditBtn,
    FirstLvlQuestionsBtn,
    Link,
    ReassignBtn,
    SecondLvlQuestionsBtn
} from '../../../components/md-button';
import {VENDORS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

import noLogoImage from '../../../images/no-image-icon.png';
import {findHint, RichHintTitle} from "../../../components/hints/hints";
import {formatTechnologyLabel} from "../../../services/data-formatting.service";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { expectAnswer, list, hints } = this.props;
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <Breadcrumbs breadCrumbsMap={ VENDORS_MAP }  />
                            <RichHintTitle update={LIST} name={`VENDORS$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `VENDORS_TITLE`)}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} to={VENDORS.LINK_EDIT()} permission={PERMISSION.VENDOR.CREATE}  hint = {findHint(hints, 'BUTTON_VENDORS_ADD')}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected />
                        </Col>
                    </Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.vendors.list.expectAnswer, hints: state.vendors.list.hintsData, list: state.vendors.list.data}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.vendors.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.vendors.list.filter,
        disabled: state.vendors.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
    />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.vendors.list, hints: state.vendors.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
        openReassignModal: item => dispatch({type: REASSIGN_DIALOG.INITIALIZE, item}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort, openReassignModal }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('VENDORS$ORGANIZATION_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('VENDORS$VENDOR_TYPE')} </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('description')}
                                >
                            {translate('VENDORS$ORGANIZATION_DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'owner'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('owner')}
                                >
                            {translate('GLOBALS$OWNER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'site'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('site')}
                        >
                            {translate('ORGANIZATION$SITE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'technologies'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('technologies')}
                        >
                            {translate('TECHNOLOGIES$TITLE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>
                        {!item.logo ? '' :(
                            <img src={item.logo} height={16} alt="Vendor Logo" onError={(event) => event.currentTarget.src = noLogoImage} />
                        )}
                        {!item.logo ? '' : '\u00a0'}
                        {item.name}&nbsp;
                        {
                            item.isCloudVendor
                            && <Tooltip title={translate('VENDORS$CLOUD_VENDOR')} className="offset-bottom-1"><Cloud  style={{color:"#213c60" }} className="align-middle" /></Tooltip>
                        }
                    </TableCell>
                    <TableCell>
                        {
                            get(item, 'isSystemVendor')
                            && <Tooltip title={translate('VENDORS$SYSTEM_VENDOR')} className="offset-bottom-1"><SystemUpdate  style={{color:"#009703" }} className="align-middle" /></Tooltip>
                        }
                        {
                            get(item, 'isTechnologyVendor')
                            && <Tooltip title={translate('VENDORS$TECHNOLOGY_VENDOR')} className="offset-bottom-1"><CellWifi  style={{color:"#009703" }} className="align-middle" /></Tooltip>
                        }
                        {
                            get(item, 'isServiceVendor')
                            && <Tooltip title={translate('VENDORS$SERVICE_VENDOR')} className="offset-bottom-1"><Battery50  style={{color:"#009703" }} className="align-middle" /></Tooltip>
                        }
                    </TableCell>
                    <AbbrevTableCell content={item.description} title={item.name}/>
                    <TableCell>{get(item, 'owner.fullName')}</TableCell>
                    <TableCell>{get(item, 'site')}</TableCell>
                    <TableCell>{get(item, 'technologies') && item.technologies.map(item => formatTechnologyLabel(item)).join(', ')}</TableCell>
                    <TableCell className="text-nowrap" align="right">
                        {!item.allowedReassign ? '' :(
                            <ReassignBtn
                                className="offset-right-2"
                                onClick={()=>openReassignModal(item)}
                                tooltip={translate('REASSIGN$REASSIGN_QUESTIONS')}
                                hint = {findHint(hints, 'BUTTON_VENDORS_REASSIGN')}
                                    >
                            </ReassignBtn>
                        )}
                        <Link
                            Btn={EditBtn}
                            className="offset-right-2"
                            permission={PERMISSION.VENDOR.UPDATE}
                            to={VENDORS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            hint = {findHint(hints, 'BUTTON_VENDORS_EDIT')}
                                />
                        <DelBtn permission={PERMISSION.VENDOR.DELETE} onClick={() => deleteItem(item.id)} className="offset-right-2"  hint = {findHint(hints, 'BUTTON_VENDORS_DELETE')}/>
                        <Link
                            Btn={FirstLvlQuestionsBtn}
                            className="offset-right-2 md-btn md-btn-warning"
                            tooltip={translate('VENDORS$SCORING_QUESTIONS')}
                            permission={PERMISSION.VENDOR.SCORING_QUESTION}
                            to={VENDORS.LINK_QUESTIONS({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            hint = {findHint(hints, 'BUTTON_VENDORS_LEVEL_1_QUESTIONS')}
                                >
                        </Link>
                        <Link
                            Btn={SecondLvlQuestionsBtn}
                            className="offset-right-2 md-btn md-btn-warning"
                            tooltip={translate('VENDORS$INTERNAL_QUESTIONS')}
                            permission={PERMISSION.VENDOR.INTERNAL_QUESTION}
                            to={VENDORS.LINK_QUESTIONS({id: item.id, group: 'internal', query: {back: history.location.pathname + history.location.search} })}
                            hint = {findHint(hints, 'BUTTON_VENDORS_LEVEL_2_QUESTIONS')}
                                >
                        </Link>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));

const ConnectedError = connect(
    state => ({message: state.vendors.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
