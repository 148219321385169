// outsource dependencies
import {get, find} from 'lodash';
import {connect} from 'react-redux';
import React, {Component, Fragment} from 'react';
import {Row, Col} from 'react-bootstrap';
import {connectModal, show} from 'redux-modal';
import {ArrowForward, ArrowBack} from '@mui/icons-material';
import {Field, reduxForm, change, getFormValues} from 'redux-form';
import {Paper, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {withStyles} from '@mui/styles';

// local dependencies
import MdInput from './md-input';
import {filters} from './filter';
import {MdSelect} from './md-select';
import {translate, withTranslation} from '../services/translate.service';
import Button, {SuccessBtn, EditIconBtn, DeleteIconBtn} from './md-button';
import {
    VARIABLE_CONSTANT,
    OPERATION_TYPES,
    DIRECTION,
    QUANT_METRIC_CONSTANT,
    RISK_MODEL_CONSTANT
} from '../constants/spec';
import separateWithCommaService from '../services/separate-with-comma.service';

// config
const EDIT_ITEM_FORM = 'editFormulaItem';
const operationalTypes = Object.keys(OPERATION_TYPES).map(item => ({name: OPERATION_TYPES[item], value: item}));

/**
 * component for Metric Formula
 *
 * @param {Object} props
 * @public
 */
const FormulaItems = ({
    fields,
    meta,
    values = [],
    editItem,
    openDialog,
    variableTypes,
    riskMetrics,
    constants,
    direction
}) => {
    return (<div>
        {/*NOTE connecting dialog for editing of formula item */}
        <ConnectedItemEditDialog update={editItem} variableTypes={variableTypes}/>
        {/*NOTE full formula */}
        <div>{Boolean(values.length) && (
            <h4 className="text-center">
                <span className="text-uppercase">{translate('METRIC_FORMULA$FORMULA')}: </span>
                {values.map((item = {}) => getItemLabel(item))}
            </h4>
        )}</div>
        <h3 className="text-uppercase offset-bottom-4">{translate('METRIC_FORMULA$METRIC_FORMULA')}</h3>
        {/*NOTE buttons to add formula items */}
        <FormulaButtons variableTypes={variableTypes} riskMetrics={riskMetrics} constants={constants}
                        addItem={fields.push}/>
        <div>{!meta.error ? '' : (
            <h4 className="text-center is-invalid"><span className="form-text"> {translate(meta.error)} </span></h4>
        )}</div>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {fields.map((mKey, index) => {
                let item = fields.get(index);
                return (
                    <Paper
                        key={index}
                        className="indent-3 offset-bottom-4 text-center align-middle"
                        style={{
                            position: 'relative',
                            paddingBottom: '30px',
                            width: '120px',
                            height: '120px',
                            margin: '5px'
                        }}
                    >
                        {/*NOTE item label */}
                        <FormulaItemLabel item={item}/>
                        <div style={{position: 'absolute', bottom: 0, left: 0, display: 'flex'}}>
                            <Tooltip title={translate('METRIC_FORMULA$MOVE_BACKWARD')} placement="top"><span>
                                <IconButton
                                    disabled={!index}
                                    style={{padding: '5px'}}
                                    onClick={() => fields.move(index, index - 1)}
                                    aria-label={translate('METRIC_FORMULA$MOVE_BACKWARD')}
                                >
                                    {direction === DIRECTION.RTL ? <ArrowForward fontSize="small"/> :
                                        <ArrowBack fontSize="small"/>}
                                </IconButton>
                            </span></Tooltip>
                            <EditIconBtn style={{padding: '5px'}} onClick={() => openDialog(mKey, item)}/>
                            <DeleteIconBtn style={{padding: '5px'}} onClick={() => fields.remove(index)}/>
                            <Tooltip title={translate('METRIC_FORMULA$MOVE_FORWARD')} placement="top"><span>
                                <IconButton
                                    style={{padding: '5px'}}
                                    disabled={index === values.length - 1}
                                    onClick={() => fields.move(index, index + 1)}
                                    aria-label={translate('METRIC_FORMULA$MOVE_FORWARD')}
                                >
                                    {direction === DIRECTION.RTL ? <ArrowBack fontSize="small"/> :
                                        <ArrowForward fontSize="small"/>}
                                </IconButton>
                            </span></Tooltip>
                        </div>
                    </Paper>
                );
            })}
        </div>
    </div>);
};


export default withTranslation(connect(
    null, dispatch => ({openDialog: (mKey, item) => dispatch(show(EDIT_ITEM_FORM, {mKey, initialValues: item}))})
)(FormulaItems));

/**
 * metric formula item displaying on card
 *
 * @param {Object} props
 * @private
 */
const FormulaItemLabel = ({item = {}}) => {
    if (item.quantMetricRef) {
        return <p
            style={{display: 'inline-block', margin: 0}}> {filters.humanize(get(item, 'quantMetricRef.name'))} </p>;
    }
    if (item.riskModelConstantRef) {
        return <p style={{
            display: 'inline-block',
            margin: 0
        }}> {filters.humanize(get(item, 'riskModelConstantRef.name'))} </p>;
    }
    return !item.isOperation && get(item, 'variableType.name', '').toUpperCase() !== VARIABLE_CONSTANT ? (
        <p style={{display: 'inline-block', margin: 0}}> {getItemLabel(item)} </p>
    ) : (item.name && item.name !== 'Hours in a year' ? (
        <div>
            <p style={{display: 'block', margin: 0, marginBottom: 10}}> {item.name} </p>
            <p style={{display: 'inline-block', margin: 0}}> {getItemLabel(item)} </p>
        </div>
    ) : (
        <p style={{display: 'inline-block', margin: 0}}> {getItemLabel(item)} </p>
    ));
};

/**
 * formula item label
 *
 * @public
 */
export const getItemLabel = (item = {}) => {
    if (item.quantMetricRef) {
        // let variable = filters.humanize(get(item, 'quantMetricRef.name'));
        let variable = get(item, 'quantMetricRef.name');
        return variable ? variable + ' ' : '';
    }
    if (item.riskModelConstantRef) {
        // let variable = filters.humanize(get(item, 'riskModelConstantRef.name'));
        let variable = get(item, 'riskModelConstantRef.name');
        return variable ? variable + ' ' : '';
    }
    if (item.isOperation) {
        let operation = find(Object.keys(OPERATION_TYPES), key => key === item.operation);
        return operation ? OPERATION_TYPES[operation] + ' ' : '';
    }
    let variable = get(item, 'variableType.name', '').toUpperCase() === VARIABLE_CONSTANT
        ? String(item.value)
        : get(item, 'variableType.name');

    return variable ? variable + ' ' : '';
};

/**
 * buttons to add specific metric formula item
 *
 * @public
 */
const FormulaButtons = ({addItem, variableTypes, riskMetrics, constants}) => {
    return (
        <div className="offset-bottom-6">
            <h4> {translate('METRIC_FORMULA$VARIABLES')} </h4>
            <div>{variableTypes.map((item, index) => {
                return (
                    <SuccessBtn
                        key={index}
                        className="offset-bottom-1"
                        tooltip={translate('METRIC_FORMULA$ADD_VARIABLE', {variable: item.name})}
                        onClick={() => addItem({
                            isOperation: false,
                            variableType: item,
                            value: item.name.toUpperCase() !== VARIABLE_CONSTANT ? null : 0
                        })}
                    >
                        {item.name}
                    </SuccessBtn>
                );
            })}</div>
            {constants && (<Fragment>
                <h4> {translate('CONSTANTS$TITLE')} </h4>
                <div>{constants.map((item, index) => {
                    return (
                        <SuccessBtn
                            key={index}
                            className="offset-bottom-1"
                            tooltip={translate('METRIC_FORMULA$ADD_CONSTANT', {constant: item.name})}
                            onClick={() => addItem({
                                isOperation: false,
                                variableType: {id: 16, name: RISK_MODEL_CONSTANT},
                                riskModelConstantRef: item
                            })}
                        >
                            {item.name}
                        </SuccessBtn>
                    );
                })}</div>
            </Fragment>)}
            {riskMetrics && (<Fragment>
                <h4> {translate('QUANTIFICATION_METRICS$TITLE')} </h4>
                <div>{riskMetrics.map((item, index) => {
                    return (
                        <SuccessBtn
                            key={index}
                            className="offset-bottom-1"
                            tooltip={translate('METRIC_FORMULA$ADD_QUANT_METRIC', {quantMetric: item.name})}
                            onClick={() => addItem({
                                isOperation: false,
                                variableType: {id: 17, name: QUANT_METRIC_CONSTANT},
                                quantMetricRef: item
                            })}
                        >
                            {item.name}
                        </SuccessBtn>
                    );
                })}</div>
            </Fragment>)}
            <h4> {translate('METRIC_FORMULA$OPERATIONS')} </h4>
            <div>{operationalTypes.map((item, index) => (
                <SuccessBtn
                    key={index}
                    className="offset-bottom-1"
                    onClick={() => addItem({isOperation: true, operation: item.value})}
                    tooltip={translate('METRIC_FORMULA$ADD_OPERATION', {operation: item.value})}
                >
                    {item.name}
                </SuccessBtn>
            ))}</div>
        </div>
    );
};

/**
 * metric formula item edit dialog component
 *
 * NOTE: use separate form to prevent immediate item update.
 * Instead we need to allow user change and then save data or stop editing
 * Don't use <form /> cause nested forms are not available. Use 'handleSubmit' manually.
 *
 * @param {Object} props
 * @public
 */
class ItemEditDialog extends Component {

    changeVariableType(variableType) {
        if (variableType !== VARIABLE_CONSTANT) {
            this.props.changeField('value', null);
        } else {
            this.props.changeField('value', 0);
        }
    }

    render() {
        let {show, formValues = {}, mKey, variableTypes, classes, update, handleSubmit, handleHide} = this.props;
        return (
            <Dialog
                open={show}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' && reason === 'escapeKeyDown') {
                        return false;
                    }
                    handleHide();
                }}
                classes={{paper: classes.paper}}
                aria-label={translate('METRIC_FORMULA$EDIT_DIALOG_TITLE')}
            >
                <DialogTitle> {translate('METRIC_FORMULA$EDIT_DIALOG_TITLE')} </DialogTitle>
                <DialogContent style={{overflow: 'visible'}}>
                    {formValues.isOperation ? (
                        <Row className="offset-bottom-4">
                            <Col xs={12}>
                                <Field
                                    name="operation"
                                    labelKey="value"
                                    valueKey="value"
                                    component={MdSelect}
                                    options={Object.keys(OPERATION_TYPES)}
                                    optionsLabel={({value}) => filters.humanize(value)}
                                    placeholder={translate('METRIC_FORMULA$OPERATION')}
                                    simpleValue={value => (value ? {value: filters.humanize(value)} : '')}
                                    required={true}
                                    label={(<strong
                                        className="required-asterisk"> {translate('METRIC_FORMULA$OPERATION')} </strong>)}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col xs={12} className="offset-bottom-4">
                                <Field
                                    name="variableType"
                                    component={MdSelect}
                                    options={variableTypes}
                                    onChange={e => this.changeVariableType(e.name)}
                                    placeholder={translate('METRIC_FORMULA$VARIABLE_TYPE')}
                                    getOptionLabel={option => filters.humanize(option.name)}
                                    required={true}
                                    label={(<strong
                                        className="required-asterisk"> {translate('METRIC_FORMULA$VARIABLE_TYPE')} </strong>)}
                                />
                            </Col>
                            {get(formValues, 'variableType.name', '').toUpperCase() === VARIABLE_CONSTANT && (
                                <Col xs={12} className="offset-bottom-4">
                                    <Field
                                        name="value"
                                        component={MdInput}
                                        placeholder={translate('GLOBALS$VALUE')}
                                        label={(<strong> {translate('GLOBALS$VALUE')} </strong>)}
                                        normalize={separateWithCommaService.normalizeReal}
                                    />
                                </Col>
                            )}
                        </Row>
                    )}
                    <Row>
                        <Col xs={12} className="offset-bottom-4">
                            <Field
                                name="name"
                                component={MdInput}
                                placeholder={translate('GLOBALS$NAME')}
                                label={(<strong> {translate('GLOBALS$NAME')} </strong>)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="offset-bottom-4">
                            <Field
                                multiline
                                name="description"
                                component={MdInput}
                                placeholder={translate('GLOBALS$DESCRIPTION')}
                                label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                            />
                        </Col>
                    </Row>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="text"
                        onClick={handleSubmit(data => {
                            update(mKey, data);
                            handleHide();
                        })}
                    >
                        {translate('GLOBALS$APPLY')}
                    </Button>
                    <Button variant="text" onClick={handleHide}> {translate('GLOBALS$CANCEL')} </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const ConnectedItemEditDialog = withStyles({paper: {minWidth: '500px', overflow: 'visible'}})(
    connectModal({name: EDIT_ITEM_FORM})(reduxForm({form: EDIT_ITEM_FORM})(
        connect(
            state => ({formValues: getFormValues(EDIT_ITEM_FORM)(state)}),
            dispatch => ({changeField: (field, value) => dispatch(change(EDIT_ITEM_FORM, field, value))})
        )(ItemEditDialog)
    ))
);
