
// outsource dependencies
import get from 'lodash/get';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { history } from '../../../store';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import getHintsList, {RISK_METRICS_EDIT_HINTS} from '../../../services/hints.service';
import separateWithCommaService from '../../../services/separate-with-comma.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        const { riskModel } = yield select( state => state.app );
        let data = yield call(getData, riskModel.id, id);
        //NOTE separate with commas
        if (data.formula && data.formula.formulaItems) yield call(separateWithCommaService.separateReal, data.formula.formulaItems);
        yield put({type: EDIT.DATA, data});
        let variableTypes = yield call(getVariableTypes);
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, initialized: true, ...params, variableTypes});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}
function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, RISK_METRICS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}


function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    // NOTE convert to numeric format
    yield call(separateWithCommaService.convertReal, options.formula.formulaItems);
    try {
        const { riskModel } = yield select( state => state.app );
        let data = yield call(updateData, riskModel.id, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.riskMetrics.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get risk metric by id
 * @param {Number|String} riskModelId
 * @param {Number|String} id
 * @private
 */
function getData ( riskModelId, id ) {
    return id === NEW_ID ? {formula: {formulaItems: []}} : instanceAPI({method: 'get', url: `/risk-models/${riskModelId}/risk-metrics/${id}`})
        .then(success => {
            // NOTE sort formula items by ordinal
            get(success, 'formula.formulaItems') && (get(success, 'formula.formulaItems') || []).sort((a, b) => a.ordinal - b.ordinal);
            return success;
        });
}

/**
 * update risk metric data
 * @param {Number|String} riskModelId
 * @param {Object} data
 * @private
 */
function updateData ( riskModelId, data ) {
    if ( get(data, 'formula.formulaItems') ) {
        (data.formula.formulaItems || []).forEach((item, index) => item.ordinal = index )
    }
    return instanceAPI({ method: !data.id || data.id === NEW_ID ? 'post' : 'put', url: `/risk-models/${riskModelId}/risk-metrics`, data})
        .then(success => {
            // NOTE sort formula items by ordinal
            get(success, 'formula.formulaItems') && (get(success, 'formula.formulaItems') || []).sort((a, b) => a.ordinal - b.ordinal);
            return success;
        });
}

/**
 * get metric domains
 * @private
 */
function getVariableTypes () {
    return instanceAPI({method: 'post', url: '/variable-types/filter', data: {filter: {name: 'RISK_METRIC'}}})
        .then(({items}) => items);
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
