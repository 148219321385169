
// outsource dependencies

// local dependencies

import {createTypes} from '../../actions/types';

export default (function (prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        CANCEL: `${prefix}CANCEL`,
        REASSIGN: `${prefix}REASSIGN`,
        INITIALIZE: `${prefix}INITIALIZE`,
        SETUP_COUNTRY: createTypes(`${prefix}SETUP_COUNTRY`),
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@geo-records-processed-dialog/');
