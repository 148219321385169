// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {CYBER_SECURITY_TOOLS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

class Edit extends Component {
    componentDidMount() { this.props.initialize( this.props.match.params.id ); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, match } = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ CYBER_SECURITY_TOOLS_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span:8,offset:2}} lg={{span:6,offset:3}}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                     <span>
                                         {isNew
                                             ? translate('CYBER_SECURITY_TOOLS$CREATE_TOOL')
                                             : translate('CYBER_SECURITY_TOOLS$EDIT_TOOL')
                                         }
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON" />
                                </h2>
                                <ConnectedError />
                                <ConnectedForm isNew={isNew} />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.cyberSecurityTools.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.cyberSecurityTools.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.cyberSecurityTools.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.cyberSecurityTools.edit.data,
        disabled: state.cyberSecurityTools.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editCyberSecurityTool',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // risk reduction percent
        if (values.riskReductionPercent && (values.riskReductionPercent < 0 || values.riskReductionPercent > 100)) {
            errors.riskReductionPercent = 'GLOBALS$PERCENTAGE_ERROR';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel})=>(
    <form autoComplete="off" name="editCyberSecurityTool" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={true}
                    placeholder={translate('GLOBALS$NAME')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline
                    name="description"
                    component={MdInput}
                    disabled={true}
                    placeholder={translate('GLOBALS$DESCRIPTION')}
                    label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    isMulti={true}
                    name="quantMetrics"
                    disabled={disabled}
                    type={ENTITY_TYPES.QUANT_METRICS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('EXPOSURE_METRICS$TITLE')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="offset-bottom-4">
                <Field
                    type="number"
                    component={MdInput}
                    disabled={disabled}
                    name="riskReduction"
                    placeholder={translate('CYBER_SECURITY_TOOLS$RISK_REDUCTION')}
                    label={(<strong> {translate('CYBER_SECURITY_TOOLS$RISK_REDUCTION')} </strong>)}
                        />
            </Col>
            {/*<Col xs={12} md={6} className="offset-bottom-4">*/}
                {/*<Field*/}
                    {/*type="number"*/}
                    {/*component={MdInput}*/}
                    {/*disabled={disabled}*/}
                    {/*name="riskReductionPercent"*/}
                    {/*inputProps={{min: 0, max: 100}}*/}
                    {/*placeholder={translate('CYBER_SECURITY_TOOLS$RISK_REDUCTION_PERCENTAGES')}*/}
                    {/*label={(<strong> {translate('CYBER_SECURITY_TOOLS$RISK_REDUCTION_PERCENTAGES')}</strong>)}*/}
                        {/*/>*/}
            {/*</Col>*/}
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    type="number"
                    name="toolPrice"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('CYBER_SECURITY_TOOLS$LICENSE_COST')}
                    label={(<strong> {translate('CYBER_SECURITY_TOOLS$LICENSE_COST')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" />
                <CancelBtn onClick={cancel} />
            </Col>
        </Row>
    </form>
))));
