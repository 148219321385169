// outsource dependencies
import {put, call, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {EDIT} from '../actions';
import {history} from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import {instanceAPI} from '../../../services/api.service';
import getHintsList, {DOMAINS_EDIT_HINTS} from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let {riskModel} = yield select(state => state.app);
        let data = yield call(getData, riskModel, id);
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true, ...params});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true});
    try {
        let {riskModel, user} = yield select(state => state.app);
        let data = yield call(updateData, riskModel, options, user);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* cancelSaga() {
    let {back} = yield select(state => state.domains.edit);
    // NOTE go back
    yield call(history.push, back);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, DOMAINS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

/**
 * get tech category by id
 * @param {Number|String} id
 * @param {Number} riskModel
 * @private
 */
function getData(riskModel, id) {
    return new Promise((resolve, reject) => {
        if (id === NEW_ID) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/risk-models/${riskModel.id}/domains/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update domain
 * @param {Object} riskModel
 * @param {Object} data
 * @param {Object} user
 * @private
 */
function updateData(riskModel, data, user) {
    return new Promise((resolve, reject) => {
        let promise;
        let saveUrl = `/risk-models/${riskModel.id}/domains`;
        let saveData = {...data, riskDomainId: null, riskManagementOwnerUserId: user.id};
        if (!data.id || data.id === NEW_ID) { // CREATE
            promise = instanceAPI({ method: 'post', url: saveUrl, data: saveData});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: saveUrl, data: saveData});
        }

        promise.then(resolve).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
