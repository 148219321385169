// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {
    Card, CardActions, CardContent,
    CardHeader, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {instanceAPI} from '../../../services/api.service';
import MdTablePagination from '../../../components/pagination';
import withDownloadLink from "../../../components/download-link";
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {QUAL_QUESTIONS} from '../../../constants/routes';
import {DOWNLOAD_TYPES, PERMISSION, QUESTION_TYPES, QUESTION_TYPES_BY_CODE} from '../../../constants/spec';
import {SimpleAsyncSelect, SimpleSelect} from '../../../components/md-select';
import {translate, withTranslation} from '../../../services/translate.service';
import SearchFilter, {SimpleSearchField} from '../../../components/search-filter';
import {AddBtn, DelBtn, EditBtn, Link, PrimaryBtn, WarningBtn} from '../../../components/md-button';
import {QUALITATIVE_QUESTIONS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {findHint, RichHintTitle} from "../../../components/hints/hints";

import {Battery50, CellWifi, Close, SystemUpdate} from '@mui/icons-material';

class QualMetrics extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, list, hints } = this.props;

        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={QUALITATIVE_QUESTIONS_MAP}  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <RichHintTitle update={LIST}  name={`SCORING_QUESTIONS$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `SCORING_QUESTIONS_TITLE`)}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4">
                            <Link Btn={AddBtn} placement="left" to={QUAL_QUESTIONS.LINK_EDIT()} permission={PERMISSION.QUALITATIVE_QUESTION.CREATE} hint={findHint(hints, "BUTTON_SCORING_QUESTIONS_ADD")} />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4"><Col xs={12}> <FiltersPanelConnected /> </Col></Row>
                    <ConnectedError />
                    { list.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({ expectAnswer: state.qualQuest.list.expectAnswer, list: state.qualQuest.list.data, hints: state.qualQuest.list.hintsData}),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE })
    })
)(QualMetrics);

const ConnectedInitializer = connect(
    state => ({initialize: state.qualQuest.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));


/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = withTranslation(connect(
    state => ({showAdvanced: state.qualQuest.list.showAdvanced, hints: state.qualQuest.list.hintsData}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => {
            dispatch({type: LIST.META, showAdvanced: true});
            dispatch({type: LIST.UPDATE_DATA, filter: ''});
        },
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ({showAdvanced, update, openAdvanced, closeAdvanced, hints}) => (<div>
    { showAdvanced ? (
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Card style={{overflow: 'visible'}}>
                    <CardHeader
                        title={translate('GLOBALS$ADVANCED_SEARCH')}
                        action={
                            <Tooltip title={translate('GLOBALS$CLOSE')}>
                                <IconButton aria-label={translate('GLOBALS$CLOSE')} onClick={closeAdvanced}>
                                    <Close fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }
                    />
                    <CardContent> <SearchForm /> </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn onClick={update} tooltip={translate('GLOBALS$APPLY')} hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_ADVANCED_SEARCH_APPLY')}> {translate('GLOBALS$APPLY')}  </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn onClick={closeAdvanced} tooltip={translate('GLOBALS$CLOSE')} hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_ADVANCED_SEARCH_CLOSE')}> {translate('GLOBALS$CLOSE')} </WarningBtn>
                    </CardActions>
                </Card>
            </Col>
        </Row>
    ) : (
        <Row>
            <Col xs={12} sm={6} lg={3} className="offset-bottom-2">
                <SearchFilterConnected />
            </Col>
            <Col xs={12} sm={6}>
                <PrimaryBtn hint={findHint(hints, 'BUTTON_SCORING_QUESTIONS_ADVANCED_SEARCH')} onClick={openAdvanced} tooltip={translate('GLOBALS$ADVANCED_SEARCH')} > {translate('GLOBALS$ADVANCED_SEARCH')} </PrimaryBtn>
            </Col>
        </Row>
    )}
</div>)));

/*
const FiltersPanelConnected = connect(
    state => ({showAdvanced:  state.qualQuest.list.showAdvanced}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => dispatch({type: LIST.META, showAdvanced: true}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ( props) => (
    <FiltersPanel SearchForm={SearchForm} SearchFilter={SearchFilterConnected} {...props} />
));
*/
/**
 * search form
 *
 * @public
 */
const SearchForm = withTranslation(connect(
    state => ({...state.qualQuest.list, metric_options: []}),
    dispatch => ({ changeFilterValue: data => dispatch({type: LIST.META, ...data}) }),
)( ({ expectAnswer, filter, metricDomain, vendorType, changeFilterValue }) => (
    
    <Row>
        <Col xs={12} md={4} className="offset-bottom-2">
            <SimpleSearchField
                value={filter}
                disabled={expectAnswer}
                clear={() => changeFilterValue({filter: ''})}
                onChange={e => changeFilterValue({filter: e.target.value})}
                    />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-2">
            <SimpleAsyncSelect
                isClearable={true}
                value={metricDomain ? metricDomain.code : null}
                disabled={expectAnswer}
                // optionsLabel={({label})=>filters.humanize(label)}
                placeholder={translate('SCORING_METRICS$METRIC_DOMAIN')}
                label={(<strong> {translate('SCORING_METRICS$METRIC_DOMAIN')} </strong>)}
                // simpleValue={value => (value ? {value: filters.humanize(value)} : '')}
                
                onChange={metricDomain => changeFilterValue({metricDomain: metricDomain && metricDomain.code ? metricDomain.code : null})}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'get', url: 'metric-domains'})
                        .then((items) =>{
                            done(items)
                        }).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} md={4} className="offset-bottom-2">
            <SimpleSelect
                valueKey="value"
                labelKey="label"
                value={vendorType}
                isClearable={true}
                disabled={expectAnswer}
                placeholder={translate('SCORING_QUESTIONS$SCORING_TYPE')}
                label={(<strong> {translate('SCORING_QUESTIONS$SCORING_TYPE')} </strong>)}
                // simpleValue={(value) => { return {value: value, label: value} }}
                options={[
                    QUESTION_TYPES.ORGANIZATION,
                    QUESTION_TYPES.SYSTEM,
                    QUESTION_TYPES.VENDOR,
                    QUESTION_TYPES.VENDOR_INTERNAL,
                    QUESTION_TYPES.CLOUD,
                    QUESTION_TYPES.CLOUD_INTERNAL,
                    QUESTION_TYPES.GDPR_SYSTEM,
                    QUESTION_TYPES.GDPR_ORGANIZATION,
                ]}
                onChange={vendorType => changeFilterValue({vendorType})}
                    />
        </Col>
    </Row>
)));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.qualQuest.list.filter,
        disabled: state.qualQuest.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
    />
));

const DownloadLink = withTranslation(withDownloadLink({downloadType: DOWNLOAD_TYPES.QUALITATIVE_QUESTION_ANSWERS.CSV_LIST})(props => (
    <PrimaryBtn tooltip={translate('SCORING_QUESTIONS$DOWNLOAD_ANSWERS_AS_CSV')} permission={PERMISSION.EXPORT.QUALITATIVE_QUESTION} {...props}>
        <i className="fa fa-download" style={{fontSize: 20}} aria-hidden="true" />&nbsp;{translate('GLOBALS$CSV')}
    </PrimaryBtn>
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.qualQuest.list, hints: state.qualQuest.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)( ({ data, hints,  page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'question'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('question')}
                                >
                            {translate('SCORING_QUESTIONS$QUESTION_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'code'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('code')}
                                >
                            {translate('SCORING_QUESTIONS$QUESTION_CODE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('description')}
                                >
                            {translate('SCORING_QUESTIONS$QUESTION_DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'qualitativeMetric'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('qualitativeMetric')}
                                >
                            {translate('SCORING_METRICS$SCORING_METRIC')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'vendorType'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('vendorType')}
                                >
                            {translate('SCORING_QUESTIONS$SCORING_TYPE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>
                        {item.question}
                        {
                            (item.vendorType === "Vendor" || item.vendorType === "Cloud" || item.vendorType === "VendorInternal" || item.vendorType === "CloudInternal") && get(item, 'isSystemVendor')
                            && <Tooltip title={translate('VENDORS$SYSTEM_VENDOR')} className="offset-bottom-1"><SystemUpdate  style={{color:"#009703" }}  className="align-middle" /></Tooltip>
                        }
                        {
                            (item.vendorType === "Vendor" || item.vendorType === "Cloud" || item.vendorType === "VendorInternal" || item.vendorType === "CloudInternal") && get(item, 'isTechnologyVendor')
                            && <Tooltip title={translate('VENDORS$TECHNOLOGY_VENDOR')} className="offset-bottom-1"><CellWifi  style={{color:"#009703" }}  className="align-middle" /></Tooltip>
                        }
                        {
                            (item.vendorType === "Vendor" || item.vendorType === "Cloud" || item.vendorType === "VendorInternal" || item.vendorType === "CloudInternal") && get(item, 'isServiceVendor')
                            && <Tooltip title={translate('VENDORS$SERVICE_VENDOR')} className="offset-bottom-1"><Battery50  style={{color:"#009703" }} className="align-middle" /></Tooltip>
                        }
                    </TableCell>
                    <TableCell>{item.code}</TableCell>
                    <AbbrevTableCell content={item.description} title={item.name} length={30} minWidth={250}/>
                    <TableCell>{get(item, 'qualitativeMetric.name')}</TableCell>
                    <TableCell>{QUESTION_TYPES_BY_CODE.getLabel(item.vendorType)}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        { item.readOnly ? null : (
                            <div>
                                <DownloadLink
                                    itemId={item ? item.id : ''}
                                    // link={() => DOWNLOAD.QUAL_QUESTION_ANSWERS_CSV_LINK({questionId: item.id})}
                                    hint={findHint(hints, `BUTTON_SCORING_QUESTIONS_DOWNLOAD_CSV`)}
                                />
                                <Link
                                    Btn={EditBtn}
                                    permission={PERMISSION.QUALITATIVE_QUESTION.UPDATE}
                                    to={QUAL_QUESTIONS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search}})}
                                    className="offset-left-2"
                                    hint={findHint(hints, `BUTTON_SCORING_QUESTIONS_EDIT`)}
                                        />
                                <DelBtn permission={PERMISSION.QUALITATIVE_QUESTION.DELETE} onClick={() => deleteItem(item.id)} className="offset-left-2" hint={findHint(hints, `BUTTON_SCORING_QUESTIONS_DELETE`)} />
                            </div>
                        )}
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));

const ConnectedError = connect(
    state => ({message: state.qualQuest.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: LIST.META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
