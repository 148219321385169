// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {change, Field, getFormValues, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {TECHNOLOGIES_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {MdAsyncCreatableSelect} from '../../../components/md-select';
// import {formatTechnologyLabel} from '../../../services/data-formatting.service';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import {instanceAPI} from "../../../services/api.service";
import {get} from "lodash";


export const FORM_NAME = 'editTechnology';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        };
    }, []);
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={TECHNOLOGIES_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-4">
                    <Col xs={12}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                    <span>
                                        <RichHintTitle
                                            update={EDIT}
                                            name={isNew ? translate('TECHNOLOGIES$CREATE_TECHNOLOGY') : translate('TECHNOLOGIES$EDIT_TECHNOLOGY')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints, 'TECHNOLOGIES_TITLE')}
                                        />
                                    </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"/>
                            </h2>
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};

export default connect(
    state => ({expectAnswer: state.technologies.edit.expectAnswer, hints: state.technologies.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.technologies.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.technologies.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.technologies.edit.data,
        disabled: state.technologies.edit.expectAnswer,
        hints: state.technologies.edit.hintsData,
        technologySubcategories: state.technologies.edit.technologySubcategories,
        technologyClassTypes: state.technologies.edit.technologyClassTypes,
        formValues: getFormValues(FORM_NAME)(state),
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        createVendor: vendor => dispatch({type: EDIT.CREATE_VENDOR, ...vendor}),
        setupTechnologyCategory: technologyCategoryId => dispatch({type: EDIT.SETUP_CATEGORY, technologyCategoryId}),
        createTechnologySubcategory: name => dispatch({type: EDIT.CREATE_SUBCATEGORY, name}),
        setupTechnologySubcategory: technologySubcategoryId => dispatch({type: EDIT.SETUP_SUBCATEGORY, technologySubcategoryId}),
        createTechnologyClassType: name => dispatch({type: EDIT.CREATE_CLASS_TYPE, name}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // description
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        // technology category
        if (!values.technologyCategory) {
            errors.technologyCategory = 'TECHNOLOGY_CATEGORIES$TECHNOLOGY_CATEGORY_REQUIRED';
        }
        // vendor
        if (!values.vendor) {
            errors.vendor = 'VENDORS$VENDOR_REQUIRED';
        }
        // country
        /*
        if (!values.country) {
            errors.country = 'ORGANIZATION$COUNTRY_REQUIRED';
        }
        */
        return errors;
    }
})(({handleSubmit, hints, invalid, pristine, disabled, update, reset, isNew, cancel, createVendor, formValues, technologySubcategories, technologyClassTypes, setupTechnologyCategory, createTechnologySubcategory, setupTechnologySubcategory, createTechnologyClassType}) => {
    console.log(formValues);
    return (
        <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
            <Row>
                <Col xs={12} md={8} xl={9} className="offset-bottom-4">
                    <Field
                        name="name"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('GLOBALS$NAME')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={4} xl={3} className="offset-bottom-4">
                    <Field
                        name="version"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('GLOBALS$VERSION')}
                        label={(<strong> {translate('GLOBALS$VERSION')} </strong>)}
                    />
                </Col>
                <Col xs={12} className="offset-bottom-4">
                    <Field
                        multiline={true}
                        name="description"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('GLOBALS$DESCRIPTION')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                    />
                </Col>
            </Row>

            <Paper className="indent-2 offset-top-8 offset-bottom-8">
                <Row>
                    <Col xs={12} md={6} xl={4} className="offset-bottom-4">
                        <SelectEntities
                            disabled={disabled}
                            name="technologyCategory"
                            type={ENTITY_TYPES.TECH_CATEGORIES}
                            placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                            label={(<strong className="required-asterisk"> {translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CATEGORY')} </strong>)}
                            onChange={(e) => setupTechnologyCategory(get(e, 'id', null))}
                        />
                    </Col>
                    <Col xs={12} md={6} xl={4} className="offset-bottom-4">
                        <Field
                            name="technologySubcategory"
                            disabled={disabled || !formValues || !formValues.technologyCategory}
                            defaultOptions={technologySubcategories}
                            component={MdAsyncCreatableSelect}
                            onCreateOption={name => createTechnologySubcategory(name)}
                            placeholder={translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_SUBCATEGORY')}
                            label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_SUBCATEGORY')} </strong>)}
                            getNewOptionData={(inputValue, optionLabel)=>({id: inputValue, name: optionLabel, __isNew__: true})}
                            loadOptions={(name, done) => {
                                if (formValues && formValues.technologyCategory && formValues.technologyCategory.id) {
                                    instanceAPI({
                                        method: 'post',
                                        url: '/technology-subcategories/filter',
                                        data: { page: 0, size: 6, filter: { name: name, parentId: formValues.technologyCategory.id } },
                                    }).then(({items}) => done(items)).catch(done.bind(null, []));
                                } else {
                                    done([]);
                                }
                            }}
                            onChange={(e) => setupTechnologySubcategory(get(e, 'id', null))}
                        />
                    </Col>
                    <Col xs={12} md={6} xl={4} className="offset-bottom-4">
                        <Field
                            name="technologyClassType"
                            disabled={disabled || !formValues || !formValues.technologyCategory || !formValues.technologySubcategory}
                            defaultOptions={technologyClassTypes}
                            component={MdAsyncCreatableSelect}
                            onCreateOption={name => createTechnologyClassType(name)}
                            placeholder={translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CLASS_TYPE')}
                            label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CLASS_TYPE')} </strong>)}
                            getNewOptionData={(inputValue, optionLabel)=>({id: inputValue, name: optionLabel, __isNew__: true})}
                            loadOptions={(name, done) => {
                                if (formValues && formValues.technologySubcategory && formValues.technologySubcategory.id) {
                                    instanceAPI({
                                        method: 'post',
                                        url: '/technology-class-types/filter',
                                        data: { page: 0, size: 6, filter: { name: name, parentId: formValues.technologySubcategory.id } },
                                    }).then(({items}) => done(items)).catch(done.bind(null, []));
                                } else {
                                    done([]);
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Paper>

            <Row>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <SelectEntities
                        disabled={disabled}
                        name="assetClassification"
                        type={ENTITY_TYPES.ASSET_CLASSES}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('ASSET_CLASSES$ASSET_CLASS')} </strong>)}
                    />
                </Col>
                {/*
                <Col xs={12} md={8}>
                    <SelectEntities
                        isMulti={true}
                        disabled={disabled}
                        name="dataDomains"
                        type={ENTITY_TYPES.DATA_DOMAINS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        getOptionLabel={item => formatTechnologyLabel(item)}
                        label={(<strong> {translate('DATA_DOMAINS$TITLE')} </strong>)}
                    />
                </Col>
                */}
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <SelectEntities
                        name="environmentType"
                        disabled={disabled}
                        type={ENTITY_TYPES.ENVIRONMENT_TYPES}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('ENVIRONMENT_TYPE$TITLE')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <SelectEntities
                        name="vendor"
                        disabled={disabled}
                        type={ENTITY_TYPES.VENDORS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong className="required-asterisk"> {translate('VENDORS$VENDOR')} </strong>)}
                        component={MdAsyncCreatableSelect}
                        onCreateOption={name => createVendor({name, isSystemVendor: true})}
                        getNewOptionData={(inputValue, optionLabel) => ({
                            id: inputValue,
                            name: optionLabel,
                            __isNew__: true
                        })}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <SelectEntities
                        name="country"
                        disabled={disabled}
                        type={ENTITY_TYPES.COUNTRIES}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('ORGANIZATION$COUNTRY')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        type="number"
                        component={MdInput}
                        disabled={disabled}
                        name="riskReduction"
                        placeholder={translate('CYBER_SECURITY_TOOLS$RISK_REDUCTION')}
                        label={(<strong> {translate('CYBER_SECURITY_TOOLS$RISK_REDUCTION')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        type="number"
                        component={MdInput}
                        disabled={disabled}
                        name="riskReductionPercent"
                        inputProps={{min: 0, max: 100}}
                        placeholder={translate('CYBER_SECURITY_TOOLS$RISK_REDUCTION_PERCENTAGES')}
                        label={(<strong> {translate('CYBER_SECURITY_TOOLS$RISK_REDUCTION_PERCENTAGES')}</strong>)}
                    />
                </Col>
                <Col xs={12} md={6} xl={4} xxl={3} className="offset-bottom-4">
                    <Field
                        type="number"
                        name="toolPrice"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('CYBER_SECURITY_TOOLS$LICENSE_COST')}
                        label={(<strong> {translate('CYBER_SECURITY_TOOLS$LICENSE_COST')} </strong>)}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} className="offset-bottom-4">
                    <SelectEntities
                        isMulti={true}
                        name="systems"
                        disabled={disabled}
                        type={ENTITY_TYPES.SYSTEMS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('SYSTEMS$TITLE')} </strong>)}
                    />
                </Col>
                <Col xs={12} className="offset-bottom-4">
                    <Field
                        name="notes"
                        multiline={true}
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('GLOBALS$NOTES')}
                        label={(<strong> {translate('GLOBALS$NOTES')} </strong>)}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                               hint={findHint(hints, isNew ? 'BUTTON_TECHNOLOGIES_CREATE' : 'BUTTON_TECHNOLOGIES_SAVE')}/>
                    <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"
                              hint={findHint(hints, 'BUTTON_TECHNOLOGIES_RESET')}/>
                    <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_TECHNOLOGIES_CANCEL')}/>
                </Col>
            </Row>
        </form>
    );
})));
