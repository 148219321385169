
// outsource dependencies

// local dependencies
import { EDIT } from '../actions';
import { QUAL_QUESTIONS } from '../../../constants/routes';

// configuration
let initial = {
    initialized: false,
    expectAnswer: true,
    errorMessage: null,
    data: {},
    // previous path
    back: QUAL_QUESTIONS.LINK(),
};

export default function ( state = initial, action ) {
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case EDIT.CLEAR:
            state = initial;
            break;
        case EDIT.DATA:
            state = { ...state, data: options.data };
            break;
        case EDIT.META:
            state = { ...state, ...options, data: state.data };
            break;
    }
    // EDIT.PREFIX.test(type)&&
    // console.log(`%c REDUCER EDIT ${type} `, 'color: #fff; background: #232323; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n action:', action
    //     // ,'\n arguments:', arguments
    // );
    return state;
}
