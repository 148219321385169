// outsource dependencies
import moment from 'moment';
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {SYSTEM_LOG} from '../actions';
import {history} from '../../../store';
import {config} from '../../../constants';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {WarningBtn} from '../../../components/md-button';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatCompliance, formatFileSize} from '../../../services/data-formatting.service';
import {GDPR_SYSTEM_COMPLIANCE_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {useParams} from "react-router-dom";
import {getQueryStringParameter} from "../../../helpers/react-utils";
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const SystemLog = (props) => {
    let {systemId} = useParams();
    useEffect(() => {
        props.initialize(systemId);
        return () => {
            props.clear();
        }
    }, [])
    // let isNew = id === NEW_ID;
    let { expectAnswer, data, clearError, errorMessage, system, back, hints } = props;
    if (!back) back = getQueryStringParameter('back');
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ GDPR_SYSTEM_COMPLIANCE_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={12} lg={10}>
                        <h2>
                            {/*{translate('GDPR$GDPR')} {translate('GDPR$SYSTEM_COMPLIANCE_LOG')}&nbsp;*/}
                            {/*&quot;<strong>{system.name}</strong>&quot; {translate('SYSTEMS$SYSTEM')}*/}
                            <RichHintTitle
                                update={SYSTEM_LOG}
                                name={translate('GDPR$SYSTEM_COMPLIANCE_LOG')}
                                expectAnswer={expectAnswer}
                                data={findHint(hints, `GDPR_SYSTEM_COMPLIANCE_STATUS_LOG_TITLE`)}/>
                            <strong>&quot;{system.name}&quot;</strong>
                            <Preloader expectAnswer={expectAnswer} type="ICON" />
                        </h2>
                    </Col>
                    <Col xs={12} lg={2} className="text-right top-indent-4">
                        <WarningBtn className="offset-left-1" onClick={()=>history.push(back)} tooltip={translate('GLOBALS$BACK')} hint={findHint(hints, 'BUTTON_GDPR_SYSTEM_COMPLIANCE_STATUS_LOG_BACK')}>
                            <i className="fa fa-reply" aria-hidden="true" />
                        </WarningBtn>
                    </Col>
                </Row>
                <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}> <SearchFilterConnected /> </Col> </Row>
                <Row> <Col xs={12}> <ErrorMessage active message={errorMessage} onChange={clearError} /> </Col> </Row>
                { data.length ? (
                    <Paper> <ConnectedTable /> </Paper>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                )}
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({...state.gdprSystemCompliance.systemLog, hints: state.gdprSystemCompliance.systemLog.hintsData}),
    dispatch => ({
        clear: () => dispatch({ type: SYSTEM_LOG.CLEAR }),
        initialize: systemId => dispatch({ type: SYSTEM_LOG.INITIALIZE, systemId }),
        clearError: () => dispatch({ type: SYSTEM_LOG.META, errorMessage: null })
    })
)(SystemLog);

const ConnectedInitializer = connect(
    state => ({initialize: state.gdprSystemCompliance.systemLog.initialized, hints: state.gdprSystemCompliance.systemLog.hintsData}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter: state.gdprSystemCompliance.systemLog.filter,
        disabled: state.gdprSystemCompliance.systemLog.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: SYSTEM_LOG.META, filter}),
        applyFilter: filter => dispatch({type: SYSTEM_LOG.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('GDPR$SEARCH_BY_ARTICLE')}
            />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.gdprSystemCompliance.systemLog}),
    dispatch => ({
        changePage: page => dispatch({type: SYSTEM_LOG.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: SYSTEM_LOG.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: SYSTEM_LOG.UPDATE_DATA, size, page: 0}),
    })
)(({ data, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'chapterNumber'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('chapterNumber')}
                                >
                            {translate('GDPR$CHAPTER_NUMBER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'chapter'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('chapter')}
                                >
                            {translate('GDPR$CHAPTER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'articleNumber'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('articleNumber')}
                                >
                            {translate('GDPR$ARTICLE_NUMBER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'article'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('article')}
                                >
                            {translate('GDPR$ARTICLE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('GLOBALS$OWNER')} </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'compliance'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('compliance')}
                                >
                            {translate('GDPR$COMPLIANCE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('GLOBALS$DOCUMENT')} </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'createdAt'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('createdAt')}
                                >
                            {translate('GLOBALS$CREATED')}
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell align="center">{get(item, 'chapter.chapterNumber')}</TableCell>
                    <TableCell>{get(item, 'chapter.name')}</TableCell>
                    <TableCell align="center">{get(item, 'article.articleNumber')}</TableCell>
                    <TableCell>{get(item, 'article.name')}</TableCell>
                    <TableCell>{get(item, 'owner.fullName')}</TableCell>
                    <TableCell>{formatCompliance(item.compliance)}</TableCell>
                    <TableCell>{ item.document && <span>{get(item, 'document.fileName')} ({ formatFileSize(get(item, 'document.fileSize')) })</span> }</TableCell>
                    <TableCell>{item.createdAt && moment(item.createdAt).format(config.clientTimeFormat+', h:mm:ss a')}</TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));
