import React from 'react';
import {history} from "../../store";
import {HOME} from '../../constants/routes';
import {translate} from '../../services/translate.service'
import {Link} from 'react-router-dom';

const breadcrumb = {
    padding: 6,
    marginBottom: 10,
    backgroundColor: 'transparent',
};

function buildMap(url, breadCrumbsMap, result = []) {

    if (!breadCrumbsMap[url]) return result;
    let item = breadCrumbsMap[url];
    item.url = url;

    if (item) {
        result[item.id] = item;
        if (item.parent) {
            for (let url in breadCrumbsMap) {
                if (breadCrumbsMap[url].id === item.parent) buildMap(url, breadCrumbsMap, result);
            }
        }
    }
    return result;
}

function Breadcrumbs(props) {
    const {breadCrumbsMap, dashboardData} = props;
    const url = history.location.pathname.replace(/\d.*/g, '').replace(/[/]$/, '');
    const list = breadCrumbsMap ? buildMap(url, breadCrumbsMap) : null;
    return dashboardData
        ?
        <DashboardBreadCrumbs dashboardData={dashboardData} />
        :
        <BreadCrumbsLayout breadcrumbsList={list} />
}

function DashboardBreadCrumbs({dashboardData}) {
    const {sections, currentTab} = dashboardData;
    const breadcrumbsList = sections.length ? sections[currentTab].breadcrumbs : [];

    function isLast(index) {
        return index === breadcrumbsList.length - 1;
    }

    return (
        <nav className="row justify-content-center">
            <ol style={breadcrumb} className="breadcrumb">
                <li className="breadcrumb-item align-items-center">
                    <Link to={'/private/' + HOME.ROUTE}  style={{padding: `6px 0 6px 12px`}}>
                        <i className="fa fa-home" aria-hidden="true"> </i>
                    </Link>
                </li>
                <span className="separator"></span>

                {
                    breadcrumbsList && breadcrumbsList.map((crumb, ci) => {
                        const disabled = isLast(ci);
                        return (
                            <li key={ci} className="breadcrumb-item align-items-center">
                                <Link to={crumb.link} disabled={disabled} className="btn-link" style={{padding: `6px 0`, marginLeft: 10}}
                                   onClick={() => {
                                       history.push(crumb.link);
                                       return false
                                   }}>
                                    {crumb.title}
                                </Link>
                            </li>
                        );
                    })
                }

            </ol>
        </nav>
    );
}

function BreadCrumbsLayout({breadcrumbsList}) {
    function isLast(index) {
        return index === breadcrumbsList.length - 1;
    }

    return (
        <nav className="row justify-content-center">
            <ol style={breadcrumb} className="breadcrumb">
                <li className="breadcrumb-item align-items-center">
                    <Link to={'/private/' + HOME.ROUTE}  style={{padding: `6px 0 6px 12px`}}>
                        <i className="fa fa-home" aria-hidden="true"> </i>
                        {/*translate('MENU$HOME_PAGE')*/}
                    </Link>
                </li>
                <span className="separator"> &nbsp; </span>
                {
                    breadcrumbsList.map((crumb, ci) => {
                        const disabled = isLast(ci);
                        return (
                            <li
                                key={ci}
                                className="breadcrumb-item align-items-center"
                            >
                                <Link to={crumb.url} className="btn-link" disabled={disabled} style={{padding: `6px 0`}}>
                                    {translate(crumb.title)}
                                </Link>

                            </li>
                        );
                    })
                }
            </ol>
        </nav>
    );
}

export default Breadcrumbs;
