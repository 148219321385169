
// outsource dependencies

// local dependencies
import { QUAL_LIST } from "../actions";

let initial = {
    data: [],
    meta: {
        hintsData: [],
        initialized: false,
        expectAnswer: false,
        errorMessage: null
    }
};

export default function ( state = initial, action ) {
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case QUAL_LIST.CLEAR:
            state = initial;
            break;
        case QUAL_LIST.LIST:
            state = { ...state, ...options };
            break;
        case QUAL_LIST.META:
            state = { ...state, meta: {...state.meta, ...options} };
            break;
    }
    // /@quant\/list/.test(type)&&
    // console.log(`%c CATEGORIES ${type} `, 'color: #fff; background: #343434; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n action:', action
    //     // ,'\n arguments:', arguments
    // );
    return state;
}
