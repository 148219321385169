
// outsource dependencies
import { put, call, takeEvery, select, take } from 'redux-saga/effects';

// local dependencies
import { CATEGORIES } from '../actions';
import { history } from '../../../store';
import { LIST } from '../../domains/actions';
import { instanceAPI } from '../../../services/api.service';
import getHintsList, {CATEGORIES_EDIT_HINTS} from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(CATEGORIES.EDIT.REQUEST, initializeSaga);
    yield takeEvery(CATEGORIES.GET_ITEM.REQUEST, getCategorySaga);
    yield takeEvery(CATEGORIES.UPDATE_ITEM.REQUEST, updateCategorySaga);
    yield takeEvery(CATEGORIES.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ( {type, ...options} ) {
    yield put({type: CATEGORIES.EDIT.CLEAR});
    yield put({type: CATEGORIES.EDIT_META, initialized: true });
    // get list of risk model domains
    yield put({type: LIST.GET_LIST.REQUEST });
    yield put({type: CATEGORIES.GET_HINTS_DATA.REQUEST});
    yield take(LIST.GET_LIST.FINISH);
    // check if category already exist
    if ( options.id !== 'new' ) {
        let id = Number(options.id);
        // get category data
        yield put({type: CATEGORIES.GET_ITEM.REQUEST, id});
        yield take(CATEGORIES.GET_ITEM.FINISH);
    } else {
        yield put({type: CATEGORIES.EDIT_META, newItem: true});
    }
    yield put({type: CATEGORIES.EDIT_META, initialized: false});
}

function* getCategorySaga ( {type, id} ) {
    try {
        // get riskModel for api request
        let {riskModel} = yield select(state => state.app);
        // get category
        let item = yield call(getCategory, id, riskModel);
        //set item
        yield put({type: CATEGORIES.ITEM, item});
    }
    catch ({message}) {
        yield put({type: CATEGORIES.EDIT_META, errorMessage: message});
    }
    yield put({type: CATEGORIES.GET_ITEM.FINISH});
}

function* updateCategorySaga ( {type, ...options} ) {
    yield put({type: CATEGORIES.EDIT_META, expectAnswer: true });
    try {
        // get data for api request
        let { riskModel } = yield select(state => state.app);
        // check if category is new
        let { newItem } = yield select(state => state.categories.edit.meta);
        // select method of updating
        let method = newItem ? createCategory : updateCategory;
        // request for update
        let item = yield call(method, options, riskModel );
        //update item
        yield put({type: CATEGORIES.ITEM, item});
        yield put({type: CATEGORIES.EDIT_META, expectAnswer: false });
        // NOTE go to list
        yield call(history.back);
    }
    catch ({message}) {
        yield put({type: CATEGORIES.EDIT_META, expectAnswer: false, errorMessage: message});
    }
    yield put({type: CATEGORIES.UPDATE_ITEM.FINISH});
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, CATEGORIES_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: CATEGORIES.EDIT_META, hintsData});
    } catch ( {message} ) {
        yield put({type: CATEGORIES.EDIT_META, errorMessage: message});
    }
    yield put({type: CATEGORIES.GET_HINTS_DATA.FINISH});
}

/**
 * get category by id
 * @param {Number} id - category id
 * @param {Object} riskModel
 * @private
 */
function getCategory( id, riskModel ) {
    return instanceAPI({method: 'get', url: `/risk-models/${riskModel.id}/categories/${id}`});
}

/**
 * update category
 * @param {Object} category
 * @param {Object} riskModel
 * @private
 */
function updateCategory( category, riskModel ) {
    return instanceAPI({
        method: 'PUT',
        url: `/risk-models/${riskModel.id}/categories`,
        data: category
    });
}

/**
 * create category
 * @param {Object} category
 * @param {Object} riskModel
 * @private
 */
function createCategory( category, riskModel ) {
    return instanceAPI({
        method: 'POST',
        url: `/risk-models/${riskModel.id}/categories`,
        data: category
    });
}
