
// outsource dependencies

// local dependencies

export const ACTIONS = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        UPDATE: `${prefix}UPDATE`,
        CANCEL: `${prefix}CANCEL`,
        GET_DATA: `${prefix}GET_DATA`,
        INITIALIZE: `${prefix}INITIALIZE`,
        UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
    };
})('@organization-risk-scoring/');
