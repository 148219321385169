// outsource dependencies
import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import Paper from '@mui/material/Paper';

// local dependencies
import Form from './form';
import {CATEGORIES} from '../actions';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {translate} from '../../../services/translate.service';
import {CATEGORIES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {useParams} from "react-router-dom";
import {LIST} from '../../cyber-roles/actions';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const EditCategory = (props) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
        let {newItem, expectAnswer, hints} = props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={CATEGORIES_MAP} />
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span:8,offset:2}}>
                            <Paper className="indent-5">
                                    <span>
                                        <RichHintTitle
                                            update={LIST}
                                            name={newItem ? translate('CATEGORIES$CREATE_CATEGORY') : translate('CATEGORIES$EDIT_CATEGORY')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints,'CATEGORIES_TITLE')}
                                        />
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                                <ConnectedError />
                                {/*edit category form*/}
                                <Form />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
}

export default connect(
    state => ({ expectAnswer: state.categories.edit.meta.expectAnswer, newItem: state.categories.edit.meta.newItem, hints: state.categories.edit.meta.hintsData }),
    dispatch => ({
        initialize: ( id ) => dispatch({type: CATEGORIES.EDIT.REQUEST, id }),
        clear: () => dispatch({type: CATEGORIES.EDIT.CLEAR})
    })
)(EditCategory)


const ConnectedInitializer = connect(
    state => ({initialize: state.categories.edit.meta.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.categories.edit.meta.errorMessage}),
    dispatch => ({clearError: () => dispatch({ type: CATEGORIES.EDIT_META, errorMessage: null })})
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
