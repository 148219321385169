// outsource dependencies
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {ASSOCIATE_SYSTEMS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const Edit = (props, {expectAnswer}) => {
    let params = useParams();
    useEffect(() => {
        props.initialize(params);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = params.id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ ASSOCIATE_SYSTEMS_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:8,offset:2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                <span>
                                    <RichHintTitle
                                        update={EDIT}
                                        name={isNew ? `ASSOCIATE_SYSTEMS$CREATE_SYSTEM` : `ASSOCIATE_SYSTEMS$EDIT_SYSTEM`}
                                        expectAnswer={expectAnswer}
                                        data={findHint(hints, isNew ? `ASSOCIATE_SYSTEMS_CREATE_TITLE` : 'ASSOCIATE_SYSTEMS_EDIT_TITLE')} />
                                </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON" />
                            </h2>
                            <ConnectedError />
                            <ConnectedForm isNew={isNew} />
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({expectAnswer: state.associateSystems.edit.expectAnswer, hints: state.associateSystems.edit.hintsData}),
    dispatch => ({
        initialize: params => dispatch({type: EDIT.INITIALIZE, ...params}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.associateSystems.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.associateSystems.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.associateSystems.edit.data,
        disabled: state.associateSystems.edit.expectAnswer,
        vendor: state.associateSystems.edit.data.vendor || {},
        hints: state.associateSystems.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({form: 'editAssociateSystems', enableReinitialize: true,})(
    ({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, vendor, hints})=>(
        <form autoComplete="off" name="editAssociateSystems" onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <h4><span className="text-uppercase"> {translate('VENDORS$VENDOR_COLON')} </span> <strong>{vendor.name}</strong></h4>
                </Col>
            </Row>
            <Row className="offset-bottom-6">
                <Col xs={12}>
                    <SelectEntities
                        name="systems"
                        isMulti={true}
                        disabled={disabled}
                        type={ENTITY_TYPES.SYSTEMS}
                        placeholder={translate('SYSTEMS$TITLE')}
                        label={(<strong> {translate('SYSTEMS$TITLE')} </strong>)}
                            />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2"
                               hint={findHint(hints, isNew ? 'BUTTON_ASSOCIATE_SYSTEMS_CREATE' : 'BUTTON_ASSOCIATE_SYSTEMS_SAVE')} />
                    <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints, `BUTTON_ASSOCIATE_SYSTEMS_RESET`)} />
                    <CancelBtn onClick={cancel} hint={findHint(hints, `BUTTON_ASSOCIATE_SYSTEMS_CANCEL`)} />
                </Col>
            </Row>
        </form>
    )
)));
