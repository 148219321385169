// outsource dependencies
import {difference} from 'lodash';
import {toastr} from 'react-redux-toastr';
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {APP} from '../../actions/types';
import {EDIT} from './actions';
// import { makeSeparatingCommas, convertOptionsToNumber } from '../organization/saga';
import {getSelf, instanceAPI} from '../../services/api.service';
import {translate} from '../../services/translate.service';
import separateService from '../../services/separate-with-comma.service';
import getHintsList, { SETTINGS_EDIT_HINTS } from '../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
    // yield takeEvery(EDIT.SETUP_STATE, setupStateSaga);
    // yield takeEvery(EDIT.SETUP_COUNTRY.REQUEST, setupCountrySaga);
}


function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, SETTINGS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}
function* initializeSaga () {
    yield put({type: EDIT.CLEAR});
    try {
        let user = yield call(getSelf);
        let data = yield call(getData);
        let availableLanguages = yield call(getLanguages);
        // // NOTE: initialize lists of states and cities for current country
        // let countryId = get(data, 'country.id', null);
        // if ( countryId ) {
        //     let stateId = get(data, 'state.id', null);
        //     yield put({type: EDIT.SETUP_COUNTRY.REQUEST, countryId});
        //     yield take(EDIT.SETUP_COUNTRY.FINISH);
        //     yield put({type: EDIT.SETUP_STATE, countryId, stateId});
        // }
        //NOTE make separating commas for insurances
        // yield put({type: EDIT.INSURANCE_COMMA_SEPARATED});
        yield call(separateService.separate, data);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, availableLanguages, user});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
    } catch ( {message} ) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, initialized: true});
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    options = separateService.convertDefault(options);

    try {
        let prevData = yield select(state => state.organization.data);
        let data = yield call(updateData, options);
        // NOTE check difference between old and new supported languages
        const unequal = Boolean(difference(prevData.supportedLanguages, options.supportedLanguages).length
            || difference(options.supportedLanguages, prevData.supportedLanguages).length);
        // NOTE update supported languages
        if (unequal) {yield put({type: APP.SETUP_LANGUAGES})

        }
        // NOTE make separating commas for insurances
        // yield put({type: EDIT.INSURANCE_COMMA_SEPARATED});
        yield call(separateService.separate, data);
        yield put({type: EDIT.DATA, data});
        yield call(toastr.success, translate('ORGANIZATION$TITLE'), translate('GLOBALS$SUCCESSFUL_DATA_UPDATE'));
        yield put({type: EDIT.META, expectAnswer: false});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

// function* setupCountrySaga ({countryId}) {
//     yield put({type: EDIT.META, expectAnswer: true, states: [], cities: []});
//     try {
//         // NOTE clear old field values
//         yield put(change('editOrganization', 'state', null));
//         yield put(change('editOrganization', 'city', null));
//         // NOTE get list of states for chosen country
//         let states = yield call(getStates, countryId);
//         // NOTE get list of cities for chosen country
//         // cause not all countries have states but can have cities
//         let cities = yield call(getCities, countryId);
//         yield put({type: EDIT.META, expectAnswer: false, states, cities});
//     }
//     catch ({message}) {
//         yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
//     }
//     yield put({type: EDIT.SETUP_COUNTRY.FINISH});
// }
//
// function* setupStateSaga ({countryId, stateId}) {
//     yield put({type: EDIT.META, expectAnswer: true, cities: []});
//     try {
//         // NOTE clear old field values
//         yield put(change('editOrganization', 'city', null));
//         // NOTE get list of cities for chosen country and state
//         let cities = yield call(getCities, countryId, stateId);
//         yield put({type: EDIT.META, expectAnswer: false, cities});
//     }
//     catch ({message}) {
//         yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
//     }
// }

/**
 * get data
 * @private
 */
function getData () {
    return instanceAPI({method: 'get', url: `/organizations/self`});
}

/**
 * update organization
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return instanceAPI({ method: 'post', url: `/organizations/update-organization`, data});
}

/**
 * get list of states for current country
 * @param {Number} countryId
 * @private
 */
// function getStates( countryId ) {
//     return new Promise (( resolve, reject ) => {
//         instanceAPI({ method: 'post', url: `/states/filter`, data: { page: 0, size: 6, filter: { countryId } } })
//             .then(({items}) => resolve(items)).catch(reject)
//     });
// }

/**
 * get list of cities for current country and state
 * @param {Number} countryId
 * @param {Number} stateId
 * @private
 */
// function getCities( countryId, stateId ) {
//     return new Promise (( resolve, reject ) => {
//         instanceAPI({ method: 'post', url: `/cities/filter`, data: { page: 0, size: 6, filter: { countryId, stateId } } })
//             .then(({items}) => resolve(items)).catch(reject)
//     });
// }

/**
 * get all available supported languages
 * @private
 */
function getLanguages() {
    return instanceAPI({ method: 'post', url: `/supported-languages/filter`, data: { size: 10000 } })
            .then(({items}) => items);
}
