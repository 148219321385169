// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import MdDatePicker from '../../../components/md-date-picker';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {CAPITAL_COSTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import separateWithCommaService from '../../../services/separate-with-comma.service';
import inputService from '../../../services/input.service';

// config
export const FORM_NAME = 'editCapitalCost';
export const changeField = (field, value) => change(FORM_NAME, field, value);

export const LICENSE_TYPES = {
    CPU: 'CPU',
    ANNUAL: 'Annual',
    PERPETUAL: 'Perpetual',
    NUMBER_OF_USERS: 'Number of Users',
};

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ CAPITAL_COSTS_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:8,offset:2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                <span>
                                    <RichHintTitle
                                        update={EDIT}
                                        name={isNew ? translate('FIXED_CAPITAL_COSTS$CREATE_COST') : translate('FIXED_CAPITAL_COSTS$EDIT_COST')}
                                        data={findHint(hints, isNew ?'FIXED_CAPITAL_COSTS_CREATE_TITLE' : 'FIXED_CAPITAL_COSTS_EDIT_TITLE')}
                                    />
                                </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                            </h2>
                            <ConnectedError />
                            <ConnectedForm isNew={isNew} />
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({expectAnswer: state.capitalCosts.edit.expectAnswer, hints: state.capitalCosts.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.capitalCosts.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.capitalCosts.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.capitalCosts.edit.data,
        formValues: getFormValues(FORM_NAME)(state),
        disabled: state.capitalCosts.edit.expectAnswer,
        hints: state.capitalCosts.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        changeLicenseType: () => dispatch({type: EDIT.CHANGE_LICENSE_TYPE}),
        calculateTotalCost: options => dispatch({type: EDIT.CALCULATE_TOTAL_COST, ...options}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: values => {
        let errors = {};
        // security tool
        if (!values.technologies) {
            errors.technologies = 'TECHNOLOGY$TECHNOLOGY_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formValues={}, changeLicenseType, calculateTotalCost, hints})=>{
    let hasAdditionalFields = [LICENSE_TYPES.CPU, LICENSE_TYPES.NUMBER_OF_USERS].includes(get(formValues, 'licenseType.name'));
    return (<form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    skipTouch
                    disabled={disabled}
                    name="technology"
                    type={ENTITY_TYPES.TECHNOLOGIES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('TECHNOLOGIES$TECHNOLOGY')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    name="vendor"
                    disabled={disabled}
                    type={ENTITY_TYPES.VENDORS}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('VENDORS$VENDOR')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <SelectEntities
                    name="businessUnit"
                    disabled={disabled}
                    type={ENTITY_TYPES.BUSINESS_UNITS}
                    getOptionLabel={item => formatBusinessUnitLabel(item)}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('BUSINESS_UNITS$BUSINESS_UNIT')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <SelectEntities
                    name="licenseType"
                    disabled={disabled}
                    onChange={changeLicenseType}
                    type={ENTITY_TYPES.LICENSE_TYPES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('FIXED_CAPITAL_COSTS$LICENSE_TYPE')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} lg={hasAdditionalFields ? 4 : 6} className="offset-bottom-4">
                <Field
                    name="licenseCost"
                    component={MdInput}
                    inputProps={{min: 0}}
                    disabled={disabled || !get(formValues, 'licenseType')}
                    placeholder={translate('FIXED_CAPITAL_COSTS$LICENSE_COST')}
                    onChange={(e, value, pr, field)=>calculateTotalCost({value, field})}
                    label={(<strong> {translate('FIXED_CAPITAL_COSTS$LICENSE_COST')} </strong>)}
                    normalize={inputService.toReal}
                        />
            </Col>
            {get(formValues.toString().split(',').length === 1 ? separateWithCommaService.separateReal(formValues) : formValues, 'licenseType.name') === LICENSE_TYPES.NUMBER_OF_USERS && (<Col xs={12} lg={4} className="offset-bottom-4">
                <Field
                    name="licenseUsers"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('FIXED_CAPITAL_COSTS$NUMBER_OF_USERS')}
                    onChange={(e, value, pr, field)=>calculateTotalCost({value, field})}
                    label={(<strong> {translate('FIXED_CAPITAL_COSTS$NUMBER_OF_USERS')} </strong>)}
                    normalize={inputService.toInteger}
                        />
            </Col>)}
            {get(formValues.toString().split(',').length === 1 ? separateWithCommaService.separateReal(formValues) : formValues, 'licenseType.name') === LICENSE_TYPES.CPU && (<Col xs={12} lg={4} className="offset-bottom-4">
                <Field
                    name="licenseCpus"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('FIXED_CAPITAL_COSTS$NUMBER_OF_CPUS')}
                    onChange={(e, value, pr, field)=>calculateTotalCost({value, field})}
                    label={(<strong> {translate('FIXED_CAPITAL_COSTS$NUMBER_OF_CPUS')} </strong>)}
                    normalize={inputService.toInteger}
                        />
            </Col>)}
            <Col xs={12} lg={hasAdditionalFields ? 4 : 6} className="offset-bottom-4">
                <Field
                    readOnly
                    name="totalCosts"
                    component={MdInput}
                    placeholder={translate('GLOBALS$TOTAL_COSTS')}
                    disabled={disabled || !get(formValues, 'licenseType')}
                    label={(<strong> {translate('GLOBALS$TOTAL_COSTS')} </strong>)}
                    normalize={inputService.freeze}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="startDate"
                    disabled={disabled}
                    component={MdDatePicker}
                    inputProps={{max: get(formValues, 'endDate')}}
                    label={(<strong> {translate('GLOBALS$START_DATE')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="endDate"
                    disabled={disabled}
                    component={MdDatePicker}
                    inputProps={{min: get(formValues, 'startDate')}}
                    label={(<strong> {translate('GLOBALS$END_DATE')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    multiline
                    name="comments"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$COMMENTS')}
                    label={(<strong> {translate('GLOBALS$COMMENTS')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2"
                           hint={findHint(hints, isNew ? 'BUTTON_FIXED_CAPITAL_COSTS_CREATE' : 'BUTTON_FIXED_CAPITAL_COSTS_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_FIXED_CAPITAL_COSTS_RESET')} />
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_FIXED_CAPITAL_COSTS_CANCEL')} />
            </Col>
        </Row>
    </form>)
})));
