
// outsource dependencies
import React, { Component } from 'react';

// local dependencies
import is from '../services/is.service';
// configuration

/**
 *
 *
 * @public
 */
class AlertError extends Component {
    constructor (...args) {
        super(...args);
        this.state = {
            message: null,
        };
    }

    componentDidMount () {
        this.setState({message: this.props.message});
    }

    componentDidUpdate ( { message } ) {
        if ( message !== this.props.message && this.state.message !== this.props.message ) {
            this.setState({message: this.props.message});
        }
    }

    clear ( event ) {
        let { onChange } = this.props;
        is.function(onChange)&&onChange( event );
        this.setState({message: null});
    }

    render () {
        let { title = 'Error:', active = false } = this.props;
        let { message } = this.state;
        let messageSplit = [];
        if (message) {
            if (message.indexOf('\\n') !== -1) {
                messageSplit = message.split('\\n');
            } else {
                messageSplit = message.split('\n');
            }
        }

        return !message ? ('') : (
            <div className="row">
                <div className="col-xs-12">
                    <p className="alert alert-danger  alert-dismissible">
                        <strong> { title } </strong>
                        {
                            messageSplit.map((messageLine, key) => {
                                return <span key={key}>{messageLine}{key < messageSplit.length - 1 ? <br/> : ''}</span>;
                            })
                        }
                        { !active ? '' : (
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={e=>active&&this.clear(e)}></button>
                        )}
                    </p>
                </div>
            </div>
        );
    }
}

export default AlertError;
