
// outsource dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { APP } from '../../actions/types';

// local dependencies
import CenteredBox from '../../components/centered-box';
import maintenanceImage from '../../images/down_for_maintenance.png';

/**
 * Check Health of server
 *
 * @constructor CheckHealth
 * @public
 */
class CheckHealth extends Component {
    render() {
        return (
            <div id="checkHealth">
                <CenteredBox>
                    <div className="container">
                        <div className="row content-wrap">
                            <div className="col-xs-12 col-md-5 offset-bottom-3">
                                <h1 className="text-uppercase title">
                                    <strong>Service unavailable</strong>
                                </h1>
                                <h3>
                                    The current page constantly checks the status of the service, and as soon as it becomes available you are redirected to the future.
                                </h3>
                            </div>
                            <div className="col-xs-12 col-md-7">
                                <img className="img-responsive" src={maintenanceImage} alt="Down for maintenance"/>
                            </div>
                        </div>
                    </div>
                </CenteredBox>
            </div>
        )
    }
    componentDidMount(){ this.interval = setInterval(this.props.check, 10*1000); }
    componentWillUnmount() { clearInterval(this.interval); }
}

// Export
export default connect(
    null,
    dispatch => ({
        check: () => dispatch({type: APP.HEALTH.REQUEST})
    })
)(CheckHealth);
