// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import ACTIONS from './actions';
import {DOWNLOAD_TYPES, PERMISSION} from '../../constants/spec';
import Preloader from '../../components/preloader';
import UploadFile from '../../components/data-import';
import {PrimaryBtn} from '../../components/md-button';
import ErrorMessage from '../../components/alert-error';
import {translate} from '../../services/translate.service';
import withDownloadLink from '../../components/download-link';
import PermissionAccess from '../../components/permission-access';

class Edit extends Component {
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { disabled, message, clearError, uploadFile, data } = this.props;
        return (<Container fluid>
            <Row className="offset-top-10">
                <Col xs={12} md={{span:10,offset:1}} lg={{span:6,offset:3}}>
                    <Paper className="indent-8">
                        <Row>
                            <Col xs={12} className="offset-bottom-4">
                                <h2 className="text-center text-uppercase">
                                    {translate('IMPORT_AND_EXPORT$QUANT_METRICS')}&nbsp;<Preloader expectAnswer={disabled} type="ICON" />
                                </h2>
                            </Col>
                        </Row>
                        <ErrorMessage active message={message} onChange={clearError} />
                        <Row className="text-center offset-bottom-5"> <Col xs={12}>
                            <DownloadLink disabled={disabled} />
                        </Col> </Row>
                        <Row>
                            <Col xs={12}  className='dropzone'>
                                <PermissionAccess permission={PERMISSION.IMPORT.QUANT_METRIC}>
                                    <UploadFile data={data} loading={disabled} uploadFile={uploadFile} />
                                </PermissionAccess>
                            </Col>
                        </Row>
                    </Paper>
                </Col>
            </Row>
        </Container>);
    }
}

export default connect(
    state => ({
        data: state.importQuantMetrics.data,
        message: state.importQuantMetrics.errorMessage,
        disabled: state.importQuantMetrics.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: ACTIONS.CLEAR}),
        uploadFile: file => dispatch({type: ACTIONS.UPLOAD_FILE, file}),
        clearError: () => dispatch({ type: ACTIONS.META, errorMessage: null})
    })
)(Edit)

const DownloadLink = withDownloadLink({downloadType: DOWNLOAD_TYPES.QUANT_METRIC.CSV_LIST})(props => (
    <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_DATA')} permission={PERMISSION.EXPORT.QUANT_METRIC} {...props}>
        <i className="fa fa-download" style={{fontSize: 20}} aria-hidden="true" />&nbsp;&nbsp;
        <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true" />
    </PrimaryBtn>
));
