
// outsource dependencies

// local dependencies
import { createTypes } from '../../actions/types';

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        INITIALIZE: `${prefix}INITIALIZE`,
        GET_DATA: createTypes(`${prefix}GET_DATA`),
    };
})('@dashboard-organization/');
