// outsource dependencies
import {get, findIndex} from 'lodash';
import {put, call, takeEvery, select} from 'redux-saga/effects';

// local dependencies
import {EDIT} from '../actions';
import {changeField} from './index';
import {history} from '../../../store';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import {instanceAPI} from '../../../services/api.service';
import {NEW_ID, USER_RATE_TYPES} from '../../../constants/spec';
import separateWithCommaService from '../../../services/separate-with-comma.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.CHANGE_TASK, changeTaskSaga);
}

function* initializeSaga({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        //NOTE separate with commas
        yield call(separateWithCommaService.separateReal, data);
        yield put({type: EDIT.DATA, data});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, initialized: true, ...params});
        // NOTE initialize task to get all information about task
        if (data && data.task) {
            yield call(initializeTaskData, {task: data.task});
        }
    } catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true});
    // NOTE convert to numeric format
    yield call(separateWithCommaService.convertReal, options);
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    } catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* changeTaskSaga({task, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true});
    yield call(initializeTaskData, {task});
    yield put({type: EDIT.META, expectAnswer: false});
}

function* initializeTaskData({task, ...options}) {
    let {estimatedHours, userRate, userRateType, totalCosts} = yield call(calculateTotalCosts, task);
    yield put(changeField('user', get(task, 'taskAssignee')));
    yield put(changeField('businessUnit', get(task, 'taskAssigneeDetails.businessUnit', null)));
    yield put(changeField('rateType', userRateType));
    yield put(changeField('rate', userRate));
    yield put(changeField('estimatedStartDate', get(task, 'estimatedStartDate')));
    yield put(changeField('estimatedEndDate', get(task, 'estimatedEndDate')));
    yield put(changeField('actualStartDate', get(task, 'actualStartDate')));
    yield put(changeField('actualEndDate', get(task, 'actualEndDate')));
    yield put(changeField('estimatedHours', estimatedHours));
    yield put(changeField('totalCosts', totalCosts));
}

function* cancelSaga() {
    let {back} = yield select(state => state.operationalCosts.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get data by id
 * @param {Number|String} id
 * @private
 */
function getData(id) {
    return new Promise((resolve, reject) => {
        if (id === NEW_ID) {
            return resolve({});
        }
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/budget/fixed-operational-costs/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update data
 * @param {Object} data
 * @private
 */
function updateData(data) {
    return new Promise((resolve, reject) => {
        let promise;
        if (!data.id || data.id === NEW_ID) { // CREATE
            promise = instanceAPI({method: 'post', url: `/budget/fixed-operational-costs`, data});
        } else { // UPDATE
            promise = instanceAPI({method: 'put', url: `/budget/fixed-operational-costs`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * calculate total costs
 * @param {Object} task
 * @private
 */
function calculateTotalCosts(task) {
    let estimatedHours = (get(task, 'estimatedHours') || 0);
    let userRateIndex = findIndex(get(task, 'taskAssigneeDetails.userRates'), item => !item.startDate || !item.endDate);
    let userRate = get(task, `taskAssigneeDetails.userRates[${userRateIndex}].rate`) || 0;
    let userRateType = get(task, `taskAssigneeDetails.userRates[${userRateIndex}].rateType`);
    let rateTypeHours;
    switch (userRateType) {
        default:
            rateTypeHours = 1;
            break;
        case USER_RATE_TYPES.ANNUAL:
            rateTypeHours = 2000;
            break;
        case USER_RATE_TYPES.MONTHLY:
            rateTypeHours = 170;
            break;
        case USER_RATE_TYPES.WEEKLY:
            rateTypeHours = 40;
            break;
        case USER_RATE_TYPES.DAILY:
            rateTypeHours = 8;
            break;
    }
    let totalCosts = estimatedHours * userRate / rateTypeHours;
    return {estimatedHours, userRate, userRateType, totalCosts};
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
