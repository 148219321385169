// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {Field, FieldArray, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import {filters} from '../../../components/filter';
import Preloader from '../../../components/preloader';
import {MdSelect} from '../../../components/md-select';
import ErrorMessage from '../../../components/alert-error';
import MdDatePicker from '../../../components/md-date-picker';
import SelectEntities from '../../../components/select-entities';
import {ENTITY_TYPES, USER_RATE_TYPES} from '../../../constants/spec';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {AddIconBtn, CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {USER_SETTINGS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';

// config
export const FORM_NAME = 'editUserSettings';

const EMPLOYMENT_TYPES = {
    EMPLOYEE: 'EMPLOYEE',
    CONTRACTOR: 'CONTRACTOR'
};

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ USER_SETTINGS_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:10, offset:1}} lg={{span:8, offset:2}} >
                        <Paper className="indent-5"> <Container fluid>
                            <Row className="offset-bottom-6">
                                <Col xs={12}>
                                    <h2 className="text-uppercase">
                                        <RichHintTitle update={EDIT}  name={`USER_SETTINGS$EDIT_SETTINGS`} data={findHint(hints, `USER_SETTINGS_EDIT_TITLE`)}/>
                                    </h2>
                                </Col>
                            </Row>
                            <Row> <Col xs={12}> <ConnectedError /> </Col> </Row>
                            <Row> <Col xs={12}> <ConnectedForm /> </Col> </Row>
                        </Container> </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({
        message: state.userSettings.edit.errorMessage,
        disabled: state.userSettings.edit.expectAnswer,
        hints: state.userSettings.edit.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({ type: EDIT.META, errorMessage: null })
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.userSettings.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.userSettings.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.userSettings.edit.data,
        disabled: state.userSettings.edit.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
        hints: state.userSettings.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: values => {
        let errors = {};
        // employment type
        if (!values.employmentType) {
            errors.employmentType = 'USER_SETTINGS$EMPLOYMENT_TYPE_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, cancel, formValues, change, hints})=>(
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12} lg={6}> <h4> <strong>{translate('USER_SETTINGS$NAME')}</strong> {get(formValues, 'fullName')} </h4> </Col>
            <Col xs={12} lg={6}> <h4> <strong>{translate('USERS$EMAIL_COLON')}</strong> {get(formValues, 'email')} </h4> </Col>
            <Col xs={12} lg={6}> <h4>
                <strong>{translate('BUSINESS_UNITS$BUSINESS_UNIT_COLON')}</strong>
                {formatBusinessUnitLabel(get(formValues, 'businessUnit'))}
            </h4> </Col>
            <Col xs={12} lg={6}> <h4> <strong>{translate('USERS$ROLES_COLON')}</strong> {get(formValues, 'roleNames', []).join(', ')} </h4> </Col>
        </Row>
        <Row className="offset-bottom-4"> <Col xs={12}>
            <Field
                labelKey="label"
                valueKey="value"
                disabled={disabled}
                component={MdSelect}
                name="employmentType"
                onChange={()=>change('userRates', [])}
                options={Object.keys(EMPLOYMENT_TYPES).map(option=>{
                    return {
                        value: option,
                        label: option
                    }
                })}
                optionsLabel={({value})=>filters.humanize(value)}
                sendValueProp
                placeholder={translate('USER_SETTINGS$EMPLOYMENT_TYPE')}
                simpleValue={value=>value&&{value, label: filters.humanize(value)}}
                 required={true}
                                    label={(<strong className="required-asterisk"> {translate('USER_SETTINGS$EMPLOYMENT_TYPE')} </strong>)}
                    />
        </Col> </Row>
        <Row> <Col xs={12}> <FieldArray name="userRates" component={Items} /> </Col> </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={false} disabled={pristine||invalid||disabled} className="offset-right-2" hint={findHint(hints,`BUTTON_USER_SETTINGS_SAVE`)} />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints,`BUTTON_VUSER_SETTINGS_RESET`)} />
                <CancelBtn onClick={cancel} hint={findHint(hints,`BUTTON_USER_SETTINGS_CANCEL`)} />
            </Col>
        </Row>
    </form>
))));

/**
* component for items of "userRates" list
*
* @param { Object } props
* @public
*/
const Items = withTranslation(connect(
    state => ({
        formValues: getFormValues(FORM_NAME)(state),
        disabled: state.userSettings.edit.expectAnswer
    }),
    null,
)(({fields, meta, disabled, formValues}) => {
    const isEmployee = get(formValues, 'employmentType') === EMPLOYMENT_TYPES.EMPLOYEE;
    let newItem = isEmployee ? {rateType: USER_RATE_TYPES.ANNUAL} : {};
    return (<div className="offset-bottom-4">
        <h3 className="text-uppercase">
            <span className="align-middle">{translate('USER_SETTINGS$RATES')}</span>&nbsp;
            <AddIconBtn disabled={disabled || !get(formValues, 'employmentType')} onClick={()=> fields.push(newItem)} />
        </h3>
        {!meta.error ? '' : (<h4 className="text-center is-invalid"><span className="form-text"> { translate(meta.error) } </span></h4>)}
        { fields.map((mKey, index) => (<Paper key={index} className="indent-3 offset-bottom-2">
            <Row>
                <Col xs={12} lg={4} className="offset-bottom-4">
                    <Field
                        labelKey="label"
                        valueKey="value"
                        component={MdSelect}
                        name={`${mKey}.rateType`}
                        options={Object.keys(USER_RATE_TYPES).map(option=>{
                            return {
                                value: option,
                                label: option
                            }
                        })}
                        disabled={disabled || isEmployee}
                        optionsLabel={({value}) => filters.humanize(value)}
                        sendValueProp
                        placeholder={translate('USER_SETTINGS$RATE_TYPE')}
                        simpleValue={value=>value&&{value, label: filters.humanize(value)}}
                        label={(<strong> {translate('USER_SETTINGS$RATE_TYPE')} </strong>)}
                            />
                </Col>
                <Col xs={6} lg={4} className="offset-bottom-4">
                    <Field
                        type="number"
                        component={MdInput}
                        disabled={disabled}
                        name={`${mKey}.rate`}
                        inputProps={{min: 0}}
                        placeholder={translate('USER_SETTINGS$RATE')}
                        label={(<strong> {translate('USER_SETTINGS$RATE')} </strong>)}
                            />
                </Col>
                <Col xs={6} lg={4} className="offset-bottom-4">
                    <SelectEntities
                        valueKey="id"
                        disabled={disabled}
                        name={`${mKey}.currency`}
                        type={ENTITY_TYPES.CURRENCIES}
                        placeholder={translate("DROPDOWN$TYPE_TO_SEARCH")}
                        label={(<strong> {translate('ORGANIZATION$CURRENCY')} </strong>)}
                        getOptionLabel={option => `${get(option, 'name')} (${get(option, 'code')})`}
                            />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        disabled={disabled}
                        component={MdDatePicker}
                        name={`${mKey}.startDate`}
                        inputProps={{max: get(formValues, `${mKey}.endDate`)}}
                        label={(<strong> {translate('GLOBALS$START_DATE')} </strong>)}
                            />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        disabled={disabled}
                        component={MdDatePicker}
                        name={`${mKey}.endDate`}
                        inputProps={{min: get(formValues, `${mKey}.startDate`)}}
                        label={(<strong> {translate('GLOBALS$END_DATE')} </strong>)}
                            />
                </Col>
            </Row>
        </Paper>)) }
    </div>)
}));
