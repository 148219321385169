import store from "../store";
import {APP} from "../actions/types";
import storageService from "./storage.service";


class IdleService {
    static idleTime = 0;
    static idleInterval = null;
    static idleTimeout = 1000;
    static idleLimit = 1800000; // Idle limit is 1800 seconds == 30 minutes

    static run(user) {

        if (user && user.logoutAfterInactivityTime > 1000) {
            IdleService.setIdleLimit(user.logoutAfterInactivityTime);
        }

        IdleService.stop();

        // Increment the idle time counter every minute.
        IdleService.idleInterval = setInterval(IdleService.timerIncrement, IdleService.idleTimeout);

        // Add stop timer events
        document.body.addEventListener('mousedown', IdleService.idleReset);
        // document.body.addEventListener('mousemove', IdleService.idleReset);
        window.addEventListener('scroll', IdleService.idleReset);
        window.addEventListener('touchstart', IdleService.idleReset);
        document.addEventListener('keydown', IdleService.idleReset);
    }

    static stop() {
        // Increment the idle time counter every minute.
        if (IdleService.idleInterval) {
            clearInterval(IdleService.idleInterval);
        }

        IdleService.idleTime = 0;
    }

    static setIdleLimit(limitMillis) {
        IdleService.idleLimit = limitMillis;
        console.log('Set auto-logout of user after inactivity time: ', IdleService.idleLimit / 1000);
    }

    static timerIncrement() {
        IdleService.idleTime += IdleService.idleTimeout;

        // console.log('Current Idle time is: ', IdleService.idleTime);

        if (IdleService.idleTime > IdleService.idleLimit) {
            // Logout after inactivity interval
            console.log('Disconnecting user after inactivity time of: ', IdleService.idleLimit / 1000);
            console.log('Current return url is: ', window.location.href);
            storageService.set('returnUrl', window.location.href);
            // NOTE sign out using app action to update view
            store.dispatch({type: APP.SIGN_OUT.REQUEST, logoutInfo: {isIdle: true}});
            // IdleService.stop();
        }
    }

    static idleReset(event) {
        IdleService.idleTime = 0;

        // console.log('Reset timer because of event: ', event);
    }
}

export default IdleService;
