
// outsource dependencies

// local dependencies

import {createTypes} from '../../actions/types';

export const EDIT = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        CHANGE_PASSWORD: `${prefix}CHANGE_PASSWORD`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@change-password/');
