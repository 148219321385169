// outsource dependencies
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, FieldArray, formValueSelector, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import {NEW_ID} from '../../../constants/spec';
import MdInput from '../../../components/md-input';
import MdSwitch from '../../../components/md-switch';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import MetricFormulaItems from '../../../components/metric-formula-items';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {RISK_METRICS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

// config
export const FORM_NAME = 'editRiskMetric';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={RISK_METRICS_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:8,offset:2}}>
                        <Paper className="indent-5">
                            <RichHintTitle update={EDIT} name={isNew ? `RISK_METRICS$CREATE_METRIC` : `RISK_METRICS$EDIT_METRIC`} expectAnswer={expectAnswer} data={findHint(hints,`RISK_METRICS_TITLE`)} />
                            <ConnectedError/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({...state.riskMetrics.edit, hints: state.riskMetrics.edit.hintsData}),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.riskMetrics.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.riskMetrics.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.riskMetrics.edit.data,
        disabled: state.riskMetrics.edit.expectAnswer,
        variableTypes: state.riskMetrics.edit.variableTypes,
        hints: state.riskMetrics.edit.hintsData,
        formulaValues: formValueSelector(FORM_NAME)(state, 'formula.formulaItems') || []
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        changeField: (field, value) => dispatch(change(FORM_NAME, field, value)),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: values => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formulaValues=[], variableTypes, changeField, hints})=>(
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    multiline
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$DESCRIPTION')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="isResidual"
                    fullWidth={false}
                    disabled={disabled}
                    component={MdSwitch}
                    label={(<strong> {translate('RISK_METRICS$IS_RESIDUAL')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <FieldArray
                    editItem={changeField}
                    values={formulaValues}
                    name="formula.formulaItems"
                    variableTypes={variableTypes}
                    component={MetricFormulaItems}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_RISK_METRICS_CREATE' : 'BUTTON_RISK_METRICS_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2"  hint={findHint(hints, `BUTTON_RISK_METRICS_RESET`)} />
                <CancelBtn onClick={cancel}  hint={findHint(hints, `BUTTON_RISK_METRICS_CANCEL`)} />
            </Col>
        </Row>
    </form>
))));
