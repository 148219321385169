
// outsource dependencies
import {change} from 'redux-form';
import { put, takeEvery } from 'redux-saga/effects';

// local dependencies
import ABBREV_DIALOG from './actions';

// configure
const FORM_NAME = 'geoRecordsForm';

export const changeField = (field, value) => change(FORM_NAME, field, value);

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(ABBREV_DIALOG.CANCEL, cancelSaga);
    yield takeEvery(ABBREV_DIALOG.INITIALIZE, initializeSaga);
}

function* initializeSaga ({data}) {
    const { content, title } = data;
    yield put({type: ABBREV_DIALOG.CLEAR});
    yield put({type: ABBREV_DIALOG.META, showModal: true});
    yield put({type: ABBREV_DIALOG.META, content, title });
    yield put({type: ABBREV_DIALOG.META, initialized: true});
}

function* cancelSaga () {
    yield put({type: ABBREV_DIALOG.CLEAR});
}
