// outsource dependencies
import createSagaMiddleware from 'redux-saga';
import {createBrowserHistory} from 'history';
import {applyMiddleware, compose, createStore} from 'redux';
import {push} from 'connected-react-router';
import {createRouterMiddleware} from '@lagunovsky/redux-react-router';

// local dependencies
import rootSaga from './sagas';
import rootReducer from './reducers';

// export history outside of components to be able dispatch navigation actions from anywhere!
export const history = createBrowserHistory();

// Build the middleware for intercepting and dispatching navigation actions
let routerMiddleware = createRouterMiddleware(history);

// Build the middleware to run our Saga
let sagaMiddleware = createSagaMiddleware();

// create store outside of root to be able dispatch actions from anywhere!
export const store = createStore(
    rootReducer(history),
    compose(applyMiddleware(routerMiddleware, sagaMiddleware))
);

// initialize saga
sagaMiddleware.run(rootSaga);

// Export
export default store;

/**
 * provide functionality to chane history outside of component
 *
 *
 * @public
 */
export function historyPush(path) {
    store.dispatch(push(path));
}
