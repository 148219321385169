
// outsource dependencies
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { history } from '../../../store';
import { NEW_ID, QUANT_METRIC_CONSTANT } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import getHintsList, {EXPOSURE_METRICS_EDIT_HINTS} from '../../../services/hints.service';
import separateWithCommaService from '../../../services/separate-with-comma.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let { riskModel } = yield select( state => state.app );
        let quantMetricData = yield call(getData, id, riskModel.id);
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        let variableTypes = yield call(getVariableTypes);
        // NOTE get list of risk metrics
        let options = yield select( state => state.quantMetrics.list );
        let riskMetrics = yield call(getRiskMetricsList, id, riskModel.id, options);
        let constants = yield call(getConstantsList, riskModel.id, options);
        //NOTE separate with commas
        yield call(separateWithCommaService.separateReal, quantMetricData);
        yield put({type: EDIT.DATA, data: quantMetricData});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        yield put({type: EDIT.META, variableTypes, riskMetrics: riskMetrics.data, constants: constants.data, initialized: true, ...params});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}


function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    // NOTE convert to numeric format
    yield call(separateWithCommaService.convertReal, options);
    try {
        let { riskModel } = yield select( state => state.app );
        // NOTE setup ordinal for formula items
        options.metricFormulaItems.forEach((i, index) => i.ordinal = index);
        let data = yield call(updateData, riskModel.id, options);
        // NOTE sort formula items by ordinal
        data.metricFormulaItems && data.metricFormulaItems.sort((a, b) => a.ordinal - b.ordinal);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}


function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, EXPOSURE_METRICS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

function* cancelSaga () {
    let { back } = yield select(state => state.quantMetrics.edit);
    // NOTE go back
    yield call(history.push, back);
}


/**
 * get quantification metric by id
 * @param {Number|String} id
 * @param {String|Number} riskModelId
 * @private
 */
function getData ( id, riskModelId ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/risk-models/${riskModelId}/quant-metrics/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * get list of quantification metrics
 * @param {String|Number} id
 * @param {String|Number} riskModelId
 * @param {Object} options
 * @private
 */
function getRiskMetricsList(id, riskModelId, {sortF, sortD, page, size, filter}) {
    return new Promise( ( resolve, reject ) => {
        let idValue = (parseInt(id, 10) > 0 ? id : 0);
        instanceAPI({
            method: 'post',
            url: `/risk-models/${riskModelId}/quant-metrics/filter`,
            data: { filter: { name: filter, excludeIds: [idValue] }, page, size, sort: { field: sortF, order: sortD ? 'ASC' : 'DESC' } }
        }).then( ({items, pages}) => {
            // NOTE sort metric formula items by ordinal
            (items||[]).forEach( item => (item.metricFormulaItems||[]).sort((a, b) => a.ordinal - b.ordinal) );
            resolve({data: items, totalPages: pages});
        }).catch(reject)
    });
}

/**
 * get list of constants
 * @param {Object} options
 * @param {Object} riskModelId
 * @private
 */
function getConstantsList(riskModelId, {sortF, sortD, page, size, filter}) {
    return new Promise( ( resolve, reject ) => {
        instanceAPI({
            method: 'post',
            url: `/risk-models/${riskModelId}/constants/filter`,
            data: {
                page,
                size,
                sort: { field: sortF, order: sortD ? 'ASC' : 'DESC' },
                filter: { name: filter },
            }
        }).then( ({items, pages}) => resolve({data: items, totalPages: pages}) ).catch(reject)
    });
}

/**
 * update quantification metric
 * @param {String|Number} riskModelId
 * @param {Object} data
 * @private
 */
function updateData ( riskModelId, data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: `/risk-models/${riskModelId}/quant-metrics`, data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: `/risk-models/${riskModelId}/quant-metrics`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * get variable types
 * @private
 */
function getVariableTypes () {
    return instanceAPI({method: 'post', url: '/variable-types/filter', data: {filter: {name: QUANT_METRIC_CONSTANT, excludeIds: [16,17]}} })
        .then(({items}) => items);
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}

