// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {Field, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {formatCompliance} from '../../../services/data-formatting.service';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {GDPR_ORGANIZATION_COMPLIANCE_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

// config
export const FORM_NAME = 'editGdprCompliance';

const Edit = (props) => {
    let params = useParams();
    useEffect(() => {
        props.initialize(params);
        return () => {
            props.clear();
        };
    }, []);
    let isNew = params.id === NEW_ID;
    let {expectAnswer, message, clearError, hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={GDPR_ORGANIZATION_COMPLIANCE_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase offset-bottom-6">
                                <span>
                                    <RichHintTitle
                                        update={EDIT}
                                        name={`GDPR$EDIT_COMPLIANCE`}
                                        expectAnswer={expectAnswer}
                                        data={findHint(hints, `GDPR_ORGANIZATION_COMPLIANCE_EDIT_TITLE`)}/>
                                </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON"/>
                            </h2>
                            <ErrorMessage active message={message} onChange={clearError}/>
                            <ConnectedForm isNew={isNew}/>
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
};

export default connect(
    state => ({
        message: state.gdprCompliance.edit.errorMessage,
        expectAnswer: state.gdprCompliance.edit.expectAnswer,
        hints: state.gdprCompliance.edit.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: params => dispatch({type: EDIT.INITIALIZE, ...params}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.gdprCompliance.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        formValues: getFormValues(FORM_NAME)(state),
        initialValues: state.gdprCompliance.edit.data,
        disabled: state.gdprCompliance.edit.expectAnswer,
        hints: state.gdprCompliance.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({form: FORM_NAME, enableReinitialize: true})(
    ({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formValues = {}, hints}) => (
        <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
            <Row><Col xs={12}>
                <h4>
                    <strong>{translate('GDPR$CHAPTER_COLON')}</strong> {get(formValues, 'chapter.chapterNumber')} - {get(formValues, 'chapter.name')}
                </h4>
            </Col></Row>
            <Row><Col xs={12}>
                <h4>
                    <strong>{translate('GDPR$ARTICLE_COLON')}</strong> {get(formValues, 'article.articleNumber')} - {get(formValues, 'article.name')}
                </h4>
            </Col></Row>
            <Row><Col xs={12}>
                <h4><strong>{translate('GDPR$PARAGRAPH_COLON')}</strong> {get(formValues, 'paragraph.name', '-')} </h4>
            </Col></Row>
            <Row><Col xs={12} className="offset-bottom-4">
                <h4>
                    <strong>{translate('GDPR$COMPLIANCE_COLON')} </strong> {formatCompliance(formValues.compliance) || '-'}
                </h4>
            </Col></Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <SelectEntities
                        isMulti
                        isClearable
                        name="tasks"
                        disabled={disabled}
                        type={ENTITY_TYPES.TASKS}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('TASKS$TITLE')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-8">
                <Col xs={12}>
                    <Field
                        multiline
                        name="comments"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('GLOBALS$COMMENTS')}
                        label={(<strong> {translate('GLOBALS$COMMENTS')} </strong>)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                               hint={findHint(hints, `BUTTON_GDPR_ORGANIZATION_COMPLIANCE_SAVE`)} />
                    <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"
                              hint={findHint(hints, `BUTTON_GDPR_ORGANIZATION_COMPLIANCE_RESET`)} />
                    <CancelBtn onClick={cancel} hint={findHint(hints, `BUTTON_GDPR_ORGANIZATION_COMPLIANCE_CANCEL`)} />
                </Col>
            </Row>
        </form>
    )
)));
