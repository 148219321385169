// outsource dependencies
import React, {Component, Fragment} from 'react';
import {TableCell, IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material'
import {OverlayTrigger, Popover} from 'react-bootstrap';


// local dependencies
import {filters} from "./filter";
import ABBREV_DIALOG from "../components/abbrev-dialog/actions";
import {connect} from "react-redux";


/**
 * Abbreviated data in a table cell
 *
 * @param {Object} props
 * @public
 */
class AbbrevTableCell extends Component {
    render() {
        let {content = '', title, length = 50, minWidth = 200, hintFontSize = '15px'} = this.props;
        let data = {content, title, hintFontSize};
        let abbrev = '';
        if(content){
            // abbrev = content.length > length ? <InfoOutlined  onClick={() => initialize(data)} className="abbrev">( ... )</InfoOutlined> : '';
            abbrev = content.length > length ?
                <RichHint child={<IconButton type="submit" style={{padding: '3px'}} > <InfoOutlined fontSize="small"/> </IconButton>}  {...data}/>
                :
                '';
        }
        return (
            <TableCell style={{minWidth}}>{filters.truncate(content || '', {length, end: '...'})}{abbrev}</TableCell>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        initialize: data => dispatch({type: ABBREV_DIALOG.INITIALIZE, data}),
    })
)(AbbrevTableCell);

const RichHint = ({content, title, child, hintFontSize}) => {
    // let ref = React.useRef(null);

    const popover = (
        <Popover id="rich-hint" style={{fontSize: hintFontSize}}>
            <p>{content}</p>
        </Popover>
    );

    return (
        <Fragment>
            <OverlayTrigger
                trigger={['hover','focus']}
                overlay={popover}
            >
                <span>
                    {child}
                </span>

            </OverlayTrigger>
        </Fragment>
    );
};