
// outsource dependencies
import React, { Component } from 'react';

// local dependencies

// configuration

/**
 * @description Preloader hide content and show spinner if we not ready to show.
 * @constructor Preloader
 * @public
 */
class Preloader extends Component {
    render () {
        
        let { type, expectAnswer, children } = this.props;
        
        switch ( type ) {
            // this preloader used like default preloader in public index html
            default: return expectAnswer ? (<div className="def-preloader">
                <div id="SPW">
                	<div id="SP_1" className="sp"> </div> <div id="SP_2" className="sp"> </div> <div id="SP_3" className="sp"> </div> <div id="SP_4" className="sp"> </div>
                    <div id="SP_5" className="sp"> </div> <div id="SP_6" className="sp"> </div> <div id="SP_7" className="sp"> </div> <div id="SP_8" className="sp"> </div>
                </div>
            </div>) : ( children||'' );

            case 'BOX': return expectAnswer ? (<div className="box-preloader">
                <div id="SPW">
                    <div id="SP_1" className="sp"> </div> <div id="SP_2" className="sp"> </div> <div id="SP_3" className="sp"> </div> <div id="SP_4" className="sp"> </div>
                    <div id="SP_5" className="sp"> </div> <div id="SP_6" className="sp"> </div> <div id="SP_7" className="sp"> </div> <div id="SP_8" className="sp"> </div>
                </div>
            </div>) : ( children||'' );

            case 'MIN_HEIGHT': return expectAnswer ? (<div className="box-preloader">
                <div id="SPW" style={{minHeight: `${(this.props.height*0.6)||90}px`, marginTop: `${(this.props.height*0.4)||60}px`}}>
                    <div id="SP_1" className="sp"> </div> <div id="SP_2" className="sp"> </div> <div id="SP_3" className="sp"> </div> <div id="SP_4" className="sp"> </div>
                    <div id="SP_5" className="sp"> </div> <div id="SP_6" className="sp"> </div> <div id="SP_7" className="sp"> </div> <div id="SP_8" className="sp"> </div>
                </div>
            </div>) : ( children||'' );

            case 'ICON': return expectAnswer ? (<strong>
                <i className="fa fa-spinner fa-spin fa-fw"> </i>
                <span className="sr-only"> Loading... </span>
            </strong>) : ( children||'' );
                
        }
    }
}

export default Preloader;
