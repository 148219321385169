// outsource dependencies
import get from 'lodash/get';
import Dropzone from 'react-dropzone';
import {connect} from 'react-redux';
import {Col, Container, Row} from 'react-bootstrap';
import React, {Component, Fragment} from 'react';
import {change, Field, reduxForm} from 'redux-form';
import {InputLabel, Paper} from '@mui/material';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {instanceAPI} from '../../../services/api.service';
import MdDatePicker from '../../../components/md-date-picker';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {MdAsyncSelect, MdSelect} from '../../../components/md-select';
import {formatFileSize} from '../../../services/data-formatting.service';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate} from '../../../services/translate.service';
import {GDPR_SYSTEM_COMPLIANCE_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

// config
export const FORM_NAME = 'editGdprSystemCompliance';
export const changeField = (field, value) => change(FORM_NAME, field, value);

class Edit extends Component {
    componentDidMount() { this.props.initialize( this.props.match.params ); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { expectAnswer, match } = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ GDPR_SYSTEM_COMPLIANCE_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span:8,offset:2}}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    { isNew ? <span>{translate('GDPR$CREATE_COMPLIANCE')}</span> : <span>{translate('GDPR$EDIT_COMPLIANCE')}</span> }
                                    <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                                </h2>
                                <ConnectedError />
                                <ConnectedForm isNew={isNew} />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.gdprSystemCompliance.edit.expectAnswer}),
    dispatch => ({
        initialize: params => dispatch({type: EDIT.INITIALIZE, ...params}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.gdprSystemCompliance.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.gdprSystemCompliance.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        sections: state.gdprSystemCompliance.edit.sections,
        articles: state.gdprSystemCompliance.edit.articles,
        initialValues: state.gdprSystemCompliance.edit.data,
        disabled: state.gdprSystemCompliance.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        changeArticle: () => dispatch(change(FORM_NAME, 'paragraph', null)),
        changeChapter: chapter => dispatch({type: EDIT.CHANGE_CHAPTER, chapter}),
        changeSection: section => dispatch({type: EDIT.CHANGE_SECTION, section}),
    })
)(reduxForm({
    form: 'editGdprSystemCompliance',
    enableReinitialize: true,
})( connect(state => ({values: state.form.editGdprSystemCompliance.values}), null)(
    ({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, values, sections, articles, changeChapter, changeSection, changeArticle}) => (
        <form autoComplete="off" name="editGdprSystemCompliance" onSubmit={handleSubmit(update)}>
            {!isNew ? (<Fragment>
                <Row><Col xs={12}>
                    <h4> <strong>Chapter:</strong> {get(values, 'chapter.chapterNumber')} - {get(values, 'chapter.name')} </h4>
                </Col></Row>
                <Row><Col xs={12}>
                    <h4> <strong>Section:</strong> {get(values, 'section.sectionNumber')} - {get(values, 'section.name')} </h4>
                </Col></Row>
                <Row><Col xs={12}>
                    <h4> <strong>Article:</strong> {get(values, 'article.articleNumber')} - {get(values, 'article.name')}  </h4>
                </Col></Row>
                {get(values, 'article.question') && !get(values, 'paragraph.question') ? (<Row><Col xs={12}> <h4> <strong>Question:</strong> {get(values, 'article.question')} </h4></Col></Row>) : ''}
                <Row><Col xs={12}>
                    <h4> <strong>Paragraph:</strong> {get(values, 'paragraph.name', '-')} </h4>
                </Col></Row>
                {get(values, 'paragraph.question') ? (<Row className="offset-bottom-4"><Col xs={12}> <h4> <strong>Question:</strong> {get(values, 'paragraph.question')} </h4></Col></Row>) : ''}
            </Fragment>): (<Fragment>
                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <SelectEntities
                            name="chapter"
                            disabled={disabled}
                            placeholder={translate("DROPDOWN$TYPE_TO_SEARCH")}
                            onChange={changeChapter}
                            type={ENTITY_TYPES.GDPR_CHAPTERS}
                            label={(<strong>Chapter </strong>)}
                            getOptionLabel={option => `${get(option, 'chapterNumber')} - ${get(option, 'name')}`}
                                />
                    </Col>
                </Row>
                {!!sections.length && (
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <Field
                                name="section"
                                disabled={disabled}
                                placeholder="Section"
                                defaultOptions={sections}
                                component={MdAsyncSelect}
                                onChange={changeSection}
                                label={(<strong>Section </strong>)}
                                getOptionLabel={option => `${get(option, 'sectionNumber')} - ${get(option, 'name')}`}
                                loadOptions={(name, done) => {
                                    instanceAPI({
                                        method: 'post',
                                        url: 'gdpr/section/filter',
                                        data: { page: 0, size: 6, filter: { name, chapterId: get(values, 'chapter.id', null) } },
                                    }).then(({items})=>done(items)).catch(done.bind(null, []));
                                }}/>
                        </Col>
                    </Row>
                )}
                <Row className="offset-bottom-4">
                    <Col xs={12}>
                        <Field
                            name="article"
                            placeholder="Article"
                            onChange={changeArticle}
                            defaultOptions={articles}
                            component={MdAsyncSelect}
                            getOptionLabel={option => `${get(option, 'articleNumber')} - ${get(option, 'name')}`}
                            disabled={!get(values, 'chapter') || (sections.length && !get(values, 'section')) || disabled}
                            label={(<strong>Article </strong>)}
                            loadOptions={(name, done) => {
                                instanceAPI({
                                    method: 'post',
                                    url: 'gdpr/articles/filter',
                                    data: { page: 0, size: 6, filter: {
                                        name,
                                        isSystemLevel: true,
                                        chapterId: get(values, 'chapter.id', null),
                                        sectionId: get(values, 'section.id', null)
                                    }},
                                }).then(({items})=>done(items)).catch(done.bind(null, []));
                            }}/>
                    </Col>
                </Row>

                {get(values, 'article.question') && !get(values, 'paragraph.question') ? ( <Row className="offset-bottom-4"><Col xs={12}> <h4> <strong>Question:</strong> {get(values, 'article.question')} </h4> </Col></Row>) : ''}

                {!!get(values, 'article.paragraphs.length', 0) && (
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <Field
                                name="paragraph"
                                component={MdSelect}
                                placeholder="Paragraph"
                                label={(<strong> Paragraph </strong>)}
                                options={get(values, 'article.paragraphs', [])}
                                disabled={!get(values, 'article', true) || disabled}
                                    />

                        </Col>
                    </Row>
                )}
                {get(values, 'paragraph.question') ? (<Row className="offset-bottom-4"><Col xs={12}> <h4> <strong>Question:</strong> {get(values, 'paragraph.question')} </h4> </Col></Row>) : ''}
            </Fragment>)}
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="number"
                        name="compliance"
                        component={MdInput}
                        disabled={disabled}
                        placeholder="Compliance"
                        label={(<strong> Compliance </strong>)}
                            />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="date"
                        name="dueDate"
                        disabled={disabled}
                        component={MdDatePicker}
                        label={(<strong> Due date </strong>)}
                            />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <SelectEntities
                        name="owner"
                        disabled={disabled}
                        placeholder={translate("DROPDOWN$TYPE_TO_SEARCH")}
                        type={ENTITY_TYPES.USERS}
                        label={(<strong> Owner </strong>)}
                        getOptionLabel={option => get(option, 'fullName')}
                            />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        name="documentLink"
                        component={MdInput}
                        disabled={disabled}
                        placeholder="Document Link Name"
                        label={(<strong> Document Link Name </strong>)}
                            />
                </Col>
            </Row>
            <Row className="offset-bottom-8">
                <Col xs={12}>
                    <Field
                        multiline
                        name="comments"
                        component={MdInput}
                        disabled={disabled}
                        placeholder="Comments"
                        label={(<strong> Comments </strong>)}
                            />
                </Col>
            </Row>
            <Row className="offset-bottom-8"> <Col xs={12}>
                <Field name="document" component={UploadFile} />
            </Col> </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" />
                    <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" />
                    <CancelBtn onClick={cancel} />
                </Col>
            </Row>
        </form>
    )
)));

const UploadFile = connect(
    state => ({
        uploaded: state.gdprSystemCompliance.edit.uploaded,
        disabled: state.gdprSystemCompliance.edit.expectAnswer
    }),
    dispatch => ({ uploadFile: file => dispatch({type: EDIT.UPLOAD_FILE, file}) })
)( ({input, meta, uploadFile, uploaded, disabled, ...attr}) => {
    return (<div>
        <InputLabel htmlFor={input.name}> <strong> Document Upload </strong> </InputLabel>
        <Dropzone
            {...attr}
            {...input}
            id={input.name}
            className="dropzone"
            disabled={!uploaded || disabled}
            activeClassName="dropzone-active"
            disabledClassName="dropzone-disabled"
            onDrop={(acceptedFiles, rejectedFiles)=> {!rejectedFiles.length&&uploadFile(acceptedFiles[0])}}
                >
            {()=>{
                if (!uploaded) { return (<h3 className="text-center text-muted">Loading...</h3>); }
                if (input.value) {
                    return (<div className="text-center">
                        <h3> Uploaded <strong>{get(input,'value.fileName')}</strong> </h3>
                        <h4>(size: {formatFileSize(get(input,'value.fileSize'))})</h4>
                    </div>);
                }
                return (<div className="text-center text-muted">
                    <p style={{fontSize: '50px'}}> <i className="fa fa-upload" /> </p>
                    <h3 className="offset-top-0">Click here or drop a file to upload!</h3>
                </div>)
            }}
        </Dropzone>
    </div>)
});
