// outsource dependencies
import queryService from "./query.service";
import {history} from "../store";
import storageService from "./storage.service";

// local dependencies

/**
 * Auth service provide common Auth Helpers
 */
export class Auth {

    /**
     * Storing token applied from the URL
     *
     * @returns {boolean}
     */
    static storeToken() {
        let queryParams = queryService.parse(history.location.search);

        if (queryParams && queryParams.token && queryParams.granter) {
            storageService.set('token', queryParams.token);
            storageService.set('granter', queryParams.granter);
            console.log('## Set GRANTER to OKTA');
        }

        return true;
    }

    /**
     * Check is OKTA authorization allowed
     *
     * @returns {boolean}
     */
    static isOkta() {
        let granter = storageService.get('granter');
        if (granter && granter === 'okta') {
            return true;
        }

        return false;
    }

    /**
     * Get token from the local storage
     *
     * @returns {*}
     */
    static getToken() {
        let token = storageService.get('token');

        return token;
    }

    /**
     * Clear token from the storage
     */
    static clearToken() {
        storageService.remove('granter');
        storageService.remove('token');
    }

    /**
     * Set IdP info data
     */
    static setIdPInfo(idpInfo) {
        Auth.idpInfo = idpInfo;
    }

    /**
     * Set IdP info data
     */
    static getIdPInfo() {
        let result = Auth.idpInfo;
        if (!result) {
            result = {};
        }

        return result;
    }

}

export default Auth;
