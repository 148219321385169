// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {DATA_CLASSES} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {findHint, RichHintTitle} from '../../../components/hints/hints';
import AbbrevTableCell from '../../../components/abbrev-table-cell';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {DATA_CLASSES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, list, hints} = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={DATA_CLASSES_MAP}/>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4 top-indent-4">
                        <Col xs={9}>
                            <RichHintTitle update={LIST} name={`DATA_CLASSES$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, 'DATA_CLASSES_TITLE')}/>
                        </Col>
                        <Col xs={3} className="text-right">
                            <Link Btn={AddBtn} placement="left" to={DATA_CLASSES.LINK_EDIT()} permission={PERMISSION.DATA_CLASS.CREATE} hint={findHint(hints, 'BUTTON_DATA_CLASSES_ADD')} />
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected/>
                        </Col>
                    </Row>
                    <ConnectedError/>
                    {list.length ? (
                        <Paper> <ConnectedTable/> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.dataClasses.list.expectAnswer, list: state.dataClasses.list.data, hints: state.dataClasses.list.hintsData }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.dataClasses.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter: state.dataClasses.list.filter,
        disabled: state.dataClasses.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DESCRIPTION")}
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
    />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.dataClasses.list, hints: state.dataClasses.list.hintsData }),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({data, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort, hints}) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('name')}
                        >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'description'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('description')}
                        >
                            {translate('GLOBALS$DESCRIPTION')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                       {translate('GLOBALS$REGULATIONS')}
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell className="text-nowrap">{item.name}</TableCell>
                    <AbbrevTableCell content={item.description} title={item.name} length={50} />
                    <AbbrevTableCell content={Array.isArray(item.regulations) && item.regulations.map(elem => elem.name).join(',')} title={item.name} length={20} minWidth={200} />
                    <TableCell align="right" className="text-nowrap">
                        {item.readOnly ? null : (<div>
                            <Link
                                Btn={EditBtn}
                                permission={PERMISSION.DATA_CLASS.UPDATE}
                                hint={findHint(hints,'BUTTON_DATA_CLASSES_EDIT')}
                                to={DATA_CLASSES.LINK_EDIT({
                                    id: item.id,
                                    query: {back: history.location.pathname + history.location.search}
                                })}
                            />
                            <DelBtn
                                permission={PERMISSION.DATA_CLASS.DELETE}
                                hint={findHint(hints, 'BUTTON_DATA_CLASSES_DELETE')}
                                onClick={() => deleteItem(item.id)}
                                className="offset-left-2"
                            />
                        </div>)}
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>)));

const ConnectedError = connect(
    state => ({message: state.dataClasses.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: LIST.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
