// default theme for material design
import { createTheme } from "@mui/material/styles";

export const ROOT = createTheme({
    palette: {
        primary: {
            main: '#213c60'
        },
        secondary: {
            main: '#FDBB30'
        },
        error: {
            main: '#d50000'
        },
    },
    typography: {
        // to resolve warnings of migration to typography v2
        useNextVariants: true,
        fontSize: 16,
        htmlFontSize: 10,
        button: {
            fontSize: 12
        },
    }
});

export const ALT = createTheme({
    ...ROOT,
    palette: {
        secondary: { main: '#d50000' },
        primary: { main: '#009703' },
        error: { main: '#d50000' },
    },
});
