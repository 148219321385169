// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID, PERMISSION} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {SUBSIDIARIES_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import separateService from '../../../services/separate-with-comma.service';
import {useParams} from 'react-router-dom';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={SUBSIDIARIES_MAP}/>
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span: 8, offset: 2}}>
                        <Paper className="indent-5">
                            <RichHintTitle
                                update={EDIT}
                                name={isNew ? translate('SUBSIDIARY_ORGANIZATIONS$CREATE_SUBSIDIARY') : translate('SUBSIDIARY_ORGANIZATIONS$EDIT_SUBSIDIARY')}
                                expectAnswer={expectAnswer}
                                data={findHint(hints, 'SUBSIDIARY_ORGANIZATIONS_TITLE')}
                            />
                            <Preloader expectAnswer={expectAnswer} type="ICON" />
                            <ConnectedError />
                            <ConnectedForm isNew={isNew} />
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({expectAnswer: state.subsidiaries.edit.expectAnswer, hints: state.subsidiaries.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.subsidiaries.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.subsidiaries.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        user: state.app.user,
        initialValues: state.subsidiaries.edit.data,
        disabled: state.subsidiaries.edit.expectAnswer,
        currentId: state.subsidiaries.edit.data.id || null,
        hints: state.subsidiaries.edit.hintsData,
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editSubsidiary',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // description
        if (!values.description) {
            errors.description = 'GLOBALS$DESCRIPTION_REQUIRED';
        }
        // parent
        if (!values.parent) {
            errors.parent = 'SUBSIDIARY_ORGANIZATIONS$PARENT_REQUIRED';
        }
        // industry
        if (!values.industry) {
            errors.industry = 'VENDORS$INDUSTRY_REQUIRED';
        }
        // averageRevenue
        if (!values.averageRevenue) {
            errors.averageRevenue = 'SUBSIDIARY_ORGANIZATIONS$ANNUAL_REVENUE_REQUIRED';
        }
        // site
        if (!values.site) {
            errors.site = 'ORGANIZATION$SITE_REQUIRED';
        }
        // currency
        if (!values.currency) {
            errors.currency = 'ORGANIZATION$CURRENCY_REQUIRED';
        }
        // language
        if (!values.language) {
            errors.language = 'ORGANIZATION$LANGUAGE_REQUIRED';
        }
        // streetAddress1
        if (!values.streetAddress1) {
            errors.streetAddress1 = 'ORGANIZATION$ADDRESS_1_REQUIRED';
        }
        // status
        if (!values.status) {
            errors.status = 'GLOBALS$STATUS_REQUIRED';
        }
        // zip
        if (!values.zip) {
            errors.zip = 'ORGANIZATION$ZIP_REQUIRED';
        }
        // country
        if (!values.country) {
            errors.country = 'ORGANIZATION$COUNTRY_REQUIRED';
        }
        // state
        if (!values.state) {
            errors.state = 'ORGANIZATION$STATE_REQUIRED';
        }
        // city
        if (!values.city) {
            errors.city = 'ORGANIZATION$CITY_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, currentId, hints, cancel, user}) => {
    let isAllowed = (user.permissions || []).includes(PERMISSION.ORGANIZATION.UPDATE);
    return (<form autoComplete="off" name="editSubsidiary" onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        name="name"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('SUBSIDIARY_ORGANIZATIONS$ORGANIZATION_NAME')}
                        label={(<strong
                            className="required-asterisk"> {translate('SUBSIDIARY_ORGANIZATIONS$ORGANIZATION_NAME')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        name="description"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('SUBSIDIARY_ORGANIZATIONS$ORGANIZATION_DESCRIPTION')}
                        label={(<strong
                            className="required-asterisk"> {translate('SUBSIDIARY_ORGANIZATIONS$ORGANIZATION_DESCRIPTION')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12} md={6}>
                    <SelectEntities
                        name="parent"
                        disabled={disabled}
                        type={ENTITY_TYPES.SUBSIDIARIES}
                        placeholder={translate('SUBSIDIARY_ORGANIZATIONS$PARENT')}
                        label={(<strong
                            className="required-asterisk"> {translate('SUBSIDIARY_ORGANIZATIONS$PARENT')} </strong>)}
                        selected={!currentId ? [] : [currentId]}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <SelectEntities
                        name="industry"
                        disabled={disabled}
                        type={ENTITY_TYPES.INDUSTRIES}
                        placeholder={translate('VENDORS$INDUSTRY')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('VENDORS$INDUSTRY')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <SelectEntities
                        isMulti
                        disabled={disabled}
                        name="technologies"
                        type={ENTITY_TYPES.TECHNOLOGIES}
                        placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                        label={(<strong> {translate('TECHNOLOGIES$TITLE')} </strong>)}
                        getOptionLabel={option => option.name + (option.version ? `(${option.version})` : '')}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12} md={6}>
                    <Field
                        type="text"
                        component={MdInput}
                        name="averageRevenue"
                        style={{color: 'black'}}
                        disabled={disabled || !isAllowed}
                        label={(<strong
                            className="required-asterisk"> {translate('SUBSIDIARY_ORGANIZATIONS$ANNUAL_REVENUE')} </strong>)}
                        normalize={separateService.normalize}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <Field
                        type="text"
                        component={MdInput}
                        name="contactLinks"
                        disabled={disabled}
                        label={(<strong> {translate('POLICY_MANAGEMENT$CONTACT_LINKS')} </strong>)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        name="site"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('ORGANIZATION$SITE')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$SITE')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="tel"
                        name="phone"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('ORGANIZATION$PHONE_NUMBER')}
                        label={(<strong> {translate('ORGANIZATION$PHONE_NUMBER')} </strong>)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <SelectEntities
                        valueKey="id"
                        name="currency"
                        disabled={disabled}
                        type={ENTITY_TYPES.CURRENCIES}
                        placeholder={translate('ORGANIZATION$CURRENCY')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$CURRENCY')} </strong>)}
                        getOptionLabel={option => `${get(option, 'name')} (${get(option, 'code')})`}
                    />
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <SelectEntities
                        name="language"
                        valueKey="id"
                        labelKey="name"
                        disabled={disabled}
                        type={ENTITY_TYPES.LANGUAGES}
                        placeholder={translate('ORGANIZATION$LANGUAGE')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$LANGUAGE')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <Field
                        name="taxId"
                        disabled={disabled}
                        component={MdInput}
                        placeholder={translate('ORGANIZATION$TAX_ID')}
                        label={(<strong> {translate('ORGANIZATION$TAX_ID')} </strong>)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        name="streetAddress1"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('ORGANIZATION$ADDRESS_1')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$ADDRESS_1')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        name="streetAddress2"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('ORGANIZATION$ADDRESS_2')}
                        label={(<strong> {translate('ORGANIZATION$ADDRESS_2')} </strong>)}
                    />
                </Col>
            </Row>
            <PositionConnected/>
            <Row>
                <Col xs={12} lg={4} className="offset-bottom-4">
                    <Field
                        name="zip"
                        component={MdInput}
                        disabled={disabled}
                        placeholder={translate('ORGANIZATION$ZIP')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('ORGANIZATION$ZIP')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                    <Field
                        name="vatId"
                        disabled={disabled}
                        component={MdInput}
                        placeholder={translate('ORGANIZATION$VAT')}
                        label={(<strong> {translate('ORGANIZATION$VAT')} </strong>)}
                    />
                </Col>
                <Col xs={12} lg={4} className="offset-bottom-4">
                    <SelectEntities
                        name="status"
                        valueKey="id"
                        labelKey="name"
                        disabled={disabled}
                        type={ENTITY_TYPES.STATUSES}
                        placeholder={translate('GLOBALS$STATUS')}
                        required={true}
                        label={(<strong className="required-asterisk"> {translate('GLOBALS$STATUS')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-6">
                <Col xs={12}>
                    <Field
                        multiline
                        name="notes"
                        component={MdInput}
                        disabled={disabled}
                        label={(<strong> {translate('GLOBALS$NOTES')} </strong>)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2"
                               hint={findHint(hints, isNew ? 'BUTTON_SUBSIDIARY_ORGANIZATIONS_CREATE' : 'BUTTON_SUBSIDIARY_ORGANIZATIONS_SAVE')}/>
                    <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2"
                              hint={findHint(hints, 'BUTTON_SUBSIDIARY_ORGANIZATIONS_RESET')}/>
                    <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_SUBSIDIARY_ORGANIZATIONS_CANCEL')}/>
                </Col>
            </Row>
        </form>
    );
})));

const PositionConnected = withTranslation(connect(
    state => ({
        cities: state.subsidiaries.edit.cities,
        states: state.subsidiaries.edit.states,
        values: state.form.editSubsidiary.values,
        disabled: state.subsidiaries.edit.expectAnswer,
    }),
    dispatch => ({
        setupCountry: countryId => dispatch({type: EDIT.SETUP_COUNTRY, countryId}),
        setupState: (countryId, stateId) => dispatch({type: EDIT.SETUP_STATE, countryId, stateId}),
    })
)(({disabled, values, states, cities, setupCountry, setupState}) => (
    <Row>
        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
            <SelectEntities
                name="country"
                disabled={disabled}
                type={ENTITY_TYPES.COUNTRIES}
                placeholder={translate('ORGANIZATION$COUNTRY')}
                required={true}
                label={(<strong className="required-asterisk"> {translate('ORGANIZATION$COUNTRY')} </strong>)}
                onChange={(e) => setupCountry(get(e, 'id', null))}
            />
        </Col>
        <Col xs={12} md={6} lg={4} className="offset-bottom-4">
            <SelectEntities
                name="state"
                defaultOptions={states}
                type={ENTITY_TYPES.STATES}
                placeholder={translate('ORGANIZATION$STATE')}
                disabled={disabled || !get(values, 'country.id', null)}
                required={true}
                label={(<strong className="required-asterisk"> {translate('ORGANIZATION$STATE')} </strong>)}
                additionalFilters={{countryId: get(values, 'country.id', null)}}
                onChange={(e) => setupState(get(values, 'country.id', null), get(e, 'id', null))}
            />
        </Col>
        <Col xs={12} lg={4} className="offset-bottom-4">
            <SelectEntities
                name="city"
                defaultOptions={cities}
                type={ENTITY_TYPES.CITIES}
                placeholder={translate('ORGANIZATION$CITY')}
                required={true}
                label={(<strong className="required-asterisk"> {translate('ORGANIZATION$CITY')}  </strong>)}
                additionalFilters={{stateId: get(values, 'state.id', null), countryId: get(values, 'country.id', null)}}
                disabled={disabled || !get(values, 'country.id', null) || (Boolean(states.length) && !get(values, 'state.id', null))}
            />
        </Col>
    </Row>
)));
