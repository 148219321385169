
// outsource dependencies

// local dependencies
import ABBREV_DIALOG from './actions';

let initial = {
    // meta
    showModal: false,
    content: '',
    title: '',
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
};

export default function ( state = initial, action ) {
    // eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case ABBREV_DIALOG.CLEAR:
            state = initial;
            break;
        case ABBREV_DIALOG.DATA:
            state = { ...state, data: options.data };
            break;
        case ABBREV_DIALOG.META:
            state = { ...state, ...options, data: state.data };
            break;
    }

    return state;
}
