// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {CloudDownload} from '@mui/icons-material';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import {EditBtn, Link} from '../../../components/md-button';
import MdTablePagination from '../../../components/pagination';
import {GDPR_EVIDENCE_DOCUMENTS} from '../../../constants/routes';
import {formatFileSize} from '../../../services/data-formatting.service';
import {translate, withTranslation} from '../../../services/translate.service';
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {GDPR_EVIDENCE_DOCUMENTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { disabled, data, message, clearError, hints } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ GDPR_EVIDENCE_DOCUMENTS_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <RichHintTitle update={LIST}  name={`GDPR$EVIDENCE_DOCUMENTS`} expectAnswer={disabled} data={findHint(hints, `GDPR_EVIDENCE_DOCUMENTS_TITLE`)}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}> <SearchFilterConnected /> </Col> </Row>
                    <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col> </Row>
                    { data.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        data: state.gdprEvidenceDocuments.list.data,
        hints: state.gdprEvidenceDocuments.list.hintsData,
        message: state.gdprEvidenceDocuments.list.errorMessage,
        disabled: state.gdprEvidenceDocuments.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.gdprEvidenceDocuments.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.gdprEvidenceDocuments.list.filter,
        disabled: state.gdprEvidenceDocuments.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_DOCUMENT_TYPE")}
    />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.gdprEvidenceDocuments.list, hints: state.gdprEvidenceDocuments.list.hintsData}),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('name')}
                                >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'documentType'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('documentType')}
                                >
                            {translate('GDPR$DOCUMENT_TYPE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('GDPR$ARTICLES')} </TableCell>
                    <TableCell className="th"> {translate('GLOBALS$TEMPLATE')} </TableCell>
                    <TableCell className="th"> {translate('GLOBALS$DOCUMENTS')} </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.documentType}</TableCell>
                    <TableCell style={{minWidth: 300}}>
                        <ul className="list-unstyled offset-0">{(item.articles || []).map((item={}, index) =>
                            <li key={index}>{get(item, 'articleNumber') + ' - ' + get(item, 'name')}</li>
                        )}</ul>
                    </TableCell>
                    <TableCell>{item.templateLink && (
                        <Tooltip title={translate('GLOBALS$DOWNLOAD_TEMPLATE')} className="offset-bottom-1"><span>
                            <IconButton
                                download
                                color="primary"
                                style={{padding: 8}}
                                href={item.templateLink}
                                aria-label={translate('GLOBALS$DOWNLOAD_TEMPLATE')}
                                    >
                                <CloudDownload />
                            </IconButton>
                         </span></Tooltip>
                    )}</TableCell>
                    <TableCell style={{minWidth: 300}}>
                        <ul className="list-unstyled offset-0">{(item.documents || []).map((item={}, index) =>
                            <li key={index}>{get(item, 'fileName')} ({ formatFileSize(get(item, 'fileSize')) })</li>
                        )}</ul>
                    </TableCell>
                    <TableCell className="text-nowrap" align="right">
                        <Link
                            Btn={EditBtn}
                            hint={findHint(hints, `BUTTON_GDPR_EVIDENCE_DOCUMENTS_EDIT`)}
                            permission={PERMISSION.GDPR_EVIDENCE_DOCUMENT.UPDATE}
                            to={GDPR_EVIDENCE_DOCUMENTS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search}})}
                                />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));
