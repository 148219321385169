
// outsource dependencies
import get from 'lodash/get';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { Paper } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';

// local dependencies
import { CATEGORIES } from '../actions';
import MdInput from '../../../components/md-input';
import { ENTITY_TYPES } from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import { translate, withTranslation } from '../../../services/translate.service';
import { SubmitBtn, ResetBtn, BackBtn, AddIconBtn, DeleteIconBtn } from '../../../components/md-button';
import {findHint} from '../../../components/hints/hints';

function Form ( props ) {
    let { invalid, pristine, expectAnswer, handleSubmit, update, reset, newItem, hints } = props;
    return (
        <form autoComplete="off" name="editCategories" onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-3">
                <Col xs={12} md={6} className="offset-bottom-3">
                    <Field
                        required
                        name="name"
                        component={MdInput}
                        disabled={expectAnswer}
                        placeholder={translate('GLOBALS$NAME')}
                        label={(<strong> {translate('GLOBALS$NAME')} </strong>)}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <SelectEntities
                        required
                        name="domain"
                        disabled={expectAnswer}
                        type={ENTITY_TYPES.DOMAINS}
                        placeholder={translate('DOMAINS$DOMAIN')}
                        getOptionValue={option => get(option, 'id')}
                        getOptionLabel={option => get(option, 'riskDomainView.name')}
                        label={(<strong> {translate('DOMAINS$DOMAIN')} </strong>)}
                    />
                </Col>
            </Row>
            <Row className="offset-bottom-3">
                <Col xs={12} className="offset-bottom-3">
                    <Field
                        name="description"
                        component={MdInput}
                        disabled={expectAnswer}
                        placeholder={translate('GLOBALS$DESCRIPTION')}
                        label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                            />
                </Col>
            </Row>
            <Row className="offset-bottom-6"> <Col xs={12}>
                <FieldArray name="riskTypes" component={Items} />
            </Col> </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={newItem} disabled={pristine||invalid||expectAnswer} className="offset-right-2" hint={findHint(hints, newItem ? 'BUTTON_CATEGORIES_CREATE' : 'BUTTON_CATEGORIES_SAVE')} />
                    <ResetBtn onClick={reset} disabled={pristine||expectAnswer} className="offset-right-2" hint={findHint(hints, 'BUTTON_CATEGORIES_RESET')} />
                    <BackBtn hint={findHint(hints, 'BUTTON_CATEGORIES_CANCEL')}/>
                </Col>
            </Row>
        </form>
    );
}

export default connect(
    state => ({
        initialValues: state.categories.edit.item,
        hints: state.categories.edit.meta.hintsData,
        newItem: state.categories.edit.meta.newItem,
        expectAnswer: state.categories.edit.meta.expectAnswer
    }),
    dispatch => ({ update: ( formData ) => dispatch({type: CATEGORIES.UPDATE_ITEM.REQUEST, ...formData}) })
)(reduxForm({
    form: 'editCategories',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } - named errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // NAME
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // DOMAIN
        if (!values.domain) {
            errors.domain = 'DOMAINS$DOMAIN_REQUIRED';
        }
        // TYPES
        if (!values.riskTypes || !values.riskTypes.length) {
            errors.riskTypes = { _error: 'CATEGORIES$RISK_TYPES_REQUIRED' }
        } else {
            const typesArrayErrors = [];
            values.riskTypes.forEach((type, i) => {
                const typeErrors = {};
                if (!type || !type.name) {
                    typeErrors.name = 'GLOBALS$NAME_REQUIRED';
                    typesArrayErrors[i] = typeErrors;
                }
            });
            if (typesArrayErrors.length) {
                errors.riskTypes = typesArrayErrors;
            }
        }
        return errors;
    }
})(Form));


/**
 * component for item of "riskTypes" list
 *
 * @param {Object} props
 * @private
 */
const Items = withTranslation( ({ fields, meta }) => {
    return (<Fragment>
        <h3 className="text-uppercase">
            <span className="align-middle">{translate('CATEGORIES$RISK_TYPES')}</span>&nbsp;
            <AddIconBtn onClick={()=>fields.push({})} />
        </h3>
        {meta.error && (<h4 className="text-center is-invalid"> <label className="form-text h4"> { translate(meta.error) } </label> </h4>)}
        <ul className="list-unstyled">
            {fields.map((type, i) => (
                <li key={i} className="offset-bottom-2">
                    <Paper className="indent-2"><Container fluid>
                        <Row className="offset-bottom-1">
                            <Col xs={12}><div className="row-adornment">
                                <Field
                                    component={MdInput}
                                    name={`${type}.name`}
                                    placeholder={translate('GLOBALS$NAME')}
                                     required={true}
                                    label={(<strong> {translate('GLOBALS$NAME')} </strong>)}
                                />
                                <div className="adornment">
                                    <DeleteIconBtn onClick={()=>fields.remove(i)} />
                                </div>
                            </div></Col>
                        </Row>
                        <Row className="offset-bottom-1">
                            <Col xs={12}>
                                <Field
                                    multiline
                                    component={MdInput}
                                    name={`${type}.description`}
                                    placeholder={translate('GLOBALS$DESCRIPTION')}
                                    label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                                        />
                            </Col>
                        </Row>
                    </Container></Paper>
                </li>
            ))}
        </ul>
    </Fragment>);
});
