
// outsource dependencies
import { change } from 'redux-form';
import { get, difference} from 'lodash';
import { toastr } from 'react-redux-toastr';
import { put, call, takeEvery, take, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from './actions';
import { APP } from '../../actions/types';
import { instanceAPI } from '../../services/api.service';
import { translate } from '../../services/translate.service';
import separateService from '../../services/separate-with-comma.service';
import getHintsList, {ORGANIZATION_HINTS} from '../../services/hints.service';

export const FORM_NAME = 'editOrganization';
export const changeField = (field, value) => change(FORM_NAME, field, value);


/**
 *
 *
 * @publicexport const FORM_NAME = 'editVendor';
 */
export default function* () {
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.SETUP_STATE, setupStateSaga);
    yield takeEvery(EDIT.SETUP_COUNTRY.REQUEST, setupCountrySaga);
    yield takeEvery(EDIT.CREATE_CITY, createCitySaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function * initializeSaga () {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData);
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        let availableLanguages = yield call(getLanguages);
        // NOTE: initialize lists of states and cities for current country
        let countryId = get(data, 'country.id', null);
        if ( countryId ) {
            let stateId = get(data, 'state.id', null);
            yield put({type: EDIT.SETUP_COUNTRY.REQUEST, countryId});
            yield take(EDIT.SETUP_COUNTRY.FINISH);
            yield put({type: EDIT.SETUP_STATE, countryId, stateId});
        }
        //NOTE separate with commas
        yield call(separateService.separate, data);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, availableLanguages});
    } catch ( {message} ) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, initialized: true});
}

function* updateDataSaga ({type, ...options}) {
    // NOTE convert to default numeric
    yield call(separateService.convert, options);
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let prevData = yield select(state => state.organization.data);
        let data = yield call(updateData, options);
        // NOTE check difference between old and new supported languages
        const unequal = Boolean(difference(prevData.supportedLanguages, options.supportedLanguages).length
            || difference(options.supportedLanguages, prevData.supportedLanguages).length);
        // NOTE update supported languages
        if (unequal) {yield put({type: APP.SETUP_LANGUAGES});}
        // NOTE separate with commas
        yield call(separateService.separate, data);
        yield put({type: EDIT.DATA, data});
        yield call(toastr.success, translate('ORGANIZATION$TITLE'), translate('GLOBALS$SUCCESSFUL_DATA_UPDATE'));
        yield put({type: EDIT.META, expectAnswer: false});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* getHintsDataSaga () {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, ORGANIZATION_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}


function* setupCountrySaga ({countryId}) {
    yield put({type: EDIT.META, expectAnswer: true, states: [], cities: []});
    try {
        // NOTE clear old field values
        yield put(change('editOrganization', 'state', null));
        yield put(change('editOrganization', 'city', null));
        // NOTE get list of states for chosen country
        let states = yield call(getStates, countryId);
        // NOTE get list of cities for chosen country
        // cause not all countries have states but can have cities
        let cities = yield call(getCities, countryId);
        yield put({type: EDIT.META, expectAnswer: false, states, cities});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
    yield put({type: EDIT.SETUP_COUNTRY.FINISH});
}

function* setupStateSaga ({countryId, stateId}) {
    yield put({type: EDIT.META, expectAnswer: true, cities: []});
    try {
        // NOTE clear old field values
        yield put(change('editOrganization', 'city', null));
        // NOTE get list of cities for chosen country and state
        let cities = yield call(getCities, countryId, stateId);
        yield put({type: EDIT.META, expectAnswer: false, cities});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

// export function makeSeparatingCommas (data) {
//     const { insuranceLimit, insuranceDeductible, averageRevenue, marketCapitalizationNumber } = data;
//
//     insuranceLimit ? data.insuranceLimit = insuranceLimit.toLocaleString('en') : null;
//     insuranceDeductible ? data.insuranceDeductible = insuranceDeductible.toLocaleString('en') : null;
//     averageRevenue ? data.averageRevenue = averageRevenue.toLocaleString('en') : null;
//     marketCapitalizationNumber ? data.marketCapitalizationNumber = marketCapitalizationNumber.toLocaleString('en') : null;
//     return data;
// }
//
// export function convertOptionsToNumber(options) {ORGANIZATION
//     const { insuranceLimit, insuranceDeductible, averageRevenue, marketCapitalizationNumber } = options;
//
//     options.insuranceLimit = insuranceLimit ? Number(insuranceLimit.replace(/[^\d]/g, '')) : null;
//     options.insuranceDeductible = insuranceDeductible ? Number(insuranceDeductible.replace(/[^\d]/g, '')) : null;
//     options.averageRevenue = averageRevenue ? Number(averageRevenue.replace(/[^\d]/g, '')) : null;
//     options.marketCapitalizationNumber = marketCapitalizationNumber ? Number(marketCapitalizationNumber.replace(/[^\d]/g, '')) : null;
//     return options;
// }

/**
 * get data
 * @private
 */
function getData () {
    return instanceAPI({method: 'get', url: '/organizations/self'});
}

/**
 * get list of hints
 * @param {String} language
 * @private
 */
/*
function getHintsList (language) {
    let data = ["ORGANIZATION_TITLE", "BUTTON_ORGANIZATION_SAVE", "BUTTON_ORGANIZATION_RESET"];
    return instanceAPI({method: 'post', url: `/hints/get/${language}`, data});
}
*/
/**
 * update organization
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return instanceAPI({ method: 'post', url: '/organizations/update-organization', data});
}

/**
 * get list of states for current country
 * @param {Number} countryId
 * @private
 */
function getStates( countryId ) {
    return new Promise(( resolve, reject ) => {
        instanceAPI({ method: 'post', url: '/states/filter', data: { page: 0, size: 6, filter: { countryId } } })
            .then(({items}) => resolve(items)).catch(reject);
    });
}

/**
 * get list of cities for current country and state
 * @param {Number} countryId
 * @param {Number} stateId
 * @private
 */
function getCities( countryId, stateId ) {
    return new Promise(( resolve, reject ) => {
        instanceAPI({ method: 'post', url: '/cities/filter', data: { page: 0, size: 6, filter: { countryId, stateId } } })
            .then(({items}) => resolve(items)).catch(reject);
    });
}

function* createCitySaga ({countryId, stateId, name}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        const city = yield call(createCity, countryId, stateId, name);
        yield put(changeField('city', city));
        // NOTE update task categories list
        const cities = yield call(getCities, countryId, stateId, name);
        yield call(toastr.success, translate('ORGANIZATION$CITY'), translate('GLOBALS$SUCCESSFUL_ITEM_CREATE'));
        yield put({type: EDIT.META, expectAnswer: false, cities});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function createCity (countryId, stateId, name) {
    let city = {name: name, country: {id: countryId}};
    if (stateId) city.state = {id: stateId};

    return instanceAPI({method: 'post', url: '/cities/safely-create', data: city});
}

/**
 * get all available supported languages
 * @private
 */
function getLanguages() {
    return instanceAPI({ method: 'post', url: '/supported-languages/filter', data: { size: 10000 } })
        .then(({items}) => items);
}
