export default [{
    id: 1,
    name: 'Item A',
    description: 'Item A Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 2,
    name: 'Item B',
    description: 'Item B Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 3,
    name: 'Item C',
    description: 'Item C Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 4,
    name: 'Item D',
    description: 'Item D Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 5,
    name: 'Item E',
    description: 'Item E Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 6,
    name: 'Item F',
    description: 'Item F Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 7,
    name: 'Item G',
    description: 'Item G Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 8,
    name: 'Item H',
    description: 'Item H Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 9,
    name: 'Item I',
    description: 'Item I Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 10,
    name: 'Item J',
    description: 'Item J Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 11,
    name: 'Item K',
    description: 'Item K Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 12,
    name: 'Item L',
    description: 'Item L Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 13,
    name: 'Item M',
    description: 'Item M Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}, {
    id: 14,
    name: 'Item N',
    description: 'Item N Description',
    createdBy: 'John Smith',
    updatedBy: 'John Smith',
    createdAt: new Date(),
    updatedAt: new Date(),
}];
