
// outsource dependencies
import React from 'react';
import moment from 'moment';
import { FormControl, FormHelperText, InputLabel, Input } from '@mui/material';
import { translate } from '../services/translate.service';

// local dependencies

// configuration

/**
 * Data picker component with material design styles and redux form logic
 *
 * @public
 */
export default function ({ input, meta, label, helpText = '', skipTouch = false, ...attr }) {
    // NOTE required format "yyyy-MM-dd"
    let value = input.value ? moment(input.value).format('YYYY-MM-DD') : '';
    let message = '';
    if ( skipTouch || meta.touched ) {
        message = translate(meta.error);
    }
    return (<FormControl error={Boolean(message)} fullWidth>
        {!label ? '' : (<InputLabel htmlFor={input.name} shrink>{label}</InputLabel>)}
        <Input
            {...input}
            {...attr}
            type="date"
            value={value}
            id={input.name}
            autoComplete="off"
            onChange={e => input.onChange(e)}
                />
        {!helpText ? '': (<FormHelperText component="label" htmlFor={input.name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={input.name}>{message}</FormHelperText>)}
    </FormControl>);
};

export const SimpleDataPicker = ({ name, value, label, helpText = '', message = '', onChange, ...attr }) => {
    // NOTE required format "yyyy-MM-dd"
    value = value ? moment(value).format('YYYY-MM-DD') : '';
    return (<FormControl error={Boolean(message)} fullWidth>
        {!label ? '' : (<InputLabel htmlFor={name} shrink>{label}</InputLabel>)}
        <Input
            {...attr}
            id={name}
            name={name}
            type="date"
            value={value}
            autoComplete="off"
            onChange={e => onChange(e.target.value)}
                />
        {!helpText ? '': (<FormHelperText component="label" htmlFor={name}>{helpText}</FormHelperText>)}
        {!message ? '': (<FormHelperText component="label" error htmlFor={name}>{message}</FormHelperText>)}
    </FormControl>);
};
