
// outsource dependencies
import React, { Component } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';

// local dependencies
import Input from './md-input';

// configuration

/**
 * Password component
 */
export default class Password extends Component {
    constructor(props){
        super(props);
        this.state = { visible: false }
    }

    showPassword = () => {
        this.setState({visible: !this.state.visible});
    };

    render () {
        let { visible } = this.state;
        return (
            <Input
                {...this.props}
                type={visible ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={this.showPassword}
                            aria-label="Toggle password visibility"
                                >
                            {visible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }/>
        )
    }

};