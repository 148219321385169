// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {filters} from '../../../components/filter';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {USER_SETTINGS} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import {EditBtn, Link} from '../../../components/md-button';
import MdTablePagination from '../../../components/pagination';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {USER_SETTINGS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { disabled, data, message, clearError, hints } = this.props;
        return (<Container fluid>
            <Breadcrumbs breadCrumbsMap={ USER_SETTINGS_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={12}>
                    <RichHintTitle update={LIST}  name={`USER_SETTINGS$TITLE`} expectAnswer={disabled} data={findHint(hints, 'USER_SETTINGS_TITLE')}/>
                    </Col>
                </Row>
                <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}> <SearchFilterConnected /> </Col> </Row>
                <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col> </Row>
                { data.length ? (
                    <Paper> <ConnectedTable /> </Paper>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                )}
            </ConnectedInitializer>
        </Container>);
    }
}

export default connect(
    state => ({
        data: state.userSettings.list.data,
        hints: state.userSettings.list.hintsData,
        message: state.userSettings.list.errorMessage,
        disabled: state.userSettings.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.userSettings.list.initialized, hints: state.userSettings.list.hintsData}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter:  state.userSettings.list.filter,
        disabled: state.userSettings.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate("GLOBALS$SEARCH_BY_NAME_AND_EMAIL")}
    />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.userSettings.list, hints: state.userSettings.list.hintsData}),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'firstName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('firstName')}
                                >
                            {translate('USERS$FIRST_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'lastName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('lastName')}
                                >
                            {translate('USERS$LAST_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'businessUnit'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('businessUnit')}
                                >
                            {translate('BUSINESS_UNITS$BUSINESS_UNIT')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'employmentType'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('employmentType')}
                                >
                            {translate('USER_SETTINGS$EMPLOYMENT_TYPE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.firstName}</TableCell>
                    <TableCell>{item.lastName}</TableCell>
                    <TableCell>{formatBusinessUnitLabel(get(item, 'businessUnit'))}</TableCell>
                    <TableCell>{item.employmentType && filters.humanize(item.employmentType)}</TableCell>
                    <TableCell className="text-nowrap" align="right">
                        <Link
                            Btn={EditBtn}
                            hint={findHint(hints,`BUTTON_USER_SETTINGS_EDIT`)}
                            permission={PERMISSION.USER_SETTINGS.UPDATE}
                            to={USER_SETTINGS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                                />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));
