
// define rule to crate actions pack for async actions
export const SUFFIX = {
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    FINISH: 'FINISH',
    ERROR: 'ERROR',
    CLEAR: 'CLEAR',
    META: 'META',
};
/**
 * create object with types to build async actions package
 *
 * @private
 */
export function createTypes ( type, res = {} ) {
    for ( let name in SUFFIX ) {
        res[name] = `${type}_${SUFFIX[name]}`;
    }
    return res;
}

export const APP = {
    META: '@app/META',
    INIT: createTypes('@app/INIT'),
    HEALTH: createTypes('@app/HEALTH'),
    SIGN_IN: createTypes('@app/SIGN_IN'),
    SIGN_OUT: createTypes('@app/SIGN_OUT'),
    GET_SELF: createTypes('@app/GET_SELF'),
    CHANGE_LANGUAGE: '@app/CHANGE_LANGUAGE',
    REBUILD_RISK_MODEL: '@app/REBUILD_RISK_MODEL',
    SETUP_LANGUAGES: '@app/SETUP_LANGUAGES',
    SETUP_RISK_MODEL: createTypes('@app/SETUP_RISK_MODEL'),
};

export const PUBLIC = {
    SIGN_UP: createTypes('@public/SIGN_UP'),
    FORGOT_PASSWORD: createTypes('@public/FORGOT_PASSWORD'),
    CHANGE_PASSWORD: createTypes('@public/CHANGE_PASSWORD'),
    EMAIL_CONFIRMATION: createTypes('@public/EMAIL_CONFIRMATION'),
    VALIDATE_PASSWORD_TOKEN: createTypes('@public/VALIDATE_PASSWORD_TOKEN'),
};

export const PRIVATE = {
    EXPAND_ASIDE: '@private/EXPAND_ASIDE',
    COLLAPSE_ASIDE: '@private/COLLAPSE_ASIDE',
};

export default {
    createTypes,
    PRIVATE,
    PUBLIC,
    SUFFIX,
    APP,
};
