// outsource dependencies
import React from 'react';
import {connect} from 'react-redux';
import {Paper} from '@mui/material';
import {Col, Row} from 'react-bootstrap';
import {Field, FieldArray, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import Page from '../../../components/page';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import {MdSelect} from '../../../components/md-select';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import TasksManagement from '../../../components/tasks-management';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {MY_TASKS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";

// config
export const FORM_NAME = 'editMyTasks';

export default connect(
    state => ({...state.myTasks.edit}),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null})
    })
)(({ match, initialized, expectAnswer, errorMessage, initialize, clearError }) => {
    let isNew = match.params.id === NEW_ID;
    return (<Page init={()=>initialize(match.params.id)} initialized={initialized}>
        <Breadcrumbs breadCrumbsMap={ MY_TASKS_MAP }  />
        <Row className="offset-top-10">
            <Col xs={12} md={{span:10, offset:1}} lg={{span:8, offset:2}} >
                <Paper className="indent-5">
                    <h2 className="text-uppercase offset-bottom-8">
                        <span>
                            {isNew
                                ? translate('MY_TASKS$CREATE_MY_TASK')
                                : translate('MY_TASKS$EDIT_MY_TASK')
                            }
                        </span>
                        <Preloader expectAnswer={expectAnswer} type="ICON" />
                    </h2>
                    <ErrorMessage active message={errorMessage} onChange={clearError}/>
                    <ConnectedForm isNew={isNew} />
                </Paper>
            </Col>
        </Row>
    </Page>);
});

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.myTasks.edit.data,
        disabled: state.myTasks.edit.expectAnswer,
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: values => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        // percentage
        if (values.percentage && (values.percentage < 0 || values.percentage > 100)) {
            errors.percentage = 'GLOBALS$PERCENTAGE_ERROR';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel})=>(
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="code"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$CODE')}
                    label={(<strong> {translate('GLOBALS$CODE')} </strong>)}
                        />
            </Col>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    name="status"
                    labelKey="value"
                    valueKey="value"
                    disabled={disabled}
                    component={MdSelect}
                    options={['OPEN','CLOSED'].map(option=>{
                        return ({
                            label: option,
                            value: option
                        })
                    })}
                    optionsLabel={({value}) => value}
                    simpleValue={(value) => { return {value: value, label: value} }}
                    placeholder={translate('GLOBALS$STATUS')}
                    label={(<strong> {translate('GLOBALS$STATUS')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="offset-bottom-4">
                <Field
                    type="number"
                    name="percentage"
                    disabled={disabled}
                    component={MdInput}
                    placeholder={translate('GLOBALS$PERCENTAGE')}
                    label={(<strong> {translate('GLOBALS$PERCENTAGE')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="offset-bottom-4">
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="technologyCategories"
                    type={ENTITY_TYPES.TECH_CATEGORIES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('TECHNOLOGY_CATEGORIES$TITLE')} </strong>)}
                        />
            </Col>
            <Col xs={12} className="offset-bottom-4">
                <SelectEntities
                    isMulti
                    disabled={disabled}
                    name="technologies"
                    type={ENTITY_TYPES.TECHNOLOGIES}
                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                    label={(<strong> {translate('TECHNOLOGIES$TITLE')} </strong>)}
                    getOptionLabel={option => option.name + (option.version ? `(${option.version})` : '') }
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-8"><Col xs={12}> <FieldArray name="tasks" component={TasksManagement} /> </Col></Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" />
                <CancelBtn onClick={cancel} />
            </Col>
        </Row>
    </form>
))));
