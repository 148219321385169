
// outsource dependencies

// local dependencies

import {createTypes} from '../../../actions/types';

export const MAPPING = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        CANCEL: `${prefix}CANCEL`,
        UPDATE: `${prefix}UPDATE`,
        INITIALIZE: `${prefix}INITIALIZE`,
        ADD_ASSESSMENTS_TYPE: `${prefix}ADD_ASSESSMENTS_TYPE`,
        REMOVE_ASSESSMENTS_TYPE: `${prefix}REMOVE_ASSESSMENTS_TYPE`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@security-requirements/edit/mapping');

export const SETTINGS = (function ( prefix ) {
    return {
        // simple
        META: `${prefix}META`,
        DATA: `${prefix}DATA`,
        CLEAR: `${prefix}CLEAR`,
        // complex
        CANCEL: `${prefix}CANCEL`,
        UPDATE: `${prefix}UPDATE`,
        INITIALIZE: `${prefix}INITIALIZE`,
        CHANGE_CONTROL_FAMILY: `${prefix}CHANGE_CONTROL_FAMILY`,
        GET_HINTS_DATA: createTypes(`${prefix}GET_HINTS_DATA`),
    };
})('@security-requirements/edit/settings');
