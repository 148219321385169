
// outsource dependencies
import { cloneDeep } from 'lodash';
import { call, put, takeEvery, take, select } from 'redux-saga/effects';

// local dependencies
import { LIST } from '../actions';
import { initial } from './reducer';
import { toastr } from 'react-redux-toastr';
import is from '../../../services/is.service';
import store, { history } from '../../../store';
import { RISK_ANSWERS } from '../../../constants/routes';
import { instanceAPI } from '../../../services/api.service';
import queryService from '../../../services/query.service';

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(LIST.CANCEL, cancelSaga);
    yield takeEvery(LIST.SAVE_DATA, saveDataSaga);
    yield takeEvery(LIST.INITIALIZE, initializeSaga);
    yield takeEvery(LIST.UPDATE_DATA, updateDataSaga);
    yield takeEvery(LIST.CHANGE_SORT, changeSortSaga);
    yield takeEvery(LIST.GET_DATA.REQUEST, getDataSaga);
    yield takeEvery(LIST.SETUP_EDITABLE, setupEditableSaga);

    // NOTE setup listener on location change to listen history back event (POP)
    yield call(history.listen, historyBackListen);
}

function* initializeSaga () {
    yield put({type: LIST.CLEAR});
    // NOTE take data from location and setup verified params
    const params = yield call(getQueryParams, queryService.parse(history.location.search));
    yield put({type: LIST.META, ...params});
    // NOTE get list
    yield put({type: LIST.GET_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    yield put({type: LIST.META, initialized: true});
}

function* getDataSaga () {
    try {
        // get riskModel
        let { riskModel } = yield select( state => state.app );
        let options = yield select( state => state.riskAnswers.list );
        let {data, totalPages} = yield call(getList, riskModel.id, options);
        // NOTE setup data
        yield put({type: LIST.DATA, data});
        yield put({type: LIST.META, totalPages});
        // NOTE update location
        yield call(updateLocation, options);
    } catch ( {message} ) {
        yield put({type: LIST.META, errorMessage: message});
    }
    yield put({type: LIST.GET_DATA.FINISH});
}

function* changeSortSaga ({field}) {
    let { sortF, sortD } = yield select( state => state.riskAnswers.list );
    // NOTE change direction if field has already chosen
    sortD = field === sortF ? !sortD : true;
    // NOTE get list
    yield put({type: LIST.UPDATE_DATA, sortF: field, sortD, page: 0});
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: LIST.CANCEL});
    // NOTE setup options
    yield put({type: LIST.META, expectAnswer: true, errorMessage: null, ...options});
    // NOTE get list
    yield put({type: LIST.GET_DATA.REQUEST});
    yield take(LIST.GET_DATA.FINISH);
    yield put({type: LIST.META, expectAnswer: false});
}

function* saveDataSaga ({index, property, value}) {
    yield put({type: LIST.META, expectAnswer: true });
    try {
        let { riskModel } = yield select( state => state.app );
        let { data } = yield select( state => state.riskAnswers.list );
        // NOTE get current editable item in list
        let item = cloneDeep(data[index]);
        // NOTE setup new value
        item[property] = value;
        // NOTE update data
        yield call(saveData, riskModel.id, [item]);
        yield put({type: LIST.UPDATE_DATA});
        yield call(toastr.success, 'Risk answers', `Data was successfully updated`);
        yield put({type: LIST.CANCEL});
        yield put({type: LIST.META, expectAnswer: false});
    }
    catch ({message}) {
        yield call(toastr.error, 'Error', message);
        yield put({type: LIST.META, expectAnswer: false, errorMessage: message});
    }
}

function* setupEditableSaga ({index, property}) {
    // NOTE dismiss previous editing
    yield put({type: LIST.CANCEL});
    // NOTE set current item as editable
    yield put({type: LIST.META, editableItem: {index, property}});
}

function* cancelSaga () {
    // NOTE dismiss editing
    yield put({type: LIST.META, editableItem: null});
}

function historyBackListen ({location : {pathname}}, action) {
    if ( action !== 'POP' || pathname !== RISK_ANSWERS.LIST ) return;
    // NOTE reinitialize search from query string after the page path was changed
    // NOTE this event will fired before the url was changed
    setTimeout(()=>store.dispatch({type: LIST.INITIALIZE}), 0);
}

/**
 * get list of risk answers
 * @param {String|Number} riskModelId
 * @param {Object} options
 * @private
 */
function getList(riskModelId, {sortF, sortD, page, size, filter, status, metricDomain}) {
    return new Promise ( ( resolve, reject ) => {
        instanceAPI({
            method: 'post',
            url: `/risk-models/${riskModelId}/answers/filter`,
            data: {
                page,
                size,
                filter: { question: filter, status, metricDomain },
                sort: { field: sortF, order: sortD ? 'ASC' : 'DESC' }
            }
        }).then( ({items, pages}) => {
            // NOTE sort question answers by name
            (items||[]).forEach( item =>
                (item.answers||[]).sort((a, b) => {
                    let x = is.string(a.answer) ? a.answer.toLowerCase() : '';
                    let y = is.string(b.answer) ? b.answer.toLowerCase() : '';
                    return x < y ? -1 : x > y ? 1 : 0;
                })
            );
            resolve({data: items, totalPages: pages})
        }).catch(reject)
    });
}

/**
 * save risk answers
 * @param {String|Number} riskModelId
 * @param {Object} data
 * @private
 */
function saveData(riskModelId, data) {
    return instanceAPI({data, method: 'post', url: `/risk-models/${riskModelId}/answers/save-items`});
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams({page, size, sortF, sortD, filter, status, metric}) {
    let availableFields = ['question', 'answer', 'md.code'];
    return {
        page: is.countable(page) ? Number(page) : initial.page,
        size: is.countable(size) ? Number(size) : initial.size,
        sortF: availableFields.includes(sortF) ? sortF : initial.sortF,
        sortD: sortD ? Boolean(Number(sortD)) : initial.sortD,
        filter: is.defined(filter) ? String(filter) : initial.filter,
        status: is.defined(status) ? String(status) : initial.status,
        metricDomain: is.defined(metric) ? String(metric) : initial.metricDomain
    };
}

/**
 * helper to setup correctness url params
 *
 * @param {Object} reducer
 * @public
 */
function updateLocation({page, size, sortF, sortD, filter, status, metricDomain}) {
    let params = {};
    // NOTE setup data to url which has difference with default data
    page !== initial.page && (params.page = page);
    size !== initial.size && (params.size = size);
    sortF !== initial.sortF && (params.sortF = sortF);
    sortD !== initial.sortD && (params.sortD = Number(Boolean(sortD)));
    filter !== initial.filter && (params.filter = filter);
    status !== initial.status && (params.status = status);
    metricDomain !== initial.metricDomain && (params.metric = metricDomain);
    let search = queryService.format(params);
    // NOTE update url if it has difference
    if ( search !== history.location.search ) {
        history.push(history.location.pathname+search);
    }
}
