
// outsource dependencies
import axios from 'axios';

// local dependencies
import store from '../store';
import is from './is.service';
import msalInstance from '../msal';
import { config } from '../constants';
import { UserModel } from '../models';
import { APP } from '../actions/types';
import storage from './storage.service';
import { getMessage } from '../constants/error-messages';
import {getRiskModelId} from "../constants/routes";
import Auth from "./auth.service";
import IdleService from "./idle.service";
// absolute url to API
let API_PATH = config.serviceUrl + config.apiPath;
// within "prepareError"  app config unavailable tha the reason to make alias
const DEBUG = Boolean(config.DEBUG);
// private names
const AUTH_STORE = 'sAuth';
const AUTH_BASIC = 'Basic '+window.btoa(config.base);
const AUTH_BEARER = 'Bearer ';
const AUTH_HEADER = 'Authorization';
const LOCALE_HEADER = 'Accept-Language';
const LOCALE_BASIC = 'en_US';
const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const LOCALE = 'locale';
const MFA_ERROR = 'mfa_required';
const MFA_TOTP_NOT_SET_ERROR = 'mfa_totp_not_set';

/**
 * @description axios instance with base configuration of app
 * @public
 */
let instanceAPI = function(data, i, h, a, v, e) {

    if ((!data || !data.headers || !data.headers[AUTH_HEADER]) && storage.get(AUTH_STORE) && storage.get(AUTH_STORE)[ACCESS_TOKEN]) {
        axiosInstance.setupAuth(storage.get(AUTH_STORE)[ACCESS_TOKEN]);
        // DEBUG && console.log("Trying to use existing token for request: " + storage.get(AUTH_STORE)[ACCESS_TOKEN]);
    }

    return axiosInstance(data, i, h, a, v, e);
};
instanceAPI.setupLocale = function (locale) {
    axiosInstance.setupLocale(locale);
    axios.setupLocale(locale);
}

let pureAxiosInstance = axios.create({
    baseURL: API_PATH,
    withCredentials: false,
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    },
});

let axiosInstance = axios.create({
    baseURL: API_PATH,
    withCredentials: false,
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    },
});

// NOTE add custom setup auth and setup locale
// axios.setupAuth = setupAuth.bind(axios);
// axios.setupLocale = setupLocale.bind(axios);
// axios.setupAuth();
// axios.setupLocale();

// TODO remove - check session token broke on fly
// window.test = () => axiosInstance.setupAuth('fake')&&recordSession({access_token: 'fake', refresh_token: 'fake'});
/**
 * @description correct setup auth headers
 *
 * @param {String} [token=AUTH_BASIC]
 * @return {Object}
 * @private
 */
function setupAuth(token) {
    let instance = this;
    instance.defaults.headers[AUTH_HEADER] = token ? AUTH_BEARER + token : AUTH_BASIC;

    return instance;
}
axiosInstance.setupAuth = setupAuth.bind(axiosInstance);
axiosInstance.setupAuth();
axios.setupAuth = setupAuth.bind(axios);
axios.setupAuth();

function setupLocale (locale) {
    let instance = this;
    instance.defaults.headers[LOCALE_HEADER] = locale ? locale : LOCALE_BASIC;

    return instance;
}
axiosInstance.setupLocale = setupLocale.bind(axiosInstance);
axios.setupLocale = setupLocale.bind(axios);
axiosInstance.setupLocale();

/**
 * @description origin axios instance interceptors
 * @private
 */
axios.interceptors.response.use(
    prepareResponse,
    prepareError
);

/**
 * @description sync check to known is user logged in
 * NOTE to known more {@link https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c | axios-interceptors-refresh-token}
 * @private
 */
axiosInstance.interceptors.response.use(
    prepareResponse,
    error => (error.request.status === 401) ? handleRefreshSession(error) : prepareError(error)
);

/**
 * @description local variables to correctness refreshing session process
 * @private
 */
let stuckRequests = [];

/**
 * stuck request should be
 * @typedef {Object} stuckRequest
 * @property {Error}    error
 * @property {Object}   config
 * @property {Function} resolve
 * @property {Function} reject
 */

/**
 * Obtain IdP Url from the Path
 *
 * @param path
 * @returns {*}
 */
export function getIdPUrl(path) {
    let result = (config.idpUrl ? config.idpUrl : config.serviceUrl) + path;
    return result;
}

/**
 * Verifying Token Data
 *
 * @param data
 */
function verifyTokenData(data) {
    if (data && !data[ACCESS_TOKEN] && data.value) {
        data[ACCESS_TOKEN] = data.value;
    }
    if (data && !data[REFRESH_TOKEN] && data.refreshToken && data.refreshToken.value) {
        data[REFRESH_TOKEN] = data.refreshToken.value;
    }
}

/**
 * @description store all requests with 401 refresh session and try send request again
 * @param {Object} error
 * @private
 */
function handleRefreshSession ( error ) {
    let { config } = error;
    // NOTE support request may get 401 (JAVA Spring is fucking genius ...) we must skip restoring for that case
    if ( /logout|\/oauth\/token/.test(config.url) ) {
        return prepareError(error);
    }
    // NOTE try to refresh only once per token
    if (!handleRefreshSession.isRefreshing) {
        handleRefreshSession.isRefreshing = true;
        refreshSession()
            .then(session => {
                // NOTE resend all
                stuckRequests.map(({config, resolve, reject}) => {
                    // NOTE set common authentication header
                    config.headers[AUTH_HEADER] = AUTH_BEARER + session[ACCESS_TOKEN];
                    instanceAPI(config).then(resolve).catch(reject);
                    return null;
                });
                // NOTE start new stuck
                stuckRequests = [];
                handleRefreshSession.isRefreshing = false;
            })
            .catch(() => {
                // NOTE sign out using app action to update view
                store.dispatch({type: APP.SIGN_OUT.REQUEST});
                // NOTE reject all
                stuckRequests.map(({error, reject}) => reject(error));
                // NOTE start new stuck
                stuckRequests = [];
                handleRefreshSession.isRefreshing = false;
            });
    }
    // NOTE determine first trying to restore session to prevent recursive restoring session for not allowed request based on user permissions
    if ( !config._wasTryingToRestore ) {
        return new Promise((resolve, reject) => {
            config._wasTryingToRestore = true;
            stuckRequests.push({ config, error, resolve, reject });
        });
    } else {
        return prepareError(error);
    }
}

/**
 * @description prepare results. Solution to prepare data ... or not
 * @param {Object} response
 * @private
 */
function prepareResponse ( response ) {
    // NOTE solution to prepare data
    return response.data;
}

/**
 * @description prepare error
 * @param {Object} error
 * @private
 */
function prepareError ( error ) {
    let { response, request, config } = error;
    DEBUG && console.log('%c Interceptor ', 'background: #EC1B24; color: #fff; font-size: 14px; font-weigth: bold;'
        ,'\n error:', error
        ,'\n URL:', config.url
        ,'\n config:', config
        ,'\n request:', request
        ,'\n response:', response
    );
    let data = response ? response.data : { exceptionCode: ['CROSS_DOMAIN_REQUEST'] };
    let message = data.messages ? getMessage(data.exceptionCode, data.messages) : getMessage(data.exceptionCode, data.message);

    return Promise.reject({ ...data, message });
}

/**
 * @description sync check to known is user logged in
 * @param {Object} session
 * @param {String} locale
 * @private
 */
function recordSession ( {access_token, refresh_token}, locale ) {
    // NOTE record session at the moment primitive
    storage.set(AUTH_STORE, {
        [ACCESS_TOKEN]: access_token,
        [REFRESH_TOKEN]: refresh_token,
    });
    storage.set(LOCALE, locale)
}

/**
 * @description sync check to known is user logged in
 * @private
 */
function clearSession () {
    // NOTE clear session at the moment primitive
    storage.remove(AUTH_STORE);
}

/**
 * @description sync check to known is user logged in
 * @example isLoggedIn(); // => true/false
 * @returns {Boolean}
 * @public
 */
function isLoggedIn () {
    return !is.empty(storage.get(AUTH_STORE));
}

/**
 * @description
 * @example logout().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function logout () {
    return new Promise(resolve => {
        if ( !isLoggedIn() ) {
            axiosInstance.setupAuth();

            return resolve({});
        }

        // Starting Idle flow
        IdleService.stop();

        instanceAPI({method: 'get', url: getIdPUrl('/logout')})
            .then(() => {
                clearSession();
                Auth.clearToken();
                axiosInstance.setupAuth();
                resolve({});
            })
            .catch(() => {
                clearSession();
                Auth.clearToken();
                axiosInstance.setupAuth();
                resolve({});
            });
    });
}

/**
 * @description manual authentication
 * @example login().then( ... ).catch( ... )
 * @param {Object} credential => {email: 'valid email', password: 'password'}
 * @returns {Promise}
 * @public
 */
function login ({email, password, locale}) {
    // setup locale chosen on sign-in page
    instanceAPI.setupLocale(locale);
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: getIdPUrl('/oauth/token'),
            // params: { grant_type: 'password', username: email, password },
            data: { grant_type: 'password', username: email, password },
            headers: { 'Content-Type': 'application/json' }
        })
        .then(data => {
            // Verifying token data after save
            verifyTokenData(data);

            // alias
            let session = data;
            // set common authentication header
            axiosInstance.setupAuth(session[ACCESS_TOKEN]);
            // check token on API
            getSelf()
                .then(user => {
                    recordSession(session, locale);
                    resolve(user);

                    // Starting Idle flow
                    IdleService.run(user);
                })
                // NOTE execute application logout
                .catch(error => logout().finally(() => {
                    reject({
                        ...error,
                        message: getMessage('CREDENTIALS_FORBIDDEN'),
                    });
                }));
        })
        // NOTE without preparing error
        .catch(error => logout().finally(() => {
            reject({
                ...error,
                message: getMessage('INVALID_CREDENTIALS'),
            });
        }));
    });
}

/**
 * @description check multi-factor authentication
 * @example verifyMFA().then( ... ).catch( ... )
 * @param {Object}
 * @returns {Promise}
 * @public
 */
function verifyMFA ({ mfaToken, code, locale }) {
    // setup locale chosen on sign-in page
    instanceAPI.setupLocale(locale);
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: getIdPUrl('/oauth/token'),
            data: {grant_type: "mfa", mfa_token: mfaToken, mfa_code: code},
            headers: { 'Content-Type': 'application/json' }
        })
            .then(data => {
                // Verifying token data after save
                verifyTokenData(data);

                // alias
                let session = data;
                // set common authentication header
                axiosInstance.setupAuth(session[ACCESS_TOKEN]);
                // check token on API
                getSelf()
                    .then(user => {
                        recordSession(session, locale);
                        resolve(user);
                    })
                    // NOTE execute application logout
                    .catch(error => logout().finally(() => reject(error)));
            })
            // NOTE without preparing error
            .catch(error => logout().finally(() => reject(error)));
    });
}

/**
 * @description login with Google
 * @example loginWithGoogle().then( ... ).catch( ... )
 * @param {Object}
 * @returns {Promise}
 * @public
 */
function loginWithGoogle ({ token, locale }) {
    // setup locale chosen on sign-in page
    instanceAPI.setupLocale(locale);
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: getIdPUrl('/oauth/token'),
            data: {grant_type: "google", code: token},
            headers: { 'Content-Type': 'application/json' }
        })
            .then(data => {
                // Verifying token data after save
                verifyTokenData(data);

                // alias
                let session = data;
                // set common authentication header
                axiosInstance.setupAuth(session[ACCESS_TOKEN]);
                // check token on API
                getSelf()
                    .then(user => {
                        recordSession(session, locale);
                        resolve(user);

                        // Starting Idle flow
                        IdleService.run(user);
                    })
                    // NOTE execute application logout
                    .catch(error => logout().finally(() => {
                        console.log(error);
                        reject({
                            ...error,
                            message: 'Failed to login with your Google account.',
                        });
                    }));
            })
            // NOTE without preparing error
            .catch(error => logout().finally(() => reject(error)));
    });
}

/**
 * @description login with Microsoft
 * @example loginWithGoogle().then( ... ).catch( ... )
 * @param {Object}
 * @returns {Promise}
 * @public
 */
function loginWithMicrosoft ({ idToken, username, accessToken, locale }) {
    // setup locale chosen on sign-in page
    instanceAPI.setupLocale(locale);
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: getIdPUrl('/oauth/token'),
            data: {grant_type: "microsoft", idToken, username, accessToken},
            headers: { 'Content-Type': 'application/json' }
        })
            .then(data => {
                // Verifying token data after save
                verifyTokenData(data);

                // alias
                let session = data;
                // set common authentication header
                axiosInstance.setupAuth(session[ACCESS_TOKEN]);
                // check token on API
                getSelf()
                    .then(user => {
                        recordSession(session, locale);
                        resolve(user);

                        // Starting Idle flow
                        IdleService.run(user);
                    })
                    // NOTE execute application logout
                    .catch(error => logout().finally(() => {
                        console.log(error);
                        reject({
                            ...error,
                            message: 'Failed to login with your Microsoft account.',
                        });
                    }));
            })
            // NOTE without preparing error
            .catch(error => logout().finally(() => reject(error)));
    });
}

/**
 * @description login with OKTA token
 *
 * @example loginWithOkta().then( ... ).catch( ... )
 * @param {Object}
 * @returns {Promise}
 * @public
 */
function loginWithOkta({token, locale}) {
    // setup locale chosen on sign-in page
    instanceAPI.setupLocale(locale);
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: getIdPUrl('/oauth/token'),
            data: {grant_type: "okta", token: token},
            headers: { 'Content-Type': 'application/json' }
        })
            .then(data => {
                // Verifying token data after save
                verifyTokenData(data);

                // alias
                let session = data;
                // set common authentication header
                axiosInstance.setupAuth(session[ACCESS_TOKEN]);
                // check token on API
                getSelf()
                    .then(user => {
                        recordSession(session, locale);
                        resolve(user);

                        // Starting Idle flow
                        IdleService.run(user);
                    })
                    // NOTE execute application logout
                    .catch(error => logout().finally(() => {
                        console.log(error);
                        reject({
                            ...error,
                            message: 'Failed to login with your OKTA account.',
                        });
                    }));
            })
            // NOTE without preparing error
            .catch(error => logout().finally(() => reject(error)));
    });
}

/**
 * @description try to refresh session using refresh_token
 * @example refreshSession().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function refreshSession() {
    return new Promise((resolve, reject) => {
        // NOTE remove authentication header if it present
        axiosInstance.setupAuth();
        // NOTE get refresh token
        let refresh_token = (storage.get(AUTH_STORE)||{})[REFRESH_TOKEN];
        let locale = (storage.get(LOCALE));
        // NOTE use the axios origin instance to refresh and store new session data
        axios({
            method: 'post',
            url: getIdPUrl('/oauth/token'),
            data: { grant_type: 'refresh_token', refresh_token },
            headers: { 'Content-Type': 'application/json' }
        }).then(session => {
            // Verifying token data after save
            verifyTokenData(session);

            recordSession(session, locale);
            axiosInstance.setupAuth(session[ACCESS_TOKEN]);
            resolve(session);
        }).catch(error => logout().then(() => reject(error) ) );
    });
}

/**
 * @description try to restore session after reloading application page
 * @example restoreSession().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function restoreSession () {
    return new Promise((resolve, reject) => {
        // NOTE do not have session at all
        if ( !isLoggedIn() ) {
            return logout().finally(() => reject({}));
        }
        // NOTE get session data from storage
        let session = storage.get(AUTH_STORE);
        let locale = storage.get(LOCALE);
        // NOTE set common authentication header
        axiosInstance.setupAuth(session.access_token);
        // NOTE setup locale
        instanceAPI.setupLocale(locale);

        // NOTE check token on API
        getSelf().then(user => {
            recordSession(session, locale);
            resolve(user);

            // Starting Idle flow
            IdleService.run(user);

        }).catch(error => logout().finally(() => reject(error)) );
    });
}

/**
 * get logged user
 *
 * @returns {Promise}
 * @public
 */
function getSelf () {
    return UserModel.getSelf();
}

/**
 * @description check token for changing password
 * @example verifyChangePasswordToken().then( ... ).catch( ... )
 * @param {Object} data
 * @returns {Promise}
 * @public
 */
function verifyPasswordToken ( {token} ) {
    // NOTE used the origin axios instance
    return pureAxiosInstance.get(`${API_PATH}/anonymous/reset-password/verify-code/${token}`);
}

/**
 * @description change password
 * @example verifyChangePasswordToken().then( ... ).catch( ... )
 * @param {Object} data
 * @returns {Promise}
 * @public
 */
function changePassword ( {token, password } ) {
    // NOTE used the origin axios instance
    return pureAxiosInstance({
        method: 'post',
        data: {code: token, password},
        url: `${API_PATH}/anonymous/reset-password/apply`,
    });
}

/**
 * create user
 * @example verifyChangePasswordToken().then( ... ).catch( ... )
 * @param {Object} data
 * @returns {Promise}
 * @public
 */
function signUp ( data ) {
    // NOTE used the origin axios instance
    return axios({
        data,
        method: 'post',
        url: getIdPUrl('/oauth/token/sign-up'),
    });
}

/**
 * @description change password
 * @example verifyChangePasswordToken().then( ... ).catch( ... )
 * @param {Object} data
 * @returns {Promise}
 * @public
 */
function forgotPassword ( {email} ) {
    // NOTE used the origin axios instance
    return pureAxiosInstance.post(API_PATH + '/anonymous/reset-password/send-reset-email', {email});
}

/**
 * @description change password
 * @example verifyChangePasswordToken().then( ... ).catch( ... )
 * @param {Object} data
 * @returns {Promise}
 * @public
 */
function emailConfirmation ( {token} ) {
    // NOTE used the origin axios instance
    return axios.get(getIdPUrl('/oauth/token/confirmation'), { params: {token} });
}

/**
 * @description check health of server
 * @example checkHealth().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function checkHealth () {
    // NOTE used the origin axios instance
    return axios.get(config.serviceUrl + '/actuator/health');
}

/**
 * @description initialize gapi service
 *
 * @public
 */
function initGapi() {
    window.gapi.load('auth2', function() {
        window.gapi.auth2.init({ client_id: config.GOOGLE_CLIENT_ID }).then(success=>success, error=>console.error(error) );
    });
}

/**
 * @description get Google token
 * @example checkHealth().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function getGoogleToken () {
    const auth2 = window.gapi.auth2.getAuthInstance();
    return auth2.signIn().then(googleUser => googleUser.getAuthResponse().id_token).catch(error => {
        console.log('Failed to authorize user with Google. ' + error.message);
    });
}

/**
 * @description get Microsoft token
 * @example checkHealth().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function getMicrosoftToken() {
    // See https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-spa
    // for any information on implementation
    // Add here scopes for id token to be used at MS Identity Platform endpoints.
    const loginRequest = {
        scopes: ["openid", "profile", "User.Read"]
    };
    // Add here scopes for access token to be used at MS Graph API endpoints.
    const tokenRequest = {
        scopes: ["openid", "profile", "User.Read"]
    };

    return msalInstance.loginPopup(loginRequest).then((data) => {
        let {idToken: {rawIdToken}, account: {userName}} = data;

        // Retrieving accessToken
        return (msalInstance.acquireTokenSilent(tokenRequest)
            .catch(error => {
                console.error(error);

                // fallback to interaction when silent call fails
                return msalInstance.acquireTokenPopup(tokenRequest)
                    .then(tokenResponse => {
                        return tokenResponse;
                    }).catch(error => {
                        console.error(error);
                        return null;
                    });
            })
        ).then(accessTokenResponse => {
            let {accessToken} = accessTokenResponse;
            // TODO remove `idToken`, `username` since now flow based only on accessToken
            return {idToken: rawIdToken, username: userName, accessToken};
        });
    });
}

/**
 * get download link
 *
 * @param {String} downloadType
 * @public
 */
function getDownloadLink ( downloadType ) {
    return new Promise((resolve, reject) => {
        let riskModelId = getRiskModelId();
        instanceAPI({ method: 'get', url: `/data-export/get-download-url/${downloadType}?riskModelId=${riskModelId}` })
            .then(result => resolve(result) )
            .catch(reject);
    });
}

// named export
export {
    instanceAPI,
    API_PATH,
    AUTH_STORE,
    AUTH_HEADER,
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    MFA_ERROR,
    MFA_TOTP_NOT_SET_ERROR,
    login,
    logout,
    signUp,
    getSelf,
    initGapi,
    verifyMFA,
    isLoggedIn,
    checkHealth,
    forgotPassword,
    restoreSession,
    refreshSession,
    changePassword,
    getGoogleToken,
    loginWithGoogle,
    getDownloadLink,
    getMicrosoftToken,
    emailConfirmation,
    loginWithMicrosoft,
    loginWithOkta,
    verifyPasswordToken,
};
