
// outsource dependencies

// local dependencies
import { EDIT } from '../actions';

let initial = {
    data: {},
    answers: [],
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
};

export default function ( state = initial, action ) {
    //eslint-disable-next-line
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case EDIT.CLEAR:
            state = initial;
            break;
        case EDIT.DATA:
            state = { ...state,  data: { ...options } };
            break;
        case EDIT.META:
            state = { ...state, ...options, data: state.data };
            break;
    }
    // /@answers\/edit\//.test(type)&&
    // console.log(`%c REDUCER EDIT ${type} `, 'color: #fff; background: #232323; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n action:', action
    //     // ,'\n arguments:', arguments
    // );
    return state;
}
