// outsource dependencies
// import axios from 'axios';
import {filter, find} from 'lodash';
import {call, put, select, take, takeEvery} from 'redux-saga/effects';

// local dependencies
import {history} from '../store';
import {APP} from '../actions/types';
import {HOME, SIGN_IN} from '../constants/routes';
import {DIRECTION} from '../constants/spec';
import storage from '../services/storage.service';
import defaultDictionary from '../constants/dictionaries';
import {checkHealth, getIdPUrl, getSelf, initGapi, instanceAPI, logout, restoreSession} from '../services/api.service';
import {toastr} from 'react-redux-toastr';
import {translate} from '../services/translate.service';
import axios from "axios";
import Auth from "../services/auth.service";

// configuration
const LANGUAGE = 'language';
const LOCALE = 'locale';
const storageRiskField = 'riskModelId';


function* getLoggedUser () {
    try {
        let user = yield call(getSelf);
        yield put({type: APP.GET_SELF.SUCCESS, user, expired: user.credentialsExpired});
    } catch ( error ) {
        yield put({type: APP.GET_SELF.ERROR, error });
    }
    yield put({type: APP.GET_SELF.FINISH});
}

function* signOut ({logoutInfo = {}}) {
    try {
        yield call(logout);
    } catch ( e ) {
        // NOTE do nothing
    }
    // NOTE manage public languages
    yield call(setupLanguagesSaga, {isPublic: true});
    // NOTE no need to redirect private layout make it by him self
    yield put({type: APP.SIGN_OUT.FINISH});

    // Set Logout by Idle message
    if (logoutInfo && logoutInfo.isIdle) {
        yield put({type: APP.SIGN_IN.ERROR, errorMessage: null, notification: 'AUTHORIZATION$IDLE_LOGOUT_PROCESSED'});
    }

    // NOTE go to login screen
    yield call(history.push, SIGN_IN.LINK);
}

function* health () {
    try {
        yield call(checkHealth);
        yield put({type: APP.HEALTH.SUCCESS});
        yield put({type: APP.INIT.REQUEST});
    } catch ( e ) {
        yield put({type: APP.HEALTH.ERROR});
    }
}

function* appInit () {
    try {
        yield call(initGapi);
        // NOTE setup IdP Info
        yield call(loadIdPInfo);
        // NOTE get logged user
        let user = yield call(restoreSession);
        // NOTE setup languages
        yield call(setupLanguagesSaga);
        // NOTE setup risk model
        yield put({type: APP.SETUP_RISK_MODEL.REQUEST, fromStorage: true});
        let { type, message } = yield take([APP.SETUP_RISK_MODEL.SUCCESS, APP.SETUP_RISK_MODEL.ERROR]);
        if ( type === APP.SETUP_RISK_MODEL.ERROR ) {
            throw new Error(message);
        }
        // NOTE setup initial app data
        yield put({type: APP.INIT.FINISH, user, auth: true, expired: user.credentialsExpired});
    } catch ( e ) {
        yield put({type: APP.SIGN_OUT.REQUEST});
        yield take(APP.SIGN_OUT.FINISH);
        yield put({type: APP.INIT.FINISH});
    }
}

function* setupRiskModelSaga ( {type, id = null, reload = false, fromStorage = false} ) {
    try {
        if ( fromStorage ) {
            // NOTE detect previous selected risk model
            id = yield call(storage.get.bind(storage), storageRiskField);
        }
        // NOTE get list of risk models
        let modelsList = yield call(getModelList);
        // NOTE select risk model by id or first in list
        let riskModel = filter(modelsList, {id})[0] || modelsList[0];
        if ( !riskModel ) {
            throw new Error('Can not find risk models within your organization.');
        }
        // NOTE record selected riskModelId
        yield call(storage.set.bind(storage), storageRiskField, id);
        // NOTE setup risk model
        yield put({type: APP.SETUP_RISK_MODEL.SUCCESS, riskModel, modelsList });
        // NOTE redirect to update current page and initialize request with new risk model id
        if ( reload ) {
            // NOTE temporary solution related to pages with form editing
            yield call(history.push, HOME.LINK());
            // let path = history.location.pathname;
            // yield call(history.push, PRIVATE_RELOAD.LINK({path}));
        }
    } catch ( {message} ) {
        // NOTE reinitialize on error
        yield put({type: APP.SETUP_RISK_MODEL.ERROR, message });
    }
    yield put({type: APP.SETUP_RISK_MODEL.FINISH });
}

function* changeLanguageSaga ({language}) {
    // NOTE set language and locale to storage
    yield call(storage.set.bind(storage), LANGUAGE, language.code);
    yield call(storage.set.bind(storage), LOCALE, language.locale);
    // NOTE setup locale
    instanceAPI.setupLocale(language.locale);
    // NOTE get dictionary
    let dictionary = yield call(getDictionary, language);
    // NOTE manage styles
    yield call(changeDirection, language.direction);
    yield put({type: APP.META, dictionary, language: language.code, direction: language.direction, locale: language.locale});
}

function* rebuildRiskModelSaga({ id = null }) {
    try {
        const { riskModel } = yield select( state => state.app );
        // NOTE get list of risk models
        let modelsList = yield call(getModelList);
        // NOTE select risk model by id or first in list
        let selectedRiskModel = filter(modelsList, {id})[0];
        if ( !riskModel ) {
            throw new Error('Can not find risk models within your organization.');
        }
        yield call(rebuildMetricsCacheForRiskModel, selectedRiskModel || riskModel);
        yield call(toastr.success, translate('RISK_MODEL$TITLE'), translate('GLOBALS$SUCCESSFUL_RISK_MODEL_REBUILD'));
    } catch ( {message} ) {
        // NOTE reinitialize on error
        yield put({type: APP.REBUILD_RISK_MODEL.ERROR, message });
    }
}

function* loadIdPInfo() {
    let idpInfo = yield call(getAppIdPInfoData);
    yield put({type: APP.META, idpInfo: idpInfo});
    Auth.setIdPInfo(idpInfo);
}

function* setupLanguagesSaga (options = {}) {
    // NOTE define we need private or public languages
    let method = options.isPublic ? getPublicLanguages : getPrivateLanguages;
    // NOTE get supported languages
    let languages = yield call(method);
    // NOTE setup current language
    let language = yield call(setupLanguage, languages), dictionary;
    // NOTE request for dictionary to server
    if (!language.isDefault) {
        dictionary = yield call(getDictionary, language);
    } else {
        dictionary = defaultDictionary;
    }
    yield put({type: APP.META, languages, dictionary, language: language.code, direction: language.direction, locale: language.locale});
    // NOTE manipulate styles
    yield call(changeDirection, language.direction);
}

/**
 * common request handler
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(APP.INIT.REQUEST, appInit);
    yield takeEvery(APP.HEALTH.REQUEST, health);
    yield takeEvery(APP.SIGN_OUT.REQUEST, signOut);
    yield takeEvery(APP.GET_SELF.REQUEST, getLoggedUser);
    yield takeEvery(APP.CHANGE_LANGUAGE, changeLanguageSaga);
    yield takeEvery(APP.REBUILD_RISK_MODEL, rebuildRiskModelSaga);
    yield takeEvery(APP.SETUP_LANGUAGES, setupLanguagesSaga);
    yield takeEvery(APP.SETUP_RISK_MODEL.REQUEST, setupRiskModelSaga);

    // initialization of app start from check health
    yield put({type: APP.HEALTH.REQUEST});
}

/**
 * get list of risk models
 *
 * @returns {Promise}
 * @public
 */
function getModelList() {
    return instanceAPI({ method: 'get', url: '/risk-models' });
}

/**
 * rebuild metrics cache for current risk model
 *
 * @public
 */
function rebuildMetricsCacheForRiskModel(riskModel) {
    return instanceAPI({ method: 'get', url: `/risk-models/rebuild-metrics-cache/${riskModel.id}` });
}


/**
 * get list of private supported languages
 *
 * @returns {Promise}
 * @public
 */
function getPrivateLanguages () {
    return instanceAPI({ method: 'get', url: '/supported-languages' })
        .catch(({message}) => {console.error(message); return [];});
}

/**
 * get list of public supported languages
 *
 * @returns {Promise}
 * @public
 */
function getPublicLanguages () {
    return instanceAPI({ method: 'get', url: 'info/supported-languages/'})
        .catch(({message}) => {console.error(message); return [];});
}

/**
 * get list of public supported languages
 *
 * @returns {Promise}
 * @public
 */
function getAppIdPInfoData () {
    return axios({method: 'get', url: getIdPUrl('/sso-signin/info'), headers: { 'Content-Type': 'application/json' }}).catch(error => {
        console.error(error);
        return [];
    });
}

/**
 * get dictionary for current language
 *
 * @param {Array} language - current language
 * @returns {Promise}
 * @public
 */
function getDictionary (language) {
    return instanceAPI({
        method: 'get',
        url: `info/supported-languages/vocabulary/${language.code}`,
    }).catch(({message}) => {console.error(message); return {};});
}

/**
 * get current app language
 *
 * @param {Array} languages - supported languages
 * @returns {Object}
 * @public
 */
function setupLanguage (languages) {
    let defaultLanguage = {code: 'eng', direction: DIRECTION.LTR, name: 'English', isDefault: true};
    // NOTE get current language from storage
    const code = storage.get(LANGUAGE);
    // NOTE check on supporting by current organization
    // if language wasn't defined or not supported setup first from list
    let language = find(languages, {code}) || languages[0];
    // NOTE setup 'English' as default if app isn't multilingual
    if (!language || !languages.length) {
        language = defaultLanguage;
    }
    // NOTE setup current language to storage
    storage.set(LANGUAGE, language.code);
    return language;
}


/**
* manipulate styles
*
*/
function changeDirection (direction) {
    if (direction === DIRECTION.RTL) {
        document.body.classList.add('direction-rtl');
    } else {
        document.body.classList.remove('direction-rtl');
    }
}

