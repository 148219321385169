// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {CheckCircleOutline, Close, HighlightOff} from '@mui/icons-material'
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {instanceAPI} from '../../../services/api.service';
import {USERS} from '../../../constants/routes';
import MdTablePagination from '../../../components/pagination';
import {SimpleAsyncSelect} from '../../../components/md-select';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import SearchFilter, {SimpleSearchField} from '../../../components/search-filter';
import {AddBtn, DelBtn, EditBtn, Link, PrimaryBtn, WarningBtn} from '../../../components/md-button';
import {USERS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import {findHint, RichHintTitle} from "../../../components/hints/hints";
import {buildRolesString} from "../../../services/utils";

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { data, expectAnswer, message, clearError, hints  } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ USERS_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-2">
                        <Col xs={6}>
                            <RichHintTitle update={LIST}  name={`USERS$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `USERS_TITLE`)}/>
                        </Col>
                        <Col xs={6} className="text-right top-indent-4">
                            <Link
                                Btn={AddBtn}
                                to={USERS.LINK_EDIT()}
                                permission={PERMISSION.USER.CREATE}
                                className="offset-left-2 offset-bottom-1"
                                hint={findHint(hints, 'BUTTON_USERS_ADD')}
                            />
                        </Col>
                    </Row>
                    <Row> <Col xs={12}>
                        <ErrorMessage active message={message} onChange={clearError}/>
                    </Col> </Row>
                    <Row className="offset-bottom-4"> <Col xs={12}> <FiltersPanelConnected /> </Col> </Row>
                    { data.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        data: state.users.list.data,
        hints: state.users.list.hintsData,
        message: state.users.list.errorMessage,
        uploadedFile: state.users.list.uploadedFile,
        expectAnswer: state.users.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        uploadFile: file => dispatch({ type: LIST.UPLOAD_FILE, file }),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.users.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/*
const DownloadLink = withTranslation(withDownloadLink({downloadType: DOWNLOAD_TYPES.USERS.CSV_LIST})(props => (
    <PrimaryBtn tooltip={translate('GLOBALS$DOWNLOAD_DATA')} permission={PERMISSION.EXPORT.USER} {...props}>
        <i className="fa fa-download" style={{fontSize: 20}} aria-hidden="true" />&nbsp;&nbsp;
        <i className="fa fa-file-text-o" style={{fontSize: 18}} aria-hidden="true" />
    </PrimaryBtn>
)));
*/

/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = withTranslation(connect(
    state => ({showAdvanced: state.users.list.showAdvanced, hints: state.users.list.hintsData}),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => {
            dispatch({type: LIST.META, showAdvanced: true});
            dispatch({type: LIST.UPDATE_DATA, filter: ''});
        },
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)( ({showAdvanced, update, openAdvanced, closeAdvanced, hints}) => (<div>
    { showAdvanced ? (
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Card style={{overflow: 'visible'}}>
                    <CardHeader
                        title={translate('GLOBALS$ADVANCED_SEARCH')}
                        action={
                            <Tooltip title={translate('GLOBALS$CLOSE')}>
                                <IconButton aria-label={translate('GLOBALS$CLOSE')} onClick={closeAdvanced}>
                                    <Close fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }
                    />
                    <CardContent> <SearchForm /> </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn onClick={update} tooltip={translate('GLOBALS$APPLY')} hint={findHint(hints, 'BUTTON_USERS_ADVANCED_SEARCH_APPLY')}> {translate('GLOBALS$APPLY')}  </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn onClick={closeAdvanced} tooltip={translate('GLOBALS$CLOSE')} hint={findHint(hints, 'BUTTON_USERS_ADVANCED_SEARCH_CLOSE')}> {translate('GLOBALS$CLOSE')} </WarningBtn>
                    </CardActions>
                </Card>
            </Col>
        </Row>
    ) : (
        <Row>
            <Col xs={12} sm={6} lg={3} className="offset-bottom-2">
                <SearchFilterConnected />
            </Col>
            <Col xs={12} sm={6}>
                <PrimaryBtn hint={findHint(hints, 'BUTTON_USERS_ADVANCED_SEARCH')} onClick={openAdvanced} tooltip={translate('GLOBALS$ADVANCED_SEARCH')} > {translate('GLOBALS$ADVANCED_SEARCH')} </PrimaryBtn>
            </Col>
        </Row>
    )}
</div>)));

/**
 * search form
 *
 * @public
 */
const SearchForm = withTranslation(connect(
    state => ({ ...state.users.list }),
    dispatch => ({ changeFilterValue: data => dispatch({type: LIST.META, ...data}) })
)( ({ expectAnswer, firstName, lastName, email, role, businessUnit, changeFilterValue }) => (<div>
    <Row>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleSearchField
                value={firstName}
                disabled={expectAnswer}
                clear={() => changeFilterValue({firstName: ''})}
                placeholder={translate('USERS$SEARCH_BY_FIRST_NAME')}
                label={(<strong> {translate('USERS$FIRST_NAME')} </strong>)}
                onChange={e => changeFilterValue({firstName: e.target.value})}
                    />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleSearchField
                value={lastName}
                disabled={expectAnswer}
                clear={() => changeFilterValue({lastName: ''})}
                placeholder={translate('USERS$SEARCH_BY_LAST_NAME')}
                label={(<strong> {translate('USERS$LAST_NAME')} </strong>)}
                onChange={e => changeFilterValue({lastName: e.target.value})}
                    />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-4">
            <SimpleSearchField
                value={email}
                disabled={expectAnswer}
                clear={() => changeFilterValue({email: ''})}
                placeholder={translate('USERS$SEARCH_BY_EMAIL')}
                label={(<strong> {translate('USERS$EMAIL')} </strong>)}
                onChange={e => changeFilterValue({email: e.target.value})}
                    />
        </Col>
    </Row>
    <Row>
        <Col xs={12} md={6} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={role}
                disabled={expectAnswer}
                placeholder={translate('USERS$ROLE')}
                onChange={role => changeFilterValue({role})}
                label={(<strong> {translate('USERS$ROLE')} </strong>)}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'roles/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
        <Col xs={12} md={6} className="offset-bottom-4">
            <SimpleAsyncSelect
                isClearable={true}
                value={businessUnit}
                disabled={expectAnswer}
                placeholder= {translate('BUSINESS_UNITS$BUSINESS_UNIT')}
                label={(<strong> {translate('BUSINESS_UNITS$BUSINESS_UNIT')} </strong>)}
                getOptionLabel={item => formatBusinessUnitLabel(item)}
                onChange={businessUnit => changeFilterValue({businessUnit})}
                loadOptions={(name, done) => {
                    instanceAPI({method: 'post', url: 'business-units/filter', data: { filter: {name} }})
                        .then(({items}) => done(items)).catch(done.bind(null, []));
                }}/>
        </Col>
    </Row>
</div>)));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter:  state.users.list.filter,
        disabled: state.users.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('USERS$SEARCH_BY_NAME_OR_EMAIL')}
            />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.users.list, hints: state.users.list.hintsData,}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({ data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell style={{minWidth: 70, maxWidth: 70, width: 70}}>
                    </TableCell>
                    <TableCell className="th" style={{minWidth: '20%', maxWidth: '20%', width: '20%'}}>
                        <TableSortLabel
                            active={sortF === 'firstName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('firstName')}
                                >
                            {translate('USERS$FIRST_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" style={{minWidth: '20%', maxWidth: '20%', width: '20%'}}>
                        <TableSortLabel
                            active={sortF === 'lastName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('lastName')}
                                >
                            {translate('USERS$LAST_NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" style={{minWidth: '30%', maxWidth: '30%', width: '30%'}}>
                        <TableSortLabel
                            active={sortF === 'email'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('email')}
                                >
                            {translate('USERS$EMAIL')}
                        </TableSortLabel>
                    </TableCell>
                    {/*
                    <TableCell className="th" style={{minWidth: '30%', maxWidth: '30%', width: '30%'}}>
                        <TableSortLabel
                            active={sortF === 'businessUnit'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('businessUnit')}
                                >
                            {translate('BUSINESS_UNITS$BUSINESS_UNIT')}
                        </TableSortLabel>
                    </TableCell>
                    */}
                    <TableCell className="th" style={{minWidth: '30%', maxWidth: '30%', width: '30%'}}>{translate('USERS$ROLES')}</TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={sortF === 'enabled'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={()=>changeSort('enabled')}
                                >
                            {translate('USERS$ENABLED')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell className="user-avatar" style={{paddingLeft: 15, minWidth: 70, maxWidth: 70, width: 70}}>
                        {item && item.profilePicture && item.profilePicture.url ? (
                            <Avatar variant="rounded" src={item.profilePicture.url} alt={item.firstName} className="avatar" />
                        ) : ""}
                    </TableCell>
                    <TableCell>{item.firstName}</TableCell>
                    <TableCell>{item.lastName}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    {/*<TableCell>{formatBusinessUnitLabel(get(item, 'businessUnit'))}</TableCell>*/}
                    <TableCell> {buildRolesString(item.roles)} </TableCell>
                    <TableCell>
                        { item.enabled ? (<CheckCircleOutline color="primary" />) : (<HighlightOff color="error" />) }
                    </TableCell>
                    <TableCell className="text-nowrap" align="right">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.USER.UPDATE}
                            to={USERS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                            hint={findHint(hints, 'BUTTON_USERS_EDIT')}
                                />
                        <DelBtn permission={PERMISSION.USER.DELETE} onClick={() => deleteItem(item.id)} className="offset-left-2" hint={findHint(hints, 'BUTTON_USERS_DELETE')}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</div>)));
