
// outsource dependencies
import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {withStyles} from '@mui/styles'

// local dependencies
import DIALOG from './actions';
import MdSwitch from '../md-switch';
import Preloader from '../preloader';
import ErrorMessage from '../alert-error';
import SelectEntities from '../select-entities';
import { ENTITY_TYPES } from '../../constants/spec';
import { SubmitBtn, ResetBtn, CancelBtn } from '../md-button';
import { translate, withTranslation } from '../../services/translate.service';
import {findHint, RichHintTitle} from '../hints/hints';
import GEO_RECORDS from '../geo-records-processed-dialog/actions';

export default withTranslation(connect(
    state => ({
        ...state.reassignDialog,
        loaded: state.reassignDialog.initialized,
        initialValues: state.reassignDialog.data,
        hints: state.reassignDialog.hintsData,
    }),
    dispatch => ({
        cancel: () => dispatch({type: DIALOG.CANCEL}),
        clearError: () => dispatch({type: DIALOG.META, errorMessage: null}),
        reassign: formData => dispatch({type: DIALOG.REASSIGN, ...formData}),
    })
)( reduxForm ({
    form: 'reassignForm',
    enableReinitialize: true,
    validate: values => {
        let errors = {};
        // user
        if (!values.user) {
            errors.user = 'USERS$USER_REQUIRED';
        }
        return errors;
    }
})( withStyles({paper: { minWidth: '500px', overflow: 'visible' }})(
    ({expectAnswer, loaded, showModal, errorMessage, clearError, cancel, reassign, handleSubmit, pristine, invalid, reset, classes, hints}) => {
        return(
            <Dialog
                open={showModal}
                onClose={(event, reason) => {
                    if(reason === "backdropClick" && reason === "escapeKeyDown") return false;
                    cancel();
                }}
                classes={{paper: classes.paper}}
                aria-labelledby="form-dialog-title"
                    >
                <Preloader expectAnswer={!loaded} type="MIN_HEIGHT" height={200}>
                    <DialogTitle id="form-dialog-title">
                        <RichHintTitle update={GEO_RECORDS}  name={translate('REASSIGN$REASSIGN')} data={findHint(hints, 'REASSIGN_DIALOG_TITLE')}/>
                        {/*translate('REASSIGN$REASSIGN')}&nbsp;<Preloader expectAnswer={expectAnswer} type="ICON" />*/}
                    </DialogTitle>
                    <form onSubmit={handleSubmit(reassign)}>
                        <DialogContent style={{overflow: 'visible'}}>
                            <ErrorMessage active message={errorMessage} onChange={clearError}/>
                            <Row className="offset-bottom-4"><Col xs={12}>
                                <SelectEntities
                                    required
                                    name="user"
                                    disabled={expectAnswer}
                                    type={ENTITY_TYPES.USERS}
                                    placeholder={translate('DROPDOWN$TYPE_TO_SEARCH')}
                                    getOptionLabel={option => get(option, 'fullName')}
                                    label={(<strong className="required-asterisk"> {translate('USERS$USER')} </strong>)}
                                        />
                            </Col></Row>
                            <Row><Col xs={12}>
                                <Field
                                    fullWidth={false}
                                    disabled={expectAnswer}
                                    name="reassignOwner"
                                    component={MdSwitch}
                                    label={(<strong> {translate('REASSIGN$REASSIGN_OWNER')} </strong>)}
                                        />
                            </Col></Row>
                        </DialogContent>
                        <DialogActions>
                            <SubmitBtn disabled={pristine||invalid||expectAnswer} className="offset-right-2"  hint={findHint(hints, 'BUTTON_REASSIGN_DIALOG_SAVE')}/>
                            <ResetBtn onClick={reset} disabled={pristine||expectAnswer} className="offset-right-2" hint={findHint(hints, 'BUTTON_REASSIGN_DIALOG_RESET')} />
                            <CancelBtn onClick={cancel} disabled={expectAnswer} hint={findHint(hints, 'BUTTON_REASSIGN_DIALOG_CANCEL')}/>
                        </DialogActions>
                    </form>
                </Preloader>
            </Dialog>
        )
    }
))));
