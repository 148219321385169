
// outsource dependencies
import { toastr } from 'react-redux-toastr';
import { put, call, takeEvery } from 'redux-saga/effects';

// local dependencies
import { EDIT } from './actions';
import { instanceAPI } from '../../services/api.service';
import { translate } from '../../services/translate.service';

/**
 *
 *
 * @publicexport const FORM_NAME = 'editVendor';
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.SEARCH, searchSaga);
}

function * initializeSaga () {
    yield put({type: EDIT.CLEAR});
    yield call(getExternalAnalyticsSaga);
    yield call(getMessagesSaga);
    yield call(getFeedsSaga);
    yield call(getTasksSaga);
    yield call(getWebinarsSaga);
    yield call(getNewsSaga);
    yield call(getPacesSaga);
    yield call(getTicketsSaga);
    yield put({type: EDIT.META, initialized: true});
}

function* getMessagesSaga () {
    try {
        let { items: messages } = yield call(instanceAPI, {method: 'post', url: '/feeds/messages', data: { size: 4 }});
        yield put({type: EDIT.META, messages});
    } catch ( {message} ) {
        yield put({type: EDIT.META, messages : []});
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
    }
}

function* getExternalAnalyticsSaga () {
    try {
        let externalAnalytics = yield call(instanceAPI, {method: 'post', url: '/feeds/external-analytics', data: { size: 4 }});
        yield put({type: EDIT.META, externalAnalytics});
    } catch ( {message} ) {
        yield put({type: EDIT.META, messages : []});
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
    }
}

function* getFeedsSaga () {
    try {
        let { items: feeds } = yield call(instanceAPI, {method: 'post', url: '/feeds/feeds', data: { size: 6 }});
        yield put({type: EDIT.META, feeds});
    } catch ( {message} ) {
        yield put({type: EDIT.META, feeds : []});
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
    }
}

function* getTasksSaga () {
    try {
        let { items: tasks } = yield call(instanceAPI, {method: 'post', url: '/tasks/self', data: { filter: { name: '' }, page: 0, size: 12, sort: { field: 'name', order: 'ASC' }} });
        yield put({type: EDIT.META, tasks});
    } catch ( {message} ) {
        yield put({type: EDIT.META, tasks : []});
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
    }
}

function* getTicketsSaga () {
    try {
        let { items: tickets } = yield call(instanceAPI, {method: 'post', url: '/feeds/tickets', data: { size: 5 }});
        yield put({type: EDIT.META, tickets});
    } catch ( {message} ) {
        yield put({type: EDIT.META, tickets : []});
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
    }
}

function* getWebinarsSaga () {
    try {
        let { items: webinars } = yield call(instanceAPI, {method: 'post', url: '/feeds/webinars', data: { size: 3 }});
        yield put({type: EDIT.META, webinars});
    } catch ( {message} ) {
        yield put({type: EDIT.META, webinars : []});
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
    }
}

function* getNewsSaga () {
    try {
        let { items: news } = yield call(instanceAPI, {method: 'post', url: '/feeds/whats-new', data: { size: 3 }});
        yield put({type: EDIT.META, news});
    } catch ( {message} ) {
        yield put({type: EDIT.META, news : []});
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
    }
}

function* getPacesSaga () {
    try {
        let { items: paces } = yield call(instanceAPI, {method: 'post', url: '/feeds/pace-courses', data: { size: 5 }});
        yield put({type: EDIT.META, paces});
    } catch ( {message} ) {
        yield put({type: EDIT.META, paces: []});
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
    }
}

function* searchSaga ({ filter }) {
    console.log('searchSaga', filter);
    yield put({type: EDIT.META, paces: []});
}

