
// outsource dependencies
import { get } from 'lodash'
import { toastr } from 'react-redux-toastr';
import { put, call, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { history } from '../../../store';
import { changeField, FORM_NAME } from './index';
import { NEW_ID } from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import { translate } from '../../../services/translate.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CHANGE_CHAPTER, changeChapterSaga);
    yield takeEvery(EDIT.CHANGE_SECTION, changeSectionSaga);
    yield takeEvery(EDIT.UPLOAD_FILE, uploadFileSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.CANCEL, cancelSaga);
}

function* initializeSaga ({type, ...options}) {
    yield put({type: EDIT.CLEAR});
    try {
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, ...params});
        // NOTE get data
        let data = yield call(getData, options);
        yield put({type: EDIT.DATA, data});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, initialized: true});
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* changeChapterSaga ({chapter}) {
    yield put({type: EDIT.META, expectAnswer: true });
    // NOTE clear 'section' and 'article' field
    yield put(changeField('section', null));
    yield put(changeField('article', null));
    try {
        // NOTE get sections list
        let sections = yield call(getSections, chapter.id);
        yield put({type: EDIT.META, sections});
        // NOTE get articles list if there is no sections in chapter
        if (!sections.length) {
            let articles = yield call(getArticles, chapter.id);
            yield put({type: EDIT.META, articles});
        }
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function* changeSectionSaga ({section}) {
    yield put({type: EDIT.META, expectAnswer: true});
    // NOTE clear 'article' field
    yield put(changeField('article', null));
    try {
        let { values } = yield select(state => state.form[FORM_NAME]);
        let chapter = get(values, 'chapter', {});
        let articles = yield call(getArticles, chapter.id, section.id);
        yield put({type: EDIT.META, articles});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function* uploadFileSaga ({file}) {
    yield put({type: EDIT.META, expectAnswer: true, uploaded: false, errorMessage: null});
    // NOTE clear field
    yield put(changeField('document', null));
    try {
        let document = yield call(uploadFile, file);
        yield call(toastr.success, 'Compliance', `File was successfully upload`);
        yield put(changeField('document', document));
        yield put({type: EDIT.META, expectAnswer: false, uploaded: true});
    } catch ({message}) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        // NOTE clear input value
        yield put({type: EDIT.META, expectAnswer: false, uploaded: true, errorMessage: message});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.gdprSystemCompliance.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get GDPR article status by id
 * @param {Number|String} id
 * @param {Number|String} systemId
 * @param {Number|String} articleId
 * @param {Number|String} paragraphId
 * @private
 */
function getData ({id, systemId, articleId, paragraphId}) {
    if ( id === NEW_ID ) {
        return {system: {id: systemId}};
    }
    return instanceAPI({
        method: 'post',
        url: '/gdpr/system-article/status/check',
        data: {
            id,
            system: {id: systemId},
            article: articleId === NEW_ID ? null : {id: articleId},
            paragraph: paragraphId === NEW_ID ? null : {id: paragraphId},
        }
    });
}

/**
 * update GDPR article status
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return instanceAPI({method: 'post', data, url: '/gdpr/system-article/status/apply'});
}

/**
 * upload file
 * @param {File} file
 * @private
 */
function uploadFile ( file ) {
    const data = new FormData();
    data.append('file', file);
    return instanceAPI({method: 'post', url: '/documents/gdpr/upload', data, headers: {'Cache-Control': 'no-cache', 'Content-Type': 'multipart/form-data'}});
}

/**
 * get list of sections for current GDPR Chapter
 * @param {Number} chapterId
 * @private
 */
function getSections( chapterId = null ) {
    return instanceAPI({ method: 'post', url: '/gdpr/section/filter', data: { page: 0, size: 6, filter: { chapterId } } })
        .then(({items}) => items);
}

/**
 * get list of sections for current GDPR Chapter
 * @param {Number} chapterId
 * @param {Number} sectionId
 * @private
 */
function getArticles( chapterId = null, sectionId = null ) {
    return instanceAPI({ method: 'post', url: '/gdpr/articles/filter', data: { page: 0, size: 6, filter: { chapterId, sectionId, isSystemLevel: true } } })
        .then(({items}) => items);
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
