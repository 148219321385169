// outsource dependencies
import moment from 'moment';
import get from 'lodash/get';
import {show} from 'redux-modal';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';


// local dependencies
import {LIST} from '../actions';
import {config} from '../../../constants/index';
import AddDomainModal from '../add-domain-modal';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import ErrorMessage from '../../../components/alert-error';
import {translate, withTranslation} from '../../../services/translate.service';
import {DOMAINS} from '../../../constants/routes';
import {history} from '../../../store';
import {DOMAINS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

class Domains extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        // let { expectAnswer , addDomain, list, hints } = this.props;
        let {expectAnswer, list, hints} = this.props;
        return (<Container fluid>
            <Breadcrumbs breadCrumbsMap={DOMAINS_MAP}/>
            <ConnectedInitializer>
                <AddDomainModal/>
                <Row className="offset-bottom-4">
                    <Col xs={9}>
                        <RichHintTitle
                            update={LIST}
                            name={`DOMAINS$TITLE`}
                            expectAnswer={expectAnswer}
                            data={findHint(hints, `DOMAINS_TITLE`)}
                        />
                    </Col>
                    <Col xs={3} className="text-right top-indent-4">
                        {/*<AddBtn onClick={addDomain} placement="left" disabled={expectAnswer} permission={PERMISSION.DOMAIN.ADD} />*/}
                        <Link
                            Btn={AddBtn}
                            placement="left"
                            hint={findHint(hints, `BUTTON_DOMAINS_ADD`)}
                            to={DOMAINS.LINK_EDIT()}
                            permission={PERMISSION.DOMAIN.CREATE}/>
                    </Col>
                </Row>
                <ConnectedError/>
                {list.length ? (
                    <Paper> <ConnectedTable/> </Paper>
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                )}
            </ConnectedInitializer>
        </Container>);
    }
}

export default connect(
    state => ({
        expectAnswer: state.domains.list.meta.expectAnswer,
        list: state.domains.list.list,
        hints: state.domains.list.meta.hintsData
    }),
    dispatch => ({
        addDomain: () => dispatch(show('add-domain-modal')),
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null})
    })
)(Domains);


const ConnectedError = connect(
    state => ({message: state.domains.list.meta.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: LIST.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedInitializer = connect(
    state => ({initialize: state.domains.list.meta.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedTable = withTranslation(connect(
    state => ({list: state.domains.list.list, hints: state.domains.list.meta.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
    })
)(
    ({list, hints, deleteItem}) => (<div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">Name</TableCell>
                    <TableCell className="th">{translate('GLOBALS$DESCRIPTION')}</TableCell>
                    <TableCell className="th">{translate('GLOBALS$CREATED_BY')}</TableCell>
                    <TableCell className="th">{translate('GLOBALS$CREATED_ON')}</TableCell>
                    <TableCell className="th">{translate('DOMAINS$MANAGED_BY')}</TableCell>
                    <TableCell className="th">{translate('GLOBALS$UPDATED_ON')}</TableCell>
                    <TableCell className="th"/>
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    {/*// NOTE: prepare data to view*/}
                    <TableCell>{get(item, 'riskDomainView', '') ? get(item, 'riskDomainView.name', '') : get(item, 'name', '')}</TableCell>
                    <TableCell>{get(item, 'riskDomainView', '') ? get(item, 'riskDomainView.description', '') : get(item, 'description', '')}</TableCell>
                    <TableCell>{get(item, 'createdByUser.firstName', '') + ' ' + get(item, 'createdByUser.lastName', '')}</TableCell>
                    <TableCell>{moment(get(item, 'createdAt', Date.now())).format(config.clientTimeFormat)}</TableCell>
                    <TableCell>{get(item, 'riskManagementOwnerUser.firstName', '') + ' ' + get(item, 'riskManagementOwnerUser.lastName', '')}</TableCell>
                    <TableCell>{moment(get(item, 'updatedAt', Date.now())).format(config.clientTimeFormat)}</TableCell>
                    <TableCell>
                        <Link
                            Btn={EditBtn}
                            disabled={!!get(item, 'riskDomainView')}
                            permission={PERMISSION.DOMAIN.UPDATE}
                            to={DOMAINS.LINK_EDIT({
                                id: item.id,
                                query: {back: history.location.pathname + history.location.search}
                            })}
                            hint={findHint(hints, 'BUTTON_DOMAINS_EDIT')}
                        />
                        <DelBtn disabled={!!get(item, 'riskDomainView')}
                                permission={PERMISSION.DOMAIN.DELETE}
                                onClick={() => deleteItem(item.id)}
                                className="offset-left-2"
                                hint={findHint(hints, 'BUTTON_DOMAINS_DELETE')}
                        />
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>)
));
