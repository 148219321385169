// outsource dependencies
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {Component} from 'react';
//import { Prompt } from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {Field, FieldArray, Form, isPristine, reduxForm, submit} from 'redux-form';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {PERMISSION} from '../../../constants/spec';
import {SubmitBtn} from '../../../components/md-button';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import {ProtectedInput} from '../../../components/md-input';
import MdTablePagination from '../../../components/pagination';
import {translate, withTranslation} from '../../../services/translate.service';
import {SYSTEM_RISK_REDUCTION_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {findHint, RichHintTitle} from "../../../components/hints/hints";
import separateWithCommaService from '../../../services/separate-with-comma.service';

class List extends Component {
    componentDidMount() { this.props.initialize(); }
    componentWillUnmount() { this.props.clear(); }
    render() {
        let { disabled, data, update, message, clearError, pristine, hints } = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={ SYSTEM_RISK_REDUCTION_MAP }  />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={9}>
                            <RichHintTitle update={LIST}  name={`SYSTEM_RISK_REDUCTION$TITLE`} expectAnswer={disabled} data={findHint(hints, `SYSTEM_RISK_REDUCTION_TITLE`)}/>
                        </Col>
                        <Col xs={3} className="text-right top-indent-4 align-middle">
                            {!pristine&&<strong className="text-danger h4">
                                <i className="fa fa-exclamation-triangle" aria-hidden="true" /> {translate('GLOBALS$UNSAVED_DATA')}
                            </strong>}&nbsp;
                            <SubmitBtn
                                onClick={update}
                                disabled={pristine||disabled}
                                hint={findHint(hints, 'BUTTON_SYSTEM_RISK_REDUCTION_SAVE')}
                                permission={PERMISSION.SYSTEM_RISK_REDUCTION.UPDATE}
                                    >
                                {translate('GLOBALS$SAVE')}
                            </SubmitBtn>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}>
                        <SearchFilterConnected />
                    </Col> </Row>
                    <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col> </Row>
                    {/*NOTE block navigating away from a page and show message*/}
                    {/*<Prompt when={!pristine} message={translate('GLOBALS$UNSAVED_DATA_MESSAGE')} />*/}
                    { data.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        data: state.systemRiskReduction.list.data,
        hints: state.systemRiskReduction.list.hintsData,
        pristine: isPristine('systemRiskReduction')(state),
        message: state.systemRiskReduction.list.errorMessage,
        disabled: state.systemRiskReduction.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({ type: LIST.CLEAR }),
        update: () => dispatch(submit('systemRiskReduction')),
        initialize: () => dispatch({ type: LIST.INITIALIZE }),
        clearError: () => dispatch({ type: LIST.META, errorMessage: null })
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.systemRiskReduction.list.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter: state.systemRiskReduction.list.filter,
        disabled: state.systemRiskReduction.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter }) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('SYSTEMS$SEARCH_BY_SYSTEM')}
            />
)));

const ConnectedTable = withTranslation(connect(
    state => ({
        ...state.systemRiskReduction.list,
        initialValues: { reductions: state.systemRiskReduction.list.data }
    }),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
        update: ({reductions}) => dispatch({ type: LIST.SAVE, reductions })
    })
)( reduxForm({ form: 'systemRiskReduction', enableReinitialize: 'true' }) (
    ({ page, size, totalPages, sortF, sortD, expectAnswer, changePage, changeSize, changeSort, handleSubmit, update }) => (
    <Form autoComplete="off" name="systemRiskReduction" onSubmit={handleSubmit(update)}>
        <div style={{overflowX: 'auto'}}>
            <Table className="md-table" padding="checkbox">
                <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                    <TableRow style={{height: 48}}>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'system'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={()=>changeSort('system')}
                                    >
                                {translate('SYSTEMS$SYSTEM')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="th">
                            <TableSortLabel
                                direction={sortD ? 'asc' : 'desc'}
                                active={sortF === 'riskReductionPercent'}
                                onClick={()=>changeSort('riskReductionPercent')}
                                    >
                                {translate('SECURITY_TOOLS$RISK_REDUCTION_PERCENTAGES')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="th">
                            <TableSortLabel
                                active={sortF === 'toolPrice'}
                                direction={sortD ? 'asc' : 'desc'}
                                onClick={()=>changeSort('toolPrice')}
                                    >
                                {translate('SECURITY_TOOLS$TOOL_PRICE')}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {/*NOTE list items with input fields*/}
                <FieldArray name="reductions" component={Items}/>
            </Table>
        </div>
        <MdTablePagination
            page={page}
            size={size}
            disabled={expectAnswer}
            totalPages={totalPages}
            changeSize={changeSize}
            changePage={changePage}
                />
    </Form>
))));

const Items = withTranslation(connect(
    state => ({ disabled: state.systemRiskReduction.list.expectAnswers }),
    null
)( ({ fields, disabled }) => (
    <TableBody>
        {fields.map((mKey, i) => {
            let item = fields.get(i);
            return (<TableRow style={{height: 48}} key={i}>
                <TableCell>{get(item, 'system.name')}</TableCell>
                <TableCell>
                    <Field
                        type="number"
                        disabled={disabled}
                        component={ProtectedInput}
                        style={{maxWidth: '250px'}}
                        classes={{input: 'text-right'}}
                        name={`${mKey}.riskReductionPercent`}
                        normalize={value => value && Number(value)}
                        permission={PERMISSION.SYSTEM_RISK_REDUCTION.UPDATE}
                        placeholder={translate('SECURITY_TOOLS$RISK_REDUCTION_PERCENTAGES')}
                            />
                </TableCell>
                <TableCell>
                    <Field
                        disabled={disabled}
                        name={`${mKey}.toolPrice`}
                        component={ProtectedInput}
                        style={{maxWidth: '250px'}}
                        classes={{input: 'text-right'}}
                        //normalize={value => value && Number(value)}
                        placeholder={translate('SECURITY_TOOLS$TOOL_PRICE')}
                        permission={PERMISSION.SYSTEM_RISK_REDUCTION.UPDATE}
                        normalize={separateWithCommaService.normalizeReal}
                            />
                </TableCell>
            </TableRow>)
        })}
    </TableBody>
)));
