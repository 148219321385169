
// outsource dependencies

// local dependencies
import { LIST } from '../actions';

export const initial = {
    data: [],
    editableItem: null,
    // meta
    initialized: false,
    expectAnswer: false,
    errorMessage: null,
    // pagination
    page: 0,
    size: 10,
    totalPages: 0,
    // sort
    sortF: 'question',
    sortD: true,
    // filter
    filter: '',
    status: 'BOTH',
    metricDomain: null
};

export default function ( state = initial, action ) {
    let { type, ...options } = action;
    switch ( type ) {
        default:
            break;
        case LIST.CLEAR:
            state = initial;
            break;
        case LIST.DATA:
            state = { ...state, data: options.data };
            break;
        case LIST.META:
            state = { ...state, ...options, data: state.data  };
            break;
    }

    // /@answers\/list\//.test(type)&&
    // console.log(`%c CATEGORIES ${type} `, 'color: #fff; background: #343434; font-size: 18px;'
    //     ,'\n state:', state
    //     ,'\n action:', action
    //     // ,'\n arguments:', arguments
    // );
    return state;
}
