// outsource dependencies
import moment from 'moment';
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel
} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {config} from '../../../constants';
import Preloader from '../../../components/preloader';
import {AUDIT_LOGS} from '../../../constants/routes';
import {SimpleRadio} from '../../../components/md-radio';
import ErrorMessage from '../../../components/alert-error';
import {instanceAPI} from '../../../services/api.service';
import MdTablePagination from '../../../components/pagination';
import {SimpleAsyncSelect} from '../../../components/md-select';
import {SimpleDataPicker} from '../../../components/md-date-picker';
import {translate, withTranslation} from '../../../services/translate.service';
import {CancelBtn, DetailsBtn, Link, PrimaryBtn, SuccessBtn} from '../../../components/md-button';
import {AUDIT_LOGS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {findHint, RichHintTitle} from "../../../components/hints/hints";

// config
export const DATE_RANGE = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    THIS_WEEK: 'This week',
    LAST_WEEK: 'Last week',
    THIS_MONTH: 'This month',
    LAST_MONTH: 'Last month',
    CHOOSE_DATE: 'Choose date',
};

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {disabled, data, message, clearError, hints, systemName} = this.props;

        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={AUDIT_LOGS_MAP} />
                <ConnectedInitializer>
                    <Row className="offset-bottom-4"> <Col xs={12}>
                        <RichHintTitle update={LIST} name={`AUDIT_LOGS$TITLE`} expectAnswer={disabled}
                                       data={findHint(hints, `AUDIT_LOGS_TITLE`)} />
                        {systemName &&
                            <span className="text-uppercase h2" style={{fontSize:30}}  aria-describedby="rich-hint"> — {systemName} </span>}
                    </Col> </Row>
                    <Row className="offset-bottom-4"> <Col xs={12}> <FiltersPanelConnected /> </Col> </Row>
                    <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError} /> </Col> </Row>
                    {data.length ? (
                        <Paper> <ConnectedTable /> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        data: state.auditLogs.list.data,
        systemName: state.auditLogs.list.systemName,
        hints: state.auditLogs.list.hintsData,
        message: state.auditLogs.list.errorMessage,
        disabled: state.auditLogs.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null})
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.auditLogs.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = withTranslation(connect(
    state => ({
        ...state.auditLogs.list,
        hints: state.auditLogs.list.hintsData,
        systemName: state.auditLogs.list.systemName
    }),
    dispatch => ({
        update: () => dispatch({type: LIST.UPDATE_DATA, page: 0}),
        openAdvanced: () => dispatch({type: LIST.META, showAdvanced: true}),
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        changeFilterValue: filter => dispatch({type: LIST.META, ...filter}),
        changeDate: dateRange => dispatch({type: LIST.CHANGE_DATE, dateRange}),
    })
)(({
       update,
       hints,
       changeFilterValue,
       user,
       itemType,
       expectAnswer,
       dateRange,
       dateFrom,
       dateTo,
       changeDate,
       showAdvanced,
       closeAdvanced,
       openAdvanced,
       systemName
   }) => (<div>
    {!systemName && <Row className="offset-bottom-4">
        <Col xs={12}>
            <Card style={{overflow: 'visible'}}>
                <CardHeader title={translate('GLOBALS$ADVANCED_SEARCH')}/>
                <CardContent style={{padding: '0 15px'}}>
                    <Row> <Col xs={12}> <SearchDescription /> </Col> </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} md={6} className="offset-bottom-2">
                            <SimpleAsyncSelect
                                value={user}
                                isClearable={true}
                                labelKey="fullName"
                                disabled={expectAnswer}
                                placeholder={translate('USERS$USER')}
                                onChange={user => changeFilterValue({user})}
                                label={(<strong> {translate('USERS$USER')} </strong>)}
                                loadOptions={(name, done) => {
                                    instanceAPI({
                                        method: 'post',
                                        url: 'users/filter',
                                        data: {page: 0, size: 6, filter: {name}},
                                    }).then(({items}) => done(items)).catch(done.bind(null, []));
                                }} />
                        </Col>
                        <Col xs={12} md={6} className="offset-bottom-2">
                            <SimpleAsyncSelect
                                value={itemType}
                                isClearable={true}
                                disabled={expectAnswer}
                                placeholder={translate('AUDIT_LOGS$ITEM_TYPE')}
                                onChange={itemType => changeFilterValue({itemType})}
                                label={(<strong> {translate('AUDIT_LOGS$ITEM_TYPE')} </strong>)}
                                loadOptions={(name, done) => {
                                    instanceAPI({
                                        method: 'get',
                                        url: 'audit-logs/item-types'
                                    }).then(done).catch(done.bind(null, []));
                                }} />
                        </Col>
                    </Row>
                </CardContent>
                <Collapse in={showAdvanced} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Row><Col xs={12}>
                            <SimpleRadio
                                row
                                name="dateRange"
                                value={dateRange}
                                disabled={expectAnswer}
                                label={translate('AUDIT_LOGS$DATE_RANGE')}
                                onChange={dateRange => changeDate(dateRange)}
                                options={Object.keys(DATE_RANGE).map(v => ({
                                    value: DATE_RANGE[v],
                                    label: DATE_RANGE[v]
                                }))}
                            />
                        </Col></Row>
                        {dateRange !== DATE_RANGE.CHOOSE_DATE ? null : (<Row>
                            <Col xs={12} md={6} className="offset-bottom-2">
                                <SimpleDataPicker
                                    name="dateFrom"
                                    value={dateFrom}
                                    disabled={expectAnswer}
                                    onChange={dateFrom => changeFilterValue({dateFrom})}
                                    label={(<strong> {translate('AUDIT_LOGS$DATE_FROM')} </strong>)}
                                />
                            </Col>
                            <Col xs={12} md={6} className="offset-bottom-2">
                                <SimpleDataPicker
                                    name="dateTo"
                                    value={dateTo}
                                    disabled={expectAnswer}
                                    onChange={dateTo => changeFilterValue({dateTo})}
                                    label={(<strong> {translate('AUDIT_LOGS$DATE_TO')} </strong>)}
                                />
                            </Col>
                        </Row>)}
                    </CardContent>
                </Collapse>
                <CardActions style={{justifyContent: 'flex-end'}}>
                    <SuccessBtn hint={findHint(hints, `BUTTON_AUDIT_LOGS_APPLY`)} tooltip={translate('GLOBALS$APPLY')}
                                onClick={update} disabled={expectAnswer} >
                        {translate('GLOBALS$APPLY')}
                    </SuccessBtn>
                    {showAdvanced ? (
                        <CancelBtn tooltip={translate('GLOBALS$CLOSE')} onClick={closeAdvanced} disabled={expectAnswer}  hint={findHint(hints, 'BUTTON_AUDIT_LOGS_ADVANCED_SEARCH_CANCEL')}>
                            {translate('GLOBALS$CLOSE')}
                        </CancelBtn>
                    ) : (
                        <PrimaryBtn tooltip={translate('GLOBALS$ADVANCED_SEARCH')} onClick={openAdvanced}  hint={findHint(hints, 'BUTTON_AUDIT_LOGS_ADVANCED_SEARCH')}
                                    disabled={expectAnswer}>
                            {translate('GLOBALS$ADVANCED_SEARCH')}
                        </PrimaryBtn>
                    )}
                </CardActions>
            </Card>
        </Col>
    </Row>}

</div>)));

const SearchDescription = connect(state => ({...state.auditLogs.list}), null)(
    ({user, itemType, organization, dateFrom, dateTo, dateRange}) => (
        <h4 className="text-center capital-letter">
            {user && (<span>{translate('USERS$USER')} <strong>{user.fullName}</strong> </span>)}
            {itemType ? (<span> {translate('AUDIT_LOGS$CHANGED_ITEM')}
                <strong>{itemType.name}</strong> </span>) : translate('AUDIT_LOGS$START_SEARCH')}
            {organization && (
                <span> {translate('AUDIT_LOGS$IN_ORGANIZATION')} <strong>{organization.name}</strong> </span>)}
            {dateRange !== DATE_RANGE.CHOOSE_DATE ? (<strong> {dateRange.toLowerCase()} </strong>) : (<span>
                {dateFrom && (<span> {translate('AUDIT_LOGS$FROM')}
                    <strong>{moment(dateFrom).format(config.clientTimeFormat)}</strong> </span>)}
                {dateTo && (<span> {translate('AUDIT_LOGS$TILL')}
                    <strong>{moment(dateTo).format(config.clientTimeFormat)}</strong> </span>)}
            </span>)}
        </h4>
    )
);

const ConnectedTable = withTranslation(connect(
    state => ({
        ...state.auditLogs.list,
        hints: state.auditLogs.list.hintsData,
        systemName: state.auditLogs.list.systemName
    }),
    dispatch => ({
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({
       data,
       hints,
       page,
       size,
       totalPages,
       sortF,
       sortD,
       expectAnswer,
       changePage,
       changeSize,
       changeSort,
       systemName
   }) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'auditUserName'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('auditUserName')}
                        >
                            {translate('USERS$USER')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"> {translate('AUDIT_LOGS$OPERATION_TYPE')} </TableCell>
                    {!systemName && <TableCell className="th"> {translate('AUDIT_LOGS$ITEM_TYPE')} </TableCell>}
                    <TableCell className="th"> {translate('GLOBALS$UPDATED')} </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'logDate'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('logDate')}
                        >
                            {translate('AUDIT_LOGS$UPDATED_DATE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th" />
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.auditUserName}</TableCell>
                    <TableCell>{item.operationTypeInfo}</TableCell>
                    {!systemName && <TableCell>{get(item, 'itemTypeInfo.name')}</TableCell>}
                    <TableCell>{formatPreview(item)}</TableCell>
                    <TableCell className="text-nowrap">
                        {item.logDate && moment(item.logDate).format(config.clientTimeFormat + ', h:mm:ss a')}
                    </TableCell>
                    <TableCell className="text-nowrap" align="right">
                        <Link
                            Btn={DetailsBtn}
                            tooltip={translate('GLOBALS$DETAILS')}
                            hint={findHint(hints, `BUTTON_AUDIT_LOGS_DETAILS`)}
                            to={AUDIT_LOGS.LINK_EDIT({
                                id: item.id,
                                query: {back: history.location.pathname + history.location.search}
                            })}
                        >
                        </Link>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>)));

/**
 * format preview for updated item
 *
 * @public
 */
const formatPreview = (item = {}) => {
    let preview;
    let value = item.newValue ? item.newValue : item.oldValue;
    value = JSON.parse(value);
    let type = get(item, 'itemTypeInfo.itemType');
    switch (type) {
        default:
            preview = get(value, 'name');
            break;
        case 'USER':
        case 'USER_PASSWORD_CHANGED':
            preview = get(value, 'fullName', '');
            break;
        case 'SYSTEM_OWNER':
        case 'VENDOR_OWNER':
            preview = get(value, 'fullName') || get(value, 'owner.fullName', '');
            break;
        case 'QUALITATIVE_QUESTION':
            preview = get(value, 'question', '');
            break;
        case 'RISK_MODEL_DOMAIN':
            preview = get(value, 'riskDomainView.name', '');
            break;
        case 'VENDOR_ASSOCIATED_SYSTEMS':
        case 'QUALITATIVE_QUESTION_ANSWER_FOR_VENDOR':
            preview = get(value, 'vendor.name', '');
            break;
        case 'QUALITATIVE_QUESTION_ANSWER_FOR_SYSTEM':
            preview = get(value, 'system.name', '');
            break;
    }
    return preview;
};
