
// outsource dependencies
import React, { Component, Fragment } from 'react';
import Modal from '@mui/material/Modal';
import { connectModal, hide } from 'redux-modal'
import { connect } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import {Field, reduxForm, change} from 'redux-form';

// local dependencies
import { LIST } from './actions';
import MdButton, { SubmitBtn } from '../../components/md-button';
import Preloader from '../../components/preloader';
import { translate, withTranslation } from '../../services/translate.service';
import MdInput from "../../components/md-input";
import { AddBtn } from '../../components/md-button';

// config
export const FORM_NAME = 'createDomain';
export const changeField = (field, value) => {change(FORM_NAME, field, value)};


class AddDomainModal extends Component {
    componentDidMount() { this.props.getList(); }

    render() {
        let { show, handleHide, modalLoading, modalList } = this.props;
        return (
            <Modal open={show}>
                <Container fluid className="md-modal-content">
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <h3 className="text-uppercase">{translate('DOMAINS$DIALOG_TITLE')}</h3>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <Preloader type="BOX" expectAnswer={modalLoading}>
                                {modalList.length ? (
                                    <Paper> <ConnectedTable /> </Paper>
                                ) : (
                                    <h4 className="text-center">{translate('DOMAINS$NO_AVAILABLE_DOMAINS')}</h4>
                                )}
                            </Preloader>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12}>
                            <Preloader type="BOX" expectAnswer={modalLoading}>
                                <Domain/>
                            </Preloader>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-right">
                            <MdButton variant="text" onClick={ () => handleHide('add-domain-modal') }>
                                {translate('GLOBALS$CLOSE')}
                            </MdButton>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        );
    }
}

export default connectModal( {name: 'add-domain-modal'} )(
    connect(
        state => ({ modalLoading: state.domains.list.meta.modalLoading, modalList: state.domains.list.modalList }),
        dispatch => ({ getList: () => dispatch({ type: LIST.GET_MODAL_LIST }) })
    )(AddDomainModal)
);


const ConnectedTable = withTranslation(connect(
    state => ({list: state.domains.list.modalList}),
    dispatch => ({
        addDomain: id => dispatch({ type: LIST.ADD_DOMAIN, id }),
        hide: () => dispatch(hide('add-domain-modal'))
    })
)( ({ list, addDomain, hide }) => (
    <Table className="md-table" >
        <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
            <TableRow style={{height: 48}}>
                <TableCell className="th">{translate('GLOBALS$NAME')}</TableCell>
                <TableCell className="th">{translate('GLOBALS$DESCRIPTION')}</TableCell>
                <TableCell className="th">&nbsp;</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {list.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>
                    <AddBtn onClick={ () => { addDomain(item.id); hide();} }></AddBtn>
                </TableCell>
            </TableRow>))}
        </TableBody>
    </Table>
)));

const Domain = connect(
    state => ({
        initialValues: state.domains.list.item,
        expectAnswer: state.domains.list.meta.expectAnswer
    }),
    dispatch => ({ addDomain: ( formData ) => dispatch({type: LIST.ADD_DOMAIN, ...formData}) })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } - named errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // NAME
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        return errors;
    }
})( ({invalid, pristine, expectAnswer, handleSubmit, addDomain}) => (
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(addDomain)}>
        <Row className="offset-bottom-6"> <Col xs={12}>
            {/*<Field name="domain" component={Item} />*/}
            <Fragment>
                <h3 className="text-uppercase">
                    <span className="align-middle">{translate('DOMAINS$DIALOG_TITLE_CREATE')}</span>&nbsp;
                </h3>
                {/*{meta.error && (<h4 className="text-center is-invalid"> <label className="form-text h4"> { translate(meta.error) } </label> </h4>)}*/}
                <ul className="list-unstyled">
                    <li className="offset-bottom-2">
                        <Paper className="indent-2"><Container fluid>
                            <Row className="offset-bottom-1">
                                <Col xs={12}>
                                    <Field
                                        component={MdInput}
                                        name="name"
                                        placeholder={translate('GLOBALS$NAME')}
                                        required={true}
                                        label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                                    />
                                </Col>
                            </Row>
                            <Row className="offset-bottom-1">
                                <Col xs={12}>
                                    <Field
                                        multiline
                                        component={MdInput}
                                        name="description"
                                        placeholder={translate('GLOBALS$DESCRIPTION')}
                                        label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                                    />
                                </Col>
                            </Row>
                        </Container></Paper>
                    </li>
                </ul>
            </Fragment>
        </Col> </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={true} disabled={pristine||invalid||expectAnswer} className="offset-right-2" />
            </Col>
        </Row>
    </form>
) ));

/**
 * component for item of "riskTypes" list
 *
 * @param {Object} props
 * @private
 */
// const Item = withTranslation( ({ meta }) => {
//     return (<Fragment>
//         <h3 className="text-uppercase">
//             <span className="align-middle">{translate('$CREATE NEW DOMAIN$')}</span>&nbsp;
//         </h3>
//         {meta.error && (<h4 className="text-center is-invalid"> <label className="form-text h4"> { translate(meta.error) } </label> </h4>)}
//         <ul className="list-unstyled">
//                 <li className="offset-bottom-2">
//                     <Paper className="indent-2"><Container fluid>
//                         <Row className="offset-bottom-1">
//                             <Col xs={12}>
//                                 <Field
//                                     component={MdInput}
//                                     name="name"
//                                     placeholder={translate('GLOBALS$NAME')}
//                                      required={true}
//                                      label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
//                                 />
//                             </Col>
//                         </Row>
//                         <Row className="offset-bottom-1">
//                             <Col xs={12}>
//                                 <Field
//                                     multiline
//                                     component={MdInput}
//                                     name="description"
//                                     placeholder={translate('GLOBALS$DESCRIPTION')}
//                                     label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
//                                 />
//                             </Col>
//                         </Row>
//                     </Container></Paper>
//                 </li>
//         </ul>
//     </Fragment>);
// });

