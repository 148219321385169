// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
// import { history } from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {DATA_EXFILTRATION_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import {translate} from '../../../services/translate.service';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

class Edit extends Component {
    componentDidMount() {
        this.props.initialize(this.props.match.params.id);
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, match, hints} = this.props;
        let isNew = match.params.id === NEW_ID;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={DATA_EXFILTRATION_MAP}/>
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span: 8, offset: 2}}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase offset-bottom-10">
                                    <span>
                                        <RichHintTitle
                                            update={EDIT}
                                            name={isNew ? translate('DATA_EXFILTRATION$CREATE_EXFILTRATION') : translate('DATA_EXFILTRATION$EDIT_EXFILTRATION')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints, 'DATA_EXFILTRATION_TITLE')}
                                        />
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"/>
                                </h2>
                                <ConnectedError/>
                                <ConnectedForm isNew={isNew}/>
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({expectAnswer: state.dataExfiltration.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit);

const ConnectedInitializer = connect(
    state => ({initialize: state.dataExfiltration.edit.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.dataExfiltration.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = connect(
    state => ({
        initialValues: state.dataExfiltration.edit.data,
        disabled: state.dataExfiltration.edit.expectAnswer,
        hints: state.dataExfiltration.edit.hintsData,
    }),
    dispatch => ({update: (formData) => dispatch({type: EDIT.UPDATE, ...formData})})
)(reduxForm({
    form: 'editDataExfiltration',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name || values.name.length < 5) {
            errors.name = 'Name is required and must contain at least 5 symbol character';
        }
        // description
        if (!values.description || values.description.length < 10) {
            errors.description = 'Description is required and must contain at least 10 symbol character';
        }
        return errors;
    }
})(({handleSubmit, invalid, cancel, pristine, disabled, update, reset, isNew, hints}) => (
    <form autoComplete="off" name="editDataExfiltration" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-3">
            <Col xs={12} md={6} className="offset-bottom-3">
                <Field
                    name="name"
                    placeholder="Name"
                    component={MdInput}
                    disabled={disabled}
                    required={true}
                    label={(<strong className="required-asterisk"> Name </strong>)}
                />
            </Col>
            <Col xs={12} md={6}>
                <Field
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder="Description"
                    required={true}
                    label={(<strong className="required-asterisk"> Description </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine || invalid || disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_DATA_EXFILTRATION_CREATE' : 'BUTTON_DATA_EXFILTRATION_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine || disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_DATA_EXFILTRATION_RESET')}/>
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_DATA_EXFILTRATION_CANCEL')}/>
            </Col>
        </Row>
    </form>
)));
