// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Field, reduxForm} from 'redux-form';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from '../actions';
import {NEW_ID} from '../../../constants/spec';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {DOMAINS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {useParams} from "react-router-dom";
import {LIST} from '../../cyber-roles/actions';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={DOMAINS_MAP}  />
                <ConnectedInitializer>
                    <Row className="offset-top-10">
                        <Col xs={12} md={{span:8,offset:2}}>
                            <Paper className="indent-5">
                                <h2 className="text-uppercase">
                                    <span>
                                        <RichHintTitle
                                            update={LIST}
                                            name={isNew ? translate('DOMAINS$CREATE_DOMAIN') : translate('DOMAINS$EDIT_DOMAIN')}
                                            expectAnswer={expectAnswer}
                                            data={findHint(hints,`DOMAINS_TITLE`)}
                                        />
                                    </span>
                                    <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                                </h2>
                                <ConnectedError />
                                <ConnectedForm isNew={isNew} />
                            </Paper>
                        </Col>
                    </Row>
                </ConnectedInitializer>
            </Container>
        );
}

export default connect(
    state => ({expectAnswer: state.domains.edit.expectAnswer, hints: state.domains.edit.hintsData}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.domains.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.domains.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.domains.edit.data,
        hints: state.domains.edit.hintsData,
        disabled: state.domains.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: 'editDomain',
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, hints, invalid, pristine, disabled, update, reset, isNew, cancel})=>(
    <form autoComplete="off" name="editDomain" onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                    required={true}
                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    multiline={true}
                    name="description"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$DESCRIPTION')}
                    label={(<strong> {translate('GLOBALS$DESCRIPTION')} </strong>)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" hint={findHint(hints, isNew ? 'BUTTON_DOMAINS_CREATE' : 'BUTTON_DOMAINS_SAVE')} />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" hint={findHint(hints, 'BUTTON_DOMAINS_RESET')}/>
                <CancelBtn onClick={cancel} hint={findHint(hints, 'BUTTON_DOMAINS_CANCEL')}/>
            </Col>
        </Row>
    </form>
))));
