// outsource dependencies
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {EDIT} from '../actions';
import {history} from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import {instanceAPI} from '../../../services/api.service';
import getHintsList, {DATA_DOMAINS_EDIT_HINTS} from '../../../services/hints.service';

/**
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true, ...params});
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, DATA_DOMAINS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

function* cancelSaga () {
    let { back } = yield select(state => state.dataDomains.edit);
    // NOTE go back
    yield call(history.push, back);
}

/**
 * get data type class by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/data-domains/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * update data type class
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: `/data-domains`, data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: `/data-domains`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}



/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
