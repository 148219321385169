// outsource dependencies
import moment from 'moment';
import get from 'lodash/get';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import {config} from '../../../constants';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {ENTITY_TYPES, NEW_ID} from '../../../constants/spec';
import SelectEntities from '../../../components/select-entities';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {OPERATIONAL_COSTS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {useParams} from "react-router-dom";
import separateWithCommaService from '../../../services/separate-with-comma.service';

// config
export const FORM_NAME = 'editOperationalCost';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer,hints}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;

    return (
        <Container fluid>
            <Breadcrumbs breadCrumbsMap={ OPERATIONAL_COSTS_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-top-10">
                    <Col xs={12} md={{span:8,offset:2}}>
                        <Paper className="indent-5">
                            <h2 className="text-uppercase">
                                 <span>
                                     {isNew
                                         ? translate('FIXED_OPERATIONAL_COSTS$CREATE_COST')
                                         : translate('FIXED_OPERATIONAL_COSTS$EDIT_COST')
                                     }
                                </span>
                                <Preloader expectAnswer={expectAnswer} type="ICON" />
                            </h2>
                            <ConnectedError />
                            <ConnectedForm isNew={isNew} />
                        </Paper>
                    </Col>
                </Row>
            </ConnectedInitializer>
        </Container>
    );
}

export default connect(
    state => ({expectAnswer: state.operationalCosts.edit.expectAnswer}),
    dispatch => ({
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clear: () => dispatch({type: EDIT.CLEAR})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.operationalCosts.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.operationalCosts.edit.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        formValues: getFormValues(FORM_NAME)(state),
        initialValues: state.operationalCosts.edit.data,
        disabled: state.operationalCosts.edit.expectAnswer
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        changeTask: task => dispatch({type: EDIT.CHANGE_TASK, task}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData})
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: values => {
        let errors = {};
        // name
        if (!values.task) {
            errors.task = 'TASKS$TASK_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formValues={}, changeTask})=>(
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)} >
        <Row>
            <Col xs={12} className="offset-bottom-4">
                <SelectEntities
                    isClearable
                    name="task"
                    disabled={disabled}
                    type={ENTITY_TYPES.TASKS}
                    placeholder={translate('TASKS$TASK')}
                    onChange={(e, value) => changeTask(value)}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('TASKS$TASK')} </strong>)}
                        />
            </Col>
        </Row>
        <Row className="offset-bottom-2">
            <Col xs={12} md={6}> <h4>
                <strong>{translate('FIXED_OPERATIONAL_COSTS$PERSON_COLON')} </strong> {get(formValues, 'user.fullName')}
            </h4> </Col>
            <Col xs={12} md={6}> <h4>
                <strong>{translate('FIXED_OPERATIONAL_COSTS$COST_CENTER_COLON')} </strong>
                {formatBusinessUnitLabel(get(formValues, 'businessUnit.name'))}
            </h4> </Col>
        </Row>
        <Row className="offset-bottom-2">
            <Col xs={12}>
                <SelectEntities
                    name="cyberRole"
                    disabled={disabled}
                    type={ENTITY_TYPES.CYBER_ROLES}
                    placeholder={translate('CYBER_ROLES$CYBER_ROLE')}
                    label={(<strong> {translate('CYBER_ROLES$CYBER_ROLE')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}> <h4><strong>{translate('USER_SETTINGS$RATE_TYPE_COLON')} </strong> {get(formValues, 'rateType')}</h4> </Col>
            <Col xs={12} md={6}> <h4><strong>{translate('USER_SETTINGS$RATE_COLON')} </strong> {get(formValues, 'rate')}</h4> </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}> <h4><strong>{translate('TASKS$ESTIMATED_START_DATE_COLON')} </strong>
                {get(formValues, 'estimatedStartDate') && moment(get(formValues, 'estimatedStartDate')).format(config.clientTimeFormat)}
            </h4> </Col>
            <Col xs={12} md={6}> <h4><strong>{translate('TASKS$ESTIMATED_END_DATE_COLON')} </strong>
                {get(formValues, 'estimatedEndDate') && moment(get(formValues, 'estimatedEndDate')).format(config.clientTimeFormat)}
            </h4> </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}> <h4><strong>{translate('TASKS$ACTUAL_START_DATE')} </strong>
                {get(formValues, 'actualStartDate') && moment(get(formValues, 'actualStartDate')).format(config.clientTimeFormat)}
            </h4> </Col>
            <Col xs={12} md={6}> <h4><strong>{translate('TASKS$ACTUAL_START_DATE_COLON')} </strong>
                {get(formValues, 'actualEndDate') && moment(get(formValues, 'actualEndDate')).format(config.clientTimeFormat)}
            </h4> </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}> <h4>
                <strong>{translate('TASKS$ESTIMATED_HOURS_COLON')} </strong> {get(formValues, 'estimatedHours')}
            </h4> </Col>
            <Col xs={12} md={6}> <h4>
                <strong>{translate('GLOBALS$TOTAL_COSTS_COLON')} </strong>
                {get(formValues.totalCosts && formValues.totalCosts.toString().split(',').length === 1 ? separateWithCommaService.separateReal(formValues) : formValues, 'totalCosts')}
            </h4> </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12}>
                <Field
                    multiline
                    name="comments"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$COMMENTS')}
                    label={(<strong> {translate('GLOBALS$COMMENTS')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2" />
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2" />
                <CancelBtn onClick={cancel} />
            </Col>
        </Row>
    </form>
))));
