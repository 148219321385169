// outsource dependencies
import filter from 'lodash/filter';
import { reset } from 'redux-form';
import { call, put, select, takeEvery, take, delay } from 'redux-saga/effects';

// local dependencies
import { MODELS_MODAL } from './actions';
import { instanceAPI } from '../../services/api.service';

// configure
const formNamePrefix = 'editModel';

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(MODELS_MODAL.INITIALIZE, initializeSaga);
    yield takeEvery(MODELS_MODAL.GET_LIST.REQUEST, getListSaga);
    yield takeEvery(MODELS_MODAL.UPDATE_MODEL, updateRiskModelSaga);
    yield takeEvery(MODELS_MODAL.TOGGLE_CARD, toggleCardSaga);
    yield takeEvery(MODELS_MODAL.SHOW_NEW, showNewSaga);
    yield takeEvery(MODELS_MODAL.CREATE_NEW, createNewSaga);
    yield takeEvery(MODELS_MODAL.CLEAR_NEW, clearNewSaga);
    yield takeEvery(MODELS_MODAL.CLOSE_ALL.REQUEST, closeAllSaga);
}

function* initializeSaga() {
    yield put({type: MODELS_MODAL.META, initialized: false, showModal: true});
    yield put({type: MODELS_MODAL.GET_LIST.REQUEST});
    yield take(MODELS_MODAL.GET_LIST.FINISH);
    yield delay(800);
    yield put({type: MODELS_MODAL.META, initialized: true});
}

function* getListSaga() {
    try {
        let list = yield call(getList);
        // NOTE add special properties to work with collapsible item cards
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            item.formName = `${formNamePrefix}-${i}`;
        }
        yield put({type: MODELS_MODAL.LIST, list});
    } catch ({message}) {
        yield put({type: MODELS_MODAL.META, errorMessage: message});
    }
    yield put({type: MODELS_MODAL.GET_LIST.FINISH});
}

function* updateRiskModelSaga({type, ...options}) {
    yield put({type: MODELS_MODAL.META, expectAnswer: true, errorMessage: null});
    try {
        yield call(updateRiskModel, options);
        // NOTE get updated list of risk models
        yield put({type: MODELS_MODAL.GET_LIST.REQUEST});
        yield take(MODELS_MODAL.GET_LIST.FINISH);
        // NOTE close all items
        yield put({type: MODELS_MODAL.CLOSE_ALL.REQUEST});
        yield take(MODELS_MODAL.CLOSE_ALL.FINISH);
        // NOTE remove preloader
        yield put({type: MODELS_MODAL.META, expectAnswer: false});
    } catch ({message}) {
        yield put({type: MODELS_MODAL.META, errorMessage: message, expectAnswer: false});
    }
}

function* showNewSaga() {
    // NOTE close all items
    yield put({type: MODELS_MODAL.CLOSE_ALL.REQUEST});
    yield take(MODELS_MODAL.CLOSE_ALL.FINISH);
    // NOTE open form creation
    yield put({type: MODELS_MODAL.META, showNew: true});
}

function* clearNewSaga() {
    // NOTE hide form creation
    yield put({type: MODELS_MODAL.META, showNew: false});
    // NOTE reset unsaved data
    yield put(reset(`${formNamePrefix}-NEW`));
}

function* createNewSaga({type, ...options}) {
    yield put({type: MODELS_MODAL.META, expectAnswer: true, errorMessage: null});
    try {
        yield call(createRiskModel, options);
        // NOTE get updated list of risk models
        yield put({type: MODELS_MODAL.GET_LIST.REQUEST});
        yield take(MODELS_MODAL.GET_LIST.FINISH);
        // NOTE close all items
        yield put({type: MODELS_MODAL.CLOSE_ALL.REQUEST});
        yield take(MODELS_MODAL.CLOSE_ALL.FINISH);
        yield put({type: MODELS_MODAL.META, expectAnswer: false});
    } catch ({message}) {
        yield put({type: MODELS_MODAL.META, errorMessage: message, expectAnswer: false});
    }
}

function* closeAllSaga() {
    // NOTE take list of items
    let list = yield select(state => state.modelsModal.list);
    // NOTE close all items
    yield put({type: MODELS_MODAL.CLEAR_NEW});
    for (let item of list) {
        item.expanded = false;
        // NOTE reset unsaved data
        yield put(reset(item.formName));
    }
    yield put({type: MODELS_MODAL.LIST, list: list.slice(0)});
    yield put({type: MODELS_MODAL.CLOSE_ALL.FINISH});
}

function* toggleCardSaga({type, id, expanded}) {
    // NOTE close all items
    yield put({type: MODELS_MODAL.CLOSE_ALL.REQUEST});
    yield take(MODELS_MODAL.CLOSE_ALL.FINISH);
    // NOTE open item
    if (expanded) {
        // NOTE take list of items
        let list = yield select(state => state.modelsModal.list);
        let itemToExpand = filter(list, {id})[0];
        itemToExpand.expanded = true;
        yield put({type: MODELS_MODAL.LIST, list: list.slice(0)});
        // NOTE forced start render
        yield put({type: MODELS_MODAL.META, _hash: Date.now()});
    }
}

/**
 * update risk model
 *
 * @param {Object} data
 * @returns {Promise}
 * @private
 */
function updateRiskModel(data) {
    return instanceAPI({url: `/risk-models`, method: 'put', data});
}

/**
 * create risk model
 *
 * @param {Object} data
 * @returns {Promise}
 * @private
 */
function createRiskModel(data) {
    return instanceAPI({url: `/risk-models`, method: 'post', data});
}

/**
 * get list of risk models
 *
 * @returns {Promise}
 * @public
 */
function getList() {
    return instanceAPI({method: 'get', url: '/risk-models'});
}
