// outsource dependencies
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {EDIT} from '../actions';
import {history} from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import {instanceAPI} from '../../../services/api.service';
import {updateData as createVendor} from '../../vendors/edit/saga';
import {FORM_NAME} from './index';
import getHintsList, {TECHNOLOGIES_EDIT_HINTS} from '../../../services/hints.service';
import {change, getFormValues} from "redux-form";
import {toastr} from "react-redux-toastr";
import {translate} from "../../../services/translate.service";

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
    yield takeEvery(EDIT.CREATE_VENDOR, createVendorSaga);
    yield takeEvery(EDIT.SETUP_CATEGORY, setupTechnologyCategorySaga);
    yield takeEvery(EDIT.SETUP_SUBCATEGORY, setupTechnologySubcategorySaga);
    yield takeEvery(EDIT.CREATE_SUBCATEGORY, createTechnologySubcategorySaga);
    yield takeEvery(EDIT.CREATE_CLASS_TYPE, createTechnologyClassTypeSaga);
}

function* initializeSaga ({id}) {
    yield put({type: EDIT.CLEAR});
    try {
        let data = yield call(getData, id);
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, initialized: true, ...params});

        if (data.technologyCategory && data.technologyCategory.id) {
            yield put({type: EDIT.SETUP_CATEGORY, technologyCategoryId: data.technologyCategory.id});
        }
    }
    catch ({message}) {
        yield put({type: EDIT.META, errorMessage: message, initialized: true});
    }
}

export function* createVendorSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true});
    try {
        const vendor = yield call(createVendor, options);
        yield put(change(FORM_NAME, 'vendor', vendor));
        yield put({type: EDIT.META, expectAnswer: false});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* setupTechnologyCategorySaga ({technologyCategoryId}) {
    yield put({type: EDIT.META, expectAnswer: true, technologySubcategories: [], technologyClassTypes: []});
    try {
        // NOTE clear old field values
        yield put(change(FORM_NAME, 'technologySubcategory', null));
        // NOTE get list of states for chosen country
        let technologySubcategories = yield call(getTechnologySubcategories, technologyCategoryId);
        yield put({type: EDIT.META, expectAnswer: false, technologySubcategories});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
    // yield put({type: EDIT.SETUP_COUNTRY.FINISH});
}

function* setupTechnologySubcategorySaga ({technologySubcategoryId}) {
    yield put({type: EDIT.META, expectAnswer: true, technologyClassTypes: []});
    try {
        // NOTE clear old field values
        yield put(change(FORM_NAME, 'technologyClassType', null));
        // NOTE get list of states for chosen country
        let technologyClassTypes = yield call(getTechnologyClassTypes, technologySubcategoryId);
        yield put({type: EDIT.META, expectAnswer: false, technologyClassTypes});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
    // yield put({type: EDIT.SETUP_COUNTRY.FINISH});
}

function* createTechnologySubcategorySaga ({name}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let {technologyCategory} = yield select(state => getFormValues(FORM_NAME)(state));
        const technologySubcategory = yield call(createSubcategory, name, technologyCategory);
        yield put(change(FORM_NAME, 'technologySubcategory', technologySubcategory));
        // NOTE update task categories list
        const technologySubcategories = yield call(getTechnologySubcategories, technologyCategory.id);
        yield call(toastr.success, translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_SUBCATEGORY'), translate('GLOBALS$SUCCESSFUL_ITEM_CREATE'));
        yield put({type: EDIT.META, expectAnswer: false, technologySubcategories, technologySubcategory});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* createTechnologyClassTypeSaga({name}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        // let {technologyCategory, technologySubcategory} = yield select(state => state.technologies.edit.data);
        let {technologyCategory, technologySubcategory} = yield select(state => getFormValues(FORM_NAME)(state));
        const technologyClassType = yield call(createClassType, name, technologyCategory, technologySubcategory);
        yield put(change(FORM_NAME, 'technologyClassType', technologyClassType));
        // NOTE update task categories list
        const technologyClassTypes = yield call(getTechnologyClassTypes, technologySubcategory.id);
        yield call(toastr.success, translate('TECHNOLOGY_CATEGORIES$TECHNOLOGY_CLASS_TYPE'), translate('GLOBALS$SUCCESSFUL_ITEM_CREATE'));
        yield put({type: EDIT.META, expectAnswer: false, technologyClassTypes, technologyClassType});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* updateDataSaga ({type, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true });
    try {
        let data = yield call(updateData, options);
        yield put({type: EDIT.DATA, data});
        yield put({type: EDIT.META, expectAnswer: false});
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    }
    catch ({message}) {
        yield put({type: EDIT.META, expectAnswer: false, errorMessage: message});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.technologies.edit);
    // NOTE go back
    yield call(history.push, back);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, TECHNOLOGIES_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

/**
 * get technology by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/technologies/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * Get list of technology subcategories for current category
 *
 * @param {Number} countryId
 * @private
 */
function getTechnologySubcategories(parentId) {
    return new Promise(( resolve, reject ) => {
        instanceAPI({ method: 'post', url: '/technology-subcategories/filter', data: { page: 0, size: 6, filter: { parentId } } })
            .then(({items}) => resolve(items)).catch(reject);
    });
}

/**
 * Get list of technology class types for current category
 *
 * @param {Number} countryId
 * @private
 */
function getTechnologyClassTypes(parentId) {
    return new Promise(( resolve, reject ) => {
        instanceAPI({ method: 'post', url: '/technology-class-types/filter', data: { page: 0, size: 6, filter: { parentId } } })
            .then(({items}) => resolve(items)).catch(reject);
    });
}

/**
 * Create sub category
 *
 * @param {String} name
 * @private
 */
function createSubcategory(name, technologyCategory) {
    return instanceAPI({method: 'post', url: '/technology-subcategories', data: {name, technologyCategory}});
}

/**
 * Create class type
 *
 * @param {String} name
 * @private
 */
function createClassType(name, technologyCategory, technologySubcategory) {
    return instanceAPI({method: 'post', url: '/technology-class-types', data: {name, technologyCategory, technologySubcategory}});
}

/**
 * update technology
 * @param {Object} data
 * @private
 */
function updateData ( data ) {
    return new Promise((resolve, reject) => {
        let promise;
        if ( !data.id || data.id === NEW_ID ) { // CREATE
            promise = instanceAPI({ method: 'post', url: `/technologies`, data});
        } else { // UPDATE
            promise = instanceAPI({ method: 'put', url: `/technologies`, data});
        }
        // NOTE handle results
        promise.then(resolve).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
