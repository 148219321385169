// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {TASK_CATEGORIES} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {TASK_CATEGORIES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {findHint, RichHintTitle} from "../../../components/hints/hints";

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {expectAnswer, data, message, clearError, hints} = this.props;
        return (<Container fluid>
            <Breadcrumbs breadCrumbsMap={ TASK_CATEGORIES_MAP }  />
            <ConnectedInitializer>
                <Row className="offset-bottom-4">
                    <Col xs={9}>
                        <RichHintTitle update={LIST}  name={`TASK_CATEGORIES$TITLE`} expectAnswer={expectAnswer} data={findHint(hints, `TASK_CATEGORIES_TITLE`)}/>
                    </Col>
                    <Col xs={3} className="text-right top-indent-4">
                        <Link Btn={AddBtn} placement="left" permission={PERMISSION.TASK_CATEGORIES.CREATE} to={TASK_CATEGORIES.LINK_EDIT()} hint = {findHint(hints, 'BUTTON_TASK_CATEGORIES_ADD')}/>
                    </Col>
                </Row>
                <Row className="offset-bottom-4"> <Col xs={12} sm={6} lg={3}> <SearchFilterConnected/> </Col> </Row>
                <Row> <Col xs={12}> <ErrorMessage active message={message} onChange={clearError}/> </Col> </Row>
                {data.length ? (
                    <ConnectedTable />
                ) : (
                    <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                )}
            </ConnectedInitializer>
        </Container>);
    }
}

export default connect(
    state => ({
        data: state.taskCategories.list.data,
        hints: state.taskCategories.list.hintsData,
        message: state.taskCategories.list.errorMessage,
        expectAnswer: state.taskCategories.list.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null})
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.taskCategories.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.taskCategories.list.filter,
        disabled: state.taskCategories.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
    />
));

const ConnectedTable = withTranslation(connect(
    state => ({...state.taskCategories.list, hints: state.taskCategories.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({data, hints, page, size, totalPages, sortF, sortD, expectAnswer, deleteItem, changePage, changeSize, changeSort}) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'name'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('name')}
                        >
                            {translate('GLOBALS$NAME')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"/>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right" className="text-nowrap">
                        {item.readOnly ? null : (
                            <div>
                                <Link
                                    Btn={EditBtn}
                                    permission={PERMISSION.TASK_CATEGORIES.UPDATE}
                                    to={TASK_CATEGORIES.LINK_EDIT({
                                        id: item.id,
                                        query: {back: history.location.pathname + history.location.search}
                                    })}
                                    hint = {findHint(hints, 'BUTTON_TASK_CATEGORIES_EDIT')}
                                />
                                <DelBtn
                                    permission={PERMISSION.TASK_CATEGORIES.DELETE}
                                    onClick={() => deleteItem(item.id)} className="offset-left-2"
                                    hint = {findHint(hints, 'BUTTON_TASK_CATEGORIES_DELETE')}
                                />
                            </div>
                        )}
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>)));
