// outsource dependencies
import {call, put, select, takeEvery} from 'redux-saga/effects';

// local dependencies
import {PREVIEW} from '../actions';
import {history} from '../../../store';
import {NEW_ID} from '../../../constants/spec';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import {instanceAPI} from '../../../services/api.service';
import getHintsList, {POLICY_MANAGEMENT_PREVIEW_HINTS} from '../../../services/hints.service';

/**
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(PREVIEW.CANCEL, cancelSaga);
    yield takeEvery(PREVIEW.INITIALIZE, initializeSaga);
    yield takeEvery(PREVIEW.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

function* initializeSaga ({id}) {
    yield put({type: PREVIEW.CLEAR});
    try {
        let data = yield call(getData, id);
        yield put({type: PREVIEW.GET_HINTS_DATA.REQUEST});
        yield put({type: PREVIEW.DATA, data});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: PREVIEW.META, initialized: true, readOnly: true, ...params});
    }
    catch ({message}) {
        yield put({type: PREVIEW.META, errorMessage: message, initialized: true});
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.policies.preview);
    // NOTE go back
    yield call(history.push, back);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, POLICY_MANAGEMENT_PREVIEW_HINTS);

        // NOTE setup hints data
        yield put({type: PREVIEW.META, hintsData});
    } catch ( {message} ) {
        yield put({type: PREVIEW.META, errorMessage: message});
    }
    yield put({type: PREVIEW.GET_HINTS_DATA.FINISH});
}

/**
 * get policy by id
 * @param {Number|String} id
 * @private
 */
function getData ( id ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/policy/${id}`}).then(resolve).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
