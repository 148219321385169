// outsource dependencies
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {Paper} from '@mui/material';
import {Col, Container, Row} from 'react-bootstrap';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

// local dependencies
import {EDIT} from '../actions';
import MdInput from '../../../components/md-input';
import Preloader from '../../../components/preloader';
import ErrorMessage from '../../../components/alert-error';
import {NEW_ID} from '../../../constants/spec';
import {CancelBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {TASK_CATEGORIES_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {useParams} from "react-router-dom";
import {findHint, RichHintTitle} from '../../../components/hints/hints';

// config
export const FORM_NAME = 'editTaskCategory';
export const changeField = (field, value) => change(FORM_NAME, field, value);

const Edit = (props, {expectAnswer}) => {
    let {id} = useParams();
    useEffect(() => {
        props.initialize(id);
        return () => {
            props.clear();
        }
    }, [])
    let isNew = id === NEW_ID;
    let {hints} = props;
    return (<Container fluid>
        <Breadcrumbs breadCrumbsMap={ TASK_CATEGORIES_MAP }  />
        <ConnectedInitializer>
            <Row className="offset-top-10">
                <Col xs={12} md={{span:10, offset:1}} lg={{span:8, offset:2}} >
                    <Paper className="indent-5">
                        <h2 className="text-uppercase">
                            <span>
                                <RichHintTitle
                                    update={EDIT}
                                    name= {isNew ? translate('TASK_CATEGORIES$CREATE_TASK_CATEGORY') : translate('TASK_CATEGORIES$EDIT_TASK_CATEGORY')}
                                    expectAnswer={expectAnswer}
                                    data={findHint(hints, isNew ? `TASK_CATEGORIES_CREATE_TITLE` : 'TASK_CATEGORIES_EDIT_TITLE')}
                                />
                            </span>
                            <Preloader expectAnswer={expectAnswer} type="ICON"> </Preloader>
                        </h2>
                        <ConnectedError/>
                        <ConnectedForm isNew={isNew} />
                    </Paper>
                </Col>
            </Row>
        </ConnectedInitializer>
    </Container>);
}

export default connect(
    state => ({
        message: state.taskCategories.edit.errorMessage,
        expectAnswer: state.taskCategories.edit.expectAnswer,
        hints: state.taskCategories.edit.hintsData
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({ type: EDIT.META, errorMessage: null})
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.taskCategories.edit.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.taskCategories.edit.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: EDIT.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));

const ConnectedForm = withTranslation(connect(
    state => ({
        initialValues: state.taskCategories.edit.data,
        disabled: state.taskCategories.edit.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
        hints: state.taskCategories.edit.hintsData
    }),
    dispatch => ({
        cancel: () => dispatch({type: EDIT.CANCEL}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @function validate
     * @public
     */
    validate: (values) => {
        let errors = {};
        // name
        if (!values.name) {
            errors.name = 'GLOBALS$NAME_REQUIRED';
        }
        return errors;
    }
})(({handleSubmit, invalid, pristine, disabled, update, reset, isNew, cancel, formValues, hints})=>(
    <form autoComplete="off" name={FORM_NAME} onSubmit={handleSubmit(update)}>
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Field
                    name="name"
                    component={MdInput}
                    disabled={disabled}
                    placeholder={translate('GLOBALS$NAME')}
                     required={true}
                                    label={(<strong className="required-asterisk"> {translate('GLOBALS$NAME')} </strong>)}
                        />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="text-right">
                <SubmitBtn isNew={isNew} disabled={pristine||invalid||disabled} className="offset-right-2"
                hint={findHint(hints, isNew ? `BUTTON_TASK_CATEGORIES_CREATE` : 'BUTTON_TASK_CATEGORIES_SAVE')}/>
                <ResetBtn onClick={reset} disabled={pristine||disabled} className="offset-right-2"  hint = {findHint(hints, 'BUTTON_TASK_CATEGORIES_RESET')}/>
                <CancelBtn onClick={cancel}  hint = {findHint(hints, 'BUTTON_TASK_CATEGORIES_CANCEL')}/>
            </Col>
        </Row>
    </form>
))));
