// outsource dependencies
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

// local dependencies
import {EDIT} from './actions';
import {history} from '../../store';
import MdButton from '../../components/md-button';
import Preloader from '../../components/preloader';
import Dashboard from '../../components/dashboard';
import ErrorMessage from '../../components/alert-error';

class Edit extends Component {
    componentDidMount() { this.props.initialize(this.props.match.params); }
    componentWillUnmount() { this.props.clear(); }

    render() {
        let { disabled, data } = this.props;
        return (
            <Container fluid>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={8}>
                            <h2 className="text-uppercase">
                                {data.name} <Preloader expectAnswer={disabled} type="ICON" />
                            </h2>
                        </Col>
                        <Col xs={4} className="text-right top-indent-4">
                            <MdButton  className="md-btn md-btn-success" onClick={()=>history.back()}>
                                <i className="fa fa-reply" aria-hidden="true" />&nbsp;Back
                            </MdButton>
                        </Col>
                    </Row>
                    <ConnectedError />
                    <Dashboard data={data} />
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        data: state.systemQualsDomain.data,
        disabled: state.systemQualsDomain.expectAnswer,
    }),
    dispatch => ({
        clear: () => dispatch({type: EDIT.CLEAR}),
        initialize: params => dispatch({type: EDIT.INITIALIZE, params}),
    })
)(Edit)

const ConnectedInitializer = connect(
    state => ({initialize: state.systemQualsDomain.initialized}),
    null
)( ({ initialize, children }) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

const ConnectedError = connect(
    state => ({message: state.systemQualsDomain.errorMessage}),
    dispatch => ({ clearError: () => dispatch({ type: EDIT.META, errorMessage: null}) })
)( ({ message, clearError }) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
