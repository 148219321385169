
// outsource dependencies
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// local dependencies
import { getAllPermissions } from '../services/data-formatting.service';

// configuration

/**
 * permission access component
 *
 * @param {Object} props
 * @public
 */
function PermissionAccess ({ user={}, permission, children }) {
    if (!user) {
        return false;
    }
    let isAllowed = !permission || (user.permissions || []).includes(permission);
    return isAllowed && children;
}

PermissionAccess.propTypes = {
    user: PropTypes.object,
    permission: PropTypes.oneOf(getAllPermissions()),
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default connect( state => ({user: state.app.user}), null )(PermissionAccess);
