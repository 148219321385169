
// outsource dependencies
import { combineReducers } from 'redux';

// local dependencies
import list from './list/reducer';
import edit from './edit/reducer';
import questions from './questions/reducer';
import statusLog from './status-log/reducer';

export default combineReducers({
    list,
    edit,
    questions,
    statusLog
});
