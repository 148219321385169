
// outsource dependencies
import { reset } from 'redux-form';
import { toastr } from 'react-redux-toastr'
import { call, put, takeEvery, take } from 'redux-saga/effects';

// local dependencies
import ACTIONS from './actions';
import { APP } from '../../actions/types';
import { instanceAPI } from '../../services/api.service';

// configure

/**
 * connect page sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(ACTIONS.UPDATE, updateSaga);
}

function* updateSaga ({type, ...options}) {
    yield put({type: ACTIONS.META, expectAnswer: true, errorMessage: null });
    try {
        yield call(changePassword, options);
        // NOTE update user
        yield put({type: APP.GET_SELF.REQUEST });
        yield take(APP.GET_SELF.FINISH);
        yield call(toastr.success, 'Reset password', `Your password has been successfully changed`);
        yield put(reset('updateCredentials'));
    }
    catch ({message}) {
        yield put({type: ACTIONS.META, errorMessage: message});
    }
    yield put({type: ACTIONS.META, expectAnswer: false});
}

/**
 * change user password
 * @param {Object} data
 * @private
 */
function changePassword ( data ) {
    return instanceAPI({method: 'post', url: `/user-profile/change-password`, data});
}
