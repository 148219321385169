
// outsource dependencies
import get from 'lodash/get'
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Save, Close } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

// local dependencies
import { EditIconBtn } from './md-button';
import {translate} from '../services/translate.service';

EditableCell.propTypes = {
    editable: PropTypes.bool,
    disabled: PropTypes.bool,
    apply: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    property: PropTypes.string.isRequired,
    setupEditable: PropTypes.func.isRequired,
};


/**
 * Table cell with form control
 * @example
 <EditableCell
     data={item}
     cancel={cancel}
     property="answer"
     disabled={expectAnswer}
     Component={SimpleSelect}
     editOptions={{
         valueKey: 'id',
         isClearable: true,
         labelKey: 'answer',
         options: item.answers,
     }}
     apply={(value)=>apply(index, 'answer', value)}
     setupEditable={()=>setupEditable(index, 'answer')}
     preview={() =>(<span>{get(item, `answer.answer`, 'No answer')}</span>)}
     editable={editableItem && editableItem.index === index && editableItem.property === "answer"}
        />
 * @public
 */
export default function EditableCell ({data, property, component, editable, permission, disabled=false, preview = defPreview, setupEditable, cancel, apply, ...attr}) {
    return editable ? (
        <Edit data={data} property={property} component={component} disabled={disabled} cancel={cancel} apply={apply} {...attr}/>
    ) : (
        <div>
            {preview(data, property)}&nbsp;
            <EditIconBtn permission={permission} disabled={disabled} onClick={setupEditable} />
        </div>
    );
}

class Edit extends Component {
    constructor(props) {
        super(props);
        let { data, property } = this.props;
        this.state = { value: get(data, property, '') };
    };

    handleChange = (e) => {
        this.setState({value: e});
    };

    render() {
        let { cancel, disabled, apply, Component, editOptions = {} } = this.props;
        let { value } = this.state;
        return(
            <div style={{position: 'relative', paddingRight: '100px'}}>
                <Component value={value} onChange={this.handleChange} {...editOptions}/>
                <div style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}>
                    <Tooltip title={translate('GLOBALS$SAVE')} placement="top"><span>
                        <IconButton aria-label={translate('GLOBALS$SAVE')} disabled={disabled} onClick={()=>apply(value)}>
                            <Save fontSize="small" />
                        </IconButton>
                    </span></Tooltip>
                    <Tooltip title={translate('GLOBALS$CLOSE')} placement="top"><span>
                        <IconButton aria-label={translate('GLOBALS$CLOSE')} disabled={disabled} onClick={cancel}>
                            <Close fontSize="small" />
                        </IconButton>
                    </span></Tooltip>
                </div>
            </div>
        );
    }
}

const defPreview = ( data, property ) => {
    return (<span> { get(data, property, translate('GLOBALS$NO_VALUE')) } </span>);
};

