// outsource dependencies
import {get} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';

// local dependencies
import {LIST} from '../actions';
import {history} from '../../../store';
import {PERMISSION} from '../../../constants/spec';
import Preloader from '../../../components/preloader';
import {CAPITAL_COSTS} from '../../../constants/routes';
import ErrorMessage from '../../../components/alert-error';
import SearchFilter from '../../../components/search-filter';
import MdTablePagination from '../../../components/pagination';
import {AddBtn, DelBtn, EditBtn, Link} from '../../../components/md-button';
import {translate, withTranslation} from '../../../services/translate.service';
import {formatBusinessUnitLabel} from '../../../services/data-formatting.service';
import {CAPITAL_COSTS_MAP} from '../../../components/breadcrumbs/breadcrumbsMap';
import Breadcrumbs from '../../../components/breadcrumbs/breadcrumb';
import {findHint, RichHintTitle} from '../../../components/hints/hints';

class List extends Component {
    componentDidMount() {
        this.props.initialize();
    }

    componentWillUnmount() {
        this.props.clear();
    }

    render() {
        let {disabled, data, hints} = this.props;
        return (
            <Container fluid>
                <Breadcrumbs breadCrumbsMap={CAPITAL_COSTS_MAP}/>
                <ConnectedInitializer>
                    <Row className="offset-bottom-4">
                        <Col xs={6}>
                            <RichHintTitle update={LIST} name={`FIXED_CAPITAL_COSTS$TITLE`} expectAnswer={disabled}
                                           data={findHint(hints, `FIXED_CAPITAL_COSTS_TITLE`)}/>
                        </Col>
                        <Col xs={6} className="text-right top-indent-4">
                            <Link Btn={AddBtn} placement="left" to={CAPITAL_COSTS.LINK_EDIT()}
                                  permission={PERMISSION.CAPITAL_COST.CREATE}
                                  hint={findHint(hints, 'BUTTON_FIXED_CAPITAL_COSTS_ADD')}/>
                        </Col>
                    </Row>
                    <Row className="offset-bottom-4">
                        <Col xs={12} sm={6} lg={3}>
                            <SearchFilterConnected/>
                        </Col>
                    </Row>
                    <ConnectedError/>
                    {data.length ? (
                        <Paper> <ConnectedTable/> </Paper>
                    ) : (
                        <h3 className="text-uppercase text-center text-highlighted"> {translate('GLOBALS$NO_DATA')} </h3>
                    )}
                </ConnectedInitializer>
            </Container>
        );
    }
}

export default connect(
    state => ({
        disabled: state.capitalCosts.list.expectAnswer,
        hints: state.capitalCosts.list.hintsData,
        data: state.capitalCosts.list.data
    }),
    dispatch => ({
        clear: () => dispatch({type: LIST.CLEAR}),
        initialize: () => dispatch({type: LIST.INITIALIZE})
    })
)(List);

const ConnectedInitializer = connect(
    state => ({initialize: state.capitalCosts.list.initialized}),
    null
)(({initialize, children}) => (
    <Preloader expectAnswer={!initialize} type="MIN_HEIGHT" height={800}>{children}</Preloader>
));

/**
 * search filter
 *
 * @public
 */
const SearchFilterConnected = withTranslation(connect(
    state => ({
        filter: state.capitalCosts.list.filter,
        disabled: state.capitalCosts.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_DATA, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder={translate('SECURITY_TOOLS$SEARCH_BY_SECURITY_TOOL')}
    />
)));

const ConnectedTable = withTranslation(connect(
    state => ({...state.capitalCosts.list, hints: state.capitalCosts.list.hintsData}),
    dispatch => ({
        deleteItem: id => dispatch({type: LIST.DELETE_ITEM, id}),
        changePage: page => dispatch({type: LIST.UPDATE_DATA, page}),
        changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}),
        changeSize: size => dispatch({type: LIST.UPDATE_DATA, size, page: 0}),
    })
)(({
    data,
    hints,
    page,
    size,
    totalPages,
    sortF,
    sortD,
    expectAnswer,
    deleteItem,
    changePage,
    changeSize,
    changeSort
}) => (<div>
    <div style={{overflowX: 'auto'}}>
        <Table className="md-table" padding="checkbox">
            <TableHead style={{paddingRight: 100, paddingLeft: 10}}>
                <TableRow style={{height: 48}}>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'technology'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('technology')}
                        >
                            {translate('TECHNOLOGIES$TECHNOLOGY')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'licenseType'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('licenseType')}
                        >
                            {translate('FIXED_CAPITAL_COSTS$LICENSE_TYPE')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'licenseCost'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('licenseCost')}
                        >
                            {translate('FIXED_CAPITAL_COSTS$LICENSE_COST')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'totalCosts'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('totalCosts')}
                        >
                            {translate('GLOBALS$TOTAL_COSTS')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'vendor'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('vendor')}
                        >
                            {translate('VENDORS$VENDOR')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th">
                        <TableSortLabel
                            active={sortF === 'businessUnit'}
                            direction={sortD ? 'asc' : 'desc'}
                            onClick={() => changeSort('businessUnit')}
                        >
                            {translate('BUSINESS_UNITS$BUSINESS_UNIT')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className="th"/>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, i) => (<TableRow style={{height: 48}} key={i}>
                    <TableCell>{get(item, 'technology.name')}</TableCell>
                    <TableCell>{get(item, 'licenseType.name')}</TableCell>
                    <TableCell>{item.licenseCost}</TableCell>
                    <TableCell>{item.totalCosts}</TableCell>
                    <TableCell>{get(item, 'vendor.name')}</TableCell>
                    <TableCell>{formatBusinessUnitLabel(get(item, 'businessUnit'))}</TableCell>
                    <TableCell className="text-nowrap" align="right">
                        <Link
                            Btn={EditBtn}
                            permission={PERMISSION.CAPITAL_COST.UPDATE}
                            hint={findHint(hints, 'BUTTON_FIXED_CAPITAL_COSTS_EDIT')}
                            to={CAPITAL_COSTS.LINK_EDIT({
                                id: item.id,
                                query: {back: history.location.pathname + history.location.search}
                            })}
                        />
                        <DelBtn permission={PERMISSION.CAPITAL_COST.DELETE} onClick={() => deleteItem(item.id)}
                                className="offset-left-2" hint={findHint(hints, 'BUTTON_VARIABLE_COSTS_DELETE')}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={expectAnswer}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
    />
</div>)));

const ConnectedError = connect(
    state => ({message: state.capitalCosts.list.errorMessage}),
    dispatch => ({clearError: () => dispatch({type: LIST.META, errorMessage: null})})
)(({message, clearError}) => (
    <ErrorMessage active message={message} onChange={clearError}/>
));
