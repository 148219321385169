
// outsource dependencies
import { get, find } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { takeEvery, put, call, select } from 'redux-saga/effects';

// local dependencies
import { EDIT } from '../actions';
import { changeField } from './index';
import { history } from '../../../store';
import * as ROUTES from '../../../constants/routes';
import queryService from '../../../services/query.service';
import { instanceAPI } from '../../../services/api.service';
import { translate } from '../../../services/translate.service';
import { NEW_ID, QUESTION_TYPES } from '../../../constants/spec';
import getHintsList, {SCORING_QUESTIONS_EDIT_HINTS} from '../../../services/hints.service';

function * initializeSaga ({ id }) {
    yield put({type: EDIT.CLEAR});
    try {
        let { riskModel } = yield select(state => state.app);
        let data = yield call(getData, id, riskModel.id);
        yield put({data, type: EDIT.DATA});
        // NOTE take data from location and setup verified params
        const params = yield call(getQueryParams, queryService.parse(history.location.search));
        yield put({type: EDIT.META, ...params});
        yield put({type: EDIT.GET_HINTS_DATA.REQUEST});
    } catch ( {message} ) {
        yield call(toastr.error, translate('GLOBALS$ERROR'), message);
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false, initialized: true});
}

function * updateDataSaga ({type, id, ...options}) {
    yield put({type: EDIT.META, expectAnswer: true, errorMessage: null});
    try {
        let { riskModel } = yield select(state => state.app);
        if (id === false) {
            // NOTE get old question value and compare with new question value
            let {question} = yield select(state => state.qualQuest.edit.data);
            if (question === options.question) options.question = question + ' (copy)';
        }
        let data = yield call(updateData, id, riskModel.id, options);
        yield put({data, type: EDIT.DATA});
        // NOTE to update edit url for new entity
        // if ( !id || id === NEW_ID ) {
        //     // NOTE update url
        //     yield call(historyPush, QUAL_QUESTIONS.LINK_EDIT({id: result.id}));
        // }
        // NOTE go to list
        yield put({type: EDIT.CANCEL});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.META, expectAnswer: false});
}

function * setupVendorTypeSaga ({vendorType}) {
    // NOTE clear previous values
    yield put(changeField('allVendorsSelected', false));
    yield put(changeField('riskTypes', []));
    yield put(changeField('vendors', []));
    yield put(changeField('systems', []));
    // NOTE clear vendor category
    yield put(changeField('isTechnologyVendor', false));
    yield put(changeField('isSystemVendor', false));
    yield put(changeField('isServiceVendor', false));
    if ( vendorType === QUESTION_TYPES.BOTH ) {
        yield put(changeField('allVendorsSelected', true));
    }
}

function * selectAllVendorsSaga ({selectedAll}) {
    if ( selectedAll ) {
        yield put(changeField('vendors', []));
        yield put(changeField('systems', []));
    }
}

function* cancelSaga () {
    let { back } = yield select(state => state.qualQuest.edit);
    // NOTE go back
    yield call(history.push, back);
}

function* getHintsDataSaga (hintType) {
    try {
        let { language } = yield select( state => state.app );
        let hintsData = yield call(getHintsList, language, SCORING_QUESTIONS_EDIT_HINTS);

        // NOTE setup hints data
        yield put({type: EDIT.META, hintsData});
    } catch ( {message} ) {
        yield put({type: EDIT.META, errorMessage: message});
    }
    yield put({type: EDIT.GET_HINTS_DATA.FINISH});
}

/**
 * connect all public sagas
 *
 * @public
 */
export default function* () {
    yield takeEvery(EDIT.CANCEL, cancelSaga);
    yield takeEvery(EDIT.UPDATE, updateDataSaga);
    yield takeEvery(EDIT.INITIALIZE, initializeSaga);
    yield takeEvery(EDIT.SETUP_VENDOR_TYPE, setupVendorTypeSaga);
    yield takeEvery(EDIT.SELECT_ALL_VENDORS, selectAllVendorsSaga);
    yield takeEvery(EDIT.GET_HINTS_DATA.REQUEST, getHintsDataSaga);
}

/**
 * getting entity data by id
 *
 * @param {String|Number} id
 * @param {String|Number} riskModelId
 * @public
 */
function getData ( id, riskModelId ) {
    return new Promise((resolve, reject) => {
        if ( id === NEW_ID ) return resolve({});
        // NOTE get entity data
        instanceAPI({method: 'get', url: `/risk-model/${riskModelId}/qualitative-questions/${id}`})
            .then( success => {
                // NOTE sort question answers by weight
                (success.answers || []).sort((a,b) => get(a, 'answerWeight.value') -  get(b, 'answerWeight.value'));
                // NOTE save 'vendorType' as relevant object for view handling
                success.vendorType = find(Object.keys(QUESTION_TYPES).map(i=>QUESTION_TYPES[i]), {value: success.vendorType});
                resolve(success);
            }).catch(reject);
    });
}

/**
 * update or create entity
 *
 * @param {String|Number} id
 * @param {String|Number} riskModelId
 * @param {Object} data
 * @public
 */
function updateData ( id, riskModelId, data ) {
    data.vendorType = get(data, 'vendorType.value');
    return new Promise((resolve, reject) => {
        let promise;
        if ( !id || id === NEW_ID ) { // CREATE
            if (data.answers && data.answers.length) {
                data.answers.map( answer => delete answer.id)
            }
            if (data.branchingLogic && data.branchingLogic.length) {
                data.branchingLogic.map( branchingLogic => delete branchingLogic.id)
            }
            promise = instanceAPI({
                method: 'post',
                data: {...data, riskModelId},
                url: `/risk-model/${riskModelId}/qualitative-questions`,
            });
        } else { // UPDATE
            promise = instanceAPI({
                method: 'put',
                data: {...data, id, riskModelId},
                url: `/risk-model/${riskModelId}/qualitative-questions`,
            });
        }
        // NOTE handle results
        promise
            .then( success => {
                // NOTE sort question answers by weight
                (success.answers || []).sort((a,b) => get(a, 'answerWeight.value') -  get(b, 'answerWeight.value'));
                // NOTE save 'vendorType' as relevant object for view handling
                success.vendorType = find(Object.keys(QUESTION_TYPES).map(i=>QUESTION_TYPES[i]), {value: success.vendorType});
                resolve(success);
            }).catch(reject);
    });
}

/**
 * helper to determine correctness url params
 *
 * @param {Object} query
 * @return {Object}
 * @public
 */
function getQueryParams ({back}) {
    let params = {};
    // back param
    for (let key in ROUTES) {
        if (ROUTES[key].REGEXP && ROUTES[key].REGEXP.test(back)) {
            params.back = back;
            break;
        }
    }
    return params;
}
