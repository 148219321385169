/**
 * @description input field change disabling method
 * @param {Number|String} value
 * @returns {Number|String}
 * @public
 */
function freezeField(value){
    if (!value) {
        return value;
    }
}

/**
 * @description integer number with a comma separators input field normalizing
 * @param {Number|String} value
 * @returns {Number|String}
 * @public
 */
function separateWithComma(value) {
    if (!value) {
        return value;
    }
    return Number(value.replace(/[^\d]/g, '')).toLocaleString('en');
}

/**
 * @description Real number with a comma separators input field normalizing
 * @param {Number|String} value
 * @returns {Number|String}
 * @public
 */
function separateWithCommaReal(value) {
    if (!value) {
        return value;
    }
    let valueWithDot;
    valueWithDot = value.replace(/[^.\d]/g, '');
    if (valueWithDot.split('.').length > 2) {
        return Number(value.replace(/[\D]/g, '')).toLocaleString('en');
    }
    if (valueWithDot.endsWith('.')) {
        return value.replace(/[^.,\d]/g, '');
    }
    if (valueWithDot.split('.').length > 1) {
        return Number(value.replace(/[^.\d]/g, '')).toLocaleString('en');
    }
    return Number(valueWithDot.replace(/[\D]/g, '')).toLocaleString('en');
}

export default {
    freeze: freezeField,
    toInteger: separateWithComma,
    toReal: separateWithCommaReal
};
